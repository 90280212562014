import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'app/services/user.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { AuthService } from 'app/services/auth.service';
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AspNetLanguages } from 'app/services/languages';

@Component({
    selector: "app-options-parents",
    templateUrl: "./options-parents.component.html",
    styleUrls: ["./options-parents.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class OptionsParentsComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  private _busy: boolean = false;

  public form: FormGroup;
  public languages$: Observable<AspNetLanguages[]>;

  constructor(
    private _userService: UserService,
    private _authService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    private _matDialog: MatDialog,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    if (!this._authService.check()) return;

    this.form = this._formBuilder.group({
      Language: [this._authService.currentUser.AspNetUserLanguages.UserStudentsIdLanguage, [Validators.required]],
    });

    this.languages$ = this._userService.getLanguages();
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.valid && !this._busy) {
      this._busy = true;

      this._userService.setUserStudentsLanguage(this._authService.currentUser.Id, this.form.value.Language).pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => {
        this._authService.reloadUser(() => {          
          let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
          });

          confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.OPTIONS.SAVE-CONFIG-TITLE');
          confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SUCCESS-SAVE-CONFIG-MESSAGE');
          confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
          confirmDialog.componentInstance.margin = false;

          confirmDialog.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => {
            this._busy = false;
            confirmDialog = null;
          });
        });
      }, error => {
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
          disableClose: true
        });

        confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.OPTIONS.SAVE-CONFIG-TITLE');
        confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.ERROR-SAVE-CONFIG-MESSAGE');
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;

        confirmDialog.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => {
          confirmDialog = null;
          this._busy = false;
        });
      });
    }
  }
}
