import { UserService } from 'app/services/user.service';
import { NavigationService } from './../../../../../services/navigation.service';
import { Component, OnInit, ViewEncapsulation, HostListener, Renderer2, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { environment } from '../../../../../../environments/environment'
import { SharedService } from 'app/core/shared/shared.service';
import { DataQuery, School, DataService } from 'app/core/shared/state';
import { UiService } from '@fuse/components/spinner/ui.service';
import { RepositoryService } from 'app/core/repository/repository.service';
import { AccountService } from '../../../account.service';
import { AuthService } from '../../../../../core/guards/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'app/app.service';
import { ToolbarService } from 'app/services/toolbarService.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'admin-info',
    templateUrl: './admin-info.component.html',
    styleUrls: ['./admin-info.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:resize)': 'onResize($event)'
    }
})
export class AdminInfoComponent implements OnInit, AfterViewInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    errorMessage: string;
    school: School;
    adminInfoForm: UntypedFormGroup;
    email: string;
    passwd: string;
    name: string;
    surname: string;
    walinwaUser: string;
    url: string;
    mobile: boolean = false;
    iPad = /iPad|Macintosh/i.test(navigator.userAgent); mobileDetect;
    continue: boolean;
    phone: string;
    isChecked = false;
    hide = true;
    submited = false;
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;
    step = 1;
    code = Math.floor(100000 + Math.random() * 900000);
    codeError = false;
    userCode = null;
    timer: number;
    regExp = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜäÄëËïÏöÖç\s\.\-'ª’]+$/u
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        private _translateService: TranslateService,
        private renderer: Renderer2,
        public _sharedService: SharedService,
        private _repositoryService: RepositoryService,
        private _ui: UiService,
        private dataQuery: DataQuery,
        private _accountService: AccountService,
        private _authService: AuthService,
        private iconRegistry: MatIconRegistry,
        private _matDialog: MatDialog,
        private sanitizer: DomSanitizer,
        private navigationService: NavigationService,
        private appService: AppService,
        private userService: UserService,
        private toolbarService: ToolbarService,
        private activatedRoute: ActivatedRoute
    ) {
        this._unsubscribeAll = new Subject();
        this.url = environment.url;
        this._ui.spin$.next(true);
        this.mobileDetect = this.appService.mobileDetect();

        iconRegistry.addSvgIcon('gafas_off', sanitizer.bypassSecurityTrustResourceUrl("https://walinwa.blob.core.windows.net/images/ver-pass-1.svg?ngsw-bypass=true"));
        iconRegistry.addSvgIcon('gafas_on', sanitizer.bypassSecurityTrustResourceUrl("https://walinwa.blob.core.windows.net/images/ver-pass.svg?ngsw-bypass=true"));
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._sharedService.adminInfoForm = this.adminInfoForm;
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit() {

        const matToolbarRef = this.toolbarService.getToolbarRef();
        if (matToolbarRef) {
            setTimeout(() => {
                this.renderer.addClass(matToolbarRef.nativeElement, 'only-logo');
            }, 0);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        event.target.innerWidth;
        this.mobile = this._sharedService.isMobile();
    }

    ngOnInit(): void {
        this.mobile = this._sharedService.isMobile();
        this.school = this.dataQuery.school;
        if (this.school == null) {
            this._router.navigate(['./login']);
            return;
        }
        this.continue = false;
        this.walinwaUser = this.school.initials + '.admin';
        this.initForm();
    }
    initForm(): void {
        if (this._sharedService.adminInfoForm != null) {
            this.adminInfoForm = this._sharedService.adminInfoForm;
        }
        else {
            this.adminInfoForm = this._formBuilder.group({
                email: ['', [Validators.required, Validators.email]],
                passwd: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
                name: ['', [Validators.pattern(this.regExp)]],
                surname: ['', [Validators.pattern(this.regExp)]],
                phone: ['', [Validators.required,Validators.minLength(9)]],
                walinwauser: [{ value: this.walinwaUser, disabled: true }],
                privacy: [false, Validators.required],
            });
        }
        
        //this.adminInfoForm.markAllAsTouched();
    }

    finish(event, step) {
        this.submited = true;
        this.adminInfoForm.markAllAsTouched();

        if (this.adminInfoForm.valid) {
            if (step == 1) {
                if (this.adminInfoForm.valid && this.adminInfoForm.value.privacy) {
                    let url = environment.baseApi + '/api/User/validate/' + this.walinwaUser + '/' + this.adminInfoForm.value.email + '/' + true;
                    this._ui.showSpinner();
                    this._repositoryService.get(url)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(() => {
                            this._repositoryService.get(environment.baseApi + "/api/School/validateNameAndDomain/" + this.school.name + "/" + this.adminInfoForm.value.email)
                                .pipe(takeUntil(this._unsubscribeAll))
                                .subscribe((response: any) => {
                                    this.userService.sendVerificationCode(this.code, this.adminInfoForm.value.email, false)
                                        .pipe(takeUntil(this._unsubscribeAll))
                                        .subscribe(() => {
                                            this.step = 2;
                                            this.timer = 100;
                                            let timer = setInterval(() => {
                                                if (this.timer == 0) {
                                                    clearInterval(timer);
                                                }
                                                else {
                                                    this.timer--;
                                                }
                                            }, 1000)
                                            this._ui.stopSpinner();
                                        });
                                },
                                    error => {
                                        this._ui.stopSpinner();
                                        if (error && error.error && error.error == "EmailAndName") {
                                            this.showDialog();
                                        } else {
                                            this.errorMessage = this._sharedService.getError(error);
                                        }
                                    });
                        },
                            error => {
                                this.errorMessage = this._sharedService.getError(error);
                                this._ui.stopSpinner();
                            });
                }
                else {
                    if (this.adminInfoForm.value.privacy == false)
                        this.continue = true;
                    else
                        this.continue = false;
                }
            }
            else {
                //876432 código maestro
                if (this.userCode == this.code || this.userCode == 876432) {
                    this._ui.showSpinner();
                    this.codeError = false;
                    this.saveValidData();
                }
                else {
                    this.codeError = true;
                }
            }
        }
    }

    showDialog() {

        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });

        this.confirmDialog.componentInstance.title = this._translateService.instant('AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.ADMIN-INFO.SCHOOL-EXIST-TITLE');
        this.confirmDialog.componentInstance.message1 = this._translateService.instant('AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.ADMIN-INFO.SCHOOL-EXIST-MESSAGE');
        this.confirmDialog.componentInstance.space = true;
        this.confirmDialog.componentInstance.options = [{
            text: this._translateService.instant('ACCEPT'),
            callback: () => {
                this.userService.sendVerificationCode(this.code, this.adminInfoForm.value.email, false)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(() => {
                        this.step = 2;
                        this.timer = 100;
                        
                        let timer = setInterval(() => {
                            if (this.timer == 0) {
                                clearInterval(timer);
                            }
                            else {
                                this.timer--;
                            }
                        }, 1000);
                        this._ui.stopSpinner();
                    });
            }
        }, {
            text: this._translateService.instant('CANCEL'),
            callback: () => { }
        }];
        this.confirmDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                result();
                this.confirmDialog = null;
            });
    }

    saveValidData() {

        let school = {
            Name: this.school.name,
            CP: this.school.cP,
            Country: this.school.country,
            Street: this.school.street,
            State: this.school.state,
            Phone: this.adminInfoForm.value.phone,
            Origin: this.school.origin,
            Initials: this.school.initials
        };

        let admin = {
            UserName: this.walinwaUser,
            Name: this.adminInfoForm.value.name,
            FamilyName: this.adminInfoForm.value.surname,
            Email: this.adminInfoForm.value.email,
        };

        this._repositoryService.post(environment.baseApi + "/api/School/addSchool", { school: school, admin: admin, password: this.adminInfoForm.value.passwd })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                let userInfo = {
                    userName: this.walinwaUser,
                    password: this.adminInfoForm.value.passwd,
                    offset: (-(new Date().getTimezoneOffset() / 60)).toString(),
                    userAgent: window.navigator.userAgent,
                    version: environment.version
                };
                this.navigationService.go("/free-trial/admin-guide", { userinfo: userInfo })
            },
                error => {
                    this.errorMessage = this._sharedService.getError(error);
                    this._ui.stopSpinner();
                });

    }
}
