import { PersonalizedTestResult } from '../../../core/shared/state/models/personalized-test/personalized-test-result.model';
import { CustomSession } from 'app/core/shared/state';
import { PersonalizedTest } from '../../../core/shared/state/models/personalized-test/personalized-test.model';
import { PersonalizedTestService } from './../../../services/personalized-test.service';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { PersonalizedTestAnswer } from 'app/core/shared/state/models/personalized-test/personalized-test-answer.model';
import { PersonalizedTestQuestion } from 'app/core/shared/state/models/personalized-test/personalized-test-question.model';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationService } from 'app/services/navigation.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { CustomizedSessionService } from 'app/services/customized-session.service';
import { SharedService } from 'app/core/shared/shared.service';
import { PersonalizedTestUserReview } from 'app/core/shared/state/models/test-user-review.model';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'personalized-test',
    templateUrl: './personalized-test.component.html',
    styleUrls: ['./personalized-test.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PersonalizedTestStudentComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    @Input() review = false;
    @Input() IdTest: number
    @Input() teacherReview = false;
    @Input() date = null;
    @Input() IdUser: number
    @Input() Score: number
    @Input() visualize: boolean = false;
    questionsForm: UntypedFormGroup
    loading = true
    test: PersonalizedTest
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;
    doneTests: PersonalizedTestUserReview[] = []
    TestList = []
    questionIndex = 0;
    state = "resultList"
    mobile: boolean;
    constructor(
        private personalizedTestService: PersonalizedTestService,
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        private navigationService: NavigationService,
        private _matDialog: MatDialog,
        private customizedSessionService : CustomizedSessionService,
        public sharedService: SharedService,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        if(!this.teacherReview && !this.review){
            this.TestDone()
        }
    }

    ngOnInit(): void {
        this.mobile = this.sharedService.isMobile()
        if (!this.authService.check()) return;
        if(this.navigationService.params){
            if(this.navigationService.params.review == true){
                this.review = true;
            }
        }
        if(this.teacherReview){
            this.loadTeacherReview()
        }
        else if (this.review) {
            this.loadReview();
        } else {
            this.questionsForm = this.formBuilder.group({
                Questions: this.formBuilder.array([])
            })
            this.getTest(this.IdTest);
        }

    }
    loadReview(){
        this.loading = true;
        this.personalizedTestService.getUserDoneTest(this.authService.currentUser.Id, this.authService.currentUser.IdClass)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((resultados: PersonalizedTestUserReview[]) => {
                this.doneTests = resultados;
                
                if (this.doneTests && this.doneTests.length > 0) {
                    this.doneTests.forEach(resultado => {
                        resultado.FormatedDate = new Date(resultado.FinishDate).toLocaleDateString("es-ES")
                    })
                    this.doneTests[0].selected = true;
                }
                this.loading = false;
            })
    }
    loadTeacherReview(){
        this.loading = true;
        this.personalizedTestService.getPersonalizedTestById(this.IdTest, this.IdUser, this.date, true)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: PersonalizedTest) => {
            this.test = result;
            this.MarkUserAnswers();
            if(this.test.MessQuestions && this.visualize){
                this.test.TestQuestion = this.shuffleArray(this.test.TestQuestion);
            }
            
            this.loading = false;
            this.state = 'reviewTest'
        })
    }
    Questions(): UntypedFormArray {
        return this.questionsForm.get('Questions') as UntypedFormArray;
    }
    newQuestion(): UntypedFormGroup {
        return this.formBuilder.group({
            IdQuestion: 0,
            Question: ['', Validators.required],
            TestAnswer: this.formBuilder.array([this.newAnswer()])
        })
    }
    next(){
        this.questionIndex++;
    }
    back(){
        this.questionIndex--;
    }
    newEmptyQuestion(question: PersonalizedTestQuestion = null): UntypedFormGroup {
        return this.formBuilder.group({
            IdQuestion: question ? question.IdQuestion : 0,
            Question: question ? [question.Question, Validators.required] : ['', Validators.required],
            TestAnswer: this.formBuilder.array([])
        })
    }
    
    userAnswered(questionIndex: number){
        return this.test.TestQuestion[questionIndex].TestAnswer.some(x => x.userAnswer)
    }
    addQuestion(question: PersonalizedTestQuestion = null) {
        if (question) {
            this.Questions().push(this.newEmptyQuestion(question));
        }
        else {
            this.Questions().push(this.newQuestion());
        }
    }
    QuestionAnswers(questionIndex): UntypedFormArray {
        return this.Questions().at(questionIndex).get("TestAnswer") as UntypedFormArray
    }

    newAnswer(answer: PersonalizedTestAnswer = null): UntypedFormGroup {
        return this.formBuilder.group({
            IdQuestion: answer ? answer.IdQuestion : 0,
            IdAnswer: answer ? answer.IdAnswer : 0,
            Answer: answer ? [answer.Answer, Validators.required] : ['', Validators.required],
            IsCorrect: false
        })
    }
    addQuestionAnswer(questionIndex: number, answer: PersonalizedTestAnswer = null) {
        this.QuestionAnswers(questionIndex).push(this.newAnswer(answer));
    }
    changeCorrectAnswer(questionIndex, answerIndex) {
        if(this.test.MultipleCorrectAnswers){
            let respuesta = this.questionsForm.value.Questions[questionIndex].TestAnswer[answerIndex];
            respuesta.IsCorrect = !respuesta.IsCorrect
            return;
        }
        this.questionsForm.value.Questions[questionIndex].TestAnswer.forEach((respuesta, index) => {
            if (answerIndex == index) {
                respuesta.IsCorrect = !respuesta.IsCorrect
            } else {
                respuesta.IsCorrect = false;
            }
        });
    }
    getTest(idTest) {
        this.personalizedTestService.getPersonalizedTestById(idTest, this.authService.currentUser.Id, moment(), false)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((result: PersonalizedTest) => {
            this.test = result
            console.log(this.test)
            this.StartTest()
            if(this.test.MessQuestions){
                this.test.TestQuestion = this.shuffleArray(this.test.TestQuestion);
            }
            this.test.TestQuestion.forEach((pregunta, preguntaIndex) => {
                this.addQuestion(pregunta)
                pregunta.TestAnswer.forEach((respuesta, respuestaIndex) => {
                    this.addQuestionAnswer(preguntaIndex, respuesta)
                });
            })
            this.loading = false
        })
    }
    StartTest(){
        let numPossibleAnswers = 0 
        this.test.TestQuestion.forEach(pregunta => {
            numPossibleAnswers+= pregunta.TestAnswer.filter(x => x.IsCorrect).length;
        })
        this.personalizedTestService.start(this.IdTest, this.authService.currentUser.Id, numPossibleAnswers)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe();
    }   
    shuffleArray(array: any[]): any[] {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // Intercambiar elementos
        }
        return array;
    }
    hasAnswered(){
        return this.questionsForm.value.Questions[this.questionIndex].TestAnswer.find(x => x.IsCorrect)
    }
    reviewTest(state: string) {
        if (state == 'resultList') {
            this.loadReview()
            this.state = state;
            return;
        }
        let selecedTest = this.doneTests.find(x => x.selected);
        this.personalizedTestService.getPersonalizedTestById(selecedTest.IdTest, this.authService.currentUser.Id, selecedTest.FinishDate, true, selecedTest.Rewarded ? true : false, selecedTest.Score)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: PersonalizedTest) => {
                this.test = result;
                selecedTest.Rewarded = true;
                this.MarkUserAnswers();
                this.state = state
            })
    }

    MarkUserAnswers(){
        var existe = false;
                this.test.TestQuestion.forEach(pregunta => {
                    pregunta.TestAnswer.forEach(respuesta => {
                        respuesta.userAnswer = false;
                    })
                })
                this.test.TestQuestion.forEach(pregunta => {
                    existe = false;
                    if (this.test.TestAnswerUser.some(x => x.IdQuestion == pregunta.IdQuestion)) {
                        existe = true
                    }
                    pregunta.TestAnswer.forEach(respuesta => {
                        if (existe) {
                            if (this.test.TestAnswerUser.find(x => x.IdQuestion == pregunta.IdQuestion && x.IdAnswer == respuesta.IdAnswer)) {
                                respuesta.userAnswer = true;
                            }
                        } else {
                            respuesta.userAnswer = false;
                        }
                    })
                });
    }
    selectPersonalizedTest(doneTest) {
        this.doneTests.forEach(x => { x.selected = false })
        doneTest.selected = true
    }
    reviewDisabled(){
        let doneTest = this.doneTests.find(x => x.selected);
        if(!doneTest || !doneTest.TestOpen){
            return true
        }
        return false;
    }
    canReview(doneTest: any){
        if(doneTest.TestOpen){
            return true;
        }
        return false
    }
    saveAnswers() {
        //primero se guardan las respuestas
        var userAnswers = []
        this.Questions().controls.forEach((question, questionIndex) => {
            this.QuestionAnswers(questionIndex).controls.forEach(rta => {
                if (rta.value.IsCorrect) {
                    userAnswers.push({
                        IdQuestion: question.value.IdQuestion,
                        IdAnswer: rta.value.IdAnswer
                    })
                }
            })
        });
        let numOks = 0;
        let numTotalPosibleAnswers = 0;
        this.test.TestQuestion.forEach(question => {
            let countErrors = 0;
            let numPosibleAnswers = question.TestAnswer.filter(x => x.IsCorrect).length;
            numTotalPosibleAnswers += numPosibleAnswers;
            let countCorrectAnswers = 0
            question.TestAnswer.forEach(answer => {
                if (answer.IsCorrect) {
                    if(userAnswers.find(x => x.IdQuestion == question.IdQuestion && x.IdAnswer == answer.IdAnswer)){
                        countCorrectAnswers++;
                    }
                }
                else {
                    if(userAnswers.find(x => x.IdQuestion == question.IdQuestion && x.IdAnswer == answer.IdAnswer)){
                        countErrors++;
                    }
                }
            })
            if(this.test.MultipleCorrectAnswers && !this.test.ProportionalScore){
                if(numPosibleAnswers == countCorrectAnswers){
                    numOks+= countCorrectAnswers;
                }
            }
            else if(this.test.MultipleCorrectAnswers && this.test.ProportionalScore && countCorrectAnswers > 0) {
                numOks+= countCorrectAnswers;
            }
            else {
                numOks+= countCorrectAnswers
            }
            if(this.test.MultipleCorrectAnswers){
                numOks -= countErrors
            }
            if(numOks < 0){
                numOks = 0;
            }
        })
        this.personalizedTestService.updateTestAnswers(this.test.IdTest, this.authService.currentUser.Id, userAnswers)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(ok => {

                this.personalizedTestService.update(this.test.IdTest, this.authService.currentUser.Id, numOks, numTotalPosibleAnswers)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(result => {
                        this.loading = false;
                        this.showMessage()
                    })
            })
    }

    TestDone(){
        this.customizedSessionService.hasTest(this.authService.currentUser.IdClass,this.authService.currentUser.Id, moment().format('HH:mm'))
        .subscribe((result : number) => {
            this.sharedService.ChangeCustomSessionHastTest(result);
        })
      }
    
    finish() {
        let notDone = []
        this.Questions().controls.forEach((question, questionIndex) => {
            if (this.QuestionAnswers(questionIndex).controls.every(x => x.value.IsCorrect == false)) {
                notDone.push(question.value.IdQuestion)
            }
        });
        if (notDone.length > 0) {
            this.showAnticipateCloseModal(notDone);
        } else {
            this.saveAnswers();
        }
    }
    showAnticipateCloseModal(notDone){
        let text = ""
            notDone.forEach((x, index) => {
                if (index == notDone.length - 1) {
                    text += x + "."
                }
                else {
                    text += x + ", "
                }
            })
            var message = this._translateService.instant('STUDENT.PERSONALIZED-TEST.NOT-ANSWERED-QUESTIONS', {text});
            this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });
            this.confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.PERSONALIZED-TEST.END-TEST-DIALOG-TITLE');
            this.confirmDialog.componentInstance.message1 = message;
            this.confirmDialog.componentInstance.space = true;
            this.confirmDialog.componentInstance.margin = false;
            this.confirmDialog.componentInstance.options = [{
                text: this._translateService.instant('ACCEPT'),
                callback: () => {
                    this.saveAnswers()
                }
            }, {
                text: this._translateService.instant('CANCEL'),
                callback: () => {
                }
            }];
            this.confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
                result();
                this.confirmDialog = null;
            });
    }
    close() {
        if (this.teacherReview) {
            this.personalizedTestService.doTest = false;
            return;
        }
        if (this.review) {
            if (this.state == 'reviewTest') {
                this.reviewTest('resultList')
            }
            else {
                this.reviewTest('reviewTest')
            }
            return
        }
        let notDone = []
        this.Questions().controls.forEach((question, questionIndex) => {
            if (this.QuestionAnswers(questionIndex).controls.every(x => x.value.IsCorrect == false)) {
                notDone.push(question.value.IdQuestion)
            }
        });
        if (notDone.length > 0) {
            this.showAnticipateCloseModal(notDone);
        }
        else {
            this.saveAnswers()
        }



    }
    getFecha() {
        if (!this.test) return;
        let meses = [
            this._translateService.instant('MONTHS.JANUARY'),
            this._translateService.instant('MONTHS.FEBRUARY'),
            this._translateService.instant('MONTHS.MARCH'),
            this._translateService.instant('MONTHS.APRIL'),
            this._translateService.instant('MONTHS.MAY'),
            this._translateService.instant('MONTHS.JUNE'),
            this._translateService.instant('MONTHS.JULY'),
            this._translateService.instant('MONTHS.AUGUST'),
            this._translateService.instant('MONTHS.SEPTEMBER'),
            this._translateService.instant('MONTHS.OCTOBER'),
            this._translateService.instant('MONTHS.NOVEMBER'),
            this._translateService.instant('MONTHS.DECEMBER')
        ];
        let dayNames = [
            this._translateService.instant('DAYS.MONDAY'),
            this._translateService.instant('DAYS.TUESDAY'),
            this._translateService.instant('DAYS.WEDNESDAY'),
            this._translateService.instant('DAYS.THURSDAY'),
            this._translateService.instant('DAYS.FRIDAY'),
            this._translateService.instant('DAYS.SATURDAY'),
            this._translateService.instant('DAYS.SUNDAY'),
        ];
        let d = moment();
        let dia = d.date();
        let mes = d.month();
        let ano = d.year();
        let dayIndex = d.day() - 1;
        if (dayIndex == -1) dayIndex = 6;
        let dayName = dayNames[dayIndex];
        return this._translateService.instant('STUDENT.PERSONALIZED-TEST.DATE-SHOW', {weekDayName: dayName, day: dia, month: meses[mes]});
    }

    getDate() {
        let meses = [
            this._translateService.instant('MONTHS.JANUARY'),
            this._translateService.instant('MONTHS.FEBRUARY'),
            this._translateService.instant('MONTHS.MARCH'),
            this._translateService.instant('MONTHS.APRIL'),
            this._translateService.instant('MONTHS.MAY'),
            this._translateService.instant('MONTHS.JUNE'),
            this._translateService.instant('MONTHS.JULY'),
            this._translateService.instant('MONTHS.AUGUST'),
            this._translateService.instant('MONTHS.SEPTEMBER'),
            this._translateService.instant('MONTHS.OCTOBER'),
            this._translateService.instant('MONTHS.NOVEMBER'),
            this._translateService.instant('MONTHS.DECEMBER')
        ];
        let dayNames = [
            this._translateService.instant('DAYS.MONDAY'),
            this._translateService.instant('DAYS.TUESDAY'),
            this._translateService.instant('DAYS.WEDNESDAY'),
            this._translateService.instant('DAYS.THURSDAY'),
            this._translateService.instant('DAYS.FRIDAY'),
            this._translateService.instant('DAYS.SATURDAY'),
            this._translateService.instant('DAYS.SUNDAY'),
        ];
        let d
        if(this.doneTests.length == 0){
            d = new Date(this.date);
        }
        else {
            d = new Date(this.doneTests.find(x => x.selected).FinishDate);
        }
        
        let dia = d.getDate();
        let mes = d.getMonth();
        let ano = d.getFullYear();
        let dayIndex = d.getDay() - 1;
        if (dayIndex == -1) dayIndex = 6;
        let dayName = dayNames[dayIndex];
        return this._translateService.instant('STUDENT.PERSONALIZED-TEST.DATE-SHOW', {weekDayName: dayName, day: dia, month: meses[mes]});
    }
    getScore() {
        return this.doneTests.find(x => x.selected).Score
    }
    naranja(questionIndex: number){
        if(this.visualize){
            return false;
        }
        if(this.test.MultipleCorrectAnswers){
            return true;
        }
        else {
            if(!this.test.TestQuestion[questionIndex].TestAnswer.some(x => x.userAnswer)){
                return true;
            }
        }
    }
    showMessage(){
        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        this.confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.PERSONALIZED-TEST.CHECK-ACTIVITY.TITLE');
        this.confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.PERSONALIZED-TEST.CHECK-ACTIVITY.MESSAGE');
        this.confirmDialog.componentInstance.space = true;
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.options = [{
            text: this._translateService.instant('ACCEPT'),
            callback: () => {
                this.personalizedTestService.doTest = false
            }
        }];
        this.confirmDialog.afterClosed()
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(result => {
            result();
            this.confirmDialog = null;
        });
    }
}
