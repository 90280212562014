import { GlobalService } from './global.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Exerc, ExerciseJson } from 'app/core/shared/state';
import { Session } from '../core/shared/state/models/session.model';

@Injectable()
export class SessionService {

    baseUrl = environment.baseApi + '/api/Session';
    currentSession: Session;
    reto;

    constructor(private http: HttpClient, private _location: Location, private globalService: GlobalService) {
    }

    getSessionByUserMonthYear(idUser, month, year) {
        return this.http.get(this.baseUrl + '/' + idUser + '/' + month + '/' + year);
    }

    checkSessionCondition(idUser, idClass, activityId){
        return this.http.get(this.baseUrl + '/checkSessionCondition/' + idUser+ "/idClass/" + idClass + "/activityId/" + activityId);
    }

    isTodaySessionFinished(idUser) {
        return this.http.post(this.baseUrl + '/isTodaySessionFinished/' + idUser, {});
    }

    getSession(id) {
        return this.http.get<Session>(this.baseUrl + '/' + id);
    }

    createTmpSession(idUser, onDemand) {
        return this.http.post(this.baseUrl + '/tmp/' + idUser + '/' + onDemand, {});
    };

    insertGlobalResult(idSession) {
        return this.http.post(this.baseUrl + '/insertGlobalResult/' + idSession, {});
    };

    updateSession(session, isAdmin) {
        var aux = {
            IdSession: session.IdSession,
            ExecutionDate: session.ExecutionDate,
            Status: session.Status,
            IsAdmin: isAdmin || false,
        };
        return this.http.put(this.baseUrl + '/' + session.IdSession, aux);
    };

    
    

    canStartSession(idUser, selectedDay, copySession) {
        let params: FormData = new FormData();
        params.append("idUser", idUser)
        params.append("selectedDate", selectedDay)
        params.append("copySession", copySession)
        return this.http.post(this.baseUrl + '/canStart/' + idUser, params);
    };

    retrySession(idUser, idSession) {
        const sessionToRetry = {
            idUSer: idUser,
            idSession: idSession
        };

        return this.http.post(`${this.baseUrl}/RetrySession`, sessionToRetry);
    };

    getActualSchedule() {
        let currentDate = moment().toObject()
        var params = {
            Hours: currentDate.hours,
            Minutes: currentDate.minutes,
            Seconds: currentDate.seconds
        };
        return this.http.post(this.baseUrl + '/getActualSchedule/', params);
    };

    clone(session, sessionToClone) {
        var params = { IdSession: session, IdSessionToClone: sessionToClone };
        return this.http.post(this.baseUrl + '/clone/', params);
    };

    getAllSchedules() {
        return this.http.post(this.baseUrl + '/getAllSchedules/', {});
    };
    getSessionScoreConditionsRanges(){
        return this.http.get(this.baseUrl + "/getSessionScoreConditionsRanges/")
    }

}
