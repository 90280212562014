import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { LicenseService } from 'app/services/license.service';
import { NavigationService } from 'app/services/navigation.service';
import * as moment from 'moment';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialog } from '@angular/material/dialog';
import { PaymentService } from 'app/services/payment.service';
import { UserService } from 'app/services/user.service';
import { combineLatest, of, Subject } from 'rxjs'
import { catchError, take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MyAccountComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  private _previousDiscountCode = null;
  private _previousDiscountOk = true;

  dataLoaded = false;
  step = 1;
  state = 'pending';
  pendingLicenses = [];
  paidLicenses = [];
  error = false;
  discountOk = false;
  calling = false;
  discountPrice = 0;
  finalPrice = 0;
  discountCode = null;
  tpvInfo = null;
  errorDescription = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.ERROR-CODE-INVALID');
  secodndLicenseShow = false;
  currentUser;
  discountDescription;
  students;
  zero = 0.0;
  hours = moment().hours();

  constructor(
    private navigationService: NavigationService,
    private licenseService: LicenseService,
    private matDialog: MatDialog,
    private paymentService: PaymentService,
    private userService: UserService,
    private _translateService: TranslateService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy(): void
   {
       // Unsubscribe from all subscriptions
       this._unsubscribeAll.next();
       this._unsubscribeAll.complete();
   }

  ngOnInit() {
    if (!sessionStorage.getItem("currentUser")) return this.navigationService.exit();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (!this.currentUser) return this.navigationService.exit();
    this.navigationService.enableBackButton(true, this.backHistory.bind(this));
    this.calling = true;
    //this.loadPendingLicenses();
    //this.getStudents();
    this._initSubscritpions();
  }

  private _initSubscritpions() {
    combineLatest([
      this.userService.getStudentsByParentId(this.currentUser.Id, 0).pipe(catchError(_ => { this.errorCallback(); return of('errorHandled'); })),
      this.licenseService.getPendingLicenses(this.currentUser.Id).pipe(catchError(_ => { this.errorCallback(); return of('errorHandled'); })),                   
      this.paymentService.getUserPayments(this.currentUser.Id).pipe(catchError(error => { this.errorCallbackPayments(error); return of('errorHandled'); })),
      this.userService.GetAuthorizedStudentsByParentId(this.currentUser.Id).pipe(catchError(_ => { this.errorCallback(); return of('errorHandled'); })),
    ]).pipe(takeUntil(this._unsubscribeAll), take(1)).subscribe(data => {
      if (data[0] !== 'errorHandled') this.successLoad(data[0]);
      if (data[1] !== 'errorHandled') this.successLoadPendingLicenses(data[1]);
      if (data[2] !== 'errorHandled') this.successLoadPayments(data[2]);
      if (data[3] !== 'errorHandled') this.successLoadAuth(data[3]);
    }, _ => this.navigationService.goLogin());
  }

  backHistory() {
    if(this.step == 1) this.navigationService.go("/school/parentStudents")
    else if(this.step == 2) this.step = 1;
    else if(this.step == 3) this.step = 2;
    else this.step = 1;
  }

  closeTPV(){
    console.log(this.tpvInfo);
    this.paymentService.DeleteUnpayedLicenses(this.tpvInfo.id)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(() => {
        this.navigationService.go("/school/parentStudents")
    },() => {
        this.navigationService.go("/school/parentStudents")
    })
    
  }

  getStudents() {
    this.calling = false;
    this.userService.getStudentsByParentId(this.currentUser.Id, 0)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoad.bind(this), this.errorCallback.bind(this));
  }

  successLoad(response) {
    this.calling = false;
    this.students = [];
    for (let i = 0; i < response.length; i++) {
      if (response[i].UserName.toUpperCase().includes(".TMP.") == false) this.students.push(response[i]);
    }
    /*this.userService.GetAuthorizedStudentsByParentId(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoadAuth.bind(this), this.errorCallback.bind(this));*/
  }

  successLoadAuth(response) {
    this.calling = false;
    if (response != null) {
      let authUsers = response;
      for (let i = 0; i < authUsers.length; i++) authUsers[i].AuthUser = true;
      let noClasses = false;
      if (!this.currentUser.IdSchool || noClasses) {
        for (let i = 0; i < authUsers.length; i++) this.students.push(authUsers[i]);
      }
    }
    this.students.sort(function (a, b) {
      return a.UserName - b.UserName;
    });
    this.dataLoaded = true;
  }


  loadPendingLicenses() {
    this.calling = true;
    this.licenseService.getPendingLicenses(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoadPendingLicenses.bind(this), this.errorCallback.bind(this));
  }

  totalAmount() {
    if (this.pendingLicenses == null)
      return 0;
    var amount = 0;
    for (var i = 0; i < this.pendingLicenses.length; i++) {
      if (this.pendingLicenses[i].selected == true)
        amount = amount + this.pendingLicenses[i].LicenseType.Price;
    }

    return Math.round(amount * 100) / 100;
  }

  totalAmountWithDisc() {
    var amount = 0;
    for (var i = 0; i < this.pendingLicenses.length; i++) {
      if (this.pendingLicenses[i].selected == true)
        amount = amount + this.pendingLicenses[i].Price;
    }

    return Math.round(amount * 100) / 100;
  }

    formatDate(date: any) {
        //console.log(moment.isMoment(date))
        if (moment.isMoment(date)) {
            return date.format('DD/MM/YYYY');
        }
        return moment(date).format('DD/MM/YYYY');
    }

  formatStatus(paid) {
    if (paid.Paid == true)
      return this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.PAYED');
    return this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.PAY-ERROR');
  }

  continue() {
    this.step = 2;
    this.finalPrice = this.totalAmountWithDisc();
  }

  moreLicenses() {
    this.navigationService.go("/school/parentStudents")
  }

  newUser() {
    this.navigationService.go("/school/parentStudents/newStudent")
  }
    sumHours(date: any) {
    return moment(date).utc().hour(this.hours)
  }
  validateCode() {
    if (this.calling)
      return;
    if (this.discountPrice != 0) {
      var message = [];

      message[0] = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.MODAL-DISCOUNT-TITLE');
      message[1] = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.MODAL-DISCOUNT-MESSAGE');

      let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
      });
      confirmDialog.componentInstance.title = message[0];
      confirmDialog.componentInstance.message1 = message[1];
      confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
      confirmDialog.componentInstance.margin = false;
      confirmDialog.componentInstance.margin = false;
      confirmDialog.componentInstance.theme = "white";
      confirmDialog.componentInstance.options = [
        {
          text: this._translateService.instant('ACCEPT'),
          callback: () => {
            this.calling = true;
            this.calling = true;
            this.error = false;
            var licenses = [];
            for (var i = 0; i < this.pendingLicenses.length; i++) {
              if (this.pendingLicenses[i].selected == true)
                licenses.push({ idUser: this.pendingLicenses[i].IdUser, startDate: this.pendingLicenses[i].StartDate });
            }

            this.paymentService.applyDiscount(this.currentUser.Id, this.discountCode, licenses)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successValidation.bind(this), this.errorCallbackValidation.bind(this));
          }
        }, {
          text: this._translateService.instant('CANCEL'),
          callback: () => {
            this.discountCode = null;
          }
        }];
      confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
        result();
        confirmDialog = null;
      });
    } else {
      var licenses = [];
      for (var i = 0; i < this.pendingLicenses.length; i++) {
        if (this.pendingLicenses[i].selected == true)
          licenses.push({ idUser: this.pendingLicenses[i].IdUser, startDate: this.pendingLicenses[i].StartDate });
      }

      this.paymentService.applyDiscount(this.currentUser.Id, this.discountCode, licenses)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successValidation.bind(this), this.errorCallbackValidation.bind(this));
    }
  };

  pay() {
    if (this.calling)
      return;
    this.calling = true;
    var licenses = [];
    for (var i = 0; i < this.pendingLicenses.length; i++) {
      if (this.pendingLicenses[i].selected == true)
        licenses.push({ idUser: this.pendingLicenses[i].IdUser, startDate: this.pendingLicenses[i].StartDate });
    }
      //console.log(this.pendingLicenses)
    //console.log(licenses)
    this.paymentService.pay(this.currentUser.Id, this.discountOk ? this.discountCode : this._previousDiscountOk ? this._previousDiscountCode : null, licenses)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successPay.bind(this), this.errorCallbackPayments.bind(this));
  }

  cancel() {
    if (this.calling)
      return;
    this.calling = true;
    const licensestoCancel = (this.pendingLicenses || []).filter(l => l.selected);

    console.clear();
    console.log(licensestoCancel.length);
    if (licensestoCancel.length === 0) {      
      this.calling = false;

      return;
    }

    for (var i = 0; i < licensestoCancel.length; i++) {
        var idUser = this.pendingLicenses[i].IdUser;
        var startDate = this.pendingLicenses[i].StartDate;
        this.paymentService.cancel(this.currentUser.Id, idUser, startDate).pipe(takeUntil(this._unsubscribeAll)).subscribe(this.loadPendingLicenses.bind(this), this.errorCallbackPayments.bind(this));
    }
  }

  disablePay() {
    if (this.calling)
      return true;
    if (this.discountCode != null && this.discountCode != '' && this.discountOk != true && !this._previousDiscountOk)
      return true;
    return false;
  }

  successValidation(response) {
    this.calling = false;
    if (response.initialPrice != this.totalAmount()) {
      return;
    }
    this.discountOk = true;
    this._previousDiscountCode = this.discountCode;
    this._previousDiscountOk = true;
    this.discountPrice = response.discountPrice;
    this.finalPrice = response.finalPrice;
    this.errorDescription = '';
      this.discountDescription = response.description;
  }
  errorCallbackValidation(response) {
    this.calling = false;
    this.error = true;
    this.discountOk = false;
    //this.discountPrice = 0;
    //this.finalPrice = this.totalAmount();
    if (response.error.code == 'AlreadyUsed')
      this.errorDescription = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.ERROR-CODE-ALREADY-USED');
    else if (response.error.code == 'TooMuchStudents')
      this.errorDescription = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.ERROR-CODE-TOO-MUCH-STUDENTS');
    else if (response.error.code == 'TooMuchUsages')
      this.errorDescription = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.ERROR-CODE-ALREADY-USED');
    else
      this.errorDescription = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.ERROR-CODE-INVALID');
  };

  successPay(response) {

    this.calling = false;
    this.dataLoaded = true;
    if (response == "") {
      // $state.go("parentMenu.paymentOk");             
    } else {
      this.tpvInfo = response;
      this.step = 3;
    }
  }


  successLoadPendingLicenses(response) {
    if (response == null) {
      this.navigationService.goLogin();
    }
    else {
      this.discountPrice = parseFloat(response.discount);
      this.pendingLicenses = response.licenses;
      for (var i = 0; i < this.pendingLicenses.length; i++) {
        this.pendingLicenses[i].selected = true;
        this.pendingLicenses[i].StartDate = this.sumHours(this.pendingLicenses[i].StartDate);
        this.pendingLicenses[i].EndDate = this.sumHours(this.pendingLicenses[i].EndDate);
      }
      console.log(this.pendingLicenses);

      if (this.pendingLicenses.length > 0 && this.students != null && this.pendingLicenses.length != this.students.length && this.secodndLicenseShow == false) {
        let discountOffer = 0;

        if (response.discountOffer)
          discountOffer = response.discountOffer;

        if (discountOffer <= 0) {
          return
        }

        this.secodndLicenseShow = true;
        let message = [];

        message[0] = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.MODAL-SUCCESS-PAY-DISCOUNT-TITLE');
        message[1] = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.MODAL-SUCCESS-PAY-DISCOUNT-MESSAGE', {discountOffer});
        message[2] = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.MODAL-SUCCESS-PAY-DISCOUNT-MESSAGE2');

        let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
          disableClose: true
        });

        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.theme = "white";

        confirmDialog.componentInstance.options = [
        {
          text: this._translateService.instant('ACCEPT'),
          callback: () => {
            this.calling = true;
            this.moreLicenses();
          }
        }, {
          text: this._translateService.instant('CANCEL'),
          callback: () => {
            this.calling = false;
          }
        }];

        confirmDialog.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
          result();
          confirmDialog = null;
        });

        return;
      } else {
        this.calling = false;
      }
      /*this.paymentService.getUserPayments(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoadPayments.bind(this), this.errorCallbackPayments.bind(this));*/
    }
  };

  successCallbackUpdateLicense() {
    this.loadPendingLicenses();
  }

  errorCallback() {
    this.navigationService.goLogin();
  };

  successLoadPayments(response) {
    this.calling = false;
    if (response == null) {
      this.navigationService.goLogin();
    }
    else {
      this.paidLicenses = response;
      if (this.paidLicenses != null)
        for (var i = 0; i < this.paidLicenses.length; i++) {
          this.paidLicenses[i].InitialAmount = Math.round(this.paidLicenses[i].InitialAmount * 100) / 100;
          this.paidLicenses[i].DiscountAmount = Math.round(this.paidLicenses[i].DiscountAmount * 100) / 100;
          this.paidLicenses[i].FinalAmount = Math.round(this.paidLicenses[i].FinalAmount * 100) / 100;
        }

      // if ($stateParams.directPay == true) {
      //     this.continue();
      //     this.pay();
      // }
      else
        this.dataLoaded = true;
    }
  };

  errorCallbackPayments(response) {
    debugger;
    this.calling = false;
    if (response != null &&
      (response.error.code == 'InvalidCode'
        || response.error.code == 'AlreadyUsed'
        || response.error.code == 'InvalidLicense'
        || response.error.code == 'TooMuchStudents'
        || response.error.code == 'TooMuchUsages')) {
      this.error = true;
      this.errorDescription = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.ERROR-CODE-INVALID');
      this.discountOk = false;
      this._previousDiscountOk = false;
      this._previousDiscountCode = null;
      this.discountPrice = 0;
      this.finalPrice = this.totalAmount();
      if (response.error.code == 'TooMuchStudents')
        this.errorDescription = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.ERROR-CODE-TOO-MUCH-STUDENTS');
      if (response.error.code == 'TooMuchUsages')
        this.errorDescription = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.ERROR-CODE-ALREADY-USED');
    }
    else
      this.navigationService.goLogin();
  };

  viewPending() {
    this.state = "pending";
  };
  viewPaid() {
    this.state = "paid";
  };

}
