<div class="walinwa-box walinwa-box-green walinwa-100" *ngIf="dataLoaded" fxNgClass.gt-sm="scroll_vertical">

    <div class="walinwa-box-header" *ngIf="!extraFields">
        {{'SCHOOL.EDIT-STUDENT' | translate}} '{{ student.UserName }}'
    </div>

    <div class="walinwa-box-header" *ngIf="extraFields">
        {{'SCHOOL.STUDENT-OPTIONS' | translate}} '{{ student.UserName }}'
    </div>

    <form [formGroup]="formDataStudent" class="walinwa-box-content" *ngIf="formDataStudent" autocomplete="new-password" fxLayout="column">
        <div *ngIf="!extraFields" fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
            <div fxFlex="15rem" fxFlex.xs="100%">
                <div class="content-avatar" id="avatar-download">
                    <!-- <avatar-file *ngIf="activeItems" [activeItems]=activeItems (click)="saveAvatar()"
                        calss="pointer"></avatar-file> -->
                    <avatar-file *ngIf="activeItems && activeItems.length > 0" [activeItems]=activeItems (click)="saveAvatar()" class="pointer" style="position: relative; z-index: 2;"></avatar-file>
                    <img *ngIf="activeItems.length == 0" src="https://walinwa.blob.core.windows.net/images/School/avatar-default.svg?ngsw-bypass=true" style="position: relative; z-index: 2;" />
                </div>
            </div>
            <div fxFlex="" fxFlex.xs="100%" fxLayout="column" fxLayputAlign="stretch">
                <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="serialize_fields speHeightFix">
                    <div>                        
                        <mat-form-field>
                            <label class="font_blue"><b>{{'NAME' | translate}}</b></label>
                            <input maxlength="41" formControlName="Name" matInput type="text" class="form-control">
                            <mat-error *ngIf="formDataStudent.get('Name').hasError('required')">
                                {{'FIELD-REQUIRED' | translate}}
                            </mat-error>
                            <mat-error *ngIf="formDataStudent.get('Name').hasError('pattern')">
                                {{'PATTERN-ERROR' | translate}}
                            </mat-error>
                            <mat-error *ngIf="formDataStudent.get('Name').hasError('maxlength')">
                                {{'NAME-MAX' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>                        
                        <mat-form-field>
                            <label class="font_blue"><b>{{'FAMILYNAME' | translate}}</b></label>
                            <!-- <mat-label>{{'FAMILYNAME' | translate}}</mat-label> -->
                            <input maxlength="51" formControlName="FamilyName" matInput type="text" class="form-control">
                            <mat-error *ngIf="formDataStudent.get('FamilyName').hasError('pattern')">
                                {{'PATTERN-ERROR' | translate}}
                            </mat-error>
                            <mat-error *ngIf="formDataStudent.get('FamilyName').hasError('maxlength')">
                                {{'FAMILYNAME-MAX' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="serialize_fields speHeightFix">
                    <div style="position: relative;">
                        <mat-form-field>
                            <label class="font_blue" (click)="showStudentId=!showStudentId">
                                <b>{{'USERNAME' | translate}}</b>
                            </label>
                            <div *ngIf="showStudentId" style="left: 5rem; z-index: 1; height: 4em; width: 15em;"
                                class="walinwa-tooltip walinwa-tooltip-right">
                                <div class="walinwa-tooltip-info walinwa-tooltip-info-primary" style="font-size: 120%">
                                    {{'SCHOOL.USER-ID' | translate}} {{ student.Id }}
                                </div>
                            </div>
                            <input id="studentUserName" formControlName="Username" matInput type="text"
                                class="form-control">
                        </mat-form-field>
                        <div class="ww_custom_error">
                            <mat-error *ngIf="formDataStudent.get('Username').hasError('required')">
                                {{ 'FIELD-REQUIRED' | translate }}
                            </mat-error>
                            <mat-error *ngIf="formDataStudent.get('Username').hasError('unique')">
                                {{'AUTHENTICATION.ERROR.USERNAME' | translate}}
                            </mat-error>
                            <mat-error *ngIf="formDataStudent.get('Username').hasError('length')">
                                {{'USERNAME-MIN-MAX' | translate}}
                            </mat-error>
                            <mat-error *ngIf="formDataStudent.get('Username').hasError('pattern')">
                                {{'PATTERN-ERROR' | translate}}
                            </mat-error>
                        </div>
                    </div>
                    <div>                       
                        <mat-form-field>
                            <label class="font_blue"><b>{{'ALIAS' | translate}}</b></label>
                            <!-- <mat-label>{{'ALIAS' | translate}}<sup></sup></mat-label> -->
                            <input maxlength="51" formControlName="Alias" matInput type="text" class="form-control">
                            <mat-error *ngIf="formDataStudent.get('Alias').hasError('maxlength')">
                                {{'ALIAS-MAX' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="serialize_fields speHeightFix">
                    <div>
                        <mat-form-field>
                            <label class="font_blue">{{'SCHOOL.COURSE' | translate}}</label>
                            <mat-select formControlName="Level" [ngModelOptions]="{standalone: true}">
                                <mat-option class="scrollable" [value]="course.Level"
                                    *ngFor="let course of courses | sortBy:'asc':'Level'">{{course.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- <label>{{'SCHOOL.COURSE' | translate}}<sup></sup></label>
                            <select formControlName="Level" class="select">
                                <option [value]="course.Level" *ngFor="let course of courses | sortBy:'asc':'Level'">{{ course.Name }}
                                </option>
                            </select> -->
                    </div>
                    <div [hidden]="!canEditClass">
                        <mat-form-field>
                            <label class="font_blue">{{'CLASS' | translate}}</label>
                            <mat-select formControlName="IdClass" [ngModelOptions]="{standalone: true}">
                                <mat-option class="scrollable" [value]="class.IdClass"
                                    *ngFor="let class of classes | sortBy:'asc':['Level','Code']">{{
                                    class.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- <label>Aula<sup></sup></label>
                            <select formControlName="IdClass" class="select">
                                <option [value]="class.IdClass" *ngFor="let class of classes | sortBy:'asc':'Level'">{{ class.Name }}
                                </option>
                            </select> -->
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" *ngIf="!extraFields">
            <div fxFlex="15rem" fxFlex.xs="100%" fxShow fxHide.xs></div>
            <div fxFlex="" fxLayout="row wrap" fxLayoutAlign="end start" fxLayoutAlign.xs="center center" class="spe_gap">
                <button *ngIf="!extraFields" [disabled]="calling" (click)="changeExtraFields()" class="new_but azul">
                    {{'SCHOOL.OPTIONS' | translate}}
                </button>
                <button [disabled]="calling" (click)="updateStudent()" class="new_but azul">
                    {{'SAVE' | translate}}
                </button>
            </div>
        </div>

        <div *ngIf="extraFields" fxLayout="column" fxLayputAlign="stretch" class="spe_gap">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="spe_gap" [hidden]="!extraFields">
                <div fxFlex="12rem" fxFlex.xs="100%" style="margin-bottom: 10px;">
                    <label class="font_blue"><b>{{'SCHOOL.MULTIME' | translate}}</b></label>
                </div>
                <div fxFlex="" fxFlex.xs="100%" class="custom-form-field">
                    <mat-form-field class="spe_drop_down">
                        <mat-select formControlName="MultTime" class="select">
                            <mat-option class="scrollable" [value]="multiTime.value"
                                *ngFor="let multiTime of multiTimes">{{multiTime.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <select formControlName="MultTime" class="select">
                        <option [value]="multiTime.value" *ngFor="let multiTime of multiTimes">
                            {{ multiTime.name }}
                        </option>
                    </select> -->
                </div>
            </div>
            <div [hidden]="!extraFields">
                <mat-checkbox [(ngModel)]="student.SmallSession" [ngModelOptions]="{standalone: true}">{{'SCHOOL.SMALLSESSION' | translate}}</mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.SMALLSESSION-INFO' | translate"></tooltip>
            </div>
            <div [hidden]="!extraFields">
                <mat-checkbox [(ngModel)]="student.BlockName" [ngModelOptions]="{standalone: true}">{{'SCHOOL.BLOCKNAME' | translate}}</mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.BLOCKNAME-INFO' | translate"></tooltip>
            </div>
            <div [hidden]="!extraFields">
                <mat-checkbox [(ngModel)]="student.GiftShopDisabled" [ngModelOptions]="{standalone: true}">{{'SCHOOL.GIFTSHOPDISABLED' | translate}}</mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.GIFTSHOPDISABLED-INFO' | translate"></tooltip>
            </div>
            <div [hidden]="!extraFields">
                <mat-checkbox [(ngModel)]="student.BlockMessageGift" [ngModelOptions]="{standalone: true}">{{'SCHOOL.BLOCKMESSAGEGIFT' | translate}}</mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.BLOCKMESSAGEGIFT-INFO' | translate"></tooltip>
            </div>
        </div>
        <div *ngIf="extraFields" fxLayout="row" fxLayoutAlign="end center">
            <button [disabled]="calling" (click)="updateStudent()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>
        </div>

    </form>

</div>