<div class="walinwa-box">

    <div class="walinwa-box-content" id="editSessionContainer">
        <div fxLayout="column" fxLayoutAlign="stretch" class="spe_gap">
            <div *ngIf="!porRangoFechas" [innerHtml]="'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.TITLE1' | translate">
            </div>
            <div *ngIf="porRangoFechas">
                {{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.TITLE2' | translate }}
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start stretch" class="spe_gap">
                <div fxFlex="100%" fxFlex.gt-xs="300px">
                    <div [hidden]="!porRangoFechas">
                        <div class="">
                            <mat-form-field>
                                <label class="font_blue"><b>{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.FROM' | translate }}</b></label>
                                <input matInput [matDatepicker]="picker1" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="rangoFechaDesde" [min]="minDateDesde" (dateChange)="valueChanged()">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="">
                            <mat-form-field>
                                <label class="font_blue"><b>{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.TO' | translate }}</b></label>
                                <input matInput [matDatepicker]="picker2" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="rangoFechaHasta" [min]="minDateHasta">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <calCustomizedSession [hidden]="porRangoFechas"
                        class="calendario dinamico walCalendar azul ww_customCalendar" [parent]="this"
                        [currentUser]="currentUser" [selectedDay]="selectedDay" [disablePast]="true" [currentMonth]="currentMonth"
                        [isCustom]="true" [customizedSessions]="filteredCustomSessions">
                    </calCustomizedSession>
                </div>
                <div fxFlex="100%" fxFlex.gt-xs="300px" class="makeScroll">
                    <mat-checkbox *ngFor="let class of classes | sortBy:'asc':['Level','Code']"
                        [checked]="class.selected" (change)="changeClass(class)">
                        <span [ngClass]="{ underline: hasSession(class.IdClass) }">{{ class.Name }}</span>
                    </mat-checkbox>
                </div>
                <div fxFlex="100%" fxFlex.gt-xs="300px" class="error-message">
                    <mat-error *ngIf="showMessage">
                        {{message}}
                    </mat-error>
                </div>
            </div>
            <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="stretch"
                fxLayoutAlign.gt-sm="space-between start" class="less_margin">
                <div fxFlex.gt-sm="0 0 calc(50%-1rem)" fxLayout="row wrap" fxLayoutAlign="space-between center"
                    fxNgClass.gt-sm="spe_gap" class="">
                    <div fxFlex="100%" fxFlex.gt-sm="8.75rem" style="margin-bottom: 10px;">
                        <label class="font_blue"><b>{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.PRIMARY-THEME' | translate }}</b></label>
                    </div>
                    <div fxFlex="100%" fxFlex.gt-sm="">
                        <mat-form-field>
                            <mat-select [disabled]="disabledBlocks()" [(ngModel)]="formData.FP" name="FP">
                                <mat-option class="scrollable" value="" selected>{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.WALINWA-METHOD' | translate }}</mat-option>
                                <mat-option class="scrollable" value="NO">{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.NO-THEME' | translate }}</mat-option>
                                <mat-option class="scrollable" *ngFor="let theme of themesFP" [value]="theme.Theme"><span [innerHtml]="replace(theme.Description)"></span></mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex.gt-sm="0 0 calc(50%-1rem)" fxLayout="row wrap" fxLayoutAlign="space-between center"
                    fxNgClass.gt-sm="spe_gap" class="">
                    <div fxFlex="100%" fxFlex.gt-sm="10rem" fxFlex.gt-md="8.75rem" style="margin-bottom: 10px;">
                        <label class="font_blue"><b>{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.SECONDARY-THEME' | translate }}</b></label>
                    </div>
                    <div fxFlex="100%" fxFlex.gt-sm="">
                        <mat-form-field>
                            <mat-select [disabled]="disabledBlocks()" [(ngModel)]="formData.FS" name="FS">
                                <mat-option class="scrollable" value="" selected>{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.WALINWA-METHOD' | translate }}</mat-option>
                                <mat-option class="scrollable" value="NO">{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.NO-THEME' | translate }}</mat-option>
                                <mat-option class="scrollable" *ngFor="let theme of themesFS" [value]="theme.Theme"><span [innerHtml]="replace(theme.Description)"></span></mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex.gt-sm="0 0 calc(50%-1rem)" fxLayout="row wrap" fxLayoutAlign="space-between center"
                    fxNgClass.gt-sm="spe_gap" class="less_margin" >
                    <div fxFlex="100%" fxFlex.gt-sm="8.75rem" style="margin-bottom: 10px;">
                        <label class="font_blue"><b>{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.ACCENTUATION' | translate }}</b></label>
                    </div>
                    <div fxFlex="100%" fxFlex.gt-sm="">
                        <mat-form-field>
                            <mat-select [disabled]="disabledBlocks()" [(ngModel)]="formData.FT" name="FT">
                                <mat-option class="scrollable" value="" selected>{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.WALINWA-METHOD' | translate }}</mat-option>
                                <mat-option class="scrollable" value="NO">{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.NO-THEME' | translate }}</mat-option>
                                <mat-option class="scrollable" *ngFor="let theme of themesFT" [value]="theme.Theme"><span [innerHtml]="replace(theme.Description)"></span></mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex.gt-sm="0 0 calc(50%-1rem)" fxLayout="row wrap" fxLayoutAlign="space-between center"
                    fxNgClass.gt-sm="spe_gap" class="less_margin">
                    <div fxFlex="100%" fxFlex.gt-sm="10rem" fxFlex.gt-md="8.75rem" style="margin-bottom: 10px;">
                        <label class="font_blue"><b>{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.GRAMMATICAL-THEME' | translate }}</b></label>
                    </div>
                    <div fxFlex="100%" fxFlex.gt-sm="">
                        <mat-form-field>
                            <mat-select [disabled]="disabledBlocks()" [(ngModel)]="formData.GR" name="GR">
                                <mat-option class="scrollable" value="" selected>{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.WALINWA-METHOD' | translate }}</mat-option>
                                <mat-option class="scrollable" value="NO">{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.NO-THEME' | translate }}</mat-option>
                                <mat-option class="scrollable" *ngFor="let theme of themesGR" [value]="theme.Theme"><span [innerHtml]="replace(theme.Description)"></span></mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap less_margin" >

            <button (click)="togglePorRangoFechas()" [hidden]="!porRangoFechas" class="new_but azul">
                {{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.BY-DATE' | translate }}
            </button>

            <button (click)="seleccionarRangoFechas()" [disabled]="!canSave()" [hidden]="!porRangoFechas"
            class="new_but azul">
            {{'SAVE' | translate}}
            </button>

            <button (click)="togglePorRangoFechas()" [hidden]="porRangoFechas" class="new_but azul">
                {{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS.BY-DATE-RANGE' | translate }}
            </button>

            <button (click)="save()" [hidden]="porRangoFechas" [disabled]="!canSave()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>

        </div>

    </div>

</div>