import { WaliLeeService } from './../../services/wali-lee.service';
import { WaliLetrasService } from './../../services/wali-letras.service';
import { RetoService } from './../../services/reto.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AvatarItem } from './../../core/shared/state/models/avatar-item.model';
import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewEncapsulation, HostListener, isDevMode, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { AdminMessageService } from 'app/services/adminMessages.service';
import { AuthService } from 'app/services/auth.service';
import { CoinService } from 'app/services/coin.service';
import { NavigationService } from 'app/services/navigation.service';
import { SchoolService } from 'app/services/school.service';
import { ShopService } from 'app/services/shop.service';
import { TourWordService } from 'app/services/tour-word.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TestExerciseService } from 'app/services/test-exercise.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from 'app/services/session.service';
import { DateAdapter } from '@angular/material/core';
import { ExtraRewardService } from 'app/services/extra-reward.service';
import { AnnouncementService } from 'app/services/announcement.service';
import { ExerciseService } from 'app/services/exercise.service';
import { CurrentSessionStatusEnum } from 'app/core/shared/enums/current-session-status.enum';
import { GlobalService } from 'app/services/global.service';
import { VersionService } from 'app/services/version.service';
import { AvatarShopService } from 'app/services/avatar-shop.service';
import { VisualMemoryService } from 'app/services/visual-memory.service';
import { environment } from 'environments/environment';
import { HelpService } from 'app/services/help.service';
import { FileService } from 'app/services/file.service';
import { FindUrlPipe } from 'app/pipes/find-url.pipe';
import { Subject, combineLatest } from 'rxjs'
import { map, take, takeUntil } from 'rxjs/operators';
import { CustomizedSessionService } from 'app/services/customized-session.service';
import { CustomSession } from 'app/core/shared/state';
import { SharedService } from 'app/core/shared/shared.service';
import { DailyTextService } from 'app/services/daily-text.service';
import { RouterOutlet } from '@angular/router';
import { ReadingTextComponent } from './reading-text/reading-text.component';
import { _Header } from './_header/_header';
import { TranslateService } from '@ngx-translate/core';

declare var $;

@Component({
    selector: "student",
    templateUrl: "./student.component.html",
    styleUrls: ["./student.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class StudentComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  
  @ViewChild('routerOutlet') routerOutlet: RouterOutlet;
  public template: TemplateRef<any> | null = null;

  availableAvatarItems: AvatarItem[] = [];
  boughtItems;
  activeItems;
  currentUser;
  maxDate;
  idReward;
  idRewardBirthDay;
  calling;
  dataLoaded;
  course;
  hideHeader;
  changeBlue;
  showMessages;
  askBirthday;
  askPassword;
  showPassword1;
  showPassword2;
  showPassword3;
  itemsLoaded;
  //retoEnabled;
  retoMsg;
  contactEnabled;
  christmas;
  currentStep;
  steps;
  showPresentIndex;
  //readingTextEnabled: boolean;
  //deductionTextEnabled: boolean;
  //HearingTextEnabled: boolean;
  //dictationEnabled;
  //MemoImageEnabled;
  tourWords;
  showTourTooltip;
  tourPhase;
  tourPhaseMsg;
  tourPhaseTooltip;
  showLastTourWordUserScore;
  licenseInfo;
  newPassword;
  showBirthday;
  todayBirthday;
  activeOthers;
  availableItems;
  adminLogin;
  showRetoMessage;
  showRetoEndMessage;
  showRetoGuestMessage;
  timer;
  extraReward;
  smDemo;
  demoUser;
  walinwosChest;
  school;
  checkClaveActual;
  newBirthdayError;
  retoMsg2;
  retoMsg2Alt;
  retoMsgAlt;
  adminMessages;
  teacherMessages;
  showReward;
  rewardImg;
  rewardText;
  showTeacherMessages;
  showAdminMessages;
  overlay;
  popup;
  showPopUp;
  showPopUpBook;
  bookName;
  announcement;
  showHelp = false;
  showReview = false;
  showTourWords = false;
  draggingMedal;
  maxDateDB
  avatarActive = false;
  avatarShopVisible = false;
  checked_announcements = false;
  status: boolean = false;
  help;
  isProd = environment.production;
    private _waliLeeSubject: Subject<boolean> = new Subject<boolean>();
    public waliLee$ = this._waliLeeSubject.asObservable();
  // Temp
  // reference1;
  fileUrl = "https://walinwa.blob.core.windows.net/images/Themes/"
  // Custom
  isMobile: boolean;
  isiPad: boolean;
  formData: UntypedFormGroup;
  regExp = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜäÄëËïÏöÖç\s\.\-'ª’,]+$/u
  public helpViewed: boolean = false;


  @HostListener("window:resize", ["$event"])
    onResize(event) {
      setTimeout(() => {
        this.updateHeight();
      }, 750);
  }

  private _retoSubject: Subject<boolean> = new Subject<boolean>();
  public reto$ = this._retoSubject.asObservable();
  public isDevMode = !environment.production;
  public isNotCurrentLocation = (): boolean => location.href.indexOf('/reading-text') === -1 && location.href.indexOf('/deduction') === -1 && location.href.indexOf('/hearing-text') === -1;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private avatarShopService: AvatarShopService,
    public authService: AuthService,
    private tourWordService: TourWordService,
    private userService: UserService,
    private shopService: ShopService,
    private navigationService: NavigationService,
    private schoolService: SchoolService,
    private adminMessageService: AdminMessageService,
    private coinService: CoinService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private exerciseService: ExerciseService,
    private _matDialog: MatDialog,
    private sessionService: SessionService,
    private dateAdapter: DateAdapter<Date>,
    private extraRewardService: ExtraRewardService,
    private adminMessagesService: AdminMessageService,
    private announcementService: AnnouncementService,
    private changeDetector: ChangeDetectorRef,
    public globalService: GlobalService,
    private versionService: VersionService,
    public visualMemoryService : VisualMemoryService,
    private helpService: HelpService,
    private customizedSessionService : CustomizedSessionService,
    public sharedService: SharedService,
    private dailyTextService: DailyTextService,
    private _formBuilder: UntypedFormBuilder,
    private retoService: RetoService,
    private waliLetrasService: WaliLetrasService,
    private waliLeeService: WaliLeeService,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    this.currentUser = this.authService.currentUser
    this.dateAdapter.setLocale('es');
    this.maxDate = new Date();
    this.calling = false;
    this.dataLoaded = false;
    this.course = null;
    this.hideHeader = false;
    this.changeBlue = false;
    this.showMessages = false;
    this.askBirthday = false;
    this.askPassword = false;
    this.showPassword1 = false;
    this.showPassword2 = false;
    this.showPassword3 = false;
    this.itemsLoaded = false;
    //this.retoEnabled = false;
    this.retoMsg = null;
    this.contactEnabled = false;
    this.christmas = false;
    this.currentStep = 1;
    this.steps = 12;
    this.showPresentIndex = null;
    //this.readingTextEnabled = true;
    //this.deductionTextEnabled = true;
    //this.HearingTextEnabled = true;
    //this.dictationEnabled = true;
    this.tourWords = null;
    this.showTourTooltip = false;
    this.tourPhase = 0;
    this.tourPhaseMsg = "";
    this.tourPhaseTooltip = "";
    this.showLastTourWordUserScore = false;
    this.licenseInfo = false;
    this.newPassword = {
      'act': {
        'value': undefined,
        'changed': false
      },
      'p1': {
        'value': undefined,
        'changed': false
      },
      'p2': {
        'value': undefined,
        'changed': false
      }
    };
    this.showBirthday = false;
    this.todayBirthday = false;
    this.activeOthers = [];
    this.availableItems = [];
    this.adminLogin = false;
    this.showRetoMessage = false;
    this.showRetoEndMessage = false;
    this.showRetoGuestMessage = false;
    this.timer = null;
    this.extraReward = false;
    this.smDemo = false;
    this.demoUser = false;
    this.walinwosChest = "empty";
    this.school = false;
    this.checkClaveActual = true;
    this.overlay = document.getElementById("overlay");
    this.popup = document.getElementById("popup");
    this.showPopUp = false;
    iconRegistry.addSvgIcon('gafas_off', sanitizer.bypassSecurityTrustResourceUrl("https://walinwa.blob.core.windows.net/images/gafas1.svg?ngsw-bypass=true"));
    iconRegistry.addSvgIcon('gafas_on', sanitizer.bypassSecurityTrustResourceUrl("https://walinwa.blob.core.windows.net/images/gafas2.svg?ngsw-bypass=true"));
    this.maxDateDB = new Date();

    
  }

    public get currentSessionStatusEnum(): typeof CurrentSessionStatusEnum {
        return this.currentSessionStatuEnum;
    }

  public get currentSessionStatuEnum(): typeof CurrentSessionStatusEnum{
    return CurrentSessionStatusEnum
  }

  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

    ngOnInit() {
        if (!this.authService.check()) return;
        if (this.authService.currentUser.IdClass) {
            this.getTodayCustomSession()
        }

        this.initSubscriptions();
        this.reloadStudentComponent();

        // Detect Only Ipad
        this.isiPad = this.detectiPad();
    }

    public completeMenuChecked$ = combineLatest([
      this.sharedService.dictation_enabled,
      this.sharedService.reading_text_enabled,
      this.sharedService.deduction_text_enabled,
      this.sharedService.hearing_text_enabled,
      this.visualMemoryService.memo_image_enabled,
      this.reto$, this.waliLee$]).pipe(take(1));

    initSubscriptions() {
        window.addEventListener("message", (event) => {
            if (!event || !event.data || !event.data.toString()) return;
            let data = event.data.toString().split(":")[0];
            switch (data) {
                case "game-back":
                    let game = event.data.split(":")[1];
                    this.authService.ReloadUserCoins(() => {
                        this.successReloadCoins();
                        this.navigationService.go("student/games/" + game);
                    });
                    break;
                case "reload-items":
                    this.getBoughtByIdUser();
                    break
                case "avatar-active":
                    this.GetActiveItems();
                    this.PanelAvantarShow()
                    break;
            }
        },);
        /*this.visualMemoryService.memo_image_enabled.pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
            this.MemoImageEnabled = result;
        })*/

        this.sharedService.reload_user_coins.pipe(takeUntil(this._unsubscribeAll)).subscribe((result) => {
            this.authService.ReloadUserCoins(() => {
                this.successReloadCoins();
            });
        })

        this.sharedService.reload_student.pipe(takeUntil(this._unsubscribeAll)).subscribe((result) => {
            if (result) {
                this.reloadStudentComponent()
            }
        })

        this.avatarShopService.ItemBuyed
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.authService.ReloadUserCoins(() => {
                    this.successReloadCoins();
                    this.GetActiveItems();
                });
            });

        this.authService.current_user
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.successReloadCoins();
            })

        this.avatarShopService.currentStatus
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(isActive => {
                this.avatarActive = isActive;
            })
        /*this.sharedService.dictation_enabled
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(dictationEnabled => {
                this.dictationEnabled = dictationEnabled;
            })*/
        /*this.sharedService.reading_text_enabled
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(readingTextEnabled => {
                this.readingTextEnabled = readingTextEnabled;
            })*/
        /*this.sharedService.deduction_text_enabled
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(deductionTextEnabled => {
                this.deductionTextEnabled = deductionTextEnabled;
            })*/
        /*this.sharedService.hearing_text_enabled
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(HearingTextEnabled => {
                this.HearingTextEnabled = HearingTextEnabled;
            })*/
    }

  ngAfterViewInit() {
    setTimeout(() => {
      this.updateHeight();
      // console.log("delay 2.5 second");
    }, 2500);
  }

  detectiPad(): boolean {
    const userAgent = window.navigator.userAgent;
    return /iPad/i.test(userAgent);
  }

  updateHeight() {
    let TooperHeaderElement = document.getElementById(
      "TooperHeaderElement"
    )?.offsetHeight;
    let FotFooterElement =
      document.getElementById("FotFooterElement")?.offsetHeight;
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;

    let mainBoxHeight = windowHeight - TooperHeaderElement - FotFooterElement - 36;

    this.isMobile = this.detectMobile();

    let rootElement = document.documentElement;
    let newClass = "scroll_vertical";

    let style = document.createElement("style");
    style.innerHTML = "." + newClass + " { height:" + mainBoxHeight + "px; }";

    // console.log("TooperHeaderElement: " + TooperHeaderElement + " - FotFooterElement: " + FotFooterElement + " - windowWidth: " + windowWidth + " - windowHeight: " + windowHeight + " - mainBoxHeight: " + mainBoxHeight);

    // if (this.isMobile == true && windowWidth > 743 && windowWidth < 1367) {
      if (windowWidth > 959) {  
        rootElement.appendChild(style);
    } 
  }

  detectMobile(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|android|iphone|ipad|iemobile|wpdesktop/i.test(userAgent);
  }


  getTodayCustomSession(){
    this.customizedSessionService.hasTest(this.authService.currentUser.IdClass, this.authService.currentUser.Id, moment().format('HH:mm'))
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((result : number) => {
        this.sharedService.ChangeCustomSessionHastTest(result);
    })
  }

  reloadStudentComponent(){
    this.checked_announcements = this.authService.checked_announcements
    this.currentUser = this.userService.getCurrentUser()
    this.adminLogin = this.currentUser.AdminLogin
    this.contactEnabled = !this.authService.currentUser.BlockContact
    this.school = this.authService.currentUser.IdSchool != null
    this.announcementService.getAnnouncement(this.authService.currentUser.Id)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(this.successGetAnnouncement.bind(this), this.errorCallback.bind(this));
    this.getBoughtByIdUser();
    this.GetActiveItems();
    this.checkDemo();
    this.getLastBirthday();
    this.getTourWords();
    this.getSchoolPartial();
    this.getPendingMessages();
    this.authService.LoadShopPresents();
    this.successCallbackGetUser();
    this.authService.checked_announcements = true
  }

  public onAvatarShopClose() {
    this.avatarShopVisible = false;
    this.GetActiveItems();
  }

    closeWordsTournament() {
        this.showTourWords = false;
        this.exerciseService.resetInteract();
    }

    initTourWords(step?) {
        if (step) {
            this.tourWords.Step = step;
        }
        this.showTourWords = true;
        setTimeout(() => {
            this.exerciseService.initInteract({
                onmove: (event) => {
                    this.draggingMedal = event.currentTarget.id;
                },
                drop: (event) => {
                    let word = event.currentTarget.id;
                    if (this.tourWords[word].Medal != null) return;
                    if (this.tourWords.Word1.Medal == this.draggingMedal) {
                        this.tourWords.Word1.Medal = null;
                    }
                    if (this.tourWords.Word2.Medal == this.draggingMedal) {
                        this.tourWords.Word2.Medal = null;
                    }
                    if (this.tourWords.Word3.Medal == this.draggingMedal) {
                        this.tourWords.Word3.Medal = null;
                    }
                    this.tourWords[this.tourWords[word].Medal] = null;
                    this.tourWords[word].Medal = this.draggingMedal;
                    this.tourWords[this.draggingMedal] =
                        this.tourWords[word].IdWord;
                    if (
                        this.tourWords.Word1.Medal &&
                        this.tourWords.Word2.Medal &&
                        this.tourWords.Word3.Medal
                    ) {
                        this.setTourWords();
                    }
                },
            });
        }, 200);
    }

    getImg(): string {
        return "https://walinwa.blob.core.windows.net/images/Announcements/" + this.getImgName()+ "?ngsw-bypass=true";
    }

    getImgName(): string {
        //example this.announcement.ImgName = /announcements/avisoAyuda.png
        return this.announcement.ImgName.split("/")[2];
    }

  getPendingMessages() {
    this.adminMessageService.getPendingMessages(this.authService.currentUser.Id, new Date())
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successPendingMessages(result);
      },
      error => {
        this.errorCallback();
      }
    )
  }

  getBoughtByIdUser() {
    this.shopService.getBoughtByIdUser(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successLoadItems(result);
      },
      error => {
        this.itemsLoaded = true;
      },
      () => {
        this.itemsLoaded = true;
      }
    )
  }

  getSchoolPartial() {    
    if (this.school) {
      this.schoolService.getSchoolParcialByUser(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.successGetSchoolParcial(result);
        },
        error => {
          this.errorCallback();
        }
      )
    } else {
      //this.readingTextEnabled = true;
      //this.dictationEnabled = true;
      //this.deductionTextEnabled = true;
      //this.HearingTextEnabled = true;
      this.sharedService.changeDeductionTextEnabled(true);
      this.sharedService.changeDictationEnabled(true);
      this.sharedService.changeReadingTextEnabled(true);
      this.sharedService.changeHearingTextEnabled(true);
      this._retoSubject.next(false);
      this._waliLeeSubject.next(false);
    }
  }

    successGetAnnouncement(response) {
        this.announcement = response;
        this.checkReward();
    }

  checkReward() {
    if (this.announcement) {
      var spCookie;
      if (this.announcement.CookieName) {
        //SpecialDate
        spCookie = sessionStorage.getItem(this.announcement.CookieName + this.authService.currentUser.Id);
        if (spCookie == null && moment().startOf('day').isSame(moment(this.announcement.ReleaseDate).startOf('day'))) {
          this.showPopUp = true;
          var now = new Date(),
            // this will set the expiration to 6 months
            exp = new Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
          sessionStorage.setItem(this.announcement.CookieName + this.authService.currentUser.Id, this.authService.currentUser.Id);
        }
      }
      else if (this.announcement.Book != null) {
        //SpecialDate
        spCookie = sessionStorage.getItem(this.announcement.Book + this.authService.currentUser.Id + moment(this.authService.currentUser.LastRewardDate).year());
        if (spCookie == null) {
          this.showPopUpBook = true;
          var now = new Date(),
            // this will set the expiration to 6 months
            exp = new Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
          sessionStorage.setItem(this.announcement.Book + this.authService.currentUser.Id + moment(this.authService.currentUser.LastRewardDate).year(),
            this.authService.currentUser.Id);
          this.showPopUpBook2(this.announcement.Book);
        }
      }
      else {
        this.showPopUp = true;
        if (!this.adminLogin) this.announcementService.setAnnouncementUser(this.announcement.IdAnnouncement, this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe() 
      }
      // this.announcement = null;
    } else if (this.authService.currentUser.LastRewardDate == null || moment(this.authService.currentUser.LastRewardDate).month() != moment().month()) {
      this.extraRewardService.getLastMonthReward(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.successLastMonthReward(result)
        },
        error => {
          this.errorCallback()
        }
      )
    } else if (this.authService.currentUser.IdReto) {
      this.retoService.getReto(this.authService.currentUser.IdReto)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.successLoadReto(result)
        },
        error => {
          this.errorCallback()
        }
      )
    }
  }

  getLastBirthday() {
    if (this.authService.currentUser.DateOfBirth != null && moment().date() == moment(this.authService.currentUser.DateOfBirth).date() && moment().month() == moment(this.authService.currentUser.DateOfBirth).month()) {
      this.extraRewardService.getLastBirthday(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.successGetLastBirthday(result)
        },
        error => {
          this.errorGetLastBirthday(error)
        }
      )
    }
  }

    checkDemo() {
        let substring = ".tmp.";
        this.smDemo = false;
        this.demoUser =
            this.authService.currentUser.UserName.indexOf(substring) !== -1;
        if (this.demoUser) {
            this.smDemo =
                this.authService.currentUser.UserName.indexOf("SM.tmp") !== -1;
            this.contactEnabled = false;
            this.sharedService.changeDictationEnabled(true)
            //this.dictationEnabled = true;
        } else {
            this.userService.getChristmasDate().subscribe((result: any) => {
                let ChristmasStart = result.ChristmasStart.split("/")
                let ChristmasEnd = result.ChristmasEnd.split("/")
                if (
                    (moment().date() >= parseInt(ChristmasStart[0]) && moment().month() == parseInt(ChristmasStart[1]) - 1) &&
                    (moment().date() <= parseInt(ChristmasEnd[0]) && moment().month() == parseInt(ChristmasEnd[1]) - 1)
                ) {
                    this.christmas = true;
                    console.log(this.christmas)
                }
            })
            
        }
    }

    showNextPresent() {
        if (this.authService.currentUser.ShopPresents_nuevos.length != 0) {
            this.authService.currentUser.ShopPresents_nuevos.splice(
                this.authService.currentUser.ShopPresents_nuevos.length - 1,
                1
            );
            this.currentUser.ShopPresents_nuevos =
                this.authService.currentUser.ShopPresents_nuevos;
            if (this.authService.currentUser.ShopPresents_nuevos.length == 0)
                this.hidePresentsMessage();
        }
    }
    showNextAvatarPresent() {
        if (this.authService.currentUser.AvatarShopPresents_nuevos.length != 0) {
            this.authService.currentUser.AvatarShopPresents_nuevos.splice(
                this.authService.currentUser.AvatarShopPresents_nuevos.length - 1,
                1
            );
            this.currentUser.AvatarShopPresents_nuevos =
                this.authService.currentUser.AvatarShopPresents_nuevos;
            if (this.authService.currentUser.AvatarShopPresents_nuevos.length == 0)
                this.hideAvatarPresentsMessage();
        }
    }

    isWeekend() {
        let now = new Date();
        let dayOfWeek = now.getDay();
        return dayOfWeek == 6 || dayOfWeek == 0;
    }

  setTourWords() {
    if (this.calling) return
    if (!this.tourWords.Gold) return
    if (!this.tourWords.Silver) return
    if (!this.tourWords.Bronze) return
    let params = {
      IdUser: this.authService.currentUser.Id,
      Day: this.tourWords.TourDay,
      Week: this.tourWords.TourWeek,
      IdTour: this.tourWords.IdTour,
      Phase: this.tourWords.Phase,
      Gold: this.tourWords.Gold,
      Silver: this.tourWords.Silver,
      Bronze: this.tourWords.Bronze
    }
    this.calling = true
    this.tourWordService.setTourWords(params)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.tourWords.tourWordUserScore = {}
        this.tourWords.completed = true;
        this.tourWords.FirstTime = false;
        this.calling = false
      }, () => {
        this.calling = false
      });
  }

    getWord(IdWord) {
        if (this.tourWords.Word1.IdWord == IdWord) return this.tourWords.Word1;
        if (this.tourWords.Word2.IdWord == IdWord) return this.tourWords.Word2;
        if (this.tourWords.Word3.IdWord == IdWord) return this.tourWords.Word3;
    }

    showWordInfo(word) {
        this.tourWords[word].ShowInfo = !this.tourWords[word].ShowInfo;
        if (word == "Word1") {
            this.tourWords.Word2.ShowInfo = false;
            this.tourWords.Word3.ShowInfo = false;
        } else if (word == "Word2") {
            this.tourWords.Word1.ShowInfo = false;
            this.tourWords.Word3.ShowInfo = false;
        } else if (word == "Word3") {
            this.tourWords.Word1.ShowInfo = false;
            this.tourWords.Word2.ShowInfo = false;
        }
    }

    showWordInfo2(word) {
        if (this.tourWords.lastTourWordUserScore) {
            this.tourWords.lastTourWordUserScore.GoldWord.ShowInfo = false;
            this.tourWords.lastTourWordUserScore.SilverWord.ShowInfo = false;
            this.tourWords.lastTourWordUserScore.BronzeWord.ShowInfo = false;
        }
        if (!this.tourWords.lastTourWordDayScore[word].ShowInfo)
            this.tourWords.lastTourWordDayScore[word].ShowInfo = false;
        this.tourWords.lastTourWordDayScore[word].ShowInfo =
            !this.tourWords.lastTourWordDayScore[word].ShowInfo;
        if (word == "GoldWord") {
            this.tourWords.lastTourWordDayScore.SilverWord.ShowInfo = false;
            this.tourWords.lastTourWordDayScore.BronzeWord.ShowInfo = false;
        } else if (word == "SilverWord") {
            this.tourWords.lastTourWordDayScore.GoldWord.ShowInfo = false;
            this.tourWords.lastTourWordDayScore.BronzeWord.ShowInfo = false;
        } else if (word == "BronzeWord") {
            this.tourWords.lastTourWordDayScore.GoldWord.ShowInfo = false;
            this.tourWords.lastTourWordDayScore.SilverWord.ShowInfo = false;
        }
    }

    showWordInfo3(word) {
        if (this.tourWords.lastTourWordDayScore) {
            this.tourWords.lastTourWordDayScore.GoldWord.ShowInfo = false;
            this.tourWords.lastTourWordDayScore.SilverWord.ShowInfo = false;
            this.tourWords.lastTourWordDayScore.BronzeWord.ShowInfo = false;
        }
        if (!this.tourWords.lastTourWordUserScore[word].ShowInfo)
            this.tourWords.lastTourWordUserScore[word].ShowInfo = false;
        this.tourWords.lastTourWordUserScore[word].ShowInfo =
            !this.tourWords.lastTourWordUserScore[word].ShowInfo;
        if (word == "GoldWord") {
            this.tourWords.lastTourWordUserScore.SilverWord.ShowInfo = false;
            this.tourWords.lastTourWordUserScore.BronzeWord.ShowInfo = false;
        } else if (word == "SilverWord") {
            this.tourWords.lastTourWordUserScore.GoldWord.ShowInfo = false;
            this.tourWords.lastTourWordUserScore.BronzeWord.ShowInfo = false;
        } else if (word == "BronzeWord") {
            this.tourWords.lastTourWordUserScore.GoldWord.ShowInfo = false;
            this.tourWords.lastTourWordUserScore.SilverWord.ShowInfo = false;
        }
    }

    showWordInfo4(day) {
        if (!this.tourWords[day].ShowInfo) this.tourWords[day].ShowInfo = false;
        this.tourWords[day].ShowInfo = !this.tourWords[day].ShowInfo;
        if (day == "Day1") {
            if (this.tourWords.Day2) this.tourWords.Day2.ShowInfo = false;
            if (this.tourWords.Day3) this.tourWords.Day3.ShowInfo = false;
            if (this.tourWords.Day4) this.tourWords.Day4.ShowInfo = false;
            if (this.tourWords.Day5) this.tourWords.Day5.ShowInfo = false;
        } else if (day == "Day2") {
            if (this.tourWords.Day1) this.tourWords.Day1.ShowInfo = false;
            if (this.tourWords.Day3) this.tourWords.Day3.ShowInfo = false;
            if (this.tourWords.Day4) this.tourWords.Day4.ShowInfo = false;
            if (this.tourWords.Day5) this.tourWords.Day5.ShowInfo = false;
        } else if (day == "Day3") {
            if (this.tourWords.Day1) this.tourWords.Day1.ShowInfo = false;
            if (this.tourWords.Day2) this.tourWords.Day2.ShowInfo = false;
            if (this.tourWords.Day4) this.tourWords.Day4.ShowInfo = false;
            if (this.tourWords.Day5) this.tourWords.Day5.ShowInfo = false;
        } else if (day == "Day4") {
            if (this.tourWords.Day1) this.tourWords.Day1.ShowInfo = false;
            if (this.tourWords.Day2) this.tourWords.Day2.ShowInfo = false;
            if (this.tourWords.Day3) this.tourWords.Day3.ShowInfo = false;
            if (this.tourWords.Day5) this.tourWords.Day5.ShowInfo = false;
        } else if (day == "Day5") {
            if (this.tourWords.Day1) this.tourWords.Day1.ShowInfo = false;
            if (this.tourWords.Day2) this.tourWords.Day2.ShowInfo = false;
            if (this.tourWords.Day3) this.tourWords.Day3.ShowInfo = false;
            if (this.tourWords.Day4) this.tourWords.Day4.ShowInfo = false;
        }
    }

    goToTournament() {
        let url = "https://www.walinwa.com/torneo.htm";
        window.open(url, "_blank");
    }

    changeTourTooltip() {
        this.showTourTooltip = !this.showTourTooltip;
    }

  setTourWordReward() {
    if (this.calling) return
    if (this.tourWords.lastTourWordUserScore.Reclamado) return
    this.calling = true
    this.tourWordService.setTourWordReward(this.authService.currentUser.Id, this.tourWords.lastTourWordUserScore.Walinwos)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.coinService.showCoins(10, () => {
          this.sharedService.changeUserCoins(true)
          this.tourWords.lastTourWordUserScore.Reclamado = true;
          this.showLastTourWordUserScore = false;
          this.calling = false
        }, "btn-reclamar", "coin-plank")
      }, () => {
        this.calling = false
      });
  }

    replaceNbsps(str) {
        let re = new RegExp(String.fromCharCode(160), "g");
        return str.replace(re, " ");
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

  getTourWords() {
    this.tourWordService.getTourWords(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.tourWords = result;
        this.tourWords.Step = 2;
        //this.tourWords.Show = false;
        if (this.tourWords.FirstTime) this.tourWords.Step = 1;
        this.tourWords.IsWeekend = this.isWeekend();
        this.tourPhase = 0;
        this.tourPhaseMsg = "";
        this.tourPhaseTooltip = "";
        this.showTourTooltip = false;
        if (this.tourWords.IsWeekend) {
          if (this.tourWords.Day1) this.tourWords.Day1.Definition = this.replaceNbsps(this.tourWords.Day1.Definition);
          if (this.tourWords.Day2) this.tourWords.Day2.Definition = this.replaceNbsps(this.tourWords.Day2.Definition);
          if (this.tourWords.Day3) this.tourWords.Day3.Definition = this.replaceNbsps(this.tourWords.Day3.Definition);
          if (this.tourWords.Day4) this.tourWords.Day4.Definition = this.replaceNbsps(this.tourWords.Day4.Definition);
          if (this.tourWords.Day5) this.tourWords.Day5.Definition = this.replaceNbsps(this.tourWords.Day5.Definition);
          if (this.tourWords.lastTourWordDayScore != null && this.tourWords.lastTourWordDayScore.Phase != null) {
            this.tourPhase = this.tourWords.lastTourWordDayScore.Phase;
          }
        } else {
          if (this.tourWords) {
            this.tourPhase = this.tourWords.Phase;
            //shuffle words
            let word1 = this.tourWords.Word1;
            let word2 = this.tourWords.Word2;
            let word3 = this.tourWords.Word3;
            let rand1 = this.getRandomInt(1, 4);
            if (rand1 == 1) {
              this.tourWords.Word1 = word1;
              let rand2 = this.getRandomInt(2, 4);
              if (rand2 == 2) {
                this.tourWords.Word2 = word2;
                this.tourWords.Word3 = word3;
              } else {
                this.tourWords.Word2 = word3;
                this.tourWords.Word3 = word2;
              }
            } else if (rand1 == 2) {
              this.tourWords.Word1 = word2;
              let rand2 = this.getRandomInt(2, 4);
              if (rand2 == 2) {
                this.tourWords.Word2 = word1;
                this.tourWords.Word3 = word3;
              } else {
                this.tourWords.Word2 = word3;
                this.tourWords.Word3 = word1;
              }
            } else {
              this.tourWords.Word1 = word3;
              let rand2 = this.getRandomInt(2, 4);
              if (rand2 == 2) {
                this.tourWords.Word2 = word1;
                this.tourWords.Word3 = word2;
              } else {
                this.tourWords.Word2 = word2;
                this.tourWords.Word3 = word1;
              }
            }
            if (this.tourWords.Word1) { this.tourWords.Word1.showInfo = false; this.tourWords.Word1.Definition = this.replaceNbsps(this.tourWords.Word1.Definition); }
            if (this.tourWords.Word2) { this.tourWords.Word2.showInfo = false; this.tourWords.Word2.Definition = this.replaceNbsps(this.tourWords.Word2.Definition); }
            if (this.tourWords.Word3) { this.tourWords.Word3.showInfo = false; this.tourWords.Word3.Definition = this.replaceNbsps(this.tourWords.Word3.Definition); }
            if (this.tourWords.lastTourWordDayScore) {
              this.tourWords.lastTourWordDayScore.GoldWord.Definition = this.replaceNbsps(this.tourWords.lastTourWordDayScore.GoldWord.Definition);
              this.tourWords.lastTourWordDayScore.SilverWord.Definition = this.replaceNbsps(this.tourWords.lastTourWordDayScore.SilverWord.Definition);
              this.tourWords.lastTourWordDayScore.BronzeWord.Definition = this.replaceNbsps(this.tourWords.lastTourWordDayScore.BronzeWord.Definition);
            }
            if (this.tourWords.lastTourWordUserScore) {
              this.tourWords.lastTourWordUserScore.GoldWord.Definition = this.replaceNbsps(this.tourWords.lastTourWordUserScore.GoldWord.Definition);
              this.tourWords.lastTourWordUserScore.SilverWord.Definition = this.replaceNbsps(this.tourWords.lastTourWordUserScore.SilverWord.Definition);
              this.tourWords.lastTourWordUserScore.BronzeWord.Definition = this.replaceNbsps(this.tourWords.lastTourWordUserScore.BronzeWord.Definition);
            }
            if (this.tourWords.tourWordUserScore) {
              this.tourWords.completed = true;
              if (this.tourWords.tourWordUserScore.Gold == this.tourWords.Word1.IdWord) {
                this.tourWords.Word1.Medal = "Gold";
              }
              if (this.tourWords.tourWordUserScore.Gold == this.tourWords.Word2.IdWord) {
                this.tourWords.Word2.Medal = "Gold";
              }
              if (this.tourWords.tourWordUserScore.Gold == this.tourWords.Word3.IdWord) {
                this.tourWords.Word3.Medal = "Gold";
              }
              if (this.tourWords.tourWordUserScore.Silver == this.tourWords.Word1.IdWord) {
                this.tourWords.Word1.Medal = "Silver";
              }
              if (this.tourWords.tourWordUserScore.Silver == this.tourWords.Word2.IdWord) {
                this.tourWords.Word2.Medal = "Silver";
              }
              if (this.tourWords.tourWordUserScore.Silver == this.tourWords.Word3.IdWord) {
                this.tourWords.Word3.Medal = "Silver";
              }
              if (this.tourWords.tourWordUserScore.Bronze == this.tourWords.Word1.IdWord) {
                this.tourWords.Word1.Medal = "Bronze";
              }
              if (this.tourWords.tourWordUserScore.Bronze == this.tourWords.Word2.IdWord) {
                this.tourWords.Word2.Medal = "Bronze";
              }
              if (this.tourWords.tourWordUserScore.Bronze == this.tourWords.Word3.IdWord) {
                this.tourWords.Word3.Medal = "Bronze";
              }
            }
          }
        }
        if (this.tourWords.IsShort) {
            switch (this.tourPhase) {
                case 1:
                    this.tourPhaseMsg = this._translateService.instant('STUDENT.TOUR-WORD.QUALIFICATION-PHASE-TITLE');
                    this.tourPhaseTooltip = this._translateService.instant('STUDENT.TOUR-WORD.QUALIFICATION-PHASE-MESSAGE');
                    break;
                case 2:
                case 3:
                    this.tourPhaseMsg = this._translateService.instant('STUDENT.TOUR-WORD.ELIMINATION-PHASE-TITLE');
                    this.tourPhaseTooltip = this._translateService.instant('STUDENT.TOUR-WORD.ELIMINATION-PHASE-MESSAGE');
                    break;
                case 4:
                    this.tourPhaseMsg = this._translateService.instant('STUDENT.TOUR-WORD.SEMIFINALS-TITLE');
                this.tourPhaseTooltip = this._translateService.instant('STUDENT.TOUR-WORD.SEMIFINALS-MESSAGE');
                    break;
                case 5:
                    this.tourPhaseMsg = this._translateService.instant('STUDENT.TOUR-WORD.FINALS-TITLE');
                this.tourPhaseTooltip = this._translateService.instant('STUDENT.TOUR-WORD.FINALS-MESSAGE');
                    break;
             }
        }
        else {
            switch (this.tourPhase) {
                //case 1:
                //this.tourPhaseMsg = null;
                //this.tourPhaseTooltip = null;
                //break;
                case 1:
                    this.tourPhaseMsg = this._translateService.instant('STUDENT.TOUR-WORD.QUALIFICATION-PHASE-TITLE');
                    this.tourPhaseTooltip = this._translateService.instant('STUDENT.TOUR-WORD.QUALIFICATION-PHASE-MESSAGE');
                    break;
                case 2:
                case 3:
                case 4:
                case 5:
                    this.tourPhaseMsg = this._translateService.instant('STUDENT.TOUR-WORD.ELIMINATION-PHASE-TITLE');
                    this.tourPhaseTooltip = this._translateService.instant('STUDENT.TOUR-WORD.ELIMINATION-PHASE-MESSAGE2');
                    break;
                case 6:
                    this.tourPhaseMsg = this._translateService.instant('STUDENT.TOUR-WORD.FAVOURITE-PHASE-TITLE');
                    this.tourPhaseTooltip = this._translateService.instant('STUDENT.TOUR-WORD.FAVOURITE-PHASE-MESSAGE');
                    break;
                case 7:
                case 8:
                    this.tourPhaseMsg = this._translateService.instant('STUDENT.TOUR-WORD.PRE-SEMIFINALS-TITLE');
                    this.tourPhaseTooltip = this._translateService.instant('STUDENT.TOUR-WORD.PRE-SEMIFINALS-MESSAGE');
                    break;
                case 9:
                    this.tourPhaseMsg = this._translateService.instant('STUDENT.TOUR-WORD.SEMIFINALS-TITLE');
                    this.tourPhaseTooltip = this._translateService.instant('STUDENT.TOUR-WORD.SEMIFINALS-MESSAGE');
                    break;
                case 10:
                    this.tourPhaseMsg = this._translateService.instant('STUDENT.TOUR-WORD.FINALS-TITLE');
                    this.tourPhaseTooltip = this._translateService.instant('STUDENT.TOUR-WORD.FINALS-MESSAGE');
                    break;
            }
        }
        
      }, () => { });
      this.changeDetector.detectChanges()
  }

    formatDate(d) {
        return moment(d).format("DD/MM/YYYY");
    }

    showPass1() {
        this.showPassword1 = !this.showPassword1;
    }

    showPass2() {
        this.showPassword2 = !this.showPassword2;
    }

    showPass3() {
        this.showPassword3 = !this.showPassword3;
    }

  closePopUpBook() {
    this.showPopUpBook = false;
    if (this.authService.currentUser.LastRewardDate == null || moment(this.authService.currentUser.LastRewardDate).month() != moment().month())
      this.extraRewardService.getLastMonthReward(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLastMonthReward.bind(this), this.errorCallback.bind(this));
  }

    oscuro() {
        if (this.availableItems) {
            for (let i = 0; i < this.availableItems.length; i++) {
                if (
                    this.availableItems[i].ShopItem.Type == 8 &&
                    this.availableItems[i].Status == 1
                ) {
                    if (this.availableItems[i].ShopItem.IdCivilization == 3)
                        return true;
                    else return false;
                }
            }
        }
        return false;
    }

  successLoadItems(result) {
    this.itemsLoaded = true;
    this.availableItems = [];
    this.shopService.ChangeUserBoughtItems(result);
    let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    for (let i = 0; i < result.length; i++) {
        if(result[i].IdShopItem == 922 && result[i].Status == 1 && !this.authService.currentUser.AvatarDisabled) {
            this.avatarActive = true;
            this.avatarShopService.setAvatarStatus(this.avatarActive);
          }
      if (result[i].ShopItem.IdCivilization != 9) {
        if (result[i].ShopItem.IdCivilization == this.authService.currentUser.Civilization.IdCivilization) {
          this.availableItems.push(result[i]);
        }
      } else {
        if (new Date(result[i].ShopItem.specialFrom2).getTime() <= today.getTime() && new Date(result[i].ShopItem.specialTo2).getTime() >= today.getTime()) {
          this.availableItems.push(result[i]);
          
        }
      }
    }
    
  }
    getChestStyle() {
        if (!this.itemsLoaded) {
            return;
        }
        let url = `https://walinwa.blob.core.windows.net/images/Student/cofre_walinwos_${this.walinwosChest}.png`+ "?ngsw-bypass=true";
        let url2;
        if (this.availableItems) {
            const filteredItems = this.availableItems.filter(item =>
                item.ShopItem.Type === 7 && item.Status
            ).sort((a, b) => a.IdShopItem + b.IdShopItem);;

            const item = filteredItems[0]

            if (item) {
                if (item.ShopItem.IdCivilization !== 9) {
                    url = `${this.fileUrl}${item.ShopItem.Civilization.ImgFolder}/shop/${item.ShopItem.IdShopItem}_${this.walinwosChest}.png`;
                } else {
                    url = `${this.fileUrl}Special/${item.ShopItem.IdShopItem}_${this.walinwosChest}.gif`;
                    url2 = `${this.fileUrl}Special/${item.ShopItem.IdShopItem}_${this.walinwosChest}.png`;
                }
            }
        }

        const backgroundImage = url2 ? `url(${url}), url(${url2})` : `url(${url})`;

        return {
            "background-image": backgroundImage,
        };
    }

    getTableroStyle() {
        if (!this.itemsLoaded) {
            return;
        }
        if (!this.availableItems) {
            return "https://walinwa.blob.core.windows.net/images/Student/walinwos_panel.png"+ "?ngsw-bypass=true";
        }

        // Filtrar los elementos disponibles por ShopItem.Type y Status
        const filteredItems = this.availableItems.filter(item =>
            item.ShopItem.Type === 8 && item.Status
        ).sort((a, b) => a.IdShopItem - b.IdShopItem);
        const item = filteredItems[0]
        // Construir la URL según el tipo de elemento encontrado
        let url = "";
        if (item) {
            if (item.ShopItem.IdCivilization !== 9) {
                url = `${this.fileUrl}${item.ShopItem.Civilization.ImgFolder}/shop/${item.ShopItem.IdShopItem}.png`;
            } else {
                url = `${this.fileUrl}Special/${item.ShopItem.IdShopItem}.png`;
            }
        }

        // Si no se encontró ningún elemento válido, devolver la URL predeterminada
        return url || "https://walinwa.blob.core.windows.net/images/Student/walinwos_panel.png?ngsw-bypass=true";
    }

    hidePresentsMessage() {
        if (!this.adminLogin) {
            this.userService.viewNewPresents(this.authService.currentUser.Id)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    result => {
                        this.authService.currentUser.ShopPresents_nuevos = [];
                    }, () => { });
        } else {
            this.authService.currentUser.ShopPresents_nuevos = [];
        }
    }
    hideAvatarPresentsMessage() {
        if (!this.adminLogin) {
            this.userService.viewNewAvatarPresents(this.authService.currentUser.Id)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    result => {
                        this.authService.currentUser.AvatarShopPresents_nuevos = [];
                    }, () => { });
        } else {
            this.authService.currentUser.AvatarShopPresents_nuevos = [];
        }
    }

    hideRetoMessage() {
        this.showRetoMessage = false;
        if (!this.adminLogin) {this.setRetoAsRead()}
    }

    hideRetoEndMessage() {
    this.showRetoEndMessage = false;
    if (!this.adminLogin) {this.setRetoAsRead()}
  }
    setRetoAsRead() {
        this.retoService.setRetoAsRead(this.authService.currentUser.Id)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe()
  }
    hideRetoGuestMessage() {
        this.showRetoGuestMessage = false;
        if (!this.adminLogin) {this.setRetoAsRead()}
    }

    

    openHelp() {
        this.showReview = true;
    }

    tutorial() {
      this.showHelp = true;
    }

  changeShow(val: boolean) {
    this.showHelp = val;
    this.showReview = val;
    
    if (!this.authService.currentUser.TutorialDone) {
      this.authService.currentUser.TutorialDone = true;
      sessionStorage.setItem('helpViewed', '1');
      this.userService.UpdateUserTutorial(this.authService.currentUser)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successCallbackUpdateUserTutorial.bind(this), () => { });
    }
  }


  finish() {
    if (this.authService.currentUser.TutorialDone == false) {
      this.calling = true;
      this.authService.currentUser.TutorialDone = true;
      this.userService.UpdateUserTutorial(this.authService.currentUser)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successCallbackUpdateUserTutorial.bind(this), () => { });
    }
}

    successCallbackUpdateUserTutorial() {
        this.authService.currentUser.TutorialDone = true;
    }


    transactions() {
        this.navigationService.go("/student/transactions", {});
    }

    successGetBirthdayReward(result) {
        // result.Shown = false // ojo
        if (result.Shown != true) {
            this.todayBirthday = true;
            this.idRewardBirthDay = result.IdCoinTransaction;
            this.itemsLoaded = false;
            this.getBoughtByIdUser();
        }
    }

  successGetLastBirthday(result) {
    // result.Shown = false // ojo
    if (!result || result.Shown == false/* || result.RewardType != 'CUMPLE_' + moment().year()*/) {
      this.extraRewardService.getBirthdayReward(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.successGetBirthdayReward(result)
        },
        error => {
          this.errorGetBirthdayReward(error)
        }
      )
    } else {
      let date_last = new Date(result.Date);
      //let year_date_last = date_last.getFullYear();
      let month_date_last = date_last.getMonth();
      let day_date_last = date_last.getDate();
      let date_act = new Date();
      //let year_date_act = date_act.getFullYear();
      let month_date_act = date_act.getMonth();
      let day_date_act = date_act.getDate();
      //if (year_date_act == year_date_last) {
        if (month_date_act != month_date_last || day_date_act != day_date_last) {
          //let birthCookie = sessionStorage.getItem('bithday' + this.authService.currentUser.Id + day_date_act + month_date_act + year_date_act);
          //if (birthCookie == null) {
            //sessionStorage.setItem('bithday' + this.authService.currentUser.Id + day_date_act + month_date_act + year_date_act, this.authService.currentUser.Id);
            let message = [];
            message[0] = this._translateService.instant('STUDENT.MODAL-BIRTHDAY-ERROR-TITLE');
            message[1] = this._translateService.instant('STUDENT.MODAL-BIRTHDAY-ERROR-MESSAGE', {date: date_last.toLocaleDateString("es-ES")})
            let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
              disableClose: true
            });
            confirmDialog.componentInstance.title = message[0];
            confirmDialog.componentInstance.message1 = message[1];
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
            confirmDialog.componentInstance.margin = false;
            confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
              confirmDialog = null;
            });
          //}
        }
      //}
      this.todayBirthday = false;
    }
  }

    errorGetLastBirthday(error) {}

    errorGetBirthdayReward(error) {}

  hideBirthdayMessage() {
    if (this.adminLogin) {
      this.todayBirthday = false;
      return;
    }
    this.extraRewardService.extraRewardViewed(this.idRewardBirthDay)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.authService.reloadUser(() => {
          this.todayBirthday = false;
        })
      }
    )
  }

    birthday(staus?: boolean) {
        this.status = staus;
        let dateOfBirth = this.authService.currentUser.DateOfBirth ? moment(this.authService.currentUser.DateOfBirth) : null
        this.formData = this._formBuilder.group({
            Name: [this.authService.currentUser.Name, [
                Validators.required,
                Validators.maxLength(40),
                this.conditionalPatternValidator(this.authService.currentUser.BlockName)
            ]],
            FamilyName: [this.authService.currentUser.FamilyName, [
                Validators.maxLength(50),
                this.conditionalPatternValidator(this.authService.currentUser.BlockName)
            ]],
            Alias: [this.authService.currentUser.Alias || "", [
                Validators.maxLength(50)
            ]],
            DateOfBirth: [dateOfBirth]
        });
        this.askBirthday = true;
    }

    conditionalPatternValidator(blockName: any): ValidatorFn | null {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!blockName) {
                return Validators.pattern(this.regExp)(control);
            } else {
                return null;
            }
        };
    }

    showAskPassword() {
        this.askPassword = true;
        this.checkClaveActual = true;
        this.newPassword.p1.changed = false;
        this.newPassword.p2.changed = false;
        this.newPassword.p1.value = "";
        this.newPassword.p2.value = "";
        this.newPassword.act.value = "";
    }

  comprobarClaveActual() {
    this.authService.checkLogin(this.authService.currentUser.UserName, this.newPassword.act.value)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successAuthS()
      },
      error => {
        this.errorAuth()
      }
    )
  }

    successAuthS() {
        this.checkClaveActual = true;
        this.newPassword.p1.changed = true;
        this.newPassword.p2.changed = true;
        this.changePassword();
    }

    errorAuth() {
        this.checkClaveActual = false;
        this.newPassword.p1.changed = false;
        this.newPassword.p2.changed = false;
    }

    len(txt) {
        if (!txt) return 0;
        return txt.toString().length;
    }

  changePassword() {
    if (this.newPassword.p1.changed && this.newPassword.p2.changed && this.newPassword.p1.value == this.newPassword.p2.value) {
      if (this.len(this.newPassword.p2.value) < 6 || this.len(this.newPassword.p2.value) > 10) return;
      if (this.len(this.newPassword.p1.value) < 6 || this.len(this.newPassword.p1.value) > 10) return;
      if (this.calling) return;
      let message = [];
      message[0] = this._translateService.instant('SCHOOL.ATTENTION');
      message[1] = this._translateService.instant('STUDENT.MODAL-CHANGE-PASSWORD-MESSAGE');
      let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
      });
      confirmDialog.componentInstance.title = message[0];
      confirmDialog.componentInstance.message1 = message[1];
      confirmDialog.componentInstance.message2 = message[2];
      confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
      confirmDialog.componentInstance.margin = false;
      confirmDialog.componentInstance.theme = "white";
      confirmDialog.componentInstance.options = [{
        text: "Sí",
        callback: () => {
          this.calling = true;
          this.userService.changePassword(this.authService.currentUser.Id, this.newPassword.p1.value)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successChangePassword.bind(this), this.errorChangePassword.bind(this));
        }
      }, {
        text: "No",
        callback: () => { }
      }];
      confirmDialog.componentInstance.margin = false;
      confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
        result();
        confirmDialog = null;
        return;
      });
    }
  }

  successChangePassword(result) {
    this.calling = false;
    let message = [];
    message[0] = this._translateService.instant('STUDENT.MODAL-SUCCESS-CHANGE-PASSWORD-TITLE');
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      this.askPassword = false;
      confirmDialog = null;
    });
  }

  errorChangePassword(result) {
    this.calling = false
    let message = [];
    message[0] = this._translateService.instant('STUDENT.MODAL-ERROR-CHANGE-PASSWORD-TITLE');
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
  }

  updateUser() {
    if (this.formData.value.DateOfBirth instanceof moment) {
      this.authService.currentUser.DateOfBirth = moment(this.formData.value.DateOfBirth).hour(moment().hours());
      if (this.authService.currentUser.DateOfBirth > moment()) {
        this.newBirthdayError = true;
        return;
      }
    } else if (this.formData.value.DateOfBirth == null) {
      this.authService.currentUser.DateOfBirth = null
    }
    this.newBirthdayError = false;
    if (this.formData.value.Name !== '' || this.formData.value.FamilyName !== '' || this.formData.value.Alias !== '') {
      this.authService.currentUser.Name = this.formData.value.Name.trim();
      this.authService.currentUser.FamilyName = this.formData.value.FamilyName.trim();
      this.authService.currentUser.Alias = this.formData.value.Alias.trim();
      if (this.authService.currentUser.Alias == "") this.authService.currentUser.Alias = this.authService.currentUser.Name + " " + this.authService.currentUser.FamilyName;
      if (this.authService.currentUser && this.authService.currentUser.Alias && this.authService.currentUser.Alias.length >= 50) this.authService.currentUser.Alias = this.authService.currentUser.Alias.substring(0, 50);
    }
    if(this.formData.invalid){
        return;
    }
    this.userService.UpdateUserBirthdayAndNameAndFamilyname(this.authService.currentUser)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((response) => {
      this.askBirthday = false;
      this.showBirthday = false;
      this.authService.reloadUser(() => {
        this.currentUser = this.authService.currentUser
        if (this.authService.currentUser.DateOfBirth != null && moment().date() == moment(this.authService.currentUser.DateOfBirth).date() && moment().month() == moment(this.authService.currentUser.DateOfBirth).month()) {
          this.extraRewardService.getLastBirthday(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
            result => {
              this.successGetLastBirthday(result)
            },
            error => {
              this.errorGetLastBirthday(error)
            }
          )
        }
      })
    }, (response) => {
    });
  }

    contact() {
        this.navigationService.go("/student/contact", {});
    }

  logoutUser() {
    if (this.authService.currentUser.UserName.split(".")[1] == "tmp" && this.smDemo != true) {
      let codigo = sessionStorage.getItem("returnCode")
      this.navigationService.go("/redirection/"+codigo);
      //this.checkVersion();
    } else {
      if (this.authService.currentUser.OnDemand) {
        this.sessionService.createTmpSession(this.authService.currentUser.Id, this.authService.currentUser.OnDemand)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
          result => {
            this.successCallbackCreateSession(result);
            //this.checkVersion();
          },
          error => {
            this.errorCallbackCreateSession(error);
          }
        )
      } else {
        this.authService.checked_announcements = false
        this.navigationService.goLogin();
        this.ResetServicesVariables();
        //this.checkVersion()
      }
    }
  }
    ResetServicesVariables() {
    this.dailyTextService.dailyText = null;
    this.dailyTextService.dailyTextScore = undefined;
    this.retoService.listClasses = []
    this.avatarShopService.setAvatarStatus(false);
    this.waliLetrasService.resetVariables()
  }
  /*checkVersion() {
    this.globalService.versionError = false;
    let meta: any = document.querySelector('meta[name="version"]');
    let localVersion = meta.content;
    this.versionService.getVersion()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        (result: any) => {
            let v = result.Version.split(";")[0];
            let e = result.Version.split(";")[1];
            if (result.Version == "Maintenance") {
                // Mantenimiento
            } else if (v != localVersion) {
                this.globalService.versionError = true;
                let cReload = 1;
                if (sessionStorage.getItem("cReload")) {
                    cReload = parseFloat(sessionStorage.getItem("cReload"));
                }
                if (cReload <= 3) {
                    sessionStorage.setItem("cReload", (cReload + 1).toString());
                    window.location.reload();
                } else {
                    sessionStorage.removeItem("cReload");
                }
            } else {
                sessionStorage.removeItem("cReload");
            }
        });
    }*/

    errorCallbackCreateSession(error) {
        this.navigationService.goLogin();
    }

    successCallbackCreateSession(result) {
        if (result.length == 0) return this.navigationService.goLogin();
        this.authService.currentUser.Sessions = [];
        this.authService.currentUser.Sessions.push(result);
        sessionStorage.setItem(
            "currentUser",
            JSON.stringify(this.authService.currentUser)
        );
        this.navigationService.go("demand");
    }

    showLicenseInfo() {
        this.licenseInfo = !this.licenseInfo;
        if (this.licenseInfo) {
            setTimeout(function () {
                document.body.addEventListener(
                    "click",
                    bodyListener.bind(this),
                    true
                );
            }, 100);
        } else {
            document.body.removeEventListener(
                "click",
                bodyListener.bind(this),
                true
            );
        }
        function bodyListener() {
            this.licenseInfo = false;
        }
    }

  successReloadCoins() {
    this.currentUser = this.userService.getCurrentUser()
    if (this.authService.currentUser.Coins > 5000) this.walinwosChest = "full";
    else if (this.authService.currentUser.Coins > 500) this.walinwosChest = "med";
    else this.walinwosChest = "empty";
  }

    successCallbackGetUser() {
        this.helpViewed = !!sessionStorage.getItem('helpViewed');

        if (this.authService.currentUser.Coins > 5000)
            this.walinwosChest = "full";
        else if (this.authService.currentUser.Coins > 500)
            this.walinwosChest = "med";
        else this.walinwosChest = "empty";
        if (!this.authService.currentUser.TutorialDone &&
            !this.authService.currentUser.IsAdmin && !this.helpViewed) {
            this.tutorial();
            }
        for (
            let i = 0;
            i < this.authService.currentUser.ShopPresents_nuevos.length;
            i++
        ) {
            if (this.authService.currentUser.ShopPresents_nuevos[i].aviso)
                this.authService.currentUser.ShopPresents_nuevos.length.splice(
                    i,
                    1
                );
        }
        this.dataLoaded = true;
    }

    splitByHastahg(text) {
        return text.split("#")[0];
    }

    async successLoadReto(response: any) {
        if (this.checked_announcements) return;
        if (!response) return;
        this.showRetoMessage = true;
        this.showRetoEndMessage = true;
        this.showRetoGuestMessage = false;
        this.retoMsg = null;
        this.retoMsg2 = null;
        this.retoMsg2Alt = null;
        let finished = true;
        let position = 0;
        let winner: any = 0;
        let countFinished = 0;
        for (let i = 0; i < response.Invitaciones.length; i++) {
            if (
                response.Invitaciones[i].IdGuest ===
                    this.authService.currentUser.Id &&
                response.Invitaciones[i].Status === "Esperando"
            ) {
                this.showRetoEndMessage = false;
                this.showRetoMessage = false;
                this.showRetoGuestMessage =
                    sessionStorage.getItem(
                        `walinwa-challenge-${response.IdReto}`
                    ) == undefined;
                return;
            }
            //Rechazado: Manual
            //Cancelado: Automático
            //Finalizado: terminado
            //Anulado: finalizado unico
            if (
                response.Invitaciones[i].Status !==
                    this.currentSessionStatusEnum[
                        this.currentSessionStatusEnum.Rechazado
                    ] &&
                response.Invitaciones[i].Status !==
                    this.currentSessionStatusEnum[
                        this.currentSessionStatusEnum.Cancelado
                    ] &&
                response.Invitaciones[i].Status !==
                    this.currentSessionStatusEnum[
                        this.currentSessionStatusEnum.Finalizado
                    ] &&
                response.Invitaciones[i].Status !==
                    this.currentSessionStatusEnum[
                        this.currentSessionStatusEnum.Anulado
                    ]
            )
                finished = false;
            if (
                response.Invitaciones[i].Status ===
                this.currentSessionStatusEnum[
                    this.currentSessionStatusEnum.Finalizado
                ]
            )
                countFinished++;
            if (
                response.Invitaciones[i].IdGuest ===
                    this.authService.currentUser.Id &&
                (response.Invitaciones[i].Status ===
                    this.currentSessionStatusEnum[
                        this.currentSessionStatusEnum.Finalizado
                    ] ||
                    response.Invitaciones[i].Status ===
                        this.currentSessionStatusEnum[
                            this.currentSessionStatusEnum.Anulado
                        ])
            )
                position = response.Invitaciones[i].Position;

            if (
                response.Invitaciones[i].Position === 1 &&
                response.Invitaciones[i].Status ===
                this.currentSessionStatusEnum[
                this.currentSessionStatusEnum.Finalizado
                ]
            )
                winner = response.Invitaciones[i].IdGuest;
        }
        if (winner != 0) {
            if (winner == this.authService.currentUser.Id) {
                winner = this.authService.currentUser.Name + " " + this.authService.currentUser.FamilyName + " (" + this.authService.currentUser.UserName + ")";
            }
            else {
                await this.getWinnerReto(winner).then((winnerName: string) => {
                    winner = winnerName
                });
            }
            
        }
        // finished = true
        // position = 1
        // countFinished = 2
    if (finished) {
      this.showRetoMessage = false;
      if (position == 1 && countFinished > 1) {
        this.showRetoMessage = false;
        this.showRetoEndMessage = true;
        this.retoMsg = this._translateService.instant('STUDENT.GAMES.NEXT-CIVILIZATION.TITLE');
        this.retoMsg2Alt = null;
        this.retoMsgAlt = this._translateService.instant('STUDENT.MODAL-CHALLENGE-FIRST-POSITION');
      } else if (position == null) {
        this.showRetoMessage = false;
        this.showRetoEndMessage = false;
        if(sessionStorage.getItem(`walinwa-challenge-message-${response.IdReto}`) != undefined){
            return;
        }
        let message = [];
        message[0] = this._translateService.instant('STUDENT.MODAL-CHALLENGE-KEEP-TRYING-TITLE');
        message[1] = this._translateService.instant('STUDENT.MODAL-CHALLENGE-KEEP-TRYING-MESSAGE');
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
          disableClose: true
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.message2 = message[2];
        confirmDialog.componentInstance.message3 = message[3];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;
        confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
            confirmDialog = null;
            return;
        });
      } else {
        this.showRetoMessage = false;
        this.showRetoEndMessage = false;
        let message = [];
        message[0] = this._translateService.instant('STUDENT.MODAL-CHALLENGE-FINISHED-TITLE');
        message[1] = this._translateService.instant('STUDENT.MODAL-CHALLENGE-FINISHED-MESSAGE', {position});
        message[2] = this._translateService.instant('STUDENT.MODAL-CHALLENGE-FINISHED-MESSAGE2', {winner});
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
          disableClose: true
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.message2 = message[2];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;
        confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
          confirmDialog = null;
          this.setRetoAsRead()
          return;
        });
      }
    } else {
      this.showRetoMessage = false;
      this.showRetoEndMessage = false;
    }
  }

  successLastMonthReward(result) {
    if (result && result.Shown == false) {
      this.idReward = result.IdCoinTransaction;
      //Show the reward
      this.showReward = true;
      if (result.RewardType.includes("MON_")) this.rewardImg = "perseverance";
      this.rewardImg += result.Amount;
      this.rewardText = result.LongDescription;
    } else if (this.authService.currentUser.IdReto) {
      this.retoService.getReto(this.authService.currentUser.IdReto)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.successLoadReto(result)
        },
        error => {
          this.errorCallback()
        }
      )
    }
  }

    successPendingMessages(result) {
        this.adminMessages = null;
        this.teacherMessages = null;
        if (
            result.teacherMessages != null &&
            result.teacherMessages.length > 0
        ) {
            this.teacherMessages = result.teacherMessages;
            return;
        }
        if (result.adminMessages != null && result.adminMessages.length > 0)
            this.adminMessages = result.adminMessages;
    }

    async successGetSchoolParcial(result) {
        if (this.demoUser) {
            this._retoSubject.next(false);
            this.sharedService.changeDeductionTextEnabled(true);
            this.sharedService.changeDictationEnabled(true);
            this.sharedService.changeReadingTextEnabled(true);
            this.sharedService.changeHearingTextEnabled(true);
            return;
        }
        this._retoSubject.next(true);
        this.sharedService.changeDeductionTextEnabled(result.DeductionText);
        this.sharedService.changeDictationEnabled(result.Dictation);
        this.sharedService.changeReadingTextEnabled(result.ReadingText);
        this.sharedService.changeHearingTextEnabled(result.HearingText);

        //let showWaliLee = await this.waliLeeService.GetShowWaliLee(this.authService.currentUser.Id).toPromise();
        
        this._waliLeeSubject.next(moment(result.StartWaliLee) <= moment() /*&& showWaliLee*/ /*&& !environment.production*/ ? true : false);

        if (result && (result.Origin == 'WALI' || result.Origin == 'ESPECIAL')) {
            this.sharedService.changePersonalizedTestEnabled(true);
        }
    }

    async getWinnerReto(idUser: number): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.userService.getSimpleUser(idUser).subscribe(
                (result: any) => {
                    let winnerName = result.Name + " " + result.FamilyName + " (" + result.UserName + ")";
                    resolve(winnerName);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
    }

  successViewMessage() {
    this.adminMessagesService.getPendingMessages(this.authService.currentUser.Id, moment().toDate())
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successPendingMessages.bind(this), this.errorCallback.bind(this));
  }

    showAdminMessagesF() {
        this.showAdminMessages = true;
    }

    showTeacherMessagesF() {
        this.showTeacherMessages = true;
    }

  closeAdminMessage() {
    // set message as read
    if (!this.adminLogin)
      this.adminMessagesService.viewMessage(this.adminMessages[0].IdAdminMessage, this.authService.currentUser.Id, this.adminMessages[0].StartDate, false)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successViewMessage.bind(this), this.errorCallback.bind(this));
    else
      this.successViewMessage();
    this.showAdminMessages = false;
  }

  closeTeacherMessage() {
    //set message as read
    if (!this.adminLogin) {
      var IdMessage = null;
      if (this.teacherMessages) {
        if (this.teacherMessages.length > 0) {
          IdMessage = this.teacherMessages[0].IdMessage;
        }
      }
      this.adminMessagesService.viewMessage(IdMessage, this.authService.currentUser.Id, null, true)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successViewMessage.bind(this), this.errorCallback.bind(this));
    } else {
      this.successViewMessage();
    }
    this.showTeacherMessages = false;
  }

    checkDefinition(text) {
        return text
            .replace("<i>", '<span class="italic-lighter">')
            .replace("</i>", "</span>");
    }

  closeReward() {
    if (!this.adminLogin) this.extraRewardService.extraRewardViewed(this.idReward)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe()
    this.showReward = false;
    if (this.authService.currentUser.IdReto) {
      this.retoService.getReto(this.authService.currentUser.IdReto)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.successLoadReto(result)
        },
        error => {
          this.errorCallback()
        }
      )
    }
  }

    errorCallback() {
        this.navigationService.goLogin();
    }

  closePopUp() {
    // this.overlay.className = '';
    // this.popup.className = '';
    this.showPopUp = false;
    if (this.authService.currentUser.LastRewardDate == null || moment(this.authService.currentUser.LastRewardDate).month() != moment().month()) {
      this.extraRewardService.getLastMonthReward(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.successLastMonthReward(result)
        },
        error => {
          this.errorCallback()
        }
      )
    }
  }

    showPopUpBook2(book) {
        this.showPopUpBook = true;
        this.bookName = book;
    }

    GetActiveItems(): void {
        this.avatarShopService.GetActiveItemsByIdUser(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                this.activeItems = result;
            })
    }

    closeModal() {
        this.askBirthday = null;
        this.askPassword = null;
    }

 /* goToVisualMemory(){
        this.navigationService.go("/student/visual-memory");
  }*/

    setStatus(status: boolean) {
        this.status = status;
  }

  openMobMenuFunction() {
    var mobi = document.getElementById("openMobMenu");
    var mobi_close = document.getElementById("openMobMenuClose");
    var mobi_modal = document.getElementById("mobiMenuFlot");
    var mobi_modal_back = document.getElementById("backOverlayMenu");

    mobi.classList.toggle("active");
    mobi_close.classList.toggle("active");
    mobi_modal.classList.toggle("active");
    mobi_modal_back.classList.toggle("active");
  }

    async PanelAvantarShow() {
        this.userService.getPlayAndMyZoneActualConditions().subscribe(async conditions => {
            let canShowShop = await this.userService.getMyZoneMeetAllConditions()
            if (canShowShop) {
                this.avatarShopVisible = true;
                this.changeDetectorRef.detectChanges();
                const panelAvat = document.getElementById("avatarpanel");
                panelAvat.classList.add('active');
            } else {
                this.showDialog(conditions);
            }
        });
    }

    async showDialog(result) {
        let messages: string[] = [];
        messages.push("Tienda");
        if (result.SessionMyZoneCondition == false) {
            console.log(result)
            const response: any = await this.sessionService.checkSessionCondition(this.authService.currentUser.Id, this.authService.currentUser.IdClass, 1).toPromise();
            if (response.message == "TodaySessionNotDone") {
                messages.push(this._translateService.instant('STUDENT.ZONE.TODAYSESSIONNOTDONE'));
            }
            else if (response.message == "FinishStartedSessions") {
                messages.push(this._translateService.instant('STUDENT.ZONE.FINISHSTARTEDSESSIONS'));
            }
            else if (response.message == "FinishAllAvailableSessions") {
                messages.push(this._translateService.instant('STUDENT.ZONE.FINISHALLAVAILABLESESSIONS'));
            }
        }
        if (result.DictationMyZoneCondition == false) {
            messages.push(this._translateService.instant('STUDENT.ZONE.DICTATIONCONDITION'));
        }
        if (result.ReadingMyZoneCondition == false || result.DeductionMyZoneCondition == false || result.HearingMyZoneCondition == false) {
            messages.push(this._translateService.instant('STUDENT.ZONE.READINGCONDITION'));
        }
        if (result.MemoryMyZoneCondition == false) {
            messages.push(this._translateService.instant('STUDENT.ZONE.MEMORYCONDITION'));
        }
        if (result.TestMyZoneCondition == false) {
            messages.push(this._translateService.instant('STUDENT.ZONE.TESTCONDITION'))
        }
        let arrfunc = () => { }
        this.navigationService.PlayAndZoneMissingConditions(messages[0], arrfunc, () => {
            this.navigationService.error(messages, arrfunc)
        })
    }  

  public activate(e: any) {    
    this.template = (e as _Header)?.template;
  }
}
