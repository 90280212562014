import { GameService } from 'app/services/game.service';
import { Component, OnDestroy, OnInit, Sanitizer, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-play-game-games',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlayComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  game_url
  params

  constructor(
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private navigationService: NavigationService,
    private authService: AuthService,
    private gameService : GameService,
  ) {
    this._unsubscribeAll = new Subject();
    if (!this.authService.currentUser) this.navigationService.goLogin()
    let game_index = parseInt(this.activatedRoute.params['value'].game)
    console.log(game_index)
    let gameIndex = game_index + 1;
    if(gameIndex == 9){
        gameIndex++;
    }
    this.gameService.canPlay(this.authService.currentUser.Id, gameIndex)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((data:any) =>{
        if (!(data == -1 || data > 0)) {
            this.navigationService.go("student/games/" + game_index)
        }
    })
    if(!this.navigationService.params?.gameVersion){
        this.navigationService.go("student/session")
        return;
    }
    console.log(gameIndex)
    if(!environment.production && (gameIndex == 5 || gameIndex == 7)){
        this.game_url = this.sanitizer.bypassSecurityTrustResourceUrl(`./assets/games/Game${(game_index + 1)}_new/index.html`)
    } else if (gameIndex == 1 || gameIndex == 5) {
        this.game_url = this.sanitizer.bypassSecurityTrustResourceUrl(`./assets/games/Game${(game_index + 1)}_new/index.html`)
    } else {
        this.game_url = this.sanitizer.bypassSecurityTrustResourceUrl(`./assets/games/Game${(game_index + 1)}/index.html`)
    }
    this.params = JSON.stringify({
      GAME_URL_SOURCE: environment.baseApi,
      GAME_VERSION: this.navigationService.params.gameVersion,
      GAME_IDCOINT: 1,
      GAME_ID_USER: this.authService.currentUser.Id,
      GAME_COST: this.navigationService.params.gameCost,
      CODE_VERSION: environment.version,
    })
    this.navigationService.resetParams();
  }
  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  ngOnInit() {

  }

}
