<section class="ww_header" [ngClass]="{ ww_header_admin: schoolAdminView == true }" id="TooperHeaderElement">
    <div class="topper_version" *ngIf="schoolAdminView == true" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-sup-izq.svg&quot;)'"></div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="container_ww spe_gap" [ngClass]="{ ww_container_admin: schoolAdminView == true }">
        <div fxFlex="7.4120rem" fxFlex.xs="3rem">
            <img (click)="changeAdminView()" src="https://walinwa.blob.core.windows.net/images/logo_walinwa_pantallas1.png?ngsw-bypass=true" class=""
                fxFlex.xs="9rem" fxHide fxShow.gt-xs />
            <img (click)="changeAdminView()" src="https://walinwa.blob.core.windows.net/images/walinwa-logo-mob.svg?ngsw-bypass=true" fxFlex.xs="3rem" fxHide fxShow.xs />
        </div>
        <div fxFlex="" fxLayout="row" fxLayoutAlign="end center" class="spe_gap">
            <div *ngIf="childComponent && childComponent.dataLoaded">
                <div class="cont_main_top_buttons">
                    <div class="probar_demo cicrle" *ngIf="!isParentStudent()" (click)="demo()">
                        <span [innerHTML]="'SCHOOL.TEST-SESSION-TITLE' | translate"></span>
                    </div>
                    <div class="contra_lice cicrle" *ngIf="childComponent.startPayment" [hidden]="
                    childComponent.schoolService.school.Testing != true ||
                    !childComponent.dataLoaded2" (click)="childComponent.startPayment()">
                        <span [innerHTML]="'SCHOOL.RENEW-LICENSE-TITLE' | translate"></span>
                    </div>
                    <div class="sec_informes cicrle" *ngIf="childComponent.viewReports" [hidden]="
                    childComponent.schoolService.school.Testing == true ||
                    !childComponent.dataLoaded2 ||
                    currentUser.IdRole != 6" (click)="childComponent.viewReports();">
                        <span [innerHTML]="'SCHOOL.REPORT-TITLE' | translate"></span>
                    </div>
                    <div class="acep_oferta cicrle" *ngIf="childComponent.startPayment" [hidden]="
                    childComponent.schoolService.school.Payment == null ||
                    !childComponent.dataLoaded2" (click)="childComponent.startPayment()">
                        <span [innerHTML]="'SCHOOL.ACCEPT-OFFER-TITLE' | translate"></span>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" class="spe_gap">
                <div class="ww_userinfo text-right pointer">
                    <div class="walinwa-tooltip" fxNgClass.xs="walinwa-tooltip-top" fxNgClass.gt-xs="walinwa-tooltip-left"
                        style="" *ngIf="showInfo" (click)="showInfo = false">
                        <div class="walinwa-tooltip-info walinwa-tooltip-info-primary">
                            {{'USERNAME' | translate}} <b>{{ currentUser.Id }}</b>.
                        </div>
                        <div class="walinwa-tooltip-info" *ngIf="schoolService.school">
                            {{'SCHOOL.NAME' | translate}}:
                            <b>{{
                                schoolService.school.IdSchool +
                                " " +
                                schoolService.school.Name
                                }}</b>.
                        </div>
                        <div class="walinwa-tooltip-info" *ngIf="schoolService.school">
                            {{'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.ADDRESS' | translate}}:
                            <b>{{ schoolService.school.Street }}
                                {{ schoolService.school.CP }}
                                {{ schoolService.school.City }}</b>.
                        </div>
                        <div class="walinwa-tooltip-info" *ngIf="schoolService.school && isTeacher">
                            {{'STUDENT.CLASS-ID' | translate}} 
                            <b>{{assignedIdClass}}</b>.
                        </div>
                    </div>
                    <div class="walinwa-header-user-info-primary">
                        <img (click)="changeInfoShow()" style="
                                cursor: pointer;
                                height: 1.2rem;
                                margin-right: 0.3rem; display: inline; vertical-align: middle;
                            " src="https://walinwa.blob.core.windows.net/images/ico-info-white.svg?ngsw-bypass=true" [hidden]="schoolAdminView == true" />
                        <img (click)="changeInfoShow()" style="
                            cursor: pointer;
                            height: 1.2rem;
                            margin-right: 0.3rem; display: inline; vertical-align: middle;
                        " src="https://walinwa.blob.core.windows.net/images/School/ico-info-naranja.svg?ngsw-bypass=true" [hidden]="schoolAdminView == false" />
                        <span *ngIf="currentUser.IdSchool" (click)="openEditUser()" class="pointer">{{
                            currentUser.UserName
                            }}</span>
                        <span *ngIf="!currentUser.IdSchool" (click)="openEditUser()" class="pointer">{{ currentUser.Name }}
                            {{ currentUser.FamilyName }}</span>
                    </div>
                    <div class="" *ngIf="schoolService.school">
                        {{'SCHOOL.LICENSE' | translate}}
                        {{ formatDate(school_valid_until) }}
                    </div>
                    <div class="" *ngIf="isParentStudent()">
                        {{ currentUser.Email }}
                    </div>
                    <div class="" *ngIf="schoolService.school">
                        {{'SCHOOL.STUDENTS-COUNT' | translate : {numStudents, maxNumStudents} }}
                    </div>
                </div>
                <div  class="pointer" (click)="openEditUser()" fxFlex="5.25rem" fxFlex.xs="3.75rem" fxHide.xs>
                    <img src="https://walinwa.blob.core.windows.net/images/teacher_ico.svg?ngsw-bypass=true" class="pointer" width="100%"
                        *ngIf="schoolAdminView == false" />
                    <img src="https://walinwa.blob.core.windows.net/images/teacher_ico-orange.svg?ngsw-bypass=true" class="pointer" width="100%"
                        *ngIf="schoolAdminView == true" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ww_content" id="MainContent" fxLayout="column" fxLayoutAlign="stretch"
    [ngClass]="{ backburbles: schoolAdminView == true }" [style.background-image]=" schoolAdminView ? 'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg&quot;)' : ''">
    <div class="container_ww" [ngClass]="{ ww_container_admin: schoolAdminView == true }" fxFlex="" fxLayout="column"
        fxLayoutAlign="stretch">
        <div class="mob_topper_icons">
            <div fxFlex="3rem" id="noMenuAdminProfile">
                <img src="https://walinwa.blob.core.windows.net/images/School/ico-menu-mobile.svg?ngsw-bypass=true" class="mobile_but_menu active" id="openMobMenu"
                    (click)="openMobMenuFunction();" />
                <img src="https://walinwa.blob.core.windows.net/images/School/ico-menu-mobile-close.svg?ngsw-bypass=true" class="mobile_but_menu_close" id="openMobMenuClose"
                    (click)="openMobMenuFunction();" />
            </div>
            <!-- <div *ngIf="childComponent && childComponent.dataLoaded" fxFlex="" fxLayout="row" fxLayoutAlign="center">
                <div class="cont_main_top_buttons mobile">
                    <div class="probar_demo cicrle" *ngIf="!isParentStudent()" (click)="demo()">
                        <span>Probar<br>sesión</span>
                    </div>
                    <div class="contra_lice cicrle" *ngIf="childComponent.startPayment" [hidden]="
                    childComponent.schoolService.school.Testing != true ||
                    !childComponent.dataLoaded2" (click)="childComponent.startPayment()">
                        <span>Contratar<br>licencias</span>
                    </div>
                    <div class="cole_promotor cicrle" *ngIf="childComponent.startPromo" [hidden]="
                    childComponent.schoolService.school.Testing != true ||
                    !childComponent.dataLoaded2" (click)="childComponent.startPromo()">
                        <span>Colegio<br>promotor</span>
                    </div>
                    <div class="sec_informes cicrle" *ngIf="childComponent.viewReports" [hidden]="
                    childComponent.schoolService.school.Testing == true ||
                    !childComponent.dataLoaded2 ||
                    currentUser.IdRole != 6" (click)="childComponent.viewReports()">
                        <span>Sección de<br>informes</span>
                    </div>
                    <div class="acep_oferta cicrle" *ngIf="childComponent.startPayment" [hidden]="
                    childComponent.schoolService.school.Payment == null ||
                    !childComponent.dataLoaded2" (click)="childComponent.startPayment()">
                        <span>Aceptar<br>oferta</span>
                    </div>
                </div>
            </div> -->
        </div>
        <router-outlet *ngIf="dataLoaded" (activate)="onActivate($event)"></router-outlet>
    </div>
</section>

<help (changeShowHelp)="changeShow($event)" *ngIf="this.currentUser.IdSchool != null && showHelp"></help>
<parent-help (changeShowHelp)="changeShow($event)" *ngIf="this.currentUser.IdSchool == null && showHelp"></parent-help>
<section class="ww_footer" id="FotFooterElement">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign.xs="space-around center"
        class="container_ww spe_gap" [ngClass]="{ ww_container_admin: schoolAdminView == true }">
        <div fxFlex.gt-xs="10rem" fxFlexOrder="1">
            <button [hidden]="!navigationService.exitButton"
                (click)="navigationService.exitButton ? exit() : null"
                class="ww_button goback" fxFlex="10rem">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5rem">
                    <span fxFlex="1.875rem">
                        <img src="https://walinwa.blob.core.windows.net/images/ico-goback.svg?ngsw-bypass=true" />
                    </span>
                    <span>
                        {{'FINISH' | translate}}
                    </span>
                </div>
            </button>
            <button [hidden]="!navigationService.backButton" (click)="navigationService.backButton ? navigationService.backAll()
            : null; ProfeAdminCheck();" class="ww_button goback" fxFlex="10rem">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5rem">
                    <span fxFlex="1.875rem">
                        <img src="https://walinwa.blob.core.windows.net/images/ico-goback.svg?ngsw-bypass=true" />
                    </span>
                    <span>
                        {{'FOOTER.BACK' | translate}}
                    </span>
                </div>
            </button>
        </div>
        <div fxFlexOrder="2" fxFlexOrder.xs="3" [hidden]="!hasClasses || !schoolAdmin || !isTeacher">
            <div class="walinwa-switch">
                <div>{{'SCHOOL.TEACHER-TITLE' | translate}}</div>
                <div (click)="changeProfile();" class="walinwa-switch-options">
                    <div [ngClass]="{ selected: schoolAdminView == false }" class="walinwa-switch-option"></div>
                    <div [ngClass]="{ selected: schoolAdminView == true }" class="walinwa-switch-option"></div>
                </div>
                <div>{{'SCHOOL.ADMIN-TITLE' | translate}}</div>
            </div>
        </div>
        <div fxFlexOrder="3" fxFlexOrder.xs="2">
            <a *ngIf="schoolAdminView" href="https://www.walinwa.com/doc/guia_administrador_Walinwa.pdf"
                target="_blank" style="text-decoration: none !important;">
                <button class="ww_button help" fxFlex="13.5rem">
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5rem">
                        <span fxFlex="1.875rem">
                            <img src="https://walinwa.blob.core.windows.net/images/ico-help.svg?ngsw-bypass=true" />
                        </span>
                        <span>
                            {{'SCHOOL.TEACHER-GUIDE' | translate}}
                        </span>
                    </div>
                </button>
            </a>

            <button *ngIf="!schoolAdminView" (click)="showHelp = true"
                class="ww_button help profe_but_help_desktop" fxFlex="10rem">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5rem">
                    <span fxFlex="1.875rem">
                        <img src="https://walinwa.blob.core.windows.net/images/ico-help.svg?ngsw-bypass=true" />
                    </span>
                    <span>
                        {{'STUDENT.TUTORIAL-TITLE' | translate}}
                    </span>
                </div>
            </button>
        </div>
    </div>
</section>

<div class="back_overlay_menu" id="backOverlayMenu" (click)="openMobMenuFunction()"></div>

<div class="ww_c_popup_back" *ngIf="modificarDatos">
    <div class="ww_c_popup large">
        <div class="head spe_gap" fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <span [hidden]="modificarDatos == 2">{{'SCHOOL.TEACHER-INFO' | translate}}</span>
                <span [hidden]="modificarDatos == 1">{{'STUDENT.CHANGE-PASSWORD' | translate}}</span>
            </div>
            <div>
                <img (click)="modificarDatos = null" class="pointer" fxFlex="2rem"
                src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" />
            </div>
        </div>
        <div class="cont">
            <form fxLayout="column" [formGroup]="formData" fxLayoutAlign="stretch" [hidden]="modificarDatos == 2" #formDataDirective="ngForm">
                <div>
                    <label class="font_blue">{{'NAME' | translate}}</label>
                    <mat-form-field class="less_top_margin_field">
                        <input formControlName="Name" matInput type="text"
                        class="form-control" />
                        <mat-error *ngIf="formData.get('Name').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formData.get('Name').hasError('pattern')">
                            {{'PATTERN-ERROR' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <label class="font_blue">{{'FAMILYNAME' | translate}}</label>
                    <mat-form-field class="less_top_margin_field">
                        <input formControlName="FamilyName" matInput type="text"
                        class="form-control" />
                        <mat-error *ngIf="formData.get('FamilyName').hasError('pattern')">
                            {{'PATTERN-ERROR' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <label class="font_blue">{{'ALIAS' | translate}}</label>
                    <mat-form-field class="less_top_margin_field">
                        <input formControlName="Alias" matInput
                            type="text" class="form-control" />
                        <mat-error *ngIf="formData.get('Alias').hasError('maxlength')">
                            {{'ALIAS-MAX' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <label class="font_blue">{{'EMAIL' | translate}}</label>
                    <mat-form-field class="less_top_margin_field">
                        <input formControlName="Email" matInput type="text"
                        class="form-control" />
                        <mat-error *ngIf="formData.get('Email').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formData.get('Email').hasError('email')">
                            {{'EMAIL-INVALID' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formData.get('Email').hasError('unique')">
                            {{'AUTHENTICATION.ERROR.EMAIL-EXIST'}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <label class="font_blue">{{'LANGUAJE' | translate }}</label>
                    <mat-form-field class="less_top_margin_field">
                        <mat-select formControlName="Language">
                            <ng-container *ngIf="languages$ | async as languages">
                                <mat-option class="scrollable" *ngFor="let language of languages" [value]="language.IdLanguage">{{ language.Language }}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="formData.get('Language').hasError('required')">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center"
                    class="spe_gap">
                    <button (click)="modificarDatos = 2"  class="new_but azul">
                        {{'STUDENT.CHANGE-PASSWORD' | translate}}
                    </button>
                    <button (click)="updateUser()" class="new_but azul">
                        {{'SAVE' | translate}}
                    </button>
                </div>
            </form>
            <form fxLayout="column" fxLayoutAlign="stretch" [hidden]="modificarDatos == 1">
                <div>
                    <label class="font_blue">{{'STUDENT.CURRENT-PASSWORD' | translate}}</label>
                    <mat-form-field [ngClass]="{ error: !checkClaveActual }" class="less_top_margin_field">
                        <input (ngModelChange)="newPassword.act.changed = true" [(ngModel)]="newPassword.act.value"
                            [ngModelOptions]="{ standalone: true }" matInput
                            [type]="showPassword1 ? 'text' : 'password'" class="form-control" />
                            <i class="icone pass_icon" [ngClass]="showPassword1 ? 'eye-open' : 'eye-close'" matSuffix (click)="showPassword1 = !showPassword1"></i>
                    </mat-form-field>
                </div>
                <div>
                    <label class="font_blue">{{ 'AUTHENTICATION.RES_PW.PASSWORD1' | translate }}</label>
                    <mat-form-field [ngClass]="{ error: !checkNewPass }" class="less_top_margin_field">
                        <input (ngModelChange)="newPassword.p1.changed = true" [(ngModel)]="newPassword.p1.value"
                            [ngModelOptions]="{ standalone: true }" matInput
                            [type]="showPassword2 ? 'text' : 'password'" class="form-control" />
                        <i class="icone pass_icon" [ngClass]="showPassword2 ? 'eye-open' : 'eye-close'" matSuffix (click)="showPassword2 = !showPassword2"></i>
                    </mat-form-field>
                </div>
                <div>
                    <label class="font_blue">{{'STUDENT.REPEAT-PASSWORD' | translate}}</label>
                    <mat-form-field [ngClass]="{ error: !checkRepetirPass }" class="less_top_margin_field">
                        <input (ngModelChange)="newPassword.p2.changed = true" [(ngModel)]="newPassword.p2.value"
                            [ngModelOptions]="{ standalone: true }" matInput
                            [type]="showPassword3 ? 'text' : 'password'" class="form-control" />
                        <i class="icone pass_icon" [ngClass]="showPassword3 ? 'eye-open' : 'eye-close'" matSuffix (click)="showPassword3 = !showPassword3"></i>
                    </mat-form-field>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center"
                    class="spe_gap">
                    <button (click)="comprobarClaveActual()" class="new_but azul">
                        {{'SAVE' | translate}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="walinwa-overlay ww_c_popup_back" *ngIf="editSchoolData" fxLayout="column" fxLayoutAlign="center center">
    <section class="section_edit_school" fxLayout="column">
        <div fxFlex="" class="modify_student_data modal_mis_datos" fxFlex="column" fxLayoutAlign="stretch">
            <div fxFlex="" class="walinwa-box-with-tabs" fxLayout="column" fxLayoutAlign="stretch">


                <div fxFlex="" fxFlex.xs="100%" class="box_white_datos" fxLayout="row" fxLayoutAlign="center center"
                    fxLayoutAlign.xs="space-between start">
                    <div class="spe_gap" fxFlex="0 1 40.625rem" *ngIf="!status" fxLayout="column"
                        fxLayoutAlign="stretch">
                        <form [formGroup]="formSchool" class="ww_box_misdatos spe_gap" fxLayout="row"
                            fxLayout.xs="row wrap" fxLayoutAlign="space-between stretch"
                            fxLayoutAlign.xs="center center" #formSchoolDirective="ngForm">
                            <div class="" fxFlex="30%" fxFlex.xs="50%" style="align-self: flex-start;">
                                <img src="https://walinwa.blob.core.windows.net/images/teacher_ico-orange.svg?ngsw-bypass=true" style="height: 85%; width: 90%;"/>
                            </div>
                            <div class="" fxFlex="" fxFlex.xs="100%" fxLayout="column" fxLayoutAlign="stretch" style="height: 100%;">
                                <div fxFlex="0 0 calc(100% - 1rem)" fxFlex.xs="100%" class="">
                                    <label class="font_blue">{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.SCHOOL-NAME' | translate }}</label>
                                    <mat-form-field floatLabel="always" class="spefieldnowrap less_top_margin_field two walinwa-100">
                                        <input
                                            formControlName="Name" matInput type="text" class="form-control" />
                                        <mat-error *ngIf="formSchool.get('Name').hasError('required')">
                                            {{'FIELD-REQUIRED' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="spe_gap">
                                    <div fxFlex="0 0 calc(50% - 1rem)" class="">
                                        <label class="font_blue">C.I.F</label>
                                        <mat-form-field floatLabel="always" class="spefieldnowrap less_top_margin_field two walinwa-100">
                                            <input matInput  readonly class="valor disabled-field" formControlName="Cif" />
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="0 0 calc(50% - 1rem)" class="">
                                        <label class="font_blue">{{'SCHOOL.LICENSES-COUNT' | translate}}</label>
                                        <mat-form-field floatLabel="always" class="spefieldnowrap less_top_margin_field two walinwa-100">
                                            <input matInput readonly class="valor disabled-field" formControlName="MaxNumStudents" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                
                                <div fxFlex="0 0 calc(100% - 1rem)" fxFlex.xs="100%" class="">
                                    <label class="font_blue">{{'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.ADDRESS' | translate}}</label>
                                    <mat-form-field floatLabel="always" class="spefieldnowrap less_top_margin_field two walinwa-100">
                                        <input
                                            formControlName="Street" matInput type="text" class="form-control" />
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="spe_gap">
                                    <div fxFlex="0 0 calc(50% - 1rem)" class="">
                                        <label class="font_blue">{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.STATE' | translate }}</label>
                                        <mat-form-field floatLabel="always" class="spefieldnowrap less_top_margin_field two walinwa-100">
                                            <input
                                                formControlName="State" matInput type="text" class="form-control" />
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="0 0 calc(50% - 1rem)" class="">
                                        <label class="font_blue">C.P.</label>
                                        <mat-form-field floatLabel="always" class="spefieldnowrap less_top_margin_field two walinwa-100">
                                            <input formControlName="Cp" matInput type="text" class="form-control" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                
                                <div fxFlex="0 0 calc(100% - 1rem)" fxFlex.xs="100%" class="">
                                    <label class="font_blue">{{'SCHOOL.RESPONSIBLE' | translate}}</label>
                                    <mat-form-field floatLabel="always" class="spefieldnowrap less_top_margin_field two walinwa-100">
                                        <input formControlName="Responsible" matInput type="text" class="form-control" />
                                    </mat-form-field>
                                </div>
                                <div fxFlex="0 0 calc(100% - 1rem)" fxFlex.xs="100%" class="">
                                    <label class="font_blue">{{'EMAIL' | translate}}</label>
                                    <mat-form-field floatLabel="always" class="spefieldnowrap less_top_margin_field two walinwa-100">
                                        <input formControlName="Email" matInput type="text" class="form-control" />
                                        <mat-error *ngIf="formSchool.get('Email').invalid">
                                            {{'EMAIL-INVALID' | translate}}
                                        </mat-error>
                                        <mat-error *ngIf="formSchool.get('Email').required">
                                            {{'FIELD-REQUIRED' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="0 0 calc(100% - 1rem)" fxFlex.xs="100%" class="">
                                    <label class="font_blue">{{'SCHOOL.ENABLED-ACTIVITIES' | translate}}</label>
                                    <div style="background-color: var(--grisClaro); color:var(--colorText)">{{checkActiveActivities()}}</div>
                                </div>

                            </div>
                        </form>
                        <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center"
                            class="spe_gap">
                            <button (click)="verFacturas()" class="new_but azul">
                                {{'SCHOOL.SHOW-BILLS' | translate}}
                            </button>
                            <button (click)="showConfirmationMessage()" class="new_but azul">
                                {{'SAVE' | translate}}
                            </button>
                        </div>
                    </div>
                    
                </div>
                <img class="walinwa_close_image popupdatos pointer" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true"
                        (click)="closeModal()" />
            </div>
        </div>
    </section>
</div>