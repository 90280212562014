<div class="walinwa-box-with-tabs">
    <div class="walinwa-tabs walinwa-tabs-top">
        <div [ngClass]="{ 'orange-selected': viewBg}" (click)="showBg()" class="walinwa-tab-gray">{{'STUDENT.SHOP.BACKGROUNDS-TITLE' | translate }}</div>
        <div [ngClass]="{ 'orange-selected': viewComp}" (click)="showComp()" class="walinwa-tab-gray">{{'STUDENT.SHOP.COMPONENTS-TITLE' | translate }}</div>
        <div [ngClass]="{ 'orange-selected': viewMsg}" (click)="showMsgs()" class="walinwa-tab-gray">{{'STUDENT.SHOP.MESSAGES-TITLE' | translate }}</div>
        <div [ngClass]="{ 'orange-selected': viewOthers}" (click)="showOthers()" class="walinwa-tab-gray">{{'STUDENT.SHOP.OTHERS-TITLE' | translate }}</div>
        <div *ngIf="((listStudents?.length != 0 && currentUser.GiftShopDisabled != true) || demoUser)" [ngClass]="{ 'orange-selected': viewPresents}" (click)="showPresents()" class="walinwa-tab-gray">{{'STUDENT.SHOP.PRESENTS-TITLE' | translate }}</div>
        <div [ngClass]="{ 'orange-selected': viewOld}" *ngIf="oldItems.length > 0" (click)="showOld()" class="walinwa-tab-gray">{{'STUDENT.SHOP.OLDS-TITLE' | translate }}</div>
        <div [ngClass]="{ 'orange-selected': viewSummer}" *ngIf="availableSummer.length > 0 && summer" (click)="showSummer()" class="walinwa-tab-gray">{{'STUDENT.SHOP.SUMMER-TITLE' | translate }}</div>
    </div>
    <div class="walinwa-box walinwa-box-orange">
        <div class="walinwa-box-shop-items" *ngIf="viewBg">
            <div class="walinwa-box-shop-item" *ngFor="let item of availableBack; let i = index">
                <div class="selectablePanelBg" (click)="selectBg(i)" [ngClass]="{'selectedPanel': item.Selected == true}">
                    <div class="stageStatusBg" [ngClass]="{'activeBg' : item.Active == true}">
                        <div class="stageImageBg" [ngStyle]="{'background-image': 'url(' + getImgBackUrl(i) + ')'}"></div>
                    </div>
                    <div [ngClass]="{'hidden': item.Bougth != true}" class="textShopContainer">
                        <span class="title textGreen"> {{ item.Description }} </span>
                        <span *ngIf="es_regalado(item) == ''" class="priceText textGray" [ngClass]="{'hidden': item.ShowShop == false}">{{'STUDENT.SHOP.PRICE' | translate }} {{ item.Walinwos }} walinwos</span>
                        <span *ngIf="es_regalado(item) != ''" class="presentText">{{'STUDENT.SHOP.GIFT-FROM' | translate }} {{ es_regalado(item) }}</span>
                    </div>
                    <div [ngClass]="{'hidden': item.Bougth == true}" class="textShopContainer">
                        <span class="title"> {{ item.Description }} </span>
                        <span class="priceText">{{'STUDENT.SHOP.PRICE' | translate }} {{ item.Walinwos }} walinwos</span>
                    </div>
                </div>
                <div class="align-items-center d-flex justify-content-center" [ngClass]="{'hidden': item.Bougth == true }">
                    <input type="button" value="Adquirir" class=" boton secundario pequeño" (click)="buyBg(i)" [disabled]="hasNoWalinwos(item) || calling == true" />
                </div>
                <div class="align-items-center d-flex justify-content-center" [ngClass]="{'hidden': item.Active == true || item.Bougth != true }">
                    <input type="button" value="Activar" class=" boton secundario pequeño" (click)="activateBg(i)" [disabled]="calling == true" />
                </div>
            </div>
        </div>
        <div class="walinwa-box-shop-items" *ngIf="viewComp">
            <div class="walinwa-box-shop-item" *ngFor="let item of availableComponents; let i = index">
                <div class="selectablePanelBg" (click)="selectComp(i)" [ngClass]="{'selectedPanel': item.Selected == true}">
                    <div class="stageStatusBg" [ngClass]="{'activeBg' : item.Active == true}">
                        <div class="stageImageBg" [ngStyle]="{'background-image': 'url(' + getImgCompUrl(i) + ')'}"></div>
                    </div>
                    <div [ngClass]="{'hidden': item.Bougth != true}" class="textShopContainer">
                        <span class="title textGreen"> {{ item.Description }} </span>
                        <span *ngIf="es_regalado(item) == ''" class="priceText textGray" [ngClass]="{'hidden': item.ShowShop == false}">{{'STUDENT.SHOP.PRICE' | translate }} {{ item.Walinwos }} walinwos</span>
                        <span *ngIf="es_regalado(item) != ''" class="presentText">{{'STUDENT.SHOP.GIFT-FROM' | translate }} {{ es_regalado(item) }}</span>
                    </div>
                    <div [ngClass]="{'hidden': item.Bougth == true}" class="textShopContainer">
                        <span class="title"> {{ item.Description }} </span>
                        <span class="priceText">{{'STUDENT.SHOP.PRICE' | translate }} {{ item.Walinwos }} walinwos</span>
                    </div>
                </div>
                <div class="align-items-center d-flex justify-content-center" [ngClass]="{'hidden': item.Bougth == true }">
                    <input type="button" value="Adquirir" class=" boton secundario pequeño" (click)="buyComp(i)" [disabled]="hasNoWalinwos(item) || calling == true" />
                </div>
                <div class="align-items-center d-flex justify-content-center" [ngClass]="{'hidden': item.Active == true || item.Bougth != true }">
                    <input type="button" value="Activar" class=" boton secundario pequeño" (click)="activateComp(i)" [disabled]="calling == true" />
                </div>
                <div class="align-items-center d-flex justify-content-center" [ngClass]="{'hidden': item.Active != true || item.Bougth != true}">
                    <input type="button" value="Desactivar" class=" boton secundario pequeño" (click)="deactivateComp(i)" [disabled]="calling == true" />
                </div>
            </div>
        </div>
        <div class="walinwa-box-shop-items mensajes" *ngIf="viewMsg">
            <div class="walinwa-box-shop-item" *ngFor="let item of availableMesagges; let i = index">
                <div class="selectablePanelBg" (click)="selectMsg(i)" [ngClass]="{'selectedPanel': item.Selected == true}">
                    <div class="stageStatusBg" [ngClass]="{'activeBg' : item.Active == true}">
                        <div class="stageImageBg" [ngStyle]="{'background-image': 'url(' + getImgMsgUrl(i) + ')'}"></div>
                    </div>
                    <div [ngClass]="{'hidden': item.Bougth != true}" class="textShopContainer">
                        <span class="title textGreen"> {{ item.Description }} </span>
                        <span *ngIf="es_regalado(item) == ''" class="priceText textGray" [ngClass]="{'hidden': item.ShowShop == false}">{{'STUDENT.SHOP.PRICE' | translate }} {{ item.Walinwos }} walinwos</span>
                        <span *ngIf="es_regalado(item) != ''" class="presentText">{{'STUDENT.SHOP.GIFT-FROM' | translate }} {{ es_regalado(item) }}</span>
                    </div>
                    <div [ngClass]="{'hidden': item.Bougth == true}" class="textShopContainer">
                        <span class="title"> {{ item.Description }} </span>
                        <span class="priceText">{{'STUDENT.SHOP.PRICE' | translate }} {{ item.Walinwos }} walinwos</span>
                    </div>
                </div>
                <div class="align-items-center d-flex justify-content-center" [ngClass]="{'hidden': item.Bougth == true }">
                    <input type="button" value="Adquirir" class=" boton secundario pequeño" (click)="buyMsg(i)" [disabled]="hasNoWalinwos(item) || calling == true" />
                </div>
                <div class="align-items-center d-flex justify-content-center" [ngClass]="{'hidden': item.Active == true || item.Bougth != true }">
                    <input type="button" value="Activar" class=" boton secundario pequeño" (click)="activateMsg(i)" [disabled]="calling == true" />
                </div>
                <div class="align-items-center d-flex justify-content-center" [ngClass]="{'hidden': item.Active != true || item.Bougth != true}">
                    <input type="button" value="Desactivar" class=" boton secundario pequeño" (click)="deactivateMsg(i)" [disabled]="calling == true" />
                </div>
            </div>
        </div>
        <div class="walinwa-box-shop-items  " *ngIf="viewOthers">
            <div [ngClass]="{'hidden': item.OnlyPresent == true && item.Bougth == false}" class="walinwa-box-shop-item" *ngFor="let item of availableOthers; let i = index">
                <div class="selectablePanel" (click)="selectOther(i)" [ngClass]="{'selectedPanel': item.Selected == true &&  item.OnlyPresent != true}">
                    <div class="stageStatus" [ngClass]="{'activeBg' : item.Active == true}">
                        <div class="stageImg" [ngStyle]="{'background-image': 'url(' + getImgOtherUrl(i) + ')'}"></div>
                    </div>
                    <div [ngClass]="{'hidden': item.Bougth != true}" class="textShopContainer">
                        <span class="title textGreen"> {{item.Description}} </span>
                        <span *ngIf="es_regalado(item) == ''" class="priceText textGray">{{'STUDENT.SHOP.PRICE' | translate }} {{item.Walinwos}} walinwos</span>
                        <span *ngIf="es_regalado(item) != ''" class="presentText">{{'STUDENT.SHOP.GIFT-FROM' | translate }} {{ es_regalado(item) }}</span>

                    </div>
                    <div [ngClass]="{'hidden': item.Bougth == true || item.OnlyPresent == true}" class="textShopContainer">
                        <span class="title textGreen"> {{item.Description}} </span>
                        <span class="priceText" [ngClass]="{'hidden':item.Type == 14}">{{'STUDENT.SHOP.PRICE' | translate }} {{item.Walinwos}} walinwos</span>
                    </div>
                </div>
                <div style="display: flex; justify-content: center; align-items: center" [ngClass]="{'hidden': item.Bougth == true || item.OnlyPresent == true || item.Type == 14}">
                    <input type="button" value="Adquirir" class=" boton secundario pequeño" (click)="buyOther(i)" [disabled]="hasNoWalinwos(item) || calling == true" />
                </div>
                <div style="display: flex; justify-content: center; align-items: center" [ngClass]="{'hidden': item.Active == true || item.Bougth != true || item.OnlyPresent == true|| item.Type == 13 || item.Type == 14}">
                    <input type="button" value="Activar" class=" boton secundario pequeño" (click)="activateOther(i)" [disabled]="calling == true" />
                </div>
                <div style="display: flex; justify-content: center; align-items: center" [ngClass]="{'hidden': item.Active != true || item.Bougth != true || item.OnlyPresent == true|| item.Type == 13 || item.Type == 14}">
                    <input type="button" value="Desactivar" class=" boton secundario pequeño" (click)="deactivateOther(i)" [disabled]="calling == true" />
                </div>
                <div style="display: flex; justify-content: center; align-items: center" [ngClass]="{'hidden': item.Bougth != true || item.OnlyPresent == true|| item.Type != 13 || item.Type == 14}">
                    <input type="button" value="Descargar" class=" boton secundario pequeño" (click)="downloadDiploma(item)" [disabled]="calling == true" />
                </div>
                <div style="display: flex; justify-content: center; align-items: center" [ngClass]="{'hidden':  item.Type != 14 }">
                    <input type="button" value="Introducir" class=" boton secundario pequeño" (click)="insertCode()" [disabled]="calling == true" />
                </div>
            </div>
        </div>
        <div class="walinwa-box-shop-items " *ngIf="viewPresents">
            <div class="walinwa-box-shop-item" *ngFor="let item of allItems; let i = index">
                <div class="selectablePanel" (click)="selectPresent(i)" [ngClass]="{'selectedPanel': item.Selected == true}">
                    <div class="stageStatus">
                        <div class="stageImg" [ngStyle]="{'background-image': getImgPresentUrl(i) }"></div>
                    </div>
                    <div class="textShopContainer">
                        <span class="title textGreen"> {{item.Description}} </span>
                        <span class="priceText textGray" *ngIf="!ya_regalado(item)">{{'STUDENT.SHOP.PRICE' | translate }} {{ item.Walinwos }} walinwos</span>
                        <span class="priceText textGray" *ngIf="ya_regalado(item)">Regalado a {{ regalado_a(item) }}</span>
                    </div>
                </div>
                <div style="display: flex; justify-content: center; align-items: center;">
                    <input type="button" value="Regalar" class="boton secundario pequeño" (click)="regalar_click(item)" [hidden]="ya_regalado(item)" [disabled]="currentUser.Coins < item.Walinwos || calling == true" />
                </div>
            </div>
        </div>
        <div class="walinwa-box-shop-items " *ngIf="viewSummer">
            <div class="walinwa-box-shop-item" *ngFor="let item of availableSummer; let i = index">
                <div class="selectablePanel" (click)="selectSummer(i)" [ngClass]="{'selectedPanel': item.Selected == true &&  item.OnlyPresent != true, 'hidden': item.OnlyPresent == true && item.Bougth == false}">
                    <div class="stageStatus" [ngClass]="{'activeBg' : item.Active == true}">
                        <div class="stageImg" [ngStyle]="{'background-image': 'url(' + getImgSummerUrl(i) + ')'}"></div>
                    </div>
                    <div [ngClass]="{'hidden': item.Bougth != true}" class="textShopContainer">
                        <span class="title textGreen"> {{item.Description}} </span>
                        <span class="priceText textGray">{{'STUDENT.SHOP.PRICE' | translate }} {{item.Walinwos}} walinwos</span>
                    </div>
                    <div [ngClass]="{'hidden': item.Bougth == true || item.OnlyPresent == true}" class="textShopContainer">
                        <span class="title textGreen"> {{item.Description}} </span>
                        <span class="priceText">{{'STUDENT.SHOP.PRICE' | translate }} {{item.Walinwos}} walinwos</span>
                    </div>
                </div>
                <div style="display: flex; justify-content: center; align-items: center" [ngClass]="{'hidden': item.Bougth == true || item.OnlyPresent == true}">
                    <input type="button" value="Adquirir" class=" boton secundario pequeño" (click)="buySummer(i)" [disabled]="hasNoWalinwos(item) || calling == true" />
                </div>
                <div style="display: flex; justify-content: center; align-items: center" [ngClass]="{'hidden': item.Bougth != true || item.OnlyPresent == true|| item.Type != 13}">
                    <input type="button" value="Descargar" class=" boton secundario pequeño" (click)="downloadDiploma(item)" [disabled]="calling == true" />
                </div>
            </div>
        </div>
        <div class="walinwa-box-shop-items " *ngIf="viewOld">
            <div class="walinwa-box-shop-item" *ngFor="let item of oldItems; let i = index">
                <div class="selectablePanel" (click)="selectItem(i)" [ngClass]="{'selectedPanel': item.ShopItem.Selected == true}">
                    <div class="stageStatus" [ngClass]="{'activeBg' : item.ShopItem.Active == true}">
                        <div class="stageImg" [ngStyle]="{'background-image': 'url(' + getImgItemUrl(i) + ')'}"></div>
                    </div>
                    <div class="textShopContainer">
                        <span class="title textGreen"> {{item.ShopItem.Description}} </span>
                        <br />
                        <span class="priceText textGray">{{'STUDENT.SHOP.PRICE' | translate }} {{ getPrecioVenta(item.ShopItem.Walinwos) }} walinwos</span>
                    </div>
                </div>
                <div style="display: flex; justify-content: center; align-items: center;">
                    <input type="button" value="Devolver" class="boton secundario pequeño" (click)="vender(item)" [disabled]="calling == true" />
                </div>
            </div>
        </div>


    </div>
</div>

<div class="walinwa-overlay" *ngIf="viewPresents" [hidden]="regalar_userTo || !regalar_item">
    <div class="walinwa-modal walinwa-box fixedpopup">
        <div class="walinwa-modal-header walinwa-box-header">
            {{'STUDENT.AVATAR-SHOP.GIFT-TITLE' | translate }}
            <img (click)="cancelRegalar()" class="pointer" fxFlex="2.5rem" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true">
        </div>
        <form class="walinwa-modal-body walinwa-box-content">
            <div>{{'STUDENT.AVATAR-SHOP.SEARCH-FRIEND-TO-GIFT' | translate }}</div>
            <div class="">
                <label class="font_blue">{{'STUDENT.AVATAR-SHOP.WRITE-NAME' | translate }}</label>
                <div class="ww_field_spe">
                    <input [(ngModel)]="regalar_userTo_bqd" [ngModelOptions]="{standalone: true}" type="text" />
                </div>
            </div>
            <div class="">
                <label class="font_blue">{{'STUDENT.AVATAR-SHOP.SEARCH-NAME-HERE' | translate }}</label>
                <div class="ww_field_spe">
                    <mat-select [(ngModel)]="selectedStudent" [ngModelOptions]="{standalone: true}" style="margin: 0; padding: 0;">
                        <mat-option class="scrollable" [value]="std" *ngFor="let std of listStudents">{{ std.Name }} {{ std.FamilyName }}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="spe_gap" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
                <button (click)="checkRegalar()" class="new_but azul">
                    {{ 'CONFIRM' | translate }}
                </button>
            </div>
        </form>
    </div>
</div>

<div class="walinwa-overlay" *ngIf="viewPresents" [hidden]="regalar_userTo == null || regalar_item == null || error != false || regalar_message == true">
    <div class="walinwa-modal walinwa-box fixedpopup">
        <div class="walinwa-modal-header walinwa-box-header">
            {{'STUDENT.AVATAR-SHOP.GIFT-TITLE' | translate }}
            <img (click)="cancelRegalar()" class="pointer" fxFlex="2.5rem" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true">
        </div>
        <form class="walinwa-modal-body walinwa-box-content" >
            <div *ngIf="regalar_userTo">{{'STUDENT.AVATAR-SHOP.SEND-MESSAGE-WITH-GIFT-TEXT' | translate:{userTo:regalar_userTo.Alias} }}</div>
            <div class="">
                <label class="font_blue">{{'MESSAGE' | translate}}</label>
                <div class="ww_field_spe">
                    <textarea maxlength="100" [(ngModel)]="regalar_message_text" [ngModelOptions]="{standalone: true}"></textarea>
                </div>
            </div>
            <div class="spe_gap" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
                <button *ngIf="regalar_message_text==''" (click)="continueRegalarMessage()" class="new_but azul">
                    {{'STUDENT.AVATAR-SHOP.SKIP-BUTTON' | translate }}
                </button>
                <button *ngIf="regalar_message_text!=''" (click)="continueRegalarMessage()" class="new_but azul">
                    {{'CONFIRM' | translate}}
                </button>
            </div>
        </form>
    </div>
</div>

<div class="walinwa-overlay" *ngIf="viewPresents" [hidden]="regalar_userTo == null || regalar_item == null || error != false || regalar_message == false">
    <div class="walinwa-modal walinwa-box fixedpopup">
        <div class="walinwa-modal-header walinwa-box-header">
            {{'STUDENT.AVATAR-SHOP.GIFT-TITLE' | translate }}
            <img (click)="cancelRegalar()" class="pointer" fxFlex="2.5rem" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true">
        </div>
        <form class="walinwa-modal-body walinwa-box-content">
            <div style="display: block;" [innerHtml]="regalar_mensaje"></div>
            <div class="">
                <label class="font_blue">{{'PASSWORD' | translate}}</label>
                <div class="ww_field_spe icon_suffix">
                    <input [(ngModel)]="regalar_password" [type]="showPassword1 ? 'text' : 'password'" [ngModelOptions]="{standalone: true}" type="password" />
                    <mat-icon class="show-password-icon" [svgIcon]="showPassword1 ? 'gafas_off' : 'gafas_on'" matSuffix (click)="showPassword1 = !showPassword1"></mat-icon>
                </div>
                <div class="error" *ngIf="regalar_password_error!=null">
                    {{ regalar_password_error }}
                </div>
            </div>
            <div class="spe_gap" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
                <button (click)="regalar()" class="new_but azul">
                    {{'CONFIRM' | translate}}
                </button>
            </div>
        </form>
    </div>
</div>