<div class="walinwa-box-with-tabs">

    <div class="walinwa-tabs walinwa-tabs-top" *ngIf="hideAdminMessages && hideNewAdminMessage && hideConversation">
        <div [ngClass]="{'orange-selected': hideStudentMessages}" (click)="dudas()" class="walinwa-tab-gray" id="dudasSugerenciasFirst">{{'STUDENT.CONTACT.QUESTIONS-AND-SUGGESTIONS' | translate }}</div>
        <div [ngClass]="{'orange-selected': !hideStudentMessages}" (click)="hideStudentMessages = false" class="walinwa-tab-gray">{{'STUDENT.CONTACT.MY-MESSAGES' | translate }}</div>
    </div>

    <div class="walinwa-tabs walinwa-tabs-top" *ngIf="!hideAdminMessages || !hideNewAdminMessage || !hideConversation">
        <div [ngClass]="{ 'orange-selected': !hideConversation }" (click)="conversation()" class="walinwa-tab-gray">{{'STUDENT.CONTACT.CHAT' | translate }}</div>
        <div [ngClass]="{ 'orange-selected': !hideAdminMessages }" (click)="mis_mensajes()" class="walinwa-tab-gray">{{'STUDENT.CONTACT.MY-MESSAGES' | translate }}</div>
        <div [ngClass]="{ 'orange-selected': !hideNewAdminMessage }" (click)="showAdminMessage()" class="walinwa-tab-gray">{{'STUDENT.CONTACT.NEW-MESSAGE' | translate }}</div>
    </div>

    <div class="walinwa-box walinwa-box-orange">

        <div class="walinwa-box-content" [hidden]="hideAdminMessages && hideNewAdminMessage && hideConversation">
            <div *ngIf="!hideAdminMessages || !hideConversation">

                <div class="walinwa-box-content-messages mis_mensajes_boxes" [hidden]="hideAdminMessages">
                    <div *ngFor="let mensaje of askAdminMessages;let i = index" class="walinwa-contact-message derecha full">
                        <div [innerHtml]="mensaje.AdminMessage.Message" class="message"></div>
                        <div class="message-date">
                            {{ mensaje.StartDate | ToLocalTime }} - {{ mensaje.EndDate | ToLocalTime }}
                        </div>
                    </div>
                </div>

                <div class="walinwa-box-content-messages" [hidden]="hideConversation" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1rem">
                    <div [ngClass]="{'izquierda' :!mensaje.AdminMessage, 'derecha' :mensaje.AdminMessage, 'visto': mensaje.Viewed }" *ngFor="let mensaje of conversationMessages;let i = index" class="walinwa-contact-message">
                        <div *ngIf="!mensaje.AdminMessage" class="subject">{{ mensaje.Subject }}</div>
                        <div *ngIf="mensaje.AdminMessage" class="message" [innerHtml]="mensaje.AdminMessage.Message"></div>
                        <div *ngIf="!mensaje.AdminMessage" class="message">{{ mensaje.Message }}</div>
                        <div *ngIf="mensaje.AdminMessage" class="message-date">
                            {{ mensaje.StartDate | ToLocalTime }} - {{ mensaje.EndDate | ToLocalTime }}
                        </div>
                        <div *ngIf="!mensaje.AdminMessage" class="message-date">
                            {{ mensaje.Moment | ToLocalTime }}
                        </div>
                    </div>
                </div>

            </div>

            <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap" fxNgClass.gt-xs="less_top_margin_field" *ngIf="!hideNewAdminMessage">
                <div fxFlex="" fxFlex.lt-md="100%" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start start" fxNgClass.gt-xs="spe_gap">                
                    <label class="font_blue">{{'STUDENT.CONTACT.SEARCH-MESSAGE-FIELD' | translate }}</label>
                    <mat-form-field fxNgClass.xs="less_top_margin_field">
                        <input (ngModelChange)="searchMessages()" [matAutocomplete]="autoMessages" matInput type="text" class="valor" [(ngModel)]="inputMessage" [ngModelOptions]="{standalone: true}" style="margin-top: 20px !important;"/>
                        <mat-autocomplete autoActiveFirstOption #autoMessages="matAutocomplete">
                            <mat-option (click)="selectMessage(message)" *ngFor="let message of messagesFiltered" [value]="trim(message.Hashtag)">
                                #{{ message.Hashtag }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>

            <div class="textarea less_top_margin_field" *ngIf="!hideNewAdminMessage">

                <div class="ww_field_spe">
                    <textarea id="challenge-textarea" [(ngModel)]="adminMessage.textMessage" style="border: .05em solid; padding: 0.5rem !important; min-height: 10rem;" placeholder="Mensaje"></textarea>
                </div>

            </div>

            <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap" fxNgClass.gt-xs="less_top_margin_field" *ngIf="!hideNewAdminMessage">
                <div fxFlex="" fxFlex.lt-md="100%" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start start" fxNgClass.gt-xs="spe_gap">
                    <label class="font_blue">{{'STUDENT.CONTACT.SHOW-FROM-DATE-FILTER' | translate }}</label>
                    <mat-form-field fxNgClass.xs="less_top_margin_field">
                        <input matInput [matDatepicker]="pickerDesde" [(ngModel)]="adminMessage.startDate" [min]="minDate" style="margin-top: 20px !important;">
                        <mat-datepicker-toggle matSuffix [for]="pickerDesde"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDesde></mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start start" fxNgClass.xs="less_top_margin_field" fxNgClass.gt-xs="spe_gap">
                    <label class="font_blue">{{'STUDENT.CONTACT.SHOW-TO-DATE-FILTER' | translate }}</label>
                    <mat-form-field fxNgClass.xs="less_top_margin_field">
                        <input matInput [matDatepicker]="pickerHasta" [(ngModel)]="adminMessage.endDate" [min]="minDate" style="margin-top: 20px !important;">
                        <mat-datepicker-toggle matSuffix [for]="pickerHasta"></mat-datepicker-toggle>
                        <mat-datepicker #pickerHasta></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center" class="spe_gap" *ngIf="!hideNewAdminMessage">

                <button *ngIf="!checkNuevoAdminMessage()" [disabled]="checkValidAdminForm() == false || checkValidForm() == false" (click)="newAdminMessage()" class="new_but azul">
                    {{'STUDENT.CONTACT.SAVE-AND-SEND-BUTTON' | translate }}
                </button>

                <button *ngIf="checkNuevoAdminMessage()" [disabled]="checkValidAdminForm() == false || checkNuevoAdminMessage()  == false|| checkValidForm() == false" (click)="saveMessage()" class="new_but azul">
                    {{'STUDENT.CONTACT.SEND-BUTTON' | translate }}
                </button>

                <button (click)="editAdminMessage()" [disabled]="checkValidAdminForm() == false || !checkNuevoAdminMessage()" class="new_but azul">
                    {{'ADMIN.ADMIN-MESSAGE.EDIT-BUTTON' | translate }}
                </button>

            </div>
        </div>

        <div class="walinwa-box-content dudas_sugerencias" [hidden]="!hideStudentMessages || !hideAdminMessages || !hideNewAdminMessage || !hideConversation">

            <form name="contactForm" class="contactForm spe_gap" autocomplete="new-password" fxLayout="column" fxLayoutAlign="stretch">

                <div class="">
                    <label class="font_blue">{{'STUDENT.CONTACT.SUBJECT-LABEL' | translate }}</label>
                    <div class="ww_field_spe">
                        <input type="text" maxlength="70" name="Subject" [(ngModel)]="formData.Subject" required tabindex="1" />
                    </div>
                    <mat-error *ngIf="formDataErr1">{{'FIELD-REQUIRED' | translate }}</mat-error>
                </div>

                <div class="">
                    <label class="font_blue">{{'MESSAGE' | translate }}</label>
                    <textarea style="height: 9em; border: 0.05em solid var(--gris);" type="text" class="valor autofocus" maxlength="700" name="Body" [(ngModel)]="formData.Body" required tabindex="2" rows="10"></textarea>
                    <mat-error *ngIf="formDataErr2">{{'FIELD-REQUIRED' | translate }}</mat-error>
                </div>
                <div class="">
                    <label class="font_blue">{{'STUDENT.CONTACT.FILE-NAME-TELL-MESSAGE' | translate }}<span style="color: var(--gris);"> {{ this.attachment ? this.attachment.name : '' }}</span>
                        <br *ngIf="BigFile">
                        <mat-error *ngIf="BigFile">
                            {{'STUDENT.CONTACT.FILE-IS-TOO-BIG-ERROR' | translate }}
                        </mat-error>
                    </label>
                    <input hidden (change)="saveFile($event)" name="attachment" #fileInput type="file" />
                </div>

                <div class="">
                    <div class="caja naranja" style="color: white; background-color: var(--naranja); padding: 0.5em 0.5em;" [innerHTML]="'STUDENT.CONTACT.MESSAGES-ADVICES' | translate ">
                    </div>
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center" class="spe_gap">
                    <button (click)="fileInput.click()" [disabled]="sendingEmail" value="cargar" class="new_but azul">{{'STUDENT.CONTACT.ADD-FILE-BUTTON' | translate }}</button>
                    <button (click)="submitForm(formData)" [disabled]="sendingEmail" value="Enviar" class="new_but azul">{{'STUDENT.CONTACT.SEND-BUTTON' | translate }}</button>
                </div>

            </form>

        </div>

        <div class="walinwa-box-content" [hidden]="hideStudentMessages || !hideAdminMessages || !hideNewAdminMessage || !hideConversation">

            <div class="lista_mensajes">
                <div class="mensaje" *ngFor="let mensaje of studentMessages | sortBy:'desc':'Moment'">
                    <span class="asunto">{{ mensaje.Subject }}</span>
                    <span class="cuerpo">{{ mensaje.Message }}</span>
                    <span class="fecha">{{ mensaje.Moment | ToLocalTime }}</span>
                </div>
            </div>

        </div>

    </div>
</div>
