<section class="main_c_excersice gap_sm" fxLayout="column" fxLayoutAlign="stretch" [ngStyle]="getBackgroundStyle()">
    <div class="eheader gap_one" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="0 0 calc(100% - 20rem)" fxFlex.lt-md="100%" class="ecoins gap_one" fxLayout="row"
            fxLayoutAlign="start center">
            <div fxFlex.lt-md="4rem" fxFlex="8rem">
                <img src="https://walinwa.blob.core.windows.net/images/logo_walinwa_pantallas1.png?ngsw-bypass=true" style="width: 100%;"
                    [hidden]="default_logo == false" fxShow fxHide.lt-md />
                <img src="https://walinwa.blob.core.windows.net/images/walinwa-logo-mob.svg?ngsw-bypass=true" fxShow.lt-md fxHide />
            </div>
            <div class="enumber">
                {{ exerciseNumberToShow }}/{{ sessionLengthToShow }}
            </div>
            <div fxShow fxHide.lt-md fxFlex="" *ngIf="!review" class="ecoinsContainer" id="skillCoins"
                [ngClass]="{ 'element-collapse': reto }">
                <div *ngFor="let coin of skillCoins; let i = index" id="{{ i }}" class="coin"
                [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/' + (status == 'Saved' ? 'ico_walinwo.png&quot;)' : 'walinwo.gif&quot;)')"></div>
            </div>
            <div fxShow fxHide.lt-md fxFlex="" *ngIf="!review" class="ecoinsContainer" id="timeCoins" [ngClass]="{
                'element-collapse': timerEnabled == false || reto == true || this.authService.currentUser.MultTime === -1
            }">
                <div *ngFor="let coin of timeCoins; let i = index" id="{{ i }}" class="coin" [hidden]="this.authService.currentUser.MultTime === -1"
                [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/' + (status == 'Saved' ? 'ico_walinwo.png&quot;)' : 'walinwo.gif&quot;)')"></div>
            </div>
            <div fxShow fxHide.lt-md fxFlex="" *ngIf="review" class="ecoinsContainer gap_one" fxLayout="row"
                fxLayoutAlign="space-between center">
                <div fxFlex="">
                    <span class="nameContainerLarge">{{ fullName }}</span>
                </div>
                <div [ngClass]="{ 'element-hidden': sessionScore == null }">
                    {{'COMPONENTS.PLANK.EXERCISE-GRADE' | translate }} {{ sessionScore }}
                    <span [ngClass]="{ 'element-hidden': sessionScoreNew === null }" style="padding-left: 20px;">
                        {{'STUDENT.EXERCISE.FINAL-SCORE' | translate}} {{ sessionScoreNew }}
                    </span>
                </div>
            </div>
        </div>
        <div class="eicons" fxNgClass.xs="gap_sm" fxNgClass.gt-xs="gap_one" fxFlex.gt-md="14.5rem" fxLayout="row"
            fxLayoutAlign="start center" fxLayoutAlign.lt-md="end center">
            <div *ngIf="review">
                <img [attr.title]="solTitle" (click)="showAnswer()" [src]="getIconResult()" class="eimageButton"
                    [class]="{invisible : exerciseNumber == 0}" />
            </div>
            <div *ngIf="review">
                <img (click)="exerciseBack()" src="https://walinwa.blob.core.windows.net/images/Exercises/ico_back.png?ngsw-bypass=true" class="eimageButton"
                    [ngClass]="{ disabled: exerciseBackDisabled() }" />
            </div>
            <div>
                <img *ngIf="review" (click)="continueReview()" src="https://walinwa.blob.core.windows.net/images/Exercises/but-play.svg?ngsw-bypass=true" class="eimageButton"
                    [ngClass]="{ disabled: continueReviewDisabled() }" />
                <img *ngIf="!review" (click)="continue(true)" src="https://walinwa.blob.core.windows.net/images/Exercises/but-play.svg?ngsw-bypass=true" class="eimageButton"
                    [ngClass]="{ disabled: ((status != 'Saved' || continueDisabled) && !showJump) || loadingExercise }" />
            </div>
            <div *ngIf="!review">
                <img (click)="openHelp()" src="https://walinwa.blob.core.windows.net/images/Exercises/but-dic.svg?ngsw-bypass=true" class="eimageButton"
                    [ngClass]="{ disabled: helpDisabled }" />
            </div>
            <div>
                <img (click)="goHome()" src="https://walinwa.blob.core.windows.net/images/but-home.svg?ngsw-bypass=true" class="eimageButton" [ngClass]="{
                        disabled:
                            (homeEnabled == false || status != 'Saved') &&
                            showJump == false
                    }" />
            </div>
        </div>
    </div>
    <div class="eheader mobile gap_one" fxLayout="column" fxLayoutAlign="stretch" fxHide fxShow.lt-md>
        <div class="ecoins mobile gap_one" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="" *ngIf="!review" class="ecoinsContainer" id="skillCoins"
                [ngClass]="{ 'element-collapse': reto }">
                <div *ngFor="let coin of skillCoins; let i = index" id="{{ i }}" class="coin"
                [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/' + (status == 'Saved' ? 'ico_walinwo.png&quot;)' : 'walinwo.gif&quot;)')"></div>
            </div>
            <div fxFlex="" *ngIf="!review" class="ecoinsContainer" id="timeCoins" [ngClass]="{
                'element-collapse': timerEnabled == false || reto == true || this.authService.currentUser.MultTime === -1
            }">
                <div *ngFor="let coin of timeCoins; let i = index" id="{{ i }}" class="coin" [hidden]="this.authService.currentUser.MultTime === -1"
                [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/' + (status == 'Saved' ? 'ico_walinwo.png&quot;)' : 'walinwo.gif&quot;)')"></div>
            </div>
            <div fxFlex="" *ngIf="review" class="ecoinsContainer gap_one" fxLayout="row"
                fxLayoutAlign="space-between center">
                <div fxFlex="">
                    <span class="nameContainerLarge">{{ fullName }}</span>
                </div>
                <div [ngClass]="{ 'element-hidden': sessionScore == null }">
                    {{'COMPONENTS.PLANK.EXERCISE-GRADE' | translate }} {{ sessionScore }} {{ sessionScore }}
                    <span [ngClass]="{ 'element-hidden': sessionScoreNew === null }" style="padding-left: 20px;">
                        {{'STUDENT.EXERCISE.FINAL-SCORE' | translate}} {{ sessionScoreNew }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="ebody" ngClass="gap_two" fxNgClass.lt-md="gap_one" fxFlex="" fxLayout="row" fxLayout.lt-md="column"
        fxLayoutAlign="start stretch" fxLayoutAlign.lt-md="stretch">
        <img *ngIf="premium && noche" class="fuego" [ngClass]="getPremiumFireClass()" [src]="getFuegoImg()" (error)="onImgError($event)" 
            fxHide.lt-md fxShow />
        <img *ngIf="premium && noche && (IdCivilization == 7 || IdCivilization == 5)" class="fuego2"
            [ngClass]="{'farabe2': IdCivilization == 7, 'fmaya2': IdCivilization == 5}"
            [src]="getFuegoImg()" (error)="onImgError($event)"  fxHide.lt-md fxShow />
        <img *ngIf="premium && noche && IdCivilization == 7" class="fuego3 farabe3" [src]="getFuegoImg()" (error)="onImgError($event)" 
            fxHide.lt-md fxShow />
        <img *ngIf="premium && noche && IdCivilization == 7" class="fuego4 farabe4" [src]="getFuegoImg()" (error)="onImgError($event)" 
            fxHide.lt-md fxShow />
        <div fxFlex.gt-sm="8rem" fxLayout="row">
            <div class="timer_desktop" fxShow fxHide.lt-md>
                <img [hidden]="review || !timerEnabled || this.authService.currentUser.MultTime === -1" [ngStyle]="{ bottom: getCurrentPorcent() + '%' }"
                    [src]="timerLineUlr" class="timer_line" [ngClass]="{ rowTimerPremium: premium, timerLineSpecial: special }" />
            </div>
            <div class="progressbar" fxShow.lt-md fxHide>
                <div class="timeri" [ngStyle]="{ width: getCurrentPorcentMobile() + '%' }"></div>
            </div>
        </div>
        <div fxFlex="0 0 calc(100% - 16rem)" fxFlex.lt-md="">

            <!-- Extra Messages -->
            <div class="exercise_over" *ngIf="badges.timeExpired && !showTablon">
                <img (click)="showSolution()" class="time_expired" [src]="timeOutMessageImg" />
            </div>

            <div class="exercise_over two" *ngIf="badges.exerciseEnded && !showTablon">
                <img *ngIf="!alertMsg && this.status == 'Saved'" (click)="continue()" class="exercise_ended"
                    [src]="okMessageImg" />
                <img *ngIf="alertMsg=='messageCircleTimeOk1'" (click)="continue()" class="exercise_ended" 
                src="https://walinwa.blob.core.windows.net/images/Exercises/message_backgroud_w1.png?ngsw-bypass=true" />
                <img *ngIf="alertMsg=='messageCircleTimeOk2'" (click)="continue()" class="exercise_ended" 
                src="https://walinwa.blob.core.windows.net/images/Exercises/message_backgroud_w2.png?ngsw-bypass=true" />
            </div>

            <!-- Sub-modules -->
            <ex-guionp *ngIf="!showEndSession && exerciseModel == 'guionp'" [review]="review"
                [show_errors]="show_errors"></ex-guionp>
            <ex-boxes2 *ngIf="!showEndSession && exerciseModel == 'boxes2'" [review]="review"
                [show_errors]="show_errors"></ex-boxes2>
            <ex-boxes2b *ngIf="!showEndSession && exerciseModel == 'boxes2b'" [review]="review"
                [show_errors]="show_errors"></ex-boxes2b>
            <ex-boxes3 *ngIf="!showEndSession && exerciseModel == 'boxes3'" [review]="review"
                [show_errors]="show_errors"></ex-boxes3>
            <ex-guionf *ngIf="!showEndSession && exerciseModel == 'guionf'" [review]="review"
                [show_errors]="show_errors"></ex-guionf>
            <ex-marca
                *ngIf="!showEndSession && exerciseModel == 'marca' || exerciseModel == 'marca.1' || exerciseModel == 'marca.2' || exerciseModel == 'marca.3'"
                [review]="review" [show_errors]="show_errors"></ex-marca>
            <ex-marcar *ngIf="!showEndSession && exerciseModel == 'marcar'" [review]="review"
                [show_errors]="show_errors"></ex-marcar>
            <ex-pregunta *ngIf="!showEndSession && exerciseModel == 'pregunta'" [review]="review"
                [show_errors]="show_errors"></ex-pregunta>
            <ex-unir *ngIf="!showEndSession && exerciseModel == 'unir'" [review]="review"
                [show_errors]="show_errors"></ex-unir>
            <ex-unir2 *ngIf="!showEndSession && exerciseModel == 'unir2'" [review]="review"
                [show_errors]="show_errors"></ex-unir2>
            <ex-unir3 *ngIf="!showEndSession && exerciseModel == 'unir3'" [review]="review"
                [show_errors]="show_errors"></ex-unir3>
            <ex-unir4 *ngIf="!showEndSession && exerciseModel == 'unir4'" [review]="review"
                [show_errors]="show_errors"></ex-unir4>
            <ex-unir4a *ngIf="!showEndSession && exerciseModel == 'unir4a'" [review]="review"
                [show_errors]="show_errors"></ex-unir4a>
            <ex-unir5 *ngIf="!showEndSession && exerciseModel == 'unir5'" [review]="review"
                [show_errors]="show_errors"></ex-unir5>
            <ex-unir6 *ngIf="!showEndSession && exerciseModel == 'unir6'" [review]="review"
                [show_errors]="show_errors"></ex-unir6>
            <ex-mLluvia *ngIf="!showEndSession && exerciseModel == 'mLluvia'" [review]="review"
                [show_errors]="show_errors"></ex-mLluvia>
            <ex-parejas *ngIf="!showEndSession && exerciseModel == 'parejas'" [review]="review"
                [show_errors]="show_errors"></ex-parejas>
            <ex-boxes2c *ngIf="!showEndSession && exerciseModel == 'boxes2c'" [review]="review"
                [show_errors]="show_errors"></ex-boxes2c>
            <ex-boxes3c *ngIf="!showEndSession && exerciseModel == 'boxes3c'" [review]="review"
                [show_errors]="show_errors"></ex-boxes3c>
            <ex-boxes4c *ngIf="!showEndSession && exerciseModel == 'boxes4c'" [review]="review"
                [show_errors]="show_errors"></ex-boxes4c>
            <ex-define *ngIf="!showEndSession && exerciseModel == 'define'" [review]="review"
                [show_errors]="show_errors"></ex-define>
            <ex-mod1 *ngIf="!showEndSession && exerciseModel == 'mod1'" [review]="review"
                [show_errors]="show_errors"></ex-mod1>
            <ex-mod2 *ngIf="!showEndSession && exerciseModel == 'mod2'" [review]="review"
                [show_errors]="show_errors"></ex-mod2>
            <ex-selec *ngIf="!showEndSession && exerciseModel == 'selec'" [review]="review"
                [show_errors]="show_errors"></ex-selec>
            <ex-flechas *ngIf="!showEndSession && exerciseModel == 'flechas'" [review]="review"
                [show_errors]="show_errors"></ex-flechas>
            <ex-orden *ngIf="!showEndSession && exerciseModel == 'orden'" [review]="review"
                [show_errors]="show_errors"></ex-orden>
            <ex-coloca *ngIf="!showEndSession && exerciseModel == 'coloca'" [review]="review"
                [show_errors]="show_errors"></ex-coloca>
            <ex-marca1 *ngIf="!showEndSession && exerciseModel == 'marca1'" [review]="review"
                [show_errors]="show_errors" [events]="eventsSubject.asObservable()"></ex-marca1>
            <ex-verbos *ngIf="!showEndSession && exerciseModel == 'verbos'" [review]="review"
                [show_errors]="show_errors"></ex-verbos>
            <ex-rapid *ngIf="!showEndSession && exerciseModel == 'rapid'" [review]="review"
                [show_errors]="show_errors"></ex-rapid>
            <ex-ahorca *ngIf="!showEndSession && exerciseModel == 'ahorca'" [review]="review"
                [show_errors]="show_errors"></ex-ahorca>
            <ex-cruci *ngIf="!showEndSession && exerciseModel == 'cruci'" [review]="review"
                [show_errors]="show_errors"></ex-cruci>
            <ex-marca-t *ngIf="!showEndSession && exerciseModel == 'marcaT'" [review]="review"
                [show_errors]="show_errors"></ex-marca-t>
            <ex-marca-f *ngIf="!showEndSession && exerciseModel == 'marcaf'" [review]="review"
                [show_errors]="show_errors"></ex-marca-f>
            <ex-colocaf *ngIf="!showEndSession && exerciseModel == 'colocaf'" [review]="review"
                [show_errors]="show_errors"></ex-colocaf>
            <ex-selecf *ngIf="!showEndSession && exerciseModel == 'selecf'" [review]="review"
                [show_errors]="show_errors"></ex-selecf>
            <ex-mod3 *ngIf="!showEndSession && exerciseModel == 'mod3'" [review]="review"
                [show_errors]="show_errors"></ex-mod3>
            <ex-numer *ngIf="!showEndSession && exerciseModel == 'numer'" [review]="review"
                [show_errors]="show_errors"></ex-numer>
            <ex-boxesn *ngIf="!showEndSession && exerciseModel == 'boxesn'" [review]="review"
                [show_errors]="show_errors"></ex-boxesn>
            <ex-sopa *ngIf="!showEndSession && exerciseModel == 'sopa'" [review]="review"
                [show_errors]="show_errors"></ex-sopa>
            <ex-ahorcafr *ngIf="!showEndSession && exerciseModel == 'ahorcafr'" [review]="review"
                [show_errors]="show_errors"></ex-ahorcafr>
            <ex-ahorcai *ngIf="!showEndSession && exerciseModel == 'ahorcai'" [review]="review"
                [show_errors]="show_errors"></ex-ahorcai>
            <ex-ahorcar *ngIf="!showEndSession && exerciseModel == 'ahorcar'" [review]="review"
                [show_errors]="show_errors"></ex-ahorcar>
            <ex-coloca1 *ngIf="!showEndSession && exerciseModel == 'coloca1'" [review]="review"
                [show_errors]="show_errors"></ex-coloca1>
            <ex-frase *ngIf="!showEndSession && exerciseModel == 'frase'" [review]="review"
                [show_errors]="show_errors"></ex-frase>
            <ex-opc1 *ngIf="!showEndSession && exerciseModel == 'opc1'" [review]="review"
                [show_errors]="show_errors"></ex-opc1>
            <ex-img *ngIf="!showEndSession && exerciseModel == 'img'" [review]="review"
                [show_errors]="show_errors"></ex-img>
            <ex-pasap *ngIf="!showEndSession && exerciseModel == 'pasap'" [review]="review"
                [show_errors]="show_errors"></ex-pasap>
            <ex-pasap1 *ngIf="!showEndSession && exerciseModel == 'pasap1'" [review]="review"
                [show_errors]="show_errors"></ex-pasap1>
            <ex-rima *ngIf="!showEndSession && exerciseModel == 'rima'" [review]="review"
                [show_errors]="show_errors"></ex-rima>
            <ex-sopa1 *ngIf="!showEndSession && exerciseModel == 'sopa1'" [review]="review"
                [show_errors]="show_errors"></ex-sopa1>
            <ex-sopa2 *ngIf="!showEndSession && exerciseModel == 'sopa2'" [review]="review"
                [show_errors]="show_errors"></ex-sopa2>
            <ex-verbos1 *ngIf="!showEndSession && exerciseModel == 'verbos1'" [review]="review"
                [show_errors]="show_errors"></ex-verbos1>
            <ex-siglas *ngIf="!showEndSession && exerciseModel == 'siglas'" [review]="review"
                [show_errors]="show_errors"></ex-siglas>
        </div>
    </div>

    <div class="temp_efooter" *ngIf="!review"
        [ngClass]="{'boxesn': exerciseModel == 'boxesn', 'marca': exerciseModel == 'marca' || exerciseModel == 'marca.1' || exerciseModel == 'marca.2' || exerciseModel == 'marca.3', 'ahorcai': exerciseModel == 'ahorcai', 'ahorca': exerciseModel == 'ahorca', 'ahorcafr': exerciseModel == 'ahorcafr','ahorcar': exerciseModel == 'ahorcar','cruci': exerciseModel == 'cruci', 'unir2': exerciseModel == 'unir2', 'sopa2': exerciseModel == 'sopa2', 'sopa1': exerciseModel == 'sopa1', 'sopa': exerciseModel == 'sopa'}">
    </div>

    <div class="efooter" fxNgClass.xs="gap_sm" fxNgClass.gt-xs="gap_one" fxLayout="row" fxLayoutAlign="start center"
        *ngIf="review">
        <div fxFlex="18rem" fxFlex.xs="12rem"></div>
        <div fxFlex="" class="gap_one" fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.lt-md="end center">
            <div *ngIf="duracion_session">
                <span fxHide.xs fxShow>Sesión: {{ duracion_session.toFixed(2) }} Min.</span>
                <span fxHide fxShow.xs>S: {{ duracion_session.toFixed(2) }} M.</span>
            </div>
            <div *ngIf="duracion_ejercicio">
                <span fxHide.xs fxShow>Ejercicio: {{ duracion_ejercicio.toFixed(2) }} seg.</span>
                <span fxHide fxShow.xs>E: {{ duracion_ejercicio.toFixed(2) }} S.</span>
            </div>
            <div fxHide.xs>P: {{penalty.toFixed(2)}}</div>
            <div *ngIf="SkillCoinsWon && SkillCoins" fxHide.xs>
                Ws: {{ SkillCoinsWon }}/{{ SkillCoins }}
            </div>
            <div *ngIf="TimeCoinsWon && TimeCoins" fxHide.xs>
                Wt: {{ TimeCoinsWon }}/{{ TimeCoins }}
            </div>
        </div>
        <div class="gap_one" fxLayout="row" fxShow fxHide.lt-md>
            <div *ngIf="sessionId">s-{{ sessionId }}</div>
            <div *ngIf="exerciseId">j-{{ exerciseId }}</div>
            <div *ngIf="student">u-{{ student.Id }}</div>
        </div>
    </div>
</section>

<reviewTheory *ngIf="showHelp" [help]="help" (changeShowHelp)="changeShow($event)">
</reviewTheory>

<plank-selector title="Sesión terminada" [isFinAnticipado]="false" [extraWalis]="extraWalis" [show]="showTablon" [showDiploma]="showDiploma"
    [mark]="mark" [markRetrieve]=markRetrieve [diplomaImageUrl]="diplomaImageUrl" [messages]="messages" [walinwos]="walinwos" [errors]="getErrores()" (onClose)="finishSession()"
    *ngIf="showEndSession && showHelp != true && medal == null && reto != true"></plank-selector>

<plank-selector title="¡Has finalizado el reto!" [isFinAnticipado]="false" [show]="showTablon"
    [showDiploma]="showDiploma" [diplomaImageUrl]="diplomaImageUrl" [mark]="mark" [messages]="messages" [walinwos]="walinwos" [errors]="getErrores()"
    (onClose)="finishSession()"
    *ngIf="showEndSession && showHelp != true && medal == null && reto == true"></plank-selector>

<div id="medal" class="medalBack" *ngIf="medal">
    <div class="medalContainer" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Medals/medalBackground.png&quot;)'" (click)="closeMedal()" [hidden]="showMedal != true">
        <div class="medalImageContanier" [style.background-image]="getMedalImage()"></div>
        <div class="medalTextContainer" [ngClass]="getMedalTextClass()" [innerHtml]="getMedalText()"></div>
    </div>
    <div class="medalContainer" (click)="closeMedal()" fxLayout="row" fxLayout.xs="column" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Medals/medalBackground.png&quot;)'"
        fxLayoutAlign="space-between center" fxLayoutAlign.xs="center stretch" [hidden]="showTrophy != true">
        <div fxFlex="35%" fxFlex.xs="" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Medals/trofeoFull.gif&quot;)'" class="medalImageTrophyContanier"></div>
        <div fxFlex="" class="medalTextTrophyContainer medalTextMaster" [innerHtml]="getMedalMasterText()"></div>
    </div>
</div>