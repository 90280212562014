<div class="walinwa-box" [hidden]="testVisible" data-help-image-number="33">
    <div class="walinwa-box walinwa-box-green">
        <div class="gray-background walinwa-box-content" style="padding-bottom: 0px !important;">
            <div class="ww_table_rwd">
                <dx-data-grid #dataGrid [keyExpr]="['IdLee', 'IdSection', 'IdUser']" [allowColumnResizing]="true" [dataSource]="results$ | async" [repaintChangesOnly]="false" (onInitialized)="$event.component.columnOption('command:expand', 'width', 30)" height="100%">
                    <dxo-load-panel [enabled]="true"></dxo-load-panel>
                    <dxo-scrolling mode="standard" [useNative]="false"></dxo-scrolling>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-header-filter [visible]="false"></dxo-header-filter>
                    <dxo-search-panel [visible]="false"></dxo-search-panel>
                    <dxo-group-panel [visible]="false" [allowColumnDragging]="false"></dxo-group-panel>
                    <dxo-grouping [contextMenuEnabled]="false" [autoExpandAll]="true"></dxo-grouping>
                    <dxo-column-chooser [enabled]="false" mode="select"></dxo-column-chooser>
                    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
                    
                    <dxi-column dataField="IdClass" [caption]="'CLASS' | translate" dataType="number" cellTemplate="classTemplate" groupCellTemplate="classGroupTemplate" [groupIndex]="0" [showWhenGrouped]="true" [allowHiding]="false" alignment="left" width="15%">
                        <dxo-lookup [dataSource]="classes" valueExpr="IdClass" displayExpr="Name"></dxo-lookup>
                        <div *dxTemplate="let class of 'classTemplate'">
                            <!--span class="must-hide"></span-->
                        </div>
                        <div *dxTemplate="let class of 'classGroupTemplate'">
                            {{'CLASS' | translate}}: <span [innerHTML]="getClassName(class.data.key)"></span>
                        </div>
                    </dxi-column>
                    <dxi-column dataField="IdLee" [caption]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-RESULTS.BOOK' | translate" dataType="number" cellTemplate="bookTemplate" groupCellTemplate="bookGroupTemplate" [editorOptions]="{ itemTemplate: 'itemBookTemplate' }" [groupIndex]="1" [showWhenGrouped]="true" [allowHiding]="false" alignment="left" width="40%">
                        <dxo-lookup [dataSource]="books" valueExpr="IdLee" [displayExpr]="title">
                            <div *dxTemplate="let book of 'itemBookTemplate'">
                                <span [innerHTML]="getBookTitle(book?.IdLee, '(Todos)')"></span>
                            </div>
                        </dxo-lookup>
                        <div *dxTemplate="let book of 'bookTemplate'">
                            <!--span class="must-hide"></span-->
                        </div>
                        <div *dxTemplate="let book of 'bookGroupTemplate'">
                            {{'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-RESULTS.BOOK' | translate}}: <span [innerHTML]="getBookTitle(book.data.key)"></span>
                        </div>
                    </dxi-column>
                    <dxi-column dataField="BookStartDate" [caption]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-RESULTS.FROM' | translate" dataType="date" format="dd/MM/yyyy" [allowGrouping]="false" [visible]="false" [editorOptions]="editorOptions"></dxi-column>
                    <dxi-column dataField="BookEndDate" [caption]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-RESULTS.TO' | translate" dataType="date" format="dd/MM/yyyy" [allowGrouping]="false" [visible]="false" [editorOptions]="editorOptions"></dxi-column>
                    <dxi-column dataField="IdUser" caption="Alumno" dataType="number" cellTemplate="studentTemplate" groupCellTemplate="studentTemplate" [showWhenGrouped]="true" [allowHiding]="false" [allowGrouping]="true" alignment="left" width="20%">
                        <dxo-lookup [dataSource]="students" valueExpr="IdUser" [displayExpr]="studentName">
                            <div *dxTemplate="let student of 'itemStudentTemplate'">
                                {{ studentName(student.data) }}
                            </div>
                        </dxo-lookup>
                        <div *dxTemplate="let student of 'studentTemplate'">
                            {{ studentName(student.data) }}
                        </div>
                    </dxi-column>
                    <dxi-column dataField="CurrentSections" [caption]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-RESULTS.COMPLETION' | translate" dataType="number" cellTemplate="totalSectionsTemplate" [allowGrouping]="false" [allowFiltering]="false" alignment="left" width="20%">
                        <div *dxTemplate="let totalSections of 'totalSectionsTemplate'">
                            <dx-bullet [showTarget]="true" color="#FFC000" [target]="totalSections.data.TotalSections " [showZeroLevel]="true" [startScaleValue]="0" [endScaleValue]="totalSections.data.TotalSections" [value]="totalSections.data.CurrentSections">
                                <dxo-size [width]="auto" [height]="25"></dxo-size>
                                <dxo-tooltip [enabled]="true" [paddingTopBottom]="2" [customizeTooltip]="customizeTooltip" zIndex="99">
                                <dxo-font [size]="18"></dxo-font>
                                </dxo-tooltip>
                                <dxo-margin [top]="5" [bottom]="0" [left]="5"></dxo-margin>
                            </dx-bullet>
                        </div>
                    </dxi-column>
                    <div *dxTemplate="let studentBook of 'detail'">
                        <dx-data-grid id="detail" #detailDataGrid [keyExpr]="['IdLee', 'IdSection', 'IdUser']" [allowColumnResizing]="true" [dataSource]="getStudentDataSource(studentBook)" [repaintChangesOnly]="true">
                            <dxo-load-panel [enabled]="false"></dxo-load-panel>
                            <dxo-filter-row [visible]="false"></dxo-filter-row>
                            <dxo-header-filter [visible]="false"></dxo-header-filter>
                            <dxo-search-panel [visible]="false"></dxo-search-panel>
                            <dxo-group-panel [visible]="false" [allowColumnDragging]="false"></dxo-group-panel>
                            <dxo-grouping [contextMenuEnabled]="false"></dxo-grouping>
                            <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
                            <dxi-column dataField="SectionTitle" [caption]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-RESULTS.SECTION' | translate" dataType="string"></dxi-column>
                            <dxi-column dataField="StartDate" [caption]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-RESULTS.DATE' | translate" dataType="date" format="dd/MM/yyyy" width="15%"></dxi-column>
                            <dxi-column dataField="Retries" [caption]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-RESULTS.RETRIES' | translate" dataType="number" alignment="left" width="15%"></dxi-column>
                            <dxi-column dataField="NumOk" [caption]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-RESULTS.SCORE' | translate" dataType="number" cellTemplate="resultTemplate" alignment="left" width="15%">
                                <div *dxTemplate="let result of 'resultTemplate'">
                                    {{ result.data.NumOk }} / {{ result.data.NumExercises }}
                                </div>
                            </dxi-column>
                            <dxi-column dataField="_" [caption]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-RESULTS.TEST' | translate" dataType="object" cellTemplate="testTemplate" width="50px" minWidth="50px" [allowResizing]="false" [allowSorting]="false" [allowFiltering]="false" [allowGrouping]="false" [allowReordering]="false">
                                <div *dxTemplate="let test of 'testTemplate'">
                                    <i class="dx-icon dx-icon-eyeopen" style="font-size: 24px !important;" (click)="showTest(test.data)" title="Ver test"></i>  
                                </div>
                            </dxi-column>
                        </dx-data-grid>
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </div>    
</div>

@if (testVisible) {
    <walilee-test [book]="book" [section]="section" [idUser]="idUser" (onClose)="onClose($event)"></walilee-test>
}