import { TranslateService } from '@ngx-translate/core';
import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
    ViewEncapsulation,
    ViewChild,
} from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { SchoolService } from "app/services/school.service";
import { FuseDialogContinueComponent } from "@fuse/components/dialog-continue/dialog-continue.component";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import {
    NgForm,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { CourseService } from "app/services/course.service";
import { UiService } from "@fuse/components/spinner/ui.service";
import { SharedService } from "app/core/shared/shared.service";
import { NavigationService } from "app/services/navigation.service";
import { Subject, forkJoin } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { Profiles } from "app/core/shared/session.model";

declare var alasql;
declare var XLSX;
declare var $;

@Component({
    selector: "school-admin-classes",
    templateUrl: "./admin-classes.component.html",
    styleUrls: ["./admin-classes.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AdminClassesComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    private _idClassToShow: number = null;

    navigationSubscription;
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;;
    currentUser;
    importExcel = false;
    editFromClass = false;
    teachers = [];
    school;
    email;
    error;
    errorDescription;
    dataLoaded = false;
    formDataStudent: UntypedFormGroup;
    dataLoaded2 = false;
    cursoDemo = null;
    selectedAuthUser = null;
    public loading = false;
    selectedUser = null;
    selectedUserOld = null;
    selectedClass = null;
    numStd = 10;
    extraFields = false;
    selectedTeacherAux;

    

    excelAlumnos = "1";
    escelOpt = "1";
    classLetters = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "Ñ",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
    ];
    state;
    calling;
    currentClass;
    checkIsAdmin;
    teacher;
    isAdmin;
    teachersAux = [];
    formDataClass: UntypedFormGroup;
    formDataClasses: UntypedFormGroup;
    schoolAdminView = true;
    numStudentsError;
    courses;
    showStudentId = false;
    showClassId = false;
    activeItems;
    @ViewChild('formDataClassDirective') private formDataClassDirective: NgForm;
    @ViewChild('formDataClassesDirective') private formDataClassesDirective: NgForm;
    multiTimes = [
        
        {
            value: 1,
            name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-DEFAULT'),
        },
        {
            value: -1,
            name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-NO-TIME')
        },
        {
            value: 1.5,
            name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MODERATE'),
        },
        {
            value: 2,
            name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MEDIUM'),
        },
        {
            value: 2.5,
            name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-HIGH'),
        },
        {
            value: 3,
            name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-HIGHER'),
        },
        {
            value: 4,
            name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MAX'),
        },
    ];
    isBackground: boolean;
    msg1 = [];
    msg2 = [];
    showMessage: boolean = false;
    isIpadIphone: boolean;
    runSchoolFirstTime = true;
    regExp = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜäÄëËïÏöÖç\s\.\-'ª’]+$/u;

    constructor(
        private router: Router,
        public schoolService: SchoolService,
        private _matDialog: MatDialog,
        private _formBuilder: UntypedFormBuilder,
        private courseService: CourseService,
        private _ui: UiService,
        private _sharedService: SharedService,
        private navigationService: NavigationService,
        private changeDetectorRef: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private _translateService: TranslateService
    ) {  
        this._unsubscribeAll = new Subject();
        this._ui.spin$.next(true);
        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
                this.init();
            }
        });
    }

    ngOnDestroy() {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        this.activatedRoute.paramMap.pipe(takeUntil(this._unsubscribeAll), map(p => p.get('idClass') ? Number(p.get('idClass')) : null)).subscribe(idClass => {
            this._idClassToShow = idClass;

            this.courseService.Courses.pipe(takeUntil(this._unsubscribeAll)).subscribe(courses => {
                this.courses = courses;
            });

            this.init();
            this.isIpadIphone = this.detectiPadiPhone();
        });
    }

    detectiPadiPhone(): boolean {
        const userAgent = window.navigator.userAgent;
        return /iphone|iPad/i.test(userAgent);
    }

    init() {
        if (!sessionStorage.getItem("currentUser")) {
            this.goLogin();
            return;
        }
        this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        this.navigationService.reset();
        if (
            !this.navigationService.params.IdClass ||
            !this.navigationService.params.IdUser ||
            !this.navigationService.params.isAdmin
        ) {
            this._ui.showSpinner();
        }
        this.school = this.schoolService.school;
        this._ui.stopSpinner();
        this.dataLoaded = true;
        this.calling = false;
        this.loading = true;
        this.loadClassesTeachersStudents();

        this._sharedService.run_get_school
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                if(data === true && this.runSchoolFirstTime === true){
                    this.runSchoolFirstTime = false;
                    this.getSchool();
                    return;
                }
        });
        
    }

    getSchool() {
        this.schoolService
          .getSchoolByUser(this.currentUser.Id)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            this.schoolService.school = result;
            this._sharedService.changeSchoolValidUntil(result.ValidUntil);
            this._sharedService.changeRunGetSchool(false);
            this.dataLoaded = true;
          });
      }

    selectUser(user: any) {
        this.selectedUser = user;
    }

    public clearSelectedUser(): void {
        this.selectedUser = null;

    }
    selectClass(clase: any) {
        this.currentClass = clase;

        if (!this.currentClass) {
            return;
        }
        if (this.currentClass.AuthorizedParents.length > 0 && this.teachers.length > 0) {
            this.currentClass.AuthorizedParents.forEach(AuthTeacher => {
                AuthTeacher.UserTutor = this.teachers.find(x => x.Id == AuthTeacher.IdUserTutor)
            });
        }
        this.currentClass.Students.forEach(student => {
            student.Course = this.courses.find(x => x.Level == student.Level)
        });
    }

    changeProfile() { }

    getCleanedString(cadena) {
        var specialChars = "!@#$^&%*()+=-[]/{}|:<>?,.";
        for (var i = 0; i < specialChars.length; i++) {
            cadena = cadena.replace(
                new RegExp("\\" + specialChars[i], "gi"),
                ""
            );
        }
        cadena = cadena.toLowerCase();
        cadena = cadena.replace(/ /g, "_");
        cadena = cadena.replace(/á/gi, "a");
        cadena = cadena.replace(/é/gi, "e");
        cadena = cadena.replace(/í/gi, "i");
        cadena = cadena.replace(/ó/gi, "o");
        cadena = cadena.replace(/ú/gi, "u");
        cadena = cadena.replace(/ñ/gi, "n");
        return cadena;
    }

    getAlulaToImport() {
        return this.currentClass;
    }

    letras(str) {
        return str.length;
    }

    importData(data) {
        var listado = this.getEstructuraExcelCorrecta(data);

        if (listado == null) {
            var excelFile: any = document.getElementById("excelFile");
            excelFile.value = "";
            return;
        }

        this.loading = true;
        this.importExcel = true;

        this.schoolService
            .getStudentsName(
                this.school.IdSchool,
                this.school.Initials,
                listado,
                this.getAlulaToImport().Code,
                true
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.successGetStudentsName(result);
                },
                (error) => {
                    this.errorGetStudentsName(error.error);
                }
            );
    }
    ShowClassErrorMessage(title : string) {
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = title
        confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.ADMIN.MODAL-NEW-CLASS-ERROR-MESSAGE');
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;
        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                confirmDialog = null;
            });
        return;
    }
    showMaxLicensesMessage(numStudents: number, title) {
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = title;
        confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.ADMIN.MODAL-MAX-LICENSES-MESSAGE', {numLicenses: numStudents, totalLicenses: (this.school.MaxNumStudents - this.school.NumStudents)});
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;
        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                confirmDialog = null;
            });
        return;
    }

    showModalSelectSheet(sheets, event) {
        var message = [];
        message[0] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.EXCEL-SELECT-SHEET-TITLE');
        message[1] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.EXCEL-SELECT-SHEET-MESSAGE');

        let options = [];
        for (let sheet of sheets) {
            options.push({
                text: sheet,
                callback: () => {
                    alasql(
                        'SELECT * FROM XLSX(?,{headers:false,sheetid:"' +
                        sheet +
                        '"})',
                        [event],
                        (data) => {
                            this.importData(data);
                        }
                    );
                    let excelFile: any = document.getElementById("excelFile");
                    excelFile.value = "";
                },
            });
        }

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.options = options;
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.canClose = true;
        confirmDialog.componentInstance.theme = "white";

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                result();
                this.confirmDialog = null;
            });
    }

    generateStudents() {
        if (this.calling) return;
        var numStudents = this.numStd;
        this.numStudentsError = 0;
        if (numStudents == null || isNaN(numStudents) === true) {
            this.numStudentsError = 1;
            return;
        }
        if (numStudents <= 0 || numStudents > 50) {
            this.numStudentsError = 2;
            return;
        }
        if (this.school.NumStudents + numStudents > this.school.MaxNumStudents) {
            this.showMaxLicensesMessage(numStudents, this._translateService.instant('SCHOOL.MODAL-ERROR-GENERATE-STUDENTS'));
            return;
        }
        var listado = [];
        var offset = this.getAlulaToImport().NumStudents;
        if (offset == null && this.getAlulaToImport().Students != null)
            offset = this.getAlulaToImport().Students.length;
        if (offset == null) offset = 0;
        var classCode = this.getAlulaToImport().Code;
        var dotIndex = classCode.indexOf(".");
        if (dotIndex >= 0) dotIndex++;
        classCode = classCode.substring(dotIndex, classCode.lenght);

        for (var i = 0; i < numStudents; i++) {
            var nombre = classCode.substring(0, 2);
            var apellido =
                classCode.substring(1, classCode.lenght) + (i + 1 + offset);
            listado.push({ Nombre: nombre, Apellidos: apellido });
        }
        this.calling = true;
        this.importExcel = false;
        this._ui.showSpinner();
        this.schoolService
            .getStudentsName(
                this.school.IdSchool,
                this.school.Initials,
                listado,
                this.getAlulaToImport().Code
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.successGetStudentsName(result);
                },
                (error) => {
                    this.errorGetStudentsName(error.error);
                }
            );
    }

    getEstructuraExcelCorrecta(data) {
        try {
            var listado = [];
            for (var i = 0; i < data.length; i++) {
                if (!this.isEmpty(data[i])) {
                    if (this.schoolService.importDataConfig.descartarPrimeraFila) {
                        if (i == 0) {
                            i++;
                        }
                    }
                    var nombre = "";
                    var apellido = "";
                    if (this.schoolService.importDataConfig.numeroColumnasImportar == 1) {
                        if (this.schoolService.importDataConfig.nombreApellidosSeparados) {
                            var cell: any = "";
                            if (
                                data[i]["A"] != null &&
                                data[i]["A"].split(",").length > 1
                            ) {
                                cell = data[i]["A"].split(",");
                            } else if (
                                data[i]["A"] != null &&
                                data[i]["A"].split(".").length > 1
                            ) {
                                cell = data[i]["A"].split(".");
                            } else if (
                                data[i]["A"] != null &&
                                data[i]["A"].split(";").length > 1
                            ) {
                                cell = data[i]["A"].split(";");
                            } else {
                                cell = [data[i]["A"], ""];
                            }
                            nombre = this.importExcelSplit(
                                cell,
                                this.schoolService.importDataConfig.ordenColumnas
                            )[0];
                            apellido = this.importExcelSplit(
                                cell,
                                this.schoolService.importDataConfig.ordenColumnas
                            )[1];

                            if (nombre == null && apellido == null)
                                apellido = data[i]["A"];
                        } else {
                            if (this.schoolService.importDataConfig.ordenColumnas == 0) {
                                if (
                                    data[i]["A"] != null &&
                                    data[i]["A"].length > 0
                                ) {
                                    nombre = data[i]["A"];
                                }
                            } else if (
                                this.schoolService.importDataConfig.ordenColumnas == 1
                            ) {
                                if (
                                    data[i]["A"] != null &&
                                    data[i]["A"].split(" ").length > 1
                                ) {
                                    nombre = data[i]["A"].split(" ")[0];
                                    apellido = data[i]["A"].split(" ")[1];
                                    if (data[i]["A"].split(" ").length > 2) {
                                        let splt = data[i]["A"].split(" ");
                                        apellido = "";
                                        for (let j = 1; j < splt.length; j++) {
                                            apellido += splt[j] + " ";
                                        }
                                        apellido = apellido.trim();
                                    }
                                }
                            } else {
                                if (
                                    data[i]["A"] != null &&
                                    data[i]["A"].split(" ").length > 1
                                ) {
                                    if (data[i]["A"].split(" ").length > 2) {
                                        nombre = data[i]["A"].split(" ")[2];
                                        apellido = data[i]["A"].split(" ")[0];
                                        apellido =
                                            apellido +
                                            " " +
                                            data[i]["A"].split(" ")[1];
                                        if (
                                            data[i]["A"].split(" ").length > 3
                                        ) {
                                            for (
                                                let index = 3;
                                                index <
                                                data[i]["A"].split(" ").length;
                                                index++
                                            ) {
                                                nombre +=
                                                    " " +
                                                    data[i]["A"].split(" ")[
                                                    index
                                                    ];
                                            }
                                        }
                                    } else {
                                        if (data[i]["A"] != null) {
                                            nombre = data[i]["A"].split(" ")[1];
                                            apellido =
                                                data[i]["A"].split(" ")[0];
                                        }
                                    }
                                }
                            }
                        }
                    } else if (
                        this.schoolService.importDataConfig.numeroColumnasImportar == 2
                    ) {
                        if (this.schoolService.importDataConfig.ordenColumnas == 0) {
                            if (data[i]["A"] != null) {
                                nombre = data[i]["A"];
                            }

                            if (data[i]["B"] != null) {
                                apellido = data[i]["B"];
                            }
                        } else if (this.schoolService.importDataConfig.ordenColumnas == 1) {
                            if (data[i]["B"] != null) {
                                nombre = data[i]["B"];
                            }

                            if (data[i]["A"] != null) {
                                apellido = data[i]["A"];
                            }
                        }
                    } else if (
                        this.schoolService.importDataConfig.numeroColumnasImportar == 3
                    ) {
                        if (this.schoolService.importDataConfig.ordenColumnas == 0) {
                            if (data[i]["A"] != null) {
                                nombre = data[i]["A"];
                            }

                            if (data[i]["B"] != null) {
                                apellido = data[i]["B"];
                            }

                            if (data[i]["C"] != null) {
                                apellido += " " + data[i]["C"];
                            }
                        } else if (this.schoolService.importDataConfig.ordenColumnas == 1) {
                            if (data[i]["A"] != null) {
                                apellido = data[i]["A"];
                            }

                            if (data[i]["B"] != null) {
                                apellido += " " + data[i]["B"];
                            }

                            if (data[i]["C"] != null) {
                                nombre = data[i]["C"];
                            }
                        }
                    }
                    if (
                        nombre.includes("undefined") ||
                        apellido.includes("undefined")
                    )
                        continue;
                    if (
                        nombre != null ||
                        apellido != null ||
                        nombre.length > 0 ||
                        apellido.length > 0
                    )
                        listado.push({
                            Nombre: nombre.trim(),
                            Apellidos: apellido.trim(),
                        });
                }
            }
            return listado;
        } catch (error) {
            var message = [];
            message[0] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.EXCEL-STRUCTURE-ERROR-TITLE');
            message[1] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.EXCEL-STRUCTURE-ERROR-MESSAGE');

            let confirmDialog = this._matDialog.open(
                FuseDialogContinueComponent,
                {
                    disableClose: true,
                }
            );
            confirmDialog.componentInstance.title = message[0];
            confirmDialog.componentInstance.message1 = message[1];
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
            confirmDialog.componentInstance.margin = false;

            confirmDialog
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result) => {
                    this.confirmDialog = null;
                });

            return null;
        }
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    }

    getObjectAtributes(obj) {
        var c = 0;
        for (var key in obj) {
            c++;
        }
        return c;
    }

    successGetStudentsName(response) {
        this.loading = false;
        this.calling = false;
        this._ui.stopSpinner();
        var excelFile: any = document.getElementById("excelFile");
        excelFile.value = "";
        var message = [];
        if (this.importExcel)
            message[0] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.IMPORT-TEACHER-SUCCESS-TITLE');
        else message[0] = this._translateService.instant('SCHOOL.ADMIN.MODAL-SUCCESS-GENERATE-STUDENTS-TITLE');

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        if(!this.importExcel){
            confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.ADMIN.MODAL-SUCCESS-GENERATE-STUDENTS-MESSAGE')
        }
        if(this.importExcel && response > 0){
            confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.ADMIN.MODAL-IMPORT-CHANGED-LICENSE', {oldLicenses: this.schoolService.school.MaxNumStudents, newLicenses: response});
            this.schoolService.school.MaxNumStudents = response; 
        }
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        // confirmDialog.componentInstance.textButton2 = "No"
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(_ => this.viewStudents());
    }

    errorGetStudentsName(response) {
        this.loading = false;
        this._ui.stopSpinner();
        var excelFile: any = document.getElementById("excelFile");
        excelFile.value = "";
        this.loading = false;
        var message = [];
        if (Array.isArray(response)) {
            for (var i = 0; i < response.length; i++) {
                message[i] = response[i].split(";")[0];
            }
        } else {
            message[0] =
                this._translateService.instant('SCHOOL.ADMIN.MODAL-ERROR-LOAD-EXCEL-MESSAGE');
        }

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title =
            this._translateService.instant('SCHOOL.ADMIN.MODAL-ERROR-LOAD-EXCEL-TITLE');
        confirmDialog.componentInstance.messages = message;
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                this.confirmDialog = null;
            });
    }

    importExcelSplit(cell, sort) {
        var obj = [];
        var surname2 = "";
        var name = "";
        switch (sort) {
            case "0":
                if (cell.length > 2) {
                    obj = [cell[0] + "." + cell[1] + "." + cell[2], ""];
                } else {
                    obj = [cell[0] + "." + cell[1], ""];
                }

                break;
            case "1":
                obj = [cell[0], cell[1]];
                break;
            case "2":
                obj = [cell[1], cell[0]];
                break;
            case "3":
                if (cell.length > 2) {
                    surname2 = cell[1];
                    name = cell[2];
                } else {
                    name = cell[1];
                }

                obj = [name, cell[0] + " " + surname2];
                break;
            default:
                break;
        }
        return obj;
    }

    importExcelSplitTeacher(cell, sort) {
        var obj = [];
        switch (sort) {
            case 0:
                obj = [cell[1], cell[0]];
                break;
            case 1:
                obj = [cell[1], cell[0]];
                break;
            case 2:
                obj = [cell[0], cell[1]];
                break;

            default:
                break;
        }
        return obj;
    }

    exportData(tipo) {
        var listado = [];
        if (this.excelAlumnos == "1" && tipo == "alumnos") {
            tipo = "alumnos2";
        }
        if (this.escelOpt == "2" && tipo == "aulas") {
            tipo = "aulas2";
        }
        if (this.escelOpt == "2" && tipo == "profesores") {
            tipo = "profesores2";
        }

        switch (tipo) {
            case "aulas":
                var tmpAulas = [];
                var opts = [];
                tmpAulas = this.school.Classes;

                tmpAulas.sort(function (a, b) {
                    if (a.Level === b.Level) {
                        if (a.Name > b.Name) return 1;
                        else if (a.Name < b.Name) return -1;
                        else return 0;
                    }
                    if (a.Level > b.Level) return 1;
                    else if (a.Level < b.Level) return -1;
                    else return 0;
                });
                var tmpArray = [];
                listado.push(tmpArray);
                for (var i = 0; i < tmpAulas.length; i++) {
                    listado[0].push({
                        Grupo: tmpAulas[i].Name,
                        Curso: tmpAulas[i].Course.Name,
                        Profesor:
                            tmpAulas[i].Tutor.Name +
                            " " +
                            tmpAulas[i].Tutor.FamilyName,
                        "Código de aula": tmpAulas[i].Code,
                        "Número de alumnos": tmpAulas[i].NumStudents,
                    });
                }

                opts.push({ sheetid: "aulas", header: true });
                alasql(
                    'SELECT INTO XLSX("listado aulas ' +
                    this.school.Name +
                    '.xlsx",?) FROM ?',
                    [opts, listado]
                );

                break;

            case "aulas2":
                var tmpAulas = [];
                var opts = [];
                tmpAulas = this.school.Classes;

                tmpAulas.sort(function (a, b) {
                    if (a.Level === b.Level) {
                        if (a.Name > b.Name) return 1;
                        else if (a.Name < b.Name) return -1;
                        else return 0;
                    }
                    if (a.Level > b.Level) return 1;
                    else if (a.Level < b.Level) return -1;
                    else return 0;
                });
                var tmpArray = [];
                listado.push(tmpArray);
                for (var i = 0; i < tmpAulas.length; i++) {
                    listado[0].push({
                        Grupo: tmpAulas[i].Name,
                        Curso: tmpAulas[i].Course.Name,
                        Profesor:
                            tmpAulas[i].Tutor.Name +
                            " " +
                            tmpAulas[i].Tutor.FamilyName,
                        "Código de aula": tmpAulas[i].Code,
                        "Número de alumnos": tmpAulas[i].NumStudents,
                    });
                }

                opts.push({ sheetid: "aulas", header: true });
                var tmp = [];
                tmp = this.teachers;
                tmp.sort(function (a, b) {
                    if (a.FamilyName > b.FamilyName) return 1;
                    else if (a.FamilyName < b.FamilyName) return -1;
                    else return 0;
                });
                var tmpArray = [];
                listado.push(tmpArray);

                for (var i = 0; i < tmp.length; i++) {
                    listado[1].push({
                        Nombre: tmp[i].FamilyName + ", " + tmp[i].Name,
                        Correo: tmp[i].Email,
                        Usuario: tmp[i].UserName,
                    });
                }
                opts.push({ sheetid: "profesores", header: true });
                alasql(
                    'SELECT INTO XLSX("listado aulas y profesores ' +
                    this.school.Name +
                    ' 2.xlsx",?) FROM ?',
                    [opts, listado]
                );

                break;

            case "alumnos_aula":
                var tmpStds2 = [];
                var opts = [];

                tmpStds2 = this.currentClass.Students;
                tmpStds2.sort(function (a, b) {
                    if (a.FamilyName > b.FamilyName) return 1;
                    else if (a.FamilyName < b.FamilyName) return -1;
                    else return 0;
                });
                var tmpArray = [];
                listado.push(tmpArray);
                for (var i = 0; i < tmpStds2.length; i++) {
                    listado[0].push({
                        Nombre:
                            tmpStds2[i].FamilyName + ", " + tmpStds2[i].Name,
                        Usuario: tmpStds2[i].UserName,
                        Curso: tmpStds2[i].Course.Name,
                    });
                }
                opts.push({ sheetid: "alumnos", header: true });
                alasql(
                    'SELECT INTO XLSX("listado alumnos ' +
                    this.currentClass.Name +
                    " " +
                    this.school.Name +
                    '.xlsx",?) FROM ?',
                    [opts, listado]
                );

                break;
        }
        this.escelOpt = "1";
    }

    errorCallback(error) {
        this.calling = false;

        if (error) {
            if (error == "className") {
                if (this.state == "newClasses") {
                    this.ShowClassErrorMessage(this._translateService.instant('SCHOOL.MODAL-ERROR-ADD-CLASS'));
                    return;
                } else {
                    this.ShowClassErrorMessage(this._translateService.instant('SCHOOL.MODAL-ERROR-GENERATE-CLASS'));
                    return;
                }
            }

        } else {
            this.goLogin();
        }
    }

    goLogin() {
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("adminLogin");
        sessionStorage.removeItem("state");
        sessionStorage.removeItem("state_params");
        sessionStorage.setItem("isLogged", "false");
        this.router.navigateByUrl("login");
    }

    successGetSchool(response: any, state: string = null) {
        this.calling = false;

        this.loading = true;

        if (response == null) {
            this.goLogin();
            return;
        }

        this.school = response;
        this._sharedService.changeMaxNumStudents(this.school.MaxNumStudents);
        this._sharedService.changeNumStudents(this.school.NumStudents);
        this._sharedService.changeSchoolValidUntil(this.school.ValidUntil);
        this.schoolService.school = this.school;
        let c;

        this.loadClassesTeachersStudents(state);
    }

    loadClassData(clases: any): void {
        let c = null;
        this.school.Classes = clases;
        if(clases.length > 0 && this.school.Classes.find(x => x.IdTutor == this.currentUser.Id || x.AuthorizedParents.find(x => x.IdUserTutor == this.currentUser.Id))){
            this._sharedService.changeSchoolHasClasses(true);
        }
        //this._sharedService.changeSchoolHasClasses(this.school.Classes.length > 0);
        if (this.currentClass) {
            c = this.school.Classes.find(
                (c) => c.IdClass == this.currentClass.IdClass
            );
            this.selectClass(c);
        } else if (this._idClassToShow) {
            c = this.school.Classes.find(c => c.IdClass == this._idClassToShow);
            this.selectClass(c);
        } else {
            c = null;
        }
        if (c == null && this.state != "teacher") {
            this.selectClass(this.school.Classes[0]);
        }

    }
    getSchoolClasses() {
        this.schoolService.getSchoolClasses(this.school.IdSchool)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((teachers) => {
                this.loadClassData(teachers)
            }, (err) => {
                this.errorCallback(err);
            })
    }

    getSchoolTeachers() {
        this.schoolService.getSchoolTeachers(this.school.IdSchool)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((teachers) => {
                this.loadTeachersData(teachers)
            }, (err) => {
                this.errorCallback(err);
            })
    }

    loadTeachersData(teachers: any): void {
        this.teachers = teachers;
        this.teachersAux = [];
        for (var i = 0; i < this.teachers.length; i++) {
            if (this.currentClass && this.teachers[i].Id == this.currentClass.IdTutor)
                continue;
            if (
                this.currentClass &&
                this.currentClass.AuthorizedParents.find(
                    (a) => a.IdUserTutor == this.teachers[i].Id
                )
            )
                continue;
            this.teachersAux.push(this.teachers[i]);
        }
        this.selectedTeacherAux = this.teachersAux.length > 0 ? this.teachersAux[0].Id : null;
        if (this.isTeacher(this.selectedUser)) {
            let profe = this.teachers.find(x => x.Id == this.selectedUser.Id)
            this.selectUser(profe);
        }
        this.selectClass(this.currentClass ? this.currentClass : this.school.Classes[0])
        this.calling = false;

    }

    loadClassesTeachersStudents(state: string = null): void {
        forkJoin([
            this.schoolService.getSchoolClasses(this.school.IdSchool),
            this.schoolService.getSchoolTeachers(this.school.IdSchool),
        ])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (data: any[]) => {
                    this.loadClassData(data[0])
                    this.loadTeachersData(data[1]);
                    this.loading = false;
                    this.dataLoaded2 = true;

                    if (this.navigationService.params.state) {
                        this.ChangeState(this.navigationService.params.state);
                    } else if (state != null) {
                        this.ChangeState(state);
                    } else {                        
                        if (this.state) this.ChangeState(this.state);
                        else {
                            if (sessionStorage.getItem(Profiles.IsSchoolAdmin) === 'true' || sessionStorage.getItem(Profiles.IsSuperAdmin) === 'true')
                                this.ChangeState("class");
                            else
                                this.goLogin();
                        }
                    }
                    this.calling = false;
                },
                error: (err: any) => { },
            });
    }

    isAssignedInClass() {
        return this.school.Classes.some(x => x.Tutor.Id == this.currentUser.Id || (x.AuthorizedParents.length > 0 && x.AuthorizedParents.find(y => y.IdUserTutor == this.currentUser.Id)))
    }

    volver() {
        this.navigationService.resetParams();

        if (this.extraFields == true) {
            this.extraFields = false;
            return;
        }
        // Pablo seteaba este valor a null, pero no especifico por que
        //this.selectedUser = null;
        switch (this.state) {
            case "newClass":
            case "newClasses":
            case "importarAlumnosAulaExcel":
            case "students":
                this.classList();

                break;

            default:
                this.router.navigateByUrl("/school/admin");
                
                break;
        }
    }

    isTeacher(teacher) {
        var r = false;
        if (teacher && teacher.IdRole && (teacher.IdRole == 2 || teacher.IdRole == 6 || teacher.IdRole == 5)) {
            r = true;
        }
        return r;
    }

    successCallbackUpdateClass() {
        this.calling = false;
        this.extraFields = false;
        this.loadClassesTeachersStudents();
        this.ChangeState("class");
    }
    successCallbackCreateClass(response) {
        this.calling = false;
        this.extraFields = false;
        if (response == null || response == null || response.length == 0) {
            this.goLogin();
        } else {
            this.loadClassesTeachersStudents();
            this.ChangeState("class");
        }
    }

    errorDeleteClass(response) {
        this.calling = false;
        this.loading = false;
        if (response == null || response == null || response.length == 0) {
            // $exceptionHandler("parentMenuAdminController:Error deleting class");
            this.goLogin();
        } else {
            var message = [];
                message[0] = this._translateService.instant('SCHOOL.ADMIN.CLASSES.MODAL-ERROR-DELETE-CLASS-TITLE');
                message[1] = this._translateService.instant('SCHOOL.ADMIN.CLASSES.MODAL-ERROR-DELETE-CLASS-MESSAGE');

            let confirmDialog = this._matDialog.open(
                FuseDialogContinueComponent,
                {
                    disableClose: true,
                }
            );
            confirmDialog.componentInstance.title = message[0];
            confirmDialog.componentInstance.message1 = message[1];
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
            confirmDialog.componentInstance.margin = false;

            confirmDialog
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result) => {
                    this.schoolService
                        .getSchoolByUser(this.currentUser.Id)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(
                            (result) => {
                                this.successGetSchool(result);
                                this.confirmDialog = null;
                            },
                            (error) => {
                                this.errorCallback(error.error);
                                this.confirmDialog = null;
                            }
                        );
                });
        }
    }
    errorDeleteClassStudents(response) {
        this.calling = false;
        this.loading = false;
        if (response == null || response == null || response.length == 0) {
            this.goLogin();
        } else {
            var message = [];
                message[0] = this._translateService.instant('SCHOOL.ADMIN.CLASSES.MODAL-ERROR-DELETE-STUDENTS-TITLE');
                message[1] = this._translateService.instant('SCHOOL.ADMIN.CLASSES.MODAL-ERROR-DELETE-STUDENTS-MESSAGE');
            let confirmDialog = this._matDialog.open(
                FuseDialogContinueComponent,
                {
                    disableClose: true,
                }
            );
            confirmDialog.componentInstance.title = message[0];
            confirmDialog.componentInstance.message1 = message[1];
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
            confirmDialog.componentInstance.margin = false;

            confirmDialog
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result) => {
                    this.schoolService
                        .getSchoolByUser(this.currentUser.Id)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(
                            (result) => {
                                this.successGetSchool(result);
                                this.confirmDialog = null;
                            },
                            (error) => {
                                this.errorCallback(error.error);
                                this.confirmDialog = null;
                            }
                        );
                });
        }
    }
    successCallbackDeleteClass(response) {
        this.calling = false;

        var message = [];
        message[0] = this._translateService.instant('SCHOOL.ADMIN.CLASSES.MODAL-SUCCESS-DELETE-CLASS-TITLE');
        message[1] = this._translateService.instant('SCHOOL.ADMIN.CLASSES.MODAL-SUCCESS-DELETE-CLASS-MESSAGE');

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                this.schoolService
                    .getSchoolByUser(this.currentUser.Id)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(
                        (result) => {
                            this.successGetSchool(result);
                            this.confirmDialog = null;
                        },
                        (error) => {
                            this.errorCallback(error.error);
                            this.confirmDialog = null;
                        }
                    );
            });
    }

    successCallbackDeleteClassStudents(response) {
        this.calling = false;
        var message = [];
        message[0] = this._translateService.instant('SCHOOL.ADMIN.CLASSES.MODAL-SUCCESS-DELETE-STUDENTS-TITLE');
        message[1] = this._translateService.instant('SCHOOL.ADMIN.CLASSES.MODAL-SUCCESS-DELETE-STUDENTS-MESSAGE');
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                this.schoolService
                    .getSchoolByUser(this.currentUser.Id)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(
                        (result) => {
                            this.successGetSchool(result,"class");
                        },
                        (error) => {
                            this.errorCallback(error.error);
                        }
                    );
                this.confirmDialog = null;
            });
    }

    startPayment() {
        // $state.go('parentMenu.adminPayment', { school: this.school });
        this.navigationService.go("/school/adminPayment", {});
    }

    startPromo() {
        // $state.go('parentMenu.adminPromo', { school: this.school });
        this.router.navigateByUrl("/school/adminPromo");
    }

    ChangeState(state) {
        this.state = state;
        this.loading = false;
        this.navigationService.enableBackButton(true, this.volver.bind(this));
        setTimeout(() => this.changeDetectorRef.detectChanges());
    }
    classList(clearSelectedUser: boolean = false) {
        if (clearSelectedUser) this.clearSelectedUser();
        // Pablo seteaba este valor a null, pero no especifico por que
        //this.selectedUser = null;

        if (this.selectedUser && this.selectedUser.IdRole != null) {
            if (this.state == "students" && this.selectedUserOld) {
                this.selectedUser = this.selectedUserOld;
                this.selectedUserOld = null;
            }
        }
        
        this.ChangeState("class");
    }

    newClass() {
        if(this.formDataClass){
            this.formDataClass.reset();
        }
        
        if(this.formDataClassDirective){
            this.formDataClassDirective.resetForm();
        }
        this.teachersAux = this.teachers;
        this.formDataClass = this._formBuilder.group({
            Level: ["", Validators.required],
            Teacher: ["", Validators.required],
            TeacherAux: [""],
            Name: ["", Validators.required],
            Code: [""],
        });
        
        this.ChangeState("newClass");
    }
    newClasses() {
        if(this.formDataClasses){
            this.formDataClasses.reset();
        }
        if(this.formDataClassesDirective){
            this.formDataClassesDirective.resetForm();
        }
        this.teachersAux = this.teachers;
        this.formDataClasses = this._formBuilder.group({
            LevelFrom: ["", Validators.required],
            LevelTo: ["", Validators.required],
            Teacher: ["", Validators.required],
            TeacherAux: [""],
            NameFrom: ["", Validators.required],
            NameTo: ["", Validators.required],
            Code: [""],
        });

        this.ChangeState("newClasses");
    }

    viewReports() {
        this.router.navigateByUrl("/school/admin/report");
    }

    parseProfesorName(user) {
        return user.FamilyName + ", " + user.Name;
    }

    editClass() {
        if (this.calling || !this.currentClass || this.currentClass.IdClass == null) return;
        this.ChangeState("editClass");
        if(this.formDataClass){
            this.formDataClass.reset();
        }
        if(this.formDataClassDirective){
            this.formDataClassDirective.resetForm();
        }
        this.formDataClass = this._formBuilder.group({
            Level: [this.currentClass.Course.Level, Validators.required],
            Teacher: [this.currentClass.Tutor.Id, Validators.required],
            TeacherAux: [""],
            Name: [this.currentClass.Name, Validators.required],
            Code: [this.currentClass.Code],
        });

        setTimeout(() => {
            $("#formCodeId").bind("input propertychange", () => {
                this.resetClass();
            });
        }, 1000);
    }

    resetClass() {
        if (
            this.formDataClass.value.Code == null ||
            this.formDataClass.value.Code.indexOf(
                this.schoolService.school.Initials.toLowerCase() + "."
            ) != 0
        ) {
            this.formDataClass.patchValue({
                Code: this.formDataClass.value.school.Initials.toLowerCase() + ".",
            });
        }
    }

    isClass(user): boolean {
        if (user == null || user.IdRole != null) {
            return false
        }
        return true;
    }

    viewStudents() {
        if (this.calling) return;
        if (!this.currentClass) return;
        if (this.currentClass.IdClass == null) return;

        this.router.navigateByUrl(`/school/admin/students/${this.currentClass.IdClass}`);
    }

    deleteClass() {
        if (this.calling) return;
        if (!this.currentClass) return;

        var message = [];
        message[0] = this._translateService.instant('SCHOOL.ADMIN.CLASSES.MODAL-WARNING-DELETE-CLASS-TITLE');
        message[1] =
        this._translateService.instant('SCHOOL.ADMIN.CLASSES.MODAL-WARNING-DELETE-CLASS-MESSAGE');

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.theme = "white";
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.canClose = true;
        if (this.currentClass.Students.length > 0) {
            confirmDialog.componentInstance.options = [
                {
                    text: this._translateService.instant('SCHOOL.ADMIN.CLASSES.MODAL-DELETE-COMPLETE-CLASS'),
                    callback: () => {
                        this.calling = true;
                        this.loading = true;
                        this.schoolService
                            .deleteClass(
                                this.currentClass.IdClass,
                                this.currentUser.Id,
                                this.currentUser.IsSchoolAdmin
                            )
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe(
                                (result) => {
                                    this.successCallbackDeleteClass(result);
                                },
                                (error) => {
                                    this.errorDeleteClass(error.error);
                                }
                            );
                    },
                },
                {
                    text: this._translateService.instant('SCHOOL.ADMIN.CLASSES.MODAL-DELETE-ONLY-STUDENTS'),
                    callback: () => {
                        this.calling = true;
                        this.loading = true;
                        this.schoolService
                            .deleteClassStudents(
                                this.currentClass.IdClass,
                                this.currentUser.Id,
                                this.currentUser.IsSchoolAdmin
                            )
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe(
                                (result) => {
                                    this.successCallbackDeleteClassStudents(
                                        result
                                    );
                                },
                                (error) => {
                                    this.errorDeleteClassStudents(error.error);
                                }
                            );
                    },
                },
            ];
        } else {
            confirmDialog.componentInstance.canClose = false;
            confirmDialog.componentInstance.options = [
                {
                    text: this._translateService.instant('ACCEPT'),
                    callback: () => {
                        this.calling = true;
                        this.loading = true;
                        this.schoolService
                            .deleteClass(
                                this.currentClass.IdClass,
                                this.currentUser.Id,
                                this.currentUser.IsSchoolAdmin
                            )
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe(
                                (result) => {
                                    this.successCallbackDeleteClass(result);
                                },
                                (error) => {
                                    this.errorDeleteClass(error.error);
                                }
                            );
                    },
                },
                {
                    text: this._translateService.instant('CANCEL'),
                    callback: () => { },
                },
            ];
        }
        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                result();
                confirmDialog = null;
            });
    }

    importClass() {
        if (this.calling || !this.currentClass || this.currentClass.IdClass == null) return;
        this.ChangeState("importarAlumnosAulaExcel");
    }

    generateClass() {
        if (this.calling || !this.currentClass || this.currentClass.IdClass == null) return;
        this.ChangeState("generarAlumnosAula");
    }

    saveClass() {
        this.formDataClass.markAllAsTouched();
        if (this.calling) return;

        if (this.formDataClass.invalid) return;

        this.schoolService
            .addClass(
                this.formDataClass.value.Name,
                this.formDataClass.value.Level,
                this.formDataClass.value.Teacher
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.successCallbackCreateClass(result);
                },
                (error) => {
                    this.errorCallback(error.error);
                }
            );
        this.calling = true;
    }

    saveClasses() {
        this.formDataClasses.markAllAsTouched();
        let courseList = [];
        let groupList = [];
        for (
            let i = this.formDataClasses.value.LevelFrom;
            i <= this.formDataClasses.value.LevelTo;
            i++
        ) {
            courseList.push(this.courses[i].Level);
        }
        for (
            let j = this.formDataClasses.value.NameFrom;
            j <= this.formDataClasses.value.NameTo;
            j++
        ) {
            groupList.push(this.classLetters[j]);
        }
        // para los cursos y los grupos lo que vamos a hacer es utilizar el indice del que eligio con la opcion desde para iniciar un for, y con el indice de to para cerrarlo
        // con eso guardamos todos lo valores que esten entre medio en un array y lo enviamos a la api
        if (this.calling) return;

        if (this.formDataClasses.invalid) return;
        this.schoolService
            .generateClasses(
                groupList,
                courseList,
                this.formDataClasses.value.Teacher
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.successCallbackCreateClass(result);
                },
                (error) => {
                    this.errorCallback(error.error);
                }
            );
        this.calling = true;
    }

    loadFile(event) {
        let files = event.target.files;
        let file = files[0];
        this.getSheets(file, event);
    }

    getSheets(file, event) {
        var reader;
        reader = new FileReader();
        reader.onload = (e) => {
            let data;
            let workbook;
            let sheets;
            data = e.target.result;
            workbook = XLSX.read(data, { type: "binary" });
            sheets = workbook.SheetNames;
            if (sheets.length == 1) {
                alasql(
                    "SELECT * FROM XLSX(?,{headers:false})",
                    [event],
                    (data) => {
                        this.importData(data);
                    }
                );
            } else {
                // elegir qué pagina
                this.showModalSelectSheet(sheets, event);
            }
        };
        reader.onerror = function (ex) { };
        reader.readAsBinaryString(file);
    }

    changeExtraFields() {
        this.extraFields = !this.extraFields;
        this.teachersAux = this.teachers.filter(x => this.currentClass.IdTutor != x.Id && !this.currentClass.AuthorizedParents.find(y => y.IdUserTutor == x.Id))
    }

    getClass() {
        this.schoolService
            .getClassToEdit(this.currentClass.IdClass)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result: any) => {
                    let index = this.school.Classes.findIndex(x => x.IdClass = result.IdClass);
                    this.school.Classes[index] = result;
                    this.selectClass(result);
                },
                (error) => {
                    this.goLogin();
                }
            );
    }

    

    addTeacherAux() {
        if (this.selectedTeacherAux == null) return;
        this.schoolService
            .addAuthTeacher(this.currentClass.IdClass, this.selectedTeacherAux)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.getSchoolClasses();
                },
                () => { }
            );
    }

    removeAuthTeacher(teacher) {
        this.schoolService
            .removeAuthTeacher(this.currentClass.IdClass, teacher.IdUserTutor)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.getSchoolClasses();
                },
                () => { }
            );
    }

    updateClass() {
        this.formDataClass.markAllAsTouched();
        if (this.calling) return;

        var codeRegEx = /^[0-9A-Za-z\.]+$/;

        if (
            this.formDataClass.value.Code != "" &&
            this.formDataClass.value.Code != null
        ) {
            let Code = this.formDataClass.value.Code.trim();
            var validName = Code.match(codeRegEx);
            if (validName == null) {
                this.formDataClass.controls["Code"].setErrors({
                    incorrect: true,
                });
                return;
            }
        } else {
            this.formDataClass.controls["Code"].setErrors({ required: true });
        }

        if (
            this.formDataClass.value.Code ==
            this.schoolService.school.Initials.toLowerCase() + "."
        ) {
            this.formDataClass.controls["Code"].setErrors({ required: true });
            return;
        }
        if (this.currentClass.AuthorizedParents.some(x => x.IdUserTutor == this.formDataClass.value.Teacher)) {
            let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                disableClose: true,
            });
            confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.EDIT-CLASS-TITLE');
            confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.ADMIN.CLASSES.MODAL-ERROR-EDIT-CLASS-MESSAGE');
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
            confirmDialog.componentInstance.margin = false;
    
            confirmDialog
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result) => {
                    return;
                });
            return;
        }
        if (this.formDataClass.invalid) return;

        this.schoolService
            .updateSimpleClass(
                this.currentClass.IdClass,
                this.formDataClass.value.Name,
                this.formDataClass.value.Level,
                this.formDataClass.value.Teacher,
                this.formDataClass.value.Code,
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                () => {
                    this.successCallbackUpdateClass();
                },
                (error) => {
                    this.errorCallbackUpdateClass(error.error);
                }
            );
        this.calling = true;
    }

    errorCallbackUpdateClass(error) {
        this.calling = false;
        if (error == "codeNotUnique") {
            this.formDataClass.controls["Code"].setErrors({ unique: true });
        } else if (error == "isAuthorized") {
            this.formDataClass.controls["Teacher"].setErrors({
                authorized: true,
            });
        }
    }
    selectFile() {
        document.getElementById("excelFile").click();
    }
}
