import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomizedSessionService } from 'app/services/customized-session.service';
import { TestService } from 'app/services/test.service';
import { Test2Service } from 'app/services/test2.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { AuthService } from 'app/services/auth.service';
import { SchoolService } from 'app/services/school.service';
import { NavigationService } from 'app/services/navigation.service';
import { CourseService } from 'app/services/course.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ExerciseService } from 'app/services/exercise.service';
import { TestExerciseService } from 'app/services/test-exercise.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CustomSession } from 'app/core/shared/state';
import * as _ from 'lodash';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { ExamDTO } from 'app/core/shared/state/models/ExamDTO.model';
import { ControlSessionService } from 'app/services/control-session.service';
import { ControlSessionDTO } from 'app/core/shared/state/models/control-session-dto.model';
import { ClassService } from 'app/services/class.service';
import { SharedService } from 'app/core/shared/shared.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: "app-exams",
  templateUrl: "./exams.component.html",
  styleUrls: ["./exams.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ExamsComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  infoPopupHelp: boolean = true;

  classes
  exams = []
  exam
  classes_exams
  loading
  state
  calendar
  courses
  form: UntypedFormGroup
  submitted
  thema
  clasesSeleccionadas = []
  messages = []
  showExamList = false;
  cancelExam = false;
  date
  isRequired = false;
  themas = [
    { thema: 'bv', Name: 'Utilización de la <b>b</b> y de la <b>v</b>' },
    { thema: 'c', Name: 'Utilización de <b>c</b> y de <b>cc</b>' },
    { thema: 'dip', Name: 'Hiatos y diptongos' },
    { thema: 'dtr', Name: 'Combinaciones de la <b>d</b> y la <b>t</b> con la <b>r</b>' },
    { thema: 'dz', Name: 'Terminaciones en <b>d</b> y <b>z</b>' },
    { thema: 'gal', Name: 'Temas de atención y memoria visual' },
    { thema: 'gen', Name: 'Género y número' },
    { thema: 'gj', Name: 'Utilización de la <b>g</b> y la <b>j</b>' },
    { thema: 'gtl', Name: 'Gentilicios' },
    { thema: 'gu', Name: 'Utilización de [<b>gue</b>, <b>gui</b>] y diéresis' },
    { thema: 'h', Name: 'Utilización de la <b>h</b>' },
    { thema: 'hom', Name: 'Palabras homónimas y juntas / separadas' },
    { thema: 'hps', Name: 'Errores comunes ortográficos' },
    { thema: 'lai', Name: 'Laísmo, leísmo y loísmo' },
    { thema: 'lly', Name: 'Utilización de <b>ll</b> e <b>y</b>' },
    { thema: 'lr', Name: 'Combinaciones de [<b>b</b>, <b>c</b>, <b>f</b>, <b>p</b>, <b>g</b>] con [<b>l</b>, <b>r</b>]' },
    { thema: 'mbp', Name: 'Utilización de la <b>m</b> antes de [<b>b</b>, <b>p</b>]' },
    { thema: 'mem', Name: 'Temas de memoria' },
    { thema: 'mn', Name: 'Utilización de la <b>m</b> y de la <b>n</b>' },
    { thema: 'nms', Name: 'Numerales' },
    { thema: 'ord', Name: 'Ordenación alfabética' },
    { thema: 'otr', Name: 'Utilización de [<b>b</b>, <b>c</b>, <b>g</b>, <b>p</b>] delante de consonante' },
    { thema: 'pco', Name: 'Palabras compuestas' },
    { thema: 'pll', Name: 'Lluvia de palabras' },
    { thema: 'ppp', Name: 'Temas de atención' },
    { thema: 'prf', Name: 'Prefijos' },
    { thema: 'qc', Name: 'Utilización de la <b>c</b> y de la <b>q</b>' },
    { thema: 'r', Name: 'Utilización de <b>r</b> y de <b>rr</b>' },
    { thema: 'sc', Name: 'Utilización de la <b>s</b> con la <b>c</b> y la <b>z</b>' },
    { thema: 'sil', Name: 'Sílabas, sílaba tónica y ordenación de sílabas' },
    { thema: 'suf', Name: 'Sufijos' },
    { thema: 'sya', Name: 'Sinónimos, antónimos y palabras derivadas' },
    { thema: 'tai', Name: 'La tilde en frases admirativas e interrogativas' },
    { thema: 'til', Name: 'La tilde y las palabras polisílabas' },
    { thema: 'tmo', Name: 'La tilde en monosílabos' },
    { thema: 'ver', Name: 'Verbos' },
    { thema: 'vor', Name: 'Vocales y consonantes' },
    { thema: 'xs', Name: 'Utilización de la <b>s</b> y de la <b>x</b>' },
    { thema: 'yeo', Name: 'Utilización de conjunciones <b>e</b>, <b>y</b>, <b>u</b>, <b>o</b>' },
    { thema: 'zc', Name: 'Utilización de la <b>c</b> y de la <b>z</b>' }
  ]
  type
  types = [
    {
      Name: 'Palabra',
      model: "guionp"
    }, {
      Name: 'Cita',
      model: "guionf"
    }
  ]
  exerciseType = 'Palabra'
  wordThemas = [
    { IdThema: [1], Name: 'Utilización de la <b>b</b> y de la <b>v</b>', IdExercise: 10010, Thema: 'BV' },
    { IdThema: [10], Name: 'Utilización de la <b>g</b> y la <b>j</b>', IdExercise: 10010, Thema: 'GJ' },
    { IdThema: [15], Name: 'Utilización de <b>ll</b> e <b>y</b>', IdExercise: 10010, Thema: 'LLY' },
    { IdThema: [21], Name: 'Utilización de la <b>h</b>', IdExercise: 10010, Thema: 'H' },
    { IdThema: [31], Name: 'La tilde y las palabras polisílabas', IdExercise: 10010, Thema: 'TIL' },
    { IdThema: [36], Name: 'Hiatos y diptongos', IdExercise: 10010, Thema: 'DIP' },
    { IdThema: [2], Name: 'Utilización de [<b>b</b>, <b>c</b>, <b>g</b>, <b>p</b>] delante de consonante', IdExercise: 10010, Thema: 'OTR' },
    { IdThema: [3, 7, 9, 11, 17], Name: 'Combinaciones de [<b>b</b>, <b>c</b>, <b>f</b>, <b>p</b>, <b>g</b>] con [<b>l</b>, <b>r</b>]', IdExercise: 10010, Thema: 'LR' },
    { IdThema: [4], Name: 'Utilización de <b>c</b> y de <b>cc</b>', IdExercise: 10010, Thema: 'C' },
    { IdThema: [5], Name: 'Utilización de [<b>b</b>, <b>c</b>, <b>g</b>, <b>p</b>] delante de consonante', IdExercise: 10010, Thema: 'OTR' },
    { IdThema: [6], Name: 'Utilización de la <b>c</b> y de la <b>q</b>', IdExercise: 10010, Thema: 'QC' },
    { IdThema: [8], Name: 'Terminaciones en <b>d</b> y <b>z</b>', IdExercise: 10010, Thema: 'DZ' },
    { IdThema: [12, 13, 14], Name: 'Utilización de [<b>gue</b>, <b>gui</b>] y diéresis', IdExercise: 10010, Thema: 'GU' },
    { IdThema: [16], Name: 'Utilización de la <b>m</b> antes de [<b>b</b>, <b>p</b>]', IdExercise: 10010, Thema: 'MBP' },
    { IdThema: [18], Name: 'Utilización de <b>r</b> y de <b>rr</b>', IdExercise: 10010, Thema: 'R' },
    { IdThema: [19], Name: 'Utilización de la <b>s</b> y de la <b>x</b>', IdExercise: 10010, Thema: 'XS' },
    { IdThema: [20], Name: 'Utilización de la <b>c</b> y de la <b>z</b>', IdExercise: 10010, Thema: 'ZC' },
    { IdThema: [47], Name: 'Combinaciones de la <b>d</b> y la <b>t</b> con la <b>r</b>', IdExercise: 10010, Thema: 'DTR' },
    { IdThema: [48, 49], Name: 'Utilización de la <b>s</b> con la <b>c</b> y la <b>z</b>', IdExercise: 10010, Thema: 'SC' },
    { IdThema: [50], Name: 'Utilización de la <b>m</b> y de la <b>n</b>', IdExercise: 10010, Thema: 'MN' }
  ]
  phraseThemas = [
    {
      IdThema: [1, 1, 1, 2],
      Name: 'La tilde en frases admirativas e interrogativas',
      IdExercise: 31010,
      Thema: "TAI"
    },
    {
      IdThema: [3],
      Name: 'La tilde en monosílabos',
      IdExercise: 31010,
      Thema: "TMO"
    },
    {
      IdThema: [4, 5],
      Name: 'Errores comunes ortográficos',
      IdExercise: 31010,
      Thema: "HPS"
    },
    {
      IdThema: [1],
      Name: 'Utilización de la <b>b</b> y de la <b>v</b>',
      IdExercise: 30010,
      Thema: "BV"
    },
    {
      IdThema: [10],
      Name: 'Utilización de la <b>g</b> y la <b>j</b>',
      IdExercise: 30010,
      Thema: "GJ"
    },
    {
      IdThema: [15],
      Name: 'Utilización de <b>ll</b> e <b>y</b>',
      IdExercise: 30010,
      Thema: "LLY"
    },
    {
      IdThema: [21],
      Name: 'Utilización de la <b>h</b>',
      IdExercise: 30010,
      Thema: "H"
    }
  ]
  exercises
  current_exercise
  dragging_element
  selectedDay
  customizedSessions: CustomSession[]
  showMessage
  nameExists = false;
  editingExamName = false;
  loadingExercises: boolean = false;
  classes_controlSessions: any[];

  constructor(
    private authService: AuthService,
    private test2Service: Test2Service,
    private customizedSessionService: CustomizedSessionService,
    public navigationService: NavigationService,
    private courseService: CourseService,
    private formBuilder: UntypedFormBuilder,
    private exerciseService: ExerciseService,
    private testExerciseService: TestExerciseService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private controlSessionService: ControlSessionService,
    private classService: ClassService,
    public schoolService: SchoolService,
    public _sharedService: SharedService,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    this.classes = []
    this.exams = []
    this.classes_exams = []
    this.state = "classes-exams"
    this.navigationService.setStateExam('classes-exams');
    this.loading = true
    this.customizedSessions = [];
    this.showMessage = false;

  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (!this.authService.check()) return
    this.loading = true;
    this.selectedDay = moment();
    this.courseService.Courses.pipe(takeUntil(this._unsubscribeAll)).subscribe(courses => {
      this.courses = courses;
    })
    this.getControlSessions()
    this.getExams()
    this.getClasses()
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.exercises,
      event.previousIndex,
      event.currentIndex
    );
  }

  getClasses() {
    this.classes = this.classService.GetClasses();
  }

  getControlSessions() {
    this.controlSessionService.getByUser(this.authService.currentUser.Id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: ControlSessionDTO[]) => {
        this.classes_controlSessions = result.filter(x => x.IdClass !== 0);
        this.classes_controlSessions.forEach(AssignedControl => {
          AssignedControl.formatted_date = new Date(AssignedControl.Date).toLocaleDateString("es-ES")
          AssignedControl.editingControlDate = false
          this.customizedSessions.push(AssignedControl);
        });
        this.loading = false;
      }, () => {
        this.loading = false;
      })
  }

  getExams() {
    this.classes_exams = []
    this.test2Service.getByUser(this.authService.currentUser.Id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: ExamDTO[]) => {
        this.classes_exams = result.filter(x => x.IdClass !== 0);
        this.exams = result.filter(x => x.IdClass === 0);
        this.classes_exams.forEach(AssignedExam => {
          AssignedExam.formatted_date = new Date(AssignedExam.Date).toLocaleDateString("es-ES")
          let exam = this.exams.find(e => e.IdExam == AssignedExam.IdExam)
          this.customizedSessions.push(AssignedExam)
          if (exam) {
            if (!exam.count_assigned) {
              exam.count_assigned = 0
            }
            exam.count_assigned++
          }
          else {
            this.exams.push(AssignedExam)
          }
        });
        this.loading = false;
      }, () => {
        this.loading = false;
      })
  }

  objtostr(obj) {
    return JSON.stringify(obj);
  }

  availableThemas() {
    return this.exerciseType == "Palabra"
      ? this.wordThemas
      : this.exerciseType == "Cita"
        ? this.phraseThemas
        : [];
  }

  deleteExam(exam) {
    if (exam.count_assigned > 0 || exam.CustomSessions?.length > 0) {
      this.navigationService.error([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.EXAMS.DELETE-EXAM-TITLE'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.EXAMS.DELETE-EXAM-MESSAGE')], ko => {

      })
      return
    }

    this.navigationService.warn([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.EXAMS.DELETE-EXAM-TITLE'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.EXAMS.DELETE-EXAM-CONFIRMATION')], ok => {
      this.test2Service.remove(exam)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
          this.getExams()
        })
    }, ko => {

    })
  }

  deleteClassExam(class_exam) {
    this.navigationService.warn([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.EXAMS.DELETE-CLASS-EXAM-TITLE'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.EXAMS.DELETE-CLASS-EXAM-CONFIRMATION')], ok => {
      this.loading = true
      let list = [{
        IdClass: class_exam.IdClass,
        Date: class_exam.Date
      }];
      this.customizedSessionService.add(list)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          result => {
            this.state = "classes-exams"
            this.navigationService.setStateExam('classes-exams');
            this.getExams()
          })
    }, ko => {

    })
  }


  addClassExam() {
    this.showExamList = false;
    this.state = "add-class-exam"
    this.navigationService.setStateExam('add-class-exam');
    this.navigationService.enableBackButtonExam(true, 'add-class-exam');
    this.navigationService.add_class_step = 1
    let tomorrow = moment().add(1, 'days')
    this.calendar = {
      current_user: this.authService.currentUser,
      selected_day: tomorrow,
      current_month: tomorrow.month() + 1
    }
  }
  cancel(editing) {
    this.cancelExam = true;
    let message = [];
    if (!editing) {
      message[0] = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.EXAMS.CANCEL-CREATION-TITLE');
      message[1] = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.EXAMS.CANCEL-CREATION-MESSAGE');
    } else {
      message[0] = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.EXAMS.CANCEL-EDIT-TITLE');
      message[1] = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.EXAMS.CANCEL-EDIT-MESSAGE');
    }
    this.navigationService.warn(message,
      ok => {
        this.cancelYes(editing);
      },
      ko => {
        this.cancelNo();
      })
  }
  cancelNo() {
    this.cancelExam = false;
  }
  cancelYes(editing) {
    if (editing) {
      this.getExams()
      this.editExams()
    } else {
      this.state = "classes-exams"
      this.navigationService.setStateExam('classes-exams');
      this.selectedDay = moment()
      this.getExams()
    }
  }
  editExams() {
    this.state = "add-class-exam"
    this.showExamList = true;
    this.navigationService.add_class_step = -1
    this.navigationService.enableBackButtonExam(true, 'add-class-exam');
    this.navigationService.setStateExam('add-class-exam');
  }

  addExam() {
    this.exam = null;
    this.submitted = false
    let Level = null;
    if (this.classes.some((x) => x.selected)) {
      Level = this.classes
        .sort((x) => x.Level - x.Level)
        .find((y) => y.selected).Level;
    }
    this.form = this.formBuilder.group({
      Name: ['Prueba', [Validators.required, this.nameExistsValidator.bind(this)]],
      Level: [
        Level ? Level : this.courses[0].Level,
        [Validators.required],
      ],
    });
    this.state = "add-exam"
    this.navigationService.setStateExam('add-exam');
  }
  startChangingExamName(exam) {
    exam.editingExamName = true;
    setTimeout(() => {
      document.getElementById("change-name").focus()
    }, 100);
  }
  changeExamName(exam) {
    this.nameExists = false;
    this.isRequired = false;
    var el = document.getElementById("change-name") as HTMLInputElement | null;
    el.value = el.value.trim();
    if (el.value == exam.Name) {
      exam.editingExamName = false;
    }
    if (el.value == "") {
      this.isRequired = true;
      return;
    }
    this.exams.forEach(examen => {
      if (exam.IdExam != examen.IdExam && examen.Name == el.value) {
        this.nameExists = true;
      }
    });
    if (this.nameExists) {
      return;
    }
    this.test2Service.updateExamName(exam.IdExam, el.value)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        result => {
          this.getExams();
        }, error => {
        }
      )
  }
  editExercises() {
    if (this.form && this.form.invalid) return
    this.state = "edit-exam-exercises"
    this.navigationService.setStateExam('edit-exam-exercises');
    this.current_exercise = 0
    this.exerciseType = "Palabra"
    this.thema = 0
    if (this.exercises.length == 0) {
      this.getNewExercise(this.current_exercise, true)
    } else {
      for (let i = 0; i < this.exercises.length; i++) this.getExercise(i)
      this.selectSlide(this.current_exercise)
    }
    this.initDragging()
  }
  editExamExercises(exam = null, editingName = false) {
    this.exam = null
    this.nameExists = false;
    this.isRequired = false;
    if (editingName) {
      this.exams.forEach(exam => {
        if (exam.Name == this.form.value.Name) {
          this.nameExists = true;
        }
      });
      if (this.nameExists) {
        return;
      }
    }
    this.submitted = true
    if (exam) {
      this.test2Service.GetExamExercises(exam.IdExam)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
          exam.exercises = result;
          this.exercises = result;
          this.exam = exam;
          this.form = this.formBuilder.group({
            Name: [exam.Name, [Validators.required, this.nameExistsValidator.bind(this)]],
            Level: [this.courses[0].Level, [Validators.required]]
          });
          this.editExercises()
        })
    }
    else {
      this.exercises = []
      this.editExercises()
    }
  }
    nameExistsValidator(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }

        const nameExists = this.exams.some(exam => exam.Name === control.value && (!this.exam || (this.exam.IdExam !== exam.IdExam)));
        return nameExists ? { nameExists: true } : null;
    }
  initDragging() {
    setTimeout(() => {
      this.testExerciseService.initDragging({
        ondragstart: (event, element) => {
          this.dragging_element = element;
        },
        ondrop: (event, element) => {
          let slide_from_index = parseInt(
            this.dragging_element.getAttribute("index")
          );
          let slide_to_index = parseInt(
            element.getAttribute("index")
          );

          let exercises = [];
          for (let i = 0; i < this.exercises.length; i++) {
            if (i == slide_from_index) {
              exercises.push(this.exercises[slide_to_index]);
            } else if (i == slide_to_index) {
              exercises.push(this.exercises[slide_from_index]);
            } else {
              exercises.push(this.exercises[i]);
            }
          }
          this.exercises = exercises;

          this.dragging_element = null;
        },
        ondragend: () => {
          this.dragging_element = null;
        },
      });
    }, 1000);
  }

  finishEditExercises() {

    if (this.exercises.length == 0) {
        return
    }
    if (this.exam) {
      this.loading = true;
      this.test2Service.removeExercisesFromExam(this.exam.IdExam)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          result => {
            this.saveExercise(this.exam.IdExam, 0)
          },
          err => {
            this.loading = false;
            console.log(err);
          }
        )
      return
    }
    this.loading = true;
    this.test2Service.insert(this.authService.currentUser.Id, this.form.value.Name)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (exam: any) => {
          this.saveExercise(exam.IdExam, 0)
        },
        err => {
          this.loading = false;
          console.log(err);
        }
      )


  }

  saveExercise(idExam, index) {
    this.test2Service.insertExercise(idExam, this.exercises[index])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        result => {
          if (index < this.exercises.length - 1) {
            this.saveExercise(idExam, index + 1)
          } else {
            this.save2(idExam)
          }
        }
      )
  }

  // selectSlide(index) {
  //   this.current_exercise = index
  //   this.getExercise(index)
  // }
  selectSlide(index, clicked = false) {
    this.current_exercise = index;
    this.getExercise(index, clicked);
  }

  getExercise(index, clicked = false) {
    this.loading = true;
    if (clicked) {
      this.changeDetectorRef.detectChanges();
    }
    let exercise = this.exercises[index];
    if (exercise?.empty) {
      return;
    }
    if (!exercise.ExerciseJson) this.reloadModel(index);
    if (exercise.ExerciseJson) {
      this.loading = false;
      this.loadingExercises = false;
    }
  }

  reloadModel(index) {
    let thema = this.availableThemas()[this.thema]
    let thema_aux = thema.IdThema[Math.floor(Math.random() * thema.IdThema.length)];
    let exerc = {
      "IdExerciseType": thema,
      "IdThema": thema_aux,
      "IdSubthema": 0,
      "MinLevel": 6,
      "MaxLevel": 6,
      "MinNumWordOcurrences": 10,
      "MinBookLevel": 6,
      "MaxBookLevel": 6

    }
    this.exerciseService.getNewExercise(exerc, this.form ? this.form.value.Level : this.courses[0].Level)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.exercises[index] = result
        this.loading = false
      }, error => {
        this.loading = false
      });
  }

  getNewExercise(index, empty = false, add_new = true) {
    this.loading = true
    this.current_exercise = index
    if (!empty) {
      if (this.thema == null) return
      if (this.exerciseType == null) return
      let thema = this.availableThemas()[this.thema]
      let thema_aux = thema.IdThema[Math.floor(Math.random() * thema.IdThema.length)];
      let exerc = {
        "IdExerciseType": thema.IdExercise,
        "IdThema": thema_aux,
        "IdSubthema": 0,
        "MinLevel": 6,
        "MaxLevel": 6,
        "MinNumWordOcurrences": 10,
        "MinBookLevel": 6,
        "MaxBookLevel": 6

      }
      this.exerciseService.getNewExercise(exerc, this.form ? this.form.value.Level : this.courses[0].Level)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
          result['temporal'] = true
          if (add_new) {
            this.exercises.splice(this.current_exercise, 0, result);
          } else {
            this.exercises[this.current_exercise] = result
          }
          this.loading = false
          this.initDragging()
        }, error => {
          this.loading = false
        });
    } else {
      this.exerciseType = null
      this.thema = null
      this.exercises.splice(this.current_exercise, 0, {
        temporal: true,
        empty: true
      });
    }
  }

  selectedDayChanged(date) {
    this.selectedDay = date;
    this.calendar.current_month = this.calendar.selected_day.month() + 1
    if (this.calendar.current_month != this.selectedDay.month() + 1) {
      this.calendar.selected_day = date
    }
  }
  get sortData() {
    return this.classes_exams.sort((a, b) => {
      return <any>new Date(b.Date) - <any>new Date(a.Date);
    });
  }
  continue() {
    if (this.navigationService.add_class_step < 3) {
      this.navigationService.add_class_step++
      if (this.navigationService.add_class_step == 2) {
        this.showMessage = false
        this.customizedSessions.forEach(sesion => {
          (sesion.IdControl != null || sesion.IdExam != null) && moment(sesion?.Date).format("DD/MM/YYYY").toString() == moment(this.selectedDay).format("DD/MM/YYYY").toString() ? this.clasesSeleccionadas.push(sesion) : this.showMessage = false;
        });
        this.clasesSeleccionadas = this.clasesSeleccionadas.filter((item, index) => {
          return this.clasesSeleccionadas.indexOf(item) === index;
        })
      }
    } else {
      this.save()
    }
  }

  save() {
    this.loading = true
    let list = [];
    for (let i = 0; i < this.classes.length; i++) {
      if (this.classes[i].selected) {
        list.push({
          IdClass: this.classes[i].IdClass,
          Date: this.selectedDay,
          IdExam: this.exams.find(e => e.selected).IdExam
        });
      }
    }
    for (let i = 0; i < this.classes.length; i++) this.classes[i].selected = false
    for (let i = 0; i < this.exams.length; i++) this.exams[i].selected = false
    this.customizedSessionService.add(list)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        result => {
          this.state = "classes-exams"
          this.navigationService.enableExitButton();
          this.navigationService.setStateExam('classes-exams');
          this.getExams()
        })
  }

  save2(IdExam) {
    this.loading = true
    let list = [];
    for (let i = 0; i < this.classes.length; i++) {
      if (this.classes[i].selected) {
        list.push({
          IdClass: this.classes[i].IdClass,
          Date: this.selectedDay,
          IdExam: IdExam
        });
      }
    }
    for (let i = 0; i < this.classes.length; i++) this.classes[i].selected = false
    for (let i = 0; i < this.exams.length; i++) this.exams[i].selected = false
    this.customizedSessionService.add(list)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        result => {
          this.state = "classes-exams"
          this.navigationService.enableExitButton();
          this.navigationService.setStateExam('classes-exams');
          this.getExams()
        })
  }

  selectClass(cls) {
    if (!cls.selected) cls.selected = false;
    cls.selected = !cls.selected;
    this.checkExamen();
  }

checkExamen() {
    this.messages = [];
    this.showMessage = false;
    let AssignedControlSessionClasses = []
    let AssignedExamClasses = []
    this.classes.forEach((cls) => {
        if (
            cls.selected &&
            this.clasesSeleccionadas.some((x) => x.IdClass == cls.IdClass)
        ) {
            if (
                this.clasesSeleccionadas.find(
                    (x) => x.IdClass == cls.IdClass
                ).IdControl != null
            ) {
                AssignedControlSessionClasses.push(cls.Name)
            } else {
                AssignedExamClasses.push(cls.Name)
            }
            
        }
    });
    if(AssignedControlSessionClasses.length > 0){
        this.messages.push(this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.ALREADY-ASSIGNED-SESSION-MESSAGE', {className: this.parseMessages(AssignedControlSessionClasses)}))
    }
    if(AssignedExamClasses.length > 0) {
        this.messages.push(this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.ALREADY-ASSIGNED-EXAM-MESSAGE', {className: this.parseMessages(AssignedExamClasses)}))
    }
    this.showMessage = true;
}
parseMessages(classNameList: any[]){
    var text = "";
    classNameList.forEach((x, index) => {
        console.log(index);
        if(index < classNameList.length - 1){
            text += x + ", "
        }
        else {
            text += x
        }
    })
    return text;
}
  selectExam(exam) {
    for (let i = 0; i < this.exams.length; i++)
      this.exams[i].selected = false;
    exam.selected = true;
  }

  disableWizard() {
    if (this.navigationService.add_class_step == 1) {
      return (
        !this.selectedDay ||
        moment(this.selectedDay).isSameOrBefore(moment(), "day")
      );
    } else if (this.navigationService.add_class_step == 2) {
      return this.classes.filter((c) => c.selected).length == 0;
    } else if (this.navigationService.add_class_step == 3) {
      return this.exams.filter((e) => e.selected).length == 0;
    }
  }

  removeExercise() {
    if (this.exercises.length <= 1) return
    this.exercises.splice(this.current_exercise, 1)
    if (this.current_exercise > 0) {
      this.current_exercise--
    }
  }
  getName(name): string {
    var find = '</b>';
    var re = new RegExp(find, 'g');
    let str = name.replace(re, '</b>&nbsp');
    str = str.replace(/<b>/g, '&nbsp<b>');
    return str;
  }

  before(index, clicked = false) {
    if (index < 0) return;
    this.current_exercise = index;
    this.getExercise(index, clicked);
    let ele2 = document.getElementById("scroll");
    const x = ele2.scrollHeight / this.exercises.length;
    ele2.scrollTop = x * index;
  }

  next(index, clicked = false) {
    if (index >= this.exercises.length) return;
    this.current_exercise = index;
    this.getExercise(index, clicked);
    let ele2 = document.getElementById("scroll");
    const x = ele2.scrollHeight / this.exercises.length;
    ele2.scrollTop = x * index;
  }

  ShowHideHelpPopup() {
    this.infoPopupHelp = !this.infoPopupHelp;
  }

  OpenPanelSlidesMobile() {
    const panel = document.getElementById("scroll");
    const panelShadow = document.getElementById("shadow_slides_close");

    panel.classList.add('active');
    panelShadow.classList.add('active');
  }

  ClosePanelSlidesMobile() {
    const panel = document.getElementById("scroll");
    const panelShadow = document.getElementById("shadow_slides_close");

    panel.classList.remove('active');
    panelShadow.classList.remove('active');
  }
}
