import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
    ViewEncapsulation,
    ViewChild,
} from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { SchoolService } from "app/services/school.service";
import { FuseDialogContinueComponent } from "@fuse/components/dialog-continue/dialog-continue.component";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import {
    NgForm,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { UserService } from "app/services/user.service";
import { CourseService } from "app/services/course.service";
import { UiService } from "@fuse/components/spinner/ui.service";
import { SharedService } from "app/core/shared/shared.service";
import { NavigationService } from "app/services/navigation.service";
import { AvatarShopService } from "app/services/avatar-shop.service";
import * as htmlToImage from "html-to-image";
import { Subject, forkJoin } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";
import { UpdateStudent } from "app/core/shared/state/models/User/update-student.model";
import { Profiles } from "app/core/shared/session.model";
import { SortByPipe } from "app/pipes/sort-by.pipe";
import { TranslateService } from "@ngx-translate/core";

declare var alasql;
declare var XLSX;
declare var $;

@Component({
    selector: "school-admin-students",
    templateUrl: "./admin-students.component.html",
    styleUrls: ["./admin-students.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AdminStudentsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    private _idClassToShow: number = null;

    navigationSubscription;
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;
    currentUser;
    editFromClass = false;
    teachers = [];
    school;
    email;
    error;
    errorDescription;
    dataLoaded = false;
    formDataStudent: UntypedFormGroup;
    dataLoaded2 = false;
    selectedAuthUser = null;
    public loading = false;
    selectedUser = null;
    selectedUserOld = null;
    selectedClass = null;
    selectedProfe = null;
    correoConfirmacion = false;
    extraFields = false;
    selectedTeacherAux;
    numStudentsError;
    numStd = 10;
    @ViewChild('formDataStudentDirective') private formDataStudentDirective: NgForm;
    @ViewChild('formDataClassDirective') private formDataClassDirective: NgForm;
    ordenColumnas = {
        1: [
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-STUDENT-COLUMN-ORDER1'),
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-STUDENT-COLUMN-ORDER2'),
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-STUDENT-COLUMN-ORDER3'),
        ],
        2: [this._translateService.instant('SCHOOL.ADMIN.IMPORT-STUDENT-COLUMN-ORDER4'), this._translateService.instant('SCHOOL.ADMIN.IMPORT-STUDENT-COLUMN-ORDER5')],
        3: [
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-STUDENT-COLUMN-ORDER6'),
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-STUDENT-COLUMN-ORDER7'),
        ],
    };
    
    numColumnas = [
        { key: 1, name: this._translateService.instant('SCHOOL.ADMIN.IMPORT-NUM-COLS1') },
        { key: 2, name: this._translateService.instant('SCHOOL.ADMIN.IMPORT-NUM-COLS2') },
        { key: 3, name: this._translateService.instant('SCHOOL.ADMIN.IMPORT-NUM-COLS3') },
    ];
    importDataConfig = {
        descartarPrimeraFila: false,
        numeroColumnasImportar: 2,
        ordenColumnas: 0,
        nombreApellidosSeparados: false,
    };

    importDataConfigProfe = {
        descartarPrimeraFila: false,
        numeroColumnasImportar: 1,
        ordenColumnas: 0,
        nombreApellidosSeparados: false,
    };
    excelAlumnos = "1";
    escelOpt = "1";
    classLetters = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "Ñ",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
    ];
    state;
    calling;
    schoolStudents = [];
    currentClass;
    checkIsAdmin;
    teacher;
    isAdmin;
    teachersAux = [];
    formDataClass: UntypedFormGroup;
    authUsers = [];
    courses;
    showStudentId = false;
    showClassId = false;
    activeItems;

    multiTimes = [
        {
            value: 1,
            name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-DEFAULT'),
        },
        {
            value: -1,
            name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-NO-TIME')
        },
        {
            value: 1.5,
            name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MODERATE'),
        },
        {
            value: 2,
            name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MEDIUM'),
        },
        {
            value: 2.5,
            name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-HIGH'),
        },
        {
            value: 3,
            name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-HIGHER'),
        },
        {
            value: 4,
            name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MAX'),
        },
    ];
    isBackground: boolean;
    msg1 = [];
    msg2 = [];
    showMessage: boolean = false;
    isIpadIphone: boolean;
    runSchoolFirstTime = true;
    regExp = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜäÄëËïÏöÖç\s\.\-'ª’]+$/u;

    constructor(
        private router: Router,
        public schoolService: SchoolService,
        private userService: UserService,
        private _matDialog: MatDialog,
        private _formBuilder: UntypedFormBuilder,
        private courseService: CourseService,
        private _ui: UiService,
        private _sharedService: SharedService,
        private navigationService: NavigationService,
        private avatarShopService: AvatarShopService,
        private changeDetectorRef: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private _sortByPipe: SortByPipe,
        private _translateService: TranslateService
    ) {  
        this._unsubscribeAll = new Subject();
        this._ui.spin$.next(true);
        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
                this.init();
            }
        });
    }

    ngOnDestroy() {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        this.activatedRoute.paramMap.pipe(takeUntil(this._unsubscribeAll), map(p => p.get('idClass') ? Number(p.get('idClass')) : null)).subscribe(idClass => {
            this._idClassToShow = idClass;
            
            if (this._idClassToShow) {
                this.ChangeState("students");
            } 

            if (
                this.navigationService.params.IdClass &&
                this.navigationService.params.IdUser &&
                this.navigationService.params.isAdmin
            ) {
                this.ChangeState("reviewActivities");
            }
    
            this.courseService.Courses.pipe(takeUntil(this._unsubscribeAll)).subscribe(courses => {
                this.courses = courses;
            })
    
            this.init();
            this.isIpadIphone = this.detectiPadiPhone();
        });        
    }

    detectiPadiPhone(): boolean {
        const userAgent = window.navigator.userAgent;
        return /iphone|iPad/i.test(userAgent);
    }

    init() {
        if (!sessionStorage.getItem("currentUser")) {
            this.goLogin();
            return;
        }
        this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        this.navigationService.reset();
        if (
            !this.navigationService.params.IdClass ||
            !this.navigationService.params.IdUser ||
            !this.navigationService.params.isAdmin
        ) {
            this._ui.showSpinner();
        }
        this.school = this.schoolService.school;
        this._ui.stopSpinner();
        this.dataLoaded = true;
        this.calling = false;
        this.loading = true;   

        this.loadClassesTeachersStudents();

        this._sharedService.run_get_school
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                if(data === true && this.runSchoolFirstTime === true){
                    this.runSchoolFirstTime = false;
                    this.getSchool();
                    return;
                }
        });
        
    }

    getSchool() {
        this.schoolService
          .getSchoolByUser(this.currentUser.Id)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            this.schoolService.school = result;
            this._sharedService.changeSchoolValidUntil(result.ValidUntil);
            this._sharedService.changeRunGetSchool(false);
            this.dataLoaded = true;
          });
      }

    selectUser(user: any) {
        this.selectedUser = user;
    }

    public selectFirstStudent(): void {
        this.selectedUser = this.schoolStudents && this.schoolStudents.length > 0 ? this.schoolStudents[0] : null;
    }

    selectClass(clase: any) {
        this.currentClass = clase;

        if (!this.currentClass) {
            return;
        }
        if (this.currentClass.AuthorizedParents.length > 0 && this.teachers.length > 0) {
            this.currentClass.AuthorizedParents.forEach(AuthTeacher => {
                AuthTeacher.UserTutor = this.teachers.find(x => x.Id == AuthTeacher.IdUserTutor)
            });
        }
        this.currentClass.Students.forEach(student => {
            student.Course = this.courses.find(x => x.Level == student.Level)
        });
    }

    ShowClassErrorMessage(title : string) {
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        this._translateService.instant('SCHOOL.ADMIN.MODAL-NEW-CLASS-ERROR-MESSAGE')
        confirmDialog.componentInstance.title = title
        confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.ADMIN.MODAL-NEW-CLASS-ERROR-MESSAGE');
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;
        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                confirmDialog = null;
            });
        return;
    }
    showMaxLicensesMessage(numStudents: number, title) {
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = title;
        confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.ADMIN.MODAL-MAX-LICENSES-MESSAGE', {numLicenses: numStudents, totalLicenses: (this.school.MaxNumStudents - this.school.NumStudents)});
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;
        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                confirmDialog = null;
            });
        return;
    }

    errorGetStudentsName(response) {
        this.loading = false;
        this._ui.stopSpinner();
        var excelFile: any = document.getElementById("excelFile");
        excelFile.value = "";
        this.loading = false;
        var message = [];
        if (Array.isArray(response)) {
            for (var i = 0; i < response.length; i++) {
                message[i] = response[i].split(";")[0];
            }
        } else {
            message[0] =
                this._translateService.instant('SCHOOL.ADMIN.MODAL-ERROR-LOAD-EXCEL-MESSAGE');
        }

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title =
            this._translateService.instant('SCHOOL.ADMIN.MODAL-ERROR-LOAD-EXCEL-TITLE');
        confirmDialog.componentInstance.messages = message;
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                this.confirmDialog = null;
            });
    }

    importExcelSplit(cell, sort) {
        var obj = [];
        var surname2 = "";
        var name = "";
        switch (sort) {
            case "0":
                if (cell.length > 2) {
                    obj = [cell[0] + "." + cell[1] + "." + cell[2], ""];
                } else {
                    obj = [cell[0] + "." + cell[1], ""];
                }

                break;
            case "1":
                obj = [cell[0], cell[1]];
                break;
            case "2":
                obj = [cell[1], cell[0]];
                break;
            case "3":
                if (cell.length > 2) {
                    surname2 = cell[1];
                    name = cell[2];
                } else {
                    name = cell[1];
                }

                obj = [name, cell[0] + " " + surname2];
                break;
            default:
                break;
        }
        return obj;
    }

    importExcelSplitTeacher(cell, sort) {
        var obj = [];
        switch (sort) {
            case 0:
                obj = [cell[1], cell[0]];
                break;
            case 1:
                obj = [cell[1], cell[0]];
                break;
            case 2:
                obj = [cell[0], cell[1]];
                break;

            default:
                break;
        }
        return obj;
    }

    exportData(tipo) {
        var listado = [];
        if (this.excelAlumnos == "1" && tipo == "alumnos") {
            tipo = "alumnos2";
        }
        if (this.escelOpt == "2" && tipo == "aulas") {
            tipo = "aulas2";
        }
        if (this.escelOpt == "2" && tipo == "profesores") {
            tipo = "profesores2";
        }

        switch (tipo) {
            case "alumnos":
                var tmpStds = [];
                var opts = [];
                tmpStds = this.schoolStudents;
                tmpStds.sort(function (a, b) {
                    if (a.FamilyName > b.FamilyName) return 1;
                    else if (a.FamilyName < b.FamilyName) return -1;
                    else return 0;
                });
                var tmpArray = [];
                listado.push(tmpArray);
                for (var i = 0; i < tmpStds.length; i++) {
                    listado[0].push({
                        Nombre: tmpStds[i].FamilyName + ", " + tmpStds[i].Name,
                        Usuario: tmpStds[i].UserName,
                        Curso: tmpStds[i].Course.Name,
                        Aula:
                            tmpStds[i].Class != null
                                ? tmpStds[i].Class.Name
                                : "sin clase",
                    });
                }

                opts.push({ sheetid: "alumnos", header: true });
                alasql(
                    'SELECT INTO XLSX("listado alumnos ' +
                    this.school.Name +
                    ' 2.xlsx",?) FROM ?',
                    [opts, listado]
                );

                break;

            case "alumnos2":
                for (let i = 0; i < this.schoolStudents.length; i++) {
                    listado.push({
                        Nombre:
                            this.schoolStudents[i].FamilyName +
                            ", " +
                            this.schoolStudents[i].Name,
                        Usuario: this.schoolStudents[i].UserName,
                        Curso: this.schoolStudents[i].Course.Name,
                        Level: this.schoolStudents[i].Level,
                        Aula:
                            this.schoolStudents[i].Class != null
                                ? this.schoolStudents[i].Class.Name
                                : "sin clase",
                    });
                }

                var data1 = [
                    { a: 1, b: 10 },
                    { a: 2, b: 20 },
                ];
                var data2 = [
                    { a: 100, b: 10 },
                    { a: 200, b: 20 },
                ];
                var datas = [];
                var opts = [];

                //cargo las aulas
                var aulas = [];
                for (i = 0; i < listado.length; i++) {
                    if (
                        aulas
                            .map(function (e) {
                                return e.name;
                            })
                            .indexOf(listado[i].Aula) < 0
                    ) {
                        aulas.push({
                            name: listado[i].Aula,
                            level: listado[i].Level,
                        });
                    }
                }
                //aulas.sort();
                aulas.sort(function (a, b) {
                    if (a.level === b.level) {
                        if (a.name > b.name) return 1;
                        else if (a.name < b.name) return -1;
                        else return 0;
                    }
                    if (a.level > b.level) return 1;
                    else if (a.level < b.level) return -1;
                    else return 0;
                });
                //cargo las opciones, que son las pestañas del excel (aulas)
                for (i = 0; i < aulas.length; i++) {
                    opts.push({ sheetid: aulas[i].name, header: true });
                }
                //creo un array de datos por aula
                for (i = 0; i < aulas.length; i++) {
                    datas[i] = [];
                }
                //cargo cada alumno en su listado correspondiente
                for (i = 0; i < listado.length; i++) {
                    var arr_aula = aulas
                        .map(function (e) {
                            return e.name;
                        })
                        .indexOf(listado[i].Aula);
                    datas[arr_aula].push({
                        Nombre: listado[i].Nombre,
                        Usuario: listado[i].Usuario,
                    });
                }
                alasql(
                    'SELECT INTO XLSX("listado alumnos ' +
                    this.school.Name +
                    '.xlsx",?) FROM ?',
                    [opts, datas]
                );

                break;

            case "alumnos_aula":
                var tmpStds2 = [];
                var opts = [];

                tmpStds2 = this.currentClass.Students;
                tmpStds2.sort(function (a, b) {
                    if (a.FamilyName > b.FamilyName) return 1;
                    else if (a.FamilyName < b.FamilyName) return -1;
                    else return 0;
                });
                var tmpArray = [];
                listado.push(tmpArray);
                for (var i = 0; i < tmpStds2.length; i++) {
                    listado[0].push({
                        Nombre:
                            tmpStds2[i].FamilyName + ", " + tmpStds2[i].Name,
                        Usuario: tmpStds2[i].UserName,
                        Curso: tmpStds2[i].Course.Name,
                    });
                }
                opts.push({ sheetid: "alumnos", header: true });
                alasql(
                    'SELECT INTO XLSX("listado alumnos ' +
                    this.currentClass.Name +
                    " " +
                    this.school.Name +
                    '.xlsx",?) FROM ?',
                    [opts, listado]
                );
                break;
        }
        this.escelOpt = "1";
    }

    errorCallback(error) {
        this.calling = false;
        if (error) {
            if (error == "className") {
                if (this.state == "newClasses") {
                    this.ShowClassErrorMessage(this._translateService.instant('SCHOOL.MODAL-ERROR-GENERATE-CLASS'));
                    return;
                } else {
                    this.ShowClassErrorMessage(this._translateService.instant('SCHOOL.MODAL-ERROR-ADD-CLASS'));
                    return;
                }
            }

        } else {
            this.goLogin();
        }
    }

    successLoadAuth(response) {
        if (response != null) {
            this.authUsers = response;
        }
        this.calling = false;
    }

    goLogin() {
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("adminLogin");
        sessionStorage.removeItem("state");
        sessionStorage.removeItem("state_params");
        sessionStorage.setItem("isLogged", "false");
        this.router.navigateByUrl("login");
    }

    successGetSchool(response: any, state: string = null) {
        this.calling = false;

        this.loading = true;

        if (response == null) {
            this.goLogin();
            return;
        }

        this.school = response;
        this._sharedService.changeMaxNumStudents(this.school.MaxNumStudents);
        this._sharedService.changeNumStudents(this.school.NumStudents);
        this._sharedService.changeSchoolValidUntil(this.school.ValidUntil);
        this.schoolService.school = this.school;
        let c;

        this.loadClassesTeachersStudents(state);
    }

    loadClassData(clases: any): void {
        let c = null;
        this.school.Classes = clases;
        if(clases.length > 0 && this.school.Classes.find(x => x.IdTutor == this.currentUser.Id || x.AuthorizedParents.find(x => x.IdUserTutor == this.currentUser.Id))){
            this._sharedService.changeSchoolHasClasses(true);
        }
        //this._sharedService.changeSchoolHasClasses(this.school.Classes.length > 0);
        if (this.currentClass) {
            c = this.school.Classes.find(
                (c) => c.IdClass == this.currentClass.IdClass
            );
            this.selectClass(c);
        } else if (this._idClassToShow) {
            c = this.school.Classes.find(c => c.IdClass == this._idClassToShow);
            this.selectClass(c);
        } else {
            c = null;
            this.selectClass(c);
        }
    }

    getSchoolStudents() {
        this.schoolService.getStudentsSchool(this.currentUser.IdSchool)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(students => {
                this.loadStudentsData(students)
            }, (err) => {
                this.errorCallback(err);
        })
    }

    loadTeachersData(teachers: any): void {
        this.teachers = teachers;
        this.teachersAux = [];
        for (var i = 0; i < this.teachers.length; i++) {
            if (this.currentClass && this.teachers[i].Id == this.currentClass.IdTutor)
                continue;
            if (
                this.currentClass &&
                this.currentClass.AuthorizedParents.find(
                    (a) => a.IdUserTutor == this.teachers[i].Id
                )
            )
                continue;
            this.teachersAux.push(this.teachers[i]);
        }
        this.selectedTeacherAux = this.teachersAux.length > 0 ? this.teachersAux[0].Id : null;
        if (this.isTeacher(this.selectedUser)) {
            let profe = this.teachers.find(x => x.Id == this.selectedUser.Id)
            this.selectUser(profe);
        }

        this.calling = false;
    }

    loadStudentsData(students: any): void {
        this.school.NumStudents = students.length;
        this._sharedService.changeNumStudents(this.school.NumStudents);
        this._sharedService.changeMaxNumStudents(this.school.MaxNumStudents);
        this.school.Users = students;

        if (this.selectedUser) {
            this.getStudents();
        }
    }


    loadClassesTeachersStudents(state: string = null): void {
        forkJoin([
            this.schoolService.getSchoolClasses(this.school.IdSchool),
            this.schoolService.getSchoolTeachers(this.school.IdSchool),
            this.schoolService.getStudentsSchool(this.currentUser.IdSchool),
            this.userService.GetAuthorizedStudentsByParentId(this.currentUser.Id)
        ])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (data: any[]) => {
                    this.loadClassData(data[0])
                    this.loadTeachersData(data[1]);
                    this.loadStudentsData(data[2]);
                    this.successLoadAuth(data[3]);
                    this.loading = false;
                    this.dataLoaded2 = true;

                    if (this.navigationService.params.state) {
                        this.ChangeState(this.navigationService.params.state);
                    } else if (state != null) {
                        this.ChangeState(state);
                    } else {    
                        if (this.state) this.ChangeState(this.state);
                        else {
                            if (sessionStorage.getItem(Profiles.IsSchoolAdmin) === 'true' || sessionStorage.getItem(Profiles.IsSuperAdmin) === 'true')
                                this.studentList(true);
                            else
                                this.goLogin();
                        }
                    }
                    this.calling = false;
                },
                error: (err: any) => { },
            });
    }

    volver() {
        this.navigationService.resetParams();
        if (this.extraFields == true) {
            this.extraFields = false;
            return;
        }
        // Pablo seteaba este valor a null, pero no especifico por que
        //this.selectedUser = null;
        switch (this.state) {
            case "newStudent1":
            case "reviewActivities":
                this.studentList();

                break;

            case "editStudent":
                if (this.editFromClass)
                    this.viewStudents();
                else
                    this.studentList();

                break;

            case "authTutor":
                if (this.editFromClass)
                    this.viewStudents();
                else
                    this.studentList();

                break;

            case "newStudent":                
                if (this.editFromClass)
                    this.viewStudents();
                else
                    this.studentList();        

                break;

            case "students":
                this.router.navigateByUrl(`/school/admin/classes/${this._idClassToShow}`);

                break;

            default:
                this.router.navigateByUrl("/school/admin");
                break;
        }
    }

    goNewStudent() {
        this.state = "newStudent1";        
        if (this.school.Classes.length != 0)
            this.currentClass = this.school.Classes[0];
    }

    unexpectedError(response) {
        this.calling = false;
        var message = [];
        if (response == "NoValidLicense") {
            message[0] = this._translateService.instant('SCHOOL.MODAL-NO-VALID-LICENSE-TITLE');
            message[1] = this._translateService.instant('SCHOOL.MODAL-NO-VALID-LICENSE-MESSAGE');
        } else {
            message[0] = this._translateService.instant('ERROR');
            message[1] = this._translateService.instant('SCHOOL.MODAL-ERROR-MESSAGE');
        }
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                return;
            });
    }

    successResetStudentPassword(response) {
        this.calling = false;
        var message = [];
        message[0] = this._translateService.instant('SCHOOL.MODAL-SUCCESS-RESET-PASSWORD-TITLE');
        message[1] = this._translateService.instant('SCHOOL.MODAL-SUCCESS-RESET-PASSWORD-MESSAGE');

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                this.confirmDialog = null;
            });
    }

    errorDeleteStudent(response) {
        this.calling = false;
        if (response == null || response == null || response.length == 0) {
            this.goLogin();
        } else {
            var message = [];
            message[0] = this._translateService.instant('SCHOOL.DELETE-USER-ERROR-TITLE');
            message[1] = this._translateService.instant('SCHOOL.DELETE-USER-ERROR-MESSAGE');
            message[2] = this._translateService.instant('SCHOOL.MODAL-ERROR-MESSAGE');

            let confirmDialog = this._matDialog.open(
                FuseDialogContinueComponent,
                {
                    disableClose: true,
                }
            );
            confirmDialog.componentInstance.title = message[0];
            confirmDialog.componentInstance.message1 = message[1];
            confirmDialog.componentInstance.message2 = message[2];
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
            confirmDialog.componentInstance.margin = false;

            confirmDialog
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result) => {
                    this.confirmDialog = null;
                });

            return;
        }
    }

    successDeleteStudent(response) {
        this.calling = false;
        this.loading = false;
        var message = [];
        message[0] = this._translateService.instant('SCHOOL.MODAL-ERROR-DELETE-STUDENT-TITLE');
        message[1] = this._translateService.instant('SCHOOL.MODAL-SUCCESS-DELETE-STUDENT-MESSAGE');

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        // confirmDialog.componentInstance.textButton2 = "No"
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {                
                if (this.state == "studentList") {
                    this.getSchoolStudents();
                } else {
                    this.school.NumStudents--;
                    this.schoolService
                    .getSchoolByUser(this.currentUser.Id)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(
                    (result) => {
                        this.successGetSchool(result, "students");
                    },
                    (error) => {
                        this.errorCallback(error.error);
                    }
                );
                }
                this.calling = false;
                this.confirmDialog = null;
            });
    }

    generateStudents() {
        if (this.calling) return;
        var numStudents = this.numStd;
        this.numStudentsError = 0;
        if (numStudents == null || isNaN(numStudents) === true) {
            this.numStudentsError = 1;
            return;
        }
        if (numStudents <= 0 || numStudents > 50) {
            this.numStudentsError = 2;
            return;
        }
        if (this.school.NumStudents + numStudents > this.school.MaxNumStudents) {
            this.showMaxLicensesMessage(numStudents, this._translateService.instant('SCHOOL.MODAL-ERROR-GENERATE-STUDENTS'));
            return;
        }
        var listado = [];
        var offset = this.getAlulaToImport().NumStudents;
        if (offset == null && this.getAlulaToImport().Students != null)
            offset = this.getAlulaToImport().Students.length;
        if (offset == null) offset = 0;
        var classCode = this.getAlulaToImport().Code;
        var dotIndex = classCode.indexOf(".");
        if (dotIndex >= 0) dotIndex++;
        classCode = classCode.substring(dotIndex, classCode.lenght);

        for (var i = 0; i < numStudents; i++) {
            var nombre = classCode.substring(0, 2);
            var apellido =
                classCode.substring(1, classCode.lenght) + (i + 1 + offset);
            listado.push({ Nombre: nombre, Apellidos: apellido });
        }
        this.calling = true;
        this._ui.showSpinner();
        this.schoolService
            .getStudentsName(
                this.school.IdSchool,
                this.school.Initials,
                listado,
                this.getAlulaToImport().Code
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.successGetStudentsName(result);
                },
                (error) => {
                    this.errorGetStudentsName(error.error);
                }
            );
    }

    getAlulaToImport() {
        return this.currentClass;
    }

    successGetStudentsName(response) {
        this.loading = false;
        this.calling = false;
        this._ui.stopSpinner();
        var message = [];
        
        message[0] = this._translateService.instant('SCHOOL.ADMIN.MODAL-SUCCESS-GENERATE-STUDENTS-TITLE');
        message[1] = this._translateService.instant('SCHOOL.ADMIN.MODAL-SUCCESS-GENERATE-STUDENTS-MESSAGE');
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        // confirmDialog.componentInstance.textButton2 = "No"
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(_ => this.loadClassesTeachersStudents("students"));
    }

    isTeacher(teacher) {
        var r = false;
        if (teacher && teacher.IdRole && (teacher.IdRole == 2 || teacher.IdRole == 6 || teacher.IdRole == 5)) {
            r = true;
        }
        return r;
    }

    successCallbackCreateClass(response) {
        this.calling = false;
        this.extraFields = false;
        if (response == null || response == null || response.length == 0) {
            this.goLogin();
        } else {
            this.currentClass = response;
            this.loadClassesTeachersStudents("students");
        }
    }

    successCallbackUpdateUser(response) {
        var message = [];
        message[0] = "";
        message[1] = this._translateService.instant('SCHOOL.MODAL-SUCCESS-UPDATE-STUDENT-MESSAGE');

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                if (this.currentClass != null) {
                    this.schoolService
                    .getSchoolByUser(this.currentUser.Id)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(
                    (result) => {
                        this.successGetSchool(result, "students");
                    },
                    (error) => {
                        this.errorCallback(error.error);
                    }
                );
                } else {
                    this.getSchoolStudents();
                    this.studentList();
                }
                this.confirmDialog = null;
            });
    }

    successCallbackCreateStudent(response) {
        this.school.NumStudents++;
        this._sharedService.changeNumStudents(this.school.NumStudents);
        this._sharedService.changeMaxNumStudents(this.school.MaxNumStudents);
        var message = [];
        message[0] = this._translateService.instant('SCHOOL.MODAL-SUCCESS-NEW-STUDENT-TITLE');
        message[1] = this._translateService.instant('SCHOOL.MODAL-SUCCESS-NEW-STUDENT-MESSAGE', {UserName: this.formDataStudent.controls['Username'].value})

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.schoolService
                    .getSchoolByUser(this.currentUser.Id)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(
                        (result) => {
                            this.successGetSchool(result, "students");
                    },
                    (error) => {
                        this.errorCallback(error.error);
                    }
                );
                this.confirmDialog = null;
            });
    }

    startPayment() {
        // $state.go('parentMenu.adminPayment', { school: this.school });
        this.navigationService.go("/school/adminPayment", {});
    }

    startPromo() {
        // $state.go('parentMenu.adminPromo', { school: this.school });
        this.router.navigateByUrl("/school/adminPromo");
    }

    ChangeState(state) {
        this.state = state;
        if(state == "students" && this.currentClass != null){
            this.selectUser(this.currentClass.Students[0]);
        }
        this.loading = false;
        this.navigationService.enableBackButton(true, this.volver.bind(this));
        setTimeout(() => this.changeDetectorRef.detectChanges());
    }

    studentList(selectFirstStudent: boolean = false) {
        if (selectFirstStudent) {
            this.selectFirstStudent();
        }

        this.currentClass = null;

        if (
            this.dataLoaded2 == true &&
            (this.school.Classes == null || this.school.Classes.length == 0)
        ) {
            this.newClass();
        } else {
            this.loadAllStudents();
        }
    }

    newClass() {
        if (this.formDataClass) {
            this.formDataClass.reset();

            if(this.formDataClassDirective){
                this.formDataClassDirective.resetForm();
            }
        }

        this.teachersAux = this.teachers;
        this.formDataClass = this._formBuilder.group({
            Level: ["", Validators.required],
            Teacher: ["", Validators.required],
            TeacherAux: [""],
            Name: ["", Validators.required],
            Code: [""],
        });

        
        this.ChangeState("newClass");
    }

    newStudent() {
        if (this.formDataStudent) {
            this.formDataStudent.reset();

            if(this.formDataStudentDirective){
                this.formDataStudentDirective.resetForm();
            }
        }

        this.formDataStudent = this._formBuilder.group({
            Name: ["", [Validators.required, Validators.pattern(this.regExp), Validators.maxLength(40)]],
            FamilyName: ["", [Validators.pattern(this.regExp), Validators.maxLength(50)]],
            Username: [{ value: "", disabled: true }],
            Password: [{ value: "12345678", disabled: true }],
        });
        
        if (this.state === "newStudent1") this.editFromClass = false;
        else this.editFromClass = true;
        this.ChangeState("newStudent");
    }

    exit() {
        this.goLogin();
    }

    viewReports() {
        this.router.navigateByUrl("/school/admin/report");
    }

    setStudentName(response) {
        this.calling = false;
        if (response.Exists != null) {
            //Show warning
            var message = [];
            message[0] = this._translateService.instant('SCHOOL.MODAL-NEW-STUDENT-TITLE');
            message[1]= this._translateService.instant('SCHOOL.MODAL-NEW-STUDENT-MESSAGE', {StudentName: this.formDataStudent.value.Name, FamilyName: this.formDataStudent.value.FamilyName || '', Exists: response.Exists})

            let confirmDialog = this._matDialog.open(
                FuseDialogContinueComponent,
                {
                    disableClose: true,
                }
            );
            confirmDialog.componentInstance.title = message[0];
            confirmDialog.componentInstance.message1 = message[1];
            confirmDialog.componentInstance.message2 = message[2];
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
            confirmDialog.componentInstance.margin = false;
            confirmDialog.componentInstance.theme = "white";

            confirmDialog.componentInstance.options = [
                {
                    text: "Sí",
                    callback: () => {
                        this.formDataStudent.patchValue({
                            Username: response.Username,
                        });
                        this.submitFormStudent();
                    },
                },
                {
                    text: "No",
                    callback: () => { },
                },
            ];

            confirmDialog
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result) => {
                    result();
                    this.confirmDialog = null;
                });
        } else {
            this.formDataStudent.patchValue({ Username: response.Username });
            this.submitFormStudent();
        }
    }

    invalidStudentUsername(response) {
        this.calling = false;
    }
    
    submitFormStudent() {
        if (this.calling) return;

        var student = {
            UserName: this.formDataStudent.controls["Username"].value,
            Name: this.formDataStudent.controls["Name"].value.trim(),
            FamilyName: this.formDataStudent.controls["FamilyName"].value?.trim() || null,
            IdSchool: this.school.IdSchool,
        };

        this.schoolService
            .addStudent(
                student,
                this.currentClass.Code,
                this.formDataStudent.controls["Password"].value,
                true,
                "añadir alumno area admin"
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.successCallbackCreateStudent(result);
                },
                (error) => {
                    this.unexpectedError(error.error);
                }
            );
        this.calling = true;
    }

    getStudentName() {
        this.formDataStudent.markAllAsTouched();
        let valid = true;
        if (this.formDataStudent.value.Name == "") {
            this.formDataStudent.controls["Name"].setErrors({ required: true });
            valid = false;
        }
        if (!valid || this.formDataStudent.invalid) return;

        this.formDataStudent.value.Name = this.formDataStudent.value.Name.trim().replace(" ", "-");
        this.formDataStudent.value.FamilyName = this.formDataStudent.value.FamilyName?.trim().replace(" ", "-") || null;
        this.schoolService
            .getStudentName(
                this.school.Initials,
                this.formDataStudent.value.Name,
                this.currentClass.Code,
                this.formDataStudent.value.FamilyName
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.setStudentName(result);
                },
                (error) => {
                    this.invalidStudentUsername(error.error);
                }
            );
        this.calling = true;
    }

    cancelStudent() {
        if (this.currentClass == null || this.currentClass.Students == null)
            this.ChangeState("studentList");
        else this.ChangeState("students");
    }

    student;

    editStudent() {
        if (this.calling) return;

        if (!this.selectedUser || !this.selectedUser.Id) return;
        this.student = this.selectedUser;
        if (this.state == "studentList") this.editFromClass = false;
        else this.editFromClass = true;

        this.ChangeState("editStudent");

        if (this.student.MultTime) {
            this.student.MultTime = this.student.MultTime;
        } else {
            this.student.MultTime = 1;
        }

        this.formDataStudent = this._formBuilder.group({
            Name: [this.student.Name, [Validators.required, Validators.pattern(this.regExp), Validators.maxLength(40)]],
            FamilyName: [this.student.FamilyName, [Validators.pattern(this.regExp), Validators.maxLength(50)]],
            Username: [
                this.student.UserName,
                [
                    Validators.required,
                    Validators.minLength(6),
                    Validators.maxLength(50),
                    Validators.pattern(/^[0-9A-Za-zñÑ\.]+$/),
                ],
            ],
            Alias: [this.student.Alias, Validators.maxLength(50)],
            Password: [""],
            Level: [this.student.Level],
            IdClass: [this.student.IdClass],
            MultTime: [this.student.MultTime],
        });
        this.GetActiveItems(this.selectedUser.Id);
        this.resetStUsername();

        setTimeout(() => {
            $("#studentUserName").bind("input propertychange", () => {
                this.resetStUsername();
            });
        }, 1000);
    }

    updateStudent() {
        this.formDataStudent.markAllAsTouched();
        let userNameLenghtRegex = /^.{6,50}$/;
        var userNameRegex = /^[0-9A-Za-záéíóúÁÉÍÓÚñÑ\.]+$/;
        if (
            this.formDataStudent.value.Username ==
            this.schoolService.school.Initials.toLowerCase() + "."
        ) {
            this.formDataStudent.controls["Username"].setErrors({
                required: true,
            });
            return;
        }
        var validUsername =
            this.formDataStudent.value.Username.match(userNameLenghtRegex);
        if (validUsername == null && this.student.UserName.length >= 6) {
            this.formDataStudent.controls["Username"].setErrors({
                length: true,
            });
            return;
        }
        validUsername =
            this.formDataStudent.value.Username.match(userNameRegex);
        if (validUsername == null) {
            this.formDataStudent.controls["Username"].setErrors({
                invalidName: false,
            });
            return;
        }
        if (this.formDataStudent.value.Alias.length >= 50) {
            let confirmDialog = this._matDialog.open(
                FuseDialogContinueComponent,
                {
                    disableClose: true,
                }
            );
            confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.MODAL-ERROR-EDIT-STUDENT-TITLE');
            confirmDialog.componentInstance.message1 = this._translateService.instant('ALIAS-MAX');
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
            confirmDialog.componentInstance.margin = false;
            confirmDialog
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result) => {
                    confirmDialog = null;
                });
            return;
        }
        if(this.formDataStudent.invalid){
            return;
        }
        
        let user: UpdateStudent = {
            Id: this.student.Id,
            Name: this.formDataStudent.value.Name.trim(),
            FamilyName: this.formDataStudent.value.FamilyName?.trim() || null,
            Alias: this.formDataStudent.value.Alias.trim(),
            IdClass: this.formDataStudent.value.IdClass,
            MultTime: this.formDataStudent.value.MultTime,
            Level: this.formDataStudent.value.Level,
            UserName: this.formDataStudent.value.Username.trim(),
            SmallSession: this.student.SmallSession,
            BlockName: this.student.BlockName,
            BlockAlias: this.student.BlockAlias,
            BlockContact: this.student.BlockContact,
            GiftShopDisabled: this.student.GiftShopDisabled,
            BlockMessageGift: this.formDataStudent.value.BlockMessageGift,
            AvatarDisabled: this.formDataStudent.value.AvatarDisabled,
        };
        this.userService
            .updateUserProfile(user)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                this.successCallbackUpdateUser.bind(this),
                this.errorUpdateStudent.bind(this)
            );
    }

    errorUpdateStudent(response) {
        if (response.error == "UserName") {
            this.formDataStudent.controls["Username"].setErrors({
                unique: true,
            });
        } else {
            this.errorCallback(response.error);
        }
    }

    getStudents() {
        this.schoolStudents = [];

        for (var i = 0; i < this.school.Users.length; i++) {
            if (
                this.school.Users[i].IdUserTutor != null &&
                this.school.Users[i].NormalizedUserName.split(".")[1] != "TMP"
            )
                this.schoolStudents.push(this.school.Users[i]);
        }

        this.schoolStudents = this._sortByPipe.transform((this.schoolStudents || []), 'asc', ['FamilyName','Name']);

        if (
            (this.schoolStudents.length > 0 &&
                this.isTeacher(this.selectedUser)) ||
            this.selectedUser?.IdRole == null
        ) {
            this.selectUser(this.schoolStudents[0]);
        } else if (this.selectedUser && !this.isTeacher(this.selectedUser)) {
            this.selectUser(
                this.schoolStudents.find((x) => x.Id == this.selectedUser.Id)
            );
        }

        this.dataLoaded2 = true;
        this.loading = false;
    }

    getComa(user) {
        if (
            user.FamilyName != null &&
            user.Name != null &&
            user.FamilyName != "" &&
            user.Name != ""
        ) {
            return ",";
        }
        return "";
    }

    parseProfesorName(user) {
        return user.FamilyName + ", " + user.Name;
    }

    viewStudents() {
        if (this.calling) return;
        if (!this.currentClass) return;
        if (this.currentClass.IdClass == null) return;

        this.selectedUserOld = this.selectedUser;
        this.selectUser(this.currentClass.Students[0]);
        this.calling = false;
        this.ChangeState("students");
    }

    loadAllStudents() {        
        if (this.calling) return;
        this.school.NumStudents = this.school.Users.length;
        this.getStudents();
        this.ChangeState("studentList");
    }

    resetStUsername() {
        if (
            this.formDataStudent.value.Username == null ||
            this.formDataStudent.value.Username.toLowerCase().indexOf(
                this.school.Initials.toLowerCase() + "."
            ) != 0
        ) {
            this.formDataStudent.patchValue({
                Username: this.school.Initials.toLowerCase() + ".",
            });
        }
    }

    validateEmail(email) {
        var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    resetStudentPassword() {
        if (this.calling) return;

        if (!this.selectedUser || !this.selectedUser.Id) return;

        var user = this.selectedUser;

        var message = [];
        message[0] = this._translateService.instant('SCHOOL.MODAL-RESET-PASSWORD-TITLE');
        message[1] =
        this._translateService.instant('SCHOOL.MODAL-RESET-PASSWORD-MESSAGE', {UserName: user.UserName});

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.message2 = message[2];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.theme = "white";

        confirmDialog.componentInstance.options = [
            {
                text: "Sí",
                callback: () => {
                    this.schoolService
                        .resetPassword(
                            user.Id,
                            this.currentUser.Id,
                            this.currentUser.IsSchoolAdmin
                        )
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(
                            this.successResetStudentPassword.bind(this),
                            this.unexpectedError.bind(this)
                        );
                },
            },
            {
                text: "No",
                callback: () => { },
            },
        ];

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                result();
                confirmDialog = null;
            });
    }

    authTutor() {
        if (this.calling) return;

        if (!this.selectedUser || !this.selectedUser.Id) return;

        this.student = this.selectedUser;

        if (this.state == "studentList") this.editFromClass = false;
        else this.editFromClass = true;

        this.ChangeState("authTutor");
        this.userService
            .getAuthorizedUsers(this.student.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                this.successGetAuthUsers.bind(this),
                this.errorCallback.bind(this)
            );
    }

    successGetAuthUsers(response) {
        if (response == null) return this.navigationService.exit();
        this.authUsers = response;
        if (this.authUsers.length > 0) this.selectAuthUser(this.authUsers[0]);
        this.email = "";
        this.dataLoaded = true;
    }

    selectAuthUser(authUser) {
        this.selectedAuthUser = authUser;
    }

    addAuthUser() {
        if (this.calling) return;

        this.error = false;
        this.errorDescription = null;
        if (this.email == null || this.email == "") {
            this.error = true;
            this.errorDescription =
                this._translateService.instant('SCHOOL.EMAIL-EMPTY');
        } else if (this.validateEmail(this.email) == false) {
            this.error = true;
            this.errorDescription = this._translateService.instant('EMAIL-INVALID');
        } else {
            this.calling = true;
            this.userService
                .addAuthorizedUser(
                    this.student.Id,
                    this.currentUser.Id,
                    this.email,
                    this.correoConfirmacion
                )
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    this.successAuthUser.bind(this),
                    this.errorCallbackAddUser.bind(this)
                );
        }
    }

    deleteAuthUser() {
        if (this.calling) return;

        var message = [];
        message[0] = this._translateService.instant('SCHOOL.ATTENTION');
        message[1] = this._translateService.instant('SCHOOL.MODAL-DELETE-AUTH-TEACHER-MESSAGE');

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.message2 = message[2];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.theme = "white";
        confirmDialog.componentInstance.margin = false;

        confirmDialog.componentInstance.options = [
            {
                text: this._translateService.instant('ACCEPT'),
                callback: () => {
                    this.calling = true;
                    this.userService
                        .removeAuthorizedUser(
                            this.student.Id,
                            this.currentUser.Id,
                            this.selectedAuthUser
                        )
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(
                            this.successAuthUser.bind(this),
                            this.errorCallback.bind(this)
                        );
                },
            },
            {
                text: this._translateService.instant('CANCEL'),
                callback: () => { },
            },
        ];
        confirmDialog.componentInstance.margin = false;
        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                result();
                confirmDialog = null;
            });
    }

    successAuthUser(response) {
        this.calling = false;
        this.userService
            .getAuthorizedUsers(this.student.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                this.successGetAuthUsers.bind(this),
                this.errorCallback.bind(this)
            );
    }

    errorCallbackAddUser(response) {
        if (response != null && response == "UserNotFound")
            this.errorDescription = this._translateService.instant('EMAIL-INVALID');
        if (response != null && response.error == "SameUser")
            this.errorDescription = this._translateService.instant('SCHOOL.ERROR-AUTH-TEACHER-USER-ALREADY-AUTH');
        else this.errorDescription = this._translateService.instant('EMAIL-INVALID');
        this.calling = false;
        this.error = true;
    }

    deleteStudent() {
        if (this.calling) return;
        if (this.loading) return;
        if (!this.selectedUser || !this.selectedUser.Id) return;

        var user = this.selectedUser;

        var message = [];
        message[0] = this._translateService.instant('SCHOOL.MODAL-ERROR-DELETE-STUDENT-TITLE');
        message[1] =this._translateService.instant('SCHOOL.MODAL-DELETE-STUDENT-MESSAGE', {UserName: user.UserName})

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.message2 = message[2];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.theme = "white";
        confirmDialog.componentInstance.options = [
            {
                text: "Sí",
                callback: () => {
                    this.loading = true;
                    this.schoolService
                        .DeleteStudent(
                            user.Id
                        )
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(
                            this.successDeleteStudent.bind(this),
                            this.errorDeleteStudent.bind(this)
                        );
                },
            },
            {
                text: "No",
                callback: () => { },
            },
        ];

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                result();
                confirmDialog = null;
            });
    }

    saveClass() {
        this.formDataClass.markAllAsTouched();
        if (this.calling) return;

        if (this.formDataClass.invalid) return;

        this.schoolService
            .addClass(
                this.formDataClass.value.Name,
                this.formDataClass.value.Level,
                this.formDataClass.value.Teacher
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.successCallbackCreateClass(result);
                },
                (error) => {
                    this.errorCallback(error.error);
                }
            );
        this.calling = true;
    }

    changeExtraFields() {
        this.extraFields = !this.extraFields;
        this.teachersAux = this.teachers.filter(x => this.currentClass.IdTutor != x.Id && !this.currentClass.AuthorizedParents.find(y => y.IdUserTutor == x.Id))
    }

    selectFile() {
        document.getElementById("excelFile").click();
    }
    GetActiveItems(id): void {
        this.avatarShopService.GetActiveItemsByIdUser(id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                this.activeItems = result;
            })
    }

    async saveAvatar() {
        this.isBackground = true;
        const avatar = document.getElementById("avatar-download")
        this.changeDetectorRef.detectChanges();
        
        setTimeout(async () => {
          await htmlToImage.toPng(avatar, { cacheBust: true, backgroundColor: 'transparent' });
          await htmlToImage.toPng(avatar, { cacheBust: true, backgroundColor: 'transparent' });
          await htmlToImage.toPng(avatar, { cacheBust: true, backgroundColor: 'transparent' });
          this.changeDetectorRef.detectChanges();
          
          setTimeout(async () => {
              let dataUrl = await Promise.all([
                  htmlToImage.toPng(avatar, { cacheBust: true, backgroundColor: 'transparent' }),
                  htmlToImage.toPng(avatar, { cacheBust: true, backgroundColor: 'transparent' }),
                  htmlToImage.toPng(avatar, { cacheBust: true, backgroundColor: 'transparent' })
              ]).then((dataUrls) => dataUrls[2]);
      
              setTimeout(() => {
                  let link = document.createElement("a"); 
          
                  link.download = `${this.formDataStudent.value.Username}.png`;
                  link.href = dataUrl;
                  link.click();
          
                  setTimeout(() => {
                      this.isBackground = false;
                      this.changeDetectorRef.detectChanges();
                  });
              });
          });
      });
    }

    goSessionReview() {
        if (!this.selectedUser || !this.selectedUser.Id) return;
        this.ChangeState("reviewActivities");
        this.navigationService.resetParams();
        this.navigationService.go(
            "/school/admin/students/null/sessionAdmin/" +
            this.selectedUser.Id +
            "/" +
            this.selectedUser.IdClass,
            {
                IdUser: this.selectedUser.Id,
                IdClass: this.selectedUser.IdClass,
                isAdmin: true,
            }
        );
    }
    change() {
        this.importDataConfig.ordenColumnas = 0;
        this.importDataConfigProfe.ordenColumnas = 0;
    }
}
