import { environment } from 'environments/environment';
import { AvatarShopService } from 'app/services/avatar-shop.service';
import { SharedService } from 'app/core/shared/shared.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ShopItem, ShopItemType } from 'app/core/shared/state';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { ReportService } from 'app/services/report.service';
import { SchoolService } from 'app/services/school.service';
import { ShopService } from 'app/services/shop.service';
import * as moment from 'moment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { BlobResponse } from 'app/core/shared/state/models/blob-response-model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShopComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  currentUser;
  buying = false;
  initialMap;
  mytimeout = null;
  calling = false;
  summer = false;
  viewBg = true;
  viewComp = false;
  viewMsg = false;
  viewOthers = false;
  viewOld = false;
  viewSummer = false;
  viewPresents = false;
  showListUsers = false;
  dataLoaded = false;
  civilization = null;
  idCivilization = null;
  userHistoryStages = null;
  availableBack = [];
  availableComponents = [];
  availableItems = [];
  availableMesagges = [];
  availableOthers = [];
  availableSummer = [];
  hideCivilization = false;
  demoUser;
  regalar_item = null;
  regalar_userTo = null;
  regalar_password = "";
  error = null;
  specialItems = [];
  boughtItems = [];
  oldItems = [];
  allItems = [];
  regalar_userTo_bqd = null;
  regalar_password_error = null;
  regalar_mensaje = "";
  selectedStudent;
  listStudents;
  regalar_message = false;
  regalar_message_text = "";
  select_userTo_bqd = null;
  idShopItemCode
  rewardCode
  showCodePopUp
  showCodeError
  showCodeOk;
  showPassword1 = false;
  onInitLoad = true;
  fileUrl = "https://walinwa.blob.core.windows.net/images/Themes/"
  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private shopService: ShopService,
    private reportService: ReportService,
    private schoolService: SchoolService,
    public sharedService: SharedService,
    private avatarShopService: AvatarShopService,
    private _translateService: TranslateService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (!this.authService.check()) return;
    this.authService.LoadShopPresents(true);
    this.currentUser = this.authService.currentUser;
    this.demoUser = this.currentUser.UserName.includes(".tmp.");
    this.idCivilization = this.currentUser.Civilization.IdCivilization;
    this.civilization = this.currentUser.Civilization.IdShopItem;
    this.shopService.getAvailableItemsCiv(this.currentUser.Id, this.idCivilization)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successLoadAvailableItems(result);
      },
      error => {
        this.errorCallback();
      }
    )
    this.seachUser();

    
  }

  successLoadAvailableItems(result) {
    this.availableBack = [];
    this.availableComponents = [];
    this.availableMesagges = [];
    this.availableOthers = [];
    this.availableSummer = [];
    let today = new Date();
    for (let i = 0; i < result.length; i++) {
      if (result[i].IdCivilization == null && (new Date(result[i].specialTo2).getTime() <= today.getTime())) i++;
      if (result[i].Type === 1 || result[i].Type === 2) this.availableBack.push(result[i]);
      if (result[i].Type === 3 || result[i].Type === 5 || result[i].Type === 11) this.availableComponents.push(result[i]);
      if (result[i].Type === 4 || result[i].Type === 6) this.availableMesagges.push(result[i]);
      if (result[i].Type === 7 || result[i].Type === 8 || result[i].Type === 9 || result[i].Type === 10 || result[i].Type === 12) this.availableOthers.push(result[i]);
      if ((result[i].Type === 13 || result[i].Type === 14 || result[i].Type === 15)) this.availableOthers.push(result[i]);
    }
    this.availableBack = this.availableBack.sort(function (a, b) { return a.IdShopItem - b.IdShopItem; });
    this.availableComponents = this.availableComponents.sort(function (a, b) { return a.IdShopItem - b.IdShopItem; });
    this.availableMesagges = this.availableMesagges.sort(function (a, b) { return a.IdShopItem - b.IdShopItem; });

    this.availableOthers = this.availableOthers.sort(function (a, b) { return a.IdShopItem - b.IdShopItem; });
    this.availableSummer = this.availableSummer.sort(function (a, b) { return a.IdShopItem - b.IdShopItem; });
    if (this.authService.currentUser.AvatarDisabled == true) {
      this.availableOthers = this.availableOthers.filter((x) => x.Description != "Avatar");
    }
    let pos;
    this.availableOthers.forEach((x,i) => {
      if (x.Description == "Avatar") {
        pos = i;
      }
    })
    if (pos) {
    this.availableOthers = this.replacePosition(this.availableOthers,5,pos)
    }
    this.getBoughtItems();
  }

  desactiveAvatar(description){
    if (description != "Avatar" ) {
      return true;
    }else if (description == "Avatar" && this.authService.currentUser.AvatarDisabled == true) {
      return false;
    }else{
      return true;
    }

  }

  replacePosition(list, p1, p2) : any[]{
      let aux = list[p1];
      list[p1] = list[p2];
      list[p2] = aux;
      return list;
  }

  ya_regalado(item) {
    if (!item) return "";
    for (let i = 0; i < this.authService.currentUser.ShopPresents_enviados.length; i++) {
      if (this.authService.currentUser.ShopPresents_enviados[i].IdShopItem == item.IdShopItem) return true;
    }
    return false;
  }

  es_regalado(item) {
    if (!item) return "";
    for (let i = 0; i < this.authService.currentUser.ShopPresents_recibidos.length; i++) {
      if (this.authService.currentUser.ShopPresents_recibidos[i].IdShopItem == item.IdShopItem){
        return this.authService.currentUser.ShopPresents_recibidos[i].UserNameFrom
      } 
    }
    return "";
  }

  regalado_a(item) {
    if (!item) return "";
    for (var i = 0; i < this.authService.currentUser.ShopPresents_enviados.length; i++) {
      if (this.authService.currentUser.ShopPresents_enviados[i].IdShopItem == item.IdShopItem){
        return this.authService.currentUser.ShopPresents_enviados[i].UserNameTo
      }
    }
    return "";
  }

  cancelarRegalo() {
    this.regalar_item = null;
    this.regalar_userTo = null;
    this.regalar_password = "";
    this.error = null;
    this.showListUsers = false;
  }

  activateInitialMap() {
    this.activateMap(this.initialMap);
  }

  activateMap(idShopItem) {
    if (this.calling) return;
    this.calling = true;
    this.shopService.activateMap(this.authService.currentUser.Id, idShopItem)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successActivateMap(result);
      },
      error => {
        this.errorCallback();
      }
    )
  }

  errorCallback() {
    this.calling = false;
    this.navigationService.error([this._translateService.instant('STUDENT.SHOP.MODAL-ERROR-TITLE'),this._translateService.instant('STUDENT.SHOP.MODAL-ERROR-MESSAGE')], () => {});
  }

    downloadDiploma(shopItem: ShopItem) {
        if (this.calling) return;
        this.calling = true;
        let reportName: string;
        let pdfName: string;
        let id = shopItem.IdShopItem.toString()
        if (shopItem.Type == ShopItemType.diploma) {
            if (id.includes("97")) {
                pdfName = "Certificado medallas";
                reportName = shopItem.IdCivilization == 1 ? "diplomaMedalsHist" : "diplomaMedals"
            }
            else if (id.includes("98")) {
                pdfName = "Certificado walinwos";
                reportName = shopItem.IdCivilization == 1 ? "diplomaWaliHist" : "diplomaWali"
            }
            else if (id.includes("99")) {
                pdfName = "Diploma sesiones";
                reportName = shopItem.IdCivilization == 1 ? "diplomaSessHist" : "diplomaSess"
            }
            var parameters = [
                { idUser: this.authService.currentUser.Id }
            ];
            this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
                result => {
                    this.successDownload(result);
                },
                error => {
                    this.errorCallback();
                }
            )
        }
        else {
            this.calling = false;
        }
    }

  successDownload(data) {
    const blobResponse = data?.body as BlobResponse;

    this.calling = false;
    var link = document.createElement("a");
    link.setAttribute("href", URL.createObjectURL(blobResponse.file));
    link.setAttribute("target", "_blank");
    link.setAttribute("download", blobResponse.fileName);
    document.body.appendChild(link);
    link.click();
  }

  successActivateMap(result) {
    if (result == 'basic1') {
      this.calling = false;
      for (var i = 0; i < this.availableBack.length; i++) this.availableBack[i].Active = false;
      this.availableBack[0].Active = true;
      this.sharedService.changeUserCoins(true)
      this.getBoughtByIdUser();
    } else {
        this.sharedService.changeUserCoins(true)
      this.getBoughtByIdUser();
    }
  }

  getBoughtByIdUser() {
    this.shopService.getBoughtByIdUser(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successLoadBoughtItems(result);
      },
      error => {
        this.errorCallback();
      }
    )
  }

  successLoadBoughtItems(result) {
    this.calling = false;
    this.availableItems = [];
    this.oldItems = [];
    var today = new Date();
    for (var i = 0; i < result.length; i++) {
      if (result[i].ShopItem.IdCivilization != 9) {
        if (result[i].ShopItem.IdCivilization == this.idCivilization) {
          this.availableItems.push(result[i]);
        } else {
          if (result[i].ShopItem.Walinwos > 0 && result[i].ShopItem.Type !== 12 && result[i].ShopItem.Type !== 13)
            this.oldItems.push(result[i]);
        }
      } else {

        if (result[i].ShopItem.Type === 15 || result[i].ShopItem.Type === 12 || result[i].ShopItem.ShowShop === false || ((moment(result[i].ShopItem.specialFrom2).diff(moment(), 'days') <= 0) &&
          (moment(result[i].ShopItem.specialTo2).diff(moment(), 'days') >= 0))) {
          this.availableItems.push(result[i]);
          this.specialItems.push(result[i].ShopItem.idShopItem);
          if (result[i].ShopItem.ShowShop === false)
            this.oldItems.push(result[i]);
        } else {
          this.oldItems.push(result[i]);
        }

      }
    }
    this.boughtItems = this.availableItems;
    var status_fondo_gratis = true;


    if (this.boughtItems.length > 0) {
      for (var i = 0; i < this.boughtItems.length; i++) {
        if (this.boughtItems[i].Status) {
          status_fondo_gratis = false;
        }
      }
    }
    //AÑADIMOS EL FONDO GRATUITO
    if (this.availableBack[0] != null) {
      var aux = {
        IdUser: this.currentUser.Id,
        User: null,
        IdShopItem: this.availableBack[0].IdShopItem,
        ShopItem: this.availableBack[0],
        Status: status_fondo_gratis
      }
      this.boughtItems.push(aux);
    }
    if (this.viewOld == true) {
      if (this.oldItems.length == 0) {
        this.showBg();
      } else {
        this.oldItems = this.oldItems.sort(function (a, b) {
          return a.ShopItem.Order - b.ShopItem.Order
        });
      }
    }
    var basicItemBought = false;
    var basicItemActive = true;
    if (this.boughtItems != null) {
      for (var i = 0; i < this.boughtItems.length; i++) {
        for (var j = 0; j < this.availableBack.length; j++) {
          if (this.boughtItems[i].IdShopItem == this.availableBack[j].IdShopItem) {
            this.availableBack[j].Bougth = true;
            if (this.boughtItems[i].Status == 1)
              this.availableBack[j].Active = true;
            else
              this.availableBack[j].Active = false;

            if (j > 0 && this.availableBack[j].Active == true) {
              basicItemActive = false;
            }
          }
        }
        this.availableBack[0].Active = basicItemActive;
        for (var j = 0; j < this.availableComponents.length; j++) {
          if (this.boughtItems[i].IdShopItem == this.availableComponents[j].IdShopItem) {
            this.availableComponents[j].Bougth = true;
            if (this.boughtItems[i].Status == 1)
              this.availableComponents[j].Active = true;
            else
              this.availableComponents[j].Active = false;
          }
        }
        for (var j = 0; j < this.availableMesagges.length; j++) {
          if (this.boughtItems[i].IdShopItem == this.availableMesagges[j].IdShopItem) {
            this.availableMesagges[j].Bougth = true;
            if (this.boughtItems[i].Status == 1)
              this.availableMesagges[j].Active = true;
            else
              this.availableMesagges[j].Active = false;
          }
        }
        for (var j = 0; j < this.availableOthers.length; j++) {
          if(this.availableOthers[j] === undefined){
            continue;
          }
          if (this.availableOthers[j].Type === 12) {
            if (this.availableOthers[j].Bougth == null)
              this.availableOthers[j].Bougth = false;
            this.availableOthers[j].OnlyPresent = true;
            this.availableOthers[j].Active = false;
          }
          if (this.boughtItems[i].IdShopItem == this.availableOthers[j].IdShopItem) {
            this.availableOthers[j].Bougth = true;
            if (this.boughtItems[i].Status == 1)
              this.availableOthers[j].Active = true;
            else
              this.availableOthers[j].Active = false;
          }
        }
        for (var j = 0; j < this.availableSummer.length; j++) {
          if (this.boughtItems[i].IdShopItem === this.availableSummer[j].IdShopItem) {
            this.availableSummer[j].Bougth = true;
          }
        }
      }
    }
    // mytimeout = $timeout(this.onCheckscrolls, 100);
    this.juntar_objetos_regalo();
      this.dataLoaded = true;
      this.shopService.ChangeUserBoughtItems(this.availableItems);
  }

  juntar_objetos_regalo() {
    this.allItems = [];
    var i;
    for (i = 0; i < this.availableBack.length; i++) {
      if (this.getPrecioVenta(this.availableBack[i].Walinwos) > 0 && this.availableBack[i].specialFrom2 == null && this.availableBack[i].ShowShop == true)
        this.allItems.push(this.availableBack[i]);
    }
    for (i = 0; i < this.availableComponents.length; i++) {
      if (this.getPrecioVenta(this.availableComponents[i].Walinwos) > 0 && this.availableComponents[i].specialFrom2 == null)
        this.allItems.push(this.availableComponents[i]);
    }
    for (i = 0; i < this.availableMesagges.length; i++) {
      if (this.getPrecioVenta(this.availableMesagges[i].Walinwos) > 0 && this.availableMesagges[i].specialFrom2 == null)
        this.allItems.push(this.availableMesagges[i]);
    }
    for (i = 0; i < this.availableOthers.length; i++) {
      if (this.getPrecioVenta(this.availableOthers[i].Walinwos) > 0 && this.availableOthers[i].Type !== 13 && this.availableOthers[i].Type !== 14 && this.availableOthers[i].specialFrom2 == null)
        this.allItems.push(this.availableOthers[i]);
    }
  }

    getBoughtItems() {
        if (!this.onInitLoad) {
            console.log("entro")
            window.postMessage("reload-items", "*");
            this.sharedService.changeUserCoins(true)
        }
        this.onInitLoad = false;
        this.getBoughtByIdUser();
    }

  showBg() {
    this.viewBg = true;
    this.viewComp = false;
    this.viewOthers = false;
    this.viewMsg = false;
    this.viewOld = false;
    this.viewSummer = false;
    this.viewPresents = false;
    this.regalar_item = null;
    this.regalar_userTo = null;
    this.regalar_password = "";
    this.error = null;
  }

  showComp() {
    this.viewBg = false;
    this.viewComp = true;
    this.viewOthers = false;
    this.viewMsg = false;
    this.viewOld = false;
    this.viewSummer = false;
    this.viewPresents = false;
    this.regalar_item = null;
    this.regalar_userTo = null;
    this.regalar_password = "";
    this.error = null;
  }

  showMsgs() {
    this.viewBg = false;
    this.viewComp = false;
    this.viewOthers = false;
    this.viewMsg = true;
    this.viewOld = false;
    this.viewSummer = false;
    this.viewPresents = false;
    this.regalar_item = null;
    this.regalar_userTo = null;
    this.regalar_password = "";
    this.error = null;
  }

  showOthers() {
    this.viewBg = false;
    this.viewComp = false;
    this.viewMsg = false;
    this.viewOthers = true;
    this.viewOld = false;
    this.viewSummer = false;
    this.viewPresents = false;
    this.regalar_item = null;
    this.regalar_userTo = null;
    this.regalar_password = "";
    this.error = null;
  }

  showOld() {
    this.viewBg = false;
    this.viewComp = false;
    this.viewMsg = false;
    this.viewOthers = false;
    this.viewOld = true;
    this.viewSummer = false;
    this.viewPresents = false;
    this.regalar_item = null;
    this.regalar_userTo = null;
    this.regalar_password = "";
    this.error = null;
  }

  showPresents() {
    this.viewBg = false;
    this.viewComp = false;
    this.viewMsg = false;
    this.viewOthers = false;
    this.viewOld = false;
    this.viewSummer = false;
    this.viewPresents = true;
    this.regalar_item = null;
    this.regalar_userTo = null;
    this.regalar_password = "";
    this.error = null;
  }

  showSummer() {
    this.viewBg = false;
    this.viewComp = false;
    this.viewMsg = false;
    this.viewOthers = false;
    this.viewOld = false;
    this.viewSummer = true;
    this.viewPresents = false;
    this.regalar_item = null;
    this.regalar_userTo = null;
    this.regalar_password = "";
    this.error = null;
  }

  selectBg(index) {
    for (var j = 0; j < this.availableBack.length; j++) this.availableBack[j].Selected = false;
    this.availableBack[index].Selected = true;
  }

  selectComp(index) {
    for (var j = 0; j < this.availableComponents.length; j++) this.availableComponents[j].Selected = false;
    this.availableComponents[index].Selected = true;
  }

  selectMsg(index) {
    for (var j = 0; j < this.availableMesagges.length; j++) this.availableMesagges[j].Selected = false;
    this.availableMesagges[index].Selected = true;
  }

  selectOther(index) {
    for (var j = 0; j < this.availableOthers.length; j++) this.availableOthers[j].Selected = false;
    this.availableOthers[index].Selected = true;
  }

  selectItem(index) {
    for (var j = 0; j < this.oldItems.length; j++) this.oldItems[j].ShopItem.Selected = false;
    this.oldItems[index].ShopItem.Selected = true;
  }

  selectPresent(index) {
    for (var j = 0; j < this.allItems.length; j++) this.allItems[j].Selected = false;
    this.allItems[index].Selected = true;
  }

  selectSummer(index) {
    for (var j = 0; j < this.availableSummer.length; j++) this.availableSummer[j].Selected = false;
    this.availableSummer[index].Selected = true;
  }

  getPrecioVenta(walinwos) {
    return walinwos * 0.8;
  }

  vender(item) {
    if (this.calling) return;
    this.calling = true;
    this.shopService.sell(item.IdShopItem, this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.sellSuccessfull();
      },
      error => {
        this.errorCallback();
      }
    )
  }

  sellSuccessfull() {
    this.shopService.getAvailableItemsCiv(this.authService.currentUser.Id, this.idCivilization)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successLoadAvailableItems(result);
      },
      error => {
        this.errorCallback();
      }
    )
  }

  regalar_click(item) {
    if (this.authService.currentUser.Coins < item.Walinwos || this.ya_regalado(item) || this.calling == true) return;
    this.seachUser();
    this.regalar_item = item;
  }

  selectUser() {
    if (this.selectedStudent) {
      this.showListUsers = false;
      this.regalar_userTo_bqd = this.selectedStudent.UserName;
      this.checkRegalar();
    }
  }

  seachUser() {

    if (this.authService.currentUser.IdSchool != null) {
      if (this.listStudents == null) {
        this.schoolService.getStudentsSchool(this.authService.currentUser.IdSchool)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
          result => {
            this.successCallbackGetAllStudents(result);
          },
          error => {
            this.errorCallback()
          }
        )
      } else {
        this.regalar_message = false;
        this.regalar_message_text = "";
        this.select_userTo_bqd = null;
        this.regalar_userTo_bqd = null;
        this.showListUsers = true;
      }
    }else{
      if (this.listStudents == null) {
        this.schoolService.getStudentsTutor(this.authService.currentUser.IdUserTutor)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
          result => {
            this.successCallbackGetAllStudents(result);
          },
          error => {
            this.errorCallback()
          }
        )
      } else {
        this.regalar_message = false;
        this.regalar_message_text = "";
        this.select_userTo_bqd = null;
        this.regalar_userTo_bqd = null;
        this.showListUsers = true;
      }
    }
    
    
  }

  continueRegalarMessage() {
    this.regalar_message = true;
  }

  successCallbackGetAllStudents(result) {
    this.regalar_message = false;
    this.regalar_message_text = "";
    this.listStudents = result.sort(this.SortArray);
    this.regalar_userTo_bqd = null;
    this.select_userTo_bqd = null;
    this.showListUsers = true;
    if (this.authService.currentUser.IdSchool == null) {
      let today = moment(new Date()).format("DD/MM/YYYY")
      this.listStudents = this.listStudents.filter(x => x.LicenseStatus != null);
      this.listStudents = this.listStudents.filter(x => x.Id != this.currentUser.Id && moment(x.LicenseStatus).format("DD/MM/YYYY")  >= today);
    }
  }

  SortArray(x, y){
    return x.Name.localeCompare(y.Name);
  }

  cancelRegalar() {
    this.regalar_item = null;
    this.regalar_userTo = null;
    this.regalar_password = "";
    this.regalar_message = false;
    this.regalar_message_text = "";
    this.error = null;
    this.regalar_userTo_bqd = null;
  }

  checkRegalar() {

    if (this.selectedStudent) {
      this.regalar_userTo_bqd = this.selectedStudent.UserName;
    }

    this.shopService.checkRegalar(this.regalar_item.IdShopItem, this.authService.currentUser.Id, this.regalar_userTo_bqd)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.error = false;
        this.regalar_userTo = result;

        var tipo_elemento = "";

        if (this.regalar_item.Type == 1 || this.regalar_item.Type == 2)
          tipo_elemento = this._translateService.instant('STUDENT.SHOP.BACKGROUND');
        if (this.regalar_item.Type == 3 || this.regalar_item.Type == 5 || this.regalar_item.Type == 11)
          tipo_elemento = this._translateService.instant('STUDENT.SHOP.COMPONENT');
        if (this.regalar_item.Type == 4 || this.regalar_item.Type == 6)
          tipo_elemento = this._translateService.instant('STUDENT.SHOP.MESSAGE');
        if (this.regalar_item.Type == 7 || this.regalar_item.Type == 8 || this.regalar_item.Type == 9 || this.regalar_item.Type == 10)
          tipo_elemento = "";

        var userTo = this.regalar_userTo.Alias;

        this.regalar_mensaje = this._translateService.instant('STUDENT.SHOP.GIFT-MESSAGE', { tipo_elemento, Description: this.regalar_item.Description, userTo, Walinwos: this.regalar_item.Walinwos})
        if(this.authService.currentUser.BlockMessageGift){
            this.continueRegalarMessage();
        }
      },
      error => {


        if (!error.error) return;
        //Errores
        //1 -> El usuario no existe (NO VA A PASAR)
        //2 -> El usuario no es de ese colegio (NO VA A PASAR)
        //3 -> El usuario no esta en la misma civilizacion (NO VA A PASAR)
        //4 -> Ya tiene el objeto
        //5 -> No tiene dinero
        var idCiv = error.error.civilizacion;
        var civ = "";

        switch (idCiv) {
          case 1:
            civ = this._translateService.instant('STUDENT.SHOP.PREHISTORY');
            break;
          case 2:
            civ = this._translateService.instant('STUDENT.SHOP.EGYPT');
            break;
          case 3:
            civ = this._translateService.instant('STUDENT.SHOP.ROME');
            break;
          case 4:
            civ = this._translateService.instant('STUDENT.SHOP.MAYANS');
            break;
          case 5:
            civ = this._translateService.instant('STUDENT.SHOP.CHINA');
            break;
          case 6:
            civ = this._translateService.instant('STUDENT.SHOP.ARABIAN');
            break;
        }


        switch (error.error.error) {
          case 1:
            this.error = this._translateService.instant('AUTHENTICATION.ERROR.USER') + ".";
            break;
          case 2:
            this.error = this._translateService.instant('STUDENT.SHOP.ERROR-USER-NOT-IN-SCHOOL');
            break;
          case 3:
            this.error = this.regalar_userTo_bqd + this._translateService.instant('STUDENT.SHOP.ERROR-USER-DIFFERENT-CIV', {civ});
            break;
          case 4:
            this.error = this._translateService.instant('STUDENT.AVATAR-SHOP.USER-ALREADY-HAS-ITEM-ERROR');
            break;
          case 5:
            this.error = this._translateService.instant('STUDENT.NOT-ENOUGH-WALIS-MESSAGE');
            break;
        }

        var message = [];
        message[0] = this._translateService.instant('STUDENT.AVATAR-SHOP.GIFT-ERROR');
        message[1] = this.error;
        this.navigationService.error(message, () => {
          this.regalar_item = null;
          this.regalar_userTo = null;
          this.regalar_password = "";
          this.error = null;
          this.regalar_userTo_bqd = null;
        },true)
      });
  }

  regalar() {
    this.regalar_password_error = null;
    if (this.regalar_password == "") {
      //No ha escrito la clave
      this.regalar_password_error = this._translateService.instant('STUDENT.AVATAR-SHOP.GIFT-BLANK-FIELD-ERROR');
    } else {
      this.authService.checkLogin(this.authService.currentUser.UserName, this.regalar_password)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.shopService.regalar(this.regalar_item.IdShopItem, this.authService.currentUser.Id, this.regalar_userTo.Id, this.regalar_message_text)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
            result => {
              var message = [];
                message[0] = this._translateService.instant('STUDENT.AVATAR-SHOP.GIFT-CORRECT-TITLE');
                message[1] = this._translateService.instant('STUDENT.AVATAR-SHOP.GIFT-CORRECT-MESSAGE2');
              this.navigationService.error(message, () => {
                this.authService.reloadUser();
                this.regalar_item = null;
                this.regalar_userTo = null;
                this.regalar_message = false;
                this.regalar_message_text = "";
                this.regalar_password = "";
                this.error = null;
                  this.regalar_userTo_bqd = null;
                  
              },true)
            },
            error => {

            }
          )
        },
        error => {
          this.regalar_password_error = this._translateService.instant('PASSWORD-WRONG');
        }
      )
    }
  }

  buyBg(index) {
    if (this.calling) return;
    if (this.availableBack[index].Walinwos > this.authService.currentUser.Coins) return;
    this.calling = true;
    this.shopService.setShopItemToUserShop(this.currentUser.Id, this.availableBack[index].IdShopItem)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.getBoughtItems();
      },
      error => {
        this.errorCallback();
      }
    )
  }

  buyComp(index) {
    if (this.calling) return;
    if (this.availableComponents[index].Walinwos > this.authService.currentUser.Coins) return;
    this.calling = true;
    this.shopService.setShopItemToUserShop(this.currentUser.Id, this.availableComponents[index].IdShopItem)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.getBoughtItems();
      },
      error => {
        this.errorCallback();
      }
    )
  }

  buyMsg(index) {
    if (this.calling) return;
    if (this.availableMesagges[index].Walinwos > this.authService.currentUser.Coins) return;
    this.calling = true;
    this.shopService.setShopItemToUserShop(this.currentUser.Id, this.availableMesagges[index].IdShopItem)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.getBoughtItems();
      },
      error => {
        this.errorCallback();
      }
    )
  }

  buyOther(index) {
    if (this.calling) return;
    if (this.availableOthers[index].Walinwos > this.authService.currentUser.Coins) return;
    this.calling = true;
    this.shopService.setShopItemToUserShop(this.currentUser.Id, this.availableOthers[index].IdShopItem)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
    result => {
        if (this.availableOthers[index].IdShopItem == 922) {
            window.postMessage("avatar-active", "*");
            this.avatarShopService.setAvatarStatus(true);
        }
        this.getBoughtItems();
      },
      error => {
        this.errorCallback();
      }
    )
  }

  buySummer(index) {
    if (this.calling) return;
    if (this.availableSummer[index].Walinwos > this.authService.currentUser.Coins) return;
    this.calling = true;
    this.shopService.setShopItemToUserShop(this.currentUser.Id, this.availableSummer[index].IdShopItem)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.getBoughtItems();
      },
      error => {
        this.errorCallback();
      }
    )
  }

  activateBg(index) {
    this.activateMap(this.availableBack[index].IdShopItem);
  }

  activateComp(index) {
    if (this.calling) return;
    this.calling = true;
    this.shopService.activateComponent(this.currentUser.Id, this.availableComponents[index].IdShopItem)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.getBoughtItems();
      },
      error => {
        this.errorCallback();
      }
    )
  }

  deactivateComp(index) {
    if (this.calling) return;
    this.calling = true;
    this.shopService.deactivateComponent(this.currentUser.Id, this.availableComponents[index].IdShopItem)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.getBoughtItems();
      },
      error => {
        this.errorCallback();
      }
    )
  }

  activateMsg(index) {
    if (this.calling) return;
    this.calling = true;
    this.shopService.activateMessage(this.currentUser.Id, this.availableMesagges[index].IdShopItem)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.getBoughtItems();
      },
      error => {
        this.errorCallback();
      }
    )
  }

  deactivateMsg(index) {
    if (this.calling) return;
    this.calling = true;
    this.shopService.deactivateMessage(this.currentUser.Id, this.availableMesagges[index].IdShopItem)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.getBoughtItems();
      },
      error => {
        this.errorCallback();
      }
    )
  }

  activateOther = function (index) {
    if (this.calling) return;
    this.calling = true;
    this.shopService.activateOther(this.currentUser.Id, this.availableOthers[index].IdShopItem)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        if(this.availableOthers[index].IdShopItem == 922) {
            window.postMessage("avatar-active", "*");
            this.avatarShopService.setAvatarStatus(true);
            
            this.navigationService.go('/student/session');
        }
        window.postMessage("reload-items", "*");
        this.getBoughtItems();
      },
      error => {
        this.errorCallback();
      }
    )
  }

  deactivateOther = function (index) {
    if (this.calling) return;
    this.calling = true;
    this.shopService.deactivateOther(this.currentUser.Id, this.availableOthers[index].IdShopItem)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
        if (this.availableOthers[index].IdShopItem == 922) {
                this.avatarShopService.setAvatarStatus(false);
            }
            window.postMessage("reload-items", "*");
            this.getBoughtItems();
      },
      error => {
        this.errorCallback();
      }
    )
  }
    hasNoWalinwos(item: any) {
        if (this.authService.currentUser.Coins > item.Walinwos) {
            return false
        }
        return true;
    }
  getImgBackUrl(index) {
    if (this.availableBack[index] == null) return;
    if (this.availableBack[index].IdCivilization != 9) {
      var url = this.fileUrl + this.currentUser.Civilization.ImgFolder + "/";
      if (this.availableBack[index].Type == 1)
        url += "shop/det_" + this.availableBack[index].IdShopItem + ".png";
      else
        url += "premium/det_Background.png";
      return url;
    }
    return this.fileUrl + "Special/det_" + this.availableBack[index].IdShopItem + ".png";
  }

  getImgCompUrl(index) {
    if (this.availableComponents[index] == null) return;
    if (this.availableComponents[index].IdCivilization != 9) {
      var url = this.fileUrl + this.currentUser.Civilization.ImgFolder + "/";
      url += "shop/det_" + this.availableComponents[index].IdShopItem + ".png";
      return url;
    }
    return this.fileUrl + "Special/det_" + this.availableComponents[index].IdShopItem + ".png";
  }

  getImgMsgUrl(index) {
    if (this.availableMesagges[index] == null) return;
    if (this.availableMesagges[index].IdCivilization != 9) {
      var url = this.fileUrl + this.currentUser.Civilization.ImgFolder + "/";
      url += "shop/det_" + this.availableMesagges[index].IdShopItem + ".png";
      return url;
    }
    return this.fileUrl + "Special/det_" + this.availableMesagges[index].IdShopItem + ".png";
  }

  getImgOtherUrl(index) {
    if (this.availableOthers[index] == null) return;
    let IdShopItem = this.availableOthers[index].IdShopItem;
    if(IdShopItem == 922){
        return this.fileUrl + IdShopItem + ".gif";
    }
    if (this.availableOthers[index].IdCivilization != 9) {
      var url = this.fileUrl + this.currentUser.Civilization.ImgFolder + "/";
      url += "shop/det_" + IdShopItem + ".png";
      IdShopItem += ""
      if(IdShopItem.includes("91")){
        url = this.fileUrl + "det_091.png"
      }
      return url;
    }
    return this.fileUrl + "Special/det_" + IdShopItem + ".png";
  }

  getImgSummerUrl(index) {
    if (this.availableSummer[index] == null) return;
    if (this.availableSummer[index].IdCivilization != 9) {
      var url = this.fileUrl + this.currentUser.Civilization.ImgFolder + "/";
      url += "shop/det_" + this.availableSummer[index].IdShopItem + ".png";
      return url;
    }
    return this.fileUrl + "Special/det_" + this.availableSummer[index].IdShopItem + ".png";
  }

  getImgItemUrl(index) {
    if (this.oldItems[index] == null) return;
    if (this.oldItems[index].ShopItem.IdCivilization != 9) {
      var url = this.fileUrl + this.oldItems[index].ShopItem.Civilization.ImgFolder + "/";
      url += "shop/det_" + this.oldItems[index].ShopItem.IdShopItem + ".png";
      return url;
    }
    return this.fileUrl + "Special/det_" + this.oldItems[index].ShopItem.IdShopItem + ".png";
  }

  getImgRewardUrl() {
    return this.fileUrl + "Special/det_" + this.idShopItemCode + ".png";
  }

  getImgPresentUrl(index) {
    var r1, r2, r3;
    if (this.allItems[index] == null) {
      r1 = "";
      r2 = "";
      r3 = "";
    } else {
      if (this.allItems[index].IdCivilization != 9) {
        var url = "";
        url = this.fileUrl + this.currentUser.Civilization.ImgFolder + "/";
        url += "shop/det_" + this.allItems[index].IdShopItem;
        r1 = url + ".png";
        r2 = url + ".jpg";
      } else {
        r1 = this.fileUrl + "Special/det_" + this.allItems[index].IdShopItem + ".png";
        r2 = this.fileUrl + "Special/det_" + this.allItems[index].IdShopItem + ".jpg";
      }
    }
    if (this.allItems[index].Type == 2) {
      r3 = this.fileUrl + this.currentUser.Civilization.ImgFolder + "/mañana/det_Background.png";
    }
    if(this.allItems[index].IdShopItem == 922){
        r1 = this.fileUrl + this.allItems[index].IdShopItem + ".gif";
    }
    return ' url(' + r1 + '), url(' + r2 + '), url(' + r3 + ')';
  }

  insertCode() {
    this.rewardCode = null;
    this.showCodePopUp = true;
    this.showCodeError = false;
    this.showCodeOk = false;
  }

}
