@if (imagesLoaded) {
    @if (showDiploma) {
        <div class="new_cartel diploma" [ngClass]="plankClass" [style]="plankBackground">
            <div class="head">
                <img (click)="close()" class="close_button" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" />
                <span class="sup_title">{{ title }}</span>
                <span class="separator">-</span>
                <span class="sup_nota">{{'COMPONENTS.PLANK.EXERCISE-GRADE' | translate }} {{ mark }}</span>
            </div>
            <div class="desc">
                <div class="insignia">
                    <img [src]="getDiplomaImage()" alt="" [ngClass]="{'diploma_bienvenida' : (this.diplomaImageUrl || '').includes('bienvenida')}">
                </div>
                <div class="notas spe_gap" fxLayout="column" fxLayoutAlign="start center">
                    <div class="c_m_top" *ngIf="messages.length == 0">{{'COMPONENTS.PLANK.EXERCISE-GRADE' | translate }} {{ mark }}</div>
                    <div class="c_m_bottom spe_gap" fxLayout="column" fxLayoutAlign="start center">
                        <ng-container *ngFor="let message of messages; let i = index">
                            <span>{{ message }}</span>
                        </ng-container>
                    </div>
                    <div *ngIf="newScenary || newDictReward">
                        <button class="new_but azul" (click)="close()">
                            {{'ACCEPT' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    } @else {
        <div class="new_cartel nodiploma" [ngClass]="plankClass" [style]="plankBackground">
            <div class="head">
                <img class="close_button" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" (click)="close()" />
                <span class="sup_title">{{ title }}</span>
                <span class="separator">-</span>
                <span class="sup_nota" *ngIf="!(isFinAnticipado || mark == -1)">{{'COMPONENTS.PLANK.EXERCISE-GRADE' | translate }} {{ mark }}</span>
            </div>
            <div class="desc">
                <div class="notas no_avatar spe_gap" fxLayout="column" fxLayoutAlign="start center"
                    *ngIf="!isFinAnticipado && !newScenary && !newDictReward">
                    <div class="c_m_top noaprobado" *ngIf="mark < 5 && messages.length == 0">{{'COMPONENTS.PLANK.NOT-PASSED-TEXT' | translate }}</div>
                    <div class="c_m_top conerrores mayoruno" *ngIf="errors > 1 && messages.length == 0">{{'COMPONENTS.PLANK.ERRORS-COUNT' | translate:{errors:errors} }}</div>
                    <div class="c_m_top conerrores uno" *ngIf="errors == 1 && messages.length == 0">{{'COMPONENTS.PLANK.SINGLE-ERROR' | translate:{errors:errors} }}</div>
                    <div class="c_m_top generalmessage" *ngFor="let message of messages; let i = index">{{ message }}</div>
                    <div class="c_m_bottom spe_gap" fxLayout="row" fxLayoutAlign="center center"
                        [hidden]="walinwos == 0 || !isInArray('Has sido el único que ha finalizado el reto.')">
                        <span class="">{{'COMPONENTS.PLANK.WALIS-RECOVERED' | translate:{walinwos:walinwos} }}</span>
                    </div>
                    <div class="c_m_bottom spe_gap" fxLayout="row" fxLayoutAlign="center center"
                        [hidden]="walinwos == 0 || !isInArray('STUDENT.CHALLENGE-FIRST-POSITION' | translate)">
                        
                        <span class="">{{'COMPONENTS.PLANK.WALIS-WON' | translate:{walinwos:walinwos} }}</span>
                    </div>
                    <div class="c_m_bottom spe_gap" fxLayout="row" fxLayoutAlign="center center"
                        [hidden]="walinwos == 0 || title == '¡Has finalizado el reto!'">
                        <span class="">{{'COMPONENTS.PLANK.WALIS-OBTAINED' | translate:{walinwos:walinwos} }}</span>
                    </div>
                    <div class="c_m_bottom spe_gap" fxLayout="row" fxLayoutAlign="center center"
                        [hidden]="walinwos > 0 || isInArray('Permanece atento al listado en la zona de retos para ver en qué posición has quedado.')">
                        
                        <span class="">{{'COMPONENTS.PLANK.NO-WALIS-WON' | translate }}</span>
                    </div>
                </div>
                <div class="notas with_avatar spe_gap" fxLayout="row" fxLayoutAlign="space-between center"
                    *ngIf="!isFinAnticipado && (newScenary || newDictReward)">
                    <div fxFlex="30">
                        <img class="walinwin-img" *ngIf="newScenary || (newDictReward && AddedAvatar)" src="https://walinwa.blob.core.windows.net/images/pose2.svg?ngsw-bypass=true" />
                        <avatar-file class="avatar-file" *ngIf="
                                    newDictReward &&
                                    !AddedAvatar &&
                                    activeItems &&
                                    activeItems.length > 0
                                " [activeItems]="activeItems"></avatar-file>
                    </div>
                    <div fxFlex="">
                        <div class="c_m_top noaprobado" *ngIf="mark < 5 && messages.length == 0">{{'COMPONENTS.PLANK.NOT-PASSED-TEXT' | translate }}</div>
                        <div class="c_m_top conerrores mayoruno" *ngIf="!newScenary && !newDictReward && errors > 1 && messages.length == 0">{{'COMPONENTS.PLANK.ERRORS-COUNT' | translate:{errors:errors} }}</div>
                        <div class="c_m_top conerrores uno" *ngIf="!newScenary && !newDictReward && errors == 1 && messages.length == 0">{{'COMPONENTS.PLANK.SINGLE-ERROR' | translate:{errors:errors} }}</div>
                        <div class="c_m_top generalmessage" *ngFor="let message of messages; let i = index">{{ message }}</div>
                        <div class="c_m_bottom" [hidden]="walinwos == 0">
                            <span class="" *ngIf="!isInArray('has sido premiado')">{{'COMPONENTS.PLANK.WALIS-OBTAINED' | translate:{walinwos:walinwos} }}</span>
                        </div>
                        <div class="c_m_bottom newReward" *ngIf="newScenary" [hidden]="
                        walinwos == 0 || title == '¡Has finalizado el reto!'">
                            <span class="">{{'COMPONENTS.PLANK.NEW-SCENARIO-UNLOCKED' | translate }}</span>
                        </div>
                        <div class="c_m_bottom newReward" *ngIf="newDictReward && AddedAvatar">
                            <span class=""><br />Además, gracias a tu esfuerzo </span>
                            <span class="">{{'COMPONENTS.PLANK.NEW-AVATAR-UNLOCKED' | translate }}</span>
                        </div>
                        <div class="c_m_bottom newReward" *ngIf="newDictReward && !AddedAvatar">
                            <span class=""><br />Además, gracias a tu esfuerzo </span>
                            <span class="">{{'COMPONENTS.PLANK.NEW-ACCESSORY-UNLOCKED' | translate }}</span>
                        </div>
                    </div>
                </div>
                <div class="notas finish_anticipado spe_gap" fxLayout="column" fxLayoutAlign="start center"
                    *ngIf="isFinAnticipado">
                    <div class="c_m_top">{{'COMPONENTS.PLANK.NOT-PASSED-TEXT' | translate }}</div>
                    <div class="c_m_bottom" [innerHTML]="'COMPONENTS.PLANK.TOO-MANY-SKIPS-OR-ERRORS' | translate "></div>
                </div>
            </div>
        </div>
    }
}
<div class="ww_back" [ngClass]="show == true ? 'active' : ''"></div>