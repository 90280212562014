import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'app/services/course.service';
import { UserService } from 'app/services/user.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from 'app/services/navigation.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-auth-parent-student',
  templateUrl: './auth-parent-student.component.html',
  styleUrls: ['./auth-parent-student.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthParentStudentComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  dataLoaded = false;
  currentUser;
  student;
  email;
  authUsers;
  selectedAuthUser;
  correoConfirmacion = false;
  calling;
  error;
  errorDescription;
  selectedIdUser;
  selectedIdClass;

  constructor(
    private activateRoter: ActivatedRoute,
    private userService: UserService,
    private _formBuilder: UntypedFormBuilder,
    private _matDialog: MatDialog,
    private router: Router,
    private courseService: CourseService,
    private _translateService: TranslateService,
    private navigationService: NavigationService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy(): void
   {
       // Unsubscribe from all subscriptions
       this._unsubscribeAll.next();
       this._unsubscribeAll.complete();
   }

  ngOnInit() {
    if (!sessionStorage.getItem("currentUser")) return this.navigationService.exit();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (!this.currentUser) return this.navigationService.exit();
    if (this.navigationService.params.IdUser) this.selectedIdUser = this.navigationService.params.IdUser;
    if (this.navigationService.params.IdClass) this.selectedIdClass = this.navigationService.params.IdClass;
    this.navigationService.enableBackButton(true, this.back.bind(this));
    this.userService.getUserById(this.activateRoter.params['value'].IdUser)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetStudent.bind(this), this.errorCallback.bind(this))
  }

  back() {
    this.navigationService.go("/school/parentStudents", {
      IdUser: this.selectedIdUser,
      IdClass: this.selectedIdClass
    })
  }

  successGetStudent(response) {
    this.student = response;
    this.userService.getAuthorizedUsers(this.student.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetAuthUsers.bind(this), this.errorCallback.bind(this));
  }

  successGetAuthUsers(response) {
    if (response == null) return this.navigationService.exit();
    this.authUsers = response;
    if (this.authUsers.length > 0) this.selectAuthUser(this.authUsers[0]);
    this.email = '';
    this.dataLoaded = true;
  }

  selectAuthUser(authUser) {
    this.selectedAuthUser = authUser;
  }

  errorCallback(error) {
    this.navigationService.exit();
  }

  addAuthUser() {
    if (this.calling)
      return;

    if (this.email == null || this.email == '') {
      this.error = true;
      this.errorDescription = this._translateService.instant('SCHOOL.EMAIL-EMPTY');
    } else if (this.validateEmail(this.email) == false) {
      this.error = true;
      this.errorDescription = this._translateService.instant('EMAIL-INVALID');
    } else {
      this.calling = true;
      this.userService.addAuthorizedUser(this.student.Id, this.currentUser.Id, this.email, this.correoConfirmacion)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successAuthUser.bind(this), this.errorCallbackAddUser.bind(this));
    }
  }

  delete() {

    if (this.calling) return;

    var message = [];
    message[0] = this._translateService.instant('SCHOOL.ATTENTION');
    message[1] = this._translateService.instant('SCHOOL.MODAL-DELETE-AUTH-TEACHER-MESSAGE');

    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.message2 = message[2];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.theme = "white";
    confirmDialog.componentInstance.options = [{
      text: this._translateService.instant('ACCEPT'),
      callback: () => {
        this.calling = true;
        this.userService.removeAuthorizedUser(this.student.Id, this.currentUser.Id, this.selectedAuthUser)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successAuthUser.bind(this), this.errorCallback.bind(this));
      }
    }, {
      text: this._translateService.instant('CANCEL'),
      callback: () => { }
    }];
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      result();
      confirmDialog = null;
    });

  }

  successAuthUser(response) {
    this.calling = false;
    this.userService.getAuthorizedUsers(this.student.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetAuthUsers.bind(this), this.errorCallback.bind(this));
  }

  errorCallbackAddUser(response) {
    if (response != null && response == "UserNotFound")
      this.errorDescription = this._translateService.instant('EMAIL-INVALID');
    if (response != null && response.error == "SameUser")
      this.errorDescription = this._translateService.instant('SCHOOL.ERROR-AUTH-TEACHER-USER-ALREADY-AUTH');
    else
      this.errorDescription = this._translateService.instant('EMAIL-INVALID');
    this.calling = false;
    this.error = true;
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


}
