import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentService } from 'app/services/payment.service';
import { SchoolService } from 'app/services/school.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationService } from 'app/services/navigation.service';
import 'moment/locale/es'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { SharedService } from 'app/core/shared/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-payment',
  templateUrl: './admin-payment.component.html',
  styleUrls: ['./admin-payment.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
  encapsulation: ViewEncapsulation.None,
})

export class AdminPaymentComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  navigationSubscription;
  currentUser;
  state;
  america;
  school;
  calling;
  numStudents;
  maxDate;
  tpvInfo;
  minDateCal;
  paymentType;
  duration;
  IVA;
  iniDate;
  newSchoolForm : UntypedFormGroup;
  iniDateFormated = moment().format();
  amount;
  refNum;
  justTpv = false;

  constructor(
    private router: Router,
    private schoolService: SchoolService,
    private paymentService: PaymentService,
    private _matDialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private navigationService: NavigationService,
    private adapter: DateAdapter<any>,
    private sharedService: SharedService,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    moment.locale('es')
    this.adapter.setLocale('es')
  }
  
  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    this.init();
    this.navigationService.enableBackButton(true, this.volver.bind(this));
  }

  goLogin() {
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('adminLogin');
    sessionStorage.removeItem("state");
    sessionStorage.removeItem("state_params");
    sessionStorage.setItem("isLogged", "false");
    this.router.navigateByUrl("login");
  }

  init() {

    if (!sessionStorage.getItem("currentUser")) {
      this.goLogin();
      return;
    }

    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.state = 'schoolData';

    this.america = false;
    this.minDateCal = new Date();
    this.paymentType = 'card';
    this.numStudents = 20;
    this.duration = "3";
    this.IVA;

    if (this.schoolService.school !== null) {
      this.school = this.schoolService.school;
      this.america = this.americanCountry(this.school.Country);
      this.paymentService.getIVA()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.IVA = result;
        },
        error => {
          this.goLogin();
        }
      );
    } else {
      this.goLogin();
      return;
    }

    this.newSchoolForm = this._formBuilder.group({
      Name: [this.school.Name, [Validators.required]],
      Street: [this.school.Street, [Validators.required]],
      Country: [this.school.Country, [Validators.required]],
      State: [this.school.State, [Validators.required]],
      Responsible: [this.school.Responsible],
      numStudents: [this.numStudents, [Validators.required, Validators.min(20)]],
      duration: [this.school.duration, [Validators.required]],
      Company: [this.school.Company],
      Cif: [this.school.Cif, [Validators.required]],
      Email: [this.school.Email],
      iniDateFormated: [moment(this.school.iniDateFormated), [Validators.required]]
    });
    if (this.school.Testing !== true) this.state = 'noTesting';

    if (this.school.Payment !== null) {
        this.state = 'tpv';
      this.justTpv = true;
      this.paymentService.getPaymentSchool(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successPay.bind(this), this.errorCallback.bind(this));
    }
  }

  volver(cerrar = false) {
    if(cerrar) {
      this.sharedService.changeRunGetSchool(true);
      this._udpateSchoolDataInService();

      return;
    }
    if (this.state === 'schoolData' || this.state === 'noTesting') {
      this._udpateSchoolDataInService();
      
      return 
    } else if (this.state == 'paymentType') {
      this.state = 'schoolData';
    } else if (this.state == 'paymentBank') {
      this.state = 'paymentType';
    } else if (this.state == 'tpv') {
      this.state = 'paymentType';
    }
  }

  private _udpateSchoolDataInService(): void {
    this.schoolService.getSchoolByUser(this.currentUser.Id).pipe(takeUntil(this._unsubscribeAll)).subscribe(school => {
      this.schoolService.school = school;
      this.sharedService.changeMaxNumStudents(this.schoolService.school.MaxNumStudents);
      this.sharedService.changeNumStudents(this.schoolService.school.NumStudents);
      this.sharedService.changeSchoolValidUntil(this.schoolService.school.ValidUntil);
      this.router.navigateByUrl("/school/admin");
    });
  }

  americanCountry(country) {
    var listCountries = ["AG", "AN", "AR", "AW", "BB", "BZ", "BM", "BO", "BR", "CA", "CL", "CO", "CR", "CU", "DM", "EC", "SV", "US", "GD", "GT", "GY", "GF", "HT", "HN", "VI", "JM", "MX", "NI", "PA", "PE", "PR", "DO", "RE", "VC", "SH", "LC", "ST", "UY", "VE"];
    if(listCountries.includes(country))
      return true;
    return false;
  }

  changeCountry() {
    this.school.Country = this.newSchoolForm.value.Country;
    this.america = this.americanCountry(this.school.Country);
  }

  back() {
    if (this.state === 'schoolData' || this.state === 'noTesting' || this.state === 'tpv') {
      this.router.navigateByUrl("/school/admin");
    } else if (this.state === 'paymentType') {
      this.state = 'schoolData';
    } else if (this.state === 'paymentBank') {
      this.state = 'paymentType';
    }


  }

  finish() {
    this.calling = true;
    this.school.MaxNumStudents = this.numStudents;
    this.school.Testing = false;
    this.school.ValidUntilReq = this.maxDate;
    this.schoolService.updateSchool(this.school)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.succesUpdateSchool.bind(this), this.errorCallback.bind(this));

  }

  pay() {
    if (this.calling)
      return;
    this.calling = true;
    this.schoolService.updateSchool(this.school)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.succesUpdateSchoolPay.bind(this), this.errorCallback.bind(this));

  }

  updateNumStudents() {
    this.numStudents = this.newSchoolForm.value.numStudents
  }

  succesUpdateSchoolPay(response) {
    this.paymentService.paySchool(this.currentUser.Id, this.numStudents, this.maxDate, this.iniDate, this.currentUser.IdSchool)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successPay.bind(this), this.errorCallback.bind(this));
  }

  successPay(response) {
    this.calling = false;
    if (response == "") {
      this.router.navigateByUrl("/school/admin");
    } else {
      this.loadTPV(response);
      // this.state = 'paymentType'; //OJO
    }
  }
  loadTPV(response) {
    this.tpvInfo = response;
    this.state = 'tpv';
  }
  succesUpdateSchool(response) {
    this.router.navigateByUrl("/school/admin");
  }

  errorCallback(response) {
    this.calling = false;
    var message = [];
    message[0] = this._translateService.instant('ERROR');
    message[1] = this._translateService.instant('SCHOOL.ERROR-MESSAGE1');
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[2];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
    confirmDialog.componentInstance.margin = false;

    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
  }

  continue() {
    this.newSchoolForm.markAllAsTouched();
    if (this.state === 'schoolData') {
      //validate schoolData
      if (this.newSchoolForm.invalid) {
        return;
      }
      if (this.newSchoolForm.value.NumStudents < this.school.NumStudents) {
        this.newSchoolForm.controls['NumStudents'].setErrors({ 'active': true });
        return;
      }
      if(!this.iniDateFormated)this.iniDateFormated = moment().format("DD/MM/YYYY");
      this.america = this.americanCountry(this.school.Country);
      this.iniDate = moment(this.iniDateFormated);
      this.maxDate = moment(this.iniDateFormated).add(this.duration, 'M');
      if (this.duration === '1') {
        if (this.america)
          this.amount = this.numStudents * 2;
        else
          this.amount = this.numStudents * 3;
      } else if (this.duration === '3') {
        if (this.america)
          this.amount = this.numStudents * 5;
        else
          this.amount = this.numStudents * 8;
      } else if (this.duration === '9') {
        this.amount = this.numStudents * 21;
      }
      if (this.school.Country === "ES" && this.school.State.toUpperCase() !== "SANTA CRUZ DE TENERIFE" && this.school.State.toUpperCase() !== "LAS PALMAS") {
        this.amount = (this.amount * (1 + (this.IVA / 100))).toFixed(2);
      }
      this.state = 'paymentType';
    } else if (this.state === 'paymentType') {
      if (this.paymentType === 'bank') {
        this.refNum = "LCTD-" + this.school.IdSchool + "_" + this.numStudents;
        this.state = 'paymentBank';
      }
      else
        this.pay();
    }

  }

  formatDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }

}
