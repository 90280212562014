export enum MiniGames {
    Jigsaw = 1,
    HeadBreaker = 2,
    Maze = 3,
    Sudoku = 4,
    DifferenceFinder = 5,
    Pipes = 6
}

export enum MiniGamesBackgroundColors {
    Jigsaw = 'Azul',
    HeadBreaker = 'Amarillo',
    Maze = 'Rosa',
    Sudoku = 'Morado',
    DifferenceFinder = 'Naranja',
    Pipes = 'Azul'
}