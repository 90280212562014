import { TranslateService } from '@ngx-translate/core';
import { CalendarDeduction } from './../../core/shared/state/models/deduction/deduction-month.model';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import * as moment from 'moment';
import 'moment/locale/es'

@Component({
    selector: 'cal-deduction-text',
    templateUrl: './cal-deduction-text.component.html',
    styleUrls: ['./cal-deduction-text.component.scss']
})
export class CalDeductionTextComponent implements OnInit {

    @Input() fullDay;
    @Input() currentUser;
    @Input() selectedDay;
    @Input() currentMonth;
    @Input() parent;
    @Input() deductionTexts: CalendarDeduction[]
    @Input() showAll
    @Input() licenseDate;
    translatedMonth: string = '';
    calendarUser;
    month;
    weeks;
    has_refreshed = true

    constructor(private _translateService: TranslateService
    ) {
        moment.locale('es')
    }

    ngOnInit() {
        this.calendarUser = this.currentUser;
        if (this.selectedDay == null || this.selectedDay.clone === "undefined") return;
        let currentDay = this.selectedDay.clone();
        this.month = currentDay.clone();
        var start = currentDay.clone();
        start.date(1);
        this._buildMonth(start, this.month);
        if (this.month.month() != moment().month()) this.selectedDay = null;
        this.selectToday();
        this.replaceMonth();
    }

      isPending(day){
        let currentDeductionText = this.deductionTexts.find((r) => { return moment(r.date).isSame(day.date) });
        if (currentDeductionText?.TextDate == null ) {
          return false;
        }else{
          if (currentDeductionText && currentDeductionText.TextDate != null  && currentDeductionText.FinishDate == null) {
            return true;
          } else {
            return false;
          }
        }
        
      }
    selectToday() {
        for (let week of this.weeks) {
            for (let day of week.days) {
                if (!this.currentSession(day.date)) continue;
                this.select(day);
                return;
            }
        }
    }

    selectedDayChangedDT(date) {
        let currentDay = this.selectedDay.clone();
        this.month = currentDay.clone();
        var start = currentDay.clone();
        start.date(1);
        this._buildMonth(start, this.month);
        this.parent.selectedDayChangedDT(date);
        this.replaceMonth()
    }

    refresh(callback = (weeks_count) => { }) {
        callback(this.weeks.length)
        setTimeout(() => {
            this.select({ date: this.selectedDay })
        }, 100)
    }

    select(day) {
        this.selectedDay = day.date;
        this.selectedDayChangedDT(this.selectedDay);
    }
    next() {
        this.selectedDay.month(this.selectedDay.month() + 1);
        this.month = this.selectedDay.clone();
        this.selectedDayChangedDT(this.selectedDay);
    }

    previous() {
        this.selectedDay.month(this.selectedDay.month() - 1);
        this.month = this.selectedDay.clone();
        this.selectedDayChangedDT(this.selectedDay);
    }

    isPast(date) {
        let now = moment()
        if (this.showAll) {
            if(this.licenseDate != null && date.isAfter(moment(this.licenseDate))) return false;
            // last month, current month and next month
            if (date.month() == now.month()) return true
            if (date.month() == now.month() + 1) return true
            return false
        }
        return date.isSameOrBefore(now)
    }
    isDone(date) {
        if (!this.deductionTexts) return false;
        var currentDeductionText = this.deductionTexts.find(function (r) {
            return r.Week == date.week() && r.Day == date.isoWeekday()
        });
        if (!currentDeductionText) return false;
        if (currentDeductionText.Score == null) return false;
        return currentDeductionText.FinishDate != null;
    }

    isFailed(date) {
        if (!this.deductionTexts) return false;
        var currentDeductionText = this.deductionTexts.find(function (r) {
          return r.Week == date.week() && r.Day == date.isoWeekday()
        });
        if (!currentDeductionText) return false;
        if (!currentDeductionText.Score) return false;
        if (!currentDeductionText.FinishDate) return false;
        return currentDeductionText.Score < 5;
      };
    notDone(date) {
        if (!this.deductionTexts) return false;
        if(this.licenseDate != null && date.isAfter(moment(this.licenseDate))) return false;
        var currentDeductionText = this.deductionTexts.find(function (r) {
            return r.Week == date.week() && r.Day == date.isoWeekday()
        });
        if (!currentDeductionText) return false;
        if (this.currentSession(date)) return false;
        return !this.isDone(date) && !this.isFailed(date);
    }

    currentSession(date) {
        if (!this.deductionTexts) return false;
        if(this.licenseDate != null && date.isAfter(moment(this.licenseDate))) return false;
        var currentDeductionText = this.deductionTexts.find(function (r) {
            return r.Week == date.week() && r.Day == date.isoWeekday()
        });
        if (!currentDeductionText) return false;
        var today = moment();
        return moment(date).calendar().indexOf("hoy") != -1;
    }

    isLowScore(date) {
        return false
    }

    isAvailableMonth(date) {
        if (!this.calendarUser) return true;
        if (moment(this.calendarUser.CreationDate).diff(date, 'days') > 0 && !this.calendarUser.UserName.includes(".tmp.")) return true;
        if ((date.month() > moment().month() - 2 && date.year() === moment().year()) ||
            (date.year() > moment().year()) ||
            (moment().month() == 0 && date.month() == 11 && (date.year() + 1) === moment().year()))
            return true;
        return false;
    }

    isAvailable(date) {
        var dictado = date.week() + '_' + date.isoWeekday();
        return true;
    }

    _removeTime(date) {
        var a = 0;
        return date.hour(a).minute(a).second(a).millisecond(a);
    }

    _buildMonth(start, month) {
        if (start == null) return;
        this.weeks = [];
        var done = false, date = start.clone(), monthIndex = date.month(), count = 0;
        while (!done) {
            this.weeks.push({ days: this._buildWeek(date.clone(), month) });
            date = date.clone();
            date.add(1, "w");
            done = count++ > 2 && (monthIndex !== date.startOf('week').month());
            monthIndex = date.month();
        }
    }

    _buildWeek(date, month) {
        let days = [];
        let dayItereator = date.startOf('week');
        for (var i = 0; i < 7; i++) {
            days.push({
                name: dayItereator.format("dd").substring(0, 1),
                number: dayItereator.date(),
                isCurrentMonth: dayItereator.month() === month.month(),
                isToday: dayItereator.isSame(new Date(), "day"),
                date: dayItereator,
                notDone: !this.showAll && this.notDone(dayItereator) && this.isPast(dayItereator),
                disabledMonth: !this.isAvailableMonth(dayItereator),
                isDone: (!this.isLowScore(dayItereator) && !this.showAll && (this.isDone(dayItereator) || this.isFailed(dayItereator))) || (this.showAll && this.notDone(dayItereator) && this.isPast(dayItereator)),
                currentSession: this.currentSession(dayItereator),
                lowScore: this.isLowScore(dayItereator) && (this.isDone(dayItereator) || this.isFailed(dayItereator))
            });
            dayItereator = dayItereator.clone();
            dayItereator.add(1, "d");
        }
        return days;
    }
    replaceMonth() {
        this._translateService.get('MONTHS').subscribe(months => {
            const monthKeys = Object.keys(months);
            const currentMonthIndex = this.month.month();
            const currentMonthKey = monthKeys[currentMonthIndex];
            this.translatedMonth = months[currentMonthKey];
        });
    }

}
