import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { LicenseService } from 'app/services/license.service';
import { NavigationService } from 'app/services/navigation.service';
import { UserService } from 'app/services/user.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialog } from '@angular/material/dialog';
import { SchoolService } from 'app/services/school.service';
import { AuthService } from 'app/services/auth.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-parent-students',
  templateUrl: './parent-students.component.html',
  styleUrls: ['./parent-students.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ParentStudentsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  currentUser;
  loading = false;
  calling;
  canCreateUsers = false;
  students;
  classes;
  dataLoaded = false;
  selectedIdUser;
  idUser;
  selectedAuthUser;
  authUsers;
  currentPage;

  isIpadIphone;


  constructor(
    private navigationService: NavigationService,
    private licenseService: LicenseService,
    private userService: UserService,
    private matDialog: MatDialog,
    public schoolService: SchoolService,
    private authService: AuthService,
    private _translateService: TranslateService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

  ngOnInit() {
    if(!this.authService.check()) return;
    this.currentUser = this.authService.currentUser;
    this.navigationService.enableExitButton();
    this.calling = true;
    this.licenseService.canCreateParentUsers(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successCanCreate.bind(this), this.errorCallback.bind(this));

this.isIpadIphone = this.detectiPadiPhone();
  }

  detectiPadiPhone(): boolean {
    const userAgent = window.navigator.userAgent;
    return /iphone|iPad/i.test(userAgent);
}

  successCanCreate(response) {
    this.canCreateUsers = response;
    this.userService.getClassesByIdTutor(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetClasses.bind(this), this.errorCallback.bind(this));
  }

  successGetClasses(response) {
    this.calling = false;
    this.classes = response;
    if (this.classes.length == 0) {
      this.classes = null;
      this.userService.getStudentsByParentId(this.currentUser.Id, 0)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoad.bind(this), this.errorCallback.bind(this));
    } else {
      this.calling = true;
      this.userService.getStudentsByParentId(this.currentUser.Id, this.classes[0].IdClass)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoad.bind(this), this.errorCallback.bind(this));
    }
  }

  successLoad(response) {
    this.calling = false;
    this.students = [];
    for (let i = 0; i < response.length; i++) {
      if(response[i].UserName == null) {
        this.students.push(response[i]);
        continue;
      }
      if (response[i].UserName.toUpperCase().includes(".TMP.") == false) this.students.push(response[i]);
    }
    this.students.sort(function (a, b) {
      return a.UserName - b.UserName;
    });
    if (!this.selectedIdUser && this.students.length > 0) this.selectedIdUser = this.students[0].Id;
    for (let i = 0; i < this.students.length; i++) {
      if (this.students[i].Id == this.selectedIdUser) {
        if (this.students[i].AuthUser != null)
          this.selectedAuthUser = true;
        else
          this.selectedAuthUser = false;
      }
    }
    if (!this.currentPage) {
      this.currentPage = 0;
    }
    this.calling = true;
    this.userService.GetAuthorizedStudentsByParentId(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoadAuth.bind(this), this.errorCallback.bind(this));
    this.dataLoaded = true;
  }

  successLoadAuth(response) {
    this.calling = false;
    if (response != null) {
      this.authUsers = response;
      for (let i = 0; i < this.authUsers.length; i++) this.authUsers[i].AuthUser = true;
      let noClasses = false;
      if (this.currentUser.IdSchool) {
        if (!this.classes) {
          this.classes = [];
          noClasses = true
        }
        if (this.authUsers && this.authUsers.length > 0) this.classes.push({ Name: "Autorizados", IdClass: -1 });
      }
      if (!this.currentUser.IdSchool || noClasses) {
        for (let i = 0; i < this.authUsers.length; i++) this.students.push(this.authUsers[i]);
      }
      if (!this.selectedIdUser && this.students.length > 0) this.selectedIdUser = this.students[0].Id;
      for (let i = 0; i < this.students.length; i++) {
        if (this.students[i].Id == this.selectedIdUser) {
          if (this.students[i].AuthUser != null)
            this.selectedAuthUser = true;
          else
            this.selectedAuthUser = false;
        }
      }
    }
  }

  selectUser(IdUser) {
    if (this.selectedIdUser != IdUser) {
      this.selectedIdUser = IdUser;
      this.selectedAuthUser = false;
      for (let i = 0; i < this.students.length; i++) {
        if (this.students[i].Id == this.selectedIdUser) {
          if (this.students[i].AuthUser != null)
            this.selectedAuthUser = true;
          else
            this.selectedAuthUser = false;
        }
      }
    }
  }

  deleteUser() {
    if (!this.selectedIdUser) return;
    if (this.calling || this.loading) return;
    let student = null;
    for (let i = 0; i < this.students.length; i++) {
      if (this.students[i].Id == this.selectedIdUser) {
        student = this.students[i];
      }
    }
    if (!student) return;
    let message = [];
    message[0] = this._translateService.instant('SCHOOL.MODAL-ERROR-DELETE-STUDENT-TITLE');
    message[1] = this._translateService.instant('SCHOOL.MODAL-DELETE-STUDENT-MESSAGE', {UserName: student.UserName});
    let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.message2 = message[2];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.theme = "white";
    confirmDialog.componentInstance.options = [{
      text: this._translateService.instant('ACCEPT'),
      callback: () => {
        this.calling = true;
        this.loading = true;
        if (this.currentUser.IdSchool != null) {
          this.schoolService.deleteUser(student.Id, this.currentUser.Id, this.currentUser.IsSchoolAdmin || false)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDeleteStudent.bind(this), this.errorDeleteStudent.bind(this));
        } else {
          this.userService.deleteUser(student.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDeleteStudent.bind(this), this.errorDeleteStudent.bind(this));
        }
      }
    }, {
      text: this._translateService.instant('CANCEL'),
      callback: () => { }
    }];
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      result();
      confirmDialog = null;
    });
  }

  successDeleteStudent() {
    this.loading = false;
    this.calling = false;
    let message = [];
    message[0] = this._translateService.instant('SCHOOL.MODAL-ERROR-DELETE-STUDENT-TITLE');
    message[1] = this._translateService.instant('SCHOOL.MODAL-SUCCESS-DELETE-STUDENT-MESSAGE');
    let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      this.calling = true;
      this.selectedIdUser = null;
      this.userService.getClassesByIdTutor(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetClasses.bind(this), this.errorCallback.bind(this));
      confirmDialog = null;
    });
  }

  errorDeleteStudent(response) {
    this.loading = false;
    this.calling = false;
    if (response == null) {
      this.navigationService.exit();
    } else {
      let message = [];
      message[0] = this._translateService.instant('SCHOOL.DELETE-USER-ERROR-TITLE');
      message[1] = this._translateService.instant('SCHOOL.DELETE-USER-ERROR-MESSAGE');
      message[2] = this._translateService.instant('SCHOOL.MODAL-ERROR-MESSAGE');
      let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
      });
      confirmDialog.componentInstance.title = message[0];
      confirmDialog.componentInstance.message1 = message[1];
      confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
      confirmDialog.componentInstance.margin = false;
      confirmDialog.componentInstance.margin = false;
      confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
        confirmDialog = null;
      });
    }
  }

  resetStudentPassword() {
    if (!this.selectedIdUser) return;
    if (this.calling) return;
    let student = null;
    for (let i = 0; i < this.students.length; i++) {
      if (this.students[i].Id == this.selectedIdUser) {
        student = this.students[i];
      }
    }
    if (!student) return;
    let message = [];
    message[0] = this._translateService.instant('SCHOOL.MODAL-RESET-PASSWORD-TITLE')
    message[1] = this._translateService.instant('SCHOOL.MODAL-RESET-PASSWORD-MESSAGE', {UserName: student.UserName})
    let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.message2 = message[2];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.theme = "white";
    confirmDialog.componentInstance.options = [{
      text: this._translateService.instant('ACCEPT'),
      callback: () => {
        this.calling = true;
        this.schoolService.resetPassword(student.Id, this.currentUser.Id, this.currentUser.IsSchoolAdmin)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successResetStudentPassword.bind(this), this.unexpectedError.bind(this));
      }
    }, {
      text: this._translateService.instant('CANCEL'),
      callback: () => { }
    }];
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      result();
      confirmDialog = null;
    });
  }

  successResetStudentPassword(response) {
    this.calling = false;
    let message = [];
    message[0] = this._translateService.instant('SCHOOL.MODAL-SUCCESS-RESET-PASSWORD-TITLE');
    message[1] = this._translateService.instant('SCHOOL.MODAL-SUCCESS-RESET-PASSWORD-MESSAGE');
    let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
  }

  unexpectedError(response) {
    this.calling = false;
    let message = [];
    message[0] = this._translateService.instant('ERROR');
    message[1] = this._translateService.instant('SCHOOL.MODAL-ERROR-MESSAGE');
    let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
  }

  getComa(user) {
    if (user.FamilyName != null && user.Name != null && user.FamilyName != '' && user.Name != '') {
      return ",";
    }
    return "";
  }

  goEditStudent() {
    if (!this.selectedIdUser) return;
    this.navigationService.go("/school/parentStudents/editStudent/" + this.selectedIdUser, {
      IdUser: this.selectedIdUser
    })
  }

  goAuthTutor() {
    if (!this.selectedIdUser) return;
    this.navigationService.go("/school/parentStudents/auth/" + this.selectedIdUser, {
      IdUser: this.selectedIdUser
    })
  }

  addUser() {
    this.navigationService.go("/school/parentStudents/newStudent", {
      IdUser: this.selectedIdUser
    })
  }

  goSessionReview() {
    if (!this.selectedIdUser) return;
    this.navigationService.go("/school/parentStudents/review", {
      IdUser: this.selectedIdUser,
    })
  }

  license() {

    if (!this.selectedIdUser) return;

    var student = null;

    for (var i = 0; i < this.students.length; i++) {
      if (this.students[i].Id == this.selectedIdUser) {
        student = this.students[i];
      }
    }

    if (student.LicenseStatus == "Pendiente") {
      var message = [];
      message[0] = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MODAL-ERROR-LICENSE-UPDATE-TITLE')
      message[1] = this._translateService.instant('SCHOOL.PARENT.STUDENTS.MODAL-ERROR-LICENSE-UPDATE-MESSAGE');
      let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
      });
      confirmDialog.componentInstance.title = message[0];
      confirmDialog.componentInstance.message1 = message[1];
      confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
      confirmDialog.componentInstance.margin = false;
      confirmDialog.componentInstance.margin = false;
      confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
        confirmDialog = null;
      });
    } else {
      this.navigationService.go("/school/parentStudents/license/" + this.selectedIdUser, {
        student: student,
      })
    }
  };

  errorCallback() {
    this.navigationService.exit();
  }

}
