import { Component, Input, OnInit, Sanitizer, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReportService } from 'app/services/report.service';
import { SchoolService } from 'app/services/school.service';
import { SessionService } from 'app/services/session.service';
import { UserService } from 'app/services/user.service';
import { AppService } from 'app/app.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { NavigationService } from 'app/services/navigation.service';
import { DateAdapter } from '@angular/material/core';
import { AuthService } from 'app/services/auth.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { BlobResponse } from 'app/core/shared/state/models/blob-response-model';
import { TranslateService } from '@ngx-translate/core';
declare let $;

@Component({
    selector: 'app-parent-report',
    templateUrl: './parent-report.component.html',
    styleUrls: ['./parent-report.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ParentReportComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    schoolReportControllerSelectedDay = moment().hour(0).minute(0).second(0).millisecond(0);
    NotDone = 0;
    Finished = 1;
    Pending = 2;
    Started = 3;
    NotFinished = 4;
    calling;
    currentSessionId;
    SessionStatus = {
        finished: "FINISHED",
        started: "STARTED",
        newSession: "NEW",
        generating: "GENERATING",
        notfinished: "NOTFINISHED"
    }
    authUsersAvailable = false;
    loading = false;
    dictEnabled = true;
    specificTheme = null;
    selectedClassId
    authUsers
    reportTypes = [
        { name: 'studentHistory', desc: this._translateService.instant('SCHOOL.PARENT.STUDENTS.REPORT.STUDENTHISTORY') },
        { name: 'studentRegularity', desc: this._translateService.instant('SCHOOL.PARENT.STUDENTS.REPORT.STUDENTREGULARITY') },
        { name: 'studentSession', desc: this._translateService.instant('SCHOOL.PARENT.STUDENTS.REPORT.STUDENTSESSION') },
        { name: 'dictationHistory', desc: this._translateService.instant('SCHOOL.PARENT.STUDENTS.REPORT.DICTATIONHISTORY') },
        { name: 'evaReadingText', desc: this._translateService.instant('SCHOOL.PARENT.STUDENTS.REPORT.EVAREADINGTEXT') }];
    reportType;
    years;
    year;
    classes = [];
    currentMonth = 0;
    selectedClass;
    selectedDay = this.schoolReportControllerSelectedDay;
    selectedDay_desde = moment().locale("es").format();
    selectedDay_hasta = moment().locale("es").format();
    currentSessionStatus = null;
    disableActionButton = true;
    showDownload = false;
    downloading = false;
    studentId = null;
    student = null;
    dataLoaded = false;
    students = [];
    expandControls = true;
    month = this.schoolReportControllerSelectedDay.month() + 1;
    mytimeout = null;
    htmlCode = null;
    currentUser;

    constructor(
        private router: Router,
        private userService: UserService,
        public schoolService: SchoolService,
        private sessionService: SessionService,
        private reportService: ReportService,
        private sanitizer: DomSanitizer,
        private appService: AppService,
        private _matDialog: MatDialog,
        private navigationService: NavigationService,
        private dateAdapter: DateAdapter<Date>,
        private _translateService: TranslateService,
        private authService: AuthService
    ) {
        this._unsubscribeAll = new Subject();
        this.dateAdapter.setLocale("es-ES");
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        if (!sessionStorage.getItem("currentUser")) {
            sessionStorage.removeItem('currentUser');
            sessionStorage.removeItem('adminLogin');
            sessionStorage.removeItem("state");
            sessionStorage.removeItem("state_params");
            sessionStorage.setItem("isLogged", "false");
            this.router.navigateByUrl("login");
            return;
        }
        this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        if (!this.authService.check()) return;
        this.navigationService.enableBackButton(true, this.back.bind(this));
        this.reportType = this.reportTypes[0].name;
        this.getYears();
        this.getDates();
        this.getStudents();
    }

    getStudents() {
        this.userService.getClassesByIdTutor(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetClasses.bind(this), this.errorCallback.bind(this));
    }

    successGetClasses(response) {
        this.calling = false;
        this.classes = response;
        if (this.classes.length == 0) {
            this.classes = null;
            this.userService.getStudentsByParentId(this.currentUser.Id, 0)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoad.bind(this), this.errorCallback.bind(this));
        } else {
            this.calling = true;
            this.userService.getStudentsByParentId(this.currentUser.Id, this.classes[0].IdClass)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoad.bind(this), this.errorCallback.bind(this));
        }
    }

    successLoad(response) {
        this.calling = false;
        this.students = [];
        for (let i = 0; i < response.length; i++) {
            if (response[i].UserName.toUpperCase().includes(".TMP.") == false) this.students.push(response[i]);
        }
        if (!this.authUsers) {
            this.calling = true;
            this.userService.GetAuthorizedStudentsByParentId(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoadAuth.bind(this), this.errorCallback.bind(this));
        }
        this.students.sort(function (a, b) {
            return a.UserName - b.UserName;
        });
        this.student = this.students[0]
        this.studentId = this.student.Id
        this.checkButton();
    }

    getYears() {
        this.years = [];
        // [{ name: 2019, desc: '2019' }, { name: 2020, desc: '2020' }]
        let currentYear = new Date().getFullYear();
        for (let i = 1; i >= 0; i--) {
            let year = { name: currentYear - i, desc: (currentYear - i).toString() }
            this.years.push(year);
        }
        this.year = this.years[this.years.length - 1].name;
    }

    getDates() {
        this.selectedDay_desde = moment().startOf('month').format();
        this.selectedDay_hasta = moment().format();
    }

    back() {
        this.router.navigateByUrl("/school/parentStudents");
    }

    getComa = (user) => {
        if (user.FamilyName != null && user.Name != null && user.FamilyName != '' && user.Name != '') {
            return ",";
        }
        return "";
    }

    checkButton() {
        switch (this.reportType) {
            case 'dictationHistory':
                if (this.student == null)
                    this.disableActionButton = true;
                else
                    this.disableActionButton = false;
                break;
            case 'studentSession':
                if (this.currentSessionStatus == null || (this.currentSessionStatus != this.Finished && this.currentSessionStatus != this.NotFinished))
                    this.disableActionButton = true;
                else
                    this.disableActionButton = false;
                break;
            case 'studentHistory':
                if (this.student == null)
                    this.disableActionButton = true;
                else
                    this.disableActionButton = false;
                break;
            case 'studentRegularity':
                if (this.student == null)
                    this.disableActionButton = true;
                else
                    this.disableActionButton = false;

                break;
            case 'studentStatus':
                if (this.student == null)
                    this.disableActionButton = true;
                else
                    this.disableActionButton = false;

                break;
            case 'studentEvolution':
                if (this.student == null)
                    this.disableActionButton = true;
                else
                    this.disableActionButton = false;

                break;
            case 'studentLastWeek':
                if (this.student == null)
                    this.disableActionButton = true;
                else
                    this.disableActionButton = false;

                break;
            case 'dictationHistory':
                this.disableActionButton = false;
                break;
            case 'evaReadingText':
                this.disableActionButton = false;
                break;
        }
        this.dataLoaded = true;
        setTimeout(() => {
            $("input[datepicker]").datepicker({
                dateFormat: 'dd/mm/yy',
                onSelect: (dateText, inst) => {
                    this[inst.id] = new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay);
                }
            });
        }, 100);
    }

    changeControls() {
        if (!this.dateHidden() && moment(this.selectedDay_desde).isAfter(moment(this.selectedDay_hasta))) {
            this.callMessage();
            return;
        }
        this.showDownload = false;
        if (this.expandControls == false)//openning
        {
            this.expandControls = true;
            this.htmlCode = "";
        } else
            if (this.expandControls == true) {//closing

                if (this.reportType == 'dictationHistory') {
                    if (this.student != null) this.showDictationHistory();
                }
                else if (this.reportType == 'studentHistory') {
                    if (this.student != null)
                        this.showStudentHistory();
                }
                else if (this.reportType == 'studentRegularity') {
                    if (this.student != null)
                        this.showStudentRegularity();
                }
                else if (this.reportType == 'studentStatus') {
                    if (this.student != null)
                        this.showStudentStatus();
                }
                else if (this.reportType == 'studentSession') {
                    if (this.currentSessionStatus == this.Finished || this.currentSessionStatus == this.NotFinished) {
                        this.showSessionReport();
                        this.expandControls = false;
                    } else
                        this.expandControls = true;
                }
                else if (this.reportType == 'studentEvolution') {
                    if (this.student != null)
                        this.showEvolution();
                }
                else if (this.reportType == 'studentLastWeek') {
                    if (this.student != null)
                        this.showLastWeek();
                }
                else if (this.reportType == 'evaReadingText') {
                    if (this.student != null) {
                        this.showEvaReadingText();
                    }
                }
                this.showDownload = !this.expandControls;
            }
    }


    callMessage() {
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        confirmDialog.componentInstance.title =this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.MODAL-TITLE');
        confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.MODAL-MESSAGE');
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');

        confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
            result();
            confirmDialog = null;
        });
    }

    dateHidden(): boolean {
        return (this.reportType != 'studentEvolution' && this.reportType != 'studentHistory' && this.reportType != 'dictationHistory' && this.reportType != 'studentStatus' && this.reportType != 'evaReadingText')
    }

    showDictationHistory() {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);

        if (endDate.isSameOrAfter(startDate)) {
            let reportName = "dictationHistory";
            let parameters = [
                { idUser: this.student.Id },
                { fechaInicio: startDate.format('YYYY-MM-DD') },
                { fechaFin: endDate.format('YYYY-MM-DD') }
            ];
            this.reportService.getHtml(reportName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
            this.loading = true;
            this.htmlCode = null;
            this.expandControls = false;
        }

    }

    showEvaReadingText() {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate.isSameOrAfter(startDate)) {
            var reportName = "evaReadingText";
            var parameters = [{
                idUser: this.student.Id
            }, {
                fechaInicio: startDate.format('YYYY-MM-DD')
            }, {
                fechaFin: endDate.format('YYYY-MM-DD')
            }];
            this.htmlCode = null;
            this.reportService.getHtml(reportName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
                result => {
                    this.successGetHtml(result);
                },
                error => {
                    this.errorCallback();
                }
            );
            this.loading = true;
            this.expandControls = false;
        }
    }

    changeDate() {
        this.checkButton();
    }

    getPdf() {
        this.downloading = true;
        if (this.reportType == 'studentHistory') {
            if (this.student != null)
                this.downloadStudentHistory('pdf');
        }
        else if (this.reportType == 'studentRegularity') {
            if (this.student != null)
                this.downloadStudentRegularity('pdf');
        }
        else if (this.reportType == 'studentStatus') {
            if (this.student != null)
                this.downloadStudentStatus('pdf');
        }
        else if (this.reportType == 'studentSession') {
            if (this.currentSessionStatus == this.Finished || this.currentSessionStatus == this.NotFinished) {
                this.downloadSessionReport('pdf');
            }
        }
        else if (this.reportType == 'studentEvolution') {
            if (this.student != null)
                this.downloadEvolution('pdf');
        }
        else if (this.reportType == 'evaReadingText') {
            if (this.student != null)
                this.downloadEvaReadingText('pdf');
        }
        else if (this.reportType == 'studentLastWeek') {
            if (this.student != null)
                this.downloadLastWeek('pdf');
        }
        else if (this.reportType == 'dictationHistory') {
            if (this.student != null) {
                this.downloadDictationHistory('pdf')
            }
        }
    }

    getExcel() {
        this.downloading = true;
        console.log("entro")
        if (this.reportType == 'studentHistory') {
            if (this.student != null)
                this.downloadStudentHistory('excel');
        }
        else if (this.reportType == 'studentRegularity') {
            if (this.student != null)
                this.downloadStudentRegularity('excel');
        }
        else if (this.reportType == 'studentStatus') {
            if (this.student != null)
                this.downloadStudentStatus('excel');
        }
        else if (this.reportType == 'studentSession') {
            if (this.currentSessionStatus == this.Finished || this.currentSessionStatus == this.NotFinished) {
                this.downloadSessionReport('excel');
            }
        }
        else if (this.reportType == 'studentEvolution') {
            if (this.student != null)
                this.downloadEvolution('excel');
        }
        else if (this.reportType == 'studentLastWeek') {
            if (this.student != null)
                this.downloadLastWeek('excel');
        }
        else if (this.reportType == 'evaReadingText') {
            if (this.student != null)
                this.downloadEvaReadingText('excel');
        }
        else if (this.reportType == 'dictationHistory') {
            if (this.student != null) {
                this.downloadDictationHistory('excel')
            }
        }
    }

    showEvaQualificationsSchool() {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate.isSameOrAfter(startDate)) {

            let reportName = "evaCalificaSchool";
            let parameters = [
                { idschool: this.schoolService.school.IdSchool },
                { fechaInicio: startDate.format('YYYY-MM-DD') },
                { fechaFin: endDate.format('YYYY-MM-DD') }

            ];
            this.reportService.getHtml(reportName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
            this.htmlCode = null;
            this.loading = true;
            this.expandControls = false;
        }
    }

    downloadEvaQualificationsSchool(format) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate.isSameOrAfter(startDate)) {

            let pdfName = startDate.format('DD-MM') + "_" + endDate.format('DD-MM');

            let reportName = "evaCalificaSchool";
            let parameters = [
                { idschool: this.schoolService.school.IdSchool },
                { fechaInicio: startDate.format('YYYY-MM-DD') },
                { fechaFin: endDate.format('YYYY-MM-DD') }];
            if (format == 'pdf')
                this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            else
                this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            this.loading = true;
        }
    }

    loadSessionMonth() {
        setTimeout(() => {
            if (this.studentId == null || this.studentId == '') {
                this.disableActionButton = true;
                return;
            }
            this.disableActionButton = false;
            this.sessionService.getSessionByUserMonthYear(this.studentId, this.month, this.year).
                subscribe(this.successLoadSessionMonth.bind(this), this.errorCallback.bind(this));
            this.currentMonth = 0;
        }, 100)
    }

    successLoadSessionMonth(response) {
        if (response == null) return this.navigationService.exit();
        for (let i = 0; i < this.students.length; i++) {
            if (this.students[i].Id == this.studentId)
                this.student = this.students[i];
        }
        this.student.Sessions = response;
        this.currentMonth = this.month;
        this.currentSessionStatus = null;
        this.checkButton();
    }

    selectedDayChanged(newDate) {
        this.currentSessionStatus = null;
        this.selectedDay = newDate;
        if (this.month != this.selectedDay.month() + 1) {
            this.month = this.selectedDay.month() + 1;
            this.year = this.selectedDay.year();
            this.loadSessionMonth();
        }
        else {
            for (let i = 0; i < this.student.Sessions.length; i++) {
                if (this.selectedDay.isSame(moment(this.student.Sessions[i].ExecutionDate), "day")) {
                    if (this.student.Sessions[i].Status == this.SessionStatus.started)
                        this.currentSessionStatus = this.NotDone;
                    else if (this.student.Sessions[i].Status == this.SessionStatus.notfinished)
                        this.currentSessionStatus = this.NotFinished;
                    else if (this.student.Sessions[i].Status == this.SessionStatus.finished)
                        this.currentSessionStatus = this.Finished;
                    else if (this.student.Sessions[i].Status == this.SessionStatus.newSession)
                        this.currentSessionStatus = null;
                    this.currentSessionId = this.student.Sessions[i].IdSession;
                }
            }
        }
        this.checkButton();
    }

    showEvaMainGroupCallback(alternative) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate.isSameOrAfter(startDate)) {

            let classId = 0;
            if (this.selectedClass != null)
                classId = this.selectedClass.IdClass;

            let reportName = "evaMainGroup";
            let parameters = [

                { fechaInicio: startDate.format('YYYY-MM-DD') },
                { fechaFin: endDate.format('YYYY-MM-DD') },
                { class: classId }
            ];
            if (alternative == "Visualizar") {
                this.reportService.getHtml(reportName, parameters)
                    
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
                this.htmlCode = null;
                this.loading = true;
                this.expandControls = false;
            } else if (alternative == "PDF") {
                this.disableActionButton = true;
                this.downloadEvaMainGroup("pdf");
            } else if (alternative == "Excel") {
                this.disableActionButton = true;
                this.downloadEvaMainGroup("excel");
            }
        }
    }

    showEvaMainGroup() {

        let message = [];
        message[0] = this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.SHOWEVAMAINGROUP-TITLE');
        message[1] = this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.SHOWEVAMAINGROUP-MESSAGE1');
        message[2] = this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.SHOWEVAMAINGROUP-MESSAGE2');

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.message2 = message[2];
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.isReport = true;

        confirmDialog.componentInstance.options = [{
            text: "PDF",
            callback: () => {
                this.showEvaMainGroupCallback("PDF");
            }
        }, {
            text: "Excel",
            callback: () => {
                this.showEvaMainGroupCallback("Excel");
            }
        }];

        confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
            result();
            confirmDialog = null;
        });

    }

    showEvaResumeGroup() {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate.isSameOrAfter(startDate)) {
            let classId = 0;
            if (this.selectedClass != null)
                classId = this.selectedClass.IdClass;

            let reportName = "evaResumeGroup";
            let parameters = [
                { fechaInicio: startDate.format('YYYY-MM-DD') },
                { fechaFin: endDate.format('YYYY-MM-DD') },
                { class: classId }
            ];
            this.reportService.getHtml(reportName, parameters)
                
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
            this.htmlCode = null;
            this.loading = true;
            this.expandControls = false;
        }
    }

    parseDate(str) {
        if (str instanceof Date) return moment(str);
        if (moment.isMoment(str)) return str;
        let d = parseFloat(str.split('/')[0]);
        let m = parseFloat(str.split('/')[1]) - 1;
        let a = parseFloat(str.split('/')[2]);
        return moment(new Date(a, m, d));
    }

    showStudentHistory() {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate.isSameOrAfter(startDate)) {

            let reportName = "studentHistory";
            let parameters = [
                { idUser: this.student.Id },
                { fechaInicio: startDate.format('YYYY-MM-DD') },
                { fechaFin: endDate.format('YYYY-MM-DD') }
            ];
            this.reportService.getHtml(reportName, parameters)
                
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
            this.htmlCode = null;
            this.loading = true;
            this.expandControls = false;
        }
    }

    showStudentRegularity() {

        let reportName = "studentRegularity";

        let parameters = [
            { usser: this.student.Id },
            { year: this.year }
        ];
        this.reportService.getHtml(reportName, parameters)
            
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
        this.htmlCode = null;
        this.loading = true;
        this.expandControls = false;
    }

    showSessionReport() {

        let reportName = "studentSession";

        let parameters = [
            { usser: this.studentId },
            { session: this.currentSessionId }
        ];
        this.reportService.getHtml(reportName, parameters)
            
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
        this.htmlCode = null;
        this.loading = true;
        this.expandControls = false;
    }

    showStudentStatus() {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate.isSameOrAfter(startDate)) {
            let reportName = "studentStatus";

            let parameters = [
                { usser: this.studentId },
                { fechaInicio: startDate.format('YYYY-MM-DD') },
                { fechaFin: endDate.format('YYYY-MM-DD') },
            ];
            this.reportService.getHtml(reportName, parameters)
                
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
            this.htmlCode = null;
            this.loading = true;
            this.expandControls = false;
        }
    }

    showEvolution() {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate.isSameOrAfter(startDate)) {
            let reportName = "studentEvolution";

            let parameters = [
                { idUser: this.student.Id },
                { fechaInicio: startDate.format('YYYY-MM-DD') },
                { fechaFin: endDate.format('YYYY-MM-DD') },
                { tema: this.specificTheme }
            ];
            this.reportService.getHtml(reportName, parameters)
                
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
            this.loading = true;
            this.htmlCode = null;
            this.expandControls = false;
        }
    }

    showLastWeek() {
        let reportName = "studentLastWeek";

        let parameters = [
            { idUser: this.student.Id }
        ];
        this.reportService.getHtml(reportName, parameters)
            
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
        this.loading = true;
        this.htmlCode = null;
        this.expandControls = false;
    }

    downloadEvaMainGroup(format) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate.isSameOrAfter(startDate)) {
            let classId;
            let pdfName = "GrupoDet_" + startDate.format('DD-MM') + "_" + endDate.format('DD-MM');
            if (this.selectedClass != null) {
                classId = this.selectedClass.IdClass;
                for (let i = 0; i < this.classes.length; i++) {
                    if (this.classes[i].IdClass == classId)
                        pdfName = "GrupoDet_" + this.classes[i].Name + "_" + startDate.format('DD-MM') + "_" + endDate.format('DD-MM');
                }
            }
            else
                classId = 0;

            let reportName = "evaMainGroup";


            let parameters = [
                {
                    fechaInicio: startDate.format('YYYY-MM-DD')
                },
                {
                    fechaFin: endDate.format('YYYY-MM-DD')
                },
                {
                    class: classId
                }
            ];

            if (format == 'pdf')
                this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            else
                this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            this.loading = true;
        }
    }

    downloadStudentHistory(format) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate.isSameOrAfter(startDate)) {
            let reportName = "studentHistory";
            let pdfName = this.student.Name + "_" + this.student.FamilyName + "_" + startDate.format('DD-MM') + "_" + endDate.format('DD-MM');
            let parameters = [
                {
                    idUser: this.student.Id
                },
                {
                    fechaInicio: startDate.format('YYYY-MM-DD')
                },
                {
                    fechaFin: endDate.format('YYYY-MM-DD')
                }
            ];
            if (format == 'pdf')
                this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            else
                this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            this.loading = true;
        }
    }

    downloadSessionReport(format) {
        let reportName = "studentSession";
        let pdfName = this.student.Name + "_" + this.student.FamilyName + "_" + this.currentSessionId;
        for (let i = 0; i < this.student.Sessions.length; i++) {
            if (this.student.Sessions[i].IdSession == this.currentSessionId)
                pdfName = this.student.Name + "_" + this.student.FamilyName + "_" + moment(this.student.Sessions[i].ExecutionDate).format('DD-MM');
        }

        let parameters = [{
            usser: this.studentId
        },
        {
            session: this.currentSessionId
        }];
        if (format == 'pdf')
            this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
        else
            this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
        this.loading = true;
    }

    downloadStudentRegularity(format) {
        let reportName = "studentRegularity";
        let pdfName = this.student.Name + "_" + this.student.FamilyName + "_" + moment().format('DD-MM');
        let parameters = [
            { usser: this.studentId },
            { year: this.year }
        ];
        if (format == 'pdf')
            this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
        else
            this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
        this.loading = true;
    }

    downloadStudentStatus(format) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate.isSameOrAfter(startDate)) {
            let reportName = "studentStatus";
            let pdfName = this.student.Name + "_" + this.student.FamilyName + "_" + moment().format('DD-MM');
            let parameters = [
                { usser: this.studentId },
                { fechaInicio: startDate.format('YYYY-MM-DD') },
                { fechaFin: endDate.format('YYYY-MM-DD') },
            ];

            if (format == 'pdf')
                this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            else
                this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            this.loading = true;
        }
    }

    downloadEvaReadingText(format: string): void {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate.isSameOrAfter(startDate)) {
            var reportName = "evaReadingText";
            let pdfName = this.student.Name + "_" + this.student.FamilyName + "_" + moment(this.selectedDay_desde).format('YYYY-MM-DD') + "_" + moment(this.selectedDay_hasta).format('YYYY-MM-DD')
            var parameters = [{
                idUser: this.student.Id
            }, {
                fechaInicio: startDate.format('YYYY-MM-DD')
            }, {
                fechaFin: endDate.format('YYYY-MM-DD')
            }];
            if (format == 'pdf')
                this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            else
                this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            this.loading = true;
        }
    }

    downloadDictationHistory(format: string): void {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate.isSameOrAfter(startDate)) {
            var reportName = "dictationHistory";
            let pdfName = this.student.Name + "_" + this.student.FamilyName + "_" + moment(this.selectedDay_desde).format('YYYY-MM-DD') + "_" + moment(this.selectedDay_hasta).format('YYYY-MM-DD')
            var parameters = [{
                idUser: this.student.Id
            }, {
                fechaInicio: startDate.format('YYYY-MM-DD')
            }, {
                fechaFin: endDate.format('YYYY-MM-DD')
            }];
            if (format == 'pdf')
                this.reportService.getPdf(reportName, pdfName, parameters)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            else
                this.reportService.getExcel(reportName, pdfName, parameters)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            this.loading = true;
        }
    }

    downloadEvolution(format) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate.isSameOrAfter(startDate)) {
            let reportName = "studentEvolution";
            let pdfName = this.student.Name + "_" + this.student.FamilyName + "_" + startDate.format('DD-MM') + "_" + endDate.format('DD-MM');
            let parameters = [
                { idUser: this.student.Id },
                { fechaInicio: startDate.format('YYYY-MM-DD') },
                { fechaFin: endDate.format('YYYY-MM-DD') },
                { tema: this.specificTheme }
            ];
            if (format == 'pdf')
                this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            else
                this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            this.loading = true;
        }
    }

    downloadLastWeek(format) {
        let reportName = "studentLastWeek";
        let endDate = moment().startOf('day');
        let pdfName = this.student.Name + "_" + this.student.FamilyName + "_Semana_" + endDate.format('DD-MM');
        let parameters = [
            { idUser: this.student.Id }
        ];
        if (format == 'pdf')
            this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
        else
            this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
        this.loading = true;
    }

    successGetHtml(data) {
        this.loading = false;
        this.htmlCode = this.sanitizer.bypassSecurityTrustHtml(data.html);
        setTimeout(() => {
            let heightNoInput = this.appService.reference1;
            let pantalla_inicial = parseFloat("1344px");
            let pantalla_final = (parseFloat(heightNoInput) * 1.4);
            let d = (pantalla_final * 100) / pantalla_inicial;
            d = 100 - d;
            d = d / 100;
            $("#report-frame").contents().find("html").css("zoom", (1 - d) * 1.5);
        }, 100)
    }

    notLoading() {
        this.loading = false;
    }

    successDownload(data) {
        const blobResponse = data?.body as BlobResponse;
        this.loading = false;
        this.disableActionButton = false;
        let link = document.createElement("a");
        link.setAttribute("href", URL.createObjectURL(blobResponse.file));
        link.setAttribute("target", "_blank");
        link.setAttribute("download", blobResponse.fileName);
        document.body.appendChild(link)
        link.click();
        this.downloading = false;
    }


    successLoadAuth(response) {
        if (response != null) {
            this.authUsers = response;
            if (this.authUsers.length != 0) {
                this.authUsersAvailable = true;
                for (let i = 0; i < this.authUsers.length; i++) {
                    this.authUsers[i].AuthUser = true;
                }
            }
            let noClasses = false;
            if (this.authService.currentUser.IdSchool != null) {
                if (this.classes == null) {
                    this.classes = [];
                    noClasses = true
                }
                if (this.authUsers && this.authUsers.length > 0) {
                    let classAuth = {
                        Name: "Autorizados",
                        IdClass: -1
                    }
                    this.classes.push(classAuth);
                }
            }
            if (this.authService.currentUser.IdSchool == null || noClasses) {
                if (this.authUsers.length != 0) {
                    for (let i = 0; i < this.authUsers.length; i++) {
                        this.students.push(this.authUsers[i]);
                    }
                }
            }
        } else {
            this.calling = false;
        }
        this.checkButton();
    }

    successLoadAuthClasses(response) {
        if (response != null) {
            if (this.classes == null) {
                this.classes = response;
            } else {
                for (let i = 0; i < response.length; i++)
                    this.classes.push(response[i]);
            }
        }
        this.calling = false;
    }

    errorCallback() {
        this.loading = false;
        this.downloading = false;
        this.expandControls = true;
    }

}
