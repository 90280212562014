import { SharedService } from 'app/core/shared/shared.service';
import { CivilizationService } from 'app/services/civilization.service';
import { UserService } from 'app/services/user.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { AuthService } from 'app/services/auth.service';
import { GameService } from 'app/services/game.service';
import { NavigationService } from 'app/services/navigation.service';
import { SchoolService } from 'app/services/school.service';
import { SessionService } from 'app/services/session.service';
import { ShopService } from 'app/services/shop.service';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { ShopItem, ShopItemUser } from 'app/core/shared/state';
import { TopUserScores } from 'app/core/shared/state/models/top-user-scores.model';
import { AvatarShopService } from 'app/services/avatar-shop.service';
import { GamesAndMyZoneConditions } from 'app/core/shared/state/models/User/games-zone-conditions.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-game',
    templateUrl: './game.component.html',
    styleUrls: ['./game.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GameComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    dataLoaded = false;
    availableGames = [];
    selectedGameIndex = null;
    topScoresAula: TopUserScores[] = null;
    topScoresGeneral: TopUserScores[] = null;
    topUserScoreAula = null;
    topUserScoreGeneral = null;
    calling = false;
    tab = 1;
    canPlay = false;
    currentUser
    sessionBeforeGame = false
    cls;
    civilizations
    loading = false;
    disableGames = false;
    GamesConditions: GamesAndMyZoneConditions;
    fileUrl = "https://walinwa.blob.core.windows.net/images/Themes/"
    constructor(
        private authService: AuthService,
        private navigationService: NavigationService,
        private gameService: GameService,
        private shopService: ShopService,
        private activatedRoute: ActivatedRoute,
        private _matDialog: MatDialog,
        private userService: UserService,
        private sanitizer: DomSanitizer,
        private civilizationService: CivilizationService,
        public sharedService: SharedService,
        private avatarShopService: AvatarShopService,
        private _translateService: TranslateService,
        private _schoolService: SchoolService
    ) {
        this._unsubscribeAll = new Subject();
        this.selectedGameIndex = parseInt(this.activatedRoute.params['value'].game)
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    async ngOnInit() {
        if (!this.authService.check()) return
        this.sharedService.changeUserCoins(true)
        if (!this.authService.currentUser.IdClass) {
            this.canPlay = true;
        }
        if (this.avatarShopService.availableItems == null || this.avatarShopService.availableItems.length == 0) {
            this.getAvatarItems();
        }
        this.currentUser = this.authService.currentUser
        this.tab = this.authService.currentUser.IdSchool == null ? 2 : 1
        this.loadCivilizations()
        this.gamesDisabledByTime()
        this.loadBoughtGames()
    }
    loadCivilizations() {
        this.civilizations = this.civilizationService.civilizations
        if (this.civilizationService.civilizations.length == 0) {
            this.civilizationService.getCivilizationsUser(this.authService.currentUser.Id)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(result => {
                    this.civilizations = result;
                })
        }
    }
    gamesDisabledByTime(){
        console.log("entro")
        this.userService.getPlayAndMyZoneActualConditions().subscribe(result => {
            
            this.disableGames = !result.CanPlayGameCondition;
            this.GamesConditions = result;
        })
    }
    sanitize(url) {
        return this.sanitizer.bypassSecurityTrustStyle(url);
    }

    checkIfCanPlay() {
        this.canPlay = false;
        this.loading = true;
        this.gameService.canPlay(this.authService.currentUser.Id, (this.selectedGameIndex + 1))
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.loading = false
                    this.canPlay = result == -1 || Number(result) > 0;
                },
                error => {
                    this.loading = false
                    this.canPlay = false;
                });
    }

    loadBoughtGames() {
        if (!(this.authService.currentUser.OnDemand || this.authService.currentUser.IdSchool == null) && this.authService.currentUser.IdClass)
            this._schoolService.getGameBlock(this.authService.currentUser.IdClass).pipe(takeUntil(this._unsubscribeAll)).subscribe(result => { console.log(result);this.cls = result});

        this.shopService.getBoughtGamesByIdUser(this.authService.currentUser.IdHistoryStage)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                gamesBought => {
                    this.successLoadBoughtGames(gamesBought);
                },
                error => {
                    this.navigationService.goLogin();
                }
            )
    }
    get_numero_posicion(numero, posicion) {
        return numero.toString().charAt(posicion - 1);
    }

    successLoadBoughtGames(gamesBought: ShopItem[]) {
        gamesBought.forEach(game => {
            
            if (game.Enabled) {
                console.log(game);
                if (this.get_numero_posicion(game.IdShopItem, 2) == '0') {
                    //new game
                    this.availableGames.push({ id: this.get_numero_posicion(game.IdShopItem, 1), ver: this.get_numero_posicion(game.IdShopItem, 2), Walinwos: game.Walinwos });
                } else {
                    for (let j = 0; j < this.availableGames.length; j++) {
                        if (this.availableGames[j].id == this.get_numero_posicion(game.IdShopItem, 1)) {
                            this.availableGames[j].ver = this.get_numero_posicion(game.IdShopItem, 2);
                            this.availableGames[j].Walinwos = game.Walinwos;
                        }
                    }
                }
            }
            
        })
        /*
        WaliSpace
        if(!environment.production){
            this.availableGames.push({
                id: 10,
                ver: 3,
                Walinwos: 50
            })
        }
        */
        console.log(this.availableGames);
        if (this.availableGames.length > 0) {
            if (this.tab == 1) {
                this.getTopScoresGameAula()
            } else {
                this.getTopScoresGameGeneral()
            }
        }
        else {
            this.selectedGameIndex = null;
            this.dataLoaded = true;
        }
        this.checkIfCanPlay();
    }

    changeGame(change) {
        if (this.selectedGameIndex + change < 0 || this.selectedGameIndex + change > this.availableGames.length) return;
        if (change > 0 && this.deactivateNextGame()) return;
        this.selectedGameIndex = this.selectedGameIndex + change;
        if (this.authService.currentUser.IdClass) {
            this.checkIfCanPlay();
        }
        if (this.authService.currentUser.IdSchool === null) {
            this.tab = 2;
            this.getTopScoresGameGeneral()
        } else {
            this.tab = 1;
            this.getTopScoresGameAula()
        }
    }

    deactivateNextGame() {
        if (this.availableGames == null) return true;
        if (this.selectedGameIndex + 1 >= this.availableGames.length) return true;
        return false;
    }

    getCurrentGameTitle() {
        if(!this.civilizations[this.selectedGameIndex]){
            return '';
        }
        return this.sanitize("url('" + this.fileUrl + this.civilizations[this.selectedGameIndex].ImgFolder + "/title.png')");
    }

    startGame(index) {
        if (this.calling) return;
        if (this.authService.currentUser.Coins < this.availableGames[this.selectedGameIndex].Walinwos) {
            var message = [];
            message[0] = this._translateService.instant('STUDENT.CHALLENGE.NOT-ENOUGH-WALIS-TITLE');
            message[1] = this._translateService.instant('STUDENT.CHALLENGE.NOT-ENOUGH-WALIS-MESSAGE');
            let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });
            confirmDialog.componentInstance.title = message[0];
            confirmDialog.componentInstance.message1 = message[1];
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
            confirmDialog.componentInstance.margin = false;
            confirmDialog.afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(result => {
                    confirmDialog = null;
                });
            return;
        }
        this.navigationService.go(`student/games/${this.selectedGameIndex}/play`, { gameVersion: this.availableGames[this.selectedGameIndex].ver, gameCost: this.availableGames[this.selectedGameIndex].Walinwos })
    }

    getGameImg() {
        if(!this.civilizations[this.selectedGameIndex]){
            return '';
        }
        if (this.selectedGameIndex == 2) {
            return this.sanitize("url('" + this.fileUrl + this.civilizations[this.selectedGameIndex].ImgFolder + "/game_1.gif')");
        }
        else {
            return this.sanitize("url('" + this.fileUrl + this.civilizations[this.selectedGameIndex].ImgFolder + "/game.gif')");
        }
    }

    reloadUser(result) {
        this.authService.reloadUser()
    }
    getAvatarItems() {
        this.avatarShopService.getAvailableItems()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                this.avatarShopService.availableItems = result;
            })
    }
    getTopScoresGameAula() {
        this.gameService.getTopScoresGameAula(this.authService.currentUser.IdClass, 10, this.selectedGameIndex + 1)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.successCallbackLoadTopScoresAula(result);
                },
                error => {
                    this.navigationService.goLogin();
                }
            )
    }
    getTopScoresGameGeneral() {
        this.gameService.getTopScoresGameGeneral(this.authService.currentUser.IdSchool ?? 0, 10, this.selectedGameIndex + 1)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.successCallbackLoadTopScoresGeneral(result);
                },
                error => {
                    this.navigationService.goLogin();
                }
            )
    }
    successCallbackLoadTopScoreAula(result) {
        this.topUserScoreAula = result;
        this.dataLoaded = true;
    }

    successCallbackLoadTopScoreGeneral(result: any) {
        this.topUserScoreGeneral = result;

        if (this.topUserScoreGeneral.Points == 0 || this.topUserScoreGeneral.Position == 0) {
            this.topUserScoreGeneral = null;
        }
        this.dataLoaded = true;
    }

    successCallbackLoadTopScoresAula(result: TopUserScores[]) {
        this.topScoresAula = result;
        this.loadStudentsActiveItems(result)

        let userInlist = this.topScoresAula.find(x => x.Id == this.authService.currentUser.Id)
        if (userInlist) {
            this.topUserScoreAula = null;
            this.dataLoaded = true;
            return;
        }
        this.gameService.getTopScoreGameAula(this.authService.currentUser.IdClass, this.authService.currentUser.Id, this.selectedGameIndex + 1)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.successCallbackLoadTopScoreAula(result);
                },
                error => {
                    this.navigationService.goLogin();
                }
            )
    }

    successCallbackLoadTopScoresGeneral(result: TopUserScores[]) {
        this.topScoresGeneral = result
        this.loadStudentsActiveItems(result)
        let userInlist = this.topScoresGeneral.find(x => x.Id == this.authService.currentUser.Id)
        if (userInlist) {
            this.topUserScoreGeneral = null;
            this.dataLoaded = true;
            return;
        }
        this.gameService.getTopScoreGameGeneral(this.authService.currentUser.IdSchool ?? 0, this.authService.currentUser.Id, this.selectedGameIndex + 1)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.successCallbackLoadTopScoreGeneral(result);
                },
                error => {
                    this.navigationService.goLogin();
                }
            )
    }

    loadStudentsActiveItems(list: TopUserScores[]) {
        let subItem = null
        return list.forEach(alumno => {
            alumno.activeItems = null;
            subItem = null;
            if (alumno.Items) {
                const itemArray = alumno.Items.split(" // ").filter(Boolean);
                const parseItem = (item: string) => {
                    const [Id, Color] = item.split(" - ").map(val => parseInt(val.trim()));
                    let Type = Math.floor(Id / 100); // Tomar el primer dígito
                    if (Id >= 300 && Id < 400) {
                        let item = this.avatarShopService.availableItems.find(x => x.Id == Id)
                        if (item.SubId) {
                            subItem = {
                                Id: item.SubId,
                                selectedAttribute: { Color },
                                Type: 9
                            }
                        }
                    }
                    if (Id >= 900) {
                        Type = 10
                        if (Id >= 910) {
                            Type = 11;
                        }
                    }
                    return {
                        Id,
                        selectedAttribute: { Color },
                        Type
                    };
                };

                alumno.activeItems = itemArray.map(parseItem);
                if (subItem) {
                    alumno.activeItems.push(subItem)
                }
            }
        });
    }
    changeTab(tab: number) {

        if (tab == 1) {
            this.getTopScoresGameAula()
        }
        else {
            this.getTopScoresGameGeneral()
        }
        this.tab = tab;
    }

}
