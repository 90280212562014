<div class="walinwa-box-modal-overlay">
    <div class="walinwa-box-modal-left" *ngIf="contenedorDictationShow && teacherReview">
        <i class="fa fa-chevron-left" (click)="before()"></i>
    </div>
    <div class="ww_inner_box" fxLayout="column" fxLayoutAlign="stretch">
        <div class="modal_header" [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Dictation/fondo-dictado-header.png?ngsw-bypass=true&quot;)' }" fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-between center" id="dictation-text-box-header">
            <div fxFlex="7rem" fxHide.lt-md>
                <img src="https://walinwa.blob.core.windows.net/images/logo_walinwa_pantallas1.png?ngsw-bypass=true">
            </div>
            <div class="" fxFlex="3rem" *ngIf="!teacherReview && !review" [fxFlexOrder.xs]="review ? 5 : 6">
                <!-- <i class="fa fa-volume-up sonido" (click)="setSonido()"
                    *ngIf="!teacherReview && !review && !repitiendo && !timeBlock && sonido" aria-hidden="true"></i> -->
                <img src="https://walinwa.blob.core.windows.net/images/Dictation/ico-popup-dictado-vol-up.svg?ngsw-bypass=true" class="pointer" fxFlex="3rem" (click)="setSonido()"
                *ngIf="!teacherReview && !review && !repitiendo && !timeBlock && sonido">
                <!-- <i class="fa fa-volume-off sonido" (click)="setSonido()"
                    *ngIf="!teacherReview && !review && !repitiendo && !timeBlock && !sonido" aria-hidden="true"></i> -->
                <img src="https://walinwa.blob.core.windows.net/images/Dictation/ico-popup-dictado-vol-down.svg?ngsw-bypass=true" class="pointer" fxFlex="3rem" (click)="setSonido()"
                *ngIf="!teacherReview && !review && !repitiendo && !sonido">
            </div>
            <div class="dictation-title" fxFlex="" fxFlex.xs="0 0 calc(70% - 4rem)" [fxFlexOrder.xs]="review ? 1 : 7">
                <div [hidden]="!primera_vez && pista != ''" class="">
                    {{'STUDENT.DICTATION.PLAY.DICTATE' | translate }}<br />{{ getFecha() }}
                </div>
                <div [hidden]="primera_vez || pista == ''" class="pista">
                    <span>{{pista}}</span>
                </div>
            </div>
            <div class="nota" *ngIf="review && dictResult" fxFlexOrder.xs="2">
                {{'STUDENT.DICTATION.PLAY.MARK' | translate }}<span>{{ getNota() }}</span>
            </div>
            <div class="errores" *ngIf="review && dictResult" fxFlex.xs="0 0 calc(100%)" ngStyle.xs="text-align: end;" fxFlexOrder.xs="4">
                <span>{{'STUDENT.DICTATION.PLAY.SPELLING-ERRORS' | translate }} {{ getErrorsType(1) }}</span><br>
                <span>{{'STUDENT.DICTATION.PLAY.TYPOGRAPHICAL-ERRORS' | translate }} {{ getErrorsType(2) }}</span>
            </div>
            <div class="amount_walinwos" *ngIf="!teacherReview && !review && !repitiendo" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="7px">
                <img src="https://walinwa.blob.core.windows.net/images/walinwo.gif?ngsw-bypass=true" fxFlex="3rem" />
                <span>{{ walinwos }} {{'STUDENT.DICTATION.PLAY.OF-CONNECTOR' | translate }} {{ getWalinwos() }}</span>
            </div>
            <div [ngStyle.xs]="{'width': !review ? '49%' : ''}" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px" fxLayoutGap.lt-md="5px" fxFlexOrder.xs="3">
                <img *ngIf="!teacherReview && !review" (click)="repetir_fragmento($event)" src="https://walinwa.blob.core.windows.net/images/Dictation/ico-popup-dictado-volver-a-escuchar.svg?ngsw-bypass=true"
                class="pointer" fxFlex="6rem" fxFlex.lt-md="5rem" fxFlex.xs="4rem">
                <img *ngIf="!teacherReview && !review" (click)="saltar_palabra($event)" src="https://walinwa.blob.core.windows.net/images/Dictation/ico-popup-dictado-saltar-palabra.svg?ngsw-bypass=true"
                    class="pointer" fxFlex="6rem" fxFlex.lt-md="5rem" fxFlex.xs="4rem">
                <img (click)="cancel()" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" class="pointer" [ngClass]="{'close_review': review}" fxFlex="2em">
            </div>
        </div>
        <div fxFlex="" class="modal_content" id="dictation-text-box" [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Dictation/fondo-dictado-body.png?ngsw-bypass=true&quot;)' }">
                <div (click)="setFocus(true)" class="dictation-text" id="dictation-text" [ngClass]="{'reviewing':review}">
                    <span [attr.id]="'letter_'+i" *ngFor="let caracter of caracteres; let i = index;"
                        [ngClass]="getCharClass(caracter, i)" [innerHtml]="show(caracter)"></span>
                </div>
                <div class="dictation_author">
                    <span class="dictation-book" [hidden]="book==null || book == ''" [innerHtml]="book+'.'"></span> 
                    <span [innerHtml]="author"></span>
                </div>
        </div>
        <div class="modal_footer" [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Dictation/fondo-dictado-footer.png?ngsw-bypass=true&quot;)' }" [ngClass]="{'noreview': !review}" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="0.7rem">
            <div *ngIf="review"></div>
            <div class="soundError" *ngIf="!review && !teacherReview" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <input type="checkbox" (change)="activatedErrorSound($event.target.checked)" id="errorSound" fxHide />
                <label for="errorSound">{{'STUDENT.DICTATION.PLAY.DISABLE-SOUNDS-ERROR-LABEL' | translate }}</label>
            </div>
            <div class="rules" *ngIf="!teacherReview && !review" fxHide.xs>
                <div [innerHTML]="'STUDENT.DICTATION.PLAY.ERRORS-WARNING' | translate ">
                </div>
                @if(dictSpace){
                    {{'STUDENT.DICTATION.PLAY.DICT-SPACE-WARNING' | translate}}
                }
                
            </div>
            <div class="rules" *ngIf="review">
                <div [innerHTML]="'STUDENT.DICTATION.PLAY.ERRORS-WARNING-REVIEW' | translate ">
                </div>
            </div>
        </div>
    </div>
    <div class="walinwa-box-modal-right" *ngIf="contenedorDictationShow && teacherReview">
        <i class="fa fa-chevron-right" (click)="next()"></i>
    </div>
</div>

<plank-selector title="Dictado terminado" [isFinAnticipado]="isFinAnticipado" [show]="isFin" [showDiploma]="showDiploma" [mark]="getNota()"
    [messages]="messages" [newDictReward]="finishedDictation.HasReward" [extraWalis]="extraWalis" [AddedAvatar]="finishedDictation.AddedAvatar" [walinwos]="walinwos" [errors]="getErrores()" (onClose)="volver() "
    *ngIf="!teacherReview && !review && isFin"></plank-selector>

<form style="position: fixed; top: -99999em; left: -99999em;" autocomplete="off">
    <input (blur)="setFocus(false)" [(ngModel)]="input" autocorrect="off" autocapitalize="off" spellcheck="false" (ngModelChange)="change()"
        [ngModelOptions]="{standalone: true}" autocomplete="off" type="text" id="dictation-input" />
</form>

<!-- <ul style="z-index: 1; position: fixed; top: 0; left: 0; width: 15em; height: 10em; font-size: var(--font-size); background-color: white; overflow: auto;">
    <li *ngFor="let message of debug_messages">{{ message }}</li>
</ul>

<button style="z-index: 1; position: fixed; top: 0; right: 0; font-size: var(--font-size);" (click)="setFocus(true)">ABRIR TECLADO</button>  -->