import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Session } from 'app/core/shared/state/models/session.model';
import { AuthService } from 'app/services/auth.service';
import * as moment from 'moment';
import 'moment/locale/es'

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges {

  @Input() fullDay;
  @Input() currentUser;
  @Input() selectedDay;
  @Input() currentMonth;
  @Input() parent;
  translatedMonth: string = '';
  calendarUser;
  month;
  weeks;
  SessionStatus = {
    finished: "FINISHED",
    started: "STARTED",
    newSession: "NEW",
    generating: "GENERATING",
    notfinished: "NOTFINISHED"
  }

  constructor(private authService: AuthService, private _translateService: TranslateService) {
    moment.locale('es')
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    this.calendarUser = this.currentUser ? this.currentUser : this.authService.currentUser;
    if (this.selectedDay == null || this.selectedDay.clone === "undefined") return;
    let currentDay = this.selectedDay.clone();
    this.month = currentDay.clone();
    var start = currentDay.clone();
    start.date(1);
    this._buildMonth(start, this.month);
    this.replaceMonth()
  }

  selectedDayChanged(date) {
    let currentDay = this.selectedDay.clone();
    this.month = currentDay.clone();
    var start = currentDay.clone();
    start.date(1);
    this.parent.selectedDayChanged(date);
    this.replaceMonth()
  }

  select(day) {
    this.selectedDay = day.date;
    this.selectedDayChanged(this.selectedDay);
  }

  next() {
    var next = this.month.clone();
    this._removeTime(next.month(next.month() + 1)).date(1);
    this.month.month(this.month.month() + 1);
    this.selectedDay = this.month;
    this.selectedDayChanged(this.selectedDay);
  }

  previous() {
    var previous = this.month.clone();
    this._removeTime(previous.month(previous.month() - 1).date(1));
    this.month.month(this.month.month() - 1);
    this.selectedDay = this.month;
    this.selectedDayChanged(this.selectedDay);
  }

  finished(date) {
    if (!this.calendarUser || !this.calendarUser.Sessions)
      return false;
    for (var i = 0; i < this.calendarUser.Sessions.length; i++) {
      var exDate = moment(this.calendarUser.Sessions[i].ExecutionDate);
      if (this.calendarUser.Sessions[i].ExecutionDate && this.calendarUser.Sessions[i].Status == this.SessionStatus.finished &&
        date.isSame(exDate, "day") && !this._isSessionRetriedAndUnfinished(this.calendarUser.Sessions[i]))

        return true;
    }
  }

  private _isSessionRetriedAndUnfinished(session: Session): boolean {
    return (session.RetrieveScore !== null && session.RetrieveScore !== undefined && session.ScoreNew === null) && moment(session.ExecutionDate).diff(moment().utc(), 'days') === 0;
  }

  started(date) {
    if (!this.calendarUser || !this.calendarUser.Sessions)
      return false;
    for (var i = 0; i < this.calendarUser.Sessions.length; i++) {
      var exDate = moment(this.calendarUser.Sessions[i].ExecutionDate);
      if (this.calendarUser.Sessions[i].ExecutionDate && (this.calendarUser.Sessions[i].Status == this.SessionStatus.started || this.calendarUser.Sessions[i].Status == this.SessionStatus.notfinished || this._isSessionRetriedAndUnfinished(this.calendarUser.Sessions[i])) &&
        date.isSame(exDate, "day"))
        return true;
    }
  }

    currentSession(date) {
        if (!this.calendarUser || this.calendarUser.Sessions == null) {
            return false;
        }
        if (this.calendarUser.Sessions.length == 0){
            if(this.calendarUser.Sessions.length == 0 && date.isSame(moment(), "day")){
                return true;
            }
            return false;
        }
        for (var i = 0; i < this.calendarUser.Sessions.length; i++) {
            if (!this.calendarUser.Sessions[i].ExecutionDate && this.calendarUser.Sessions[i].Status == this.SessionStatus.newSession &&
                date.isSame(moment(), "day")) {
                return true;
            }
        }
        let todaySessionNotDone = this.calendarUser.Sessions.some(x => !moment(x.ExecutionDate).isSame(moment(), "day") && date.isSame(moment(), "day"))
        return todaySessionNotDone
    }

  notDone(date) {
    if (!this.calendarUser || !this.calendarUser.Sessions)
      return false;
    if (moment().diff(date, 'days') <= 0)
      return false;
    if (moment(this.calendarUser.CreationDate).diff(date, 'days') > 0)
      return false;

    for (var i = 0; i < this.calendarUser.Sessions.length; i++) {
      var exDate = moment(this.calendarUser.Sessions[i].ExecutionDate);
      if (this.calendarUser.Sessions[i].ExecutionDate &&
        date.isSame(exDate, "day") &&
        this.calendarUser.Sessions[i].Status == this.SessionStatus.finished)
        return false;
    }
    return true;
  }

  isAvailableMonth(date) {
    if (!this.calendarUser)
      return true;
    if (moment(this.calendarUser.CreationDate).diff(date, 'days') > 0)
      return true;
    if ((date.month() > moment().month() - 2 && date.year() === moment().year()) ||
      (date.year() > moment().year()) ||
      (moment().month() == 0 && date.month() == 11 && (date.year() + 1) === moment().year()))
      return true;
    return false;
  }

  _removeTime(date) {
    var a = 0;
    return date.hour(a).minute(a).second(a).millisecond(a);
  }

  _buildMonth(start, month) {
    this.weeks = [];
    var done = false, date = start.clone(), monthIndex = date.month(), count = 0;
    while (!done) {
      this.weeks.push({ days: this._buildWeek(date.clone(), month) });
      date = date.clone();
      date.add(1, "w");
      done = count++ > 2 && (monthIndex !== date.startOf('week').month());
      monthIndex = date.month();
    }
  }

  _buildWeek(date, month) {
    let days = [];
    let dayItereator = date.startOf('week');
    for (let i = 0; i < 7; i++) {      
      days.push({
        name: dayItereator.format("dd").substring(0, 1),
        number: dayItereator.date(),
        isCurrentMonth: dayItereator.month() === month.month(),
        isToday: dayItereator.isSame(new Date(), "day"),
        date: dayItereator,
        month: dayItereator.format("MM"),
        finished: this.finished(dayItereator),
        started: this.started(dayItereator),
        currentSession: this.currentSession(dayItereator),
        notDone: this.notDone(dayItereator),
        disabledMonth: !this.isAvailableMonth(dayItereator)
      });
      dayItereator = dayItereator.clone();
      dayItereator.add(1, "d");
    }
    return days;
  }
    replaceMonth() {
        this._translateService.get('MONTHS').subscribe(months => {
            const monthKeys = Object.keys(months);
            const currentMonthIndex = this.month.month();
            const currentMonthKey = monthKeys[currentMonthIndex];
            this.translatedMonth = months[currentMonthKey];
        });
    }


}
