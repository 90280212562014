import { UserService } from 'app/services/user.service';
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { filter, startWith, takeUntil } from "rxjs/operators";

import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";

import { navigation } from "app/navigation/navigation";
import { NavigationEnd, Router } from "@angular/router";
import { SessionInfo } from "./core/shared/session.model";
import { AuthService as AuthServiceOld } from "./core/guards/auth.service";
import { AppService } from "./app.service";
import { environment } from "environments/environment";
import { UiService } from "@fuse/components/spinner/ui.service";
import { SharedService } from "./core/shared/shared.service";
import { UntypedFormBuilder } from "@angular/forms";
import { VersionService } from "./services/version.service";
import { RepositoryService } from "app/core/repository/repository.service";
import { GlobalService } from "./services/global.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { FuseDialogContinueComponent } from "@fuse/components/dialog-continue/dialog-continue.component";
import { NavigationService } from "./services/navigation.service";
import { AuthService } from "./services/auth.service";
import { OnlineStatusService, OnlineStatusType } from "ngx-online-status";
import { WalinwaUpdateService } from './services/walinwa-update.service';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import config from 'devextreme/core/config'; 
import { licenseKey } from '../devextreme-license';

declare var $;
declare const gtag: Function;

@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    // host: {
    //     '(window:resize)': 'onResize()'
    // }
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    sessionInfo: SessionInfo;
    isValidBrowser = true;
    mobile;
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;
    production = environment.production;
    local = environment.local;
    status: OnlineStatusType;
    userIdBackup;
    isDesktop: boolean = false;

    // Custom
    isMobile: boolean;

    private _unsubscribeAll: Subject<any>;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _router: Router,
        private _authService: AuthServiceOld,
        private _appService: AppService,
        private _ui: UiService,
        private _sharedService: SharedService,
        private _formBuilder: UntypedFormBuilder,
        private _repositoryService: RepositoryService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private versionService: VersionService,
        public globalService: GlobalService,
        private _deviceService: DeviceDetectorService,
        private _matDialog: MatDialog,
        private navigationService: NavigationService,
        private authService: AuthService,
        private onlineStatusService: OnlineStatusService,
        private userService: UserService,
        private _walinwaUpdateService: WalinwaUpdateService
    ) {        
        config({ licenseKey });   

        this._router.events.pipe(filter(event => event instanceof NavigationEnd), startWith(this._router)).subscribe((event: NavigationEnd) => {            
            if (!event.urlAfterRedirects || event.urlAfterRedirects.indexOf("/student/") > -1) {
                this.authService.refreshCurrentUser();

                if (this.authService.currentUser)
                    this.userService.getPlayAndMyZoneConditionsData(this.authService.currentUser.Id);
            }                
        });

        this.onlineStatusService.status.subscribe(
            (status: OnlineStatusType) => {
                if (
                    this.authService.currentUser &&
                    status === OnlineStatusType.ONLINE &&
                    this.authService.currentUser.Id
                ) {
                    this.userIdBackup = this.authService.currentUser.Id;
                }
                if (
                    status === OnlineStatusType.OFFLINE &&
                    this.status != status
                ) {
                    alert("El dispositivo perdió la conexión a Internet.");
                } else if (
                    this.status == OnlineStatusType.OFFLINE &&
                    status == OnlineStatusType.ONLINE
                ) {
                    this.globalService.clientError(
                        "El usuario estuvo sin conexion",
                        this.userIdBackup
                    );
                }
                this.status = status;
            }
        );

        iconRegistry.addSvgIcon(
            "gafas_off",
            sanitizer.bypassSecurityTrustResourceUrl(
                "https://walinwa.blob.core.windows.net/images/gafas1.svg?ngsw-bypass=true"
            )
        );

        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register("main", this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation("main");

        // Add languages
        //this._translateService.addLangs(['es']);

        // Set the default language
        //this._translateService.setDefaultLang('es');

        // Set the navigation translations

        // Use a language
        //this._translateService.use("es");

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this._trackPageViews();
    }

    // onResize() {
    //     this.mobile = this._sharedService.isMobile();
    //     console.log("this.mobile", this.mobile);
    //     this._appService.onResize();
    // }

    getConfig(callback) {
        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        };
        fetch("./assets/config.json?v=" + new Date().getTime(), options).then(
            (result) => {
                result.json().then((json) => {
                    callback(json);
                });
            },
            (error) => {
                callback(null);
            }
        );
    }

    async ngOnInit(): Promise<void> {
        try {
            this.userService.setFingerPrint((await (await FingerprintJS.load()).get()).visitorId);
        } catch { }
        

        this.mobile = this._sharedService.isMobile();

        this.getConfig((config) => {
            if (!config) return;
            let maintenance = config.maintenance || false;
            if (maintenance) this._router.navigateByUrl("maintenance");
        });

        // this.onResize();

        let isDesktop =
            !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );

        this.isDesktop = isDesktop;
        let mobileDetect = this._appService.mobileDetect();

        document.getElementById("prueba1").innerHTML = "Desktop: " + isDesktop;
        document.getElementById("prueba2").innerHTML = mobileDetect.message;

        if (isDesktop && mobileDetect.IsAndroidTablet) {
            this.confirmDialog = this._matDialog.open(
                FuseDialogContinueComponent,
                {
                    disableClose: true,
                }
            );

            this.confirmDialog.componentInstance.title = "Advertencia";
            this.confirmDialog.componentInstance.message1 =
                'Si estás utilizando una tablet es recomendable que desactives la opción "Vista ordenador" en los ajustes de tu navegador, para garantizar un correcto funcionamiento.';
            this.confirmDialog.componentInstance.textButton = "Continuar";
            this.confirmDialog.componentInstance.margin = false;

            this.confirmDialog.afterClosed().subscribe((result) => {
                this.confirmDialog = null;
            });
        }
/*
        // @ts-ignore: Unreachable code error
        String.prototype.allReplace = function (obj) {
            var retStr = this;
            for (var x in obj) {
                retStr = retStr.replace(new RegExp(x, "g"), obj[x]);
            }
            return retStr;
        };*/

        //this.isValidBrowser = this.checkBrowser();

        let isSessionStorageSupported =
            this.globalService.checkIfIsSessionStorageSupported();
        // this.isSessionStorageSupported = isSessionStorageSupported;
        if (isSessionStorageSupported) this.checkVersion();
        // this.onResize();
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
                this._appService.changeFuseConfig(
                    this._authService.loggedIn(isSessionStorageSupported)
                );

                // Boxed
                if (this.fuseConfig.layout.width === "boxed") {
                    this.document.body.classList.add("boxed");
                } else {
                    this.document.body.classList.remove("boxed");
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith("theme-")) {
                        this.document.body.classList.remove(className);
                    }
                }

                if (!this._authService.loggedIn(isSessionStorageSupported)) {
                    if (
                        !location.href.includes("free-trial-educational") &&
                        !location.href.includes("free-trial-particular") &&
                        !location.href.includes("maintenance") &&
                        //!location.href.includes("questionaire") &&
                        !location.href.includes("resetpassword") &&
                        !location.href.includes("redirection") &&
                        !location.href.includes("demosm") &&
                        !location.href.includes("test") &&
                        !location.href.includes("loginApprender")
                    ) {
                        var href = window.location.href;

                        this._router.navigate(["./login"]);

                        if (href.indexOf("free-trial") > -1)
                            this._router.navigate(["./free-trial"]);
                    }
                }
                sessionStorage.setItem(
                    "isProd",
                    environment.production ? "true" : "false"
                );
                sessionStorage.setItem("gtag", environment.gtag);
                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
        // this._router.events.subscribe(event => {
        //     if (event instanceof NavigationEnd) {
        //         this.onResize();
        //     }
        //     });

        // Call the detectZoom function
        // this.isMobile = this.detectMobile();
        // // console.log("Is mobile:", this.isMobile);
        // if (this.isMobile == false) {
        //     this.detectZoom();
        // }
    }

    detectMobile(): boolean {
        const userAgent = navigator.userAgent.toLowerCase();
        return /mobile|android|iphone|ipad|iemobile|wpdesktop/i.test(userAgent);
    }

    isPortrait() {
        return window.innerHeight > window.innerWidth;
    }

    checkBrowser() {
        if (this._deviceService.browser == "IE") return false;
        return true;
    }

    checkVersion() {
        //console.log("entro")
        //this.globalService.versionError = false;
        this.versionService.getVersion()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
            (result: any) => {
                //let v = result.Version.split(";")[0];
                //let e = result.Version.split(";")[1];
                if (result.Version.includes("Maintenance")) {
                    this._router.navigateByUrl("maintenance");
                }/* else if (v != environment.version) {
                    this.globalService.versionError = true;
                    let cReload = 1;
                    if (sessionStorage.getItem("cReload")) {
                        cReload = parseFloat(sessionStorage.getItem("cReload"));
                    }
                    if (cReload <= 3) {
                        sessionStorage.setItem("cReload", (cReload + 1).toString());
                        window.location.reload();
                    } else {
                        sessionStorage.removeItem("cReload");
                    }
                } else {
                    sessionStorage.removeItem("cReload");
                }*/
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    _trackPageViews(): void {
        this._router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                gtag("event", "page_view", {
                    page_title:
                        document.title + " - " + event.urlAfterRedirects,
                    page_path: event.urlAfterRedirects,
                });
            });
    }

    // Detect zoom level - Not erase, created and dissable by Leo
    // detectZoom() {
    //     const zoomLevel = window.devicePixelRatio;
    //     console.log("Zoom level:", zoomLevel);

    //     // Add your custom logic based on the zoom level
    //     if (zoomLevel >= 2) {
    //         console.log("zoom in");
    //         $(":root").css("--font-size", "0.75rem");
    //     } else if (zoomLevel >= 1.5) {
    //         console.log("zoom in two");
    //         $(":root").css("--font-size", "0.8rem");
    //     } else {
    //         console.log("normal");
    //     }
    // }
}
