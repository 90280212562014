import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { TranslateService } from '@ngx-translate/core';
import { CalHearingTextComponent } from 'app/components/cal-hearing-text/cal-hearing-text.component';
import { SharedService } from 'app/core/shared/shared.service';
import { CalendarHearingText } from 'app/core/shared/state/models/Hearing-text/hearing-text-month.model';
import { AuthService } from 'app/services/auth.service';
import { ClassService } from 'app/services/class.service';
import { HearingTextService } from 'app/services/hearing-text.service';
import { NavigationService } from 'app/services/navigation.service';
import { SchoolService } from 'app/services/school.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
declare var $;
@Component({
  selector: 'app-hearing-text',
  templateUrl: './hearing-text.component.html',
  styleUrls: ['./hearing-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HearingTextComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    @ViewChild('template', { static: true }) template: TemplateRef<any> | null = null;
    @ViewChild(CalHearingTextComponent) child: CalHearingTextComponent;
    currentUser: any;
    selectedDay: any;
    currentMonth: number;
    currentYear: number;
    hearingTexts: CalendarHearingText[] = null;
    six_weeks: boolean;
    canDoIt: boolean;

    isMobile: boolean;
    timeBlock: boolean;
    loading: boolean;
    isFuture: boolean = false;
    public hideComponent: boolean = false;

    constructor(
        private authService: AuthService,
        private navigationService: NavigationService,
        private hearingTextService: HearingTextService,
        private router: Router,
        private ref: ChangeDetectorRef,
        private _matDialog: MatDialog,
        private schoolService: SchoolService,
        private sharedService: SharedService,
        private classService: ClassService,
        private _translateService: TranslateService,
        private _activatedRoute: ActivatedRoute
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        if (!this.authService.check()) return;
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => this.hideComponent = this._activatedRoute.firstChild !== null);
        this.sharedService.hearing_text_enabled
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(hearingTextEnabled => {
            if (!hearingTextEnabled) {
                this.navigationService.go("student/session");
            }
        })
        this.currentUser = this.authService.currentUser;
        this.selectedDay = moment();
        this.selectedDay.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        this.currentMonth = this.selectedDay.month() + 1;
        this.currentYear = this.selectedDay.year();
        this.getHearingTextByMonth();
        this.hearingTextService.reload_monthly_hearing
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                if (result) {
                    this.getHearingTextByMonth();
                }
        })

        this.isMobile = this.detectMobile();
        this.ref.detectChanges();
    }

    detectMobile(): boolean {
        const userAgent = navigator.userAgent.toLowerCase();
        return /mobile|android|iphone|ipad|iemobile|wpdesktop/i.test(userAgent);
    }

    sixWeeks() {
        return $('.week').length - 1 >= 6;
    }

    async selectedDayChangedCA(newDate, canDoIt) {
        this.selectedDay = newDate;
        if (this.selectedDay.format() < this.authService.currentUser.CreationDate && !this.authService.currentUser.UserName.includes(".tmp.")) {
            this.canDoIt = false;
        }
        else {
            this.canDoIt = true;
        }
        if (this.authService.currentUser.IsAdmin) {
            this.isFuture = false;
        } else {

            if (moment().utc().isSameOrBefore(this.selectedDay, 'day')) //today or future
                this.isFuture = true;
            else if (moment(this.authService.currentUser.CreationDate).diff(this.selectedDay, 'days', true) > 0)
                this.isFuture = true;
            else if (!this.finished() && moment().utc().month() - this.selectedDay.month() > 1)
                this.isFuture = true;
            else
                this.isFuture = false;
        }
        this.finished();
        if (this.currentMonth != this.selectedDay.month() + 1) {
            this.currentMonth = this.selectedDay.month() + 1;
            this.currentYear = this.selectedDay.year();
            if (this.child) this.six_weeks = this.child.weeks.length >= 6
            this.getHearingTextByMonth();
        }
    }

    getHearingTextByMonth() {
        if (!this.loading) {
            this.loading = true;
            //this.loading = true;
            //this.hearingTexts = [];
            //this.ref.detectChanges();
            this.hearingTextService.getHearingTextByMonth(this.authService.currentUser.Id, this.currentMonth, this.currentYear, this.authService.currentUser.Level, this.authService.currentUser.IdRole, this.authService.currentUser.CreationDate)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    result => {
                        if (!result || !Array.isArray(result)) return;
                        this.loading = false;
                        this.hearingTexts = result
                        this.hearingTextService.MonthlyHearing = result;
                        this.finished();
                        this.ref.detectChanges();
                        this.child.refresh((weeks_count) => {
                            this.six_weeks = weeks_count >= 6
                        })
                    },
                    error => {
                        this.hearingTexts = [];
                        this.loading = false;
                    }
                )
        }
    }

    play(review: boolean) {
        //this.fixPositionScroll_vertical_Class();
        this.loading = true;
        let currentHearingText = this.hearingTexts.find((r) => { return moment(r.date).isSame(this.selectedDay) });
        if (!currentHearingText) {
            let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });
            confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.START-ACTIVITY-TITLE');
            confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.HEARING-TEXT.CANT-RECOVER-HEARING-FROM-DATE');
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
            confirmDialog.componentInstance.margin = false;
            confirmDialog.afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(result => {
                    this.router.navigate(['/student/hearing-text']);
                    this.loading = false;
                    confirmDialog = null;
                });
            return;
        }
        if (this.authService.currentUser.IdClass) {
            this.checkTimeBlock(currentHearingText, review);
        }
        else {
            this.navigationService.go("student/hearing-text/" + (review ? "review" : "play") + "/" + currentHearingText.IdHearingText + "/" + currentHearingText.date, { review: review });
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    }

    canPlay() {
        if (!this.hearingTexts) return false;
        let currentHearingText = this.hearingTexts.find((r) => { return moment(r.date).isSame(this.selectedDay) });
        let canPlay = currentHearingText != null;
        let futuro = false;
        if (this.authService.currentUser.IsAdmin) {
            futuro = false;
        }
        else {
            if (moment().diff(this.selectedDay, 'days', true) < 0) //today or future
                futuro = true;
            else if (moment(this.authService.currentUser.CreationDate).diff(this.selectedDay, 'days', true) > 0)
                futuro = true;
            else if (!this.finished() && moment().month() - this.selectedDay.month() > 1)
                futuro = true;
            else
                futuro = false;
        }
        return canPlay && !futuro;
    }

    finished() {
        if (!this.hearingTexts) return false;
        let currentHearingText = this.hearingTexts.find((r) => { return moment(r.date).isSame(this.selectedDay) });
        if (!currentHearingText || currentHearingText.Score == null) return false;
        return currentHearingText.FinishDate;
    }

    started() {
        if (!this.hearingTexts) return false;
        let currentHearingText = this.hearingTexts.find((r) => { return moment(r.date).isSame(this.selectedDay) });
        if (!currentHearingText) return false;
        return currentHearingText.TextDate != null
    }

    checkTimeBlock(currentHearingText: any, review: boolean) {
        this.timeBlock = false
        this.classService.canExecuteHearingText(this.authService.currentUser.IdClass)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.timeBlock = !result.canStart
                if(this.timeBlock){
                    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                        disableClose: true
                    });
                    confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.ACTIVITY-BLOCKED');
                    confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.HEARING-TEXT.DISABLED-NOW', {initTime: result.TimeIni.substr(0,5), endTime: result.TimeEnd.substr(0,5)})
                    confirmDialog.componentInstance.theme = "white";
                    confirmDialog.componentInstance.margin = false;
                    confirmDialog.componentInstance.options = [
                        {
                            text: this._translateService.instant('ACCEPT'),
                        }
                    ];
                    confirmDialog.componentInstance.margin = false;
                    confirmDialog.afterClosed()
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(result => {
                            this.loading = false;
                            confirmDialog = null;
                        });
                }
                else {
                    this.navigationService.go("student/hearing-text/" + (review ? "review" : "play") + "/" + currentHearingText.IdHearingText + "/" + currentHearingText.date, { review: review });
                        setTimeout(() => {
                            this.loading = false;
                        }, 1000);
                }
            },() => {
                this.loading = false;
            })
    }
    /*fixPositionScroll_vertical_Class() {
        const elements = document.querySelectorAll('.iphonechromepopup');
        elements.forEach((element) => {
            // (element as HTMLElement).style.overflow = 'inherit';
            element.classList.add("popup");
        });
        //console.log("popup scroll_fixed");
    }*/

}