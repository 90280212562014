import { ShopItem } from './../../core/shared/state/models/shop-item.model';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewChecked } from '@angular/core';
import { SharedService } from 'app/core/shared/shared.service';
import { AuthService } from 'app/services/auth.service';
import { AvatarShopService } from 'app/services/avatar-shop.service';
import { ShopService } from 'app/services/shop.service';
import { combineLatest, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'plank-selector',
  templateUrl: './plank.component.html',
  styleUrls: ['./plank.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlankComponent implements OnInit, AfterViewChecked {
  private _availableItems = [];
  private _plankClass: string = null;

  @Input() diplomaImageUrl: string;
  @Input() title = this._translateService.instant('COMPONENTS.PLANK.EXERCISE-FINISHED');
  @Input() showDiploma = false;
  @Input() mark = 0;
  @Input() walinwos = 0;
  @Input() errors = 0;
  @Input() show = false;
  @Input() newScenary = false;
  @Input() newDictReward = false;
  @Input() AddedAvatar = false;
  @Input() messages = [];
  @Input() isFinAnticipado = false;
  @Input() extraWalis = 0;
  @Input() newItemId: number = null
  @Output() onClose = new EventEmitter<string>();
  penalizado: boolean;
  walinwosMessage;
  isCommon = false;
  selectedPlank: string = ""
  fileUrl = "https://walinwa.blob.core.windows.net/images/Themes/"
  activeItems = null;
  public imagesLoaded: boolean = false;
  public plankClass: string = '';
  public plankBackground: any = '';

  constructor(
    private shopService: ShopService,
    private authService: AuthService,
    private avatarShopService: AvatarShopService,
    public _sharedService: SharedService,
    private _translateService: TranslateService,
    private _detector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    /*if (this.newDictReward && !this.AddedAvatar) {
      this.GetActiveItems()
    }
    this.getItems();*/

    combineLatest([
      this.shopService.getBoughtByIdUser(this.authService.currentUser.Id),
      this.newDictReward && !this.AddedAvatar ? this.avatarShopService.GetActiveItemsByIdUser(this.authService.currentUser.Id) : of(null)
    ]).pipe(take(1)).subscribe(data => {
      this._availableItems = data[0].sort((a, b) => b.IdShopItem - a.IdShopItem);
      this.activeItems = data[1];

      if(this.newItemId != null && this.newItemId != 922){
          let itemText = this._availableItems.find(x => x.IdShopItem == this.newItemId)?.ShopItem.Description;
          this.messages[0]+= itemText;
      }

      this._getPlankClass();
      this._getPlankBackground();
    });
  }

  ngAfterViewChecked(): void {
    if (this.imagesLoaded && this.plankClass === '' && this._plankClass !== null) {
      this.plankClass = this._plankClass;
      this._plankClass = null;
      
      setTimeout(() => this._detector.detectChanges(), 1);
    }
  }

  close() {
    this.onClose.emit();
  }

  /*getItems() {
    this.shopService.getBoughtByIdUser(this.authService.currentUser.Id).subscribe(
      (result: any) => {
        this._availableItems = result.sort((a, b) => b.IdShopItem - a.IdShopItem);

        if(this.newItemId != null && this.newItemId != 922){
            let itemText = this._availableItems.find(x => x.IdShopItem == this.newItemId)?.ShopItem.Description;
            this.messages[0]+= itemText;
        }

        this._getPlankClass();
        this._getPlankBackground();
      })
  }

  GetActiveItems(): void {
    this.avatarShopService.GetActiveItemsByIdUser(this.authService.currentUser.Id)
      .subscribe((result) => {
        this.activeItems = result;
      })
  }*/

  private _getPlankClass() {    
    this.messages.forEach(element => {
        if(element.includes('Se ha penalizado el resultado de tu nota por haber accedido mÃ¡s veces al ejercicio.')){
          return this.penalizado = true;
        }else{
          return this.penalizado = false;
        }
      })
    let classes = ["walinwa-plank"];
    if (this.show) classes.push("walinwa-plank-down");
    if (this.newScenary || this.newDictReward) {
      classes.push("walinwa-plank-common-new-scenary");
    } 
    // else if (this.isMobile()) {
    //     classes.push("walinwa-plank-common");
    //     this.isCommon = true;
    // }
    else {
      let tablon = this._availableItems.find(a => a.ShopItem.Type == 9 && a.Status == 1);

      if (tablon && (tablon.ShopItem.IdCivilization == this.authService.currentUser.IdCivilizacion || tablon.ShopItem.IdCivilization == 9)) {
        classes.push("walinwa-plank-custom");
        let idTablon = tablon.ShopItem.IdShopItem + ""
        if(idTablon.includes("91") ){
            classes.push("tablon_pizarra");
        }
        else {
            classes.push("tablon_" + tablon.ShopItem.IdShopItem);
        }
        
        this.selectedPlank = tablon.ShopItem.Description;
        this.isCommon = false;
      } else {
        classes.push("walinwa-plank-common");
        this.isCommon = true;
      }
    
    }

    this._plankClass = classes.join(" ");
  }

  private _getPlankBackground() {
      let imagesToLoad = [];
      let backgroundImages = [];
      let tablon = this._availableItems.find(a => a.ShopItem.Type == 9 && a.Status == 1);

      if (!tablon) {
          this.imagesLoaded = true;
          this._detector.detectChanges();

          return;
      }

      let idTablon = tablon.ShopItem.IdShopItem + ""

      if (tablon && (tablon.ShopItem.IdCivilization == this.authService.currentUser.IdCivilizacion || tablon.ShopItem.IdCivilization == 9)) {
          if (tablon.ShopItem.IdCivilization == 9) {
              let url = this.fileUrl + "Special/" + tablon.ShopItem.IdShopItem + ".png?ngsw-bypass=true"
              backgroundImages.push("url(" + url + ")");
              imagesToLoad.push(url);
          } else {
              let url = this.fileUrl + tablon.ShopItem.Civilization.ImgFolder + "/shop/" + tablon.ShopItem.IdShopItem + ".png?ngsw-bypass=true";
              if (idTablon.includes("91")) {
                  url = this.fileUrl + "091.png?ngsw-bypass=true"
              }
              backgroundImages.push("url(" + url + ")");
              imagesToLoad.push(url);
          }
      }

      if (this.newScenary || this.newDictReward) {
          backgroundImages.push("url(https://walinwa.blob.core.windows.net/images/confeti.gif?ngsw-bypass=true");
          imagesToLoad.push('https://walinwa.blob.core.windows.net/images/confeti.gif?ngsw-bypass=true');
      }

      this.plankBackground = { "background-image": backgroundImages.join(", ") };

      if (imagesToLoad.length > 0) {
        combineLatest(imagesToLoad.map(i => this._imageLoaded(i))).pipe(take(1)).subscribe(_ => {
          this.imagesLoaded = true;
          this._detector.detectChanges();
        });
      } else {
        this.imagesLoaded = true;
        this._detector.detectChanges();
      }

      // Unir todas las imágenes de fondo en una sola cadena
      /*return {
          "background-image": backgroundImages.join(", ")
      };*/
  }

  isMobile(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|android|iphone|ipad|iemobile|wpdesktop/i.test(userAgent);
  }

  getDiplomaImage() {
    if (this.diplomaImageUrl)
      return "https://walinwa.blob.core.windows.net/images/Diploma/" + this.diplomaImageUrl + "?ngsw-bypass=true";
    else if (this.extraWalis > 0) 
      return "https://walinwa.blob.core.windows.net/images/Diploma/diploma" + this.extraWalis + ".png" + "?ngsw-bypass=true";
  }

  isInArray(txt) {
    return this.messages.some(x => x.includes(txt));
  }

  private _imageLoaded(url: string): Observable<boolean> {
    return new Observable(observer => {
      const img = new Image();

      img.src = url;
      
      img.onload = () => {
        observer.next(true);
        observer.complete();
      };
      
      img.onerror = () => {
        observer.next(false);
        observer.complete();
      };
    });
  }
}
