import { ExerciseService } from 'app/services/exercise.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation, HostListener, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { AuthService } from 'app/services/auth.service';
import { GlobalService } from 'app/services/global.service';
import { NavigationService } from 'app/services/navigation.service';
import { SessionService } from 'app/services/session.service';
import * as moment from 'moment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'app/services/user.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
declare var $;

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SessionComponent implements OnInit, OnDestroy  {
    private _unsubscribeAll: Subject<any>;
    allowContinue;
  currentUser;
  selectedDay;
  currentMonth;
  calling = false;
  NotDone = 0;
  Started = 3;
  Finished = 1;
  Pending = 2;
  NotFinished = 4;
  isLoadingSession = true;
  ToRecover = 5;
  todaySessionFailed = false;
  coin = 0;
  dataLoaded = false;
  showReview = false;
  generatingSession = false;
  currentSessionStatus = null;
  month;
  year;
  weeks
  currentSession
  nextSessionToStart
  reto
  isProd = environment.production;
  SessionStatus = { finished: "FINISHED", started: "STARTED", newSession: "NEW", generating: "GENERATING", notfinished: "NOTFINISHED" };

  isMobile: boolean;
  private _selectedDayAtStartNextSession: moment.Moment;

  private _canRetrySession: boolean = false;
  _CanSkipWeekendSession: boolean = false;
    day: number;
  @HostListener("window:resize", ["$event"])
    onResize(event) {
        
      // 
        
    }

  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private sessionService: SessionService,
    private _matDialog: MatDialog,
    private globalService: GlobalService,
    private exerciseService: ExerciseService,
    private _userService: UserService,
    private _translateService: TranslateService,
    private _detector: ChangeDetectorRef
  ) {
    this._unsubscribeAll = new Subject();
}

ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  async ngOnInit() {
    if (!this.authService.check()) return;
    this.isMobile = this.detectMobile();
    this.calling = true;
    this.currentUser = this.authService.currentUser;
    this.selectedDay = moment();
    this.currentMonth = this.selectedDay.month() + 1;
    this.month = this.selectedDay.month() + 1;
    this.year = this.selectedDay.year();
    this.weeks = this.getWeekNums();
    this.day = moment().day();
    this.reto = false;
    this._detector.detectChanges();
    this.hasExerciseToSave();
    this._canRetrySession = await this._userService.canRetrySession(this.currentUser.Id).toPromise();
    this._CanSkipWeekendSession = await this._userService.canSkipWeekendSession(this.currentUser.Id).toPromise();
    console.log((this._CanSkipWeekendSession && (this.day == 6 || this.day == 0)));
    let forceWeekend = await this._userService.forceWeekend().toPromise();
    if(forceWeekend){
        this.day = 0
    }
    this.loadSessionMonth();
  }

  detectMobile(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|android|iphone|ipad|iemobile|wpdesktop/i.test(userAgent);
  }

  hasExerciseToSave(){
    let exercise = sessionStorage.getItem("jump-exercise")
    if(exercise) {
        let exerc = JSON.parse(exercise)
        if (exerc.NumElementsERR > 0) {
            exerc.Result = "ERR-JMP";
        } else if (
            exerc.NumElementsOK > 0
        ) {
            exerc.Result = "OK-JMP";
        } else {
            exerc.Result = "JMP";
        }
        if(exerc.IdReto){
            this.exerciseService.updateExercReto(exerc, this.authService.currentUser.IsAdmin)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe();
        }else {
            this.exerciseService.updateExercise(exerc, this.authService.currentUser.IsAdmin)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe();
        }
        sessionStorage.removeItem("jump-exercise")
    }
  }
  sixWeeks() {
    return $('.week').length - 1 >= 6;
  }

  selectedDayChanged(newDate, canDoIt) {
      this.selectedDay = newDate;
      console.log(newDate);
    if (this.month != this.selectedDay.month() + 1) {
      this.calling = true;
      this.month = this.selectedDay.month() + 1;
      this.year = this.selectedDay.year();
      this.weeks = this.getWeekNums();
      this.loadSessionMonth();
      return;
    }
    this.loadCurrentSession();
  }

  contarCaracteres = function (str) {
    return this.getCleanedString(str).length;
  }
  perfectSession(){
    if(this.currentSession == null || this.currentSession.Score == null){
        return false;
    }
    if(this.currentSession.Score == 10 || this.currentSession.RetrieveScore == 10){
        return true;
    }
  }
  getWeekNums() {
    var date = moment(this.selectedDay);
    var dateFirst = moment(date).date(1)
    var dateLast = moment(date).date(date.daysInMonth())
    var startWeek = dateFirst.week()
    var endWeek = dateLast.week()
    if (endWeek < startWeek) {
      return dateFirst.weeksInYear() - startWeek + 1 + endWeek
    } else {
      return endWeek - startWeek + 1
    }
  }

  async successCallbackCanStart(result) {
    if (this.navigationService.params.session) {
      this.calling = true;
      this.currentSession = result.session;
        if (this.currentSession.Status == this.SessionStatus.finished) {
            this.log();
            return;
        }
      this.currentSession.ExecutionDate = this._selectedDayAtStartNextSession.hour(moment().hours());
      this.currentSession.Status = this.SessionStatus.started;
      this.sessionService.updateSession(this.currentSession, this.authService.currentUser.OnDemand || this.authService.currentUser.IsAdmin)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.successCallbackUpdateSession(result);
        },
        error => {
          this.errorCallbackUpdateSession(error);
        }
      )
      return;
    }
    if (result && result.status == "START" && result.session != null) {
      if (this._selectedDayAtStartNextSession != null) {
        this.currentSession = result.session;
        if (this.currentSession.Status == this.SessionStatus.finished) {
            this.log();
            return;
        }
        this.reto = false;
        this.nextSessionToStart = null;
          this.calling = true;
          this.currentSession.ExecutionDate = moment(this._selectedDayAtStartNextSession).add(moment(this._selectedDayAtStartNextSession).utcOffset(), 'minutes');
        this.currentSession.Status = this.SessionStatus.started;
        this.sessionService.updateSession(this.currentSession, this.authService.currentUser.OnDemand || this.authService.currentUser.IsAdmin)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
          result => {
            this.successCallbackUpdateSession(result);
          },
          error => {
            this.errorCallbackUpdateSession(error);
          }
        )
        return;
      }
      // } else if (this.currentSession.Status != 'NEW') {
      //   this.calling = false;
      //   this.loadSessionMonth();
    } else {
      if (this._selectedDayAtStartNextSession != null) {
        this.calling = true;
        if (this.currentSession.Status == this.SessionStatus.finished) {
            this.log();
            return;
        }
        this.currentSession.ExecutionDate = this._selectedDayAtStartNextSession.hour(moment().hours());
        this.currentSession.Status = this.SessionStatus.started;
        this.sessionService.updateSession(this.currentSession, this.authService.currentUser.OnDemand || this.authService.currentUser.IsAdmin)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
          result => {
            this.successCallbackUpdateSession(result);
          },
          error => {
            this.errorCallbackUpdateSession(error);
          }
        )
      }
    }
  }

  errorCallbackCanStart(error, clicked = false) {
    if(!clicked)return;
    var message = [];
    message[0] = this._translateService.instant('STUDENT.SESSION.ERROR-CAN-START-TITLE');
    if (error.error == "NoSession") {
      message[1] = this._translateService.instant('STUDENT.SESSION.ERROR-NO-SESSION-MESSAGE');
    }
    else if (error.error == "Future") {
      message[1] = this._translateService.instant('STUDENT.SESSION.ERROR-FUTURE-MESSAGE');
    }
    else if (error.error == "TodayFirst") {
      message[1] = this._translateService.instant('STUDENT.SESSION.ERROR-TODAY-FIRST-MESSAGE');
    }
    else if (error.error == "JustThree") {
      message[1] = this._translateService.instant('STUDENT.SESSION.ERROR-JUST-THREE-MESSAGE');
    }
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      this.calling = false;
      confirmDialog = null;
    });
    return;
  }

  continueSession = function () {
    // $state.go('exercise.modelCommon');
    // this.navigationService.goJS("exercise.modelCommon", { session: this.currentSession })
    this.navigationService.go("student/session/play", { session: this.currentSession })
  }

  startReview = function () {
    this.navigationService.go("student/session/play", { session: this.currentSession, errors: false })
  }

  startReviewErrors = function () {
    this.navigationService.go("student/session/play", { session: this.currentSession, errors: true })
  }

    loadSessionMonth() {
        
    this.sessionService.getSessionByUserMonthYear(this.authService.currentUser.Id, this.month, this.year)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successLoadSessionMonth(result);
      },
      error => {
        this.errorCallback();
      }
    )
    this.currentMonth = 0;
  }

  successLoadSessionMonth(result) {
    this.authService.currentUser.Sessions = result;
    this.calling = false;
    this.currentMonth = this.month; //0 index month
    this.currentUser = this.authService.currentUser
    this.generatingSession = false;
    this.loadCurrentSession();
  }
  

  loadCurrentSession() {
    this.showReview = false;
    this.allowContinue = false;
    if (this.selectedDay === null) {
      if (!this.dataLoaded) this.dataLoaded = true;
      return;
    }

    this.currentSessionStatus = null;
      this.currentSession = null;
    for (var i = 0; i < this.currentUser.Sessions.length; i++) {
      if (this.selectedDay.isSame(moment(this.currentUser.Sessions[i].ExecutionDate), "day")) {
        //Session already started
            this.successLoadSession(this.currentUser.Sessions[i])
            return;
      }
      else
        if (!this.currentUser.Sessions[i].ExecutionDate && moment().diff(this.selectedDay, 'days', true) > 0) {
          //Session not done but just for today
            this.successLoadSession(this.currentUser.Sessions[i])
            return;
        }
    }
    if (moment().month() - this.selectedDay.month() < 2 && this.selectedDay >= moment(this.currentUser.CreationDate) && !moment(this.selectedDay).isSame(moment(),"day"))
      this.currentSessionStatus = this.ToRecover;
    if (moment().diff(this.selectedDay, 'days', true) < 0) {
      this.currentSessionStatus = 0;
    }
    if(moment(this.selectedDay).isSame(moment(),"day")){
        this.currentSessionStatus = this.Pending
    }

    this.isLoadingSession = false;
  }
  startButtonHidden() : boolean {
    return this.IsLoadingSession() || this.currentSessionStatus != this.Pending;
  }
  recoveryButtonHidden() : boolean {
    return this.IsLoadingSession() || this.currentSessionStatus != this.ToRecover;
  }

  FinishSessionButtonHidden() : boolean {
    return this.IsLoadingSession() || (this.currentSessionStatus != this.Started && !this.allowContinue)
  }

  FinishRetriedSessionButtonHidden() : boolean {
    return this.IsLoadingSession() || (this.currentSessionStatus != this.Started && !this.allowContinue && !this.isRetrySessionAvailable()) || this.currentSession.RetrieveScore === null || this.currentSession.RetrieveScore === undefined;
  }

  IsLoadingSession(): boolean {
    return this.isLoadingSession || this.currentSessionStatus == null || this.calling
  }
    successLoadSession(result) {
    this.calling = false;
    console.log("success load session", result)
    this.isLoadingSession = true;
    this.currentSessionStatus = this.Pending;
    this.currentSession = result;
    if (this.selectedDay != null && this.selectedDay.month() === moment().month() && this.selectedDay.date() === moment().date()) {
      this.authService.todaySessionStarted = true; //ojo
    }
    if (this.currentSession.Status === this.SessionStatus.started) {
      if (this.selectedDay != null && moment().diff(this.selectedDay, 'days', true) < 1) {//today or future
        this.currentSessionStatus = this.Started;
        this.showReview = false;
      } else {
        this.currentSessionStatus = this.NotFinished;
        if (this.selectedDay != null && this.selectedDay >= moment().add(-1, 'months').startOf('month'))
          this.allowContinue = true;
        else
          this.allowContinue = false;
      }
    }
    else if (this.currentSession.Status == this.SessionStatus.notfinished) {
      this.currentSessionStatus = this.NotFinished;
      if (this.selectedDay >= moment().add(-1, 'months').startOf('month'))
        this.allowContinue = true;
      else
        this.allowContinue = false;
    }
    else if (this.currentSession.Status === this.SessionStatus.finished) {
        this.currentSessionStatus = this.Finished;

        if ((this.mustHideRetryButton() && !this.isRetrySessionAvailable())) {
          this.showReview = true;
        } else {
          this.showReview = false;
        }
      if (this.selectedDay) {
        if (this.selectedDay.month() === moment().month() && this.selectedDay.date() === moment().date()) {
          this.authService.todaySessionStarted = true;
        }
      }
    }
    else if (this.currentSession.Status === this.SessionStatus.newSession) {
      this.showReview = false;
      if (moment().diff(this.selectedDay, 'days', true) < 0) {
        this.currentSessionStatus = null;
      }
      if (this.selectedDay != null &&
        this.selectedDay.month() === moment().month() &&
        this.selectedDay.date() === moment().date()) {
        this.currentSessionStatus = this.Pending;
        this.authService.todaySessionStarted = false;
      }
      else {
        //Session in the past not done
        if (this.selectedDay != null &&
          moment().month() - this.selectedDay.month() < 2 &&
          this.selectedDay >= moment(this.authService.currentUser.CreationDate)) {

          this.currentSessionStatus = this.ToRecover;
        }
        else if(!this.authService.currentUser.UserName.includes(".tmp.")) {
          this.currentSessionStatus = null;
        }
      }

    }
    this.isLoadingSession = false;
    this.dataLoaded = true;
    let substring = ".tmp.";
    let demoUser = this.authService.currentUser.UserName.indexOf(substring) !== -1;
    if (demoUser && this.currentSessionStatus === this.Pending) this.startNextSession();
  }

  startNextSession(clicked = false) {
    if (this.calling) return
    this.reto = false;
    if (this.authService.currentUser != null && this.selectedDay != null) {
      this.calling = true;
      this._selectedDayAtStartNextSession = this.selectedDay;
      this.sessionService.canStartSession(this.authService.currentUser.Id, moment(this._selectedDayAtStartNextSession).hour(moment().hours()).format(), clicked)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.successCallbackCanStart(result);
        },
        error => {
          this.errorCallbackCanStart(error, clicked);
        }
      )
    }
  }

    successCallbackUpdateSession(result) {
        setTimeout(() => {
            this.calling = false;
        }, 1000);
        // this.navigationService.goJS("exercise.modelCommon", { session: this.currentSession })
        this.navigationService.go("student/session/play", { session: this.currentSession })

    }

  errorCallback() {
    this.calling = false;
    this.navigationService.goLogin();
  }
    errorCallbackUpdateSession(error: any) {
        this.errorCallback();
    }
    sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  log(){
    this.globalService.clientError("updateSession, se intento de empezar una sesion que ya estaba finalizada, IdSession:" + this.currentSession.IdSession + "StartDate: " + this.currentSession.StartDate + "FinishDate" + this.currentSession.FinishDate+ "ExecutionDate" + this.currentSession.ExecutionDate);
  }

  public mustHideRetryButton(): boolean {
    return !this._canRetrySession || !this.currentSession || (this.currentSession.Status !== this.SessionStatus.finished || moment(this.currentSession.ExecutionDate).diff(moment().utc(), 'days') !== 0 || (this.currentSession.Score || 0) === 10 || (this.currentSession.RetrieveScore !== null && this.currentSession.RetrieveScore !== undefined) || !((this.currentSession.Special || '').trim() === ''));
  }

  public isRetrySessionAvailable(): boolean {
    return this._canRetrySession && this.currentSession && moment(this.currentSession.ExecutionDate).diff(moment().utc(), 'days') === 0 && this.currentSession.RetrieveScore !== null && this.currentSession.RetrieveScore !== undefined && this.currentSession.ScoreNew === null;
  }

    public retrySession() {
        if (this.calling) return;

        if (this.currentSession && this.currentSession.Status === this.SessionStatus.finished && moment(this.currentSession.ExecutionDate).diff(moment(), 'days') === 0 && (this.currentSession.Score || 0) < 10 && (this.currentSession.RetrieveScore === null || this.currentSession.RetrieveScore === undefined) && ((this.currentSession.Special || '').trim() === '')) {
            if (this.authService.currentUser != null && this.selectedDay != null) {
                this.calling = true;

                this.sessionService.retrySession(this.authService.currentUser.Id, this.currentSession.IdSession).pipe(takeUntil(this._unsubscribeAll)).subscribe(session => {
                    this.currentSession = session;
                    this.navigationService.go("student/session/play", { session: this.currentSession })
                    
                }, error => this.errorCallbackCanStart(error, false));
            }
        }
    }
}
