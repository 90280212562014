import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl, SafeStyle } from '@angular/platform-browser';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivityType } from 'app/core/shared/enums/activity-tipe.enum';
import { MiniGames, MiniGamesBackgroundColors } from 'app/core/shared/enums/mini-game.enum';
import { LeeBooks } from 'app/core/shared/state/models/Wali-lee/lee-books.model';
import { LeeResult } from 'app/core/shared/state/models/Wali-lee/lee-result.model';
import { LeeSections } from 'app/core/shared/state/models/Wali-lee/lee-sections.model';
import { GameService } from 'app/services/game.service';
import { environment } from 'environments/environment';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'map-section',
    templateUrl: './map-section.component.html',
    styleUrl: './map-section.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MapSectionComponent implements OnInit, OnDestroy, AfterViewInit {
    private _unsubscribeAll: Subject<any>;
    private _timeout: number = null;
    private _currentUser: any;
    private _currentSectionWidth: number = 44;
    private _currentSectionHeight: number = 110;
    private _sectionWidth: number = 22;
    private _sectionHeight: number = 13;

    @HostListener('window:resize', ['$event'])
    onResize = _ => this._markForUpdate();
    
    @ViewChild('mapSection', { static: true }) mapSection;
    @ViewChild('mapSectionTemplate', { static: false }) mapSectionTemplate: TemplateRef<any>;

    @Input() book: LeeBooks;
    @Input() section: LeeSections;
    @Input() mapHeight: number;
    @Input() mapWidth: number;
    @Input() mapInner: any;
    @Output() onModalShown = new EventEmitter<boolean>();
    @Output() onApprobed = new EventEmitter<number>();
    @Output() onSuspended = new EventEmitter<number>();

    public sectionDataVisible: boolean = false;
    public confirmDialog: MatDialogRef<FuseDialogContinueComponent>;    
    public gameURL: SafeResourceUrl = null;
    public gameBackgroundStyle: SafeStyle = null;
    public walileeBackgroundStyle: SafeStyle = null;    
    public confetiBackgroundStyle: SafeStyle = null;
    public textColor: SafeStyle = null;
    public circleColor: SafeStyle = null;
    public gameParams: any = {};
    public gameId: number = null;
    public gameVisible: boolean = false;
    public listener: any;
    public gamePlayed: boolean = false;
    public gameReward: string = '';

    constructor(private _matDialog: MatDialog,
                private _sanitizer: DomSanitizer,
                private _gameService: GameService,
                private _detector: ChangeDetectorRef,
                private _translateService: TranslateService) {
        this._unsubscribeAll = new Subject();
        this._updateSectionPosition = this._updateSectionPosition.bind(this);
    }

    ngOnInit(): void {        
        if (this.section.Current) {
            this._preloadGame();
            this._listenMessages();
        }

        this._updateSectionPosition();
    }

    ngOnDestroy(): void {
        if (window['gameListener'] === 'true' && this.listener) {
            window['gameListener'] = 'false';
            window.removeEventListener('message', this.listener);
        }
        
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit(): void {
        this._attachScroll();
    }

    showSectionData(){
        if(this.section.Current) {
            this.showModal();
        } else if(this.section.Approved){
            this.showStudentScore();
        }        
    }

    private _attachScroll() {
        fromEvent(document.querySelector('#studentContainer'), 'scroll').pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => this._markForUpdate());
    }

    private _markForUpdate() {
        if (this._timeout) clearTimeout(this._timeout);
        
        this._timeout = setTimeout(() => this._updateSectionPosition());
    }

    private _updateSectionPosition() {        
        const mapRect = this.mapInner.getBoundingClientRect();
        const changePercent = (mapRect.width - this.mapWidth) / this.mapWidth * 100;
        //fix temporal
        if(!this.section.coordinates){
            this.mapSection.nativeElement.style.display = 'none';
            return;
        }
        let changeWidth = changePercent > 0 ? (100 - changePercent) / 100 : (100 + changePercent) / 100;

        changeWidth = parseFloat(changeWidth.toFixed(2));

        const sectionWidth = (this.section.Current ? this._currentSectionWidth : this._sectionWidth) * changeWidth;
        const sectionHeight = (this.section.Current ? this._currentSectionHeight : this._sectionHeight) * changeWidth;
        this.mapSection.nativeElement.style.width = sectionWidth + 'px';
        this.mapSection.nativeElement.style.height = sectionHeight + 'px';
        this.mapSection.nativeElement.style.left = ((this.section.coordinates.x / this.mapWidth) * mapRect.width) + (mapRect.width > this.mapWidth ? changePercent : 0) - (sectionWidth) + 'px';
        this.mapSection.nativeElement.style.top = ((this.section.coordinates.y / this.mapHeight) * mapRect.height) + (mapRect.height > this.mapHeight ? changePercent : 0) - (sectionHeight / 2) + 'px';
    }

    private _preloadGame() {
        this._currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

        const availableGames = (Object.keys(MiniGames).length / 2);
        const GAME_ID = Math.floor(Math.random() * (availableGames)) + 1;
        const GAME_NAME = MiniGames[GAME_ID];
        const backgroundVersion = this._currentUser.Level >= 8 && this._currentUser.Level <= 11 ? '1' : '2';
        
        this.gameId = GAME_ID;
        this.gameParams = JSON.stringify({
            GAME_ID: GAME_ID,
            GAME_URL_SOURCE: environment.baseApi,
            GAME_URL_IMAGE_SOURCE: environment.filesUrl,
            GAME_ID_USER: this._currentUser.Id,
            GAME_LEVEL_USER: this._currentUser.Level,
            GAME_CAN_REPLAY: 1,
        });

        const backgroundColor = MiniGamesBackgroundColors[GAME_NAME];
        this.gameURL = this._sanitizer.bypassSecurityTrustResourceUrl(`./assets/games/mini-games/${GAME_NAME}/index.html`);
        this.gameBackgroundStyle = this._sanitizer.bypassSecurityTrustStyle(`url('${environment.filesUrl}/images/MiniGames/Backgrounds/${backgroundColor}${backgroundVersion}.svg?ngsw-bypass=true')`);
        this.confetiBackgroundStyle = this._sanitizer.bypassSecurityTrustStyle(`url('${environment.filesUrl}/images/MiniGames/Backgrounds/confeti.gif?ngsw-bypass=true')`);
        this.walileeBackgroundStyle = this._sanitizer.bypassSecurityTrustStyle(`url('${environment.filesUrl}/images/WaliLee/WaliLee.svg?ngsw-bypass=true')`);
        this.textColor = backgroundColor === MiniGamesBackgroundColors.HeadBreaker ? this._sanitizer.bypassSecurityTrustStyle('var(--azul)') : this._sanitizer.bypassSecurityTrustStyle('var(--amarillo)');
        this.circleColor = backgroundColor === MiniGamesBackgroundColors.DifferenceFinder ? this._sanitizer.bypassSecurityTrustStyle('var(--azul)') : this._sanitizer.bypassSecurityTrustStyle('var(--naranja)');

        this._detector.detectChanges();

        /*this._ngZone.runOutsideAngular(() => {
            const iframe = document.getElementById('iframeGame') as HTMLIFrameElement;
    
            if (iframe) iframe.src = this.gameURL;
        });*/
    }

    public testClosed(result: LeeResult) {
        if (result) {
            if (result.IsApproved) {
                this.showApprovedModal(result, this.section.showGame);
            } else {
                this.showTestFailed(result);
            }
        } else {
            this.showTestFailed();
        }
    }

    showModal() {
        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });

        let title = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-RESULTS.SECTION') + " "  + this.section.IdSection + ": «" + this.section.Title + "»";
        let message = '';
        
        if (this.section.Started) {
            message = this._translateService.instant('STUDENT.WALILEE.MAP-SECTION.RESTART-TEST');
        } else {
            message = this._translateService.instant('STUDENT.WALILEE.MAP-SECTION.START-TEST');
        }

        this.confirmDialog.componentInstance.title = title;
        this.confirmDialog.componentInstance.message1 = message;
        this.confirmDialog.componentInstance.space = true;
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.options = [{
            text: this._translateService.instant('ACCEPT'),
            callback: () => {
                this.sectionDataVisible = true;
                this.onModalShown.emit(true);
                this._detector.detectChanges();
            }
        }, {
            text: this._translateService.instant('CANCEL'),
            callback: () => {
                this.sectionDataVisible = false;
                this._detector.detectChanges();
            }
        }];

        this.confirmDialog.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
            result();

            this.confirmDialog = null;
        });
    }

    showStudentScore(){
        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });

        let title = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-RESULTS.SECTION') + " " + this.section.IdSection + ": «" + this.section.Title + "»";
        let message = this._translateService.instant('STUDENT.WALILEE.MAP-SECTION.CORRECT-ANSWERS').replace('{0}', this.section.NumOk).replace('{1}', this.section.NumExercises);

        this.confirmDialog.componentInstance.title = title;
        this.confirmDialog.componentInstance.message1 = message;
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.options = [{
            text: this._translateService.instant('ACCEPT'),
            callback: () => {
            }
        }];

        this.confirmDialog.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => {
            this.confirmDialog = null;
        });
    }

    public saveGameResult(gameInfo, replay = false){
        let activityInfo = gameInfo.clues >= 30 || replay ? null : {
            Idlee: this.section.IdLee,
            IdSection: this.section.IdSection,
            Advance: this.section.Advance,
            IdCivilization: this._currentUser.IdCivilizacion
        };
        this._gameService.SaveGameResult(this.gameId, this._currentUser.Id, gameInfo.seconds, gameInfo.fails, gameInfo.clues, gameInfo.backgroundImage, activityInfo, !replay ? ActivityType.WaliLee : ActivityType.unset).pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
            if(replay){
                return;
            }
            this.gameVisible = false;
            this.gamePlayed = true;

            if (result) {
                if (result.Walinwos == null) {
                    this.gameReward = this._translateService.instant(result.Descripcion);
                } else {
                    this.gameReward = `<span class="big">${result.Walinwos}</span><br/>walinwos<br/>extra`;
                }
            } else {
                this.goToMap();
            }
            
            this._detector.detectChanges();
        });
    }

    private _listenMessages(): void {
        //if (environment.production) return;
        if (window['gameListener'] !== 'true') {
            window['gameListener'] = 'true';
            this.listener = this._messageEvent.bind(this);
            window.addEventListener('message', this.listener, false);
        }
    }

    private _messageEvent(e: any) {
        /*if (['http://localhost:4200', 'https://development.walinwa.net'].indexOf(e.origin) === -1) {
            return;
        }*/

        let messageRecived = {} as any;

        try {
            messageRecived = JSON.parse(e.data);
        } catch (ex) {
            messageRecived = { message: e.data };
        }
        if (messageRecived.message === 'GameReplay') {            
            const gameInfo = {
                fails: messageRecived.data.fails,
                seconds: messageRecived.data.seconds,
                clues: messageRecived.data.clues || 0,
                backgroundImage: messageRecived.data.backgroundImage
            }

            this.saveGameResult(gameInfo, true);
            this._detector.detectChanges();

        } else if(messageRecived.message  === 'GameResult'){
            const gameInfo = {
                fails: messageRecived.data.fails,
                seconds: messageRecived.data.seconds,
                clues: messageRecived.data.clues || 0,
                backgroundImage: messageRecived.data.backgroundImage
            }
            this.saveGameResult(gameInfo);
            this._detector.detectChanges();
        
        } else if (messageRecived.message === 'GameExit') {   
            this.showGameExit();
        }
    }

    public showApprovedModal(result: LeeResult, showGame: boolean){
        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });

        this.confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.WALILEE.MAP-SECTION.GOOD-JOB');
        this.confirmDialog.componentInstance.message1 = (showGame ? this._translateService.instant('STUDENT.WALILEE.MAP-SECTION.CORRECT-ANSWERS-AND-GAME') : this._translateService.instant('STUDENT.WALILEE.MAP-SECTION.CORRECT-ANSWERS-AND-WALIS')).replace('{0}', result.NumOk).replace('{1}', result.NumExercises).replace('{2}', 50);
        this.confirmDialog.componentInstance.space = true;
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.options = [{
            text: this._translateService.instant('ACCEPT'),
            callback: () => {
                this.sectionDataVisible = false;

                if (showGame) {
                    this.gameVisible = true;
                    this._detector.detectChanges();
                } else {
                    this.gameVisible = false;
                    this.onApprobed.emit(this.section.IdSection);
                    this._detector.detectChanges();
                }
            }
        }, {
            text: this._translateService.instant('CANCEL'),
            callback: () => {
                this.sectionDataVisible = false;
                this.gameVisible = false;
                this.onApprobed.emit(this.section.IdSection);
                this._detector.detectChanges();
            }
        }];

        this.confirmDialog.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
            result();

            this.confirmDialog = null;
        });
    }

    showTestFailed(result: LeeResult | void){
        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });

        let title = this._translateService.instant('STUDENT.WALILEE.MAP-SECTION.NOT-APPROBED');
        let message = result ? this._translateService.instant('STUDENT.WALILEE.MAP-SECTION.CORRECT-ANSWERS-FAILED').replace('{0}', result.NumOk).replace('{1}', result.NumExercises) : this._translateService.instant('STUDENT.WALILEE.MAP-SECTION.TRY-AGAIN');

        this.confirmDialog.componentInstance.title = title;
        this.confirmDialog.componentInstance.message1 = message;
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.options = [{
            text: this._translateService.instant('ACCEPT'),
            callback: () => {
                this.sectionDataVisible = false;
                this.onSuspended.emit(this.section.IdSection);
            }
        }];

        this.confirmDialog.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
            result();
            this.confirmDialog = null;
        });
    }

    showGameExit() {
        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });

        let title = this._translateService.instant('STUDENT.WALILEE.MAP-SECTION.ATTENTION');
        let message = this._translateService.instant('STUDENT.WALILEE.MAP-SECTION.QUIT-GAME-MESSAGE');

        this.confirmDialog.componentInstance.title = title;
        this.confirmDialog.componentInstance.message1 = message;
        this.confirmDialog.componentInstance.space = true;
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.options = [{
            text: this._translateService.instant('ACCEPT'),
            callback: () => {
                this.onApprobed.emit(this.section.IdSection);         
                this.gameVisible = false;
                this._detector.detectChanges();
            }
        }, {
            text: this._translateService.instant('CANCEL'),
            callback: () => {
            }
        }];

        this.confirmDialog.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
            result();

            this.confirmDialog = null;
        });
    }    

    public goToMap() {
        this.onApprobed.emit(this.section.IdSection);
        this.sectionDataVisible = false;
        this.gamePlayed = false;
        this.gameVisible = false;
        this._detector.detectChanges();
    }
}
