<div class="walinwa-box-modal-overlay" *ngIf="deductionText">

    <div id='libroSVG' *ngIf="!review" class="deduccion_modal" 
        [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/Deduction/fondo-deduccion.png?ngsw-bypass=true&quot;)' }"
        [ngStyle.sm]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/Deduction/deduccion-toper-mobile.png?ngsw-bypass=true&quot;), url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/Deduction/deduccion-footer-mobile.png?ngsw-bypass=true&quot;)' }"
        [ngStyle.xs]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/Deduction/deduccion-toper-mobile.png?ngsw-bypass=true&quot;), url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/Deduction/deduccion-footer-mobile.png?ngsw-bypass=true&quot;)' }"
        [ngClass]="{ 'active': deductionText }"
        fxLayout="column">

        <div class="supper_body" fxFlex="" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="1rem"
            fxLayoutGap.lt-md="0rem">
            <div class="header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem">
                <div class="" fxFlex="auto" fxFlex.xs="150px" fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="mr-8">{{'STUDENT.DEDUCTION-TEXT.PLAY.DEDUCTION-HEADER' | translate }}</div>
                    <div>{{ getDate() }}</div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
                    <div *ngIf="deductionText" class="walinwos_badge" fxLayout="row" fxLayoutAlign="center center"
                        fxLayoutGap="7px" fxHide.gt-sm fxShow>
                        <img src="https://walinwa.blob.core.windows.net/images/walinwo.gif?ngsw-bypass=true" fxFlex="2.5rem">
                        <span>
                            {{ walinwos }} {{'STUDENT.TEXT-EXERCISES.OF-CONNECTOR' | translate }} {{ deductionText.Questions.length * 10 }}
                        </span>
                    </div>
                    <div class="" fxFlex="2rem">
                        <img class="pointer" (click)="finish()" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true" fxFlex="2em">
                    </div>
                </div>
            </div>
            <div fxFlex="" class="contenti" fxLayout="row" fxLayout.lt-md="row wrap"
                fxLayoutAlign="space-between stretch">
                <form class="content spe_gap" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="words sangrado_text" fxFlex="" [innerHtml]="deductionText.Fragment  | replaceTags"></div>
                    <div class="autor text-right">
                        <span class=""><em>{{ getBookName(deductionText.Book) }}</em> </span>
                        <span *ngIf="deductionText.Author" class=""> {{ deductionText.Author }}</span>
                    </div>
                </form>
                <div class="questions" fxLayout="column" fxLayoutAlign="stretch">
                    <div fxFlex="5rem" fxLayout="row" fxLayoutAlign="space-between center"
                        style="margin-top: -1rem; margin-bottom: 2rem;" fxHide fxShow.gt-sm>
                        <div fxFlex="7rem">
                            <img src="https://walinwa.blob.core.windows.net/images/Comprensiones/logo_walinwa.svg?ngsw-bypass=true">
                        </div>
                        <div>
                            <div *ngIf="deductionText" class="walinwos_badge" fxLayout="row"
                                fxLayoutAlign="center center" fxLayoutGap="7px">
                                <img src="https://walinwa.blob.core.windows.net/images/walinwo.gif?ngsw-bypass=true" fxFlex="2.5rem">
                                <span>
                                    {{ walinwos }} {{'STUDENT.TEXT-EXERCISES.OF-CONNECTOR' | translate }} {{ deductionText.Questions.length * 10 }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <form [formGroup]="form" fxFlex="" class="main_box" fxLayout="column"
                        fxLayoutAlign="space-between stretch" faLayoutGap="2rem">
                        <div *ngIf="questionIndex==-1" class="" [innerHtml]="deductionText.Fragment | replaceTags">
                        </div>
                        <div *ngIf="questionIndex==-1" class="">
                            <span class=""><em>{{ getBookName(deductionText.Book) }}</em></span>
                            <span *ngIf="readingText.Author">{{ deductionText.Author }}</span>
                        </div>
                        <div *ngIf="questionIndex>-1" class="reading_text_content" fxLayout="column"
                            fxLayoutAlign="stretch" fxLayoutGap="1rem">
                            <div class="verdeAzulado"
                                [innerHtml]="deductionText.Questions[questionIndex].Question | replaceTags"></div>
                            <fieldset [disabled]="correct != null" class="reading_text_answers" fxLayout="column"
                                fxLayoutAlign="stretch" fxLayoutGap="0.5rem">
                                <label [ngClass]="{ isCorrect: correct != null && answer.IsCorrect }"
                                    class="reading_text_answer"
                                    *ngFor="let answer of deductionText.Questions[questionIndex].Answers; let i = index;"
                                    fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5rem">
                                    <input type="radio" [value]="answer.IdAnswer" formControlName="IdAnswer">
                                    <span [innerHtml]="answer.Answer | replaceTags"></span>
                                </label>
                            </fieldset>
                        </div>
                        <div class="reading_text_buttons spe_gap" fxLayout="row" fxLayoutAlign="space-between center">
                            <div *ngIf="questionIndex>-1 && deductionText" class="font_blue">
                                {{'STUDENT.TEXT-EXERCISES.QUESTIONS-PROGRESS' | translate:{questionNum:questionIndex + 1,questionCount:deductionText.Questions.length} }}
                            </div>
                            <button [disabled]="loading" (click)="next()" *ngIf="questionIndex==-1" class="new_but azul">
                                {{'STUDENT.TEXT-EXERCISES.START-EXERCISE-BUTTON' | translate }}
                            </button>
                            <button [disabled]="loading || correct != null" (click)="next()"
                                *ngIf="questionIndex>-1 && !score" class="new_but azul">
                                {{'STUDENT.TEXT-EXERCISES.ANSWER-BUTTON' | translate }}
                            </button>
                        </div>
                    </form>

                    <img (click)="goNext(false)" *ngIf="correct === false && !loading" class="correct" src="https://walinwa.blob.core.windows.net/images/Comprensiones/bad.png?ngsw-bypass=true">
                    <img (click)="goNext(false)" *ngIf="correct === true && !loading" class="correct" src="https://walinwa.blob.core.windows.net/images/Comprensiones/good.png?ngsw-bypass=true">
                                    
                </div>
            </div>
        </div>

    </div>


    <div *ngIf="review && !loading" [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/Deduction/fondo-deduccion.png?ngsw-bypass=true&quot;)' }"
    [ngStyle.sm]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/Deduction/deduccion-toper-mobile.png?ngsw-bypass=true&quot;), url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/Deduction/deduccion-footer-mobile.png?ngsw-bypass=true&quot;)' }"
    [ngStyle.xs]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/Deduction/deduccion-toper-mobile.png?ngsw-bypass=true&quot;), url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/Deduction/deduccion-footer-mobile.png?ngsw-bypass=true&quot;)' }" 
    class="deduccion_modal" [ngClass]="{ 'active': deductionText }">

        <div class="supper_body" fxFlex="" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="1rem"
            fxLayoutGap.lt-md="0rem">
            <div class="header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
                <div class="" fxFlex="auto" fxFlex.xs="150px" fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="mr-8">{{'STUDENT.TEXT-EXERCISES.ANSWER-BUTTON' | translate }}</div>
                    <div>{{ getDate() }}</div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
                    <div *ngIf="deductionText" class="text-center notaTop">
                        <span *ngIf="!reviewingActivity && deductionText.Score != null" style="color: var(--verdeAzulado);"><small>{{'STUDENT.TEXT-EXERCISES.MARK-TELL-TEXT' | translate }}</small><br><span class="nota">{{
                            deductionText.Score }} {{deductionText.Retries > 0 ?
                            '(reintentos '+ deductionText.Retries+')': ''}}</span> </span>
                    </div>
                    <div class="" fxFlex="2rem">
                        <img class="pointer" (click)="finish()" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true" fxFlex="2em">
                    </div>
                </div>
            </div>
            <div fxFlex="" class="contenti" fxLayout="row" fxLayout.lt-md="row wrap"
                fxLayoutAlign="space-between stretch">
                <form class="content spe_gap" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="words sangrado_text" fxFlex="" [innerHtml]="deductionText.Fragment | replaceTags"></div>
                    <div class="autor text-right">
                        <span><em>{{ getBookName(deductionText.Book) }} </em></span>
                        <span *ngIf="deductionText.Author">{{ deductionText.Author }}</span>
                    </div>
                </form>
                <div class="questions" fxLayout="column" fxLayoutAlign="stretch">
                    <div fxFlex="5rem" fxLayout="row" fxLayoutAlign="space-between center"
                        style="margin-bottom: -2rem;" fxHide fxShow.gt-sm>
                        <div fxFlex="7rem" style="margin-top: -6.5rem;">
                            <img src="https://walinwa.blob.core.windows.net/images/Comprensiones/logo_walinwa.svg?ngsw-bypass=true">
                        </div>
                        <div class="" *ngIf="deductionText">
                            <div class="" *ngIf="deductionText.DeductionTextResult" style="margin-bottom: 3.5rem;">
                                <span style="color: var(--verdeAzulado);"><b>{{'STUDENT.TEXT-EXERCISES.MARK-TELL-TEXT' | translate }} {{
                                    deductionText.DeductionTextResult.Score }}
                                    {{deductionText.DeductionTextResult.Retries > 0 ?
                                    '(reintentos '+ deductionText.DeductionTextResult.Retries+')': ''}}</b></span>
                            </div>
                        </div>
                    </div>
                    <form class="main_box spe_gap" fxFlex="" fxLayout="column" fxLayoutAlign="space-between stretch">

                        <div class="reading_text_review_question"
                            *ngFor="let question of deductionText.Questions; let i = index">

                            <div class="verdeAzulado">
                                <div class="" [innerHtml]="question.Question | replaceTags:i + 1"></div>
                            </div>

                            <div [ngClass]="{ ok: answer.IsCorrect, ko: !answer.IsCorrect && wasSelected(answer) }"
                                class="answer" *ngFor="let answer of question.Answers; let i = index;" fxlayout="row"
                                fxLayoutAlign="start center" fxLayoutGap="0.5rem">
                                <div class="check" fxFlex="1.5rem">
                                    <i *ngIf="answer.IsCorrect" class="fas fa-check"></i>
                                </div>
                                <div class="response" fxFlex="">
                                    <span [innerHtml]="answer.Answer | replaceTags"></span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<plank-selector *ngIf="finished" [title]="'COMPONENTS.PLANK.EXERCISE-FINISHED' | translate" [isFinAnticipado]="false" [show]="messages.length>0"
    [mark]="score.Score" [newScenary]="nuevoFondo" [messages]="messages" [walinwos]="score.Walinwos"
    [errors]="score.NumExercises - score.NumOk" (onClose)="finish()"></plank-selector>