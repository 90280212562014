import { Component, OnInit, ViewEncapsulation, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { UiService } from '@fuse/components/spinner/ui.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { AccountService } from '../account.service';
import { IUserInfo } from '../user-info';
import { environment } from 'environments/environment';
import { SharedService } from 'app/core/shared/shared.service';
import { AuthService } from 'app/core/guards/auth.service';
import { RepositoryService } from '../../../core/repository/repository.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'app/services/navigation.service';
import { AppService } from 'app/app.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'request-reset-password',
    templateUrl: './request-reset-password.component.html',
    styleUrls: ['./request-reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:resize)': 'onResize($event)'
    }
})
export class RequestResetPasswordComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    mobile: boolean = false;
 iPad = /iPad|Macintosh/i.test(navigator.userAgent); mobileDetect;
    requestResetPasswordForm: UntypedFormGroup;
    errorMessage;
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;
    isStudent;
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _accountService: AccountService,
        private _router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        public _sharedService: SharedService,
        private _authService: AuthService,
        private _ui: UiService,
        private _repositoryService: RepositoryService,
        private _matDialog: MatDialog,
        private _translateService: TranslateService,
        private navigationService: NavigationService,
        private appService: AppService
    ) {
        this._unsubscribeAll = new Subject();
        this._ui.spin$.next(true);
        this.mobileDetect = this.appService.mobileDetect();

    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        event.target.innerWidth;
        this.mobile = this._sharedService.isMobile();
    }

    ngOnInit(): void {
        if (this.navigationService.params.isStudent) this.isStudent = this.navigationService.params.isStudent;
        this.mobile = this._sharedService.isMobile();
        this.requestResetPasswordForm = this._formBuilder.group({
            userName: ['', [Validators.required, Validators.pattern(/^[0-9A-Za-zñÑ\.]+$/)] ],
            email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]]
        });
        //this.requestResetPasswordForm.markAllAsTouched();

    }

    submit() {
        this.requestResetPasswordForm.markAllAsTouched();
        if (this.requestResetPasswordForm.valid) {
            
            this.errorMessage = null;
            let url = environment.baseApi + "/api/User/username/" + this.requestResetPasswordForm.value.userName + "/email/" + this.requestResetPasswordForm.value.email;
            this._ui.showSpinner();
            this._repositoryService.get(url)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((response: any) => {
                this._ui.stopSpinner();
                this.showDialog();
            },
                error => {
                    this.getError(error);
                    this._ui.stopSpinner();
                });
        }

    }

    getError(error) {
        if (error && error.error && error.error.code) {
            switch (error.error.code) {
                case "InvalidUser":
                    this.errorMessage = "AUTHENTICATION.ERROR.USER";
                    break;
                case "InvalidEmail":
                    this.errorMessage = "AUTHENTICATION.ERROR.EMAIL";
                    break;
                case "License":
                    this.errorMessage = "AUTHENTICATION.ERROR.LICENSE"
                    break;
                default:
                    this.errorMessage = "ERROR";
                    break;
            }
        } else {
            this.errorMessage = "AUTHENTICATION.ERROR.CON";
        }
    }

    showDialog(): void {

        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });

        // this.confirmDialog.componentInstance.title = this._translateService.instant('REQ_RES_PW.TITLE-DIALOG');
        this.confirmDialog.componentInstance.message1 = this._translateService.instant('AUTHENTICATION.REQ_RES_PW.MESSAGE-DIALOG');
        this.confirmDialog.componentInstance.textButton = this._translateService.instant('CONTINUE');
        this.confirmDialog.componentInstance.margin = false;

        this.confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
            if (result) {
                this._router.navigateByUrl("login");
            }
            this.confirmDialog = null;
        });
    }

}
