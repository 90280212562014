<div class="walinwa-box">
    <form [formGroup]="form" class="walinwa-box-content">
        <div style="margin-bottom: -1rem;">
            <mat-form-field>
                <label class="font_blue">{{'CLASS' | translate}}</label>
                <mat-select id="select-class" [(ngModel)]="selectedClass" (selectionChange)="changeClass()"
                    [ngModelOptions]="{standalone: true}">
                    <mat-option class="scrollable" [value]="0">{{'SCHOOL.TEACHER.CONFIG.ALL-CLASSES' | translate}}</mat-option>
                    <mat-option class="scrollable" *ngFor="let class of classes | sortBy:'asc':['Level','Code']"
                        [value]="class.IdClass">{{ class.Name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div style="margin-top:10px; margin-bottom: 10px;">
            <label class="cust_label"><b>{{'SCHOOL.TEACHER.CONFIG.ZONE.TITLE' | translate}}</b></label>
        </div>
        <div fxFlex="100%" class="session-zone-select">
            <mat-form-field>
                <mat-select formControlName="SessionMyZoneCondition">
                    <mat-option class="scrollable" [value]="value.value" *ngFor="let value of SessionMyZoneConditions">{{
                        value.desc}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="schoolService.school?.Dictation">
            <mat-checkbox formControlName="DictationMyZoneCondition">{{'SCHOOL.TEACHER.CONFIG.ZONE.DICTATIONMYZONECONDITION' | translate}}</mat-checkbox>
            <tooltip position="R" [info]="'SCHOOL.TEACHER.CONFIG.ZONE.DICTATIONMYZONECONDITION-INFO' | translate"></tooltip>
        </div>
        <div
            *ngIf="schoolService.school?.ReadingText || schoolService.school?.DeductionText || schoolService.school?.HearingText">
            <mat-checkbox formControlName="ComprensionMyZoneCondition">{{'SCHOOL.TEACHER.CONFIG.ZONE.COMPRENSIONMYZONECONDITION' | translate}}
            </mat-checkbox>
            <tooltip position="L" [info]="'SCHOOL.TEACHER.CONFIG.ZONE.COMPRENSIONMYZONECONDITION-INFO' | translate"></tooltip>
        </div>
        <div>
            <mat-checkbox formControlName="MemoryMyZoneCondition">{{'SCHOOL.TEACHER.CONFIG.ZONE.MEMORYMYZONECONDITION' | translate}}
            </mat-checkbox>
            <tooltip position="L" [info]="'SCHOOL.TEACHER.CONFIG.ZONE.MEMORYMYZONECONDITION-INFO' | translate"></tooltip>
        </div>
        <div *ngIf="schoolService.school?.PersonalizedTest">
            <mat-checkbox formControlName="TestMyZoneCondition">{{'SCHOOL.TEACHER.CONFIG.ZONE.TESTMYZONECONDITION' | translate}}</mat-checkbox>
            <tooltip position="R" [info]="'SCHOOL.TEACHER.CONFIG.ZONE.TESTMYZONECONDITION-INFO' | translate"></tooltip>
        </div>
        <div class="spe_gap walinwa-row" fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
            <button (click)="save()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>
        </div>
    </form>

</div>