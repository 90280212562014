<section fxLayout="column" fxLayoutAlign="stretch" class="spe_gap" style="height: 100%;">
    <div>
        <div class="walinwa-box walinwa-box-orange">
            <calendar class="calendario sesion walCalendar ww_customCalendar_two" [ngClass]="{'sixWeeks': sixWeeks()}"
                [parent]="this" [fullDay]="isMobile == false ? true : false" [selectedDay]="selectedDay" [currentUser]="currentUser"
                [currentMonth]="currentMonth">
            </calendar>
            
            <div class="ww_footi_cal spe_gap" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="25" fxFlex.xs=""[hidden]="!mustHideRetryButton() || (_CanSkipWeekendSession && (day == 6 || day == 0))">
                    <tooltip fxHide fxShow.gt-xs id="stateSession" info='
                    <div>
                        <i class="fa fa-circle end"></i> {{"STUDENT.SESSION.TOOLTIP-MESSAGE1" | translate}} &nbsp;&nbsp;&nbsp; 
                        <i class="fas fa-circle notDone"></i> {{"STUDENT.SESSION.TOOLTIP-MESSAGE2" | translate}} <small style="white-space: nowrap">{{"STUDENT.SESSION.TOOLTIP-MESSAGE3" | translate}}</small>
                    </div>
                    <div>
                        <i class="fa fa-circle notFinish"></i> {{"STUDENT.SESSION.TOOLTIP-MESSAGE4" | translate}} &nbsp;&nbsp;&nbsp; 
                        <i class="fa fa-circle notRecover"></i> {{"STUDENT.SESSION.TOOLTIP-MESSAGE5" | translate}}
                    </div>
                    ' color="excl" position="R"></tooltip>
                    
                    <tooltip class="line-height" fxShow.xs fxHide.gt-xs id="stateSession" info=' 
                    <div class="walinwa-100"><i class="fa fa-circle end"></i> {{"STUDENT.SESSION.TOOLTIP-MESSAGE1" | translate}}</div>
                    <div class="walinwa-100"><i class="fas fa-circle notDone"></i> {{"STUDENT.SESSION.TOOLTIP-MESSAGE2" | translate}} <small style="white-space: nowrap">{{"STUDENT.SESSION.TOOLTIP-MESSAGE3" | translate}}</small></div>
                    <div class="walinwa-100"><i class="fa fa-circle notFinish"></i> {{"STUDENT.SESSION.TOOLTIP-MESSAGE4" | translate}}</div>
                    <div class="walinwa-100"><i class="fa fa-circle notRecover"></i> {{"STUDENT.SESSION.TOOLTIP-MESSAGE5" | translate}}</div>
                    ' color="excl" position=""></tooltip>
                </div>
                <div>
                    <div fxHide fxShow.gt-xs [hidden]="mustHideRetryButton()">
                        <i class="fas fa-asterisk asterisco fa-sm"></i> 
                        <span class="texto_asterisco">
                            {{'STUDENT.SESSION.REPEAT-EXERCISES-TEXT' | translate }}
                        </span>
                    </div>
                    <div  fxHide fxShow.gt-xs [hidden]="!(_CanSkipWeekendSession && (day == 6 || day == 0))">
                        <i *ngIf="mustHideRetryButton()" class="fas fa-asterisk asterisco fa-sm"></i> 
                        <span class="texto_asterisco" [ngStyle]="{ 'margin-left': !mustHideRetryButton() ? '1.5em' : '0' }">
                            {{'STUDENT.SESSION.CAN-SKIP-WEEKEND-SESSION' | translate }}
                        </span>
                    </div>
                </div>
                
                <tooltip [hidden]="mustHideRetryButton()" fxShow.xs fxHide.gt-xs id="stateSession2"
                    info="'STUDENT.SESSION.REPEAT-EXERCISES-TEXT' | translate"
                    color="excl" position=""></tooltip>
                <div fxFlex="" fxLayout="row" fxLayoutAlign="end center" class="spe_gap">
                    <!-- <div [hidden]="showReview"></div> -->
                    <button [hidden]="!showReview" [disabled]="calling" class="new_but azul" (click)="showReview ? startReview() : null">
                        {{'SCHOOL.REVIEW-ACTIVITIES.REVIEW-SESSION' | translate}}
                    </button>
                    <button [hidden]="!showReview" [disabled]="calling || perfectSession()" class="new_but azul" (click)="showReview ? startReviewErrors() : null">
                        {{'SCHOOL.REVIEW-ACTIVITIES.REVIEW-ERRORS' | translate}}
                    </button>
                    <button [hidden]="startButtonHidden()" [disabled]="calling" class="new_but azul" (click)="currentSessionStatus == Pending ? startNextSession(true) : null">
                        {{'STUDENT.SESSION.START-SESSION' | translate }}
                    </button>
                    <button [hidden]="recoveryButtonHidden()" [disabled]="calling" class="new_but azul" (click)="currentSessionStatus == ToRecover ? startNextSession(true) : null">
                        {{'STUDENT.SESSION.RETRIEVE-SESSION' | translate }}
                    </button>
                    <button [hidden]="FinishSessionButtonHidden()" [disabled]="calling" class="new_but azul"
                        (click)="!(currentSessionStatus != Started && !allowContinue) ? continueSession() : null">
                        {{'STUDENT.SESSION.FINISH-SESSION' | translate }}
                    </button>
                    <button [hidden]="FinishRetriedSessionButtonHidden()" [disabled]="calling" class="new_but azul"
                        (click)="!(currentSessionStatus != Started && !allowContinue && !isRetrySessionAvailable()) ? continueSession() : null">
                        {{'STUDENT.SESSION.FINISH-RETRIEVE-SESSION' | translate }}
                    </button>
                    <button [hidden]="mustHideRetryButton()" [disabled]="calling || IsLoadingSession()" class="new_but azul" (click)="retrySession()">
                        {{'STUDENT.SESSION.RETRY-SESSION' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="stretch">
        <daily-text [color]="'var(--naranja)'"></daily-text>
    </div>
</section>