import { CalendarDeduction } from './../../../core/shared/state/models/deduction/deduction-month.model';
import { SchoolService } from './../../../services/school.service';
import { CalDeductionTextComponent } from './../../../components/cal-deduction-text/cal-deduction-text.component';
import { DeductionTextService } from './../../../services/deduction-text.service';
import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy, ViewEncapsulation, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import * as moment from 'moment';
import { CustomSession } from 'app/core/shared/state';
import { CustomizedSessionService } from 'app/services/customized-session.service';
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators';
import { SharedService } from 'app/core/shared/shared.service';
import { DeductionText } from 'app/core/shared/state/models/deduction/deduction-text-model';
import { ClassService } from 'app/services/class.service';
import { TranslateService } from '@ngx-translate/core';
declare var $;

@Component({
    selector: 'app-deduction-text',
    templateUrl: './deduction-text.component.html',
    styleUrls: ['./deduction-text.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DeductionTextComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    @ViewChild('template', { static: true }) template: TemplateRef<any> | null = null;
    @ViewChild(CalDeductionTextComponent) child: CalDeductionTextComponent;
    currentUser: any;
    canDoIt = true;
    selectedDay: any;
    currentMonth: number;
    currentYear: number;
    deductionTexts: CalendarDeduction[] = null
    six_weeks: boolean
    loading: boolean = false;
    isMobile: boolean;
    timeBlock: boolean = false;
    isFuture: boolean = false;
    public hideComponent: boolean = false;

    constructor(
        private authService: AuthService,
        private navigationService: NavigationService,
        private deductionTextService: DeductionTextService,
        private router: Router,
        private ref: ChangeDetectorRef,
        private _matDialog: MatDialog,
        private schoolService: SchoolService,
        private sharedService: SharedService,
        private classService: ClassService,
        private _translateService: TranslateService,
        private _activatedRoute: ActivatedRoute
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        if (!this.authService.check()) return;
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => this.hideComponent = this._activatedRoute.firstChild !== null);
        this.sharedService.deduction_text_enabled
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(deductionTextEnabled => {
            if (!deductionTextEnabled) {
                this.navigationService.go("student/session");
            }
        })
        this.currentUser = this.authService.currentUser;
        this.selectedDay = moment();
        this.selectedDay.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        this.currentMonth = this.selectedDay.month() + 1;
        this.currentYear = this.selectedDay.year();
        this.getDeductionByMonth();
        this.deductionTextService.reload_monthly_deduction
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                if (result) {
                    this.getDeductionByMonth();
                }
            })

        this.isMobile = this.detectMobile();
        this.ref.detectChanges();
    }

    detectMobile(): boolean {
        const userAgent = navigator.userAgent.toLowerCase();
        return /mobile|android|iphone|ipad|iemobile|wpdesktop/i.test(userAgent);
    }

    sixWeeks() {
        return $('.week').length - 1 >= 6;
    }

    async selectedDayChangedDT(newDate, canDoIt) {
        this.selectedDay = newDate;
        if (this.selectedDay.format() < this.authService.currentUser.CreationDate && !this.authService.currentUser.UserName.includes(".tmp.")) {
            this.canDoIt = false;
        }
        else {
            this.canDoIt = true;
        }

        if (this.authService.currentUser.IsAdmin) {
            this.isFuture = false;
        } else {

            if (moment().utc().isSameOrBefore(this.selectedDay, 'day')) //today or future
                this.isFuture = true;
            else if (moment(this.authService.currentUser.CreationDate).diff(this.selectedDay, 'days', true) > 0)
                this.isFuture = true;
            else if (!this.finished() && moment().utc().month() - this.selectedDay.month() > 1)
                this.isFuture = true;
            else
                this.isFuture = false;
        }

        if (this.currentMonth != this.selectedDay.month() + 1) {
            this.currentMonth = this.selectedDay.month() + 1;
            this.currentYear = this.selectedDay.year();
            if (this.child) this.six_weeks = this.child.weeks.length >= 6
            this.getDeductionByMonth();
        }
    }

    getDeductionByMonth() {
        if (!this.loading) {
            this.loading = true;
        //this.loading = true;
        //this.deductionTexts = [];
        //this.ref.detectChanges();
            this.deductionTextService.getDeductionTextByMonth(this.authService.currentUser.Id, this.currentMonth, this.currentYear, this.authService.currentUser.Level, this.authService.currentUser.IdRole, this.authService.currentUser.CreationDate)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    result => {
                        if (!result || !Array.isArray(result)) return;
                        this.deductionTexts = result
                        this.deductionTextService.MonthlyDeduction = result;
                        this.loading = false;
                        this.finished();
                        this.ref.detectChanges();
                        this.child.refresh((weeks_count) => {
                            this.six_weeks = weeks_count >= 6
                        })
                    },
                    error => {
                        this.deductionTexts = [];
                        this.loading = false;
                    }
                )
        }
    }

    play(review: boolean) {
        //this.fixPositionScroll_vertical_Class();
        this.loading = true;
        let currentDeductionText = this.deductionTexts.find((r) => { return moment(r.date).isSame(this.selectedDay) });
        if (!currentDeductionText) {
            let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });
            confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.START-ACTIVITY-TITLE');
            confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.DEDUCTION-TEXT.CANT-RECOVER-DEDUCTION-FROM-DATE');
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
            confirmDialog.componentInstance.margin = false;
            confirmDialog.afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(result => {
                    this.router.navigate(['/student/deduction']);
                    confirmDialog = null;
                    this.loading = false;
                });
            return;
        }
        if (this.currentUser.IdClass) {
            this.checkTimeBlock(currentDeductionText, review);
        }
        else {
            this.navigationService.go("student/deduction/" + (review ? "review" : "play") + "/" + currentDeductionText.IdDeduction + "/" + currentDeductionText.date, { review: review });
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    }

    canPlay() {
        if (!this.deductionTexts) return false;
        let currentDeductionText = this.deductionTexts.find((r) => { return moment(r.date).isSame(this.selectedDay) });
        let canPlay = currentDeductionText != null;
        let futuro = false;
        if (this.authService.currentUser.IsAdmin) {
            futuro = false;
        }
        else {
            if (moment().diff(this.selectedDay, 'days', true) < 0) //today or future
                futuro = true;
            else if (moment(this.authService.currentUser.CreationDate).diff(this.selectedDay, 'days', true) > 0)
                futuro = true;
            else if (!this.finished() && moment().month() - this.selectedDay.month() > 1)
                futuro = true;
            else
                futuro = false;
        }
        return canPlay && !futuro;
    }

    finished() {
        if (!this.deductionTexts) return false;
        let currentDeductionText = this.deductionTexts.find((r) => { return moment(r.date).isSame(this.selectedDay) });
        if (!currentDeductionText || currentDeductionText.Score == null) return false;
        return currentDeductionText.FinishDate;
    }

    started() {
        if (!this.deductionTexts) return false;
        let currentDeductionText = this.deductionTexts.find((r) => { return moment(r.date).isSame(this.selectedDay) });
        if (!currentDeductionText) return false;
        return currentDeductionText.TextDate != null
    }

    checkTimeBlock(currentDeductionText: CalendarDeduction, review: boolean) {
        this.timeBlock = false
        this.classService.canExecuteDeductionText(this.authService.currentUser.IdClass)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.timeBlock = !result.canStart
                if (this.timeBlock) {
                    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                        disableClose: true
                    });
                    confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.ACTIVITY-BLOCKED');
                    confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.DEDUCTION-TEXT.DEDUCTIONS-DISABLED-NOW', {initTime: result.TimeIni.substr(0,5), endTime: result.TimeEnd.substr(0,5)})
                    confirmDialog.componentInstance.theme = "white";
                    confirmDialog.componentInstance.margin = false;
                    confirmDialog.componentInstance.options = [
                        {
                            text: this._translateService.instant('ACCEPT'),
                        }
                    ];
                    confirmDialog.componentInstance.margin = false;
                    confirmDialog.afterClosed()
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(result => {
                            confirmDialog = null;
                            this.loading = false
                        });
                }
                else {
                    this.navigationService.go("student/deduction/" + (review ? "review" : "play") + "/" + currentDeductionText.IdDeduction + "/" + currentDeductionText.date, { review: review });
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                }
            },() => {
                this.loading = false;
            })
    }

    /*fixPositionScroll_vertical_Class() {
        const elements = document.querySelectorAll('.iphonechromepopup');
        elements.forEach((element) => {
            // (element as HTMLElement).style.overflow = 'inherit';
            element.classList.add("popup");
        });
        //console.log("popup scroll_fixed");
    }*/

    
}
