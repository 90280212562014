<div class="walinwa-box">

    <div class="walinwa-box-content" *ngIf="navigationService.StateTest=='classes-personalizedTests'">
        <div>
            <div class="ww_table_rwd">
                <table class="walinwa-table">
                    <thead>
                        <tr>
                            <th width="">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.DATE' | translate }}</th>
                            <th width="">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.TIME' | translate }}</th>
                            <th width="">{{'CLASS' | translate }}</th>
                            <th width="">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.NAME' | translate }}</th>
                            <th width="">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.STATE' | translate }}</th>
                            <th style="width:50px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let class_personalizedTest of sortData">
                            <td>{{ class_personalizedTest.formatted_date }}</td>
                            <td
                                *ngIf="class_personalizedTest.TestState == 'Sin realizar' && !isPast(class_personalizedTest.Date)">
                                <input style="text-align: center; padding: 0; color: var(--colorText) !important" (change)="changeTestDate(class_personalizedTest)" type="time"
                                    [(ngModel)]="class_personalizedTest.TestTime">
                            </td>
                            <td
                                *ngIf="class_personalizedTest.TestState != 'Sin realizar' || isPast(class_personalizedTest.Date)">
                                <input style="text-align: center; padding: 0; color: var(--colorText) !important; background: none;" type="time" disabled [value]="class_personalizedTest.TestTime ?? '--:--'">                         
                            </td>
                            <td>
                                <span *ngIf="class_personalizedTest.Aula">{{ class_personalizedTest.Aula }}</span>
                                <span *ngIf="!class_personalizedTest.IdClass">{{ class_personalizedTest.IdClass }}</span>
                            </td>
                            <td>
                                <span *ngIf="class_personalizedTest.Name">{{ class_personalizedTest.Name }}</span>
                                <span *ngIf="!class_personalizedTest.IdTest">{{ class_personalizedTest.IdTest }}</span>
                            </td>
                            <td>
                                <span>{{ class_personalizedTest.TestState }}</span>
                            </td>
                            <td style="text-align: right;">
                                <img class="spe_ico_exam" src="https://walinwa.blob.core.windows.net/images/School/ico-trash.svg?ngsw-bypass=true"
                                    (click)="deleteClassPersonalizedTest(class_personalizedTest)">
                            </td>
                        </tr>
                        <tr *ngIf="classes_personalizedTests?.length==0">
                            <td class="font_blue" colspan="6">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.NO-ASSIGNED-TEST' | translate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="walinwa-row spe_gap" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
            <button (click)="editPersonalizedTests()" [disabled]="loading" class="new_but azul">
                {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.SHOW-TEST' | translate }}
            </button>
            <button (click)="addClassPersonalizedTest()" [disabled]="loading" class="new_but azul">
                {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ASSIGN-NEW' | translate }}
            </button>
        </div>
    </div>

    <div class="walinwa-box-content" *ngIf="navigationService.StateTest=='add-class-personalizedTest'" #divScroll>
        <div *ngIf="navigationService.add_class_step==1">
            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ADD-TEST-TITLE1' | translate }}
        </div>
        <div *ngIf="navigationService.add_class_step==2">
            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ADD-TEST-TITLE2' | translate }}
        </div>
        <div *ngIf="navigationService.add_class_step==3">
            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ADD-TEST-TITLE3' | translate }}
        </div>
        <div *ngIf="navigationService.add_class_step==1" fxLayout="row wrap" fxLayoutAlign="space-between center"
            class="spe_gap">
            <div fxFlex="100%" fxFlex.gt-xs="">
                <calCustomizedSession class="calendario dinamico walCalendar azul ww_customCalendar"
                    [parent]="this" [currentUser]="calendar.current_user" [disablePast]="true"
                    [selectedDay]="calendar.selected_day" [currentMonth]="calendar.current_month"
                    [customizedSessions]="customizedSessions" [isTest]="true">
                </calCustomizedSession>
            </div>
            <div fxFlex="100%" fxFlex.gt-xs="" class="custom-form-field">
                <div [innerHTML]="'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.TEST-TIME' | translate "></div>
                <input style="width: 10rem;" type="time" [(ngModel)]="selectedHour">
            </div>
        </div>
        <div class="" *ngIf="navigationService.add_class_step==2">
            <div class="makeScroll" fxFlex="100%" fxFlex.gt-xs="300px">
                <mat-checkbox *ngFor="let class of classes | sortBy:'asc':['Level','Code']"
                    (change)="selectClass(class)" [checked]="class.selected">
                    <span>{{ class.Name }}</span>
                </mat-checkbox>
            </div>
        </div>
        <div *ngIf="navigationService.add_class_step==2 && showMessage" class="error-message">
            <mat-error>
                <div *ngFor="let message of messages">
                    {{message}}
                </div>
            </mat-error>
        </div>
        <div *ngIf="navigationService.add_class_step==3 || navigationService.add_class_step==-1">
            <div class="walinwa-tabs walinwa-tabs-top">
                <div [ngClass]="{ 'selected': testsTab !== 2}" (click)="(testsTab = 1) && clearTestsSelection()" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CENTER-TEST' | translate }}</div>
                <div [ngClass]="{ 'selected': testsTab === 2}" (click)="(testsTab = 2) && clearTestsSelection()" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.COMMUNITY-TEST' | translate }}</div>        
            </div>
            <div class="ww_table_rwd" [hidden]="testsTab === 2">
                <table class="walinwa-table">
                    <thead>
                        <tr>
                            <th *ngIf="showTestList" style="width: 50px;"></th>
                            <th style="width: 45%;">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.TEST-NAME' | translate }}</th>
                            <th style="width: 20%;">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.COURSE' | translate }}</th>
                            <th style="width: 15%;">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.PROPIETARY' | translate }}</th>
                            <th style="width: 15%;">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.LAST-UPDATE' | translate }}</th>
                            <th style="width:50px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr  *ngFor="let personalizedTest of personalizedTests | sortBy:'asc': 'Name'" id="trTest{{ personalizedTest.IdTest }}"
                            [ngClass]="{ selected: personalizedTest.selected }">
                            <td *ngIf="showTestList" style="border-bottom: none;">
                                <mat-checkbox (change)="checkJoinTestButtonActivation($event)" [(ngModel)]="personalizedTest.selected"></mat-checkbox>
                            </td>
                            <td (click)="selectPersonalizedTest(personalizedTest)" (dblclick)="canEditTest(personalizedTest) ? startChangingPersonalizedTestName(personalizedTest) : null"
                                *ngIf="!personalizedTest.editingPersonalizedTestName"
                                [style.color]="personalizedTest.ShareTest === 1 && personalizedTest.IdTutor === this.authService.currentUser.Id ? '#086fa8' : 'inherit'">{{ personalizedTest.Name }}</td>
                            <td *ngIf="personalizedTest.editingPersonalizedTestName">
                                <input (blur)="changePersonalizedTestName(personalizedTest)" id="change-name"
                                    type="text" [value]="personalizedTest.Name" />
                                <mat-error *ngIf="nameExists">
                                    {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.TEST-NAME-EXISTS' | translate }}
                                </mat-error>
                                <mat-error *ngIf="isRequired">
                                    {{ 'FIELD-REQUIRED' | translate }}
                                </mat-error>
                            </td>
                            <td (click)="selectPersonalizedTest(personalizedTest)" [style.color]="personalizedTest.ShareTest === 1 && personalizedTest.IdTutor === this.authService.currentUser.Id ? '#086fa8' : 'inherit'">
                                {{ getCourse(personalizedTest)}}
                            </td>
                            <td (click)="selectPersonalizedTest(personalizedTest)">
                                {{ personalizedTest.IdTutor === this.authService.currentUser.Id ? '' : personalizedTest.UserName }}
                            </td>
                            <td (click)="selectPersonalizedTest(personalizedTest)" [style.color]="personalizedTest.ShareTest === 1 && personalizedTest.IdTutor === this.authService.currentUser.Id ? '#086fa8' : 'inherit'">
                                {{ toLocalTime(personalizedTest.LastUpdateDate ?? personalizedTest.CreationDate) | date: 'dd/MM HH:mm' }}
                            </td>
                            <td>
                                <div style="font-size: 22px; font-weight: bold;" (click)="showContextMenu(personalizedTest.IdTest, $event)">...</div>
                            </td>

                            <div class="context-menu" *ngIf="this['isContextMenuVisible' + personalizedTest.IdTest] === true" [style.top.px]="contextMenuY" [style.left.px]="contextMenuX">
                                <ul (mouseleave)="contextMenuVisible = null; hideContextMenu(personalizedTest.IdTest)">
                                    <li (click)="(hideContextMenu(personalizedTest.IdTest) && showTestPropertiesForm(personalizedTest, OPERATION.View))">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.VISUALIZE' | translate }}</li>
                                    <li *ngIf="canEditTest(personalizedTest)" (click)="hideContextMenu(personalizedTest.IdTest) && showTestPropertiesForm(personalizedTest, OPERATION.Edit)">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.EDIT' | translate }}</li>
                                    <li (click)="hideContextMenu(personalizedTest.IdTest) && showCloneTestPropertiesForm(personalizedTest)">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CLONE' | translate }}</li>
                                    <li *ngIf="canEditTest(personalizedTest)" (click)="hideContextMenu(personalizedTest.IdTest) && deletePersonalizedTest(personalizedTest)">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.DELETE' | translate }}</li>
                                </ul>
                            </div>    
                        </tr>
                        <tr *ngIf="personalizedTests.length==0">
                            <td class="font_blue" colspan="2">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.NO-TEST' | translate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="ww_table_rwd" [hidden]="testsTab !== 2">
                <table class="walinwa-table">
                    <thead>
                        <tr>                            
                            <th style="width: 45%;">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.TEST-NAME' | translate }}</th>
                            <th style="width: 20%;">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.COURSE' | translate }}</th>
                            <th style="width: 15%;">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.PROPIETARY' | translate }}</th>
                            <th style="width: 15%;">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.LAST-UPDATE' | translate }}</th>
                            <th style="width:50px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let personalizedTest of personalizedCommunityTests | sortBy:'asc': 'Name'" id="trTest{{ personalizedTest.IdTest }}"
                            [ngClass]="{ selected: personalizedTest.selected }">
                            <td (click)="selectPersonalizedCommunityTest(personalizedTest)" (dblclick)="canEditTest(personalizedTest) ? startChangingPersonalizedTestName(personalizedTest, true) : null"
                                *ngIf="!personalizedTest.editingPersonalizedTestName">
                                {{ personalizedTest.Name }}
                            </td>
                            <td *ngIf="personalizedTest.editingPersonalizedTestName">
                                <input (blur)="changePersonalizedTestName(personalizedTest, true)" id="change-name-community"
                                    type="text" [value]="personalizedTest.Name" />
                                <mat-error *ngIf="nameExists">
                                    {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.TEST-NAME-EXISTS' | translate }}
                                </mat-error>
                                <mat-error *ngIf="isRequired">
                                    {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.TEST-NAME-REQUIRED' | translate }}
                                </mat-error>
                            </td>
                            <td (click)="selectPersonalizedCommunityTest(personalizedTest)">
                                {{ getCourse(personalizedTest)}}
                            </td>
                            <td (click)="selectPersonalizedCommunityTest(personalizedTest)">
                                {{ personalizedTest.IdTutor === this.authService.currentUser.Id ? '' : personalizedTest.UserName }}
                            </td>
                            <td (click)="selectPersonalizedCommunityTest(personalizedTest)">
                                {{ toLocalTime(personalizedTest.LastUpdateDate ?? personalizedTest.CreationDate) | date: 'dd/MM HH:mm' }}
                            </td>
                            <td>

                                <div style="font-size: 22px; font-weight: bold;"
                                    (click)="showContextMenu(personalizedTest.IdTest, $event)">...</div>
                            </td>

                            <div class="context-menu"
                                *ngIf="this['isContextMenuVisible' + personalizedTest.IdTest] === true"
                                [style.top.px]="contextMenuY" [style.left.px]="contextMenuX">
                                <ul (click)="hideContextMenu(personalizedTest.IdTest)" (mouseleave)="contextMenuVisible = null; hideContextMenu(personalizedTest.IdTest)">
                                    <li (click)="(hideContextMenu(personalizedTest.IdTest) && showTestPropertiesForm(personalizedTest, OPERATION.View))">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.VISUALIZE' | translate }}</li>
                                    <li *ngIf="canEditTest(personalizedTest)" (click)="hideContextMenu(personalizedTest.IdTest) && showTestPropertiesForm(personalizedTest, OPERATION.Edit)">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.EDIT' | translate }}</li>
                                    <li (click)="hideContextMenu(personalizedTest.IdTest) && showCloneTestPropertiesForm(personalizedTest)">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CLONE' | translate }}</li>
                                    <li *ngIf="canEditTest(personalizedTest)" (click)="hideContextMenu(personalizedTest.IdTest) && deletePersonalizedTest(personalizedTest)">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.DELETE' | translate }}</li>
                                </ul>
                            </div>
                        </tr>
                        <tr *ngIf="personalizedCommunityTests.length==0">
                            <td class="font_blue" colspan="2">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.NO-TEST' | translate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="walinwa-row spe_gap" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
            <div *ngIf="navigationService.add_class_step < 3 && testsTab !== 2"></div>
            <button (click)="testJoinEnabled ? showTestPropertiesForm(null, OPERATION.Join) : showErrorMessage('Fusionar test', 'Seleccione 2 o más test para realizar la fusión en un nuevo test.')" *ngIf="showTestList && testsTab !== 2" [disabled]="loading" class="new_but azul">
                {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.JOIN-TEST' | translate }}
            </button>

            <button (click)="addPersonalizedTest()" *ngIf="(navigationService.add_class_step == 3 || showTestList) && testsTab !== 2"
                [disabled]="loading" class="new_but azul">
                {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.NEW-TEST' | translate }}
            </button>
            <button (click)="continue()" *ngIf="navigationService.add_class_step!=-1"
                [disabled]="loading || disableWizard() || blockAssignation" class="new_but azul">
                <span *ngIf="navigationService.add_class_step < 3">{{'CONTINUE' | translate }}</span>
                <span *ngIf="navigationService.add_class_step == 3">{{'FINISH' | translate }}</span>
            </button>
        </div>

    </div>

    <form [formGroup]="form" class="walinwa-box-content spe_gap" fxLayout="column" fxLayoutAlign="stretch"
        *ngIf="navigationService.StateTest=='add-personalizedTest'">
        <div *ngIf="currentOperation !== OPERATION.Edit">
            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ADD-TEST' | translate }}
        </div>
        <div *ngIf="currentOperation === OPERATION.Edit">
            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.EDIT-TEST' | translate }}
        </div>
        <section fxLayout="row wrap" fxLayoutAlign="space-between center" class="spe_gap">
            <div fxFlex="0 0 calc(50% - 1rem)" fxFlex.xs="100%">
                <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.TEST-NAME' | translate }}</label>
                <mat-form-field floatLabel="always"
                    class="spefieldnowrap less_top_margin_field two">
                    <input matInput type="text" formControlName="Name" />
                    <mat-error *ngIf="form.get('Name').hasError('maxlength')">
                        {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ADD-TEST-MAX-CHARACTERS' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('Name').hasError('required') && submitted">
                        {{'FIELD-REQUIRED' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('Name').hasError('nameExists') && submitted">
                        {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.TEST-NAME-EXISTS' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="0 0 calc(50% - 1rem)" fxFlex.xs="100%">
                <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.LEVEL' | translate }}</label>
                <mat-form-field floatLabel="always"
                    class="spefieldnowrap less_top_margin_field two">
                    <mat-select formControlName="TLevel" [ngModelOptions]="{standalone: true}">
                        <mat-option class="scrollable" [value]="course.Level" *ngFor="let course of courses">{{
                            course.Name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </section>
        <section fxLayout="row wrap" fxLayoutAlign="space-between center" class="spe_gap" style="margin-top: -1em;">
            <div fxFlex="0 0 calc(100%)" fxFlex.xs="100%">
                <label class="font_blue" >{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.OPTIONS' | translate }}</label>
                <mat-form-field floatLabel="always"
                    class="spefieldnowrap less_top_margin_field two">
                    <mat-select formControlName="ShareTest" [ngModelOptions]="{standalone: true}">
                        <mat-option class="scrollable" [value]="i" *ngFor="let opt of ShareTestOpt; let i = index">
                            {{opt}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </section>
        <section [hidden]="!advancedOptionsVisible" fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="100%" fxFlex.gt-sm="" ngStyle.lt-md="margin-bottom:1rem;">
                <mat-checkbox formControlName="MessQuestions" [ngModelOptions]="{standalone: true}">
                    <span class="font_w_normal">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.MESSQUESTIONS' | translate }}</span>
                </mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.MESSQUESTIONS-INFO' | translate"></tooltip>
            </div>            
        </section>
        <section [hidden]="!advancedOptionsVisible" fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="" fxFlex.gt-sm="" ngStyle.lt-md="margin-bottom:1rem;">
                <mat-checkbox formControlName="TestFormat">
                    <span class="font_w_normal">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.TESTFORMAT' | translate }}</span>
                </mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.TESTFORMAT-INFO' | translate"></tooltip>
            </div>
        </section>
        <section [hidden]="!advancedOptionsVisible" fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="100%" fxFlex.gt-sm="" ngStyle.lt-md="margin-bottom:1rem;">
                <mat-checkbox formControlName="MultipleCorrectAnswers">
                    <span class="font_w_normal">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.MULTIPLECORRECTANSWERS' | translate }}</span>
                </mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.MULTIPLECORRECTANSWERS-INFO' | translate"></tooltip>
            </div>
        </section>
        
        <section [hidden]="!advancedOptionsVisible" fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="100%" fxFlex.gt-sm="" ngStyle.lt-md="margin-bottom:1rem;">
                <mat-checkbox formControlName="ProportionalScore">
                    <span class="font_w_normal">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.PROPORTIONALSCORE' | translate }}</span>
                    <tooltip position="L" (click)="($event.event || $event).preventDefault(); " [info]="'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.PROPORTIONALSCORE-INFO' | translate"></tooltip>
                </mat-checkbox>                
            </div>
        </section>
        <section [hidden]="!advancedOptionsVisible" fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="100%" fxFlex.gt-sm="" ngStyle.lt-md="margin-bottom:1rem;">
                <mat-checkbox formControlName="AutoOpenTest">
                    <span class="font_w_normal">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.AUTOOPENTEST' | translate }}
                    </span>
                </mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.AUTOOPENTEST-INFO' | translate"></tooltip>
            </div>
        </section>
        <div class="walinwa-row spe_gap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
            <ng-container *ngIf="currentOperation === OPERATION.Join">
                <button type="button" class="new_but azul" (click)="advancedOptionsVisible = !advancedOptionsVisible">
                    {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ADVANCED-OPTIONS' | translate }}
                </button>
                <button [disabled]="loading || form.invaid" class="new_but azul" (click)="joinTests()">
                    {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.GO-TO-QUESTIONS' | translate }}
                </button>
            </ng-container>

            <ng-container *ngIf="currentOperation === OPERATION.Clone">
                <button type="button" class="new_but azul" (click)="advancedOptionsVisible = !advancedOptionsVisible">
                    {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ADVANCED-OPTIONS' | translate }}
                </button>
                <button [disabled]="loading || form.invalid" class="new_but azul" (click)="cloneTests()">
                    {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.GO-TO-QUESTIONS' | translate }}
                </button>
            </ng-container>

            <ng-container *ngIf="currentOperation === OPERATION.Edit">
                <button type="button" class="new_but azul" (click)="advancedOptionsVisible = !advancedOptionsVisible">
                    {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ADVANCED-OPTIONS' | translate }}
                </button>
                <button type="button" [disabled]="loading" class="new_but azul" (click)="editPersonalizedTestQuestions(currentTest, true)">
                    {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.EDIT-QUESTIONS' | translate }}
                </button>
                <button [disabled]="loading" class="new_but azul" (click)="saveTest()">
                    {{'FINISH' | translate }}
                </button>   
            </ng-container> 

            <ng-container *ngIf="currentOperation === OPERATION.Create">
                <button type="button" class="new_but azul" (click)="advancedOptionsVisible = !advancedOptionsVisible">
                    {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ADVANCED-OPTIONS' | translate }}
                </button>
                <button type="button" [disabled]="loading" class="new_but azul" (click)="editPersonalizedTestQuestions(null, true)">
                    {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CREATE-QUESTIONS' | translate }}
                </button>
                <button [disabled]="loading" *ngIf="!isProd && (canCreateTestWithChatGPT$ | async) !== true && (canCreateTestWithChatGPT$ | async) !== false; else checked" type='button' class="new_but azul">
                    {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CREATE-IA-QUESTIONS' | translate }}
                </button>
                <ng-template #checked>
                    <button [disabled]="loading" *ngIf="(canCreateTestWithChatGPT$ | async) === true && !isProd" type='button' class="new_but azul" (click)="showChatGPTTestPopup()">
                        {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CREATE-IA-QUESTIONS' | translate }}
                    </button>    
                    <button [disabled]="loading" *ngIf="(canCreateTestWithChatGPT$ | async) === false && !isProd" type='button' class="new_but azul" (click)="showErrorMessage('Generar Test con IA', 'Ha excedido los de test que puede generar con IA al día.<br>Por favor, vuelva a intentarlo mañana.')">
                        {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CREATE-IA-QUESTIONS' | translate }}
                    </button>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="currentOperation === OPERATION.View">
                <button type="button" class="new_but azul" (click)="advancedOptionsVisible = !advancedOptionsVisible">
                    {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ADVANCED-OPTIONS' | translate }}
                </button>
                <button type="button" [disabled]="loading" class="new_but azul" (click)="editPersonalizedTestQuestions(currentTest) ">
                    {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.SHOW-QUESTIONS' | translate }}
                </button>
            </ng-container>

            <!--ng-container *ngIf="editable; else onlyView">

                <ng-container *ngIf="currentTest === null">
                    <button type="button" [disabled]="loading" class="new_but azul" (click)="editPersonalizedTestQuestions(null, true)">
                        Crear preguntas
                    </button>
                    <button *ngIf="(canCreateTestWithChatGPT$ | async) !== true && (canCreateTestWithChatGPT$ | async) !== false; else checked" type='button' class="new_but azul" [disabled]="true">
                        Crear preguntas IA
                    </button>
                    <ng-template #checked>
                        <button *ngIf="(canCreateTestWithChatGPT$ | async) === true" type='button' class="new_but azul" (click)="showChatGPTTestPopup()">
                            Crear preguntas IA
                        </button>    
                        <button *ngIf="(canCreateTestWithChatGPT$ | async) === false" type='button' class="new_but azul" (click)="showErrorMessage('Generar Test con IA', 'Ha excedido los de test que puede generar con IA al día.<br>Por favor, vuelva a intentarlo mañana.')">
                            Crear preguntas IA
                        </button>
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="currentTest !== null && Operation.Edit">
                    <button type="button" [disabled]="loading" class="new_but azul" (click)="editPersonalizedTestQuestions(currentTest, true)">
                        Editar preguntas
                    </button>
                    <button [disabled]="loading" class="new_but azul" (click)="saveTest()">
                        Finalizar
                    </button>    
                </ng-container>

            </ng-container>
            <ng-template #onlyView>
                <button type="button" [disabled]="loading" class="new_but azul" (click)="editPersonalizedTestQuestions(currentTest) ">
                    Ver preguntas
                </button>
            </ng-template>
        </ng-container-->

        </div>
    </form>

    <form [formGroup]="questionsForm" class="walinwa-box-content" 
        *ngIf="navigationService.StateTest=='add-personalizedTestQuestions'">
        <div>
            {{personalizedTest?.Name}}
        </div>
        <div formArrayName="Questions" cdkDropList fxLayout="column" fxLayoutAlign="stretch" class="spe_gap" (cdkDropListDropped)="drop($event)">
            <div cdkDrag [cdkDragDisabled]="currentOperation === OPERATION.View" class="custom-form-field" *ngFor="let question of Questions().controls; let questionIndex = index">
                <div [ngStyle]="{'margin-top': questionIndex > 0 ? '1em' : '0'}" >
                    <mat-icon *ngIf="currentOperation !== OPERATION.View" class="move_handler" cdkDragHandle style="vertical-align: middle !important; height: 18px !important;">open_with</mat-icon>
                    <label style="display: inline; vertical-align: top;">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.QUESTION-NUMBER' | translate }} {{questionIndex + 1}}</label>                    
                </div>
                <div>
                    <div [formGroupName]="questionIndex" fxLayout="column" fxLayoutAlign="stretch" class="spe_gap">
                        <input type="hidden" formControlName="IdQuestion">
                        <input type="hidden" formControlName="ChatGPT">
                        <div class="custom-form-field spe_gap" fxLayout="row" fxLayoutAlign="space-between center">
                            <div fxFlex="">
                                <textarea id="focused{{question.value.IdQuestion}}"
                                    [ngStyle]="question.value.Question.length <= 120 ? {height: '1.5em;'} :{height: '2.5em;'}"
                                    type="text" formControlName="Question" [placeholder]="'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.QUESTION-NAME' | translate"
                                    maxlength="500" [readonly]="currentOperation === OPERATION.View"></textarea>
                            </div>
                            <div fxFlex="20px" *ngIf="currentOperation !== OPERATION.View">
                                <img class="spe_ico_exam" src="https://walinwa.blob.core.windows.net/images/School/ico-trash.svg?ngsw-bypass=true"
                                    (click)="removeQuestion(question.value.IdQuestion)">
                            </div>
                        </div>
                        <div formArrayName="TestAnswer" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="5px">                            
                            <div class="custom-form-field" *ngFor="let answer of QuestionAnswers(question.value.IdQuestion).controls; let answerIndex = index">
                                <div [formGroupName]="answerIndex" fxLayout="row" fxLayoutAlign="space-between center"
                                    class="spe_gap">
                                    <input type="hidden" formControlName="IdQuestion">
                                    <input type="hidden" formControlName="IdAnswer">
                                    <div fxFlex="20px">
                                        <mat-checkbox (change)="changeCorrectAnswer(question.value.IdQuestion, answer.value.IdAnswer)"
                                            [checked]="answer.value.IsCorrect" [disabled]="currentOperation === OPERATION.View">
                                        </mat-checkbox>
                                    </div>
                                    <div fxFlex="">
                                        <input (dblclick)="currentOperation !== OPERATION.View ? changeCorrectAnswer(question.value.IdQuestion, answer.value.IdAnswer) : null"
                                            (ngModelChange)="detectNewAnswer(question.value.IdQuestion, answerIndex + 1)" type="text"
                                            formControlName="Answer" [placeholder]="'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ANSWER-NAME' | translate" [readonly]="currentOperation === OPERATION.View">
                                    </div>
                                    <div fxFlex="20px" *ngIf="currentOperation !== OPERATION.View && !isLatest(question.value.IdQuestion, answerIndex + 1)">
                                        <img class="spe_ico_exam" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true"
                                            (click)="removeQuestionAnswer(question.value.IdQuestion, answer.value.IdAnswer)">
                                    </div>
                                </div>
                            </div>
                            <div class="ww_messagin" *ngIf="currentOperation !== OPERATION.View && isLastQuestion(questionIndex) && addedNewQuestion">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.QUESTION-MESSAGE' | translate }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showMessage && currentOperation !== OPERATION.View" class="error-message">
            <mat-error>{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.QUESTION-ERROR' | translate }}</mat-error>
        </div>
        <div *ngIf="currentOperation !== OPERATION.View" class="walinwa-row spe_gap" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
            <button [disabled]="loading" class="new_but azul"(click)="addQuestion(null, true)">
                {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ADD-QUESTION' | translate }}
            </button>
            <button [disabled]="loading" class="new_but azul"
                (click)="saveQuestion()">
                {{'FINISH' | translate }}
            </button>
        </div>
    </form>
</div>
<personalized-test 
*ngIf="personalizedTestService.doTest"
[review]="true" 
[teacherReview]="true" 
[IdTest]="currentTest.IdTest" 
[date]="currentTest.Date"
[visualize]="true"
[IdUser]="currentTest.IdUser">
</personalized-test>
<!--app-message-popup #chatGPTTestRequestExceededPopup [title]="'Generar Test con IA'" [message]="'Ha excedido los de test que puede generar con IA al día.<br>Por favor, vuelva a intentarlo mañana.'"></app-message-popup-->
<!--app-message-popup #notTestForJoinSelectedPopup [title]="'Fusionar test'" [message]="'Seleccione 2 o más test para realizar la fusión en un nuevo test.'"></app-message-popup-->
<app-personalized-test-chatgpt-popup *ngIf="chatGPTTestPopupVisible" (onCancel)="chatGPTTestPopupVisible = false" (onAccept)="getChatGPTTest($event)"></app-personalized-test-chatgpt-popup>
<!--app-personalized-test-join-tests-popup *ngIf="joinTestPopupVisible" [userAvailablePersonalizedTest]="personalizedTests" (onCancel)="joinTestPopupVisible = false" (onAccept)="joinTests($event)"></app-personalized-test-join-tests-popup-->
