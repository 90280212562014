<div class="walinwa-box walinwa-box-green walinwa-100" *ngIf="dataLoaded">

    <div class="walinwa-box-header">
        {{'SCHOOL.NEW-STUDENT-TITLE' | translate}}
    </div>

    <form [formGroup]="formDataStudent" class="walinwa-box-content" *ngIf="formDataStudent" autocomplete="new-password">

        <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
            <div fxFlex="" fxFlex.xs="100%">
                <label class="font_blue">{{'NAME' | translate}}</label>
                <mat-form-field class="less_top_margin_field two">
                    <input maxlength="41" formControlName="Name" matInput type="text" class="form-control">
                    <mat-error *ngIf="formDataStudent.get('Name').hasError('required')">
                        {{'FIELD-REQUIRED' | translate}}
                    </mat-error>
                    <mat-error *ngIf="formDataStudent.get('Name').hasError('pattern')">
                        {{'PATTERN-ERROR' | translate}}
                    </mat-error>
                    <mat-error *ngIf="formDataStudent.get('Name').hasError('maxlength')">
                        {{'NAME-MAX' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="" fxFlex.xs="100%">
                <label class="font_blue">{{'FAMILYNAME' | translate}}</label>
                <mat-form-field class="less_top_margin_field two" floatLabel="always">
                    <input maxlength="51" formControlName="FamilyName" matInput type="text" class="form-control">
                    <mat-error *ngIf="formDataStudent.get('FamilyName').hasError('pattern')">
                        {{'PATTERN-ERROR' | translate}}
                    </mat-error>
                    <mat-error *ngIf="formDataStudent.get('FamilyName').hasError('maxlength')">
                        {{'FAMILYNAME-MAX' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
            <div fxFlex="" fxFlex.xs="100%">
                <label class="font_blue"[innerHTML]="'SCHOOL.AUTOMATIC-USER' | translate"></label>
                <div class="ww_field_spe">
                    <input formControlName="Username" disabled matInput type="text" class="form-control">
                </div>
                <div class="legend">
                    {{'SCHOOL.AUTOMATIC-USER-TEXT' | translate }}
                </div>
            </div>
            <div fxFlex="" fxFlex.xs="100%">
                <label class="font_blue"><b>{{'SCHOOL.INITIAL-PASSWORD' | translate}}</b></label>
                <div class="ww_field_spe">
                    <input formControlName="Password" disabled matInput class="form-control" style="background-color: transparent !important; padding-left: 0px !important;">
                </div>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
            <button [disabled]="calling" (click)="getStudentName()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>
        </div>

    </form>

</div>