<div class="walinwa-box-with-tabs" fxNgClass.gt-sm="scroll_vertical">
    <div class="walinwa-tabs walinwa-tabs-top">
        <div [ngClass]="{ 'selected': state == 'mail' }" (click)="editMailConfig()" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.CONFIG.EMAILS-TITLE' | translate}}</div>
        <div [ngClass]="{ 'selected': state == 'options' }" (click)="options()" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.CONFIG.OPTIONS-TITLE' | translate}}</div>
        <!-- <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'editConfigSessions'}" (click)="editConfigSessions()" class="walinwa-tab-gray">{{'SCHOOL.REVIEW-ACTIVITIES.SESSION-TITLE' | translate}}</div>
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'editSessions'}" (click)="editSessions()" class="walinwa-tab-gray">Predefinidas</div>
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'exams'}" (click)="editExams()" class="walinwa-tab-gray">Exámenes</div>
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'dictations'}" (click)="editDicts()" class="walinwa-tab-gray">{{'SCHOOL.REVIEW-ACTIVITIES.DICTATION-TITLE' | translate}}</div>
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'games'}" (click)="editGames()" class="walinwa-tab-gray">Juegos</div>
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'options'}" (click)="options()" class="walinwa-tab-gray">Opciones</div> -->
    </div>
    <div class="walinwa-box walinwa-box-green">
        <router-outlet></router-outlet>           
    </div>    
</div>