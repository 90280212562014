<div class="walinwa-box" data-help-image-number="32">
    <div class="walinwa-box walinwa-box-green">
        <div class="gray-background walinwa-box-content">
            <div class="ww_table_rwd">
                <div class="header-container">
                    <div>{{ 'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.ASSIGN-BOOK-HINT' | translate }}</div>
                    <div class="walilee-logo">
                        <img src="https://walinwa.blob.core.windows.net/images/WaliLee/WaliLee-Solo.svg" alt="">
                    </div>
                </div>
                <dx-data-grid #dataGrid [keyExpr]="['IdLee', 'IdClass']" [allowColumnResizing]="true" [dataSource]="booksAsigned$ | async" [repaintChangesOnly]="true" (onInitNewRow)="onInitNewRow($event)" (onEditingStart)="onEditingStart($event)" (onCellPrepared)="onCellPrepared($event)" (onRowInserting)="onRowInserting($event)" (onRowUpdating)="onRowUpdating($event)" [noDataText]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.NO-DATA' | translate" [hidden]="!dataLoaded" height="100%">
                    <dxo-load-panel [enabled]="true"></dxo-load-panel>
                    <dxo-scrolling mode="standard" [useNative]="false"></dxo-scrolling>
                    <!--dxo-paging [pageSize]="12" [pageIndex]="0"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[12, 24, 36, 'all']" [showInfo]="true" [showNavigationButtons]="true"></dxo-pager-->
                    <dxo-editing mode="row" [useIcons]="true" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false"></dxo-editing>
                    <dxo-filter-row [visible]="false"></dxo-filter-row>
                    <dxo-header-filter [visible]="false"></dxo-header-filter>
                    <dxo-search-panel [visible]="false"></dxo-search-panel>
                    <dxo-group-panel [visible]="false" [allowColumnDragging]="true"></dxo-group-panel>
                    <dxo-grouping [contextMenuEnabled]="false"></dxo-grouping>                    

                    <dxi-column dataField="IdClass" [caption]="'CLASS' | translate" dataType="number" width="16%" alignment="left" [setCellValue]="setCellValue">
                        <dxo-lookup [dataSource]="classes" valueExpr="IdClass" displayExpr="Name"></dxo-lookup>
                        <dxi-validation-rule type="required" [message]="'FIELD-REQUIRED' | translate"></dxi-validation-rule>
                    </dxi-column>
                    <dxi-column dataField="IdLee" [caption]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.BOOK' | translate" dataType="number" width="49%" alignment="left" [editorOptions]="{ itemTemplate: 'editBookTemplate' }" cellTemplate="bookTemplate">
                        <dxo-lookup [dataSource]="books$ | async" valueExpr="IdLee" [displayExpr]="title">
                            <div *dxTemplate="let book of 'editBookTemplate'">
                                <span [innerHTML]="getBookTitle(book?.IdLee)"></span>
                            </div>
                        </dxo-lookup>
                        <dxi-validation-rule type="required" [message]="'FIELD-REQUIRED' | translate"></dxi-validation-rule>
                        <dxi-validation-rule type="custom" [ignoreEmptyValue]="true" [reevaluate]="true" [validationCallback]="checkIsBookSelectable" message="El libro tiene un nivel distinto al de las aulas seleccionadas."></dxi-validation-rule>
                        <div *dxTemplate="let book of 'bookTemplate'">
                            <span [innerHTML]="getBookTitle(book.data.IdLee)"></span>
                        </div>
                    </dxi-column>
                    <dxi-column dataField="StartDate" [caption]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.FROM' | translate" dataType="date" format="dd/MM/yyyy" width="15%" [allowGrouping]="false" [editorOptions]="editorOptions">
                        <dxi-validation-rule type="required" [message]="'FIELD-REQUIRED' | translate"></dxi-validation-rule>
                    </dxi-column>
                    <dxi-column dataField="EndDate" [caption]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.TO' | translate" dataType="date" format="dd/MM/yyyy" width="15%" [allowGrouping]="false" [editorOptions]="editorOptions">
                        <dxi-validation-rule type="required" [message]="'FIELD-REQUIRED' | translate"></dxi-validation-rule>
                        <dxi-validation-rule type="custom" [ignoreEmptyValue]="true" [reevaluate]="true" [validationCallback]="checkIsLaterThan" [message]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.TO-ERROR-GREATER' | translate"></dxi-validation-rule>
                    </dxi-column>

                    <dxi-column type="buttons" headerCellTemplate="headerCellTemplate">
                        <dxi-button name="edit"></dxi-button>
                        <dxi-button [text]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.DELETE' | translate" icon="trash" [hint]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.DELETE' | translate" [onClick]="onRowRemoving"></dxi-button>
                        <div *dxTemplate="let _ of 'headerCellTemplate'">
                            <dx-button icon="add" class="add-button" [hint]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.ADD-NEW-ROW' | translate" (click)="onAddRow($event)"></dx-button>
                        </div>
                    </dxi-column>
                </dx-data-grid>
            </div>
        </div>
    </div>    
</div>