<div class="walinwa-box">
    <div class="walinwa-box-content" *ngIf="navigationService.stateExam=='classes-exams'">
        <div>
            <div class="ww_table_rwd">
                <table class="walinwa-table">
                    <thead>
                        <tr>
                            <th width="15%;">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.DATE' | translate }}</th>
                            <th width="15%;">{{'CLASS' | translate }}</th>
                            <th width="">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.NAME' | translate }}</th>
                            <th style="width:50px">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let class_exam of sortData">
                            <td >{{ class_exam.formatted_date }}</td>
                            <td>
                                <span *ngIf="class_exam.Aula">{{ class_exam.Aula }}</span>
                                <span *ngIf="!class_exam.Aula">{{ class_exam.IdClass }}</span>
                            </td>
                            <td>
                                <span *ngIf="class_exam.Name">{{ class_exam.Name }}</span>
                                <span *ngIf="!class_exam.Name">{{ class_exam.IdExam }}</span>
                            </td>
                            <td style="text-align: right;">
                                <img class="spe_ico_exam" src="https://walinwa.blob.core.windows.net/images/School/ico-trash.svg?ngsw-bypass=true"
                                    (click)="deleteClassExam(class_exam)">
                            </td>
                        </tr>
                        <tr *ngIf="classes_exams.length==0">
                            <td class="font_blue" colspan="4">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.NO-ASSIGNED-EXAMS' | translate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="walinwa-row spe_gap" fxLayout="row wrap" fxLayoutAlign="end center"
            fxLayoutAlign.xs="center center">
            <button (click)="editExams()" [disabled]="loading" class="new_but azul">
                {{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.SHOW-EXAMS' | translate }}
            </button>
            <button (click)="addClassExam()" [disabled]="loading" class="new_but azul">
                {{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.ASSIGN-NEW' | translate }}
            </button>
        </div>
    </div>

    <div class="walinwa-box-content spe_gap" *ngIf="navigationService.stateExam=='add-class-exam'">
        <div *ngIf="navigationService.add_class_step==1" [innerHTML]="'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.ADD-EXAM-TITLE1' | translate">
        </div>
        <div *ngIf="navigationService.add_class_step==2">
            {{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.ADD-EXAM-TITLE2' | translate }}
        </div>
        <div *ngIf="navigationService.add_class_step==3">
            {{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.ADD-EXAM-TITLE3' | translate }}
        </div>
        <div *ngIf="navigationService.add_class_step==1">
            <calCustomizedSession class="calendario dinamico walCalendar azul ww_customCalendar" [isExamen]="true"
                [parent]="this" [currentUser]="calendar.current_user" [disablePast]="true"
                [selectedDay]="calendar.selected_day" [currentMonth]="calendar.current_month"
                [customizedSessions]="customizedSessions">
            </calCustomizedSession>
        </div>
        <div *ngIf="navigationService.add_class_step==2">
            <div class="makeScroll" fxFlex="100%" fxFlex.gt-xs="300px">
                <mat-checkbox *ngFor="let class of classes | sortBy:'asc':['Level','Code']"
                    (change)="selectClass(class)" [checked]="class.selected">
                    <span>{{ class.Name }}</span>
                </mat-checkbox>
            </div>
        </div>
        <div *ngIf="navigationService.add_class_step==2 && showMessage" class="error-message">
            <mat-error>
                <div *ngFor="let message of messages">
                    {{message}}
                </div>
            </mat-error>
        </div>
        <div>
            <table class="walinwa-table"
                *ngIf="navigationService.add_class_step==3 || navigationService.add_class_step==-1">
                <thead>
                    <tr>
                        <th width="70%;">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.EXAM-NAME' | translate }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let exam of exams" class="walinwa-table-row" [ngClass]="{ selected: exam.selected }">
                        <td (dblclick)="startChangingExamName(exam)" *ngIf="!exam.editingExamName"
                            (click)="selectExam(exam)">{{ exam.Name }}</td>
                        <td *ngIf="exam.editingExamName">
                            <input required (blur)="changeExamName(exam)" id="change-name" type="text"
                                [value]="exam.Name" />
                            <mat-error *ngIf="nameExists">
                                {{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.NAME-EXISTS' | translate }}
                            </mat-error>
                            <mat-error *ngIf="isRequired">
                                {{ 'FIELD-REQUIRED' | translate }}
                            </mat-error>
                        </td>
                        <td style="text-align: right;">
                            <img class="spe_ico_exam" src="https://walinwa.blob.core.windows.net/images/School/ico-edit.svg?ngsw-bypass=true"
                                (click)="editExamExercises(exam)" />
                            <img class="spe_ico_exam" src="https://walinwa.blob.core.windows.net/images/School/ico-trash.svg?ngsw-bypass=true" (click)="deleteExam(exam)" />
                        </td>
                    </tr>
                    <tr *ngIf="exams.length==0">
                        <td class="font_blue" colspan="2">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.NO-EXAMS' | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="walinwa-row spe_gap" fxLayout="row wrap" fxLayoutAlign="end center"
            fxLayoutAlign.xs.="center center">
            <div *ngIf="navigationService.add_class_step < 3"></div>
            <button (click)="addExam()" *ngIf="navigationService.add_class_step == 3 || showExamList"
                [disabled]="loading" class="new_but azul">
                {{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.NEW-EXAM' | translate }}
            </button>
            <button (click)="continue()" *ngIf="navigationService.add_class_step!=-1"
                [disabled]="loading || disableWizard()" class="new_but azul">
                <span *ngIf="navigationService.add_class_step < 3">{{'CONTINUE' | translate }}</span>
                <span *ngIf="navigationService.add_class_step == 3">{{'FINISH' | translate }}</span>
            </button>
        </div>
    </div>

    <form (submit)="editExamExercises(null,true)" [formGroup]="form" class="walinwa-box-content walinwa-100"
        *ngIf="navigationService.stateExam=='add-exam'">
        <div>
            <h2>
                {{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.ADD-EXAM-TITLE4' | translate }}
            </h2>
            <section fxLayout="row wrap" fxLayoutAlign="space-between center" class="spe_gap">
                <div fxFlex="0 0 calc(100%)" fxFlex.xs="100%">
                    <mat-form-field floatLabel="always"
                        class="spefieldnowrap less_top_margin_field two">
                        <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.EXAM-NAME' | translate }}</label>
                        <input matInput type="text" formControlName="Name" />
                        <mat-error *ngIf="form.get('Name').hasError('required') && submitted">
                            {{ 'FIELD-REQUIRED' | translate }}
                        </mat-error>
                        <mat-error class="nameExists" *ngIf="form.get('Name').hasError('nameExists')">
                            {{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.NAME-EXISTS' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </section>
            <section fxLayout="row wrap" fxLayoutAlign="space-between center" class="spe_gap">
                <div fxFlex="0 0 calc(100%)" fxFlex.xs="100%">
                    <mat-form-field floatLabel="always"
                        class="spefieldnowrap less_top_margin_field two">
                        <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.COURSE' | translate }}</label>
                        <mat-select formControlName="Level">
                            <mat-option class="scrollable" [value]="course.Level" *ngFor="let course of courses">{{course.Name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('Level').hasError('required') && submitted">
                            {{ 'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </section>
        </div>
        <div class="walinwa-row" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
            <button [disabled]="loading" class="new_but azul">
                {{'START' | translate }}
            </button>
        </div>

    </form>
</div>

<div class="walinwa-box">
    <div class="walinwa-overlay walinwa-overlay-exam-exercises-cs"
        *ngIf="navigationService.stateExam=='edit-exam-exercises'">
        <div cdkDropList class="slides" id="scroll" (cdkDropListDropped)="drop($event)">
            <div [attr.index]="i" class="slide dropzone" [ngClass]="{ active: i == current_exercise }"
                *ngFor="let exercise of exercises; let i = index" cdkDrag>
                <div class="slide-number">
                    <span>{{ i + 1 }}</span>
                    <mat-icon class="move_handler" cdkDragHandle>open_with</mat-icon>
                </div>
                <div class="slide-content" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Themes/PrehistoricTheme/shop/110.jpg&quot;)'" [attr.index]="i">
                    <div class="but_gen_a"><mat-icon>style</mat-icon></div>
                    <div class="clicable" (click)="selectSlide(i, true)">
                        <app-mini-exercise [review]="false" #slide [id]="'ex-focus'+i"
                            class="slide-model-container-exer" [exerciseModel]="exercise.ExerciseModel" [slide]="true"
                            [exercise]="exercise" [currentExercise]="current_exercise"></app-mini-exercise>
                    </div>
                </div>
            </div>
        </div>
        <div class="current-slide walinwa-box-content">
            <div *ngIf="!exam" class="title spe_gap" fxLayout="row nowrap" fxLayoutAlign="space-between center">
                <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap">
                    <mat-icon class="icopanelslides pointer" fxHide fxShow.lt-md
                        (click)="OpenPanelSlidesMobile();">format_indent_decrease</mat-icon>
                    <span>{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.CREATE' | translate }} {{ form.get('Name').value }}</span>
                    <img src="https://walinwa.blob.core.windows.net/images/ico-info-white.svg?ngsw-bypass=true" fxHide.gt-md fxShow fxFlex="2rem"
                        (click)="ShowHideHelpPopup()" />
                </div>
                <img (click)="cancel(false)" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" class="pointer" fxFlex="2rem">
            </div>
            <div *ngIf="exam" class="title spe_gap" fxLayout="row nowrap" fxLayoutAlign="space-between center">
                <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap">
                    <mat-icon class="icopanelslides pointer" fxHide fxShow.lt-md
                        (click)="OpenPanelSlidesMobile();">format_indent_decrease</mat-icon>
                    <span>{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.EDIT' | translate }} {{ exam.Name }}</span>
                    <img src="https://walinwa.blob.core.windows.net/images/ico-info-white.svg?ngsw-bypass=true" fxHide.gt-md fxShow fxFlex="2rem"
                        (click)="ShowHideHelpPopup()" />
                </div>
                <img (click)="cancel(true)" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" class="pointer" fxFlex="2rem">
            </div>
            <div class="current-slide-content">
                <div class="slide-title-content" fxHide fxShow.gt-md>
                    <h2 *ngIf="!exam">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.CREATE-TITLE' | translate }}</h2>
                    <h2 *ngIf="exam">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.EDIT-TITLE' | translate }}</h2>
                </div>
                <div class="slide-config">
                    <div class="custom-form-field" fxFlex="40%" fxFlex.xs="100%">
                        <mat-form-field>
                            <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.EXERCISE-TYPE' | translate }}</label>
                            <mat-select [disabled]="!exercises[current_exercise].temporal"
                                (selectionChange)="getNewExercise(current_exercise, false, false)" class="valor"
                                [(ngModel)]="exerciseType">
                                <mat-option class="scrollable" [value]="null">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.SELECT' | translate }}</mat-option>
                                <mat-option class="scrollable" [value]="type.Name" *ngFor="let type of types">{{
                                    type.Name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="custom-form-field" fxFlex="40%" fxFlex.xs="100%">
                        <mat-form-field>
                            <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.THEME' | translate }}</label>
                            <mat-select [disabled]="!exercises[current_exercise].temporal"
                                (selectionChange)="getNewExercise(current_exercise, false, false)" [(ngModel)]="thema">
                                <mat-option class="scrollable" [value]="null">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.SELECT' | translate }}</mat-option>
                                <mat-option class="scrollable" [value]="i" *ngFor="let thema of availableThemas(); let i = index"><span [innerHtml]="getName(thema.Name)"></span></mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex="" fxLayout="row" fxLayoutAlign="space-between stretch">
                    <div fxFlex="2.5rem" fxLayoutAlign="left center" class="handler-left-box">
                        <div class="handler-left pointer">
                            <i class="fa fa-chevron-left" (click)="before(current_exercise - 1,true)"></i>
                        </div>
                    </div>
                    <div fxFlex="">
                        <div class="slide-content" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Themes/PrehistoricTheme/shop/110.jpg&quot;)'">
                            <div *ngIf="exercises[current_exercise]" class="box_prev_excersice">
                                <app-mini-exercise [review]="false" *ngIf="!loading" class="cs-model-container-exer"
                                    [exerciseModel]="exercises[current_exercise].ExerciseModel"
                                    [exercise]="exercises[current_exercise]"
                                    [currentExercise]="current_exercise"></app-mini-exercise>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="2.5rem" fxLayoutAlign="end center" class="handler-right-box">
                        <div class="handler-right pointer">
                            <i class="fa fa-chevron-right" (click)="next(current_exercise + 1,true)"></i>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-between center"
                    fxLayoutAlign.xs="center center" class="button-container spe_gap">
                    <button (click)="removeExercise()" [disabled]="loading || (exercises?.length <= 1) " class="new_but azul" fxHide.xs fxShow>
                        {{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.DELETE-EXERCISE' | translate }}
                    </button>
                    <!-- Mobile Button -->
                    <div class="but_gen_a" (click)="removeExercise()" [disabled]="loading || (exercises?.length <= 1)"
                        fxHide fxShow.xs>
                        <mat-icon>layers_clear</mat-icon>
                    </div>

                    <button *ngIf="!exam"
                    (click)="getNewExercise(current_exercise+1, !exercises[current_exercise].temporal)"
                    [disabled]="loading" class="new_but azul" fxHide.xs fxShow>
                    {{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.ADD-EXERCISE' | translate }}
                    </button>
                    <!-- Mobile Button -->
                    <div class="but_gen_a" *ngIf="!exam"
                    (click)="getNewExercise(current_exercise+1, !exercises[current_exercise].temporal)"
                    [disabled]="loading"
                        fxHide fxShow.xs>
                        <mat-icon>library_add</mat-icon>
                    </div>

                    <button *ngIf="exam" (click)="getNewExercise(current_exercise+1, false)"
                    [disabled]="loading" class="new_but azul" fxHide.xs fxShow>
                    {{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.ADD-NEW-EXERCISE' | translate }}
                    </button>
                    <!-- Mobile Button -->
                    <div class="but_gen_a" *ngIf="exam" (click)="getNewExercise(current_exercise+1, false)"
                    [disabled]="loading" fxHide fxShow.xs>
                        <mat-icon>library_add</mat-icon>
                    </div>

                    <button (click)="getNewExercise(current_exercise, false, false)" [disabled]="loading"
                    class="new_but azul" fxHide.xs fxShow>
                    {{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.REPLACE-EXERCISE' | translate }}
                    </button>
                    <!-- Mobile Button -->
                    <div class="but_gen_a" (click)="getNewExercise(current_exercise, false, false)" [disabled]="loading"
                        fxHide fxShow.xs>
                        <mat-icon>find_replace</mat-icon>
                    </div>

                    <button (click)="finishEditExercises()" [disabled]="loading" class="new_but azul" fxHide.xs
                        fxShow>
                        {{'FINISH' | translate }}
                    </button>
                    <!-- Mobile Button -->
                    <div class="but_gen_a" (click)="finishEditExercises()" [disabled]="loading" fxHide fxShow.xs>
                        <mat-icon>check</mat-icon>
                    </div>
                </div>
            </div>
            <div class="shadow_slides_close" id="shadow_slides_close" fxHide fxShow.lt-md>
                <img src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" class="pointer close_icon_overlay"
                    (click)="ClosePanelSlidesMobile();">
            </div>
        </div>
    </div>
</div>

<div class="walinwa-overlay extra" [hidden]="infoPopupHelp">
    <div class="infopopup spe_gap" fxLayout="column" fxLayoutAlign="stretch">
        <div>
            <span *ngIf="!exam">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.CREATE-TITLE' | translate }}</span>
            <span *ngIf="exam">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.EDIT-TITLE' | translate }}</span>
            <div class="walinwa-100 mt-8 " fxHide fxShow.xs>
                <div class="mb-8"><b>Leyenda:</b></div>
                <div fxLayout="column" class="spe_gap leyenda_icons">
                    <div fxLayout="row nowrap" fxLayoutAlign="start center" class="spe_gap">
                        <div><mat-icon>layers_clear</mat-icon></div>
                        <div>{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.DELETE-EXERCISE' | translate }}</div>
                    </div>
                    <div fxLayout="row nowrap" fxLayoutAlign="start center" class="spe_gap" *ngIf="!exam">
                        <div><mat-icon>library_add</mat-icon></div>
                        <div>{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.ADD-EXERCISE' | translate }}</div>
                    </div>
                    <div fxLayout="row nowrap" fxLayoutAlign="start center" class="spe_gap" *ngIf="exam">
                        <div><mat-icon>library_add</mat-icon></div>
                        <div>{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.ADD-NEW-EXERCISE' | translate }}</div>
                    </div>
                    <div fxLayout="row nowrap" fxLayoutAlign="start center" class="spe_gap">
                        <div><mat-icon>find_replace</mat-icon></div>
                        <div>{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.REPLACE-EXERCISE' | translate }}</div>
                    </div>
                    <div fxLayout="row nowrap" fxLayoutAlign="start center" class="spe_gap">
                        <div><mat-icon>check</mat-icon></div>
                        <div>{{'FINISH' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
            <button class="new_but" (click)="ShowHideHelpPopup()">
                {{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS.CLOSE' | translate }}
            </button>
        </div>
    </div>
</div>