<div class="walinwa-box">
    <form [formGroup]="form" class="walinwa-box-content">
        <div>
            <label class="font_blue">{{'STUDENTS-LANGUAJE' | translate }}</label>
            <mat-form-field class="less_top_margin_field">
                <mat-select formControlName="Language">
                    <ng-container *ngIf="languages$ | async as languages">
                        <mat-option class="scrollable" *ngFor="let language of languages" [value]="language.IdLanguage">{{ language.Language }}</mat-option>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="form.get('Language').hasError('required')">
                    {{'FIELD-REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="walinwa-row" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
            <button (click)="save()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>
        </div>
    </form>
</div>