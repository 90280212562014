import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/services/auth.service';
import * as moment from 'moment';
import 'moment/locale/es'

@Component({
  selector: 'cal-dictations',
  templateUrl: './cal-dictations.component.html',
  styleUrls: ['./cal-dictations.component.scss']
})
export class CalDictationsComponent implements OnInit, OnChanges {

  @Input() fullDay;
  @Input() currentUser;
  @Input() selectedDay;
  @Input() currentMonth;
  @Input() dictations;
  @Input() parent;
  @Input() showAll;
  @Input() licenseDate;
  translatedMonth: string = '';
  calendarUser;
  month;
  weeks;
  SessionStatus = {
    finished: "FINISHED",
    started: "STARTED",
    newSession: "NEW",
    generating: "GENERATING",
    notfinished: "NOTFINISHED"
  }

  constructor(private authService: AuthService, private _translateService: TranslateService) {
    moment.locale('es')
  }

  ngOnInit() {
    this.calendarUser = this.currentUser;
    if (this.selectedDay == null || this.selectedDay.clone === "undefined") return;
    let currentDay = this.selectedDay.clone();
    this.month = currentDay.clone();
    var start = currentDay.clone();
    start.date(1);
    this._buildMonth(start, this.month);
    if (this.month.month() != moment().month()) this.selectedDay = null;
    this.selectToday();
    this.replaceMonth();
  }

  ngOnChanges() {
    setTimeout(() => {
      let currentDay = this.selectedDay.clone();
      this.month = currentDay.clone();
      let start = currentDay.clone();
      start.date(1);
      this._buildMonth(start, this.month);
    }, 100)
  }

  getLastMonth() {
    let month = this.getCurrentMonth() - 1;
    if (month > 12) month = 1;
    if (month < 1) month = 12;
    return month;
  }

  getCurrentMonth() {
    return moment().month() + 1;
  }

  getNextMonth() {
    let month = this.getCurrentMonth() + 1;
    if (month > 12) month = 1;
    if (month < 1) month = 12;
    return month;
  }

  getDayClass(day) {
    let classes = [];
    if (this.showAll) {
        let month = day.date.month() + 1;
            if (month == this.getCurrentMonth() 
            || month == this.getNextMonth() ) {
                if((this.licenseDate != null && day.date.isSameOrBefore(moment(this.licenseDate)))){
                    classes.push("notDone2");
                }
            }
    } else {
        if (this.isDone(day.date) && day.date.month() == this.selectedDay.month()) {
            classes.push("isDone");
        }
        if (this.notDone(day.date) && day.date.month() == this.selectedDay.month()) {
            classes.push("notDone");
        }
        if (!this.isAvailableMonth(day.date) && day.date.month() == this.selectedDay.month()) {
            classes.push("disabled-month");
        }
        if (day.date.month() != this.selectedDay.month()){
            classes.push("different-month");
        } 
    }
    if (this.isToday(day.date)){
        classes.push("currentSession");
    } 
    if (day.date.isSame(this.selectedDay)){
        classes.push("selected");
    } 
    return classes.join(" ");
  }

  selectToday() {
    for (let week of this.weeks) {
      for (let day of week.days) {
        if (!this.isToday(day.date)) continue;
        this.select(day);
        return;
      }
    }
  }

  isToday(date) {
    return date.isSame(new Date(), "day");
  }

  selectedDayChanged(date,day?) {
    let currentDay = this.selectedDay.clone();
    this.month = currentDay.clone();
    var start = currentDay.clone();
    start.date(1);
    this._buildMonth(start, this.month);

    let canDoIt = false;
    if (this.showAll) {
      let month = date.month() + 1;
      if (this.authService.currentUser.IdSchool == 1) {
        if (month == this.getCurrentMonth() || month == this.getLastMonth() || month == this.getNextMonth()) {
          canDoIt = true;
        }
      } else {
        if (month == this.getCurrentMonth() || month == this.getNextMonth() || month == this.getLastMonth()) {
          canDoIt = true;
        }
      }
    }

    this.parent.selectedDayChangedDictation(date, canDoIt,day);
    this.replaceMonth()
  }

  select(day) {
    this.selectedDay = day.date;
    this.selectedDayChanged(this.selectedDay,day);
  }

  next() {
    var next = this.month.clone();
    this._removeTime(next.month(next.month() + 1)).date(1);
    this.month.month(this.month.month() + 1);
    this.selectedDay = this.month;
    this.selectedDayChanged(this.selectedDay);
  }

  previous() {
    var previous = this.month.clone();
    this._removeTime(previous.month(previous.month() - 1).date(1));
    this.month.month(this.month.month() - 1);
    this.selectedDay = this.month;
    this.selectedDayChanged(this.selectedDay);
  }

  isDone(date) {
    if (this.dictations != null) {
      for (var i = 0; i < this.dictations.length; i++) {
        var dictado = this.dictations[i];
        if (dictado.DictDate.split('T')[0] == date.format('YYYY-MM-DD') && dictado.Score >= 0) {
            return true;
        }
      }
    }
    return false;
  }

  finished(date) {
    if (!this.calendarUser || !this.calendarUser.Sessions)
      return false;
    for (var i = 0; i < this.calendarUser.Sessions.length; i++) {
      var exDate = moment(this.calendarUser.Sessions[i].ExecutionDate);
      if (this.calendarUser.Sessions[i].ExecutionDate && this.calendarUser.Sessions[i].Status == this.SessionStatus.finished &&
        date.isSame(exDate, "day"))
        return true;
    }
  }

  isFailed(date) {
    var result = false;
    if (this.dictations != null) {
      for (var i = 0; i < this.dictations.length; i++) {
        var dictado = this.dictations[i];
        var dictado_2 = date.week() + '_' + date.isoWeekday();
        if (dictado.Dictado == dictado_2) {
          if (dictado.Score != 0)
            return false;
          result = true;
        }
      }
    }
    return result;
  }

  // started(date) {
  //   if (!this.calendarUser || !this.calendarUser.Sessions)
  //     return false;
  //   for (var i = 0; i < this.calendarUser.Sessions.length; i++) {
  //     var exDate = moment(this.calendarUser.Sessions[i].ExecutionDate);
  //     if (this.calendarUser.Sessions[i].ExecutionDate && (this.calendarUser.Sessions[i].Status == this.SessionStatus.started || this.calendarUser.Sessions[i].Status == this.SessionStatus.notfinished) &&
  //       date.isSame(exDate, "day"))
  //       return true;
  //   }
  // }

  // currentSession(date) {
  //   if (!this.calendarUser || !this.calendarUser.Sessions)
  //     return false;
  //   for (var i = 0; i < this.calendarUser.Sessions.length; i++) {
  //     if (!this.calendarUser.Sessions[i].ExecutionDate && this.calendarUser.Sessions[i].Status == this.SessionStatus.newSession &&
  //       date.isSame(moment(), "day"))
  //       return true;
  //   }
  // }

  notDone(date) {
    if (!this.calendarUser || !this.dictations)
      return false;
    if (moment().diff(date, 'days') <= 0)
      return false;
    if (moment(this.calendarUser.CreationDate).diff(date, 'days') > 0 && !this.calendarUser.UserName.includes(".tmp."))
      return false;

    return !this.isDone(date) && !this.isFailed(date);
  }

  isAvailableMonth(date) {
    if (!this.calendarUser) return true;
    if (moment(this.calendarUser.CreationDate).diff(date, 'days') > 0 && !this.calendarUser.UserName.includes(".tmp.")) return true;
    if ((date.month() > moment().month() - 2 && date.year() === moment().year()) ||
      (date.year() > moment().year()) ||
      (moment().month() == 0 && date.month() == 11 && (date.year() + 1) === moment().year()))
      return true;
    return false;
  }

  isAvailable(date) {
    return true;
  }

  _removeTime(date) {
    var a = 0;
    return date.hour(a).minute(a).second(a).millisecond(a);
  }

  _buildMonth(start, month) {
    this.weeks = [];
    var done = false, date = start.clone(), monthIndex = date.month(), count = 0;
    while (!done) {
      this.weeks.push({ days: this._buildWeek(date.clone(), month) });
      date = date.clone();
      date.add(1, "w");
      done = count++ > 2 && (monthIndex !== date.startOf('week').month());
      monthIndex = date.month();
    }
  }

  _buildWeek(date, month) {
    let days = [];
    let dayItereator = date.startOf('week');
    for (var i = 0; i < 7; i++) {
      let day = {
        name: dayItereator.format("dd").substring(0, 1),
        number: dayItereator.date(),
        isCurrentMonth: dayItereator.month() === moment().month(),
        isToday: dayItereator.isSame(new Date(), "day"),
        date: dayItereator,
        month: dayItereator.format("MM")
      }
      day["css"] = this.getDayClass(day)
      days.push(day);
      dayItereator = dayItereator.clone();
      dayItereator.add(1, "d");
    }
    return days;
  }
  replaceMonth() {
    this._translateService.get('MONTHS').subscribe(months => {
        const monthKeys = Object.keys(months);
        const currentMonthIndex = this.month.month();
        const currentMonthKey = monthKeys[currentMonthIndex];
        this.translatedMonth = months[currentMonthKey];
    });
}

}
