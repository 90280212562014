import { Component, OnInit, Input, AfterViewChecked, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import { SharedService } from 'app/core/shared/shared.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements AfterViewChecked {

  @Input() info: String;
  @Input() position: String;
  @Input() color: String;
  showing: Boolean;

  constructor(public sharedService: SharedService, private _renderer: Renderer2, private _el: ElementRef) { 
    this.showing = false;
    if(!this.position) this.position = "L";
    if(!this.color) this.color = "gray";
  }

  ngAfterViewChecked(): void {
    this._absoulteToFixed();
  }


  public toggle() {
    this.showing = !this.showing;
  }

  private _absoulteToFixed() {
    const element = this._el.nativeElement.querySelector('.walinwa-tooltip-panel');

    if (element) {
      if (this._isElementVisible(element)) {
        
        const rect = element.getBoundingClientRect();
        const top = element.getAttribute('data-top');

        if (!top) {
          this._renderer.setAttribute(element, 'data-top', element.style.top);
          this._renderer.setAttribute(element, 'data-left', element.style.left);
        }

        this._renderer.setStyle(element, 'position', 'fixed');
        this._renderer.setStyle(element, 'top', `${rect.top}px`);
        this._renderer.setStyle(element, 'left', `${rect.left}px`);

      } else {
        const top = element.getAttribute('data-top');
        const left = element.getAttribute('data-left');

        if (top && left) {
          this._renderer.setStyle(element, 'position', '');          
          this._renderer.removeStyle(element, 'top');
          this._renderer.removeStyle(element, 'left');
        }
      } 
    }
  }

  private _isElementVisible(element: HTMLElement): boolean {
    const style = window.getComputedStyle(element);
    return style.display !== 'none' && style.visibility !== 'hidden';
  }
}
