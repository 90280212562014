import { AvatarShopService } from 'app/services/avatar-shop.service';
import { environment } from 'environments/environment';
import { LogService } from 'app/services/log.service';
import { Dictation } from './../../../../core/shared/state/models/dictation.model';
import { SharedService } from 'app/core/shared/shared.service';
import { FindUrlPipe } from 'app/pipes/find-url.pipe';
import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'app/app.service';
import { AuthService } from 'app/services/auth.service';
import { DictationService } from 'app/services/dictation.service';
import { GlobalService } from 'app/services/global.service';
import { NavigationService } from 'app/services/navigation.service';
import { SchoolService } from 'app/services/school.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { LogEventLevel } from 'app/core/logging/log-models';
import { TranslateService } from '@ngx-translate/core';
declare let $;

@Component({
    selector: 'app-play-dictation',
    templateUrl: './play.component.html',
    styleUrls: ['./play.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PlayComponent implements OnInit, OnDestroy, AfterViewInit {
    directory = "https://walinwa.blob.core.windows.net/images/Dictation/";
    private _unsubscribeAll: Subject<any>;
    public disabledCloseBtn = false;
    errorSound: HTMLAudioElement;
    deshabilitarErrorSound = false;
    dictation;
    cancelDict
    contenedorDictationShow = false;
    audio = document.createElement("audio");
    showDiploma = false;
    dictStart = null;
    messages = [];
    android = /android/i.test(navigator.userAgent);
    texto = "";
    caracteres = [];
    fragmentos = [];
    fragmento = 0;
    nFragmentos = 1;
    primera_vez = true;
    pista = "";
    sonido = false;
    walinwos = 0;
    hideBlink = false;
    dictado = false;
    nota = 10;
    restaNota = 0.5;
    restaNotaTipo = 0.05;
    restaNotaGram = 0.25;
    lastRestaNota = -1;
    lastRestaNotaPalabra = -1;
    restaWalis = 10;
    restaWalisTipo = 1;
    extraWalis = 0;
    restaWalisPalabra = 20;
    intervalBlink;
    repitiendo;
    countErrorT = 0;
    caracteres_separadores_inicio = [
        '#',
        ',',
        '.',
        ';',
        ':'
    ];
    caracteres_separadores = [
        '·',
        '#',
        ',',
        '.',
        ';',
        ':',
        // '-',
        '–',
        '—',
        '¿',
        '?',
        // '¡',
        '!',
        '(',
        ')',
        // '«',
        // '»',
        // '”',
        // '“'
    ];
    currentUser
    caracteres_mostrar = [
        ',',
        '.',
        ';',
        ':',
        '¿',
        '?',
        '¡',
        '!',
        '-',
        '–',
        '—',
        '(',
        ')',
        '"',
        '«',
        '»',
        '”',
        '“',
    ];
    dictResult;
    tags = [{
        "texto": ",",
        "leer": " $ "
    },
    {
        "texto": ".",
        "leer": " $ "
    }, {
        "texto": ";",
        "leer": " $ "
    }, {
        "texto": ":",
        "leer": " $ "
    }, {
        "texto": "(",
        "leer": " $ "
    }, {
        "texto": ")",
        "leer": " $ "
    }, {
        "texto": '"',
        "leer": " $ "
    }, {
        "texto": '«',
        "leer": " $ "
    }, {
        "texto": '»',
        "leer": " $ "
    }, {
        "texto": '“',
        "leer": " $ "
    }, {
        "texto": '”',
        "leer": " $ "
    }
    ];
    speaking = false;
    fallos = [];
    saltos = [];
    indiferentes = [];
    isFin = false;
    teacherReview;
    review = false;
    dictSpace = false;
    AccentPenalty = false;
    level;
    week;
    day;
    year;
    author;
    book;
    user;
    ultimo_leido = -1;
    cola = [];
    calling;
    palabras_saltadas_seguidas = 0;
    palabras_saltadas_total = 0;
    input = "";
    dictText;
    eventsSubject: Subject<object> = new Subject<object>();
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;
    continued = false;
    regex = new RegExp(/Tab|Alt|Arrow|Page/);
    BoxHeight: number;
    finishedDictation: Dictation = null;
    @HostListener('document:keydown', ['$event'])
    handleDeleteKeyboardEvent(event: KeyboardEvent) {
        if (this.regex.test(event.key)) event.preventDefault();
    }
    debug_messages = [
        "init"
    ]
    selectedDay: any;
    lastLetra: any;
    constructor(
        private authService: AuthService,
        private schoolService: SchoolService,
        private activatedRoute: ActivatedRoute,
        private navigationService: NavigationService,
        private dictationService: DictationService,
        private sharedService: SharedService,
        private matDialog: MatDialog,
        private logService: LogService,
        private avatarShopService: AvatarShopService,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();
    }
    ngAfterViewInit(): void {
        // this.calcInitialBoxHeight();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {


        setTimeout(() => {
            this.handleResize();
        }, 750);
    }

    ngOnInit() {
        if (!this.authService.check()) return;
        this.init();
    }
    init() {
        this.continued = false;
        this.initParams();
        this.getClass();
        this.currentUser = this.authService.currentUser
        this.errorSound = new Audio()
        this.errorSound.src = "./assets/sound/error.wav";
    }

    getCharClass(caracter, i) {
        let classes = ["dictation-text-char"];
        if (this.es_letra(caracter)) classes.push("letra");
        if (this.es_signo(caracter)) classes.push("signo");
        if (this.espacio_despues(i)) classes.push("espacio_despues");
        if (caracter.salto) classes.push("break-space");
        if (caracter.caracter == ' ') classes.push("espacio");
        if (!caracter.show && caracter.caracter != ' ') classes.push("borde");
        if (caracter.fragmento == this.fragmento + 1) classes.push("fragmento");
        if (caracter.italic == true) classes.push("italic");
        if (caracter.salto) classes.push("salto");
        if (this.nextChar() == i && this.hideBlink) classes.push("nextChar");
        if (this.nextChar() == i) classes.push("currentChar"); //Leo: To detect unique top
        if (caracter.errorT || caracter.errorO || caracter.errorG) classes.push("errorChar");
        if (caracter.errorT) classes.push("errorCharT");
        if (caracter.errorO) classes.push("errorCharO");
        if (caracter.errorG) classes.push("errorCharG");
        if (caracter.caracter == ' ' && this.caracteres[i - 1].caracter == '-') classes.push("absolute");
        return classes.join(" ");
    }
    ngOnDestroy() {
        if (!this.teacherReview) clearInterval(this.intervalBlink);
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    initKeyListener() {
        setTimeout(() => {
            this.setFocus(true)
        }, 1000);
    }

    handleResize() {

        var viewportHeight = window.innerHeight;
        var viewportHeightMitad = viewportHeight / 2;
        var visualViewportHeight = window.visualViewport.height;
        var keyboardHeight = viewportHeight - visualViewportHeight;

        let containerMidle = document.getElementById("dictation-text-box");
        let containerMidleHeight = containerMidle.offsetHeight;

        // console.log("Keyboard height:", keyboardHeight);

        if (keyboardHeight > 100) {

            // let firstHeight = keyboardHeight / 2;
            // let firstHeight = (keyboardHeight > viewportHeightMitad) ? keyboardHeight / 2 - 48 : containerMidleHeight - keyboardHeight;
            let firstHeight = (keyboardHeight > viewportHeightMitad) ? keyboardHeight / 2 - 48 : containerMidleHeight - keyboardHeight;

            let secondHeight = firstHeight; //==> 48 is the extra height in double keyboard after start the keyboard

            containerMidle.style.maxHeight = secondHeight + "px";
            // console.log(' altos ' + keyboardHeight + ' - ' + viewportHeightMitad + ' - ' + containerMidleHeight);
            this.ElelemtSetFocu();
        }
        else {
            this.returnHeightContainer();
        }
    }

    returnHeightContainer() {
        let containerMidle = document.getElementById("dictation-text-box");
        containerMidle.style.maxHeight = "none";
        containerMidle.scrollTo({ top: 0 });
    }

    ElelemtSetFocu() {
        let elementScroll = document.querySelector('.currentChar') as HTMLElement;
        let elementScrollid = elementScroll.id;

        let elementTopResource = document.getElementById(elementScrollid);
        let elemTop = elementTopResource.offsetTop;

        let containerMidle = document.getElementById("dictation-text-box");
        let containerMidleTop = containerMidle.scrollTop;
        let relativeTop = (containerMidleTop) ? elemTop : 0;

        containerMidle.scrollTo({ top: elemTop });

        // console.log(containerMidle.scrollTop + ' - ' + relativeTop + ' top ' + elemTop);
    }

    setFocus(bool = true) {

        if (bool) {
            document.getElementById("dictation-input").focus();

            setTimeout(() => {
                this.handleResize();
            }, 750);
        }
        if (!bool) {
            document.getElementById("dictation-input").blur();

            this.returnHeightContainer();
        }

    }

    change() {
        this.debug_messages.push("change: " + this.input)
        if (this.input.length == 0) return;
        this.entrada_dictado_change(this.input.charAt(this.input.length - 1));
    }

    getLastMonth() {
        let month = this.getCurrentMonth() - 1;
        if (month > 12) month = 1;
        if (month < 1) month = 12;
        return month;
    }

    getCurrentMonth() {
        return moment().month() + 1;
    }

    getNextMonth() {
        let month = this.getCurrentMonth() + 1;
        if (month > 12) month = 1;
        if (month < 1) month = 12;
        return month;
    }

    initInterval() {
        this.intervalBlink = setInterval(() => {
            if (!$("#dictation-input").is(":focus")) this.hideBlink = false; else this.hideBlink = !this.hideBlink;
        }, 500);
    }

    initParams() {
        this.review = location.href.includes("review");
        this.teacherReview = this.review ? false : this.authService.currentUser.IsTutor;
        this.selectedDay = moment(this.navigationService.params.selectedDay).format();
        this.year = moment(this.selectedDay).isoWeekYear();
        this.week = moment(this.selectedDay).isoWeek();
        this.day = moment(this.selectedDay).isoWeekday();
        this.user = this.navigationService.params.user ? this.navigationService.params.user : this.authService.currentUser;
        this.level = this.activatedRoute.params['value'].level ? this.activatedRoute.params['value'].level : this.user.Level;
        if (this.level != 11) this.level = this.level % 2 == 0 ? this.level : this.level - 1;
        if (this.level >= 14) this.level = 14;
    }

    getClass() {
        this.schoolService.getDictConfig(this.user.IdClass)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result: any) => {
                    if (!this.teacherReview && !this.review) this.dictSpace = result.DictSpace || false;
                    if (!this.teacherReview && !this.review) this.AccentPenalty = result.AccentPenalty || false;
                    if (!this.dictSpace) this.caracteres_mostrar.push(" ");
                    this.getInfo();
                },
                error => {
                    this.caracteres_mostrar.push(" ");
                    this.getInfo();
                }
            )
    }

    getInfo() {
        let reviewing = false;
        if(this.review || this.teacherReview){
            reviewing = true;
        }
        
        this.dictationService.getDictationInfo(this.level, this.week, this.day, this.authService.currentUser.Id, reviewing)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result: any) => {
                    this.setDictationInfo(result);
                }, error => {
                    this.setDictationInfo({
                        Author: "",
                        Book: "",
                        Dict: null
                    });
                });
    }

    setDictationInfo(result) {
        this.author = result.Author;
        this.book = result.Book;
        this.dictation = {
            dictado: `${this.week}_${this.day}`,
            date: moment(this.selectedDay),
            repetido: null
        }
        this.repitiendo = this.dictation.repetido;
        this.dictText = result.Dict;
        this.getDictation()
    }

    getDictation() {
        if (this.dictText) return this.loadDictation(this.dictText);
    }

    next() {
        let date = moment(this.dictation.date)
        date.add({ days: 1 });
        let day = date.day();
        if (day == 0) day = 7;
        let week = date.week();
        if (!this.canDoDict(date)) return;
        this.goDictation(week, day, this.level)
        this.dictation.date = date;
        this.selectedDay = date;
    }

    canDoDict(date) {
        if (this.authService.currentUser.IdSchool == 1) return true;
        let month = date.month() + 1;
        return month == this.getCurrentMonth() || month == this.getLastMonth() || month == this.getNextMonth();
    }

    before() {
        let date = moment(this.dictation.date)
        date.add({ days: -1 });
        let day = date.day();
        if (day == 0) day = 7;
        let week = date.week();
        if (!this.canDoDict(date)) return;
        this.dictation.date = date;
        this.selectedDay = date;
        this.goDictation(week, day, this.level)
    }

    goDictation(week, day, level) {
        this.week = week;
        this.day = day;
        this.level = level;
        this.getClass();
    }

    loadDictation(text) {
        this.contenedorDictationShow = true;
        this.texto = text.replace(/(?:\r\n|\r|\n)/g, '&')
        this.texto = this.texto.replace(/\.\.\./g, '…').replace(/\./g, '. ').replace('-', '- ').replace('.&', '. &').replace('!&', '! &').replace(':&', ': &').replace(/…/g, '... ');
        this.comprobar_alm();
        this.quitar_saltos();
        this.getCaracteres();
        this._preloadAudios();
        this.walinwos = this.getWalinwos();
        let next = this.nextChar();
        if (next >= 0 && this.caracteres_mostrar.indexOf(this.caracteres[next].caracter) >= 0) {
            try {
                this.add_letra(this.caracteres[next].caracter, false);
            } catch (e) { }
        }
        if (this.review) this.getDictResult();
        if (!this.teacherReview && !this.review) this.startWarning();
        this.addBreakSpaces();
        //console.log(this.texto)
    }

    addBreakSpaces() {
        setTimeout(() => {
            $(".break-space").each(function () {
                $(this).after("<div style='display: block'></div>");
            })
        }, 100);
    }

    getDictResult() {
        this.dictationService.getDictResult(this.user.Id, `${this.week}_${this.day}`)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.dictResult = result;
                    this.nota = this.dictResult.Score;
                    this.markErrors();
                }
            )
    }

    getErrorsType(type) {
        let errors = JSON.parse(this.dictResult.Errores);
        if (type == 1) return errors.filter(a => a.includes("_") || a.includes("-")).length;
        if (type == 2) return errors.filter(a => !(a.includes("_") || a.includes("-"))).length;
    }

    markErrors() {
        let dictErrorsIndex = []
        let dictErrors = JSON.parse(this.dictResult.Errores) || []
        if (dictErrors.length > 0) {
            dictErrorsIndex = this.convertToArrayOfInts(dictErrors);
        }
        let index = 0
        for (let error of dictErrorsIndex) {
            let error_type = "";
            if (dictErrors[index].includes("_")) {
                error_type = "errorG"
            }
            else if (dictErrors[index].includes("-")) {
                error_type = "errorO"
            }
            else {
                error_type = "errorT"
            }
            this.caracteres[error][error_type] = true;
            index++;
        }
    }

    startWarning() {
        let message = [];
        message[0] = this._translateService.instant('STUDENT.DICTATION.PLAY.START-WARNINGS.HEADER');
        message[1] = this._translateService.instant('STUDENT.DICTATION.PLAY.START-WARNINGS.MESSAGE');
        this.navigationService.question(message, [
            {
                text: this._translateService.instant('CANCEL'),
                callback: this.cancelYes.bind(this)
            }, {
                text: this._translateService.instant('START'),
                callback: () => {
                    this.comenzar()
                }
            }
        ]);
    }

    setSonido() {
        this.sonido = !this.sonido;
        if (this.sonido) {
            this.pista = '';
        } else {
            this.audio.pause();
            this.speaking = false;
        }
    };

    getFecha() {
        if (!this.dictation) return;
        let meses = [
            this._translateService.instant('MONTHS.JANUARY'),
            this._translateService.instant('MONTHS.FEBRUARY'),
            this._translateService.instant('MONTHS.MARCH'),
            this._translateService.instant('MONTHS.APRIL'),
            this._translateService.instant('MONTHS.MAY'),
            this._translateService.instant('MONTHS.JUNE'),
            this._translateService.instant('MONTHS.JULY'),
            this._translateService.instant('MONTHS.AUGUST'),
            this._translateService.instant('MONTHS.SEPTEMBER'),
            this._translateService.instant('MONTHS.OCTOBER'),
            this._translateService.instant('MONTHS.NOVEMBER'),
            this._translateService.instant('MONTHS.DECEMBER')
        ];
        let dayNames = [
            this._translateService.instant('DAYS.MONDAY'),
            this._translateService.instant('DAYS.TUESDAY'),
            this._translateService.instant('DAYS.WEDNESDAY'),
            this._translateService.instant('DAYS.THURSDAY'),
            this._translateService.instant('DAYS.FRIDAY'),
            this._translateService.instant('DAYS.SATURDAY'),
            this._translateService.instant('DAYS.SUNDAY'),
        ];
        let d = this.dictation.date;
        let dia = d.date();
        let mes = d.month();
        let ano = d.year();
        let dayIndex = d.day() - 1;
        if (dayIndex == -1) dayIndex = 6;
        let dayName = dayNames[dayIndex];
        return `${dayName}, ${dia} de ${meses[mes]}`;
    }

    espacio_despues(i) {
        if (this.caracteres && this.caracteres.length > (i + 1)) {
            if (this.caracteres[i + 1].caracter == ' ') {
                return true;
            }
        }
        return false;
    }

    comprobar_alm() {
        let aux = "";
        let espacio = false;
        for (let i = 0; i < this.texto.length; i++) {
            if (this.texto.charAt(i) == '#') {
                aux += ' ';
                espacio = true;
            } else if (espacio && this.texto.charAt(i) != ' ') {
                aux += ' ';
                espacio = false;
            }
            aux += this.texto.charAt(i);
        }
        this.texto = aux;
    }

    show(caracter) {
        if (caracter.show || caracter.caracter == ',' || caracter.caracter == '.') return caracter.caracter;
        return "&nbsp";
    }

    quitar_saltos() {
        this.texto = this.texto.replace(/(\r\n|\n|\r)/gm, "");
    }

    getWalinwos() {
        let w = 0;
        for (let i = 0; i < this.caracteres.length; i++) {
            if (this.caracteres_mostrar.indexOf(this.caracteres[i].caracter) < 0) {
                w++;
            }
        }
        w /= 2;
        w = Math.floor(w);
        return w;
    }

    cancel() {
        this.disabledCloseBtn = true;
        this.cancelDict = true;
        if (this.teacherReview || this.review) {
            this.cancelYes();
        } else {
            let message = [];
            message[0] = this._translateService.instant('STUDENT.DICTATION.PLAY.END-DICTATION');
            message[1] = this._translateService.instant('STUDENT.DICTATION.PLAY.END-DICTATION-DOUBLECHECK');
            this.navigationService.warn(message,
                ok => {
                    this.cancelYes();
                },
                ko => {
                    this.cancelNo();
                    this.disabledCloseBtn = false;
                })
        }
    }

    cancelYes() {
        this.volver();
        this.fixPositionScroll_vertical_ClassReturn();
    } 

    cancelNo() {
        this.cancelDict = false;
    }

    getCaracteres() {        
        let caracteres = this.texto.split('');

        this.caracteres = [];
        let inicio = true;
        let fragmentoLength = 0;
        let minFragmentoLength = 3;
        let sep = false;
        let italic = false;
        let ignoreErr = false;
        for (let i = 0; i < caracteres.length; i++) {
            let c = caracteres[i];
            if (c == "|") continue;
            if (c == "¬") {
                ignoreErr = true;
                continue;
            }
            let hasAlphanumeric = this.caracteres.filter(c => c.fragmento == this.nFragmentos && !this.caracteres_separadores.includes(c.caracter) && c.caracter != " " && c.caracter != "&").length > 0;
            fragmentoLength = this.caracteres.filter(c => c.fragmento == this.nFragmentos && this.es_letra(c)).length;
            // fragmentoLength++;
            if (c == '~') {
                italic = !italic;
                continue;
            }
            if (inicio) {
                if (this.caracteres_separadores_inicio.includes(c) && hasAlphanumeric) {
                    if (this.caracteres_separadores.indexOf(caracteres[i + 1]) >= 0) {
                        sep = true;
                    }
                    if (!sep && fragmentoLength >= minFragmentoLength) {
                        this.nFragmentos++;
                        inicio = false;
                        fragmentoLength = 0;
                    } else {
                        sep = false;
                    }
                }
                if (this.caracteres_separadores.indexOf(c) < 0) {
                    inicio = false;
                }
            } else {
                if (this.caracteres_separadores.includes(c) && hasAlphanumeric) {
                    if (this.caracteres_separadores.indexOf(caracteres[i + 1]) >= 0) {
                        sep = true;
                    }
                    if (!sep && fragmentoLength >= minFragmentoLength && caracteres[i - 1] != "|") {
                        this.nFragmentos++;
                        fragmentoLength = 0;
                    } else {
                        sep = false;
                    }
                }
            }
            let show = this.caracteres_mostrar.indexOf(c) >= 0 ? true : false;
            this.caracteres.push({
                caracter: c,
                fragmento: this.nFragmentos,
                answered: false,
                show: this.teacherReview || this.review || show,
                salto: false,
                italic: italic,
                ignoreErr: ignoreErr
            });
            if (ignoreErr) ignoreErr = false;
        }
        this.quitar_alm();
        this.quitar_espacios();
        this.set_indiferentes();
        this.fragmentos = this.separar();
        this.fragmento = 0;
        this.set_tags();
    }
    
    /*private getSoundUrl(){
        return environment.filesUrl + this.dictationService.subDirectory + this.level + "/" + this.week + "_" + this.day + "/" + this.fragmento + ".mp3"
    }*/

    set_indiferentes() {
        for (let i = 0; i < this.caracteres.length; i++) {
            if (this.caracteres[i].caracter == '/') {
                this.indiferentes.push(this.caracteres[i + 1].caracter);
                this.caracteres.splice(i, 1);
                i = -1;
            }
        }
    }

    quitar_espacios() {
        let i;
        let espacio = false;
        for (i = 0; i < this.caracteres.length; i++) {
            let c = this.caracteres[i];
            if (c.caracter == ' ') {
                if (espacio) {
                    if (this.caracteres[i - 1].salto)
                        this.caracteres.splice(i, 1);
                    else
                        this.caracteres.splice(i - 1, 1);
                    i = -1;
                } else {
                    espacio = true;
                }
            } else {
                espacio = false;
            }
        }
        let s = false;
        for (i = 0; i < this.caracteres.length; i++) {
            if (this.caracteres[i].salto) {
                s = true;
                i++;
            }
            if (s) {
                if (i < this.caracteres.length && this.caracteres[i].caracter === ' ') {
                    this.caracteres.splice(i, 1);
                    i = -1;
                    s = false;
                } else {
                    s = false;
                }
            }
        }
    }

    quitar_alm() {
        for (let i = 0; i < this.caracteres.length; i++) {
            let c = this.caracteres[i].caracter;
            if (c == '#') {
                this.caracteres.splice(i, 1);
                i = -1;
            }
        }
    }

    separar() {
        let aux = [];
        let texto = "";
        let i;
        for (i = 0; i < this.caracteres.length; i++) {
            let c = this.caracteres[i];
            if (i == 0) {
                texto += c.caracter;
            } else {
                if (c.fragmento != this.caracteres[i - 1].fragmento) {
                    if (c.caracter != '(')
                        texto += c.caracter;
                    aux.push(texto);
                    texto = "";
                    if (c.caracter == '(')
                        texto += c.caracter;

                } else {
                    texto += c.caracter;
                }
            }
        }
        aux = this.puntos(aux);
        for (i = 0; i < this.caracteres.length; i++) {
            if (this.caracteres[i].caracter == '&') {
                this.caracteres[i - 1].salto = true;
                this.caracteres.splice(i, 1);
                i = -1;
            }
        }
        for (i = 0; i < this.caracteres.length; i++) {
            if (this.caracteres[i].caracter == '%') {
                this.caracteres.splice(i, 1);
                i = -1;
            }
        }
        this.quitar_espacios();
        return aux;
    }

    puntos(fragmentos) {
        let i, j;
        let aux = [];
        for (i = 0; i < fragmentos.length; i++) {
            if (fragmentos[i].indexOf('.') >= 0) { //Si hay un punto, si no pa que
                if (i == fragmentos.length - 1) { //Si es el ultimo fragmento
                    if (!fragmentos[i].split(".")[1]) { //Si despues del punto no hay nada mas
                        //punto y final
                        aux[i] = fragmentos[i] + ' punto y final';
                    }
                } else { //Si no es el ultimo fragmento, solo puede ser punto y aparte, o punto y seguido
                    if (fragmentos[i].charAt(fragmentos[i].length - 1) == '.' && fragmentos[i].charAt(fragmentos[i].length - 2) == '.' && fragmentos[i].charAt(fragmentos[i].length - 3)) {
                        aux[i] = fragmentos[i].substring(0, fragmentos[i].length - 3);
                        //aux[i] = aux[i] + '$ puntos suspensivos $ ';                            
                        aux[i] = aux[i] + '$ ';
                    } else if (fragmentos[i + 1].charAt(0) == '&' || fragmentos[i + 1].charAt(1) == '&' || fragmentos[i + 1].charAt(2) == '&') {
                        //aux[i] = fragmentos[i] + ' y a parte $ ';
                        aux[i] = fragmentos[i] + ' $ ';
                    } else {
                        //aux[i] = fragmentos[i] + ' y seguido $ ';
                        aux[i] = fragmentos[i] + ' $ ';
                    }
                    //mas puntos supensivos a mitad del fragmento
                    aux[i] = aux[i].replace(/\.\.\./g, ' $ ');
                }
            } else {
                //mas puntos supensivos a mitad del fragmento
                aux[i] = fragmentos[i].replace(/\.\.\./g, ' $ ');
            }
        }
        for (i = 0; i < aux.length; i++) {
            aux[i] = aux[i].replace('&', '');
        }
        for (i = 0; i < aux.length; i++) {
            aux[i] = aux[i].replace('%', '$');
        }
        return aux;
    }

    set_tags() {
        for (let i = 0; i < this.fragmentos.length; i++) {
            for (let j = 0; j < this.tags.length; j++) {
                while (this.fragmentos[i].includes(this.tags[j].texto))
                    this.fragmentos[i] = this.fragmentos[i].replace(this.tags[j].texto, this.tags[j].leer);
            }
        }
        for (let i = 0; i < this.fragmentos.length; i++) {
            for (let j = 0; j < this.tags.length; j++) {
                this.fragmentos[i] = this.fragmentos[i].replace('$', ',');
            }
        }
    }

    comenzar() {
        if (!this.teacherReview && !this.review) {
            this.initKeyListener();
            this.initInterval();
        }
        this.dictStart = moment();
        this.sonido = true;
        this.primera_vez = false;
        this.dictado = true;
        this.leer(true);
    }

    nextChar() {
        return this.caracteres.findIndex(c => !c.answered)
    }

    add_letra(letra, introducido) {
        this.debug_messages.push("add letra");
        if (letra !== null) {
            this.pista = "";
            let ok = false;
            let errorT = false;
            let errorO = false;
            let errorG = false;
            let i = this.nextChar();
            if (i < 0) {
                return;
            }
            if (this.caracteres[i] && this.caracteres[i].caracter == letra) {
                this.caracteres[i].show = true;
                this.caracteres[i].answered = true;
                ok = true;
                if (introducido) {
                    this.lastLetra = letra;
                }
                if (i == this.caracteres.length - 1) {
                    this.fin();
                } else {
                    if (this.caracteres[i].fragmento != this.caracteres[i + 1].fragmento) {
                        if (this.caracteres[i].leido != true) this.leer(true);
                        this.siguiente_fragmento();
                    }
                    //si es un signo lo saltamos
                    let next = this.nextChar();
                    
                    if (next !== -1) {
                        if (next >= 0 && this.caracteres_mostrar.indexOf(this.caracteres[next].caracter) >= 0) {
                            this.add_letra(this.caracteres[next].caracter, false);
                        }
                        // por si el último caracter es un espacio
                        else if (next == this.caracteres.length - 1 && this.caracteres[next].caracter == ' ') {
                            this.add_letra(this.caracteres[next].caracter, false);
                        }
                        else if ((this.caracteres[next].caracter == ' ' && this.caracteres[next].salto)) {
                            this.add_letra(this.caracteres[next].caracter, false);
                        }
                    }
                }
            } else if (introducido) {
                if (this.caracteres[i].ignoreErr) {
                    this.caracteres[i].show = true;
                    this.caracteres[i].answered = true;
                    ok = true;
                    //fin
                    if (i == this.caracteres.length - 1) {
                        this.fin();
                    } else {
                        //si es un signo lo saltamos
                        if (this.caracteres[i].fragmento != this.caracteres[i + 1].fragmento) {
                            this.siguiente_fragmento();
                        }
                        let next = this.nextChar();
                        if (next >= 0 && this.caracteres_mostrar.indexOf(this.caracteres[next].caracter) >= 0) {
                            this.add_letra(this.caracteres[next].caracter, false);
                        }
                    }
                } else if (this.caracteres[i] && this.caracteres[i].caracter == letra.toUpperCase()) {
                    if (this.indiferentes.indexOf(this.caracteres[i].caracter) >= 0 || this.caracteres[i].ignoreErr) {
                        this.caracteres[i].show = true;
                        this.caracteres[i].answered = true;
                        ok = true;
                        //fin
                        if (i == this.caracteres.length - 1) {
                            this.fin();
                        } else {
                            //si es un signo lo saltamos
                            if (this.caracteres[i].fragmento != this.caracteres[i + 1].fragmento) {
                                this.siguiente_fragmento();
                            }
                            let next = this.nextChar();
                            if (next >= 0 && this.caracteres_mostrar.indexOf(this.caracteres[next].caracter) >= 0) {
                                this.add_letra(this.caracteres[next].caracter, false);
                            }
                        }
                    } else {
                        this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.UPPER-CASE');
                        if (this.lastRestaNota != i) {
                            this.lastRestaNota = i;
                        }
                        errorG = true;
                    }
                } else if (this.caracteres[i] && this.caracteres[i].caracter == letra.toLowerCase()) {
                    if (this.indiferentes.indexOf(this.caracteres[i].caracter) >= 0) {
                        this.caracteres[i].show = true;
                        this.caracteres[i].answered = true;
                        ok = true;
                        //fin
                        if (i == this.caracteres.length - 1) {
                            this.fin();
                        } else {
                            if (this.caracteres[i].fragmento != this.caracteres[i + 1].fragmento) {
                                this.siguiente_fragmento();
                            }
                            //si es un signo lo saltamos
                            let next = this.nextChar();
                            if (next >= 0 && this.caracteres_mostrar.indexOf(this.caracteres[next].caracter) >= 0) {
                                this.saltar_letra(next);
                            }
                        }
                    } else {
                        this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.LOWER-CASE');
                        if (this.lastRestaNota != i) {
                            this.lastRestaNota = i;
                        }
                        errorG = true;
                    }
                } else if (this.caracteres[i] && this.caracteres[i].caracter == ',') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.COMA');
                    errorT = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter == ' ') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.NEED-SPACE');
                    errorT = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter == 'á' || this.caracteres[i].caracter == 'é' || this.caracteres[i].caracter == 'í' || this.caracteres[i].caracter == 'ó' || this.caracteres[i].caracter == 'ú') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.USE-ACCENT');
                    if (!this.AccentPenalty) {
                        if (this.lastRestaNota != i) {
                            this.lastRestaNota = i;
                        }
                        errorO = true;
                    }
                    else if (this.deshabilitarErrorSound === false) {
                        this.errorSound.play()
                    }
                    
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'A' && letra.toUpperCase() === 'Á') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (!this.AccentPenalty) {
                        if (this.lastRestaNota != i) {
                            this.lastRestaNota = i;
                        }
                        errorO = true;
                    }
                    else if (this.deshabilitarErrorSound === false) {
                        this.errorSound.play()
                    }
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'E' && letra.toUpperCase() === 'É') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (!this.AccentPenalty) {
                        if (this.lastRestaNota != i) {
                            this.lastRestaNota = i;
                        }
                        errorO = true;
                    }
                    else if (this.deshabilitarErrorSound === false) {
                        this.errorSound.play()
                    }
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'I' && letra.toUpperCase() === 'Í') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (!this.AccentPenalty) {
                        if (this.lastRestaNota != i) {
                            this.lastRestaNota = i;
                        }
                        errorO = true;
                    }
                    else if (this.deshabilitarErrorSound === false) {
                        this.errorSound.play()
                    }
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'O' && letra.toUpperCase() === 'Ó') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (!this.AccentPenalty) {
                        if (this.lastRestaNota != i) {
                            this.lastRestaNota = i;
                        }
                        errorO = true;
                    }
                    else if (this.deshabilitarErrorSound === false) {
                        this.errorSound.play()
                    }
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'U' && letra.toUpperCase() === 'Ú') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (!this.AccentPenalty) {
                        if (this.lastRestaNota != i) {
                            this.lastRestaNota = i;
                        }
                        errorO = true;
                    }
                    else if (this.deshabilitarErrorSound === false) {
                        this.errorSound.play()
                    }
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'B' && letra.toUpperCase() === 'V') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {

                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'V' && letra.toUpperCase() === 'B') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {

                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'G' && letra.toUpperCase() === 'J') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {

                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'J' && letra.toUpperCase() === 'G') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {

                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'L' && letra.toUpperCase() === 'Y') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {

                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'Y' && letra.toUpperCase() === 'L') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {

                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'N' && letra.toUpperCase() === 'M') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {

                        this.lastRestaNota = i;
                    }
                    if (this.esConsonante(this.caracteres[i + 1].caracter.toUpperCase())) {
                        errorO = true;
                    } else {
                        errorT = true;
                    }

                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'M' && letra.toUpperCase() === 'N') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {

                        this.lastRestaNota = i;
                    }
                    if (this.esConsonante(this.caracteres[i + 1].caracter.toUpperCase())) {
                        errorO = true;
                    } else {
                        errorT = true;
                    }
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'U' && letra.toUpperCase() === 'Ü') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {

                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'C' && letra.toUpperCase() === 'Z') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {

                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'Z' && letra.toUpperCase() === 'C') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {

                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'X' && letra.toUpperCase() === 'S') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {

                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'S' && letra.toUpperCase() === 'X') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {
                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'C' && letra.toUpperCase() === 'S') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {
                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'S' && letra.toUpperCase() === 'C') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {
                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'Y' && letra.toUpperCase() === 'I') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {
                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'I' && letra.toUpperCase() === 'Y') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {
                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'L' && letra.toUpperCase() === 'R') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {
                        this.lastRestaNota = i;
                    }
                    if (this.esConsonante(this.caracteres[i + 1].caracter.toUpperCase())) {
                        errorO = true;
                    } else {
                        errorT = true;
                    }
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'R' && letra.toUpperCase() === 'L') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {
                        this.lastRestaNota = i;
                    }
                    if (this.esConsonante(this.caracteres[i + 1].caracter.toUpperCase())) {
                        errorO = true;
                    } else {
                        errorT = true;
                    }
                } else if (this.caracteres[i] && (this.caracteres[i].caracter.toUpperCase() === 'A' || this.caracteres[i].caracter.toUpperCase() === 'E' || this.caracteres[i].caracter.toUpperCase() === 'I' || this.caracteres[i].caracter.toUpperCase() === 'O' || this.caracteres[i].caracter.toUpperCase() === 'U') && letra.toUpperCase() === 'H') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {
                        this.lastRestaNota = i;                    
                    }

                    if (this.caracteres[i - 1].caracter == ' ') {
                        errorO = true;
                    }
                    else {
                        errorT = true;
                    }
                    
                } else if (this.caracteres[i] && this.caracteres[i].caracter.toUpperCase() === 'H' && (letra.toUpperCase() === 'A' || letra.toUpperCase() === 'E' || letra.toUpperCase() === 'I' || letra.toUpperCase() === 'O' || letra.toUpperCase() === 'U')) {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {
                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (i > 0 && this.caracteres[i] && this.caracteres[i - 1] && this.caracteres[i].caracter.toUpperCase() === 'C' && this.caracteres[i - 1].caracter.toUpperCase() === 'C' && letra.toUpperCase() !== 'C') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {
                        this.lastRestaNota = i;
                    }
                    errorO = true;
                } else if (i > 0 && this.caracteres[i] && this.caracteres[i - 1] && this.caracteres[i].caracter.toUpperCase() === 'R' && this.caracteres[i - 1].caracter.toUpperCase() === 'R' && letra.toUpperCase() !== 'R') {
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    if (this.lastRestaNota != i) {
                        this.lastRestaNota = i;
                    }
                    errorO = true;
                }
                else {
                    if (this.lastRestaNota != i) {
                        this.lastRestaNota = i;
                    }
                    this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TIPS.INCORRECT');
                    errorT = true;
                }
            }
            if (this.nota < 0)
                this.nota = 0;
            if (errorT || errorO || errorG) {
                //fallo de android
                let errores = this.convertToArrayOfInts(this.fallos);
                if (this.android && (letra == this.lastLetra || letra == ' ')) {
                    this.pista = '';
                    errorT = false;
                    errorO = false;
                    errorG = false;
                }
                else if (this.caracteres_mostrar.indexOf(this.caracteres[i].caracter) < 0 && this.caracteres_mostrar.indexOf(letra) < 0) {
                    if (errores.indexOf(i) < 0) {
                        if (this.caracteres[i].caracter !== " " && letra !== " ") {
                            let index = i
                            //if (i == -1 || (i == 0 && errorO)) index = 99999
                            if (errorT) {
                                this.countErrorT++;
                                this.fallos.push(index + "");
                                if (this.countErrorT > 3) {
                                    this.restar_walinwos(this.restaWalisTipo);
                                    this.nota = this.nota - this.restaNotaTipo;
                                }
                            } else if(errorO) {
                                this.fallos.push("-" + index);
                                this.restar_walinwos(this.restaWalis);
                                this.nota = this.nota - this.restaNota;
                            }
                            else if(errorG) {
                                this.fallos.push("_" + index);
                                this.restar_walinwos(this.restaWalis);
                                this.nota = this.nota - this.restaNotaGram;
                            }
                        }
                    }
                }
            }
            if (errorO || errorT || errorG) {
                if (this.deshabilitarErrorSound == false) {
                    this.errorSound.play()
                }
            }
            if (!introducido && this.caracteres[i].caracter === letra) {
                if (this.saltos.indexOf(i) < 0) {
                    this.saltos.push(i);
                }
            }
        }
        this.autoScroll();
    }

    activatedErrorSound(checked: boolean) {
        (checked) ? this.deshabilitarErrorSound = true : this.deshabilitarErrorSound = false
    }

    restar_walinwos(n) {
        this.walinwos -= n;
        if (this.walinwos <= 0) this.walinwos = 0;
        this.checkFinAnticipado();
    }

    errores() {
        let aux = []
        let parsedIndexArray = this.convertToArrayOfInts(this.fallos);
        for (let i = 0; i < parsedIndexArray.length; i++) {
            let falloIndex = parsedIndexArray[i]
            if (falloIndex < 0) falloIndex = falloIndex * -1
            if (this.caracteres[falloIndex].caracter == " ") continue
            if (this.caracteres_mostrar.indexOf(this.caracteres[falloIndex].caracter) < 0) {
                aux.push(this.fallos[i] + "")
            }
        }
        for (let i = 0; i < this.saltos.length; i++) {
            if (this.caracteres[this.saltos[i]].caracter == " ") continue
            if (this.caracteres_mostrar.indexOf(this.caracteres[this.saltos[i]].caracter) < 0) {
                aux.push(this.saltos[i] + "")
            }
        }
        return aux;
    }
    getErrores() {
        return this.errores().length;
    }
    convertToArrayOfInts(arr: string[]){
        return arr.map(str => {
            const match = str.match(/[-_]?(\d+)/);
            if (match && match[1]) {
                return parseInt(match[1], 10);
            } else {
                return NaN; // O manejar el caso de que no haya un número válido
            }
        });
    }
    siguiente_fragmento() {
        if (this.fragmento < this.nFragmentos - 1) {
            this.fragmento++;
            let next = this.nextChar();
            if (this.caracteres[next] && this.caracteres_mostrar.indexOf(this.caracteres[next].caracter) >= 0) {
                this.saltar_letra(next);
            }
            this.leer(true);
        }
    }

    repetir_fragmento(event) {
        if (event.type === 'keydown') return;
        if (this.speaking || this.isFin || this.primera_vez) return;
        this.leer(false);
        // setTimeout(() => {
        //     this.setFocus(true)
        // }, 1000);
        this.returnHeightContainer();
    }

    saltar_palabra(event) {
        if (event.type === 'keydown') return;
        let i = this.nextChar();
        this.saltar_letra(i, true);
        this.restar_walinwos(this.restaWalisPalabra);
        if (this.lastRestaNotaPalabra != i) {
            this.nota = this.nota - 1;
            this.lastRestaNotaPalabra = i;
        }
        if (this.nota < 0) this.nota = 0;
        this.palabras_saltadas_seguidas++;
        this.palabras_saltadas_total++;
        this.checkFinAnticipado();
        this.returnHeightContainer();
    }

    checkFinAnticipado() {
        if (this.continued && this.palabras_saltadas_total >= 10) {
            this.cancelDictationMessage();
        }
        else if (!this.continued && (this.walinwos == 0 || this.palabras_saltadas_seguidas == 5 || this.palabras_saltadas_total >= 10)) {
            this.continued = true;
            this.showMessage()
        }
    }

    saltar_letra(i, manually = false) {
        let c = this.caracteres[i];
        //this.ElelemtSetFocu(); //To go to the new line
        // console.log('start jump');
        if (c) {
            this.add_letra(c.caracter, false);
            let nextChar = "";
            if (this.caracteres[i + 1]) nextChar = this.caracteres[i + 1].caracter;
            // console.log('start jump 1');
            if (!manually) {
                if (!nextChar.match(/[a-zñÑáÁéÉíÍóÓúÚ]/i) && c.caracter != ' ' && c.caracter != '(' && c.caracter != ')' && c.caracter != '¡' && c.caracter != '«' && c.caracter != '¿' && c.caracter != '?' && c.caracter != '“' && c.caracter != '-' && c.caracter != '–' && c.caracter != '—' && c.caracter != ',' && c.caracter != '.' && c.caracter != ':' && c.caracter != ';' && c.caracter != '!') {
                    this.saltar_letra(i + 1, manually);
                    // this.ElelemtSetFocu();
                }
                this.ElelemtSetFocu(); //To go to the new line
            } else {
                if (c.caracter != ' ' && c.caracter != '(' && c.caracter != '¡' && c.caracter != '«' && c.caracter != '¿' && c.caracter != '“' && c.caracter != '-' && c.caracter != '–' && c.caracter != '—') {
                    this.saltar_letra(i + 1, manually);
                    // console.log('start jump 4');
                }
            }
        }
    }

    es_signo(item) {
        return this.caracteres_mostrar.indexOf(item.caracter) >= 0;
    }

    es_letra(item) {
        return item.caracter.length === 1 && item.caracter.match(/[a-zñÑáÁéÉíÍóÓúÚüÜ\-—«»¡:¿?!(),\.]/i);
    }



    fin(anticipado = false) {
        this.dictado = false;
        let maxErr = 0;
        for (let i = 0; i < this.caracteres.length; i++) {
            if (!this.es_signo(this.caracteres[i])) maxErr++;
        }
        // this.nota = 10; // ojo
        if (this.nota < 0) this.nota = 0;
        if (this.nota > 9.0) {
            let extraWalis = this.getExtraWalis();
            this.messages = [];
            this.messages[0] = this._translateService.instant('STUDENT.DICTATION.PLAY.WALIS-WON-TEXT', {walis:this.walinwos, extra:extraWalis});
            this.messages[1] = this._translateService.instant('STUDENT.DICTATION.PLAY.TOTAL-WALIS-TEXT', {totalWalis:this.walinwos + extraWalis});
            this.walinwos += extraWalis;
            this.extraWalis = extraWalis;
            this.showDiploma = true;
        }
        if (anticipado) {
            this.messages = [];
            this.messages[0] = this._translateService.instant('STUDENT.DICTATION.PLAY.TOO-MANY-ERRORS-TEXT');
        }
        if (anticipado) return;
        let duration = moment.duration(moment().diff(this.dictStart));
        let secs = duration.asSeconds();
        this.nota = parseFloat(this.nota.toFixed(2))
        if (this.walinwos > 0) {
            if (!this.repitiendo) {
                if (this.calling == true) return;
                this.calling = true;
                this.finishDictation(maxErr, this.walinwos, secs, this.nota);
            }
        } else {
            if (this.calling == true) return;
            this.calling = true;
            this.finishDictation(maxErr, 0, secs, this.nota);
        }
    }

    finishDictation(maxErr: number, walinwos: number, seconds: number, mark: number) {
        let e = this.errores();
        let countOErrors = 0;
        if (e.length == 0) {
            this.nota = 10;
        }
        else {
            countOErrors = e.filter(x => x < 0).length
        }
        
        this.dictationService.finishDictation(this.user.Id, this.dictation.dictado, JSON.stringify(e), countOErrors, maxErr, walinwos, Math.round(seconds), this.palabras_saltadas_total, /*moment(this.selectedDay).hour(moment().hours())*/`${moment(this.navigationService.params.selectedDay, 'YYYY-MM-DD').format('YYYY-MM-DD')}T00:00:00.000Z`, this.year, mark, this.level)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result: Dictation) => {
                    this.finishedDictation = result;
                    if (this.finishedDictation.HasReward) {
                        this.showDiploma = false;
                        this.avatarShopService.changeReloadItems(true);
                        this.avatarShopService.ItemBuyed.next()
                        if (this.finishedDictation.AddedAvatar) {
                            this.avatarShopService.setAvatarStatus(true);
                        }
                    }
                    this.sharedService.changeUserCoins(true)
                    this.isFin = true;
                },
                error => {
                    this.isFin = true;
                }
            )
    }

    getExtraWalis() {
        if (this.nota < 9.2)
            return 20;
        else if (this.nota < 9.4)
            return 40;
        else if (this.nota < 9.6)
            return 60;
        else if (this.nota < 9.8)
            return 80;
        else if (this.nota < 10)
            return 90;
        else
            return 100;
    }

    getNota() {
        if (this.nota === 10 || this.nota === 9 || this.nota === 8 || this.nota === 7 || this.nota === 6 || this.nota === 5 || this.nota === 4 || this.nota === 3 || this.nota === 2 || this.nota === 1 || this.nota === 0) return this.nota;
        return this.nota.toFixed(1);
    }

    volver() {
        this.contenedorDictationShow = false;
        let date = moment(this.selectedDay)
        this.dictationService.onFinishDictation.next(date);
        setTimeout(() => {
            if (this.navigationService.params.parentReview) {
                this.navigationService.go("/school/parentStudents/review/dictations", {
                    IdUser: this.navigationService.params.IdUser,
                    student: this.navigationService.params.student,
                });
                return;
            }
            if (this.teacherReview) {
                this.navigationService.go("/school/activities/dictations");
            } else if (this.review) {
                if (this.navigationService.params.isAdmin) {
                    this.navigationService.go('/school/admin/students/null/sessionAdmin/' + this.user.Id + '/' + this.navigationService.params.IdClass + '/dictationsReview/' + this.user.Id + '/' + this.navigationService.params.IdClass, { IdClass: this.navigationService.params.IdClass, navigate: 'dictation', isAdmin: true, IdUser: this.user.Id });
                } else if (this.navigationService.params.user) {
                    this.navigationService.go('/school/session/' + this.user.Id + '/' + this.navigationService.params.IdClass + '/dictationsReview/' + this.user.Id + '/' + this.navigationService.params.IdClass, { IdClass: this.navigationService.params.IdClass, navigate: 'dictation' });
                } else {
                    this.navigationService.go(`student/dictation`);
                }
            } else {
                this.navigationService.go(`student/dictation`);
            }
        }, 100);
    }

    entrada_dictado_change(letter = null) {
        this.debug_messages.push("entrada_dictado_change")
        if (!letter) return;
        if (this.caracteres_mostrar.includes(letter)) return;
        this.palabras_saltadas_seguidas = 0;
        this.add_letra(letter, true);
    }

    autoScroll(force = false) {
        let container = document.querySelector(".walinwa-scroll-container");
        if (container == null) return;
        let nextLetterIndex = this.caracteres.findIndex(c => !c.show && !this.caracteres_separadores.includes(c.caracter) && c.caracter != " ");
        if (nextLetterIndex == 0) return;
        if (this.caracteres[nextLetterIndex].hasScrolled) return;
        let currentLetterIndex = -1;
        for (let i = nextLetterIndex; i >= 0; i--) {
            let c = this.caracteres[i];
            if (c.show && !this.caracteres_separadores.includes(c.caracter) && c.caracter != " ") {
                currentLetterIndex = i;
                break;
            }
        }
        if (currentLetterIndex == -1) return;
        let currentLetter = document.getElementById(`letter_${currentLetterIndex}`);
        let nextLetter = document.getElementById(`letter_${nextLetterIndex}`);
        let nextLetterTop = nextLetter.getBoundingClientRect().top;
        let currentLetterTop = currentLetter.getBoundingClientRect().top;
        let diff = currentLetterTop / nextLetterTop;
        if (force && this.fragmento + 1 == this.fragmentos.length) {
            container.scroll(0, 1000);
            this.caracteres[nextLetterIndex].hasScrolled = true;
            return;
        }
        if (diff == 1 || this.pista != "") return;
        container.scroll(0, 1000);
        this.caracteres[nextLetterIndex].hasScrolled = true;

    }

    leer(automatico) {
        if (!this.sonido) {
            this.pista = this._translateService.instant('STUDENT.DICTATION.PLAY.TURN-ON-SOUND');
            return;
        }
        if (this.cola.indexOf(this.fragmento) < 0) {
            if (this.fragmento != this.ultimo_leido || !automatico) {
                this.cola.push(this.fragmento);
            }
        }
        if (this.speaking) return;
        this.ultimo_leido = this.cola[0];
        this.cola.splice(0, 1);
        let text = this.fragmentos[this.ultimo_leido];
        for (let i = 0; i < this.caracteres.length; i++) {
            if (this.caracteres[i].fragmento == (this.ultimo_leido + 1))
                this.caracteres[i].leido = true;
        }
        if (!text) return;
        //let textString = text.split(",");
        if (this.fragmento + 1 >= this.nFragmentos) return;
        /*let soundUrl = this.getSoundUrl();
        this.audio.src = soundUrl;*/
        this.audio = document.getElementById(`dictationFragment${this.fragmento}`) as HTMLAudioElement;

        if (this.audio.readyState >= 3 || this.audio.getAttribute('data-played') === '1') {
            this._playAudio(this.audio);
        } else {
            if (this.confirmDialog) this.confirmDialog.close();

            this.confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });
    
            this.confirmDialog.componentInstance.active = false;
            this.confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.DICTATION.PLAY.DICTATES');
            this.confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.DICTATION.PLAY.LOADING-AUDIO-MESSAGE');
            this.confirmDialog.componentInstance.space = true;
            this.confirmDialog.componentInstance.margin = false;

            this.confirmDialog.componentInstance.options = [{
                text: this._translateService.instant('CONTINUE'),
                callback: () => {}
            }];

            this.confirmDialog.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => {
                this.confirmDialog = null;
                this._playAudio(this.audio)
            });

            this.audio.oncanplaythrough = _ => this.confirmDialog.componentInstance.active = true;
        }
        /*this.audio.onended = () => {
            this.speaking = false;
        }*/

        return;
    }

    showMessage() {

        this.confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        this.confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.DICTATION.PLAY.DICTATES');
        this.confirmDialog.componentInstance.message1 = this._translateService.instant('COMPONENTS.PLANK.TOO-MANY-SKIPS-OR-ERRORS');
        this.confirmDialog.componentInstance.space = true;
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.options = [{
            text: this._translateService.instant('STUDENT.DICTATION.PLAY.REPEAT-BUTTON'),
            callback: () => {
                this.resetProperties();
                this.init();
                this.confirmDialog = null;
            }
        }, {
            text: this._translateService.instant('CONTINUE'),
            callback: () => {

            }
        }];
        this.confirmDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                result();
                this.confirmDialog = null;
            });
    }

    cancelDictationMessage() {
        let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.DICTATION.PLAY.DICTATES');
        confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.DICTATION.PLAY.SKIP-WRONG-USE');
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;
        confirmDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                this.logService.logMessage("Se cancelo el dictado del dia: " + this.dictation.dictado + " por abuso del botón Saltar palabra, nº palabras saltadas: " + this.palabras_saltadas_total + " , IdUser: " + this.currentUser.Id, LogEventLevel.Information, true).subscribe(() => {
                })
                this.navigationService.go('/student/dictation');
                confirmDialog = null;
            });
        return;
    }

    esConsonante(char: string) {
        return char.match(/^[b-df-hj-np-tv-zñÑB-DF-HJ-NP-TV-Z]$/)
    }

    resetProperties() {
        this.deshabilitarErrorSound = false;
        this.contenedorDictationShow = false;
        this.audio = document.createElement("audio");
        this.showDiploma = false;
        this.dictStart = null;
        this.messages = [];
        this.texto = "";
        this.caracteres = [];
        this.fragmentos = [];
        this.fragmento = 0;
        this.nFragmentos = 1;
        this.primera_vez = false;
        this.pista = "";
        this.sonido = false;
        this.walinwos = 0;
        this.hideBlink = false;
        this.dictado = false;
        this.nota = 10;
        this.restaNota = 0.5;
        this.restaNotaTipo = 0.05;
        this.lastRestaNota = -1;
        this.lastRestaNotaPalabra = -1;
        this.restaWalis = 10;
        this.restaWalisTipo = 1;
        this.restaWalisPalabra = 20;
        this.intervalBlink;
        this.repitiendo = true;
        this.speaking = false;
        this.fallos = [];
        this.saltos = [];
        this.indiferentes = [];
        this.isFin = false;
        this.review = false;
        this.dictSpace = false;
        this.AccentPenalty = false;
        this.palabras_saltadas_seguidas = 0;
        this.palabras_saltadas_total = 0;
        this.input = "";
        this.continued = false;
    }

    fixPositionScroll_vertical_ClassReturn() {
        const elements = document.querySelectorAll('.iphonechromepopup');
        elements.forEach((element) => {
            // (element as HTMLElement).style.overflow = 'inherit';
            element.classList.remove("popup");
        });
        //console.log("retornar scroll_fixed");
    }
    
    private _preloadAudios(): void {
        let component = document.getElementsByTagName('app-play-dictation')[0];

        for (let i = 0; i < this.nFragmentos - 1; i++) {
            let audio = document.createElement("audio");

            audio.preload = 'auto';
            audio.src = `${environment.filesUrl}${this.dictationService.subDirectory}${this.level}/${this.week}_${this.day}/${i}.mp3?${Date.now()}`;
            audio.id = `dictationFragment${i}`;
            audio.onended = () => this.speaking = false;

            component.appendChild(audio);
            audio.load();
        }
    }

    private _playAudio(audio: HTMLAudioElement): void {
        audio.setAttribute('data-played', '1');

        let playPromise = audio.play();

        if (playPromise !== undefined) {
            playPromise.then(_ => {
                this.speaking = true;
            }).catch(error => {
                this.speaking = false;
            });
        }
    }
}
