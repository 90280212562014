<div *ngIf="dataLoaded && dataLoaded2" style="width: 100%; height: 100%;">

    <!-- alumnos -->
    <section fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutAlign.lt-md="stretch"
        class="spe_gap" [hidden]="state != 'students' || !dataLoaded2">
        <div fxFlex="" fxFlex.lt-md="0 0 auto" fxNgClass.gt-sm="scroll_vertical">
            <div class="walinwa-box walinwa-box-green" *ngIf="currentClass && state == 'students'">
                <div class="walinwa-box-header spe_gap" fxLayout="row" fxLayout.lt-md="column"
                    fxLayoutAlign="space-between center">
                    <div>
                        {{'SCHOOL.ADMIN.STUDENTS.CLASS-LIST-TITLE' | translate }} {{currentClass.Name}} <small>({{'SCHOOL.TEACHER.STUDENTS.STUDENTS-COUNT' | translate }} {{ currentClass.Students.length
                            }})</small>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="spe_gap">
                        <img (click)="exportData('alumnos_aula')" src="https://walinwa.blob.core.windows.net/images/School/ico-descargar-excel.svg?ngsw-bypass=true"
                            fxFlex="2.5rem" class="pointer" />
                        <div (click)="exportData('alumnos_aula')" class="pointer">
                            {{'SCHOOL.ADMIN.STUDENTS.DOWNLOAD-EXCEL' | translate }}
                        </div>
                    </div>
                </div>
                <div class="walinwa-box-content no_padding_tables" fxLayout="column" fxLayoutAlign="stretch">
                    <div class="ww_table_rwd">
                        <table class="walinwa-table two">
                            <thead>
                                <tr>
                                    <th>{{'NAME' | translate}}</th>
                                    <th>{{'USERNAME' | translate}}</th>
                                    <th>{{'SCHOOL.COURSE' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr [ngClass]="{'selected' : selectedUser && selectedUser.Id == student.Id, 'disabled': !student.Activated }"
                                    *ngFor="let student of currentClass.Students | sortBy:'asc':['FamilyName','Name']" (click)="selectUser(student)"
                                    class="">
                                    <td>
                                        {{ student.FamilyName }}{{ getComa(student) }} {{ student.Name }}
                                    </td>
                                    <td>
                                        {{ student.UserName }}
                                    </td>
                                    <td>
                                        {{ student.Course.Name }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="12rem" fxFlex.lt-md="0 0 auto" fxNgClass.gt-sm="scroll_vertical">
            <div class="ww_sidebar_inner">
                <div (click)="editStudent()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-edit-alum.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.EDIT-STUDENT-TITLE' | translate"></div>
                </div>
                <div (click)="resetStudentPassword()" fxLayout="row" fxLayout.lt-md="column"
                    fxLayoutAlign="start center" fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem"
                    fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-reini-contra.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.RESET-PASSWORD-TITLE' | translate"></div>
                </div>
                <div (click)="deleteStudent()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-elim-alum.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.DELETE-STUDENT-TITLE' | translate"></div>
                </div>
                <div (click)="authTutor()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-auto-tutor.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.AUTH-TUTOR-TITLE' | translate"></div>
                </div>

                <div (click)="newStudent()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap"
                    [ngClass]="{ 'iPad_iPhone_margin': isIpadIphone }">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-add-alum.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.ADD-STUDENT-TITLE' | translate"></div>
                </div>
            </div>
        </div>
    </section>

    <!-- {{'SCHOOL.NEW-STUDENT-TITLE' | translate}} -->
    <div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical"
        [hidden]="state != 'newStudent1' || !dataLoaded2">
        <div class="walinwa-box-header">
            {{'SCHOOL.NEW-STUDENT-TITLE' | translate}}
        </div>
        <div class="walinwa-box-content" fxLayout="column" fxLayoutAlign="stretch">
            <div>
                {{'SCHOOL.ADMIN.STUDENTS.SELECT-CLASS-TEXT' | translate }}
            </div>
            <div>
                <label class="font_blue">{{'CLASS' | translate}}</label>
                <div class="ww_field_spe">
                    <select [(ngModel)]="currentClass" class="select">
                        <option [ngValue]="class" *ngFor="let class of school.Classes">{{ class.Name }}
                        </option>
                    </select>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
                <button [disabled]="currentClass == null" (click)="newStudent()" class="new_but azul">
                    {{'CONTINUE' | translate}}
                </button>
            </div>
        </div>
    </div>

    <!-- Generar alumnos -->
    <div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical" *ngIf="getAlulaToImport()"
        [hidden]="(state != 'generarAlumnosAula') || !dataLoaded2">
        <div class="walinwa-box-header">
            {{'SCHOOL.ADMIN.STUDENTS.GENERATE-STUDENTS-TITLE' | translate }} {{ getAlulaToImport().Name }}
        </div>
        <div class="walinwa-box-content" fxLayout="column" fxLayoutAlign="center stretch">
            <div>
                {{'SCHOOL.ADMIN.STUDENTS.GENERATE-STUDENTS-TEXT' | translate }}
            </div>
            <div fxLayout="row" fxFlex.lt-md="column">
                <div fxFlex="50%" fxFlex.lt-md="auto">                    
                    <mat-form-field class="">
                        <label class="font_blue" style="display: block;">{{'SCHOOL.ADMIN.STUDENTS.STUDENTS-AMOUNT' | translate }}</label>
                        <input [(ngModel)]="numStd" matInput type="number" min="0" max="50" class="form-control">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="numStudentsError == 1" class="error-message">
                <mat-error>
                    {{'FIELD-REQUIRED' | translate }}
                </mat-error>
            </div>
            <div *ngIf="numStudentsError == 2" class="error-message">
                <mat-error>
                    {{'SCHOOL.ADMIN.STUDENTS.STUDENTS-AMOUNT-LENGTH' | translate }}
                </mat-error>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
                <button (click)="generateStudents()" [hidden]="loading" class="new_but azul">
                    {{'CONTINUE' | translate}}
                </button>
            </div>
        </div>
    </div>


    <div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical"
        [hidden]="(state != 'newStudent' && state != 'editStudent') || !dataLoaded2">
        <div class="walinwa-box-header spe_gap" fxLayout="row" fxLayout.lt-md="column"
            fxLayoutAlign="space-between center" *ngIf="currentClass" [hidden]="state == 'editStudent'">
            <div>
                {{'SCHOOL.NEW-STUDENT-TITLE' | translate}} {{ currentClass.Name }}
            </div>
            <!--div fxLayout="row" fxLayoutAlign="end center" class="spe_gap">
                <img (click)="state = 'generarAlumnosAula'" [src]="schoolService.getImgUrl('ico-generar-alumnos.svg')"
                    fxFlex="2.5rem" class="pointer" />
                <div (click)="state = 'generarAlumnosAula'" class="pointer" style="display: inline-flex;">
                    Generar<br />alumnos
                </div>
                <div fxFlex="10px"></div>
                <img (click)="state = 'importarAlumnosAulaExcel'" [src]="schoolService.getImgUrl('ico-import-excel.svg')"
                    fxFlex="2.5rem" class="pointer" />
                <div (click)="state = 'importarAlumnosAulaExcel'" class="pointer" style="display: inline-flex;">
                    Importar<br />Excel
                </div>
            </div-->
        </div>
        <div class="walinwa-box-header" [hidden]="state == 'newStudent' ||  extraFields == true">
            {{'SCHOOL.EDIT-STUDENT' | translate}}
        </div>
        <div class="walinwa-box-header" [hidden]="state == 'newStudent' ||  extraFields == false">
            {{'SCHOOL.STUDENT-OPTIONS' | translate}}
        </div>
        <form class="walinwa-box-content" fxLayout="column" fxLayoutAlign="stretch" [formGroup]="formDataStudent" #formDataStudentDirective="ngForm"
            *ngIf="formDataStudent" autocomplete="new-password">
            <div fxLayout="column" fxLayoutAlign="stretch" class="spe_gap"
                *ngIf="!extraFields && state == 'editStudent'">
                <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
                    <div fxFlex="15rem" fxFlex.xs="100%">
                        <div class="content-avatar" id="avatar-download">
                            <avatar-file *ngIf="activeItems && activeItems.length > 0" [activeItems]=activeItems (click)="saveAvatar()" style="cursor: pointer;" style="position: relative; z-index: 2;"></avatar-file>
                            <img *ngIf="!activeItems || activeItems.length == 0" src="https://walinwa.blob.core.windows.net/images/School/avatar-default.svg?ngsw-bypass=true" style="position: relative; z-index: 2;" />
                        </div>
                    </div>
                    <div fxFlex="" fxFlex.xs="100%" fxLayout="column" fxLayputAlign="stretch">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxNgClass.gt-sm="spe_gap">
                            <div fxFlex="" fxFlex.lt-md="100%">                                
                                <mat-form-field class="less_top_margin_field">
                                    <label class="font_blue">{{'SCHOOL.SCHOOL-COURSE' | translate }}</label>
                                    <mat-select formControlName="Level" [ngModelOptions]="{standalone: true}">
                                        <mat-option class="scrollable" [value]="course.Level"
                                            *ngFor="let course of courses | sortBy:'asc':'Level'">{{course.Name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="" fxFlex.lt-md="100%">                                
                                <mat-form-field class="less_top_margin_field">
                                    <label class="font_blue">{{'CLASS' | translate}}</label>
                                    <mat-select formControlName="IdClass" [ngModelOptions]="{standalone: true}">
                                        <mat-option class="scrollable" [value]="class.IdClass"
                                            *ngFor="let class of school.Classes">{{ class.Name }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxNgClass.gt-sm="spe_gap">
                            <div fxFlex="" fxFlex.lt-md="100%">                                
                                <mat-form-field class="less_top_margin_field">
                                    <label class="font_blue">{{'NAME' | translate}}</label>
                                    <input maxlength="41" formControlName="Name" matInput type="text" class="form-control">
                                    <mat-error *ngIf="formDataStudent.get('Name').hasError('required')">
                                        {{'FIELD-REQUIRED' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="formDataStudent.get('Name').hasError('pattern')">
                                        {{'PATTERN-ERROR' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="formDataStudent.get('Name').hasError('maxlength')">
                                        {{'NAME-MAX' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="" fxFlex.lt-md="100%">                                
                                <mat-form-field class="less_top_margin_field">
                                    <label class="font_blue">{{'FAMILYNAME' | translate}}</label>
                                    <input maxlength="51" formControlName="FamilyName" matInput type="text" class="form-control">
                                    <mat-error *ngIf="formDataStudent.get('FamilyName').hasError('pattern')">
                                        {{'PATTERN-ERROR' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="formDataStudent.get('FamilyName').hasError('maxlength')">
                                        {{'FAMILYNAME-MAX' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
                            <div fxFlex="" fxFlex.lt-md="100%">
                                <mat-form-field class="less_top_margin_field">
                                    <label class="font_blue" (click)="showStudentId=!showStudentId">{{'USERNAME' | translate}}</label>
                                    <input maxlength="51" id="studentUserName" formControlName="Username" matInput type="text">
                                    <mat-error *ngIf="formDataStudent.get('Username').hasError('pattern')">
                                        {{'PATTERN-ERROR' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="formDataStudent.get('Username').hasError('minlength') || formDataStudent.get('Username').hasError('maxlength')">
                                        {{'USERNAME-MIN-MAX' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="formDataStudent.get('Username').hasError('unique')">
                                        {{'AUTHENTICATION.ERROR.USERNAME' | translate}}
                                    </mat-error>
                                    <div *ngIf="showStudentId" style="right: 15%; z-index: 1; height: 4em; width: 15em;"
                                        class="walinwa-tooltip walinwa-tooltip-right">
                                        <div class="walinwa-tooltip-info walinwa-tooltip-info-primary">
                                            {{'SCHOOL.USER-ID' | translate}} {{ student.Id }}
                                        </div>
                                    </div>
                                </mat-form-field>
                            </div>
                            <div fxFlex="" fxFlex.lt-md="100%">                                
                                <mat-form-field class="less_top_margin_field">
                                    <label class="font_blue">{{'ALIAS' | translate}}</label>
                                    <input maxlength="51" formControlName="Alias" matInput type="text" class="">
                                    <mat-error *ngIf="formDataStudent.get('Alias').hasError('maxlength')">
                                        {{'ALIAS-MAX' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxNgClass.gt-xs="spe_gap">
                    <div fxFlex="15rem" fxFlex.xs="100%" fxShow fxHide.xs></div>
                    <div fxFlex="" fxLayout="row wrap" fxLayoutAlign="end start"
                        fxLayoutAlign.xs="center center" class="spe_gap">
                        <button [disabled]="calling" (click)="changeExtraFields()" class="new_but azul">
                            {{'SCHOOL.OPTIONS' | translate}}
                        </button>
                        <button [disabled]="calling" (click)="updateStudent()" class="new_but azul">
                            {{'SAVE' | translate}}
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="state == 'newStudent'  && !extraFields" fxLayout="row wrap"
                fxLayoutAlign="space-between start" fxNgClass.gt-xs="spe_gap">
                <div fxFlex="" fxFlex.xs="100%">                    
                    <mat-form-field class="less_top_margin_field">
                        <label class="font_blue">{{'NAME' | translate}}</label>
                        <input maxlength="41" formControlName="Name" matInput type="text" class="">
                        <mat-error *ngIf="formDataStudent.get('Name').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formDataStudent.get('Name').hasError('pattern')">
                            {{'PATTERN-ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formDataStudent.get('Name').hasError('maxlength')">
                            {{'NAME-MAX' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.xs="100%">                    
                    <mat-form-field class="less_top_margin_field">
                        <label class="font_blue">{{'FAMILYNAME' | translate}}</label>
                        <input maxlength="51" formControlName="FamilyName" matInput type="text" class="form-control">
                        <mat-error *ngIf="formDataStudent.get('FamilyName').hasError('pattern')">
                            {{'PATTERN-ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formDataStudent.get('FamilyName').hasError('maxlength')">
                            {{'FAMILYNAME-MAX' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="state == 'newStudent' && !extraFields" fxLayout="row wrap"
                fxLayoutAlign="space-between start" fxNgClass.gt-xs="spe_gap">
                <div fxFlex="" fxFlex.xs="100%">
                    <label class="font_blue"[innerHTML]="'SCHOOL.AUTOMATIC-USER' | translate"></label>
                    <div class="ww_field_spe">
                        <input formControlName="Username" disabled matInput type="text" class="form-control">
                    </div>
                    <div class="ww_legend spe">
                        {{'SCHOOL.AUTOMATIC-USER-TEXT' | translate }}
                    </div>
                </div>
                <div fxFlex="" fxFlex.xs="100%">
                    <label class="font_blue">{{'SCHOOL.INITIAL-PASSWORD' | translate}}</label>
                    <div class="ww_field_spe">
                        <input formControlName="Password" disabled matInput class="form-control"  style="background-color: transparent !important; padding-left: 0px !important;">
                    </div>
                </div>
            </div>
            <div *ngIf="state == 'editStudent' && extraFields" fxLayout="row wrap" fxLayoutAlign="start center"
                fxNgClass.gt-xs="spe_gap">
                <div fxFlex="25rem" fxFlex.xs="100%">
                    <div fxFlex="11rem" fxFlex.xs="100%">
                        <label class="font_blue">{{'SCHOOL.MULTIME' | translate}}</label>
                    </div>
                    <mat-form-field style="margin-top: -5px;">
                        <mat-select formControlName="MultTime" class="select">
                            <mat-option class="scrollable" [value]="multiTime.value"
                                *ngFor="let multiTime of multiTimes">{{multiTime.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="state == 'editStudent' && extraFields" class="less_top_margin_field">
                <mat-checkbox [(ngModel)]="student.SmallSession" [ngModelOptions]="{standalone: true}">{{'SCHOOL.SMALLSESSION' | translate}}</mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.SMALLSESSION-INFO' | translate"></tooltip>
            </div>
            <div *ngIf="state == 'editStudent' && extraFields">
                <mat-checkbox [(ngModel)]="student.BlockName" [ngModelOptions]="{standalone: true}">{{'SCHOOL.BLOCKNAME' | translate}}</mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.BLOCKNAME-INFO' | translate"></tooltip>
            </div>
            <div *ngIf="state == 'editStudent' && extraFields">
                <mat-checkbox [(ngModel)]="student.GiftShopDisabled" [ngModelOptions]="{standalone: true}">{{'SCHOOL.GIFTSHOPDISABLED' | translate}}</mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.GIFTSHOPDISABLED-INFO' | translate"></tooltip>
            </div>
            <div *ngIf="state == 'editStudent' && extraFields">
                <mat-checkbox [(ngModel)]="student.BlockMessageGift" [ngModelOptions]="{standalone: true}">{{'SCHOOL.BLOCKMESSAGEGIFT' | translate}}</mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.BLOCKMESSAGEGIFT-INFO' | translate"></tooltip>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap"
                *ngIf="state == 'newStudent'">
                <button [disabled]="calling" (click)="getStudentName()" class="new_but azul">
                    {{'CONTINUE' | translate}}
                </button>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap"
                *ngIf="extraFields">
                <button [disabled]="calling" (click)="updateStudent()" class="new_but azul">
                    {{'SAVE' | translate}}
                </button>
            </div>
        </form>
    </div>

    <div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical"
        [hidden]="state != 'authTutor' || !dataLoaded2">
        <div class="walinwa-box-header">
            <span *ngIf="student">{{'SCHOOL.AUTH-TEACHER-TITLE' | translate}} '{{ student.Name }}'</span>
        </div>
        <div class="walinwa-box-content" fxLayout="column" fxLayoutAlign="stretch">
            <div>
                {{'SCHOOL.AUTH-TEACHER-MESSAGE' | translate}}
            </div>
            <div>
                <label class="font_blue">{{'EMAIL' | translate}}</label>
                <div class="ww_field_spe">
                    <input [(ngModel)]="email" type="email">
                </div>
                <div *ngIf="error" class="error-message">
                    <mat-error>
                        {{errorDescription}}
                    </mat-error>
                </div>
                
            </div>
            <div>
                <mat-checkbox [(ngModel)]="correoConfirmacion">
                    {{'SCHOOL.SEND-PASSWORD-EMAIL' | translate}}
                </mat-checkbox>
            </div>
            <div *ngIf="authUsers">
                <div class="ww_table_rwd" *ngIf="authUsers.length>0">
                    <table class="walinwa-table" style="border-top: 0.05em solid var(--gris);">
                        <tbody>
                            <tr [ngClass]="{'selected' : authUser == selectedAuthUser}"
                                (click)="selectAuthUser(authUser)" *ngFor="let authUser of authUsers">
                                <td class="text-center">{{ authUser }}</td>
                                <td style="width: 50px;"><img (click)="deleteAuthUser()" class="spe_ico_exam"
                                    src="https://walinwa.blob.core.windows.net/images/School/ico-trash.svg?ngsw-bypass=true"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
                <button [disabled]="calling" (click)="addAuthUser()" class="new_but azul">
                    {{'SAVE' | translate}}
                </button>
            </div>
        </div>
    </div>

     <!-- alumnos aula -->
     <section fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutAlign.lt-md="stretch"
     class="spe_gap" [hidden]="state != 'studentList' || !dataLoaded2">
     <div fxFlex="" fxFlex.lt-md="0 0 auto" fxNgClass.gt-sm="scroll_vertical">
         <div class="walinwa-box walinwa-box-green">
             <div class="walinwa-box-header spe_gap" fxLayout="row" fxLayout.lt-md="column"
                 fxLayoutAlign="space-between center">
                 <div>
                    {{'SCHOOL.ADMIN.STUDENTS.STUDENTS-LIST-TITLE' | translate }} <small>({{'SCHOOL.TEACHER.STUDENTS.STUDENTS-COUNT' | translate }} {{ schoolStudents.length }})</small>
                 </div>
                 <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem" class="">
                     <img (click)="exportData('alumnos')" src="https://walinwa.blob.core.windows.net/images/School/ico-descargar-aulas.svg?ngsw-bypass=true" fxFlex="2.5rem"
                         class="pointer" />
                     <div>
                         <select [(ngModel)]="excelAlumnos" class="download_select">
                             <option value="1">{{'SCHOOL.ADMIN.STUDENTS.DOWNLOAD-PER-CLASS' | translate }}</option>
                             <option value="2">{{'SCHOOL.ADMIN.STUDENTS.DOWNLOAD-PER-STUDENT' | translate }}</option>
                         </select>
                     </div>
                 </div>
             </div>
             <div class="walinwa-box-content no_padding_tables" fxLayout="column" fxLayoutAlign="center stretch">
                 <div>
                     <div class="ww_table_rwd">
                         <table class="walinwa-table two">
                             <thead>
                                 <tr>
                                     <th>{{'NAME' | translate}}</th>
                                     <th>{{'USERNAME' | translate}}</th>
                                     <th>{{'SCHOOL.COURSE' | translate}}</th>
                                     <th>{{'CLASS' | translate}}</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 <tr [ngClass]="{'selected' : selectedUser && selectedUser.Id == student.Id, 'disabled': !student.Activated }"
                                     *ngFor="let student of schoolStudents | sortBy:'asc':['FamilyName','Name']" (click)="selectUser(student)" class="">
                                     <td>
                                         {{ student.FamilyName }}{{ getComa(student) }} {{ student.Name }}
                                     </td>
                                     <td>
                                         {{ student.UserName }}
                                     </td>
                                     <td>
                                         {{ student.Course.Name }}
                                     </td>
                                     <td>
                                         <span *ngIf="student.Class">{{ student.Class.Name }}</span>
                                     </td>
                                 </tr>
                             </tbody>
                         </table>
                     </div>
                 </div>
             </div>
         </div>
     </div>
     <div fxFlex="12rem" fxFlex.lt-md="0 0 auto" fxNgClass.gt-sm="scroll_vertical">
         <div class="ww_sidebar_inner">
             <div (click)="editStudent()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                 fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                 <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                     <img src="https://walinwa.blob.core.windows.net/images/School/ico-edit-alum.svg?ngsw-bypass=true" />
                 </div>
                 <div fxFlex="" [innerHTML]="'SCHOOL.EDIT-STUDENT-TITLE' | translate"></div>
             </div>
             <div (click)="resetStudentPassword()" fxLayout="row" fxLayout.lt-md="column"
                 fxLayoutAlign="start center" fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem"
                 fxNgClass.gt-sm="spe_gap">
                 <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                     <img src="https://walinwa.blob.core.windows.net/images/School/ico-reini-contra.svg?ngsw-bypass=true" />
                 </div>
                 <div fxFlex="" [innerHTML]="'SCHOOL.RESET-PASSWORD-TITLE' | translate"></div>
             </div>
             <div (click)="deleteStudent()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                 fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                 <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                     <img src="https://walinwa.blob.core.windows.net/images/School/ico-elim-alum.svg?ngsw-bypass=true" />
                 </div>
                 <div fxFlex=""[innerHTML]="'SCHOOL.DELETE-STUDENT-TITLE' | translate"></div>
             </div>
             <div (click)="goSessionReview()" fxLayout="row" fxLayout.lt-md="column"
                 fxLayoutAlign="start center" fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem"
                 fxNgClass.gt-sm="spe_gap">
                 <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                     <img src="https://walinwa.blob.core.windows.net/images/School/ico-rev-activi.svg?ngsw-bypass=true" />
                 </div>
                 <div fxFlex="" [innerHTML]="'SCHOOL.REVIEW-ACTIVITIES-TITLE' | translate"></div>
             </div>
             <div (click)="authTutor()" fxLayout="row" fxLayout.lt-md="column"
                 fxLayoutAlign="start center" fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem"
                 fxNgClass.gt-sm="spe_gap">
                 <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                     <img src="https://walinwa.blob.core.windows.net/images/School/ico-auto-tutor.svg?ngsw-bypass=true" />
                 </div>
                 <div fxFlex="" [innerHTML]="'SCHOOL.AUTH-TUTOR-TITLE' | translate"></div>
             </div>
             <div (click)="goNewStudent()" fxLayout="row" fxLayout.lt-md="column"
                 fxLayoutAlign="start center" fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem"
                 fxNgClass.gt-sm="spe_gap" [ngClass]="{ 'iPad_iPhone_margin': isIpadIphone }">
                 <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                    <img src="https://walinwa.blob.core.windows.net/images/School/ico-add-alum.svg?ngsw-bypass=true" />
                 </div>
                 <div fxFlex="" [innerHTML]="'SCHOOL.ADD-STUDENT-TITLE' | translate"></div>
             </div>
         </div>
     </div>
 </section>

    <!-- nuevo aula -->
    <div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical"
        [hidden]="!(state || '').includes('newClass') || !dataLoaded2">
        <div class="walinwa-box-header" *ngIf="state == 'newClass'">
            {{'SCHOOL.ADMIN.NEW-CLASS-TITLE' | translate }}
        </div>        
        <div class="walinwa-box-content" fxLayout="column" fxLayoutAlign="center stretch"
            *ngIf="extraFields && currentClass && currentClass.AuthorizedParents">
            <div>
                {{'SCHOOL.AUTH-CLASS-MESSAGE' | translate }}
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-around center" class="spe_gap">
                <div fxFlex="75%" fxFlex.lt-md="100%">                    
                    <mat-form-field class="less_top_margin_field" style="width: 100%;">
                        <label class="font_blue" style="display: block;">{{'SCHOOL.AUTH-NEW-TEACHER' | translate }}</label>
                        <mat-select [disabled]="teachersAux.length==0" [(ngModel)]="selectedTeacherAux">
                            <mat-option class="scrollable" *ngIf="teachersAux.length==0" [value]="null">{{'SCHOOL.NO-TEACHERS' | translate }}</mat-option>
                            <mat-option class="scrollable"
                                *ngFor="let teacher of teachersAux | sortBy:'asc':'FamilyName'"
                                [value]="teacher.Id">{{parseProfesorName(teacher)}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%">
                    <button [disabled]="!selectedTeacherAux" (click)="addTeacherAux()" class="new_but azul">
                        {{'SAVE' | translate}}
                    </button>
                </div>
            </div>
            <div>
                <label class="font_blue">{{'SCHOOL.AUTH-TEACHERS' | translate }}</label>
            </div>
            <div>
                <div class="ww_table_rwd">
                    <table class="walinwa-table">
                        <tbody>
                            <tr class="selected" *ngFor="let teacher of currentClass.AuthorizedParents">
                                <td>
                                    {{ teacher.UserTutor.Email }}
                                </td>
                                <td style="width: 50px;">
                                    <img (click)="removeAuthTeacher(teacher)" class="spe_ico_exam"
                                    src="https://walinwa.blob.core.windows.net/images/School/ico-trash.svg?ngsw-bypass=true">
                                </td>
                            </tr>
                            <tr *ngIf="currentClass.AuthorizedParents.length == 0">
                                <td align="center">
                                    {{'SCHOOL.NO-AUTH-TEACHERS' | translate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <form class="walinwa-box-content" fxLayout="column" fxLayoutAlign="center stretch"
            [hidden]="extraFields" [formGroup]="formDataClass" *ngIf="formDataClass" #formDataClassDirective="ngForm">
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
                <div fxFlex="" fxFlex.lt-md="100%">                    
                    <mat-form-field class="less_top_margin_field">
                        <label class="font_blue">{{'SCHOOL.SCHOOL-COURSE' | translate }}</label>
                        <mat-select formControlName="Level">
                            <mat-option class="scrollable" [value]="class.Level"
                                *ngFor="let class of courses | sortBy:'asc':'Level'">{{ class.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formDataClass.get('Level').hasError('required')">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%">                    
                    <mat-form-field class="less_top_margin_field arrowed-autocomplete">
                        <label class="font_blue" [innerHTML]="'SCHOOL.CLASS-LETTER' | translate"></label>
                        <input formControlName="Name" [matAutocomplete]="autoGrupo" matInput type="text" />
                        <svg viewBox="0 0 24 24" width="24px" height="24px" focusable="false" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
                        <mat-autocomplete autoActiveFirstOption #autoGrupo="matAutocomplete">
                            <mat-option class="scrollable" [value]="letter" *ngFor="let letter of classLetters">
                                {{ letter }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="formDataClass.get('Name').hasError('required')">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
                <div fxFlex="0 0 calc(50% - 1rem)" fxFlex.lt-md="100%">                    
                    <mat-form-field class="less_top_margin_field">
                        <label class="font_blue">{{'SCHOOL.AUTH-TEACHER' | translate }}</label>
                        <mat-select [disabled]="teachersAux.length==0" formControlName="Teacher">
                            <mat-option class="scrollable" [value]="teacher.Id"
                                *ngFor="let teacher of teachers | sortBy:'asc':'FamilyName'">{{parseProfesorName(teacher)}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formDataClass.get('Teacher').hasError('required')">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="0 0 calc(50% - 1rem)" fxFlex.lt-md="100%" [hidden]="state == 'newClass'">
                    <label class="font_blue" (click)="showClassId=!showClassId">{{'SCHOOL.CLASS-CODE' | translate }}</label>
                    <div class="ww_field_spe two" style="position: relative;">
                        <input id="formCodeId" formControlName="Code" matInput type="text" class="form-control">
                        <div *ngIf="showClassId"
                            style="left: 9.5rem; top: -3.5rem; z-index: 1; height: 4em; width: 15em;"
                            class="walinwa-tooltip walinwa-tooltip-right">
                            <div class="walinwa-tooltip-info walinwa-tooltip-info-primary">
                                {{'CLASS' | translate}}: {{ currentClass.IdClass }}
                            </div>
                        </div>
                    </div>
                    <mat-error *ngIf="formDataClass.get('Code').hasError('required')">
                        {{'FIELD-REQUIRED' | translate }}
                    </mat-error>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center"
                class="spe_gap">

                <button [disabled]="calling" (click)="saveClass()" class="new_but azul">
                    {{'SAVE' | translate}}
                </button>
            </div>
        </form>
    </div>

    <div class="walinwa-box walinwa-100" [hidden]="state != 'reviewActivities'">
        <router-outlet></router-outlet>
    </div>
</div>

<img [hidden]="state == 'start' || dataLoaded2 || state == 'reviewActivities'"
    style="width: 10em; position: fixed;top: 0;left: 0;bottom: 0;right: 0;margin: auto;"
    src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">

<img [hidden]="!loading" style="width: 10em; position: fixed;top: 0;left: 0;bottom: 0;right: 0;margin: auto;"
    src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">