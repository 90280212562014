
<div *ngIf="dataLoaded && dataLoaded2" style="width: 100%; height: 100%;">

    <!-- profesores -->
    <section fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutAlign.lt-md="stretch"
        class="spe_gap" [hidden]="state != 'teacher' || !dataLoaded2">
        <div fxFlex="" fxFlex.lt-md="0 0 auto" fxNgClass.gt-sm="scroll_vertical">
            <div class="walinwa-box walinwa-box-green">
                <div class="walinwa-box-header spe_gap" fxLayout="row" fxLayout.lt-md="column"
                    fxLayoutAlign="space-between center">
                    <div [innerHTML]="'SCHOOL.ADMIN.TEACHERS.TITLE' | translate: {NumTeachers: this.getNumProfesores()}">
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem" class="">
                        <img (click)="exportData('profesores')" src="https://walinwa.blob.core.windows.net/images/School/ico-descargar-aulas.svg?ngsw-bypass=true"
                            fxFlex="2.5rem" class="pointer" />
                        <select [(ngModel)]="escelOpt" class="download_select">
                            <option value="1">{{'SCHOOL.ADMIN.TEACHERS.EXCEL-OPT-1' | translate}}</option>
                            <option value="2">{{'SCHOOL.ADMIN.TEACHERS.EXCEL-OPT-2' | translate}}</option>
                        </select>
                    </div>
                </div>
                <div class="walinwa-box-content no_padding_tables" fxLayout="column" fxLayoutAlign="center stretch">
                    <div class="ww_table_rwd">
                        <table class="walinwa-table two">
                            <thead>
                                <tr>
                                    <th>{{'SCHOOL.ADMIN.TEACHERS.LIST-HEADER-1' | translate}}</th>
                                    <th>{{'USERNAME' | translate}}</th>
                                    <th>{{'SCHOOL.ADMIN.TEACHERS.LIST-HEADER-3' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr [ngClass]="{'selected' : selectedUser && selectedUser.Id == teacher.Id}"
                                    *ngFor="let teacher of teachers | sortBy:'asc':'FamilyName'"
                                    [hidden]="!isTeacher(teacher)" (click)="selectUser(teacher)" class="">
                                    <td>
                                        {{'SCHOOL.ADMIN.TEACHERS.LIST-VALUE-1' | translate: {Name: parseProfesorName(teacher)} }}
                                    </td>
                                    <td
                                        [ngClass]="{ bold: comprobarAdmin(teacher), light: !comprobarAsignacion(teacher) }">
                                        {{'SCHOOL.ADMIN.TEACHERS.LIST-VALUE-2' | translate: {UserName: teacher.UserName} }}
                                    </td>
                                    <td>
                                        {{'SCHOOL.ADMIN.TEACHERS.LIST-VALUE-3' | translate: {Email: teacher.Email} }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="12rem" fxFlex.lt-md="0 0 auto" fxNgClass.gt-sm="scroll_vertical">
            <div class="ww_sidebar_inner">
                <div (click)="editTeacher()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-editar-profesor.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.ADMIN.TEACHERS.EDIT-TEACHER' | translate"></div>
                </div>
                <div (click)="resetTeacherPassword()" fxLayout="row" fxLayout.lt-md="column"
                    fxLayoutAlign="start center" fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem"
                    fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-reini-contra.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.RESET-PASSWORD-TITLE' | translate"></div>
                </div>
                <div (click)="deleteTeacher()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-eliminar-profe.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.ADMIN.TEACHERS.DELETE-TEACHER' | translate"></div>
                </div>
                <div (click)="importTeacher()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-importar-profe.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.ADMIN.TEACHERS.IMPORT-TEACHER' | translate"></div>
                </div>
                <div (click)="newTeacher()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap"
                    [ngClass]="{ 'iPad_iPhone_margin': isIpadIphone }">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-add-profe.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.ADMIN.TEACHERS.NEW-TEACHER' | translate"></div>
                </div>
            </div>
        </div>
    </section>   

    <!-- nuevo profesor / editar profesor -->
    <div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical"
        [hidden]="(state != 'newTeacher' && state != 'editTeacher') || !dataLoaded2">
        <div class="walinwa-box-header">
            <span [hidden]="state == 'editTeacher'">{{'SCHOOL.ADMIN.TEACHERS.NEW-TEACHER-TITLE' | translate }}</span>
            <span [hidden]="state == 'newTeacher'">{{'SCHOOL.ADMIN.TEACHERS.EDIT-TEACHER-TITLE' | translate }}</span>
        </div>
        <form class="walinwa-box-content" [formGroup]="formDataAdmin" *ngIf="formDataAdmin" #formDataAdminDirective="ngForm">
            <div fxLayout="column" fxLayoutAlign="stretch" class="spe_gap">
                <div fxLayout="row wrap" fxLayoutAlign="space-around start" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="" fxFlex.lt-md="100%">
                        <label class="font_blue">{{'NAME' | translate }}</label>
                        <mat-form-field class="less_top_margin_field">
                            <input maxlength="35" formControlName="Name" matInput type="text">
                            <mat-error *ngIf="formDataAdmin.get('Name').hasError('required')">
                                {{'FIELD-REQUIRED' | translate }}
                            </mat-error>
                            <mat-error *ngIf="formDataAdmin.get('Name').hasError('pattern')">
                                {{'PATTERN-ERROR' | translate }}s
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="" fxFlex.lt-md="100%">
                        <label class="font_blue">{{'FAMILYNAME' | translate }}</label>
                        <mat-form-field class="less_top_margin_field">
                            <input maxlength="35" formControlName="FamilyName" matInput type="text">
                            <mat-error *ngIf="formDataAdmin.get('FamilyName').hasError('pattern')">
                                {{'PATTERN-ERROR' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-around start" fxNgClass.gt-sm="spe_gap"
                    ngStyle.lt-md="margin: -1rem 0;">
                    <div fxFlex="" fxFlex.lt-md="100%">
                        <label class="font_blue">{{'EMAIL' | translate }}</label>
                        <mat-form-field class="less_top_margin_field">
                            <input maxlength="100" formControlName="Email" matInput type="email">
                            <mat-error *ngIf="formDataAdmin.get('Email').hasError('required')">
                                {{'FIELD-REQUIRED' | translate }}
                            </mat-error>
                            <mat-error *ngIf="formDataAdmin.get('Email').hasError('email')">
                                {{'EMAIL-INVALID' | translate }}
                            </mat-error>
                            <mat-error *ngIf="formDataAdmin.get('Email').hasError('unique')">
                                {{'ERROR.EMAIL-EXIST' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxHide fxShow.gt-sm fxFlex="" fxFlex.lt-md="100%">
                        <!--  -->
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-around start" class="spe_gap">
                    <div fxFlex="" fxFlex.lt-md="100%">
                        <label class="font_blue" *ngIf="state=='newTeacher'">{{'SCHOOL.ADMIN.TEACHERS.USERNAME' | translate }}<sup>1</sup></label>
                        <label class="font_blue" *ngIf="state=='editTeacher'" (click)="showStudentId=!showStudentId">{{'USERNAME' | translate }}</label>
                        <div class="ww_field_spe" [ngClass]="{disabled: state=='newTeacher'}">
                            <input maxlength="50" id="teacherUserName" formControlName="Username"
                                [disabled]="state=='newTeacher'" matInput type="text" class="">
                        </div>
                        <mat-error *ngIf="formDataAdmin.get('Username').hasError('required')">
                            {{ 'FIELD-REQUIRED' | translate }}
                        </mat-error>
                        <mat-error *ngIf="formDataAdmin.get('Username').hasError('pattern')">
                            {{'PATTERN-ERROR' | translate }}
                        </mat-error>
                        <mat-error *ngIf="formDataAdmin.get('Username').hasError('unique')">
                            {{'AUTHENTICATION.ERROR.USERNAME' | translate }}
                        </mat-error>
                        <mat-error *ngIf="formDataAdmin.get('Username').hasError('length')">
                            {{'USERNAME-MIN-MAX' | translate }}
                        </mat-error>
                        <mat-error *ngIf="formDataAdmin.get('Username').hasError('characters')">
                            {{'PATTERN-ERROR' | translate }}
                        </mat-error>
                        <div *ngIf="showStudentId" style="left: 14%;top: 43%; z-index: 1; height: 4em; width: 15em;"
                            class="walinwa-tooltip walinwa-tooltip-right">
                            <div class="walinwa-tooltip-info walinwa-tooltip-info-primary">
                                {{'SCHOOL.ADMIN.TEACHERS.TEACHER-ID' | translate }}: {{ teacher.Id }}
                            </div>
                        </div>
                        <div class="ww_legend" *ngIf="state=='newTeacher'">
                            {{'SCHOOL.AUTOMATIC-USER-TEXT' | translate }}
                        </div>
                    </div>
                    <div fxFlex="" fxFlex.lt-md="100%">
                        <label class="font_blue" *ngIf="state=='newTeacher'">{{'SCHOOL.ADMIN.TEACHERS.PASSWORD-NEW-TEACHER' | translate }}<sup>2</sup></label>
                        <label class="font_blue" *ngIf="state=='editTeacher'">{{ 'PASSWORD' | translate }}</label>
                        <div class="ww_field_spe" [ngClass]="{disabled: state=='newTeacher'}">
                            <input [placeholder]="state=='editTeacher' ? '·····' : ''" formControlName="Password"
                                [disabled]="state=='newTeacher'" matInput type="password" class="">
                        </div>
                        <div class="ww_legend" *ngIf="state=='newTeacher'">
                            {{'SCHOOL.ADMIN.TEACHERS.PASSWORD-LEGEND' | translate }}
                        </div>
                    </div>
                </div>
                <div [hidden]="!checkIsAdmin">
                    <mat-checkbox formControlName="isSchoolAdmin">
                        {{'SCHOOL.ADMIN.TEACHERS.SCHOOL-ADMIN' | translate }}
                    </mat-checkbox>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between end">
                <div fxFlex="70%" fxFlex.lt-md="100%" style="padding: 1rem 2rem; border-radius: 10px; background: white;" *ngIf="showMessage && state == 'editTeacher'">
                    <div class="">
                        <b>{{'SCHOOL.ADMIN.TEACHERS.ASSIGNED-CLASSES' | translate }}</b> {{msg1}}
                    </div>
                    <div class="">
                        <b>{{'SCHOOL.ADMIN.TEACHERS.AUTHORIZED-CLASSES' | translate }}</b> {{msg2}}
                    </div>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%" fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap" fxNgClass.lt-md="mt-16">
                    <button [disabled]="calling" (click)="getTeacherName()" [hidden]="state == 'editTeacher'"
                    class="new_but azul">
                    {{'CONTINUE' | translate }}
                    </button>

                    <button [disabled]="calling" (click)="updateTeacher()" [hidden]="state == 'newTeacher'"
                    class="new_but azul">
                    {{'SAVE' | translate}}
                    </button>
                </div>
            </div>
        </form>
    </div>

    <!-- importar profesores desde excel -->
    <div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical"
        [hidden]="state != 'importarProfesoresAulaExcel' || !dataLoaded2">
        <div class="walinwa-box-header">
            {{'SCHOOL.ADMIN.TEACHERS.IMPORT-EXCEL' | translate }}
        </div>
        <div class="walinwa-box-content" fxLayout="column" fxLayoutAlign="stretch">
            <div [innerHTML]="'SCHOOL.ADMIN.TEACHERS.IMPORT-EXCEL-TEXT' | translate">
            </div>
            <div fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-between start" class="gap_desc_file">
                <div fxFlex="" fxFlex.xs="100%">
                    <label class="font_blue">{{'SCHOOL.ADMIN.TEACHERS.COLUMNS-TO-IMPORT' | translate }}</label>
                    <div class="ww_field_spe">
                        <select [(ngModel)]="importDataConfigProfe.numeroColumnasImportar"
                            [ngModelOptions]="{standalone: true}" class="select" (ngModelChange)="change()">
                            <option [value]="numVal.key" *ngFor="let numVal of numColumnasProfe">
                                {{ numVal.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div fxFlex="" fxFlex.xs="100%">
                    <mat-checkbox [(ngModel)]="importDataConfigProfe.descartarPrimeraFila"
                        [ngModelOptions]="{standalone: true}">
                        {{'SCHOOL.ADMIN.TEACHERS.DISMISS-FIRST-COLUMN' | translate }}
                    </mat-checkbox>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="gap_desc_file">
                <div fxFlex="" fxFlex.xs="100%">
                    <label class="font_blue">{{'SCHOOL.ADMIN.TEACHERS.COLUMNS-ORDER' | translate }}</label>
                    <div class="ww_field_spe">
                        <select [(ngModel)]="importDataConfigProfe.ordenColumnas" [ngModelOptions]="{standalone: true}">
                            <option [value]="i"
                                *ngFor="let orden of ordenColumnasProfe[importDataConfigProfe.numeroColumnasImportar]; let i = index">
                                {{ orden }}
                            </option>
                        </select>
                    </div>
                </div>
                <div fxFlex="" fxFlex.xs="100%">
                    <mat-checkbox
                        *ngIf="(importDataConfigProfe.numeroColumnasImportar == 1 && importDataConfigProfe.ordenColumnas != 0 ) && importDataConfigProfe.ordenColumnas != 4"
                        [(ngModel)]="importDataConfigProfe.nombreApellidosSeparados"
                        [ngModelOptions]="{standalone: true}">
                        {{'SCHOOL.ADMIN.TEACHERS.NAMES-SEPARATED' | translate }}
                    </mat-checkbox>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center"
                class="spe_gap">
                <input accept=".xlsx, .xls" id="excelFile" style="visibility:hidden; display: none;" type="file"
                    (change)="loadFile($event)" />

                <button [hidden]="loading" (click)="!loading ? selectFile() : null" class="new_but azul">
                    {{'SCHOOL.ADMIN.TEACHERS.SELECT-FILE' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<img [hidden]="state == 'start' || dataLoaded2"
    style="width: 10em; position: fixed;top: 0;left: 0;bottom: 0;right: 0;margin: auto;"
    src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true)">

<img [hidden]="!loading" style="width: 10em; position: fixed;top: 0;left: 0;bottom: 0;right: 0;margin: auto;"
    src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">