import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, isDevMode } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { environment } from 'environments/environment';
import { of, Subject } from 'rxjs';

@Component({
    selector: 'app-mini-games',
    templateUrl: './mini-games.component.html',
    styleUrls: ['./mini-games.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiniGamesComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    public games: any[] = [
        { name: 'Puzle', folder: 'Jigsaw', enabled: true },
        { name: 'Rompecabezas', folder: 'HeadBreaker', enabled: true },
        { name: 'Laberinto', folder: 'Maze', enabled: true },
        { name: 'Sudoku', folder: 'Sudoku', subGames: true, enabled: true },
        { name: 'Diferencias', folder: 'DifferenceFinder', subGames: true, enabled: true },
        { name: 'Tuberías', folder: 'Pipes', enabled: true },
        { name: 'Tetris', folder: 'Tetris', enabled: true }
    ];
    public game_url: SafeResourceUrl = null;
    public params: any = {};
    public listener: any;
    public subGamesVisible: string = null;
    public subGame: string = null;
    public subGames: string[] = [];

    constructor(
        private _sanitizer: DomSanitizer,
        private _navigationService: NavigationService,
        private _authService: AuthService,
        private _translateService: TranslateService,
        private _http: HttpClient,
        private _detector: ChangeDetectorRef) {
        this._unsubscribeAll = new Subject();
    }
   
    ngOnInit() {        
        if (!this._authService.currentUser) this._navigationService.goLogin();
        this._listenMessages();
        this._navigationService.resetParams();
    }

    ngOnDestroy() {
        window.removeEventListener('message', this.listener);
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    
    public goToGame(gameName: string, subGameName: string = null): void {
        if (environment.production) return;

        this.params = JSON.stringify({
            GAME_URL_SOURCE: environment.baseApi,
            GAME_URL_IMAGE_SOURCE: environment.filesUrl,
            GAME_ID_USER: this._authService.currentUser.Id,
            GAME_LEVEL_USER: this._authService.currentUser.Level,
            GAME_SUB_GAME: subGameName
        });

        this.subGamesVisible = null;
        this.subGame = null;
        this.game_url = this._sanitizer.bypassSecurityTrustResourceUrl(`./assets/games/mini-games/${gameName}/index.html`);
        this._detector.detectChanges();
    }

    public showSubGames(gameFolder: string): void {
        if (environment.production) return;

        this.subGame = null;
        let game = this.games.findIndex(g => g.folder === gameFolder && g.subGames) + 1;

        (game ? this._http.get<string[]>(`${environment.baseApi}/api/Game1/GetGameSubGames?game=${game}`) : of([])).subscribe(subGames => {
            this.subGames = subGames.sort();
            this.subGamesVisible = gameFolder;
            this._detector.detectChanges();
        });        
    }

    private _listenMessages(): void {
        if (environment.production) return;

        this.listener = this._messageEvent.bind(this);
        window.addEventListener('message', this.listener, false);       
    }

    private _messageEvent(e: any) {
        if (['http://localhost:4200', 'https://development.walinwa.net'].indexOf(e.origin) === -1) {
            return;
        }

        let messageRecived = {} as any;

        try {
            messageRecived = JSON.parse(e.data);
        } catch (ex) {
            messageRecived = { message: e.data };
        }

        if (messageRecived.message === 'GameResult') {            
            // Guadardar resultado en la versión real
            alert(this._translateService.instant('STUDENT.MINIGAMES.FINISH-MESSAGE', {gameTime: messageRecived.data.seconds, numFails: messageRecived.data.fails}));

            this.game_url = null;
            this._detector.detectChanges();
        } else if (messageRecived.message === 'GameExit') {            
            this.game_url = null;
            this._detector.detectChanges();
        }
    }
}