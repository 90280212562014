import { UpdateStudent } from './../core/shared/state/models/User/update-student.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AspNetUserLanguages, User } from '../core/shared/state/models/user.model';
import { Observable, ReplaySubject, of } from 'rxjs';
import { GamesAndMyZoneConditions } from 'app/core/shared/state/models/User/games-zone-conditions.model';
import { filter, first, map, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { locale, loadMessages } from "devextreme/localization";
import { NavigationEnd, Router } from '@angular/router';
import { AspNetLanguages } from './languages';

@Injectable()
export class UserService {
    private _fingerPrint: string;
    baseUrl = environment.baseApi + '/api/User';
    playAndMyZoneConditions = new ReplaySubject<GamesAndMyZoneConditions>(1);
    playAndMyZoneConditions$: Observable<GamesAndMyZoneConditions>;
    initialConditions: GamesAndMyZoneConditions = {
        CanPlayGameCondition: false,
        SessionPlayCondition: false,
        DictationPlayCondition: false,
        HearingPlayCondition: false,
        ReadingPlayCondition: false,
        DeductionPlayCondition: false,
        MemoryPlayCondition: false,
        TestPlayCondition: false,
        SessionMyZoneCondition: false,
        DictationMyZoneCondition: false,
        HearingMyZoneCondition: false,
        ReadingMyZoneCondition: false,
        DeductionMyZoneCondition: false,
        MemoryMyZoneCondition: false,
        TestMyZoneCondition: false
    }

    constructor(private http: HttpClient,
                private _translateService: TranslateService,
                private _router: Router) {
    }

    public getUserByToken() {
        return this.http.get(this.baseUrl + '/GetUserByToken').pipe(tap((u: User) => u.AspNetUserLanguages = u.AspNetUserLanguages || ({ UserIdLanguage: 'es', UserStudentsIdLanguage: 'es' } as AspNetUserLanguages)), tap(this.setApplicationLanguage));
    }

    public setApplicationLanguage = (u: User, async: boolean = true) => {
        const userLanguage = ([1, 3].indexOf(Number(u.IdRole)) > -1 ?  u.AspNetUserLanguages.UserStudentsIdLanguage : u.AspNetUserLanguages.UserIdLanguage).toLowerCase();

        const observable = (async ? this._router.events : of(new NavigationEnd(1, '/start', '/end'))).pipe(
            filter(event => event instanceof NavigationEnd),
            take(1)
        );

        observable.subscribe(async _ => {
            this._translateService.use(userLanguage);

            const messages = await import(`devextreme/localization/messages/${userLanguage}.json`);

            loadMessages(messages);
            locale(userLanguage);
        });

        return observable;
    }    

    getSimpleUser(idUser: number) {
        return this.http.get(this.baseUrl + '/simpleUser/' + idUser);
    }

    getPlayAndMyZoneConditionsData(IdUser: number) {
        this.playAndMyZoneConditions = new ReplaySubject<GamesAndMyZoneConditions>(1);
        this.playAndMyZoneConditions$ = this.playAndMyZoneConditions.asObservable();

        this.http.get<GamesAndMyZoneConditions>(`${this.baseUrl}/PlayAndMyZoneConditionsData/${IdUser}`).subscribe(d => {
            this.playAndMyZoneConditions.next(d);
        });
    }
    getPlayAndMyZoneActualConditions() {
        if (!this.playAndMyZoneConditions$) {
            const currentUser = sessionStorage.getItem("currentUser");

            if (currentUser) {
                this.getPlayAndMyZoneConditionsData(JSON.parse(currentUser).Id);
            }
        }
        
        return this.playAndMyZoneConditions$;
    }

    async getPlayMeetAllConditions() {
        return await (this.playAndMyZoneConditions$ || of(this.initialConditions))
        .pipe(
            first(),
            map(conditions => 
            conditions.SessionPlayCondition &&
            conditions.DictationPlayCondition &&
            conditions.HearingPlayCondition &&
            conditions.DeductionPlayCondition &&
            conditions.ReadingPlayCondition &&
            conditions.MemoryPlayCondition &&
            conditions.TestPlayCondition)).toPromise();
    }

    async getMyZoneMeetAllConditions() {
        return await (this.playAndMyZoneConditions$ || of(this.initialConditions))
        .pipe(
            first(),
            map(conditions => 
            conditions.SessionMyZoneCondition == true &&
            conditions.DictationMyZoneCondition == true &&
            conditions.HearingMyZoneCondition &&
            conditions.DeductionMyZoneCondition &&
            conditions.ReadingMyZoneCondition &&
            conditions.MemoryMyZoneCondition == true &&
            conditions.TestMyZoneCondition == true)).toPromise();
    }
    getPasswordToken(idUser) {
        return this.http.get(this.baseUrl + '/password/' + idUser);
    }

    getUserById(idUser) {
        return this.http.get<User>(this.baseUrl + '/idUser/' + idUser);
    }
    getUserHeaderDataById(idUser) {
        return this.http.get<User>(this.baseUrl + '/idUser/getHeaderData/' + idUser);
    }
    getUserCoins(idUser) {
        return this.http.get(this.baseUrl + '/usercoins/' + idUser);
    }

    getStudentsByParentId(idUser, idClass) {
        return this.http.get(this.baseUrl + '/students/idUser/' + idUser + "/idClass/" + idClass);
    }

    GetAuthorizedStudentsByParentId(idUser) {
        return this.http.get(this.baseUrl + '/authStudents/idUser/' + idUser);
    }

    updateUserProfile(user : UpdateStudent) {
        return this.http.post(this.baseUrl + '/updateUserProfile', user);
    }

    UpdateUserBirthdayAndNameAndFamilyname(user) {
        var params = { idUser: user.Id, birthday: user.DateOfBirth, name: user.Name, familyName: user.FamilyName, alias: user.Alias }
        return this.http.post(this.baseUrl + "/updateUserBirthdayAndNameAndFamilyname", params);
    }

    updateUserEmailAndNameAndFamilynameAndAlias(user) {
        var params = { idUser: user.Id, email: user.Email, name: user.Name, familyName: user.FamilyName, alias: user.Alias, IdLanguage: user.AspNetUserLanguages.UserIdLanguage}
        return this.http.post(this.baseUrl + "/updateUserEmailAndNameAndFamilynameAndAlias", params);
    }

    GetStudentMessages(user) {
        return this.http.get(this.baseUrl + "/getStudentMessages/" + user.Id);
    }

    UpdateUserCourseAndCivilization(user) {
        var params = { idUser: user.Id, level: user.Level, idCivilization: user.IdCivilizacion }
        return this.http.post(this.baseUrl + "/updateUserCourseAndCivilization", params);
    }

    UpdateUserTutorial(user) {
        return this.http.post(this.baseUrl + "/updateUserTutorial/" + user.Id, {});
    }

    CreateStudent(user, password) {
        var params = { User: user, Password: password }
        return this.http.post(this.baseUrl + "/addStudent", params);
    }

    RequestResetPassword(username, email) {
        return this.http.get(this.baseUrl + "/username/" + username + "/email/" + email, {});
    }

    ResetPassword(idUser : number, token : string, password : string) {
        var params = { Token: token, NewPassword: password}
        return this.http.post(this.baseUrl + "/iduser/" + idUser, params);
    }

    SetLicenseToUser(idUser, idLicenseType) {
        return this.http.post(this.baseUrl + "/iduser/" + idUser + "/idLicenseType/" + idLicenseType, {});
    }

    SetFreeLicenseToUser(idUser) {
        return this.http.post(this.baseUrl + "/iduser/" + idUser + "/free/", {});
    }

    GetUserPayments(idUser) {
        return this.http.get(this.baseUrl + "/payments/iduser/" + idUser);
    }

    getClassesByIdTutor(idTutor) {
        return this.http.get(this.baseUrl + "/classes/idTutor/" + idTutor);
    }

    getReducedClassesByIdTutor(IdTutor: number) {
        return this.http.get(this.baseUrl + "/ReducedClasses/idTutor/" + IdTutor);
    }

    getClassesFullByIdTutor(idTutor) {
        return this.http.get(this.baseUrl + "/classesFull/idTutor/" + idTutor);
    }

    validateNewUser(username, email, school) {
        return this.http.get(this.baseUrl + "/validate/" + username + "/" + email + "/" + school);
    }

    addAuthorizedUser(idUser, idUserTutor, email, correoConfirmacion) {
        return this.http.post(this.baseUrl + "/authorizedUser/idUser/" + idUser + "/idUserTutor/" + idUserTutor + "/email/" + email + "/correoConfirmacion/" + correoConfirmacion, {});
    }

    viewNewPresents(idUser) {
        return this.http.post(this.baseUrl + "/ViewNewPresents/" + idUser, {});
    }
    viewNewAvatarPresents(idUser) {
        return this.http.post(this.baseUrl + "/ViewNewAvatarPresents/" + idUser, {});
    }

    getAuthorizedUsers(idUser) {
        return this.http.get(this.baseUrl + "/authorizedUser/idUser/" + idUser);
    }

    removeAuthorizedUser(idUser, idUserTutor, email) {
        return this.http.delete(this.baseUrl + "/authorizedUser/idUser/" + idUser + "/idUserTutor/" + idUserTutor + "/email/" + email);
    }

    sendInfoEmail(idUser, body, subject, file) {
        let params: FormData = new FormData();
        params.append("idUser", idUser);
        params.append("body", body);
        params.append("subject", subject);
        if (file) {
            params.append("file", file, file.name);
        }

        let headers = new HttpParams().set('Content-Type', 'multipart/form-data').set('Accept', 'application/json');
        const options = {
            params: headers,
        };
        //var params = { idUser: idUser, body: body, subject: subject, attach: attachment }
        return this.http.post(this.baseUrl + "/sendInfoEmail", params, options);
    }
    sendVerificationCode(code, parentMail, isParticular) {
        let params: FormData = new FormData();
        params.append("code", code);
        params.append("parentMail", parentMail);
        params.append("isParticular", isParticular);
        return this.http.post(this.baseUrl + "/sendVerificationCode", params);
    }

    searchUsers(searchText, num, isAdmin = false) {
        var params = { searchText: searchText, number: num, isAdmin: isAdmin }
        return this.http.post(this.baseUrl + "/searchUsers", params);
    }

    changePassword(IdUser: number, password: string) {
        var params = { IdUser: IdUser, Password: password }
        return this.http.post(this.baseUrl + "/changePassword", params);
    }

    adminResetPassword(idUser, isAdmin) {
        return this.http.post(this.baseUrl + "/adminResetPassword/" + idUser + "/" + isAdmin, {});
    }

    deleteUser(idUser) {
        return this.http.get(this.baseUrl + "/deleteUser/" + idUser);
    }

    getUserLicenseByIdUser(idUser) {
        return this.http.get(this.baseUrl + '/getUserLicense/' + idUser, {});
    }

    addCoinTransaction(idUser, coins) {
        return this.http.get(this.baseUrl + '/addCoinTransaction/' + idUser + '/' + coins);
    }

    cumpleRecibido(idUser) {
        return this.http.get(this.baseUrl + '/cumpleRecibido/' + idUser);
    }

    doSchoolAdmin(idUser) {
        return this.http.get(this.baseUrl + '/doSchoolAdmin/' + idUser);
    }

    undoSchoolAdmin(idUser) {
        return this.http.get(this.baseUrl + '/undoSchoolAdmin/' + idUser);
    }

    addLicenseTypeUser(idUser, meses) {
        return this.http.get(this.baseUrl + '/addLicenseTypeUser/' + idUser + "/" + meses);
    }

    updateLevel(idUser) {
        return this.http.post(this.baseUrl + '/updateLevel/' + idUser, {});
    }

    getCurrentUser() {
        return JSON.parse(sessionStorage.getItem("currentUser"));
    }

    getChildren(idUser) {
        return this.http.get(this.baseUrl + '/students/idTutor/' + idUser);
    }
    getUserPresents(idUser : number){
        return this.http.get(this.baseUrl + '/getUserPresents/' + idUser);
    }
    getChristmasDate() {
        return this.http.get(this.baseUrl + '/getChristmasDate/');
    }

    public canRetrySession(idUser: number): Observable<boolean> {
        return this.http.get<boolean>(`${this.baseUrl}/CanRetrySession/${idUser}`);
    }
    public canSkipWeekendSession(idUser: number): Observable<boolean> {
        return this.http.get<boolean>(`${this.baseUrl}/CanSkipWeekendSession/${idUser}`);
    }
    public forceWeekend(): Observable<boolean> {
        return this.http.get<boolean>(`${this.baseUrl}/ForceWeekend/`);
    }
    getFingerPrint(): string {
        return this._fingerPrint;
    }

    setFingerPrint(fingerPrint: string): void {
        this._fingerPrint = fingerPrint;
    }

    public getIsLanguageAvailable(language: string): Observable<boolean> {
        return this.http.get<boolean>(`${this.baseUrl}/GetIsLanguageAvailable/${language}`);
    }

    public getLanguages(): Observable<AspNetLanguages[]> {
        return this.http.get<AspNetLanguages[]>(`${this.baseUrl}/GetLanguages`);
    }

    public setUserStudentsLanguage(idUser: number, idLanguage: string) {
        return this.http.put<void>(`${this.baseUrl}/SetUserStudentsLanguage/${idUser}/${idLanguage}`, { idUser, idLanguage });
    }
}
