import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SchoolService } from 'app/services/school.service';
import { UserService } from 'app/services/user.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { NavigationService } from 'app/services/navigation.service';
import { AuthService } from 'app/services/auth.service';
import { Observable, Subject } from 'rxjs'
import { switchMap, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AspNetLanguages } from 'app/services/languages';

@Component({
    selector: "app-options",
    templateUrl: "./options.component.html",
    styleUrls: ["./options.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class OptionsComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  private _busy: boolean = false;

  classes;
  selectedClass;
  form;
  public languages$: Observable<AspNetLanguages[]>;

  constructor(
    private schoolService: SchoolService,
    private userService: UserService,
    private navigationService: NavigationService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    this.selectedClass = 0;
    this.form = this.formBuilder.group({
      BlockName: [false],
      BlockAlias: [false],
      BlockContact: [false],
      GiftShopDisabled: [false],
      BlockMessageGift: [false],
      AvatarDisabled: [false],
      Language: [this.authService.currentUser.AspNetUserLanguages.UserStudentsIdLanguage, [Validators.required]],
    });
  }

  ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

  ngOnInit(): void {
    if (!this.authService.check()) return;
    this.languages$ = this.userService.getLanguages();
    this.getClasses();
  }

  getClasses() {
    this.userService.getClassesFullByIdTutor(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        setTimeout(() => { this.selectedClass = parseFloat(this.selectedClass.toString()) }, 100);
        this.classes = result;
        this.classes.sort(function (a, b) {
          return a.Level - b.Level;
        });
        this.changeClass();
      },
      error => {
        this.navigationService.exit();
      });
  }

    sameValues() {
        if (!this.classes) return;
        if (this.classes.length == 0) return;
        let values1 = {
            BlockName: this.classes[0].BlockName,
            BlockAlias: this.classes[0].BlockAlias,
            BlockContact: this.classes[0].BlockContact,
            GiftShopDisabled: this.classes[0].GiftShopDisabled,
            BlockMessageGift: this.classes[0].BlockMessageGift,
            AvatarDisabled: this.classes[0].AvatarDisabled,
        };
        for (let cls of this.classes.filter(c => c.NumStudents >= 1)) {
            let values2 = {
                BlockName: cls.BlockName,
                BlockAlias: cls.BlockAlias,
                BlockContact: cls.BlockContact,
                GiftShopDisabled: cls.GiftShopDisabled,
                BlockMessageGift: cls.BlockMessageGift,
                AvatarDisabled: cls.AvatarDisabled,
            };
            if (JSON.stringify(values1) != JSON.stringify(values2))
                return false;
        }
        return true;
    }

    changeClass() {
        let cls;
        if (this.selectedClass == 0) {
            if (this.sameValues()) {
                cls = this.classes[0];
            } else {
                this.form.patchValue({
                    BlockName: false,
                    BlockAlias: false,
                    BlockContact: false,
                    GiftShopDisabled: false,
                    BlockMessageGift: false,
                    AvatarDisabled: false,
                });
                return;
            }
        } else {
            cls = this.classes.find((c) => c.IdClass == this.selectedClass);
        }
        this.form.patchValue({
            BlockName: cls.BlockName || false,
            BlockAlias: cls.BlockAlias || false,
            BlockContact: cls.BlockContact || false,
            GiftShopDisabled: cls.GiftShopDisabled || false,
            BlockMessageGift: cls.BlockMessageGift || false,
            AvatarDisabled: cls.AvatarDisabled || false,
        });

        const _function = cls.NumStudents >= 1 ? 'enable': 'disable';

        this.form.get('BlockName')[_function]();
        this.form.get('GiftShopDisabled')[_function]();
        this.form.get('BlockMessageGift')[_function]();
    }

  save() {
    this.form.markAllAsTouched();

    if (this.form.valid && !this._busy) {
      this._busy = true;

      this.schoolService.updateClassOptions(
        this.selectedClass,
        this.authService.currentUser.Id,
        this.form.value.BlockName,
        this.form.value.BlockAlias,
        this.form.value.BlockContact,
        this.form.value.GiftShopDisabled,
        this.form.value.BlockMessageGift,
        this.form.value.AvatarDisabled
      )
  .pipe(takeUntil(this._unsubscribeAll), switchMap(() => this.userService.setUserStudentsLanguage(this.authService.currentUser.Id, this.form.value.Language)))
  .subscribe(
        result => {
          this.authService.reloadUser(() => {          
            let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
              disableClose: true
            });

            confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SUCCESS-SAVE-CONFIG-TITLE');
            confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SUCCESS-SAVE-CONFIG-MESSAGE');  
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
            confirmDialog.componentInstance.margin = false;

            confirmDialog.afterClosed()
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(result => {
              this._busy = false;
              confirmDialog = null;
              this.getClasses();
            });
          });
        }, error => {
          let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
          });
          confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.ERROR-SAVE-CONFIG-TITLE');
          confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.ERROR-SAVE-CONFIG-MESSAGE');
          confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
          confirmDialog.componentInstance.margin = false;

          confirmDialog.afterClosed()
  .pipe(takeUntil(this._unsubscribeAll))
  .subscribe(result => {
            confirmDialog = null;
            this._busy = false;
          });
        }
      )
    }
  }

}
