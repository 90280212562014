import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LicenseService } from 'app/services/license.service';
import { NavigationService } from 'app/services/navigation.service';
import { UserService } from 'app/services/user.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LicenseComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  currentUser;
  calling = false;
  dataLoaded = false;
  licenses;
  mytimeout = null;
  user = null;

  constructor(
    private navigationService: NavigationService,
    private licenseService: LicenseService,
    private userService: UserService,
    private matDialog: MatDialog,
    private _translateService: TranslateService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy(): void
   {
       // Unsubscribe from all subscriptions
       this._unsubscribeAll.next();
       this._unsubscribeAll.complete();
   }

  ngOnInit() {
    if (!sessionStorage.getItem("currentUser")) return this.navigationService.exit();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (this.navigationService.params.student) this.user = this.navigationService.params.student;
    if (!this.currentUser || !this.user) return this.navigationService.exit();
    this.navigationService.enableBackButton(true, this.back.bind(this));
    this.calling = true;
    this.loadLicenses();
    this.dataLoaded = true;
    this.calling = false;
  }

  back() {
    this.navigationService.go("/school/parentStudents")
  }

  errorCallbackLoadStudent(error) {
    this.navigationService.goLogin();
  };
  successCallbackUpdateLicense(response) {
    this.navigationService.go("/school/myAccount")
  };

  loadLicenses() {
    this.licenseService.getAvailableLicenses(this.user.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successCallbackGetLicenses.bind(this), this.errorCallbackkGetLicenses.bind(this));
  }

    successCallbackGetLicenses(response) {
        this.licenses = response;
        this.licenses = this.licenses.sort(function (a, b) {
            return a.Duration - b.Duration;
        });
        for (var i = 0; i < this.licenses.length; i++) {
            this.licenses[i].active = true;
        }
    };

  errorCallbackUpdateLicense(error) {
    this.navigationService.go("/school/myAccount")
  };

    errorCallbackkGetLicenses(error) {
        this.navigationService.goLogin();
    }

  submitForm(index) {
    if (!this.licenses[index].Active)
      return;
    if(this.calling)
      return;
    this.calling = true;
    this.userService.SetLicenseToUser(this.user.Id, this.licenses[index].IdLicenseType)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successCallbackUpdateLicense.bind(this), this.errorCallbackUpdateLicense.bind(this));
  };

  cancel() {
    this.back();
  }

}
