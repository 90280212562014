import { DomSanitizer } from '@angular/platform-browser';
import { NavigationService } from './../../../services/navigation.service';
import { WaliLetrasResult } from './../../../core/shared/state/models/wali-letras-result';
import { WaliLetrasInfo } from './../../../core/shared/state/models/wali-letras-info.model';
import { WaliLetrasPalabras } from './../../../core/shared/state/models/wali-letras-palabras.model';
import { AuthService } from 'app/services/auth.service';
import { WaliLetras, WalinwosPerWord } from './../../../core/shared/state/models/wali-letras.model';
import { WaliLetrasService } from './../../../services/wali-letras.service';
import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { AppService } from 'app/app.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { SharedService } from 'app/core/shared/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'wali-letras',
    templateUrl: './wali-letras.component.html',
    styleUrls: ['./wali-letras.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WaliLetrasComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;
    state = null;
    letras = [];
    palabras: WaliLetrasPalabras[] = [];
    input;
    blur: boolean;
    indexCard: number = 0;
    position: number;
    isDelete: boolean;
    regex = new RegExp(/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜçÇ-]*$/);
    lastLength = 0;
    colors = ['naranja', 'turquesa', 'verde', 'azul', 'morado', 'amarillo', 'rosa', 'turquesa-2'];
    viewInfo: boolean;
    StudentsResult: WaliLetrasResult[] = []
    review = false;
    walinwos = 0;
    tab = 1;
    spark = false;
    ismodal = false;
    userResponses: WaliLetrasInfo[] = []
    loading = false;
    mobileDetect;
    started = false;
    finish = false;
    confirmDialog: any;
    walinwosPerWord: WalinwosPerWord = null;
    messages: any = [];
    finished = false;
    baseUrl = "WaliLetras/"

    @HostListener('window:resize', ['$event'])
    onResize(event) {

        var viewportHeight = window.innerHeight;
        var visualViewportHeight = window.visualViewport.height;
        var keyboardHeight = viewportHeight - visualViewportHeight;

        var iPadw = window.innerWidth;
        /*
        if( iPadw < 1300 && iPadw > 959){
            setTimeout(() => {
                this.handleResize();

                if(keyboardHeight < 100){
                    this.returnHeightContainer();
                    // console.log("B Keyboard BB: " + keyboardHeight); 
                }
            }, 750);
        }
        */
        if( iPadw <= 959){
            setTimeout(() => {
                this.handleResizeMob();

                if(keyboardHeight < 100){
                    this.returnHeightContainerMob();
                    // console.log("B Keyboard: " + keyboardHeight); 
                }
            }, 750);
        }
    }

    @HostListener('window:popstate', ['$event'])
    onPopStateHandler(event: any) {
        console.log(event.target.location)
        if (event.target.location.href.includes("student")) {
            this.close()
        }
    }
    @HostListener('window:pagehide', ['$event'])
    onPageHideHandler() {
        console.log();
        if (this.isIos()) {
            this.close()
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    onBeforeUnloadHandler() {
        console.log();
        if (!this.isIos()) {
            this.close()
        }
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        let key = event.key;
        if (event.key == "Unidentified") return;
        if (event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40 || event.keyCode == 18 || event.keyCode == 19) event.preventDefault();
        if (event.key == "Enter" && !this.ismodal) {
            this.verificarRespuesta()

        } else {
            this.ismodal = false;
        }
    }
    @HostListener('document:focus', ['$event'])
    onfocus(event) {
        // window.screen.width <= 768 ? true : false;
        console.log(event);
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions

        console.log("entro")
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.waliLetrasService.resetVariables()
    }

    constructor(public waliLetrasService: WaliLetrasService,
        public authService: AuthService,
        public navService: NavigationService,
        private _matDialog: MatDialog,
        private appService: AppService,
        public sharedService: SharedService,
        private sanitizer: DomSanitizer,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();

    }
    isIos() {
        return navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
    }
    isDesktop() {
        return !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || this.mobileDetect.IsAndroidTablet || this.mobileDetect.IsIPad);
    }

    ngOnInit(): void {
        this.waliLetrasService.has_finished.pipe(takeUntil(this._unsubscribeAll)).subscribe((finished) => {
            if (finished) {
                this.finalizar();
            }
        })
        this.blur = false;
        this.mobileDetect = this.appService.mobileDetect();
        this.loading = true;
        this.waliLetrasService.isFinished(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                this.loading = false;
                this.isFinished(result);
            })

    }
    isFinished(result: any) {
        if (result.IsFinished && !result.Date) {
            this.review = true;
            this.userResponses = result.UserResponses;
            this.waliLetrasService.changeButton(true);
            this.start()
        }
        else {
            if (result.UserResponses) {
                this.userResponses = result.UserResponses;
            }
            // result trae las ultimas respuestas del usuario
            // si la date tiene valor significa que el usuario tiene empezada la actividad
            // se tiene que setear la variable startime de waliletrasService al valor del momento en que salio(waliletrasInfo.Date) para que el timer se acomode solo
            if (result.Date) {
                let date = moment(result.Date).add(moment().utcOffset(), 'minutes');
                if (this.userResponses.length > 0) {
                    let lastUserResponse = this.userResponses[this.userResponses.length - 1].Date;
                    date = moment(lastUserResponse).add(moment().utcOffset(), 'minutes');
                }
                let today = moment();
                var newDateObj = date.add(1, 'minutes');
                console.log(newDateObj > today)
                if (newDateObj > today) {
                    if (!this.waliLetrasService.timer.startTime) {
                        let fecha = new Date(result.Date)
                        let zonaHorariaActual = fecha.getTimezoneOffset();
                        let diferenciaHorariaEnMs = zonaHorariaActual * 60 * 1000;
                        let fechaSinZonaHoraria = new Date(fecha.getTime() - diferenciaHorariaEnMs);
                        this.waliLetrasService.timer.startTime = fechaSinZonaHoraria.getTime()
                    }
                    this.review = false;
                    this.started = true;
                    this.start()
                } else {
                    this.finish = true;
                    this.review = true;
                    this.start()
                    this.waliLetrasService.changeButton(true)
                }
            } else {
                this.state = "pre"
            }
        }
    }
    change() {
        let key = this.input.charAt(this.input.length - 1);
        if (this.input.length < this.lastLength) {
            key = "Backspace";
        }
        this.lastLength = this.input.length;
        this.countInsertedCharacters();
        if (this.state == "exerc" && this.palabras[this.indexCard].answered == null) {
            this.isDelete ? this.setCaracterPosition(this.indexCard, this.countInsertedCharacters(), key) : this.setLetra(key);
            this.setTitilando(key);
        }
    }
    countInsertedCharacters() {
        let sum = 0;
        this.palabras[this.indexCard].caracteres.forEach(x => {
            if (x.letraEscrita) {
                sum++;
            }
        })
        console.log(sum)
        return sum;
    }
    close() {
        this.waliLetrasService.changeButton(true)
        this.waliLetrasService.show = false;
    }
    back() {
        this.waliLetrasService.show = false;
    }
    show(caracter) {
        return "&nbsp";
    }
    start() {
        this.waliLetrasService.getPalabras(moment().week(), this.authService.currentUser.Level)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: WaliLetras) => {
                this.walinwosPerWord = result.WalinwosPerWord
                this.palabras = JSON.parse(result.Definition).palabras;
                this.letras = JSON.parse(result.Definition).letras;
                this.palabras.forEach(x => {
                    x.finished = null
                    x.answered = null
                    x.caracteres = []
                    for (let i = 0; i < this.letras.length; i++) {
                        x.caracteres.push({
                            titila: false,
                            letraEscrita: null,
                            caracter: '',
                        })
                    }
                })
                this.palabras = this.palabras.filter(x => x.pal.length > 3)
                if (this.finish) {
                    this.finalizar(true)
                    return;
                }
                if (this.review) {
                    this.loadReview()
                } else if (this.started || (this.userResponses != null && this.userResponses?.length > 0)) {
                    this.continue();
                } else {
                    let resultado: WaliLetrasResult = {
                        IdUser: this.authService.currentUser.Id,
                        week: moment().week(),
                        Score: 0,
                        Finished: false
                    }
                    this.waliLetrasService.start(resultado)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(() => {
                            this.waliLetrasService.startTimer();
                            this.state = "exerc";
                            setTimeout(() => {
                                this.selectedCard(0);
                            }, 100);
                        });

                }

            })
    }
    loadReview() {
        this.waliLetrasService.pauseTimer();
        this.loadUserAnswers();
        this.waliLetrasService.review(this.authService.currentUser.Id, this.authService.currentUser.IdSchool)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: WaliLetrasResult[]) => {
                this.StudentsResult = result.sort((a, b) => {
                    if (a.Score > b.Score) {
                        return -1;
                    } else if (a.Score < b.Score) {
                        return 1
                    }
                    else {
                        return 0
                    }
                });
                this.walinwos = this.StudentsResult.find(x => x.IdUser == this.authService.currentUser.Id).Score;
                this.state = "review"
            })
    }
    continue() {
        this.state = "exerc"
        this.loadUserAnswers()
        this.walinwos = this.calcScore();
        if (this.userResponses.length > 0) {
            this.selectedCard(this.userResponses.length);
        }
        else {
            this.selectedCard(0)
        }
        this.waliLetrasService.startTimer();
    }
    loadUserAnswers() {
        let borrado;
        let initalIndex;
        this.userResponses.forEach((respuesta, index) => {
            //guardamos el indice de la palabra que corresponde a la respondida
            initalIndex = this.palabras.findIndex(x => x.pal == respuesta.Word)
            //reemplazamos la primer palabra con la respondida y guardamos la borrada
            borrado = this.palabras.splice(index, 1, this.palabras[initalIndex])
            // movemos la borrada a la posicion de la respondida
            this.palabras.splice(initalIndex, 1, borrado[0])
            this.palabras[index].caracteres.forEach((caracter, caractIndex) => {
                if (respuesta.Word[caractIndex] === undefined) return;
                caracter.letraEscrita = respuesta.Word[caractIndex];
            })
            this.palabras[index].answered = true;
            this.palabras[index].finished = true;
        })
    }
    setFocus(bool = false) {
        var iPadw = window.innerWidth;
        if (bool && this.state == 'exerc') {
            document.getElementById("dictation-input").focus()
            if (this.mobileDetect.IsAndroidTablet || this.mobileDetect.IsIPad) {
                document.getElementById("palabras-container").classList.add('palabras-container-kb');
                document.getElementById("cubo-container").classList.add('cubo-container-kb');
                document.getElementById("hrader-kb").style.marginTop = "-34.5em";
                this.letras.forEach((x, i) => {
                    document.getElementById("cubo" + i).classList.add('cubo-kb');
                })

            }
            else if(iPadw <= 959){
                setTimeout(() => {
                    this.handleResizeMob();
                }, 750);
            }
        }
        else if (this.state == 'exerc') {
            document.getElementById("dictation-input").blur()
            if (this.mobileDetect.IsAndroidTablet || this.mobileDetect.IsIPad) {
                document.getElementById("palabras-container").classList.remove('palabras-container-kb');
                document.getElementById("cubo-container").classList.remove('cubo-container-kb');
                document.getElementById("hrader-kb").style.marginTop = "0";
                this.letras.forEach((x, i) => {
                    document.getElementById("cubo" + i).classList.remove('cubo-kb');
                })
            }
            else if( iPadw <= 959){
                this.returnHeightContainerMob();
            }


        }
    }

    onBlur() {
        //console.log('oaushdlajbsiuasgvcuv');

    }


    private setLetra(key: string) {
        if (!(this.regex.test(key)) || key.length > 1) {
            if (key.includes('Backspace')) {
                //Funcion para borrar un caracter
                for (let i = this.palabras[this.indexCard].caracteres.length - 1; i >= 0; i--) {
                    if (this.palabras[this.indexCard].caracteres[i].letraEscrita == null && this.palabras[this.indexCard].caracteres[i].titila) {
                        
                        this.palabras[this.indexCard].caracteres[i].titila = false;
                        if (i - 1 < 0) {
                            // seteamos titila en true en la posicion anteior
                            this.palabras[this.indexCard].caracteres[0].titila = true;
                            // eliminamos letraEscrita en la posicion anteior
                            this.palabras[this.indexCard].caracteres[0].letraEscrita = null;
                        }
                        else {
                            // seteamos titila en true en la posicion anteior
                            this.palabras[this.indexCard].caracteres[i - 1].titila = true;
                            // eliminamos letraEscrita en la posicion anteior
                            this.palabras[this.indexCard].caracteres[i - 1].letraEscrita = null;
                        }
                        // agregamos en el dom el espacio en blanco
                        document.getElementById(`letter_${this.indexCard}_${i - 1}`).innerHTML = '&nbsp';
                        return;
                    } else if (this.palabras[this.indexCard].caracteres[i].letraEscrita && !this.palabras[this.indexCard].caracteres[i].titila) {
                        //quitamos el cursor en la posicion actual
                        this.palabras[this.indexCard].caracteres[i].titila = true;
                        // seteamos titila en false en la posicion anteior
                        this.palabras[this.indexCard].caracteres[i - 1].titila = false;
                        // eliminamos letraEscrita en la posicion actual ya que es el ultimo caracter escrito
                        this.palabras[this.indexCard].caracteres[i].letraEscrita = null;
                        // agregamos en el dom el espacio en blanco en la ultima posicion
                        document.getElementById(`letter_${this.indexCard}_${i}`).innerHTML = '&nbsp';
                        return;
                    }
                }
            }
            return;
        }

        if (this.indexCard !== null) {
            this.palabras[this.indexCard].caracteres.forEach((caract, index) => {
                if (caract.titila && !caract.letraEscrita) {

                    if (this.regex.test(key)) {
                            caract.letraEscrita = key;
                            document.getElementById(`letter_${this.indexCard}_${index}`).innerHTML = key;
                            caract.titila = false;
                    }
                    if (index === this.palabras[this.indexCard].caracteres.length - 1) {
                        this.verificarRespuesta();

                    }
                    return;
                }
            });
        }
    }

    setCaracterPosition(index: number, j: number, key: string) {
        if (!(this.regex.test(key)) || key.length > 1) {
            if (key.includes('Backspace')) {
                //Funcion para borrar un caracter
                for (let i = j; i >= 0; i--) {
                    if (this.palabras[index].caracteres[i].letraEscrita == null && this.palabras[index].caracteres[i].titila) {
                        //quitamos el cursor en la posicion actual
                        this.palabras[index].caracteres[i].titila = false;

                        if (i - 1 < 0) {
                            // seteamos titila en true en la posicion anteior
                            this.palabras[index].caracteres[0].titila = true;
                            // eliminamos letraEscrita en la posicion anteior
                            this.palabras[index].caracteres[0].letraEscrita = null;
                        }
                        else {
                            // seteamos titila en true en la posicion anteior
                            this.palabras[index].caracteres[i - 1].titila = true;
                            // eliminamos letraEscrita en la posicion anteior
                            this.palabras[index].caracteres[i - 1].letraEscrita = null;
                        }
                        // agregamos en el dom el espacio en blanco
                        document.getElementById(`letter_${index}_${i - 1}`).innerHTML = '&nbsp';
                        return;
                    } else if (this.palabras[index].caracteres[i].letraEscrita && this.palabras[index].caracteres[i].titila) {
                        //quitamos el cursor en la posicion actual
                        this.palabras[index].caracteres[i].titila = true;
                        // seteamos titila en false en la posicion anteior
                        if (i - 1 < 0) {
                            this.palabras[index].caracteres[0].titila = false;
                        }
                        else {
                            this.palabras[index].caracteres[i - 1].titila = false;
                        }
                        // eliminamos letraEscrita en la posicion actual ya que es el ultimo caracter escrito
                        this.palabras[index].caracteres[i].letraEscrita = null;
                        // agregamos en el dom el espacio en blanco en la ultima posicion
                        document.getElementById(`letter_${index}_${i}`).innerHTML = '&nbsp';
                        return;
                    }
                }
            }
            else if (key.includes("Enter")) {
                this.verificarRespuesta()
            }
            return;
        }
        if (this.indexCard !== null) {
            this.palabras[this.indexCard].caracteres.forEach((caract, index) => {
                if (caract.titila && !caract.letraEscrita) {
                    if (this.regex.test(key)) {
                        caract.letraEscrita = key;
                        document.getElementById(`letter_${this.indexCard}_${index}`).innerHTML = key;
                        caract.titila = false;
                    }
                    if (index === j) {
                        this.isDelete = false;
                    }
                    return;
                }
            });
        }
    }
    //ok
    public selectedCard(i: number) {
        this.checkPosition(i);
        this.indexCard = i;
        this.setFocus(true);
    }
    //ok
    checkPosition(position: number) {
        let bandera = false
        this.isDelete = false;
        this.palabras[position].caracteres.forEach((caract, index) => {
            if (!caract.letraEscrita && !bandera) {
                this.palabras[position].caracteres[index].titila = true;
                bandera = true;
                this.deleteCaracter(position, index);
            }
        })
    }
    //ok
    deleteCaracter(i: number, j: number) {
        if(i > 0 && this.palabras[i - 1].answered == null) {
            this.verificarRespuesta()
            return;
        }
        this.indexCard = i;
        this.position = j;
        this.isDelete = true;
        if (this.position == 0) {
            this.palabras[this.indexCard].caracteres.forEach((caract, index) => {
                if (index == j && this.indexCard == i) {
                    caract.titila = true
                }
                else {
                    caract.titila = false;
                }
            })
        }
        else {
            this.palabras[this.indexCard].caracteres.forEach((caract, index) => {
                if (caract.letraEscrita !== undefined || this.iterator(this.indexCard, this.position)) {
                    if (index == j && this.indexCard == i) {
                        caract.titila = true
                    }
                    else {
                        caract.titila = false;
                    }
                }
            })
        }
    }

    //ok
    iterator(indexCard, position) {
        let flag = false;
        for (let index = position; index < this.palabras[indexCard].caracteres.length; index++) {
            if (this.palabras[indexCard].caracteres[index].letraEscrita != undefined) {
                return flag = true;
            }

        }
        return flag;
    }
    //ok
    private setTitilando(key = null) {
        if (key !== null && (!(this.regex.test(key)) || key.length > 1)) {
            return;
        }
        let bandera = false;
        if (this.indexCard !== null) {
            this.palabras[this.indexCard].caracteres.forEach((caract, index) => {
                if (!caract.letraEscrita && !caract.titila && !bandera) {
                    caract.titila = true;
                    bandera = true;
                    return;
                }
            });
        }
    }
    getPosition(caracter, caracteres) {
        return caracteres.indexOf(caracter)
    }
    verificarRespuesta() {
        var palabra = ""
        this.palabras[this.indexCard].caracteres.forEach(x => {
            if (x.letraEscrita) {
                palabra += x.letraEscrita;
            }

        })
        if (palabra.length < 4 && palabra.length > 0) {
            this.ismodal = true;
            var message = [];
            message[1] = this._translateService.instant('STUDENT.WALILETRAS.MODAL-ERROR-MESSAGE');


            let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });
            confirmDialog.componentInstance.title = message[0];
            confirmDialog.componentInstance.message1 = message[1];
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
            confirmDialog.componentInstance.theme = "white";
            confirmDialog.componentInstance.margin = false;
            confirmDialog.afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(result => {
                    confirmDialog = null;
                    this.setFocus(true)
                });
            return;
        }
        let index = 0;
        let bandera = false;
        let indexScroll = 10;
        if (this.mobileDetect.IsAndroidTablet || this.mobileDetect.IsIPad) {
            indexScroll = 6;
        }
        if (this.appService.isMobile()) {
            indexScroll = 0;
        }
        this.palabras.forEach((x, i) => {
            if (x.pal == palabra && x.finished != true) {
                bandera = true;
                x.finished = true;
                index = i

                if (this.indexCard >= indexScroll) {
                    let ele2 = document.getElementById("box-scrollable");
                    const x = ele2.scrollHeight / this.palabras.length;
                    ele2.scrollTop += x;
                }
                return
            }
        })
        if (bandera) {
            if (this.palabras[index].pal.length >= 7) {
                this.spark = true;
                setTimeout(() => {
                    this.spark = false;
                }, 5000);
            }
            this.palabras[this.indexCard].answered = true;
            this.walinwos += this.calcScore(index);
            this.checkPosition(this.indexCard + 1);
            this.waliLetrasService.addAnswer({
                IdUser: this.authService.currentUser.Id,
                Week: moment().week(),
                Word: this.palabras[index].pal,
                Score: this.calcScore(index)
            })
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe()
        }
        else {
            this.palabras[this.indexCard].answered = false;
            setTimeout(() => {
                this.palabras[this.indexCard].answered = null;
            }, 1000);
        }
    }
    checkLetra(key) {
        let found = false;
        this.letras.forEach(x => {
            if (key == x) {
                found = true;
                return;
            }
        })
        return found;
    }
    getImg(i) {
        return this.colors[i]
    }
    finalizar(loadReview = false) {
        if (loadReview) {
            this.loadUserAnswers()
        }
        let result: WaliLetrasResult =
        {
            IdUser: this.authService.currentUser.Id,
            week: moment().week(),
            Score: this.calcScore(),
            Finished: true,
            Date: moment()
        }

        this.waliLetrasService.finish(result)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.waliLetrasService.changeButton(true)
                this.waliLetrasService.timerEnd = true;
                if (loadReview) {
                    this.loadMessage();
                }
                else {
                    this.messages.push("El tiempo ha finalizado.")
                    this.finished = true;
                }  
            });


    }
    loadMessage() {
        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        this.confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.WALILETRAS.WALILETRAS');
        this.confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.WALILETRAS.MODAL-NO-TIME-MESSAGE')
        this.confirmDialog.componentInstance.space = true;
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.options = [
            {
                text: this._translateService.instant('ACCEPT'),
                callback: () => {
                    this.waliLetrasService.show = false;
                }
            }
        ];
        this.confirmDialog.afterClosed().subscribe(result => {
            result()
            this.confirmDialog = null;
        });
    }
    showInfo() {
        this.viewInfo = !this.viewInfo;
        if (this.viewInfo) {
            setTimeout(function () {
                document.body.addEventListener("click", bodyListener.bind(this), true);
            }, 100)
        } else {
            document.body.removeEventListener("click", bodyListener.bind(this), true);
        }
        function bodyListener() {
            this.viewInfo = false;
        }
    }

    calcScore(index = null) {
        let score = 0;
        if (index != null) {
            switch (this.palabras[index].pal.length) {
                case 4:
                    score += this.walinwosPerWord.Fourletters;
                    break;
                case 5:
                    score += this.walinwosPerWord.Fiveletters;
                    break;
                case 6:
                    score += this.walinwosPerWord.Sixletters;
                    break;
                case 7:
                    if (this.authService.currentUser.Level >= 8 && this.authService.currentUser.Level <= 11) {
                        score += this.walinwosPerWord.SevenlettersLowLevel;
                    } else {
                        score += this.walinwosPerWord.Sevenletters
                    }
                    break;
                case 8:
                    score += this.walinwosPerWord.EightLetters;
                    break;
            }
        } else {
            this.palabras.forEach(x => {
                if (x.finished) {
                    switch (x.pal.length) {
                        case 4:
                            score += this.walinwosPerWord.Fourletters;
                            break;
                        case 5:
                            score += this.walinwosPerWord.Fiveletters;
                            break;
                        case 6:
                            score += this.walinwosPerWord.Sixletters;
                            break;
                        case 7:
                            if (this.authService.currentUser.Level >= 8 && this.authService.currentUser.Level <= 11) {  
                                score += this.walinwosPerWord.SevenlettersLowLevel;
                            } else {
                                score += this.walinwosPerWord.Sevenletters
                            }
                            break;
                        case 8:
                            score += this.walinwosPerWord.EightLetters;
                            break;
                    }
                }
            })
        }
        return score;

    }

    get AulaResult() {
        return this.StudentsResult.filter(x => x.IdClass == this.authService.currentUser.IdClass);
    }

    getPalabra(i) {
        let finished_words = this.palabras.filter(x => x.finished);
        let palabra = ""
        if (i != finished_words.length - 1) {
            palabra = this.palabras[i].pal + ","
        } else {
            palabra = this.palabras[i].pal + "."
        }
        return palabra
    }
    showMessage() {
        if (this.review) {
            this.close()
        }
        this.waliLetrasService.pauseTimer()
        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        this.confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.WALILETRAS.WALILETRAS');
        this.confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.WALILETRAS.MODAL-WARNING-MESSAGE');
        this.confirmDialog.componentInstance.message2 = this._translateService.instant('STUDENT.WALILETRAS.MODAL-WARNING-MESSAGE2');
        this.confirmDialog.componentInstance.space = true;
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.options = [{
            text: this._translateService.instant('ACCEPT'),
            callback: () => {
                this.saveCurrentWalinwos()
            }
        }, {
            text: this._translateService.instant('CANCEL'),
            callback: () => {
                this.waliLetrasService.restartTimer()
                this.setFocus(true)
            }
        }];
        this.confirmDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                result();
                this.confirmDialog = null;
            });
    }


    saveCurrentWalinwos() {
        let result: WaliLetrasResult =
        {
            IdUser: this.authService.currentUser.Id,
            week: moment().week(),
            Score: this.calcScore(),
            Finished: false,
            Date: moment(),
        }
        this.waliLetrasService.finish(result)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.close();
                this.waliLetrasService.restartTimer()

            });
    }

    handleResizeMob(): void {

        var viewportHeight = window.innerHeight;
        var visualViewportHeight = window.visualViewport.height;
        var keyboardHeight = viewportHeight - visualViewportHeight;

        if (keyboardHeight > 100) {

            let containerMidle = document.getElementById("waliletras-main");
            // let firstHeight = containerMidle.offsetHeight;
            let windowHeight = window.innerHeight;
            let secondHeight = windowHeight - keyboardHeight;

            containerMidle.style.maxHeight = secondHeight + "px";
            console.log("A Keyboard:", keyboardHeight);
            this.ElelemtSetFocuMob();
        }
    }

    ElelemtSetFocuMob(): void {
        let elementScroll = document.querySelector('.titila') as HTMLElement;
        let elementScrollid = elementScroll.id;

        let elementTopResource = document.getElementById(elementScrollid);
        let elemTop = elementTopResource.offsetTop;
        
        let containerMidle = document.getElementById("waliletras-main");
        let relativeTop = (elemTop <= 190) ? 0 : elemTop;

        containerMidle.scrollTo({top: relativeTop});
    }

    returnHeightContainerMob(): void {
        const containerMidle = document.getElementById("waliletras-main");
            containerMidle.style.maxHeight = "none";
    }

    getImgUrl(imgName: string, baseDirectory = false) {
        if (baseDirectory) {
            return "https://walinwa.blob.core.windows.net/images/" + imgName+ "?ngsw-bypass=true";
        }
        return "https://walinwa.blob.core.windows.net/images/" + this.baseUrl + imgName+ "?ngsw-bypass=true";
    }
}
