<div class="walinwa-box-modal-overlay" style="align-items: center;"
    *ngIf="test && !review || review && state == 'reviewTest'" [ngStyle.xs]="mobile ? 'height: 100%; min-width: 100%;' : ''">
    <div class="walinwa-box-modal" style="margin-top: 0; border-radius: 1.5em; padding: 50px 0 0 50px" [ngStyle.xs]="mobile ? 'height: 100%; min-width: 100%;' : ''">
        <div style="width: 100%; height: 100%; display: flex; flex-direction: column;" [hidden]="loading">
            <div class="header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem">
                <div class="" fxFlex="6rem" *ngIf="!mobile">
                    <img class="img" src="https://walinwa.blob.core.windows.net/images/personalized-test.svg?ngsw-bypass=true" fxFlex="5em"
                        style="filter: brightness(900%);">
                </div>
                <div style="font-size: 120%;" fxFlex="auto" fxFlex.xs="200px" fxLayout="column wrap"
                    fxLayoutAlign="space-between start">
                    <div *ngIf="!review">{{'STUDENT.PERSONALIZED-TEST.LABEL' | translate}}</div>
                    <div *ngIf="review">{{'STUDENT.PERSONALIZED-TEST.LABEL' | translate}} {{ visualize ? test.Name : ''}}</div>
                    <div *ngIf="!visualize"> {{review ? getDate() : getFecha() }}</div>

                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
                    <div class="text-center notaTop">
                        <span class="nota"
                            *ngIf="review && !visualize">{{'STUDENT.PERSONALIZED-TEST.MARK-TELL-TEXT' | translate}}<br><span >{{ (teacherReview ? this.Score : getScore()) }}</span>
                        </span>
                    </div>
                    <div class="" fxFlex="2.5rem">
                        <img class="pointer" (click)="close()" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true" fxFlex="2.5em"
                            style="filter: brightness(900%);">
                    </div>
                </div>
            </div>
            <div class="content" ngStyle.xs="padding: calc(var(--font-size) * 2) calc(var(--font-size) * 1);">
                <form *ngIf="!review" [formGroup]="questionsForm"
                    class="walinwa-box-modal-container d-flex flex-column scrollable questions-box"
                    [ngStyle.xs]="test.TestFormat == 1 ? 'height: auto;' : 'height: 75%;'">
                    <div formArrayName="Questions" *ngIf="test.TestFormat != 1">
                        <div class="reading-text-content"
                            *ngFor="let question of Questions().controls; let questionIndex = index">
                            <div [formGroupName]="questionIndex">
                                <div class="reading-text-question">
                                    <div class="question-name" [innerHtml]="test.TestQuestion[questionIndex].Question | replaceTags: questionIndex + 1">
                                    </div>
                                    <div formArrayName="TestAnswer">
                                        <fieldset>
                                            <div
                                                class="reading-text-answers reading-text-answers-student" style="line-height: 0.1em;"
                                                *ngFor="let answer of QuestionAnswers(questionIndex).controls; let answerIndex = index">
                                                <div [formGroupName]="answerIndex">
                                                    <label
                                                        [ngClass]="{ isCorrect: correct != null && answer.IsCorrect }"
                                                        class="reading-text-answer">
                                                        <mat-checkbox style="font-weight: bold; width: 3em !important"
                                                            (change)="changeCorrectAnswer(questionIndex, answerIndex )"
                                                            [checked]="answer.value.IsCorrect">
                                                        </mat-checkbox>
                                                        <div style="width: 100%;"
                                                            [innerHtml]="test.TestQuestion[questionIndex].TestAnswer[answerIndex].Answer | replaceTags"></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="test.TestFormat == 1" formArrayName="Questions">
                        <div class="reading-text-content">
                            <div [formGroupName]="questionIndex">
                                <div class="reading-text-question">
                                    <div class="question-name" [innerHtml]="test.TestQuestion[questionIndex].Question | replaceTags: questionIndex + 1"></div>
                                    <div formArrayName="TestAnswer">
                                        <fieldset>
                                            <div class="reading-text-answers reading-text-answers-student" style="line-height: 0.1em;"
                                                *ngFor="let answer of QuestionAnswers(questionIndex).controls; let answerIndex = index">
                                                <div [formGroupName]="answerIndex">
                                                    <label
                                                        [ngClass]="{ isCorrect: correct != null && answer.IsCorrect }"
                                                        class="reading-text-answer">
                                                        <mat-checkbox style="font-weight: bold; width: 3em !important"
                                                            (change)="changeCorrectAnswer(questionIndex, answerIndex )"
                                                            [checked]="answer.value.IsCorrect">
                                                        </mat-checkbox>
                                                        <div style="width: 100%;"
                                                            [innerHtml]="test.TestQuestion[questionIndex].TestAnswer[answerIndex].Answer | replaceTags"></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="walinwa-box-modal-container d-flex flex-column scrollable questions-box"
                    *ngIf="review && state == 'reviewTest'" [ngStyle.xs]="test.TestFormat == 1 && visualize ? 'height: auto;' : 'height: 75%;'">
                    <ng-container *ngIf="!visualize || visualize && test.TestFormat != 1">
                        <div class="reading-text-content" style="height: auto;"
                        *ngFor="let question of test.TestQuestion; let questionIndex = index">
                        <div class="reading-text-question">
                            <div class="question-name" [innerHtml]="question.Question | replaceTags: questionIndex + 1">
                            </div>

                            <fieldset>
                                <div class="reading-text-answers"
                                    *ngFor="let answer of question.TestAnswer; let answerIndex = index">
                                    <div [ngClass]="{ 'ok': answer.IsCorrect, 'ko': answer.userAnswer && !answer.IsCorrect, 'not-answered': answer.IsCorrect && !answer.userAnswer && naranja(questionIndex) }"
                                        class="reading_text_review_question_answer">
                                        <div class="reading_text_review_question_answer_check" fxFlex="2rem" style="align-items: center; display: flex;">
                                            <i *ngIf="answer.IsCorrect" class="fas fa-check"></i>
                                        </div>

                                        <div class="reading_text_review_question_answer_text" [innerHtml]="answer.Answer | replaceTags">
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    </ng-container>
                    <ng-container *ngIf="test.TestFormat == 1 && visualize">
                            <div class="reading-text-content">
                                <div>
                                    <div class="reading-text-question">
                                        <div class="question-name" [innerHtml]="test.TestQuestion[questionIndex].Question | replaceTags: questionIndex + 1"></div>
                                        <div>
                                            <fieldset>
                                                <div class="reading-text-answers"
                                                    *ngFor="let answer of test.TestQuestion[questionIndex].TestAnswer; let answerIndex = index">
                                                    <div [ngClass]="{ 'ok': answer.IsCorrect, 'ko': answer.userAnswer && !answer.IsCorrect, 'not-answered': answer.IsCorrect && !answer.userAnswer && naranja(questionIndex) }"
                                                        class="reading_text_review_question_answer">
                                                        <div class="reading_text_review_question_answer_check" fxFlex="2rem" style="align-items: center; display: flex;">
                                                            <i *ngIf="answer.IsCorrect" class="fas fa-check"></i>
                                                        </div>
                
                                                        <div class="reading_text_review_question_answer_text" [innerHtml]="answer.Answer | replaceTags">
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
    
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </ng-container>
                    
                </div>
                <div class="buttons" fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="end end">
                    <button class="new_but azul" [disabled]="loading || questionIndex == 0" (click)="back()" *ngIf="teacherReview && visualize && test.TestFormat == 1">
                        {{'STUDENT.PERSONALIZED-TEST.PREVIOUS-BUTTON' | translate}}
                    </button>
                    <button class="new_but azul" [disabled]="loading || questionIndex == this.test.TestQuestion.length - 1" (click)="next()" *ngIf="teacherReview && visualize && test.TestFormat == 1">
                        {{'STUDENT.PERSONALIZED-TEST.NEXT-BUTTON' | translate}}
                    </button>
                    <button [disabled]="loading" (click)="next()"
                        *ngIf="!review && (test.TestFormat == 1 && questionIndex < this.test.TestQuestion.length - 1)"
                        class="new_but azul">
                        {{'CONTINUE' | translate}}
                    </button>
                    <button [disabled]="loading" (click)="finish()"
                        *ngIf="!review && (test.TestFormat != 1 || (test.TestFormat == 1 && questionIndex == this.test.TestQuestion.length - 1))"
                        class="new_but azul">
                        {{'STUDENT.PERSONALIZED-TEST.END-TEST-BUTTON' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="walinwa-box walinwa-100" *ngIf="review && !loading">

    <div class="walinwa-box-content walinwa-100" *ngIf="state == 'resultList'">
        <div>
            <div class="ww_table_rwd">
                <table class="walinwa-table">
                    <tr>
                        <th style="width:5%; text-align: left;">{{'STUDENT.PERSONALIZED-TEST.TEST-DATE' | translate}}</th>
                        <th style="width:10%; text-align: left;">{{'STUDENT.PERSONALIZED-TEST.TEST-NAME' | translate}}</th>
                        <th style="width:5%; text-align: left;">{{'STUDENT.PERSONALIZED-TEST.TEST-MARK' | translate}}</th>
                    </tr>
                    <tr *ngFor="let doneTest of doneTests" class="walinwa-table-row"
                        (click)="selectPersonalizedTest(doneTest)" [ngClass]="{ selected: doneTest.selected }">
                        <ng-container *ngIf="canReview(doneTest)">
                            <td>{{ doneTest.FormatedDate }}</td>
                            <td>
                                <span>{{ doneTest.Name }}</span>
                            </td>
                            <td>
                                <span>{{ doneTest.Score }}</span>
                            </td>
                        </ng-container>

                    </tr>
                    <tr *ngIf="doneTests?.length == 0">
                        <td style="color: var(--azul);" colspan="4">{{'STUDENT.PERSONALIZED-TEST.NO-TEST-TO-CHECK-TEXT' | translate}}</td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="walinwa-row" fxLayout="row" fxLayoutAlign="end center">
            <button (click)="close()" [disabled]="loading || reviewDisabled()" mat-raised-button color="accent"
                class="submit-button" fxFlex="145px">
                {{'STUDENT.PERSONALIZED-TEST.TEST-CHECK-BUTTON' | translate}}
            </button>
        </div>

    </div>
</div>