<div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical" [hidden]="state != 'schoolData'">
    <div class="walinwa-box-header">
        {{'SCHOOL.ADMIN.PAYMENT.TITLE' | translate}}
    </div>
    <form [formGroup]="newSchoolForm" class="walinwa-box-content" fxLayout="column" fxLayoutAlign="stretch">
        <div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxNgClass.gt-sm="spe_gap">
                <div fxFlex="" fxFlex.lt-md="100%">                    
                    <mat-form-field class="less_top_margin_field two">
                        <label class="font_blue">{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.SCHOOL-NAME' | translate }}</label>
                        <input formControlName="Name" matInput type="text" class="">
                        <mat-error *ngIf="newSchoolForm.get('Name').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%">
                    <mat-form-field class="less_top_margin_field two">
                        <label class="font_blue">{{'SCHOOL.ADMIN.PAYMENT.COMPANY' | translate}}</label>
                        <input formControlName="Company" matInput type="text">
                        <mat-error *ngIf="newSchoolForm.get('duration').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxNgClass.gt-sm="spe_gap">
                <div fxFlex="" fxFlex.lt-md="100%">
                    <mat-form-field class="less_top_margin_field two">
                        <label class="font_blue">{{'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.ADDRESS' | translate}}</label>
                        <input formControlName="Street" matInput type="text" class="">
                        <mat-error *ngIf="newSchoolForm.get('Street').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%">
                    <mat-form-field class="less_top_margin_field two">
                        <label class="font_blue">{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.STATE' | translate }}</label>
                        <input formControlName="State" matInput type="text" class="">
                        <mat-error *ngIf="newSchoolForm.get('State').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxNgClass.gt-sm="spe_gap">
                <div fxFlex="" fxFlex.lt-md="100%">
                    <mat-form-field class="less_top_margin_field two">
                        <label class="font_blue">{{'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.COUNTRY' | translate}}</label>
                            <mat-select formControlName="Country" (change)="changeCountry()">                         
                                <mat-option value="AF">Afganistán</mat-option>
                                <mat-option value="AL">Albania</mat-option>
                                <mat-option value="DE">Alemania</mat-option>
                                <mat-option value="AD">Andorra</mat-option>
                                <mat-option value="AO">Angola</mat-option>
                                <mat-option value="AI">Anguilla</mat-option>
                                <mat-option value="AQ">Antártida</mat-option>
                                <mat-option value="AG">Antigua y Barbuda</mat-option>
                                <mat-option value="AN">Antillas Holandesas</mat-option>
                                <mat-option value="SA">Arabia Saudí</mat-option>
                                <mat-option value="DZ">Argelia</mat-option>
                                <mat-option value="AR">Argentina</mat-option>
                                <mat-option value="AM">Armenia</mat-option>
                                <mat-option value="AW">Aruba</mat-option>
                                <mat-option value="AU">Australia</mat-option>
                                <mat-option value="AT">Austria</mat-option>
                                <mat-option value="AZ">Azerbaiyán</mat-option>
                                <mat-option value="BS">Bahamas</mat-option>
                                <mat-option value="BH">Bahrein</mat-option>
                                <mat-option value="BD">Bangladesh</mat-option>
                                <mat-option value="BB">Barbados</mat-option>
                                <mat-option value="BE">Bélgica</mat-option>
                                <mat-option value="BZ">Belice</mat-option>
                                <mat-option value="BJ">Benin</mat-option>
                                <mat-option value="BM">Bermudas</mat-option>
                                <mat-option value="BY">Bielorrusia</mat-option>
                                <mat-option value="MM">Birmania</mat-option>
                                <mat-option value="BO">Bolivia</mat-option>
                                <mat-option value="BA">Bosnia y Herzegovina</mat-option>
                                <mat-option value="BW">Botswana</mat-option>
                                <mat-option value="BR">Brasil</mat-option>
                                <mat-option value="BN">Brunei</mat-option>
                                <mat-option value="BG">Bulgaria</mat-option>
                                <mat-option value="BF">Burkina Faso</mat-option>
                                <mat-option value="BI">Burundi</mat-option>
                                <mat-option value="BT">Bután</mat-option>
                                <mat-option value="CV">Cabo Verde</mat-option>
                                <mat-option value="KH">Camboya</mat-option>
                                <mat-option value="CM">Camerún</mat-option>
                                <mat-option value="CA">Canadá</mat-option>
                                <mat-option value="TD">Chad</mat-option>
                                <mat-option value="CL">Chile</mat-option>
                                <mat-option value="CN">China</mat-option>
                                <mat-option value="CY">Chipre</mat-option>
                                <mat-option value="VA">Ciudad del Vaticano (Santa Sede)</mat-option>
                                <mat-option value="CO">Colombia</mat-option>
                                <mat-option value="KM">Comores</mat-option>
                                <mat-option value="CG">Congo</mat-option>
                                <mat-option value="CD">Congo, República Democrática del</mat-option>
                                <mat-option value="KR">Corea</mat-option>
                                <mat-option value="KP">Corea del Norte</mat-option>
                                <mat-option value="CI">Costa de Marfíl</mat-option>
                                <mat-option value="CR">Costa Rica</mat-option>
                                <mat-option value="HR">Croacia (Hrvatska)</mat-option>
                                <mat-option value="CU">Cuba</mat-option>
                                <mat-option value="DK">Dinamarca</mat-option>
                                <mat-option value="DJ">Djibouti</mat-option>
                                <mat-option value="DM">Dominica</mat-option>
                                <mat-option value="EC">Ecuador</mat-option>
                                <mat-option value="EG">Egipto</mat-option>
                                <mat-option value="SV">El Salvador</mat-option>
                                <mat-option value="AE">Emiratos Árabes Unidos</mat-option>
                                <mat-option value="ER">Eritrea</mat-option>
                                <mat-option value="SI">Eslovenia</mat-option>
                                <mat-option value="ES" selected>España</mat-option>
                                <mat-option value="US">Estados Unidos</mat-option>
                                <mat-option value="EE">Estonia</mat-option>
                                <mat-option value="ET">Etiopía</mat-option>
                                <mat-option value="FJ">Fiji</mat-option>
                                <mat-option value="PH">Filipinas</mat-option>
                                <mat-option value="FI">Finlandia</mat-option>
                                <mat-option value="FR">Francia</mat-option>
                                <mat-option value="GA">Gabón</mat-option>
                                <mat-option value="GM">Gambia</mat-option>
                                <mat-option value="GE">Georgia</mat-option>
                                <mat-option value="GH">Ghana</mat-option>
                                <mat-option value="GI">Gibraltar</mat-option>
                                <mat-option value="GD">Granada</mat-option>
                                <mat-option value="GR">Grecia</mat-option>
                                <mat-option value="GL">Groenlandia</mat-option>
                                <mat-option value="GP">Guadalupe</mat-option>
                                <mat-option value="GU">Guam</mat-option>
                                <mat-option value="GT">Guatemala</mat-option>
                                <mat-option value="GY">Guayana</mat-option>
                                <mat-option value="GF">Guayana Francesa</mat-option>
                                <mat-option value="GN">Guinea</mat-option>
                                <mat-option value="GQ">Guinea Ecuatorial</mat-option>
                                <mat-option value="GW">Guinea-Bissau</mat-option>
                                <mat-option value="HT">Haití</mat-option>
                                <mat-option value="HN">Honduras</mat-option>
                                <mat-option value="HU">Hungría</mat-option>
                                <mat-option value="IN">India</mat-option>
                                <mat-option value="ID">Indonesia</mat-option>
                                <mat-option value="IQ">Irak</mat-option>
                                <mat-option value="IR">Irán</mat-option>
                                <mat-option value="IE">Irlanda</mat-option>
                                <mat-option value="BV">Isla Bouvet</mat-option>
                                <mat-option value="CX">Isla de Christmas</mat-option>
                                <mat-option value="IS">Islandia</mat-option>
                                <mat-option value="KY">Islas Caimán</mat-option>
                                <mat-option value="CK">Islas Cook</mat-option>
                                <mat-option value="CC">Islas de Cocos o Keeling</mat-option>
                                <mat-option value="FO">Islas Faroe</mat-option>
                                <mat-option value="HM">Islas Heard y McDonald</mat-option>
                                <mat-option value="FK">Islas Malvinas</mat-option>
                                <mat-option value="MP">Islas Marianas del Norte</mat-option>
                                <mat-option value="MH">Islas Marshall</mat-option>
                                <mat-option value="UM">Islas menores de Estados Unidos</mat-option>
                                <mat-option value="PW">Islas Palau</mat-option>
                                <mat-option value="SB">Islas Salomón</mat-option>
                                <mat-option value="SJ">Islas Svalbard y Jan Mayen</mat-option>
                                <mat-option value="TK">Islas Tokelau</mat-option>
                                <mat-option value="TC">Islas Turks y Caicos</mat-option>
                                <mat-option value="VI">Islas Vírgenes (EEUU)</mat-option>
                                <mat-option value="VG">Islas Vírgenes (Reino Unido)</mat-option>
                                <mat-option value="WF">Islas Wallis y Futuna</mat-option>
                                <mat-option value="IL">Israel</mat-option>
                                <mat-option value="IT">Italia</mat-option>
                                <mat-option value="JM">Jamaica</mat-option>
                                <mat-option value="JP">Japón</mat-option>
                                <mat-option value="JO">Jordania</mat-option>
                                <mat-option value="KZ">Kazajistán</mat-option>
                                <mat-option value="KE">Kenia</mat-option>
                                <mat-option value="KG">Kirguizistán</mat-option>
                                <mat-option value="KI">Kiribati</mat-option>
                                <mat-option value="KW">Kuwait</mat-option>
                                <mat-option value="LA">Laos</mat-option>
                                <mat-option value="LS">Lesotho</mat-option>
                                <mat-option value="LV">Letonia</mat-option>
                                <mat-option value="LB">Líbano</mat-option>
                                <mat-option value="LR">Liberia</mat-option>
                                <mat-option value="LY">Libia</mat-option>
                                <mat-option value="LI">Liechtenstein</mat-option>
                                <mat-option value="LT">Lituania</mat-option>
                                <mat-option value="LU">Luxemburgo</mat-option>
                                <mat-option value="MK">Macedonia, Ex-República Yugoslava de</mat-option>
                                <mat-option value="MG">Madagascar</mat-option>
                                <mat-option value="MY">Malasia</mat-option>
                                <mat-option value="MW">Malawi</mat-option>
                                <mat-option value="MV">Maldivas</mat-option>
                                <mat-option value="ML">Malí</mat-option>
                                <mat-option value="MT">Malta</mat-option>
                                <mat-option value="MA">Marruecos</mat-option>
                                <mat-option value="MQ">Martinica</mat-option>
                                <mat-option value="MU">Mauricio</mat-option>
                                <mat-option value="MR">Mauritania</mat-option>
                                <mat-option value="YT">Mayotte</mat-option>
                                <mat-option value="MX">México</mat-option>
                                <mat-option value="FM">Micronesia</mat-option>
                                <mat-option value="MD">Moldavia</mat-option>
                                <mat-option value="MC">Mónaco</mat-option>
                                <mat-option value="MN">Mongolia</mat-option>
                                <mat-option value="MS">Montserrat</mat-option>
                                <mat-option value="MZ">Mozambique</mat-option>
                                <mat-option value="NA">Namibia</mat-option>
                                <mat-option value="NR">Nauru</mat-option>
                                <mat-option value="NP">Nepal</mat-option>
                                <mat-option value="NI">Nicaragua</mat-option>
                                <mat-option value="NE">Níger</mat-option>
                                <mat-option value="NG">Nigeria</mat-option>
                                <mat-option value="NU">Niue</mat-option>
                                <mat-option value="NF">Norfolk</mat-option>
                                <mat-option value="NO">Noruega</mat-option>
                                <mat-option value="NC">Nueva Caledonia</mat-option>
                                <mat-option value="NZ">Nueva Zelanda</mat-option>
                                <mat-option value="OM">Omán</mat-option>
                                <mat-option value="NL">Países Bajos</mat-option>
                                <mat-option value="PA">Panamá</mat-option>
                                <mat-option value="PG">Papúa Nueva Guinea</mat-option>
                                <mat-option value="PK">Paquistán</mat-option>
                                <mat-option value="PY">Paraguay</mat-option>
                                <mat-option value="PE">Perú</mat-option>
                                <mat-option value="PN">Pitcairn</mat-option>
                                <mat-option value="PF">Polinesia Francesa</mat-option>
                                <mat-option value="PL">Polonia</mat-option>
                                <mat-option value="PT">Portugal</mat-option>
                                <mat-option value="PR">Puerto Rico</mat-option>
                                <mat-option value="QA">Qatar</mat-option>
                                <mat-option value="UK">Reino Unido</mat-option>
                                <mat-option value="CF">República Centroafricana</mat-option>
                                <mat-option value="CZ">República Checa</mat-option>
                                <mat-option value="ZA">República de Sudáfrica</mat-option>
                                <mat-option value="DO">República Dominicana</mat-option>
                                <mat-option value="SK">República Eslovaca</mat-option>
                                <mat-option value="RE">Reunión</mat-option>
                                <mat-option value="RW">Ruanda</mat-option>
                                <mat-option value="RO">Rumania</mat-option>
                                <mat-option value="RU">Rusia</mat-option>
                                <mat-option value="EH">Sahara Occidental</mat-option>
                                <mat-option value="KN">Saint Kitts y Nevis</mat-option>
                                <mat-option value="WS">Samoa</mat-option>
                                <mat-option value="AS">Samoa Americana</mat-option>
                                <mat-option value="SM">San Marino</mat-option>
                                <mat-option value="VC">San Vicente y Granadinas</mat-option>
                                <mat-option value="SH">Santa Helena</mat-option>
                                <mat-option value="LC">Santa Lucía</mat-option>
                                <mat-option value="ST">Santo Tomé y Príncipe</mat-option>
                                <mat-option value="SN">Senegal</mat-option>
                                <mat-option value="SC">Seychelles</mat-option>
                                <mat-option value="SL">Sierra Leona</mat-option>
                                <mat-option value="SG">Singapur</mat-option>
                                <mat-option value="SY">Siria</mat-option>
                                <mat-option value="SO">Somalia</mat-option>
                                <mat-option value="LK">Sri Lanka</mat-option>
                                <mat-option value="PM">St Pierre y Miquelon</mat-option>
                                <mat-option value="SZ">Suazilandia</mat-option>
                                <mat-option value="SD">Sudán</mat-option>
                                <mat-option value="SE">Suecia</mat-option>
                                <mat-option value="CH">Suiza</mat-option>
                                <mat-option value="SR">Surinam</mat-option>
                                <mat-option value="TH">Tailandia</mat-option>
                                <mat-option value="TW">Taiwán</mat-option>
                                <mat-option value="TZ">Tanzania</mat-option>
                                <mat-option value="TJ">Tayikistán</mat-option>
                                <mat-option value="TF">Territorios franceses del Sur</mat-option>
                                <mat-option value="TP">Timor Oriental</mat-option>
                                <mat-option value="TG">Togo</mat-option>
                                <mat-option value="TO">Tonga</mat-option>
                                <mat-option value="TT">Trinidad y Tobago</mat-option>
                                <mat-option value="TN">Túnez</mat-option>
                                <mat-option value="TM">Turkmenistán</mat-option>
                                <mat-option value="TR">Turquía</mat-option>
                                <mat-option value="TV">Tuvalu</mat-option>
                                <mat-option value="UA">Ucrania</mat-option>
                                <mat-option value="UG">Uganda</mat-option>
                                <mat-option value="UY">Uruguay</mat-option>
                                <mat-option value="UZ">Uzbekistán</mat-option>
                                <mat-option value="VU">Vanuatu</mat-option>
                                <mat-option value="VE">Venezuela</mat-option>
                                <mat-option value="VN">Vietnam</mat-option>
                                <mat-option value="YE">Yemen</mat-option>
                                <mat-option value="YU">Yugoslavia</mat-option>
                                <mat-option value="ZM">Zambia</mat-option>
                                <mat-option value="ZW">Zimbabue</mat-option>
                            </mat-select>
                        <mat-error *ngIf="newSchoolForm.get('Country').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%">                    
                    <mat-form-field class="less_top_margin_field two">
                        <label class="font_blue">{{'SCHOOL.ADMIN.PAYMENT.CIF' | translate}}</label>
                        <input formControlName="Cif" matInput type="text" class="form-control">
                        <mat-error *ngIf="newSchoolForm.get('Cif').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxNgClass.gt-sm="spe_gap">
                <div fxFlex="" fxFlex.lt-md="100%">                    
                    <mat-form-field class="less_top_margin_field two">
                        <label class="font_blue">{{'SCHOOL.ADMIN.PAYMENT.RESPONSIBLE' | translate}}</label>
                        <input formControlName="Responsible" matInput type="text" class="form-control">
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%">                    
                    <mat-form-field class="less_top_margin_field two">
                        <label class="font_blue">{{'EMAIL' | translate}}</label>
                        <input formControlName="Email" matInput type="text" class="form-control">
                        <mat-error *ngIf="newSchoolForm.get('Email').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                        <mat-error *ngIf="newSchoolForm.get('Email').hasError('email')">
                            {{'EMAIL-INVALID' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxNgClass.gt-sm="spe_gap">
                <div fxFlex="" fxFlex.lt-md="100%">                    
                    <mat-form-field class="less_top_margin_field two">
                        <label class="font_blue">{{'SCHOOL.ADMIN.PAYMENT.NUM-STUDENTS' | translate}}</label>
                        <input (change)="updateNumStudents()" formControlName="numStudents" matInput type="text"
                            class="form-control">
                        <mat-error *ngIf="newSchoolForm.get('numStudents').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                        <mat-error *ngIf="newSchoolForm.get('numStudents').hasError('min')">
                            {{'SCHOOL.ADMIN.PAYMENT.NUM-STUDENTS-MIN' | translate}}
                        </mat-error>
                        <mat-error *ngIf="newSchoolForm.get('numStudents').hasError('active')">
                            {{'SCHOOL.ADMIN.PAYMENT.NUM-STUDENTS-ACTIVE' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%">
                    <mat-form-field class="less_top_margin_field two">
                        <label class="font_blue">{{'SCHOOL.ADMIN.PAYMENT.STARTDATE' | translate}}</label>
                        <input matInput [matDatepicker]="picker" [min]="minDateCal" formControlName="iniDateFormated"
                            [(ngModel)]="iniDateFormated">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="newSchoolForm.get('iniDateFormated').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxNgClass.gt-sm="spe_gap">
                <div fxFlex="" fxFlex.lt-md="100%">
                    <mat-form-field class="less_top_margin_field two">
                        <label class="font_blue">{{'SCHOOL.ADMIN.PAYMENT.LICENSE-DURATION' | translate}}</label>

                        <mat-select formControlName="duration" class="select" [(ngModel)]="duration" *ngIf="!america">
                            <mat-option value="1" [hidden]="school.Country == 'ES'">{{'SCHOOL.ADMIN.PAYMENT.LICENSE-DURATION-1' | translate}}</mat-option>
                            <mat-option value="3">{{'SCHOOL.ADMIN.PAYMENT.LICENSE-DURATION-2' | translate}}</mat-option>
                            <mat-option value="9">{{'SCHOOL.ADMIN.PAYMENT.LICENSE-DURATION-3' | translate}}</mat-option>
                        </mat-select>

                        <mat-select formControlName="duration" class="select" [(ngModel)]="duration" *ngIf="america">
                            <mat-option value="1">{{'SCHOOL.ADMIN.PAYMENT.LICENSE-DURATION-AMERICA1' | translate}}</mat-option>
                            <mat-option value="3">{{'SCHOOL.ADMIN.PAYMENT.LICENSE-DURATION-AMERICA2' | translate}}</mat-option>
                        </mat-select>                          
                        <mat-error *ngIf="newSchoolForm.get('duration').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%" fxHide.lt-md fxShow></div>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
            <button [disabled]="calling" (click)="continue()" class="new_but azul">
                {{'CONTINUE' | translate}}
            </button>
        </div>
    </form>
</div>

<div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical" [hidden]="state != 'paymentType'">
    <div class="walinwa-box-header">
        {{'SCHOOL.ADMIN.PAYMENT.TITLE' | translate}}
    </div>
    <form class="walinwa-box-content" fxLayout="column" fxLayoutAlign="stretch">
        <div>
            <mat-form-field class="less_top_margin_field two">
                <label class="font_blue">{{'SCHOOL.ADMIN.PAYMENT.PAYMENT-TYPE' | translate}}</label>
                <mat-select class="select" [(ngModel)]="paymentType" [ngModelOptions]="{standalone: true}">
                    <mat-option value="bank">{{'SCHOOL.ADMIN.PAYMENT.PAYMENT-TYPE-1' | translate}}</mat-option>
                    <mat-option value="card">{{'SCHOOL.ADMIN.PAYMENT.PAYMENT-TYPE-2' | translate}}</mat-option>
                </mat-select>  
            </mat-form-field>
        </div>
        <div>
            {{'SCHOOL.ADMIN.PAYMENT.LICENSE-COST-1' | translate}}{{numStudents}}{{'SCHOOL.ADMIN.PAYMENT.LICENSE-COST-2' | translate}}{{formatDate(maxDate)}}{{'SCHOOL.ADMIN.PAYMENT.LICENSE-COST-3' | translate}}<span
                class="font_blue"><b>{{ amount }} €.</b></span>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
            <button [disabled]="calling" (click)="continue()" class="new_but azul">
                {{'CONTINUE' | translate}}
            </button>
        </div>
    </form>
</div>

<div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical" [hidden]="state != 'paymentBank'">
    <div class="walinwa-box-header">
        {{'SCHOOL.ADMIN.PAYMENT.TITLE' | translate}}
    </div>
    <form class="walinwa-box-content" fxLayout="column" fxLayoutAlign="stretch">
        <div [innerHTML]="'SCHOOL.ADMIN.PAYMENT.VALIDATE-LICENSE-PAYMENT' | translate:{ refNum: refNum }">
            
        </div>
        <div>
            {{'SCHOOL.ADMIN.PAYMENT.VALIDATE-LICENSE-PAYMENT-EMAIL' | translate}}
        </div>
        <div>
            {{'SCHOOL.ADMIN.PAYMENT.LICENSE-COST-1' | translate}}{{numStudents}}{{'SCHOOL.ADMIN.PAYMENT.LICENSE-COST-2' | translate}}{{formatDate(maxDate)}}{{'SCHOOL.ADMIN.PAYMENT.LICENSE-COST-3' | translate}}
            &nbsp;<span class="font_blue"><b>{{ amount }} €.</b></span>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
            <button [disabled]="calling" (click)="finish()" class="new_but azul">
                {{'FINISH' | translate}}
            </button>
        </div>
    </form>
</div>

<tpv [config]="tpvInfo" (onClose)="volver(true)" *ngIf="state=='tpv'"></tpv>