<div class="walinwa-box-with-tabs" fxNgClass.gt-sm="scroll_vertical" #scrollableElement>
    <div class="walinwa-tabs walinwa-tabs-top">
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'editSessions', 'disabled': !schoolService.school?.PredefinedSession}"
            (click)="editSessions()" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.COMPLEMENTS.PREDEFINED-SESSIONS-TITLE' | translate }}</div>
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'exams', 'disabled': !schoolService.school?.Exam}" (click)="editExams()"
            class="walinwa-tab-gray">{{'SCHOOL.TEACHER.COMPLEMENTS.EXAMS-TITLE' | translate }}</div>
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'controlSession', 'disabled': !schoolService.school?.ControlSession}"
            (click)="controlSession()" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION-TITLE' | translate }}</div>
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'personalizedTest', 'disabled': !schoolService.school?.PersonalizedTest}"
            (click)="personalizedTest()" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST-TITLE' | translate }}</div>

    </div>
    <div class="walinwa-box-green">
        @if (dataLoaded) {
            <router-outlet></router-outlet>
        }
    </div>
</div>