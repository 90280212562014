import { SharedService } from 'app/core/shared/shared.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolService } from 'app/services/school.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationService } from 'app/services/navigation.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-student',
  templateUrl: './new-student.component.html',
  styleUrls: ['./new-student.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewStudentComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  currentUser;
  licenseError = false;
  formDataStudent : UntypedFormGroup;
  calling;
  invalidStudenUsername;
  schoolInitials;
  class;
  currentSchool;
  dataLoaded = false;
  regExp = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜäÄëËïÏöÖç\s\.\-'ª’]+$/u
    
  constructor(
    private router: Router,
    private schoolService: SchoolService,
    private _matDialog: MatDialog,
    private activateRoter: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private navigationService: NavigationService,
    private sharedService: SharedService,
    private _translateService: TranslateService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

  ngOnInit(): void {
    if (!sessionStorage.getItem("currentUser")) return this.navigationService.exit();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (!this.currentUser) return this.navigationService.exit();
    this.navigationService.enableBackButton(true, this.back.bind(this));
    let idClass = this.activateRoter.params['value'].IdClass;
      this.schoolService.getSimpleClass(idClass)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetClass.bind(this), this.errorCallback.bind(this));
    this.currentSchool = this.schoolService.school;
    this.newStudent();
    this.dataLoaded = true;
  }

    back() {
    this.navigationService.go("/school/students", {IdClass: this.class.IdClass})
  }

  newStudent() {
    this.formDataStudent = this._formBuilder.group({
      Name: ['', [Validators.required, Validators.pattern(this.regExp), Validators.maxLength(40)]],
      FamilyName: ['',[Validators.pattern(this.regExp), Validators.maxLength(50)]],
      Username: [{ value: '', disabled: true }],
      Password: [{ value: '12345678', disabled: true }]
    });
  }

  getStudentName() {

    if (!this.formDataStudent.valid) return;

    this.schoolService.getStudentName(this.currentSchool.Initials, this.formDataStudent.value.Name.trim(), this.class.Code, this.formDataStudent.value.FamilyName.trim())
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.setStudentName(result);
      },
      error => {
        this.invalidStudentUsername(error.error);
      }
    )
    this.calling = true;
  }

  invalidStudentUsername(response) {
    this.invalidStudenUsername = true;
    this.calling = false;
  }

  setStudentName(response) {
    this.calling = false;
    if (response.Exists != null) {
      //Show warning
      var message = [];
      message[0] = this._translateService.instant('SCHOOL.MODAL-NEW-STUDENT-TITLE');
      message[1]= this._translateService.instant('SCHOOL.MODAL-NEW-STUDENT-MESSAGE', {StudentName: this.formDataStudent.value.Name, FamilyName: this.formDataStudent.value.FamilyName || '', Exists: response.Exists})

      let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
      });
      confirmDialog.componentInstance.title = message[0];
      confirmDialog.componentInstance.message1 = message[1];
      confirmDialog.componentInstance.message2 = message[2];
      confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
      confirmDialog.componentInstance.margin = false;
      confirmDialog.componentInstance.theme = "white";

      confirmDialog.componentInstance.options = [{
        text: "Sí",
        callback: () => {
          this.formDataStudent.patchValue({ Username: response.Username });
          this.invalidStudenUsername = false;
          this.submitFormStudent();
        }
      }, {
        text: "No",
        callback: () => { }
      }];

      confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
        result()
        confirmDialog = null;
      });
    } else {
      this.formDataStudent.patchValue({ Username: response.Username });
      this.invalidStudenUsername = false;
      this.submitFormStudent();
    }
  }

  submitFormStudent() {
    if (this.calling)
      return;

    var student = {
      UserName: this.formDataStudent.controls['Username'].value,
      Name: this.formDataStudent.controls['Name'].value,
      FamilyName: this.formDataStudent.controls['FamilyName'].value,
      IdSchool: this.currentSchool.IdSchool
    };

    this.schoolService.addStudent(
      student,
      this.class.Code,
      this.formDataStudent.controls['Password'].value,
      true,
      "añadir alumno area profe")
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successCallbackCreateStudent.bind(this), this.unexpectedError.bind(this));
    this.calling = true;
  }

  successCallbackCreateStudent(response) {
    var message = [];
    message[0] = this._translateService.instant('SCHOOL.MODAL-SUCCESS-NEW-STUDENT-TITLE');
    message[1] = this._translateService.instant('SCHOOL.MODAL-SUCCESS-NEW-STUDENT-MESSAGE', {UserName: this.formDataStudent.controls['Username'].value})
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      this.schoolService.getSchoolByUser(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        (result: any) => {
        this.schoolService.school = result;
        this.sharedService.changeNumStudents(result.NumStudents);
        
        this.back();
        }
      )
      confirmDialog = null;
    });
  }

  unexpectedError(response) {
    this.calling = false;
    var message = [];
    console.log(response, response.data)
    if (response.error == 'NoValidLicense') {
      message[0] = this._translateService.instant('SCHOOL.MODAL-NO-VALID-LICENSE-TITLE');
      message[1] = this._translateService.instant('SCHOOL.MODAL-NO-VALID-LICENSE-MESSAGE');
    } else {
      message[0] = this._translateService.instant('ERROR');
      message[1] = this._translateService.instant('SCHOOL.MODAL-ERROR-MESSAGE');
    }
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
  }

  successGetSchoolInitials(response) {
    this.calling = false;
      if (response == null) return this.navigationService.exit();
    this.schoolInitials = response.Initials;
  }

  successGetClass(response) {
    this.calling = false;
    if (response == null) return this.navigationService.exit();
    this.class = response;
    this.schoolService.getSchoolInitialsById(this.class.IdSchool)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetSchoolInitials.bind(this), this.errorCallback.bind(this));
  }

  errorCallback() {
    this.navigationService.exit();
  }

}
