<div class="walinwa-box">
    <div class="walinwa-box-content" *ngIf="navigationService.stateControl=='classes-controlSessions'">
        <div>
            <div class="ww_table_rwd">
                <table class="walinwa-table">
                    <thead>
                        <tr>
                            <th width="15%;">{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.DATE' | translate }}</th>
                            <th width="15%;">{{'CLASS' | translate }}</th>
                            <th width="">{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.NAME' | translate }}</th>
                            <th style="width:50px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let class_controlSession of sortData">
                            <td>
                                {{ class_controlSession.formatted_date}}
                            </td>
                            <td>
                                <span *ngIf="class_controlSession.Aula">{{ class_controlSession.Aula }}</span>
                                <span *ngIf="!class_controlSession.Aula">{{ class_controlSession.IdClass }}</span>
                            </td>
                            <td>
                                <span *ngIf="class_controlSession.Name">{{
                                    class_controlSession.Name }}</span>
                                <span *ngIf="!class_controlSession.Name">{{ class_controlSession.IdControl
                                    }}</span>
                            </td>
                            <td style="text-align: right;">
                                <img class="spe_ico_exam" src="https://walinwa.blob.core.windows.net/images/School/ico-trash.svg?ngsw-bypass=true"
                                    (click)="deleteClassControlSession(class_controlSession)">
                            </td>
                        </tr>
                        <tr *ngIf="classes_controlSessions.length==0">
                            <td class="font_blue" colspan="4">{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.NO-ASSIGNED-SESSION' | translate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="spe_gap" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
            <button (click)="editControlSessions()" [disabled]="loading" class="new_but azul">
                {{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.SHOW-SESSION' | translate }}
            </button>
            <button (click)="addClassControlSession()" [disabled]="loading" class="new_but azul">
                {{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.ASSIGN-NEW' | translate }}
            </button>
        </div>
    </div>

    <div class="walinwa-box-content spe_gap" *ngIf="navigationService.stateControl=='add-class-controlSession'">
        <div *ngIf="navigationService.add_class_step==1" [innerHTML]="'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.ADD-SESSION-TITLE1' | translate">
        </div>
        <div *ngIf="navigationService.add_class_step==2">
            {{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.ADD-SESSION-TITLE2' | translate}}
        </div>
        <div *ngIf="navigationService.add_class_step==3">
            {{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.ADD-SESSION-TITLE3' | translate}}
        </div>
        <div *ngIf="navigationService.add_class_step==1">
            <calCustomizedSession class="calendario dinamico walCalendar azul ww_customCalendar" [parent]="this"
                [currentUser]="calendar.current_user" [disablePast]="true" [selectedDay]="calendar.selected_day"
                [currentMonth]="calendar.current_month" [customizedSessions]="customizedSessions" [isExamen]="true">
            </calCustomizedSession>
        </div>
        <div class="" *ngIf="navigationService.add_class_step==2">
            <div class="makeScroll" fxFlex="100%" fxFlex.gt-xs="300px">
                <mat-checkbox *ngFor="let class of classes | sortBy:'asc':['Level','Code']"
                    (change)="selectClass(class)" [checked]="class.selected">
                    <span>{{ class.Name }}</span>
                </mat-checkbox>
            </div>
        </div>
        <div *ngIf="navigationService.add_class_step==2 && showMessage" class="error-message">
            <mat-error>
                <div *ngFor="let message of messages">
                    {{message}}
                </div>
            </mat-error>
        </div>
        <div *ngIf="navigationService.add_class_step==3 || navigationService.add_class_step==-1">
            <div class="ww_table_rwd">
                <table class="walinwa-table">
                    <thead>
                        <tr>
                            <th width="70%;">{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.SESSION-NAME' | translate}}</th>
                            <th style="width:80px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let controlSession of controlSessions"
                            [ngClass]="{ selected: controlSession.selected }">
                            <td (dblclick)="startChangingControlSessionName(controlSession)"
                                *ngIf="!controlSession.editingControlSessionName"
                                (click)="selectControlSession(controlSession)">{{ controlSession.Name }}</td>
                            <td *ngIf="controlSession.editingControlSessionName">
                                <input (blur)="changeControlSessionName(controlSession)" id="change-name" type="text"
                                    [value]="controlSession.Name" />
                                <mat-error *ngIf="nameExists">
                                    {{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.NAME-EXISTS' | translate}}
                                </mat-error>
                                <mat-error *ngIf="isRequired">
                                    {{ 'FIELD-REQUIRED' | translate }}
                                </mat-error>
                            </td>
                            <td style="text-align: right;">
                                <img class="spe_ico_exam" src="https://walinwa.blob.core.windows.net/images/School/ico-edit.svg?ngsw-bypass=true"
                                    (click)="editSession(controlSession)" />
                                <img class="spe_ico_exam" src="https://walinwa.blob.core.windows.net/images/School/ico-trash.svg?ngsw-bypass=true"
                                    (click)="deleteControlSession(controlSession)" />
                            </td>
                        </tr>
                        <tr *ngIf="controlSessions.length==0">
                            <td class="font_blue" colspan="2">{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.NO-SESSION' | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="walinwa-row spe_gap" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
            <div *ngIf="navigationService.add_class_step < 3"></div>
            <button (click)="addControlSession()" *ngIf="navigationService.add_class_step == 3  || showControlList"
                [disabled]="loading" class="new_but azul">
                {{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.NEW-SESSION' | translate}}
            </button>
            <button (click)="continue()" *ngIf="navigationService.add_class_step!=-1"
                [disabled]="loading || disableWizard()" class="new_but azul">
                <span *ngIf="navigationService.add_class_step < 3">{{'CONTINUE' | translate}}</span>
                <span *ngIf="navigationService.add_class_step == 3">{{'FINISH' | translate}}</span>
            </button>
        </div>
    </div>

    <form (submit)="editControlSessionExercises(null,true)" [formGroup]="form" class="walinwa-box-content"
        *ngIf="navigationService.stateControl=='add-controlSession'" >
        <div [innerHTML]="'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.ADD-SESSION-TITLE4' | translate">
        </div>
        <section fxLayout="row wrap" fxLayoutAlign="space-between center" class="spe_gap" style="margin-bottom: -2em;">
            <div fxFlex="0 0 calc(50% - 1rem)" fxFlex.xs="100%" style="margin-top: .1em;">
                <mat-form-field floatLabel="always"                
                    class="spefieldnowrap less_top_margin_field two">
                    <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.SESSION-NAME' | translate}}</label>
                    <input matInput type="text" formControlName="Name" />
                    <mat-error class="nameExists" *ngIf="form.get('Name').hasError('nameExists')">
                        {{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.NAME-EXISTS' | translate}}
                    </mat-error>
                    <mat-error  *ngIf="form.get('Name').hasError('required') && submitted">
                        {{ 'FIELD-REQUIRED' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="0 0 calc(50% - 1rem)" fxFlex.xs="100%">
                <mat-form-field floatLabel="always"
                    class="spefieldnowrap less_top_margin_field two">
                    <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.COURSE' | translate}}</label>
                    <mat-select formControlName="Level" [ngModelOptions]="{standalone: true}">
                        <mat-option class="scrollable" [value]="course.Level" *ngFor="let course of courses">{{
                            course.Name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </section>
        <section fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="stretch"
            fxLayoutAlign.gt-sm="space-between start" style="margin-top: 25px;">
            <div fxFlex.gt-sm="0 0 calc(50%-1rem)" fxLayout="row wrap" fxLayoutAlign="space-between center"
                fxNgClass.gt-sm="spe_gap">
                <div fxFlex="100%" fxFlex.gt-sm="8.68rem" style="margin-bottom: 10px;">
                    <label class="font_blue"><b>{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.PRIMARY-THEME' | translate}}</b></label>
                </div>
                <div fxFlex="100%" fxFlex.gt-sm="" class="custom-form-field">
                    <mat-form-field>
                        <mat-select formControlName="FP" name="FP">
                            <mat-option class="scrollable" value="" selected>{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.RANDOM' | translate}}</mat-option>
                            <mat-option class="scrollable" value="NO">{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.NO-THEME' | translate}}</mat-option>
                            <mat-option class="scrollable" *ngFor="let theme of themesFP" [value]="theme.Theme"><span [innerHtml]="replace(theme.Description)"></span></mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex.gt-sm="0 0 calc(50%-1rem)" fxLayout="row wrap" fxLayoutAlign="space-between center"
                fxNgClass.gt-sm="spe_gap">
                <div fxFlex="100%" fxFlex.gt-sm="8.68rem" style="margin-bottom: 10px;">
                    <label class="font_blue"><b>{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.SECONDARY-THEME' | translate}}</b></label>
                </div>
                <div fxFlex="100%" fxFlex.gt-sm="" class="custom-form-field">
                    <mat-form-field>
                        <mat-select formControlName="FS" name="FS">
                            <mat-option class="scrollable" value="" selected>{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.RANDOM' | translate}}</mat-option>
                            <mat-option class="scrollable" value="NO">{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.NO-THEME' | translate}}</mat-option>
                            <mat-option class="scrollable" *ngFor="let theme of themesFS" [value]="theme.Theme"><span [innerHtml]="replace(theme.Description)"></span></mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex.gt-sm="0 0 calc(50%-1rem)" fxLayout="row wrap" fxLayoutAlign="space-between center"
                fxNgClass.gt-sm="spe_gap">
                <div fxFlex="100%" fxFlex.gt-sm="8.68rem" style="margin-bottom: 10px;">
                    <label class="font_blue"><b>{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.ACCENTUATION' | translate}}</b></label>
                </div>
                <div fxFlex="100%" fxFlex.gt-sm="" class="custom-form-field">
                    <mat-form-field>
                        <mat-select formControlName="FT" name="FT">
                            <mat-option class="scrollable" value="" selected>{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.RANDOM' | translate}}</mat-option>
                            <mat-option class="scrollable" value="NO">{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.NO-THEME' | translate}}</mat-option>
                            <mat-option class="scrollable" *ngFor="let theme of themesFT" [value]="theme.Theme"><span [innerHtml]="replace(theme.Description)"></span></mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex.gt-sm="0 0 calc(50%-1rem)" fxLayout="row wrap" fxLayoutAlign="space-between center"
                fxNgClass.gt-sm="spe_gap">
                <div fxFlex="100%" fxFlex.gt-sm="8.68rem" style="margin-bottom: 10px;">
                    <label class="font_blue"><b>{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.GRAMMATICAL-THEME' | translate}}</b></label>
                </div>
                <div fxFlex="100%" fxFlex.gt-sm="" class="custom-form-field">
                    <mat-form-field>
                        <mat-select formControlName="GR" name="GR">
                            <mat-option class="scrollable" value="" selected>{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.RANDOM' | translate}}</mat-option>
                            <mat-option class="scrollable" value="NO">{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.NO-THEME' | translate}}</mat-option>
                            <mat-option class="scrollable" *ngFor="let theme of themesGR" [value]="theme.Theme"><span [innerHtml]="replace(theme.Description)"></span></mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </section>
        <div class="walinwa-row spe_gap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
            <img *ngIf="loadingExercises"
                style="width: 10em; position: fixed;top: 0;left: 0;bottom: 0;right: 0;margin: auto; z-index: 6;"
                src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">
            <button
                [disabled]="loading || (form.value.FP == 'NO' && form.value.FS == 'NO' && form.value.FT == 'NO' && form.value.GR == 'NO')"
                class="new_but azul">
                {{'START' | translate}}
            </button>
        </div>
    </form>
</div>

<div class="walinwa-box">
    <div class="walinwa-overlay walinwa-overlay-exam-exercises-cs"
        *ngIf="navigationService.stateControl=='edit-controlSession-exercises'">
        <div cdkDropList class="slides" id="scroll" (cdkDropListDropped)="drop($event)">
            <div [attr.index]="i" class="slide dropzone" [ngClass]="{ active: i == current_exercise }"
                *ngFor="let exercise of exerciseListPaginated; let i = index" cdkDrag>
                <div class="slide-number">
                    <span>{{ i + 1 }}</span> 
                    <mat-icon class="move_handler" cdkDragHandle>open_with</mat-icon>
                </div>
                <div class="slide-content" [attr.index]="i" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Themes/PrehistoricTheme/shop/110.jpg&quot;)'">
                    <div class="but_gen_a"><mat-icon>style</mat-icon></div>
                    <div class="clicable" (click)="selectSlide(i, true)">
                        <app-mini-exercise #slide [id]="'ex-focus'+i" class="slide-model-container-exer"
                            [currentExercise]="i" [exerciseModel]="exercise.ExerciseModel" [slide]="true"
                            [exercise]="exercise"></app-mini-exercise>
                    </div>
                </div>
            </div>
        </div>
        <div class="current-slide walinwa-box-content">
            <div *ngIf="!editing" class="title spe_gap" fxLayout="row nowrap" fxLayoutAlign="space-between center">
                <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap">
                    <mat-icon class="icopanelslides pointer" fxHide fxShow.lt-md
                        (click)="OpenPanelSlidesMobile();">format_indent_decrease</mat-icon>
                    <span>{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.CREATE' | translate}} {{ controlSession.Name }}</span>
                    <img src="https://walinwa.blob.core.windows.net/images/ico-info-white.svg?ngsw-bypass=true" fxHide.gt-md fxShow fxFlex="2rem"
                        (click)="ShowHideHelpPopup()" />
                </div>
                <!-- <img (click)="cancel()" src="./assets/img/ico-close-avatar.svg" class="pointer" fxFlex="2rem"> -->
                <img (click)="cancel()" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" class="pointer" fxFlex="2rem">
            </div>
            <div *ngIf="editing" class="title spe_gap" fxLayout="row nowrap" fxLayoutAlign="space-between center">
                <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap">
                    <mat-icon class="icopanelslides pointer" fxHide fxShow.lt-md
                        (click)="OpenPanelSlidesMobile();">format_indent_decrease</mat-icon>
                    <span>{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.EDIT' | translate}} {{ controlSession.Name }} </span>
                    <img src="https://walinwa.blob.core.windows.net/images/ico-info-white.svg?ngsw-bypass=true" fxHide.gt-md fxShow fxFlex="2rem"
                        (click)="ShowHideHelpPopup()" />
                </div>
                <!-- <img (click)="cancel()" src="./assets/img/ico-close-avatar.svg" class="pointer" fxFlex="2rem"> -->
                <img (click)="cancel()" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true"  class="pointer" fxFlex="2rem">
            </div>
            <div class="current-slide-content">
                <div class="slide-title-content" fxHide fxShow.gt-md>
                    <h2 [innerHTML]="'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.CREATE-TITLE' | translate">
                    </h2>
                </div>

                <div fxFlex="" fxLayout="row" fxLayoutAlign="space-between stretch">
                    <div fxFlex="2.5rem" fxLayoutAlign="left center" class="handler-left-box">
                        <div class="handler-left pointer">
                            <i class="fa fa-chevron-left" (click)="before(current_exercise - 1, true)"></i>
                        </div>
                    </div>
                    <div fxFlex="">
                        <div class="slide-content" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Themes/PrehistoricTheme/shop/110.jpg&quot;)'">
                            <div *ngIf="exercises[current_exercise]" class="box_prev_excersice">
                                <app-mini-exercise *ngIf="!loading" class="cs-model-container-exer"
                                    [exerciseModel]="exercises[current_exercise].ExerciseModel"
                                    [exercise]="exercises[current_exercise]"
                                    [currentExercise]="current_exercise"></app-mini-exercise>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="2.5rem" fxLayoutAlign="end center" class="handler-right-box">
                        <div class="handler-right pointer">
                            <i class="fa fa-chevron-right" (click)="next(current_exercise + 1, true)"></i>
                        </div>
                    </div>
                </div>

                <div fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-between center"
                    fxLayoutAlign.xs="center center" class="button-container spe_gap">
                    <button (click)="removeExercise()" [disabled]="loading || (exercises?.length <= 1)"
                    class="new_but azul" fxHide.xs fxShow>
                    {{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.DELETE-EXERCISE' | translate}}
                    </button>
                    <!-- Mobile Button -->
                    <div class="but_gen_a" (click)="removeExercise()" [disabled]="loading || (exercises?.length <= 1)"
                        fxHide fxShow.xs>
                        <mat-icon>layers_clear</mat-icon>
                    </div>

                    <button (click)="getNewExercise(current_exercise+1, false)" [disabled]="loading" class="new_but azul" fxHide.xs fxShow>
                        {{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.ADD-EXERCISE' | translate}}
                    </button>
                    <!-- Mobile Button -->
                    <div class="but_gen_a" (click)="getNewExercise(current_exercise+1, false)" [disabled]="loading"
                        fxHide fxShow.xs>
                        <mat-icon>library_add</mat-icon>
                    </div>

                    <button (click)="getNewExercise(current_exercise, true, false)" [disabled]="loading"
                    class="new_but azul" fxHide.xs fxShow>
                    {{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.REPLACE-EXERCISE' | translate}}
                    </button>
                    <!-- Mobile Button -->
                    <div class="but_gen_a" (click)="getNewExercise(current_exercise, true, false)" [disabled]="loading"
                        fxHide fxShow.xs>
                        <mat-icon>find_replace</mat-icon>
                    </div>

                    <button (click)="finishEditExercises()" [disabled]="loading" class="new_but azul" fxHide.xs
                        fxShow>
                        {{'FINISH' | translate}}
                    </button>
                    <!-- Mobile Button -->
                    <div class="but_gen_a" (click)="finishEditExercises()" [disabled]="loading" fxHide fxShow.xs>
                        <mat-icon>check</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="shadow_slides_close" id="shadow_slides_close" fxHide fxShow.lt-md>
            <!-- <img src="./assets/img/ico-close-avatar.svg" class="pointer close_icon_overlay"
                (click)="ClosePanelSlidesMobile();"> -->
            <img src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" class="pointer close_icon_overlay"
                (click)="ClosePanelSlidesMobile();">
        </div>
    </div>
</div>

<div class="walinwa-overlay extra" [hidden]="infoPopupHelp">
    <div class="infopopup spe_gap" fxLayout="column" fxLayoutAlign="stretch">
        <div [innerHTML]="'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.CREATE-TITLE' | translate">
            <div class="walinwa-100 mt-8 " fxHide fxShow.xs>
                <div class="mb-8"><b>Leyenda:</b></div>
                <div fxLayout="column" class="spe_gap leyenda_icons">
                    <div fxLayout="row nowrap" fxLayoutAlign="start center" class="spe_gap">
                        <div><mat-icon>layers_clear</mat-icon></div>
                        <div>{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.DELETE-EXERCISE' | translate}}</div>
                    </div>
                    <div fxLayout="row nowrap" fxLayoutAlign="start center" class="spe_gap">
                        <div><mat-icon>library_add</mat-icon></div>
                        <div>{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.ADD-EXERCISE' | translate}}</div>
                    </div>
                    <div fxLayout="row nowrap" fxLayoutAlign="start center" class="spe_gap">
                        <div><mat-icon>find_replace</mat-icon></div>
                        <div>{{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.REPLACE-EXERCISE' | translate}}</div>
                    </div>
                    <div fxLayout="row nowrap" fxLayoutAlign="start center" class="spe_gap">
                        <div><mat-icon>check</mat-icon></div>
                        <div>{{'FINISH' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
            <button class="new_but" (click)="ShowHideHelpPopup()">
                {{'SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.CLOSE' | translate}}
            </button>
        </div>
    </div>
</div>