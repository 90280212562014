<div class="maincont" *ngIf="!loadingData">
    <div class="box one">
        <div class="close" fxHide fxShow.gt-sm>
            <img class="pointer" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true" fxFlex="2rem" (click)="PanelAvantarHide()"  />
        </div>
        <div class="options">
            <div class="avatar_tabs">
                <div [ngClass]="{ 'active': viewHeads}" (click)="showHead()" class="tabit">
                    {{'STUDENT.AVATAR-SHOP.AVATAR-PARTS.HEAD' | translate }}</div>
                <div [ngClass]="{ 'active': viewEars}" (click)="showEars()" class="tabit">
                    {{'STUDENT.AVATAR-SHOP.AVATAR-PARTS.EARS' | translate }}</div>
                <div [ngClass]="{ 'active': viewHair}" (click)="showHair()" class="tabit">
                    {{'STUDENT.AVATAR-SHOP.AVATAR-PARTS.HAIR' | translate }}</div>
                <div [ngClass]="{ 'active': viewEyebrows}" (click)="showEyebrows()" class="tabit">
                    {{'STUDENT.AVATAR-SHOP.AVATAR-PARTS.EYEBROWS' | translate }}</div>
                <div [ngClass]="{ 'active': viewEyes}" (click)="showEyes()" class="tabit">
                    {{'STUDENT.AVATAR-SHOP.AVATAR-PARTS.EYES' | translate }}</div>
                <div [ngClass]="{ 'active': ViewMouths}" (click)="showMouths()" class="tabit">
                    {{'STUDENT.AVATAR-SHOP.AVATAR-PARTS.MOUTH' | translate }}</div>
                <div [ngClass]="{ 'active': viewNoses}" (click)="showNoses()" class="tabit">
                    {{'STUDENT.AVATAR-SHOP.AVATAR-PARTS.NOSE' | translate }}</div>
                <div [ngClass]="{ 'active': viewShirts}" (click)="showShirts()" class="tabit">
                    {{'STUDENT.AVATAR-SHOP.AVATAR-PARTS.SHIRT' | translate }}</div>
                <div [ngClass]="{ 'active': viewAccesories}" (click)="showAccesories()" class="tabit" *ngIf="hasSpecialItems()">
                    {{'STUDENT.AVATAR-SHOP.AVATAR-PARTS.ACCESSORIES' | translate }}</div>
            </div>
            <!-- ViewHeads -->
            <div *ngIf="viewHeads" class="box_avat_options heads">
                <div *ngFor="let item of avatarItems; let i = index" class="item head">
                    <div class="mainItemAvat">
                        <div class="borderItem" (click)=tryItem(item)
                            [ngClass]="{'activeBg' : item.Selected == true}">
                            <img [src]="getcabezaimage(i)" alt="">
                        </div>
                        <div class="leyenda">
                            <div [ngClass]="{'hidden': item.Bougth != true}" class="">
                                <span class="text_Green"> {{''}}</span>
                                <span class="text_Gray" *ngIf="item.Walinwos != 0"> {{ item.Walinwos }}
                                    walinwos</span>
                                <span class="text_Gray" *ngIf="item.Walinwos == 0">{{'STUDENT.AVATAR-SHOP.FREE-ITEM' | translate }}</span>
                            </div>
                            <div [ngClass]="{'hidden': item.Bougth == true}" class="">
                                <span class=""> {{ item.Walinwos }} walinwos</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Ears -->
            <div *ngIf="viewEars" class="box_avat_options ears">
                <ng-container *ngFor="let item of avatarItems; let i = index">
                    <div class="item ear">
                        <div class="mainItemAvat">
                            <div class="borderItem" (click)=tryItem(item)
                                [ngClass]="{'activeBg' : item.Selected == true}">
                                <img [src]="GetActiveItemsImg(1)" alt="">
                                <img [src]="getImgoreja(i)" class="overImage earoido" alt="">
                            </div>
                            <div class="leyenda">
                                <div [ngClass]="{'hidden': item.Bougth != true}" class="">
                                    <span class="text_Green"> {{''}}</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos != 0"> {{ item.Walinwos }}
                                        walinwos</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos == 0">{{'STUDENT.AVATAR-SHOP.FREE-ITEM' | translate }}</span>
                                </div>
                                <div [ngClass]="{'hidden': item.Bougth == true}" class="">
                                    <span class=""> {{ item.Walinwos }} walinwos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!--  Hair -->
            <div *ngIf="viewHair" class="box_avat_options hairs">
                <ng-container *ngFor="let item of avatarItems; let i = index">
                    <div class="item hair" *ngIf="item.Type == 3">
                        <div class="mainItemAvat">
                            <div class="borderItem" (click)=tryItem(item)
                                [ngClass]="{'activeBg' : item.Selected == true}">
                                <img [src]="getImgPeloSub(i)" class="overImage hairpelo secondPosition" alt="">
                                <img [src]="GetActiveItemsImg(1)" alt="">
                                <img [src]="getImgPelo(i)" class="overImage hairpelo" alt="">
                            </div>
                            <div class="leyenda">
                                <div [ngClass]="{'hidden': item.Bougth != true}" class="">
                                    <span class="text_Green"> {{''}}</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos != 0"> {{ item.Walinwos }}
                                        walinwos</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos == 0">{{'STUDENT.AVATAR-SHOP.FREE-ITEM' | translate }}</span>
                                </div>
                                <div [ngClass]="{'hidden': item.Bougth == true}" class="">
                                    <span class=""> {{ item.Walinwos }} walinwos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- Ojos -->
            <div *ngIf="viewEyes" class="box_avat_options eyes">
                <ng-container *ngFor="let item of avatarItems; let i = index">
                    <div class="item eye">
                        <div class="mainItemAvat">
                            <div class="borderItem" (click)=tryItem(item)
                                [ngClass]="{'activeBg' : item.Selected == true}">
                                <img [src]="GetActiveItemsImg(1)" alt="">
                                <img [src]="getImg(i)" class="overImage eyeojo" alt="">
                            </div>
                            <div class="leyenda">
                                <div [ngClass]="{'hidden': item.Bougth != true}" class="">
                                    <span class="text_Green"> {{''}}</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos != 0"> {{ item.Walinwos }}
                                        walinwos</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos == 0">{{'STUDENT.AVATAR-SHOP.FREE-ITEM' | translate }}</span>
                                </div>
                                <div [ngClass]="{'hidden': item.Bougth == true}" class="">
                                    <span class=""> {{ item.Walinwos }} walinwos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- EyeBrow -->
            <div *ngIf="viewEyebrows" class="box_avat_options eyebrows">
                <ng-container *ngFor="let item of avatarItems; let i = index">
                    <div class="item mouth">
                        <div class="mainItemAvat">
                            <div class="borderItem" (click)=tryItem(item)
                                [ngClass]="{'activeBg' : item.Selected == true}">
                                <img [src]="GetActiveItemsImg(1)" alt="">
                                <img [src]="getImgCeja(i)" class="overImage eyebrowceja" alt="">
                            </div>
                            <div class="leyenda">
                                <div [ngClass]="{'hidden': item.Bougth != true}" class="">
                                    <span class="text_Green"> {{''}}</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos != 0"> {{ item.Walinwos }}
                                        walinwos</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos == 0">{{'STUDENT.AVATAR-SHOP.FREE-ITEM' | translate }}</span>
                                </div>
                                <div [ngClass]="{'hidden': item.Bougth == true}" class="">
                                    <span class=""> {{ item.Walinwos }} walinwos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- Mouths -->
            <div *ngIf="ViewMouths" class="box_avat_options mouths">
                <ng-container *ngFor="let item of avatarItems; let i = index">
                    <div class="item mouth">
                        <div class="mainItemAvat">
                            <div class="borderItem" (click)=tryItem(item)
                                [ngClass]="{'activeBg' : item.Selected == true}">
                                <img [src]="GetActiveItemsImg(1)" alt="">
                                <img [src]="getImg(i)" class="overImage mouthlabio" alt="">
                            </div>
                            <div class="leyenda">
                                <div [ngClass]="{'hidden': item.Bougth != true}" class="">
                                    <span class="text_Green"> {{''}}</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos != 0"> {{ item.Walinwos }}
                                        walinwos</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos == 0">{{'STUDENT.AVATAR-SHOP.FREE-ITEM' | translate }}</span>
                                </div>
                                <div [ngClass]="{'hidden': item.Bougth == true}" class="">
                                    <span class=""> {{ item.Walinwos }} walinwos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- Noses -->
            <div *ngIf="viewNoses" class="box_avat_options noses">
                <ng-container *ngFor="let item of avatarItems; let i = index">
                    <div class="item nose">
                        <div class="mainItemAvat">
                            <div class="borderItem" (click)=tryItem(item)
                                [ngClass]="{'activeBg' : item.Selected == true}">
                                <img [src]="GetActiveItemsImg(1)" alt="">
                                <img [src]="getImg(i)" class="overImage nosesnarizes" alt="">
                            </div>
                            <div class="leyenda">
                                <div [ngClass]="{'hidden': item.Bougth != true}" class="">
                                    <span class="text_Green"> {{''}}</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos != 0"> {{ item.Walinwos }}
                                        walinwos</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos == 0">{{'STUDENT.AVATAR-SHOP.FREE-ITEM' | translate }}</span>
                                </div>
                                <div [ngClass]="{'hidden': item.Bougth == true}" class="">
                                    <span class=""> {{ item.Walinwos }} walinwos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- Tshirts -->
            <div *ngIf="viewShirts" class="box_avat_options shirts">
                <ng-container *ngFor="let item of avatarItems; let i = index">
                    <div class="item shirt">
                        <div class="mainItemAvat">
                            <div class="borderItem" (click)=tryItem(item)
                                [ngClass]="{'activeBg' : item.Selected == true}">
                                <img [src]="getImg(i)" class="shirtCamisa" alt="">
                            </div>
                            <div class="leyenda">
                                <div [ngClass]="{'hidden': item.Bougth != true}" class="">
                                    <span class="text_Green"> {{''}}</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos != 0"> {{ item.Walinwos }}
                                        walinwos</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos == 0">{{'STUDENT.AVATAR-SHOP.FREE-ITEM' | translate }}</span>
                                </div>
                                <div [ngClass]="{'hidden': item.Bougth == true}" class="">
                                    <span class=""> {{ item.Walinwos }} walinwos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="viewAccesories" class="box_avat_options accesories">
                <ng-container *ngFor="let item of avatarItems; let i = index">
                    <div class="item accesories" *ngIf="item.Bougth">
                        <div class="mainItemAvat">
                            <div class="borderItem" (click)="tryItem(item, true)"
                                [ngClass]="{'activeBg' : item.Selected == true}">
                                <img [src]="GetActiveItemsImg(1)" alt="">
                                <img [src]="getImg(i)" class="overImage cosmetico" alt="">
                            </div>
                            <div class="leyenda">
                                <div [ngClass]="{'hidden': item.Bougth != true}" class="">
                                    <span class="text_Green"> {{''}}</span>
                                    <span class="text_Gray" *ngIf="item.Walinwos == 0">{{'STUDENT.AVATAR-SHOP.FREE-ITEM' | translate }}</span>
                                    <span *ngIf="es_regalado(item) != ''" class="text_Green">Regalo de {{ es_regalado(item) }}</span>
                                </div>
                                <div [ngClass]="{'hidden': item.Bougth == true}" class="">
                                    <span class=""> {{ item.Walinwos }} walinwos</span>
                                </div>
                                <div [ngClass]="{'hidden': item.Bougth != true}" class="textShopContainer">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- Buttons -->
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center" class="avats_buttons spe_gap">
                <button [disabled]="!itemSelcted || itemSelcted.Bougth != true || es_regalado(itemSelcted) != ''" [ngClass]="!itemSelcted ||  itemSelcted.Bougth != true || es_regalado(itemSelcted) != '' ? 'disabled' : 'oranges'" class="new_but" *ngIf="viewAccesories" (click)="regalar_click()">
                    {{'STUDENT.AVATAR-SHOP.GIFT-BUTTON' | translate }}
                </button>
                <button [disabled]="!itemSelcted || !itemSelcted.Active || !findItemActive()" [ngClass]="!itemSelcted || !itemSelcted.Active || !findItemActive() ? 'disabled' : 'oranges'" class="new_but" *ngIf="viewAccesories" (click)="desactivateItem()">
                    {{'STUDENT.AVATAR-SHOP.DEACTIVATE-BUTTON' | translate }}
                </button>
                <button class="new_but" (click)="buyItem()" *ngIf="!viewAccesories"
                    [disabled]="!itemSelcted || this.authService.currentUser.Coins < itemSelcted.Walinwos || calling == true || itemSelcted.Bougth == true"
                    [ngClass]="!itemSelcted || this.authService.currentUser.Coins < itemSelcted.Walinwos || calling == true || itemSelcted.Bougth == true ? 'disabled' : 'oranges'">{{'STUDENT.AVATAR-SHOP.GET-BUTTON' | translate }}</button>
                <button [disabled]="save || itemSelcted?.Bougth !== true || recentlySaved || !hasChanges()" class="new_but" [ngClass]="save || itemSelcted?.Bougth !== true || recentlySaved || !hasChanges() ? 'disabled' : 'azul'"
                    (click)="saveChanges()"> {{'STUDENT.AVATAR-SHOP.SAVE-CHANGES' | translate}}
                </button>
            </div>
        </div>
    </div>
    <div class="box two" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="0.5rem">
        <div class="close" fxShow fxHide.gt-sm>
            <img class="pointer" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true" fxFlex="2rem" (click)="PanelAvantarHide()" />
        </div>
        <div fxFlex="" *ngIf="isMobile == false">
            <tooltip id="stateSession"
                    [info]=tooltipText
                    color="excl" position=""></tooltip>
        </div>
        <div class="avatar_avat">
            <avatar-file *ngIf="activeItems" [activeItems]="activeItems"></avatar-file>
        </div>
        <div *ngIf="hasColor" fxLayout="row" fxLayoutAlign="center center" class="spe_gap">
            <div *ngIf="viewHair || viewEyebrows" style="background: #313131;" class="color" (click)="changeColor(0)">
            </div>
            <div *ngIf="viewHair || viewEyebrows" style="background: #51321B;" class="color" (click)="changeColor(1)">
            </div>
            <div *ngIf="viewHeads || viewEars" style="background: #DB8561;" class="color" (click)="changeColor(0)">
            </div>
            <div *ngIf="viewHeads || viewEars" style="background: #F7A989;" class="color" (click)="changeColor(1)">
            </div>
            <div *ngIf="viewHeads || viewEars" style="background: #FFCAAE;" class="color" (click)="changeColor(3)">
            </div>
            <div *ngIf="viewHeads || viewEars" style="background: #FFD8C7;" class="color" (click)="changeColor(2)">
            </div>
            <div *ngIf="viewEyebrows || viewHair" style="background: #F5C082;" class="color" (click)="changeColor(4)">
            </div>
            <div *ngIf="viewEyebrows || viewHair" style="background: #CA4B00;" class="color" (click)="changeColor(5)">
            </div>
        </div>
    </div>
</div>
<div *ngIf="regalar_userTo || regalar_item" class="walinwa-overlay">
    <div class="walinwa-modal walinwa-box fixedpopup">
        <div class="walinwa-modal-header walinwa-box-header">
            {{'STUDENT.AVATAR-SHOP.GIFT-TITLE' | translate }}
            <img (click)="cancelRegalar()" class="pointer" fxFlex="2.5rem" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true">
        </div>
        <form class="walinwa-modal-body walinwa-box-content">
            <ng-container *ngIf="!regalar_userTo">
                <div><span style="color: red;">{{'STUDENT.AVATAR-SHOP.WARNING' | translate }}</span>{{'STUDENT.AVATAR-SHOP.GIFT-WARNING' | translate }}</div>
                <div>{{'STUDENT.AVATAR-SHOP.SEARCH-FRIEND-TO-GIFT' | translate }}</div>
                <div class="">
                    <label class="font_blue">{{'STUDENT.AVATAR-SHOP.WRITE-NAME' | translate }}</label>
                    <div class="ww_field_spe">
                        <input [(ngModel)]="regalar_userTo_bqd" [ngModelOptions]="{standalone: true}" type="text" />
                    </div>
                </div>
                <div class="">
                    <label class="font_blue">{{'STUDENT.AVATAR-SHOP.SEARCH-NAME-HERE' | translate }}</label>
                    <div class="ww_field_spe">
                        <mat-select [(ngModel)]="selectedStudent" [ngModelOptions]="{standalone: true}">
                            <mat-option class="scrollable" [value]="std" *ngFor="let std of listStudents">{{ std.Name }} {{ std.FamilyName }}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="spe_gap" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
                    <button (click)="checkRegalar()" class="new_but azul">
                        {{'CONFIRM' | translate }}
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="regalar_userTo && !regalar_message">
                    <div *ngIf="regalar_userTo">{{'STUDENT.AVATAR-SHOP.SEND-MESSAGE-WITH-GIFT-TEXT' | translate:{userTo:regalar_userTo.Alias} }}</div>
                    <div class="">
                        <label class="font_blue">{{'MESSAGE' | translate }}</label>
                        <div class="ww_field_spe">
                            <textarea maxlength="100" [(ngModel)]="regalar_message_text" [ngModelOptions]="{standalone: true}"></textarea>
                        </div>
                    </div>
                    <div class="spe_gap" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
                        <button *ngIf="regalar_message_text==''" (click)="continueRegalarMessage()" class="new_but azul">
                            {{'STUDENT.AVATAR-SHOP.SKIP-BUTTON' | translate }}
                        </button>
                        <button *ngIf="regalar_message_text!=''" (click)="continueRegalarMessage()" class="new_but azul">
                            {{'CONFIRM' | translate }}
                        </button>
                    </div>
            </ng-container>
            <ng-container *ngIf="regalar_userTo && regalar_message">
                    <div style="display: block;" [innerHtml]="regalar_mensaje"></div>
                    <div class="">
                        <label class="font_blue">{{'PASSWORD' | translate }}</label>
                        <div class="ww_field_spe icon_suffix">
                            <input [(ngModel)]="regalar_password" [type]="showPassword1 ? 'text' : 'password'" [ngModelOptions]="{standalone: true}" type="password" />
                            <mat-icon class="show-password-icon" [svgIcon]="showPassword1 ? 'gafas_off' : 'gafas_on'" matSuffix (click)="showPassword1 = !showPassword1"></mat-icon>
                        </div>
                        <div class="error" *ngIf="regalar_password_error!=null">
                            {{ regalar_password_error }}
                        </div>
                    </div>
                    <div class="spe_gap" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
                        <button [disabled]="calling" (click)="regalar()" class="new_but azul">
                            {{'CONFIRM' | translate }}
                        </button>
                    </div>
            </ng-container>
        </form>
    </div>
</div>