import { Theme } from 'app/core/shared/state/models/theme.model';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'app/services/course.service';
import { CustomizedSessionService } from 'app/services/customized-session.service';
import { SchoolService } from 'app/services/school.service';
import { TestService } from 'app/services/test.service';
import { Test2Service } from 'app/services/test2.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from 'app/services/navigation.service';
import { AuthService } from 'app/services/auth.service';
import { DateAdapter } from '@angular/material/core';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { ClassService } from 'app/services/class.service';
import { TranslateService } from '@ngx-translate/core';

declare var $;

@Component({
    selector: "app-edit-session",
    templateUrl: "./edit-session.component.html",
    styleUrls: ["./edit-session.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class EditSessionComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  dataLoaded = false;
  selectedDay;
  currentMonth;
  customizedSessions;
  classes = null;
  selectedClass;
  themes = null;
  themesFP = null;
  themesFS = null;
  themesGR = null;
  themesFT = null;

    porRangoFechas = false;
    disabled = true;
    formData: any = {
        FP: "",
        FS: "",
        FT: "",
        GR: "",
        test: "",
        disabled: false,
    };
    rangeSelected = true;
    rangoFechaDesde;
    rangoFechaHasta;
    currentUser;
    minDateDesde;
    minDateHasta;
    messageDays = [];
    showMessage: boolean = false;
    message;
    filteredCustomSessions;

  constructor(
    private userService: UserService,
    private customizedSessionService: CustomizedSessionService,
    private _matDialog: MatDialog,
    private navigationService: NavigationService,
    private authService: AuthService,
    private dateAdapter: DateAdapter<Date>,
    private _translateService: TranslateService,
    private classService: ClassService,
  ) {
    this._unsubscribeAll = new Subject();
    this.dateAdapter.setLocale("es-ES");
  }

  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    if (!this.authService.check()) return;
    this.resetDates();
    this.currentMonth = this.authService.currentUser;
      this.selectedDay = moment()
    this.currentMonth = this.selectedDay.month() + 1;
    this.successGetClasses(this.classService.GetClasses())

    
  }


    disabledBlocks() {
        if (this.formData.disabled) return true;
        if (this.disabled && !this.porRangoFechas) return true;
        if (this.classes.filter((c) => c.selected == true).length == 0)
            return true;
        return false;
    }
    parseDateAndAdddays(days: number) {
        return moment().add(days, "d");
    }
    canSave() {
        if (this.disabledBlocks()) return false;
        if (!this.checkFormData()) return false;
        return true;
    }

    checkFormData() {
        let keys = ["FP", "FS", "FT", "GR"];
        let result = false;
        for (let key of keys) {
            if (this.formData[key] != "NO") result = true;
        }
        return result;
    }

    disabledEx() {
        if (this.formData.disabled) return true;
        if (this.disabled && !this.porRangoFechas) return true;
        if (this.classes.filter((c) => c.selected == true).length == 0)
            return true;
        return false;
    }

    selectedDayChanged(newDate) {
        console.log(newDate)
        if (!this.porRangoFechas) {
            this.selectedDay = newDate
            if (this.selectedDay.isSameOrBefore(moment())) this.disabled = true;
            else this.disabled = false;
        } else {
            this.disabled = false;
        }

        if (this.currentMonth != this.selectedDay.month() + 1) {
            this.loadSessionMonth();
            return;
        }
        this.updateSelections();
    }

    seleccionarRangoFechas() {
        if (this.rangoFechaDesde != null && this.rangoFechaHasta != null) {
            var dias = moment(this.rangoFechaHasta).diff(
                moment(this.rangoFechaDesde),
                "days"
            );
            var list = [];
            if (this.rangoFechaDesde) {
                var f = moment(this.rangoFechaDesde).hour(this.selectedDay.hours()).toDate();

        for (var j = 0; j <= dias; j++) {
            var aux = new Date(f.getTime());
            console.log(aux)
          for (let i = 0; i < this.classes.length; i++) {
           
            if (this.classes[i].selected) {
              let customizedSession = this.customizedSessions.find(c => moment(c.Date).format("DD/MM/YYYY")== moment(aux).format("DD/MM/YYYY")  && c.IdClass == this.classes[i].IdClass)
            
                if (customizedSession?.IdExam != null) {
                  var item = { IdClass: this.classes[i].IdClass, Date: aux, IdExam: customizedSession.IdExam  };
                  list.push(item);
                  this.messageDays.push(aux.getDate());
                }else if (customizedSession?.IdControl != null) {
                  var item3= { IdClass: this.classes[i].IdClass, Date: aux, IdControl: customizedSession.IdControl  };
                  list.push(item3);
                  this.messageDays.push(aux.getDate());
                }
                else {
                  var item2 = { Date: aux, IdClass: this.classes[i].IdClass, FP: this.formData.FP, FS: this.formData.FS, FT: this.formData.FT, GR: this.formData.GR };
                  list.push(item2);
                }
            }
          }
          f.setDate(f.getDate() + 1);
        }
        if (list.length > 0) {
          this.customizedSessionService.add(list)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successAdd.bind(this), this.errorCallbackAdd.bind(this));
        }
      }
    }

  }

    changeClass(clase) {
        for (var i = 0; i < this.classes.length; i++) {
            if (this.classes[i].IdClass == clase.IdClass) {
                this.classes[i].selected = !this.classes[i].selected;
            }/* else {
                this.classes[i].selected = false;
            }*/
        }

        this.updateSelections();
    }

    save() {
        if (this.disabled) return;
        this.disabled = true;
        var list = [];
        var aux = new Date(this.selectedDay);
        for (let i = 0; i < this.classes.length; i++) {
            if (this.classes[i].selected) {
                if (this.formData) {
                    if (this.formData.test) {
                        var item = {
                            IdClass: this.classes[i].IdClass,
                            Date: this.selectedDay,
                            IdExam: this.formData.test,
                        };
                        list.push(item);
                    } else {
                        var item2 = {
                            Date: aux,
                            IdClass: this.classes[i].IdClass,
                            FP: this.formData.FP,
                            FS: this.formData.FS,
                            FT: this.formData.FT,
                            GR: this.formData.GR,
                        };
                        list.push(item2);
                    }
                } else {
                    //Empty item to delete
                    var item3 = {
                        IdClass: this.classes[i].IdClass,
                        Date: this.selectedDay,
                    };
                    list.push(item3);
                }
            }
        }

    this.customizedSessionService.add(list)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successAdd.bind(this), this.errorCallbackAdd.bind(this));
  }

    hasSession(IdClass) {
        if (!this.customizedSessions) return;
        if (!this.porRangoFechas) {
            return (
                this.customizedSessions.find(
                    (c) =>
                        moment(c.Date).isSame(this.selectedDay, "month") &&
                        moment(c.Date).isSame(this.selectedDay, "day") &&
                        c.IdClass == IdClass &&
                        c.IdTest == null
                ) != null
            );
        }
    }

    togglePorRangoFechas() {
        this.classes.forEach((element) => {
            element.selected = false;
        });
        this.porRangoFechas = !this.porRangoFechas;
        this.updateSelections();
    }

    updateSelections() {
        this.showMessage = false;
        let selectedClasses = this.classes.filter((c) => c.selected == true);

        if (!this.porRangoFechas) {
            this.formData = {
                disabled: false,
                test: "",
                FP: "",
                FS: "",
                FT: "",
                GR: "",
            };
        }
        this.formData.disabled = false;
        this.formData.test = "";

        if (!this.porRangoFechas) {  
            const idClasses = selectedClasses.map(c => c.IdClass);
            const customizedSessions = this.customizedSessions.filter(c => idClasses.indexOf(c.IdClass) > -1 && moment(c.Date).isSame(this.selectedDay, "day"));

            if (customizedSessions.length === 0) return;

            const classesWithExamns = customizedSessions.filter(cs => !!cs.IdExam);            
            const classesWithControls = customizedSessions.filter(cs => !!cs.IdControl);

            if (classesWithExamns.length > 0) {
                const idClassesWithExams = classesWithExamns.map(c => c.IdClass);

                this.showMessage = true;
                this.formData.disabled = true;                
                this.message = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.CLASS-ASSIGNED-EXAM-MESSAGE', {className: selectedClasses.filter(c => idClassesWithExams.indexOf(c.IdClass) > -1).map(c => c.Name).join(', ')});
            }

            if (classesWithControls.length > 0) {
                const idClassesWithControls = classesWithControls.map(c => c.IdClass);

                this.showMessage = true;
                this.formData.disabled = true;                    
                this.message = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.CLASS-ASSIGNED-CONTROL-MESSAGE', {className: selectedClasses.filter(c => idClassesWithControls.indexOf(c.IdClass) > -1).map(c => c.Name).join(', ')});
            }

            if (this.showMessage) return;

            const _customizedSessions = customizedSessions.filter(cs => !cs.IdTest).map(cs => { return { FP: cs.FP, FS: cs.FS, FT: cs.FT, GR: cs.GR }})
            const _cs = JSON.stringify(_customizedSessions[0]);

            if (_customizedSessions.every(cs => JSON.stringify(cs) === _cs)) {
                this.formData.FP = customizedSessions[0].FP;
                this.formData.FS = customizedSessions[0].FS;
                this.formData.FT = customizedSessions[0].FT;
                this.formData.GR = customizedSessions[0].GR;
            } else {
                this.showMessage = true;
                this.formData.disabled = true;                    
                this.message = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.CLASSES-ASSIGNED-DIFFERENT-SESSION-MESSAGE');
            }
        }

        let keys = ["FP", "FS", "FT", "GR"];

        for (let key of keys) {
            if (this.formData[key] == null) this.formData[key] = "";
        }
    }

    successAdd() {
        this.resetDates();
        this.disabled = false;
        this.porRangoFechas = false;

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title =
            "Configuración de sesiones predefinidas";
        if (this.messageDays.length > 0) {
            let text;
            this.messageDays.length > 1
                ? (text =
                      "No se ha podido crear una sesión predefinida para los días " +
                      this.messageDays.toString() +
                      " porque ya existen para esos días un examen o sesión de control.")
                : (text =
                      "No se ha podido crear una sesión predefinida para el día " +
                      this.messageDays.toString() +
                      " porque ya existe para ese día un examen o sesión de control.");
            confirmDialog.componentInstance.message1 = text;
        } else {
            confirmDialog.componentInstance.message1 =
                "Opciones actualizadas con éxito.";
        }
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;

    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
    this.messageDays = [];
    this.loadSessionMonth();
  }

  loadSessionMonth() {
    this.customizedSessionService.getMonth(this.authService.currentUser.Id, this.selectedDay.month() + 1)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoadSessionMonth.bind(this), () => { this.navigationService.exit() });
  }

    successLoadSessionMonth(response) {
        if (!response) return this.navigationService.exit();
        this.customizedSessions = response;
        this.filteredCustomSessions = this.customizedSessions.filter(
            (x) => x.IdControl == null && x.IdExam == null && x.IdTest == null
        );
        this.currentMonth = this.selectedDay.month() + 1; //0 index month
        this.dataLoaded = true;
    }

  successLoadthemes(response) {
    if (!response) return this.navigationService.exit();
    this.customizedSessionService.themes = response;
    this.themes = response;
    this.themesFP = this.themes.filter(x => x.Type == 'FP' );
    this.themesFS = this.themes.filter(x => x.Type == 'FS' );
    this.themesFT = this.themes.filter(x => x.Type == 'FT' );
    this.themesGR = this.themes.filter(x => x.Type == 'GR' );
    this.loadSessionMonth();
  }

  successGetClasses(response) {
    if (!response) return this.navigationService.exit();
    this.classes = response.sort(function (a, b) {
      return a.Level - b.Level;
    });
    if (this.classes.length != 0) {
      for (let i = 0; i < this.classes.length; i++)
        this.classes[i].selected = false;
    }
    if(this.customizedSessionService.themes.length > 0){
        this.successLoadthemes(this.customizedSessionService.themes)
    }
    else {
        this.customizedSessionService.getThemes()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((response : Theme[]) => {
        this.successLoadthemes(response)
        }, () => {
        this.navigationService.exit()
        })
    }
  }

  errorCallbackAdd() {
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = "Configuración de sesiones predefinidas";
    confirmDialog.componentInstance.message1 = "Ha habido un error al guardar la configuración";
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
    this.navigationService.exit();
  }

    valueChanged() {
        this.minDateHasta = null;
        this.rangoFechaHasta = null;
        this.minDateHasta = moment(this.rangoFechaDesde).add(1, "days");
        this.rangoFechaHasta = this.minDateHasta;
    }
    resetDates() {
        this.minDateDesde = this.parseDateAndAdddays(1);
        this.rangoFechaDesde = this.parseDateAndAdddays(1);
        this.minDateHasta = this.parseDateAndAdddays(2);
        this.rangoFechaHasta = this.parseDateAndAdddays(2);
    }
    replace(text: string): string {
        var find = "</i>";
        var re = new RegExp(find, "g");
        let res = text.replace(/<i>/g, "&nbsp");
        return res.replace(re, "&nbsp");
    }
}
