<div class="walinwa-box" data-help-image-number="34">
    <div class="walinwa-box walinwa-box-green">
        <div class="gray-background walinwa-box-content" style="padding-bottom: 0px !important;">
            <div class="ww_table_rwd">
                <dx-data-grid #dataGrid keyExpr="IdLee" [allowColumnResizing]="false" [dataSource]="books$ | async" [repaintChangesOnly]="true" (onCellPrepared)="onCellPrepared($event)" (onInitialized)="$event.component.columnOption('command:expand', 'width', 30)" height="100%">
                    <dxo-load-panel [enabled]="true"></dxo-load-panel>
                    <dxo-scrolling mode="standard" [useNative]="false"></dxo-scrolling>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-header-filter [visible]="false"></dxo-header-filter>
                    <dxo-search-panel [visible]="false"></dxo-search-panel>
                    <dxo-group-panel [visible]="false" [allowColumnDragging]="false"></dxo-group-panel>
                    <dxo-grouping [contextMenuEnabled]="false" [autoExpandAll]="false"></dxo-grouping>
                    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
                    
                    <dxi-column dataField="ULevel" [caption]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIBRARY.COURSE' | translate" dataType="number" cellTemplate="classTemplate" groupCellTemplate="classGroupTemplate" [groupIndex]="0" [showWhenGrouped]="true" [allowHiding]="false" alignment="left" width="25%">
                        <dxo-lookup [dataSource]="courses" valueExpr="Level" [displayExpr]="className"></dxo-lookup>
                        <div *dxTemplate="let class of 'classTemplate'">
                            <span class="must-hide"></span>
                        </div>
                        <div *dxTemplate="let class of 'classGroupTemplate'">
                            {{'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIBRARY.COURSE' | translate}}: <span [innerHTML]="getClassGroupName(class.data.key)"></span>
                        </div>
                    </dxi-column>
                    <dxi-column dataField="IdLee" [caption]="'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIBRARY.BOOK' | translate" dataType="number" cellTemplate="bookTemplate" [editorOptions]="{ itemTemplate: 'itemBookTemplate' }" [allowHiding]="false" alignment="left" width="75%">
                        <dxo-lookup [dataSource]="books" valueExpr="IdLee" [displayExpr]="title">
                            <div *dxTemplate="let book of 'itemBookTemplate'">
                                <span [innerHTML]="getBookTitle(book?.IdLee, '(Todos)') | replaceTags"></span>
                            </div>
                        </dxo-lookup>
                        <div *dxTemplate="let book of 'bookTemplate'">
                            <div style="display: flex; flex-wrap: nowrap; cursor: default;">
                                <div style="width: 20%; margin-right: 10px;">
                                    <img [src]="cover(book)">
                                    <div class="font-ubuntu publisher" [innerHTML]="book.data.Publisher || '' | replaceTags"></div>
                                </div>
                                <div class="font-ubuntu" style="width: 80%;">
                                    <div class="blue" [innerHTML]="book.data.Author || '' | replaceTags"></div>
                                    <div class="colorText bold" [innerHTML]="book.data.Title || '' | replaceTags"></div>
                                    <p class="colorText" [innerHTML]="book.data.Synopsis || '' | replaceTags"></p>
                                    <div class="activities">
                                        <div class="orange">{{'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIBRARY.COURSES-RECOMMENDED' | translate}} {{ getClassGroupName(book.data.ULevel) }}</div>
                                        <button class="ww_button goback" (click)="downloadActivities(book.data.Activities)">{{'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIBRARY.DOWNLOAD-ACTIVITIES' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dxi-column>             
                </dx-data-grid>
            </div>
        </div>
    </div>    
</div>