<div *ngIf="dataLoaded && dataLoaded2" style="width: 100%; height: 100%;">

    <!-- aulas -->
    <section fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutAlign.lt-md="stretch"
        class="spe_gap" [hidden]="state != 'class' || !dataLoaded2">
        <div fxFlex="" fxFlex.lt-md="0 0 auto" fxNgClass.gt-sm="scroll_vertical">
            <div class="walinwa-box walinwa-box-green">
                <div class="walinwa-box-header spe_gap" fxLayout="row" fxLayout.lt-md="column"
                    fxLayoutAlign="space-between center">
                    <div>
                        {{'SCHOOL.ADMIN.CLASSES.CLASSES-LIST' | translate}} <small>({{'SCHOOL.ADMIN.CLASSES.CLASSES-AMOUNT' | translate}} {{ school.Classes?.length }})</small>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem" class="">
                        <img (click)="exportData('aulas')" src="https://walinwa.blob.core.windows.net/images/School/ico-descargar-aulas.svg?ngsw-bypass=true" fxFlex="2.5rem"
                            class="pointer" />
                        <select [(ngModel)]="escelOpt" class="download_select">
                            <option value="1">{{'SCHOOL.ADMIN.CLASSES.DOWNLOAD-CLASSES' | translate}}</option>
                            <option value="2">{{'SCHOOL.ADMIN.CLASSES.DOWNLOAD-CLASSES-AND-TEACHERS' | translate}}</option>
                        </select>
                    </div>
                </div>
                <div class="walinwa-box-content no_padding_tables" fxLayout="column" fxLayoutAlign="center stretch">
                    <div class="ww_table_rwd">
                        <table class="walinwa-table two">
                            <thead>
                                <tr>
                                    <th>{{'SCHOOL.ADMIN.CLASSES.GROUP' | translate}}</th>
                                    <th>{{'SCHOOL.COURSE' | translate}}</th>
                                    <th>{{'SCHOOL.ADMIN.CLASSES.TEACHER' | translate}}</th>
                                    <th>{{'SCHOOL.ADMIN.CLASSES.CLASS-CODE' | translate}}</th>
                                    <th>{{'SCHOOL.ADMIN.CLASSES.NUM' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr [ngClass]="{'selected' : currentClass && currentClass.IdClass == class.IdClass }"
                                    *ngFor="let class of school.Classes | sortBy:'asc':['Level','Code']"
                                    (click)="selectClass(class)">
                                    <td>
                                        {{ class.Name }}</td>
                                    <td>
                                        {{ class.Course.Name }}</td>
                                    <td>
                                        {{ class.Tutor.Name }} {{ class.Tutor.FamilyName }}
                                    </td>
                                    <td>
                                        {{ class.Code }}
                                    </td>
                                    <td>
                                        {{ class.NumStudents }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="12rem" fxFlex.lt-md="0 0 auto" fxNgClass.gt-sm="scroll_vertical">
            <div class="ww_sidebar_inner">
                <div (click)="viewStudents()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-ver-alumnos.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.ADMIN.CLASSES.VIEW-STUDENTS-TITLE' | translate"></div>
                </div>
                <div (click)="editClass()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-edit-aula.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.TEACHER.STUDENTS.EDIT-CLASS-TITLE' | translate"></div>
                </div>
                <div (click)="deleteClass()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-eliminar-aula.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.ADMIN.CLASSES.DELETE-CLASS-TITLE' | translate"></div>
                </div>
                <div (click)="importClass()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-importar-alum.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.ADMIN.CLASSES.IMPORT-STUDENTS-TITLE' | translate"></div>
                </div>
                <div (click)="generateClass()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-generar-alumnos.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.ADMIN.CLASSES.GENERATE-STUDENTS-TITLE' | translate"></div>
                </div>
                <div (click)="newClasses()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-generar-aulas.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.ADMIN.CLASSES.GENERATE-CLASSES-TITLE' | translate"></div>
                </div>
                <div (click)="newClass()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap"
                    [ngClass]="{ 'iPad_iPhone_margin': isIpadIphone }">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-anadir-aula.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.ADMIN.CLASSES.ADD-CLASS-TITLE' | translate"></div>
                </div>
            </div>
        </div>
    </section>

    <!-- nuevo aula -->
    <div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical"
        [hidden]="(!state.includes('newClass') && state != 'editClass') || !dataLoaded2">
        <div class="walinwa-box-header" *ngIf="state == 'newClass'">
            {{'SCHOOL.ADMIN.NEW-CLASS-TITLE' | translate }}
        </div>
        <div class="walinwa-box-header" *ngIf="state == 'newClasses'">
            {{'SCHOOL.ADMIN.CLASSES.GENERATE-CLASSES' | translate}}
        </div>
        <div class="walinwa-box-header spe_gap" fxLayout="row" fxLayout.lt-md="column"
            fxLayoutAlign="space-between center" *ngIf="!extraFields && currentClass"
            [hidden]="state.includes('newClass')">
            <div>
                {{'SCHOOL.EDIT-CLASS-TITLE' | translate }} {{ currentClass.Name }}
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" class="spe_gap">
                <img (click)="state = 'generarAlumnosAula'" src="https://walinwa.blob.core.windows.net/images/School/ico-generar-alumnos-v2.svg?ngsw-bypass=true" fxFlex="2.5rem"
                    class="pointer" />
                <div (click)="state = 'generarAlumnosAula'" class="pointer" style="display: inline-flex;" 
                [innerHTML]="'SCHOOL.ADMIN.CLASSES.GENERATE-STUDENTS-TITLE' | translate">
                </div>
                <div fxFlex="10px"></div>
                <img (click)="state = 'importarAlumnosAulaExcel'" src="https://walinwa.blob.core.windows.net/images/School/ico-import-excel.svg?ngsw-bypass=true"
                    fxFlex="2.5rem" class="pointer" />
                <div (click)="state = 'importarAlumnosAulaExcel'" class="pointer" style="display: inline-flex;" 
                [innerHTML]="'SCHOOL.ADMIN.CLASSES.IMPORT-EXCEL-TITLE' | translate">
                </div>
            </div>
        </div>
        <div class="walinwa-box-header" *ngIf="extraFields && currentClass && !state.includes('newClass')">
            {{'SCHOOL.AUTH-CLASS-TITLE' | translate }} {{ currentClass.Name }}
        </div>
        <div class="walinwa-box-content" fxLayout="column" fxLayoutAlign="center stretch"
            *ngIf="extraFields && currentClass && currentClass.AuthorizedParents">
            <div>
                {{'SCHOOL.AUTH-CLASS-MESSAGE' | translate }}
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-around center" class="spe_gap">
                <div fxFlex="75%" fxFlex.lt-md="100%">                    
                    <mat-form-field class="" style="width: 100%;">
                        <label class="font_blue" style="display: block;">{{'SCHOOL.AUTH-NEW-TEACHER' | translate }}</label>
                        <mat-select [disabled]="teachersAux.length==0" [(ngModel)]="selectedTeacherAux">
                            <mat-option class="scrollable" *ngIf="teachersAux.length==0" [value]="null">{{'SCHOOL.NO-TEACHERS' | translate }}</mat-option>
                            <mat-option class="scrollable"
                                *ngFor="let teacher of teachersAux | sortBy:'asc':'FamilyName'"
                                [value]="teacher.Id">{{parseProfesorName(teacher)}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%">
                    <button [disabled]="!selectedTeacherAux" (click)="addTeacherAux()" class="new_but azul">
                        {{'SAVE' | translate}}
                    </button>
                </div>
            </div>
            <div>
                <label class="font_blue">{{'SCHOOL.AUTH-TEACHERS' | translate }}</label>
            </div>
            <div>
                <div class="ww_table_rwd">
                    <table class="walinwa-table">
                        <tbody>
                            <tr class="selected" *ngFor="let teacher of currentClass.AuthorizedParents">
                                <td>
                                    {{ teacher.UserTutor.Email }}
                                </td>
                                <td style="width: 50px;">
                                    <img (click)="removeAuthTeacher(teacher)" class="spe_ico_exam"
                                    src="https://walinwa.blob.core.windows.net/images/School/ico-trash.svg?ngsw-bypass=true">
                                </td>
                            </tr>
                            <tr *ngIf="currentClass.AuthorizedParents.length == 0">
                                <td align="center">
                                    {{'SCHOOL.NO-AUTH-TEACHERS' | translate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <form class="walinwa-box-content" fxLayout="column" fxLayoutAlign="center stretch"
            [hidden]="extraFields || state == 'newClasses'" [formGroup]="formDataClass" *ngIf="formDataClass" #formDataClassDirective="ngForm">
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
                <div fxFlex="0 0 calc(50% - 1rem)" fxFlex.lt-md="100%">
                    <mat-form-field class="">
                        <label class="font_blue">{{'SCHOOL.SCHOOL-COURSE' | translate }}</label>
                        <mat-select formControlName="Level">
                            <mat-option class="scrollable" [value]="class.Level"
                                *ngFor="let class of courses | sortBy:'asc':'Level'">{{ class.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formDataClass.get('Level').hasError('required')">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="0 0 calc(50% - 1rem)" fxFlex.lt-md="100%">                    
                    <mat-form-field class="arrowed-autocomplete">
                        <label class="font_blue" [innerHTML]="'SCHOOL.CLASS-LETTER' | translate"></label>
                        <input formControlName="Name" [matAutocomplete]="autoGrupo" matInput type="text" />
                        <svg viewBox="0 0 24 24" width="24px" height="24px" focusable="false" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
                        <mat-autocomplete autoActiveFirstOption #autoGrupo="matAutocomplete">
                            <mat-option class="scrollable" [value]="letter" *ngFor="let letter of classLetters">
                                {{ letter }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="formDataClass.get('Name').hasError('required')">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
                <div fxFlex="0 0 calc(50% - 1rem)" fxFlex.lt-md="100%">
                    <mat-form-field class="">
                        <label class="font_blue">{{'SCHOOL.AUTH-TEACHER' | translate }}</label>
                        <mat-select [disabled]="teachersAux.length==0" formControlName="Teacher">
                            <mat-option class="scrollable" [value]="teacher.Id"
                                *ngFor="let teacher of teachers | sortBy:'asc':'FamilyName'">{{parseProfesorName(teacher)}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formDataClass.get('Teacher').hasError('required')">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="0 0 calc(50% - 1rem)" fxFlex.lt-md="100%" [hidden]="state == 'newClass'">
                    <label class="font_blue" (click)="showClassId=!showClassId">{{'SCHOOL.CLASS-CODE' | translate }}</label>
                    <div class="ww_field_spe two" style="position: relative;">
                        <input id="formCodeId" formControlName="Code" matInput type="text" class="form-control">
                        <div *ngIf="showClassId"
                            style="left: 9.5rem; top: -3.5rem; z-index: 1; height: 4em; width: 15em;"
                            class="walinwa-tooltip walinwa-tooltip-right">
                            <div class="walinwa-tooltip-info walinwa-tooltip-info-primary">
                                {{'CLASS' | translate}}: {{ currentClass.IdClass }}
                            </div>
                        </div>
                    </div>
                    <mat-error *ngIf="formDataClass.get('Code').hasError('required')">
                        {{'FIELD-REQUIRED' | translate }}
                    </mat-error>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center"
                class="spe_gap">

                <button (click)="changeExtraFields()" [hidden]="state == 'newClass'" class="new_but azul">
                    {{'SCHOOL.AUTH-TEACHERS' | translate }}
                </button>

                <button [disabled]="calling" (click)="saveClass()" [hidden]="state == 'editClass'" class="new_but azul">
                    {{'SAVE' | translate}}
                </button>

                <button [disabled]="calling" (click)="updateClass()" [hidden]="state == 'newClass'" class="new_but azul">
                    {{'SAVE' | translate}}
                </button>
            </div>
        </form>
        <form class="walinwa-box-content" fxLayout="column" fxLayoutAlign="center stretch"
            [hidden]="extraFields || state != 'newClasses'" [formGroup]="formDataClasses" *ngIf="formDataClasses" #formDataClassesDirective="ngForm">
            <div fxLayout="row wrap" fxLayoutAlign="space-around start" class="spe_gap">
                <div fxFlex="" fxFlex.lt-md="100%">
                    <mat-form-field class="">
                        <label class="font_blue">{{'SCHOOL.ADMIN.CLASSES.COURSE-FROM' | translate}}</label>
                        <mat-select formControlName="LevelFrom">
                            <mat-option class="scrollable" [value]="levelFrom"
                                *ngFor="let class of courses | sortBy:'asc':'Level'; let levelFrom = index">{{
                                class.Name }}</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="formDataClasses.get('LevelFrom').hasError('required') && formDataClasses.get('LevelFrom').touched">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%">                    
                    <mat-form-field class="">
                        <label class="font_blue">{{'SCHOOL.ADMIN.CLASSES.COURSE-TO' | translate}}</label>
                        <mat-select formControlName="LevelTo">
                            <mat-option class="scrollable" [value]="levelTo"
                                *ngFor="let class of courses | sortBy:'asc':'Level'; let levelTo = index">{{ class.Name
                                }}</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="formDataClasses.get('LevelTo').hasError('required') && formDataClasses.get('LevelTo').touched">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-around start" class="spe_gap">
                <div fxFlex="" fxFlex.lt-md="100%">                    
                    <mat-form-field class="">
                        <label class="font_blue">{{'SCHOOL.ADMIN.CLASSES.GROUP-FROM' | translate}}</label>
                        <mat-select formControlName="NameFrom">
                            <mat-option class="scrollable" [value]="nameFrom"
                                *ngFor="let letter of classLetters; let nameFrom = index">{{ letter }}</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="formDataClasses.get('NameFrom').hasError('required') && formDataClasses.get('NameFrom').touched">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%">                    
                    <mat-form-field class="">
                        <label class="font_blue">{{'SCHOOL.ADMIN.CLASSES.GROUP-TO' | translate}}</label>
                        <mat-select formControlName="NameTo">
                            <mat-option class="scrollable" [value]="nameTo"
                                *ngFor="let letter of classLetters; let nameTo = index">{{ letter }}</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="formDataClasses.get('NameTo').hasError('required') && formDataClasses.get('NameTo').touched">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-around start" class="spe_gap">
                <div fxFlex="" fxFlex.lt-md="100%">                    
                    <mat-form-field class="">
                        <label class="font_blue">{{'SCHOOL.AUTH-TEACHER' | translate }}</label>
                        <mat-select [disabled]="teachersAux.length==0" formControlName="Teacher">
                            <mat-option class="scrollable" [value]="teacher.Id"
                                *ngFor="let teacher of teachers | sortBy:'asc':'FamilyName'">{{parseProfesorName(teacher)}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="formDataClasses.get('Teacher').hasError('required') && formDataClasses.get('Teacher').touched">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%" fxHide fxShow.gt-sm>
                    <!--  -->
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
                <button [disabled]="calling" (click)="saveClasses()" class="new_but azul">
                    {{'CONTINUE' | translate}}
                </button>
            </div>
        </form>
    </div>

     <!-- Generar alumnos -->
    <div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical" *ngIf="getAlulaToImport()"
     [hidden]="(state != 'generarAlumnosAula') || !dataLoaded2">
     <div class="walinwa-box-header">
        {{'SCHOOL.ADMIN.STUDENTS.GENERATE-STUDENTS-TITLE' | translate }} {{ getAlulaToImport().Name }}
     </div>
     <div class="walinwa-box-content" fxLayout="column" fxLayoutAlign="center stretch">
         <div>
            {{'SCHOOL.ADMIN.STUDENTS.GENERATE-STUDENTS-TEXT' | translate }}
         </div>
         <div fxLayout="row" fxFlex.lt-md="column">
             <div fxFlex="50%" fxFlex.lt-md="auto">                 
                 <mat-form-field class="">
                    <label class="font_blue" style="display: block;">{{'SCHOOL.ADMIN.STUDENTS.STUDENTS-AMOUNT' | translate }}</label>
                     <input [(ngModel)]="numStd" matInput type="number" min="0" max="50" class="form-control">
                 </mat-form-field>
             </div>
         </div>
         <div *ngIf="numStudentsError == 1" class="error-message">
             <mat-error >
                {{'FIELD-REQUIRED' | translate }}
             </mat-error>
         </div>
         <div *ngIf="numStudentsError == 2" class="error-message">
             <mat-error>
                {{'SCHOOL.ADMIN.STUDENTS.STUDENTS-AMOUNT-LENGTH' | translate }}
             </mat-error>
         </div>
         <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
             <button (click)="generateStudents()" [hidden]="loading" class="new_but azul">
                {{'CONTINUE' | translate}}
             </button>
         </div>
    </div>
    </div>
     <!-- importar alumnos aula desde excel -->
    <div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical" *ngIf="getAlulaToImport()"
     [hidden]="state != 'importarAlumnosAulaExcel' || !dataLoaded2">
     <div class="walinwa-box-header">
        {{'SCHOOL.ADMIN.CLASSES.IMPORT-FROM-EXCEL' | translate}} {{ getAlulaToImport().Name }}
     </div>
     <div class="walinwa-box-content" fxLayout="column" fxLayoutAlign="center stretch">
         <div [innerHTML]="'SCHOOL.ADMIN.CLASSES.IMPORT-FROM-EXCEL-TEXT' | translate">
         </div>
         <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="gap_desc_file">
             <div fxFlex="" fxFlex.lt-md="100%">
                 <label class="font_blue">{{'SCHOOL.ADMIN.TEACHERS.COLUMNS-TO-IMPORT' | translate }}</label>
                 <div class="ww_field_spe">
                     <select (ngModelChange)="change()" [(ngModel)]="schoolService.importDataConfig.numeroColumnasImportar"
                         [ngModelOptions]="{standalone: true}" class="select">
                         <option [value]="numVal.key" *ngFor="let numVal of schoolService.numColumnas">
                             {{ numVal.name }}
                         </option>
                     </select>
                 </div>
             </div>
             <div fxFlex="" fxFlex.lt-md="100%">
                 <mat-checkbox [(ngModel)]="schoolService.importDataConfig.descartarPrimeraFila"
                     [ngModelOptions]="{standalone: true}">
                     {{'SCHOOL.ADMIN.TEACHERS.DISMISS-FIRST-COLUMN' | translate }}
                 </mat-checkbox>
             </div>
         </div>
         <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="gap_desc_file">
             <div fxFlex="" fxFlex.lt-md="100%">
                 <label class="font_blue">{{'SCHOOL.ADMIN.TEACHERS.COLUMNS-ORDER' | translate }}</label>
                 <div class="ww_field_spe">
                     <select [(ngModel)]="schoolService.importDataConfig.ordenColumnas" [ngModelOptions]="{standalone: true}">
                         <option [value]="i"
                             *ngFor="let orden of schoolService.ordenColumnas[schoolService.importDataConfig.numeroColumnasImportar]; let i = index">
                             {{ orden }}
                         </option>
                     </select>
                 </div>
             </div>
             <div fxFlex="" fxFlex.lt-md="100%">
                 <mat-checkbox
                     *ngIf="schoolService.importDataConfig.numeroColumnasImportar == 1 && schoolService.importDataConfig.ordenColumnas != 0"
                     [(ngModel)]="schoolService.importDataConfig.nombreApellidosSeparados" [ngModelOptions]="{standalone: true}">
                     <p>{{'SCHOOL.ADMIN.TEACHERS.NAMES-SEPARATED' | translate }}</p>
                 </mat-checkbox>
                 <div *ngIf="!(schoolService.importDataConfig.numeroColumnasImportar == 1 && schoolService.importDataConfig.ordenColumnas != 0)">
                 </div>
             </div>
         </div>
         <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center"
             class="spe_gap">
             <input accept=".xlsx, .xls" id="excelFile" type="file" (change)="loadFile($event)"
                 style="visibility:hidden; display: none;" />

             <button [hidden]="loading" (click)="!loading ? selectFile() : null" class="new_but azul">
                {{'SCHOOL.ADMIN.TEACHERS.SELECT-FILE' | translate }}
             </button>
            </div>
        </div>
    </div>
</div>

<img [hidden]="state == 'start' || dataLoaded2"
    style="width: 10em; position: fixed;top: 0;left: 0;bottom: 0;right: 0;margin: auto;"
    src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">

<img [hidden]="!loading" style="width: 10em; position: fixed;top: 0;left: 0;bottom: 0;right: 0;margin: auto;"
    src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">