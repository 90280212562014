import { FindUrlPipe } from 'app/pipes/find-url.pipe';
import { MyZoneItemsUser } from 'app/core/shared/state/models/my-zone-items-user.model';
import { MyZoneService } from 'app/services/my-zone.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { AvatarShopService } from 'app/services/avatar-shop.service';
import { NavigationService } from 'app/services/navigation.service';
import { environment } from 'environments/environment';
import { FileService } from 'app/services/file.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'app/services/user.service';
import { result } from 'lodash';
import { SessionService } from 'app/services/session.service';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from 'app/core/shared/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-zone',
    templateUrl: './zone.component.html',
    styleUrls: ['./zone.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ZoneComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    activeItems;
    avatarActive;
    availableAvatarItems;
    boughtItems;
    showHelp = false;
    loading = false;
    availableItems: MyZoneItemsUser[];
    canPlay: boolean;
    canBuy: boolean;
    loadingPopUp = false;

    constructor(
        private avatarShopService: AvatarShopService,
        private authService: AuthService,
        private navigationService: NavigationService,
        private myZoneService: MyZoneService,
        public userService: UserService,
        private sessionService: SessionService,
        public sanitizer: DomSanitizer,
        public sharedService: SharedService,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    async ngOnInit() {
        this.loading = true;
        this.canBuy = await this.userService.getMyZoneMeetAllConditions()
        this.canPlay = await this.userService.getPlayMeetAllConditions()
        this.GetAvailableImages();
        this.avatarShopService.currentStatus
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(resp => {
                this.avatarActive = resp;
                if (this.avatarActive) {
                    this.GetActiveItems();
                }
            })
        this.avatarShopService.ItemBuyed
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.GetActiveItems();
            });
    }

    GetActiveItems(): void {
        this.avatarShopService.GetActiveItemsByIdUser(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                this.activeItems = result;
            })
    }

    errorCallback() {
        this.navigationService.goLogin();
    }
    /*getImgUrl(imgName: string = null) {
        if(imgName != null){
            return this.sharedService_.getImgUrl(this.myZoneService.subDirectory + imgName);
        }
        
        return "'url(&quot;https://walinwa.blob.core.windows.net/images/" + (this.myZoneService.subDirectory + this.getImgName()) + "&quot;)'";
    }*/

    getImgName(): string {
        let imageName = "100.png"
        if (this.availableItems && this.availableItems.length > 0) {
            let activo = this.availableItems.find(x => x.Active == true)
            imageName = activo.IdMyZone + ".png";
        }
        return imageName;
    }
    getButtonImgUrl(buttonName: string): string {
        return "https://walinwa.blob.core.windows.net/images/student/zone/" + buttonName + ".svg?ngsw-bypass=true";
    }
    openAvatar() {
        window.postMessage("avatar-active", "*");
    }
    GetAvailableImages() {
        this.loading = true;
        this.myZoneService.GetUserItems(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: MyZoneItemsUser[]) => {
                this.availableItems = result
                if (result.length == 0) {
                    let IdUser: number = this.authService.currentUser.Id
                    this.availableItems.push({
                        IdMyZone: 100,
                        IdUser: IdUser,
                        RewardDate: new Date(),
                        Active: true,
                    })
                }
                this.availableItems.forEach(x => {
                    if (x.Active) {
                        x.selected = true;
                    }
                })
                this.loading = false;
            }, () => {
                this.loading = false;
            })

    }
    showDialog(title, messages: string[]) {
        this.loadingPopUp = false;
        let arrfunc = () => {
        }
        this.navigationService.PlayAndZoneMissingConditions(title, arrfunc, () => {
            
            this.navigationService.error(messages, arrfunc)
            
        })
    }
    showGameDialog() {
        if(this.loadingPopUp){
            return
        }
        this.loadingPopUp = true;
        let messages: string[] = [];
        this.userService.getPlayAndMyZoneActualConditions().subscribe(async result => {
            messages.push("Juegos");
            if (result.SessionPlayCondition == false) {
                console.log(result)
                const response: any = await this.sessionService.checkSessionCondition(this.authService.currentUser.Id, this.authService.currentUser.IdClass ?? 0, 2).toPromise();
                if (response.message == "TodaySessionNotDone") {
                    messages.push(this._translateService.instant('STUDENT.ZONE.TODAYSESSIONNOTDONE'));
                }
                else if (response.message == "FinishStartedSessions") {
                    messages.push(this._translateService.instant('STUDENT.ZONE.FINISHSTARTEDSESSIONS'));
                }
                else if (response.message == "FinishAllAvailableSessions") {
                    messages.push(this._translateService.instant('STUDENT.ZONE.FINISHALLAVAILABLESESSIONS'));
                }
            }
            if (result.DictationPlayCondition == false) {
                messages.push(this._translateService.instant('STUDENT.ZONE.DICTATIONCONDITION'));
            }
            if (result.ReadingPlayCondition == false || result.DeductionPlayCondition == false || result.HearingPlayCondition == false) {
                messages.push(this._translateService.instant('STUDENT.ZONE.READINGCONDITION'));
            }
            if (result.MemoryPlayCondition == false) {
                messages.push(this._translateService.instant('STUDENT.ZONE.MEMORYCONDITION'));
            }
            if (!result.TestPlayCondition) {
                messages.push(this._translateService.instant('STUDENT.ZONE.TESTCONDITION'))
            }
            this.showDialog("Juegos", messages);
        },() => {
            this.loadingPopUp = false;
        })
    }
    showZoneDialog() {
        if(this.loadingPopUp){
            return
        }
        this.loadingPopUp = true;
        let messages: string[] = [];
        this.userService.getPlayAndMyZoneActualConditions().subscribe(async result => {
            messages.push("Tienda");
            if (result.SessionMyZoneCondition == false) {
                const response: any = await this.sessionService.checkSessionCondition(this.authService.currentUser.Id, this.authService.currentUser.IdClass ?? 0, 1).toPromise();
                if (response.message == "TodaySessionNotDone") {
                    messages.push(this._translateService.instant('STUDENT.ZONE.TODAYSESSIONNOTDONE'));
                }
                else if (response.message == "FinishStartedSessions") {
                    messages.push(this._translateService.instant('STUDENT.ZONE.FINISHSTARTEDSESSIONS'));
                }
                else if (response.message == "FinishAllAvailableSessions") {
                    messages.push(this._translateService.instant('STUDENT.ZONE.FINISHALLAVAILABLESESSIONS'));
                }
            }
            if (result.DictationMyZoneCondition == false) {
                messages.push(this._translateService.instant('STUDENT.ZONE.DICTATIONCONDITION'));
            }
            if (result.ReadingMyZoneCondition == false || result.DeductionMyZoneCondition == false || result.HearingMyZoneCondition == false) {
                messages.push(this._translateService.instant('STUDENT.ZONE.READINGCONDITION'));
            }
            if (result.MemoryMyZoneCondition == false) {
                messages.push(this._translateService.instant('STUDENT.ZONE.MEMORYCONDITION'));
            }
            if (result.TestMyZoneCondition == false) {
                messages.push(this._translateService.instant('STUDENT.ZONE.TESTCONDITION'))
            }
            this.showDialog("Tienda", messages);
        },() => {
            this.loadingPopUp = false;
        })
    }
}
